import { UserOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Input from '../../../../foundation/components/input/Input';
import Select from '../../../../foundation/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectAllowedAdditionalUsers,
  selectClientIp,
  selectSubscribedPlanId,
  selectUser,
} from '../../../user/redux/selectors';
import { inviteTeam, InviteTeamProps } from '../../redux/async_thunks';
import {
  selectAccountMembersAvailableSlots,
  selectAllAppRoles,
} from '../../redux/selectors';
import { InviteType, StepTypes } from '../../types';

const { Panel } = Collapse;

type USER_OBJ = {
  email: undefined | string;
  role: undefined | number;
  id: undefined | number;
  errors: {
    email: undefined | string;
    role: undefined | string;
  };
  activated: boolean;
};

const yupEmailSchema = Yup.string().email();

type InviteTeamCompProps = {
  handleSkip?: () => void;
  handleCancel?: () => void;
  setStep?: (v: StepTypes) => void;
  setAllInvites?: (v: InviteType[]) => void;
  canSkip: boolean;
  type: 'onboarding' | 'members' | 'clients';
  groupId?: string;
};

const InviteTeam = ({
  handleSkip,
  setStep,
  setAllInvites,
  canSkip,
  handleCancel,
  type,
  groupId,
}: InviteTeamCompProps) => {
  const MIN_CLIENT_SEATS = 5;
  const isTypeClients = type === 'clients';
  const dispatch = useAppDispatch();

  const [isInviteTeamLoading, setInviteTeamLoading] = useState(false);
  const [activePanel, setActivePanel] = useState<number[]>([]);
  const [usersArray, setUsersArray] = useState<USER_OBJ[]>([]);
  const [displayAllInvites, setInvitesDisplay] = useState(false);

  const allAppRoles = useAppSelector(selectAllAppRoles);

  // Remove the client role by default
  const userAppRoles = allAppRoles
    ? allAppRoles.filter((role) => {
        return role.value !== 0;
      })
    : [];

  const subscribedPlanId = useAppSelector(selectSubscribedPlanId);

  const additionalNumOfUsers = useAppSelector(selectAllowedAdditionalUsers);

  const availableSlots = useAppSelector(selectAccountMembersAvailableSlots);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const memberTypeDisplayText = isTypeClients ? 'client' : 'user';

  const emptyUserObj: USER_OBJ = useMemo(
    () => ({
      email: '',
      // Assign client role ID on clients invite flow
      role: isTypeClients ? 0 : undefined,
      id: undefined,
      errors: {
        email: undefined,
        role: undefined,
      },
      activated: false,
    }),
    [],
  );

  const numOfUsersAllowed = useMemo(() => {
    let numOfUsers = 0;

    if (isTypeClients) {
      return MIN_CLIENT_SEATS;
    }

    if (type === 'members' && availableSlots) {
      numOfUsers += availableSlots;
    } else {
      if (additionalNumOfUsers) {
        numOfUsers += additionalNumOfUsers;
      }
    }

    return numOfUsers;
  }, [subscribedPlanId, additionalNumOfUsers, availableSlots]);

  const handleUserRemove = (id: number) => () => {
    const newArray = usersArray.map((item) => {
      if (item.id === id) {
        return {
          ...emptyUserObj,
          id,
        };
      }
      return { ...item };
    });

    setUsersArray([...newArray]);
    const panels = [];
    for (const i of activePanel) {
      if (i !== id) {
        panels.push(i);
      }
    }
    setActivePanel(panels);
  };

  const handlePanelInviteClick = (id: number) => () => {
    const newArray = usersArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          activated: true,
        };
      }
      return { ...item };
    });

    setUsersArray([...newArray]);
  };

  const handleEmailChange = (id: number) => (e: any) => {
    const newArray = usersArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          email: e.target.value,
        };
      }
      return { ...item };
    });

    setUsersArray([...newArray]);
  };

  const handleRoleChange = (id: number) => (v: number) => {
    const newArray = usersArray.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          role: v,
          errors: {
            ...item.errors,
            role: undefined,
          },
        };
      }
      return { ...item };
    });

    setUsersArray([...newArray]);
  };

  const handleCollapse = (v: any) => {
    setActivePanel(v);
  };

  const handleErrors = () => {
    let isContainErrors = false;

    const activatedUsrs: {
      email: string;
      roleId: number;
    }[] = [];

    const newArray = usersArray.map((item) => {
      if (item.activated) {
        let errorEmail = '';
        let errorRole = '';
        const isValidEmail = yupEmailSchema.isValidSync(item.email);

        if (isValidEmail && item.role !== undefined && item.email) {
          activatedUsrs.push({
            email: item.email,
            roleId: item.role,
          });
        }

        if (!isValidEmail) {
          isContainErrors = true;
          errorEmail = 'Please enter a valid email.';
        }

        if (item.role === undefined) {
          isContainErrors = true;
          errorRole = 'Please select the permissions';
        }

        return {
          ...item,
          errors: {
            email: errorEmail,
            role: errorRole,
          },
        };
      }
      return item;
    });

    setUsersArray([...newArray]);

    return { isContainErrors, activatedUsrs };
  };

  const handleTeamInvite = async () => {
    const { isContainErrors } = handleErrors();
    let { activatedUsrs } = handleErrors();
    if (isContainErrors || isInviteTeamLoading) {
      return;
    }

    try {
      if (activatedUsrs && activatedUsrs.length) {
        setInviteTeamLoading(true);

        // Group ID is added to the payload when invite is triggered from the chat component
        if (groupId) {
          activatedUsrs = activatedUsrs.map((user) => {
            return { ...user, groupId: groupId };
          });
        }

        const options = {
          token: user?.jwtToken.token,
          clientIp: clientIp,
          sessionId: user?.sessionId,
        };

        const data: InviteTeamProps = {
          userId: user?.userId,
          invitees: activatedUsrs,
        };

        await dispatch(
          inviteTeam({
            data,
            options,
          }),
        ).unwrap();

        if (setAllInvites) {
          setAllInvites(activatedUsrs);
        }

        if (setStep) {
          setStep('success');
        }

        if (handleCancel) {
          handleCancel();
        }

        notification.success({
          message: 'Success!',
          description: 'Invite has been sent successfully.',
        });
      }
      setInviteTeamLoading(false);
    } catch (error) {
      console.log(error);
      setInviteTeamLoading(false);
    }
  };

  const handleCancelStep = () => {
    if (isInviteTeamLoading) {
      return;
    }
    if (handleSkip) {
      handleSkip();
    }
  };

  const handleModalCancel = () => {
    if (isInviteTeamLoading) {
      return;
    }

    if (handleCancel) {
      handleCancel();
    }
  };

  useEffect(() => {
    const allowedUsers = [];

    for (let i = 0; i < numOfUsersAllowed; i++) {
      allowedUsers.push({ ...emptyUserObj, id: i + 1 });
    }

    setUsersArray(allowedUsers);
  }, [numOfUsersAllowed]);

  const inviteView = useMemo(() => {
    const views = [];
    if (usersArray) {
      for (let i = 0; i < usersArray.length; i++) {
        if (!displayAllInvites) {
          if (i === 3) {
            break;
          }
        }
        const id = i + 1;
        views.push(
          <Panel
            className="l-team-setup__panel"
            header={
              <div className="l-team-setup__panel-header">
                <div className="l-team-setup__panel-hedaer-side">
                  <Button
                    className="l-team-setup__panel-hedaer-usr-icon"
                    shape="circle"
                    icon={<UserOutlined />}
                    type="primary"
                  />
                  <div className="l-team-setup__panel-hedaer-usr-num">
                    Additional {memberTypeDisplayText} {id}
                  </div>
                </div>
                <div>
                  {usersArray[i].activated ? (
                    <Button
                      className="l-team-setup__panel-hedaer-cancel-btn"
                      onClick={handleUserRemove(id)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      className="l-team-setup__panel-hedaer-invite-btn"
                      onClick={handlePanelInviteClick(id)}
                    >
                      Invite {memberTypeDisplayText}
                    </Button>
                  )}
                </div>
              </div>
            }
            key={id}
            showArrow={false}
          >
            <div className="l-team-setup__field-wrapper">
              <Input
                label="Enter email address"
                onChange={handleEmailChange(id)}
                value={usersArray[i].email}
              />
              <div className="l-team-setup__field-error">
                {usersArray[i].errors.email}
              </div>
            </div>
            <div
              className="l-team-setup__field-wrapper"
              style={isTypeClients ? { display: 'none' } : {}}
            >
              <div className="l-team-setup__field-label">User Pemissions</div>
              <Select
                options={userAppRoles}
                className="l-team-setup__select"
                onChange={handleRoleChange(id)}
                value={usersArray[i].role}
                disabled={isTypeClients}
              />
              <div className="l-team-setup__field-error">
                {usersArray[i].errors.role}
              </div>
            </div>
          </Panel>,
        );
      }
    }
    return views;
  }, [usersArray, displayAllInvites]);

  return (
    <div className="l-team-setup">
      {isInviteTeamLoading && <FullPageLoader />}
      <Divider />
      <Collapse
        ghost
        activeKey={activePanel}
        onChange={handleCollapse}
        style={{ marginBottom: 20 }}
      >
        {inviteView}
      </Collapse>
      {usersArray.length > 3 && (
        <div className="l-team-setup__show-btn">
          <Button
            onClick={() => {
              setInvitesDisplay((v) => !v);
            }}
          >
            {displayAllInvites ? 'Show less' : `Show more `}
          </Button>
        </div>
      )}
      <Divider />
      <div
        className={`l-onboarding__footer ${
          !canSkip ? 'l-team-setup__footer' : ''
        }`}
      >
        {canSkip && (
          <div className="l-onboarding__skip-btn" onClick={handleCancelStep}>
            Do this later
          </div>
        )}
        {!canSkip && (
          <Button
            className="l-onboarding__save-btn"
            style={{ width: 132, marginRight: 20 }}
            onClick={handleModalCancel}
            disabled={isInviteTeamLoading}
          >
            Cancel
          </Button>
        )}
        <Button
          type="primary"
          className="l-onboarding__save-btn"
          style={{ width: 132 }}
          onClick={handleTeamInvite}
          disabled={isInviteTeamLoading}
        >
          Invite
        </Button>
      </div>
    </div>
  );
};

export default InviteTeam;
