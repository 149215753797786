import { RootState } from '../../../store';

export const selectUser = (state: RootState) => state.user.user;

export const selectClientIp = (state: RootState) => state.user.clientIP;

export const selectFeatureFlag = (state: RootState) => state.user.featureFlag;

export const selectUserInviteLink = (state: RootState) =>
  state.user.invite?.link;

export const selectUserInviteMessage = (state: RootState) =>
  state.user.invite?.message;

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectUserFavorites = (state: RootState) => state.user.favorites;

export const selectUserSearchFavorites = (state: RootState) => ({
  suburbSearches: state.user.favorites?.suburbSearches,
  propertySearches: state.user.favorites?.propertySearches,
});

export const selectUserSuburbFavorites = (state: RootState) =>
  state.user.favorites?.suburbs;

export const selectUserPropertyFavorites = (state: RootState) =>
  state.user.favorites?.properties;

export const selectUserCometChatConfig = (state: RootState) =>
  state.user.ccConfig;

export const selectIsOnboarded = (state: RootState) => state.user.isOnboarded;

export const selectNotifications = (state: RootState) =>
  state.user.notifications;

export const selectHasNotifications = (state: RootState) =>
  state.user.hasNotifications;

export const selectStripeCustomerId = (state: RootState) =>
  state.user?.subscribeChallenge?.customerId;

export const selectStripeConfig = (state: RootState) => state.user.stripeConfig;

export const selectAvailablePlans = (state: RootState) =>
  state.user?.subscribeChallenge?.subscriptionPlans;

export const selectStripeClientSecretId = (state: RootState) =>
  state.user?.subscribeChallenge?.subscriptionPlanDetails?.clientSectId;

export const selectUserFeatures = (state: RootState) => {
  return state.user.user ? state.user.user.features : [];
};

export const selectIsSubscribed = (state: RootState) => state.user?.subscribed;

export const selectSubscriptionPlanDetails = (state: RootState) =>
  state.user?.subscribeChallenge?.subscriptionPlanDetails;

export const selectOrgId = (state: RootState) => state.user.profile?.orgId;

export const selectOrgName = (state: RootState) => state.user.profile?.orgName;

export const selectSubscribedPlanId = (state: RootState) =>
  state.user?.subscribeChallenge?.subscriptionPlanDetails.subscriptionPlanId;

export const selectAllowedAdditionalUsers = (state: RootState) =>
  state.user?.subscribeChallenge?.subscriptionPlanDetails.additionalUsers;

export const selectOccupiedSeats = (state: RootState) =>
  state.user?.occupiedSeats;

export const selectBillingFrequency = (state: RootState) =>
  state.user?.billingFrequency;
