import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectListContainerSize } from '../../../search/redux/selectors';
import View from '../../../search/view/details/dwelling/view/View';
import { postReportDetail } from '../../redux/async_thunks';
import {
  selectDescriptors,
  selectDwelling,
  selectReportUser,
  selectSuburb,
} from '../../redux/selectors';

type Props = {
  setAreDetailsLoading?: any;
};

const Dwelling = ({ setAreDetailsLoading }: Props) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectReportUser);
  const suburb = useAppSelector(selectSuburb);
  const descriptor = useAppSelector(selectDescriptors);
  const dwelling = useAppSelector(selectDwelling);

  const listContainerSize = useAppSelector(selectListContainerSize);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Dwelling tab',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchDwellingDetails = async () => {
    toggleLoader(true);

    try {
      await dispatch(
        postReportDetail({
          data: {
            userId: user.userId,
          },
          options: {
            // @ts-ignore
            token: user.jwtToken.token,
            clientIp: user.clientIp,
            sessionId: user.sessionId,
          },
          endpoint: 'dwelling',
        }),
      )
        // @ts-ignore
        .unwrap();

      toggleLoader(false);
    } catch (error) {
      errorHandler();
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      dwelling={dwelling}
      // @ts-ignore
      descriptor={descriptor.dwelling}
      isFirstLoad={isFirstLoad}
      fetchDwellingDetails={fetchDwellingDetails}
      suburb={suburb}
      user={user}
      isLoading={isLoading}
      listContainerSize={listContainerSize}
    />
  );
};

export default Dwelling;
