import { createSlice } from '@reduxjs/toolkit';

import {
  postAiSuburbReport,
  postAiSuburbReportSuggestions,
} from './async_thunks';
import { AiReportState } from './types';

const initialState: AiReportState = {
  suggestions: [],
  queryValues: [],
  keyword: undefined,
  report: undefined,
};

export const aiReport = createSlice({
  name: 'aiReport',
  initialState,
  reducers: {
    setQueryValues: (state, action) => {
      state.queryValues = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = initialState.suggestions;
    },
    resetAiReport: (state) => {
      state.report = initialState.report;
      state.suggestions = initialState.suggestions;
      state.queryValues = initialState.queryValues;
      state.keyword = initialState.keyword;
    },
  },
  extraReducers(builder) {
    builder.addCase(postAiSuburbReport.fulfilled, (state, action) => {
      if (action.payload) {
        state.report = action.payload;
      } else {
        state.report = initialState.report;
      }
    });

    builder.addCase(postAiSuburbReport.rejected, (state) => {
      state.report = initialState.report;
    });

    builder.addCase(
      postAiSuburbReportSuggestions.fulfilled,
      (state, action) => {
        state.suggestions = action.payload;
      },
    );

    builder.addCase(postAiSuburbReportSuggestions.rejected, (state) => {
      state.suggestions = initialState.suggestions;
    });
  },
});

export const { setQueryValues, setKeyword, resetSuggestions, resetAiReport } =
  aiReport.actions;

export default aiReport.reducer;
