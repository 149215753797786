import { Modal, Tabs } from 'antd';
import {
  ArcElement,
  Chart as ChartJS,
  defaults,
  registerables,
} from 'chart.js';
import { merge } from 'lodash';
import React, { useState } from 'react';
// eslint-disable-next-line
import { Chart } from 'react-chartjs-2';

import Close from '../../../foundation/assets/svgs/Close';
import DisclaimerModal from '../../../foundation/components/static_content/disclaimer_modal/DisclaimerModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import SearchLoader from '../../search/form/search/search_loader/SearchLoader';
import { selectIsDisclaimerModalActive } from '../../search/redux/selectors';
import { setIsDisclaimerModalActive } from '../../search/redux/slice';
import { selectSuburb } from '../redux/selectors';
import Dwelling from './dwelling/Dwelling';
import KeyIndicators from './key_indicators/KeyIndicators';
import Population from './population/Population';
import PriceRentForecast from './price_rent_forecasts/PriceRentForecasts';
import Projects from './projects/Projects';

ChartJS.register(ArcElement);
ChartJS.register(...registerables);

merge(defaults, {
  color: '#696c6e',
  borderColor: '#f2f3f4',
  backgroundColor: [
    '#50C8FC', // Blue
    '#33B433', // Green
    '#EE6D00', // Orange
    '#FDCC62', // Yellow
    '#FF968F', // Pick
    '#651FFF', // Purple
  ],
});

merge(defaults.font, {
  size: 13,
  family: 'Poppins',
});

merge(defaults.plugins.tooltip, {
  backgroundColor: 'rgba(0, 0, 0, 0.78)',
  cornerRadius: 5,
  caretPadding: 3,
  caretSize: 7,
  padding: 15,
  displayColors: false,
  position: 'nearest',
});

merge(defaults.elements.arc, {
  borderWidth: 0,
});

const SuburbDetails = () => {
  const suburb = useAppSelector(selectSuburb);
  const dispatch = useAppDispatch();

  const [areDetailsLoading, setAreDetailsLoading] = useState(false);

  const isDisclaimerModalActive = useAppSelector(selectIsDisclaimerModalActive);

  const disclaimerModalCloseHandler = () => {
    dispatch(setIsDisclaimerModalActive(false));
  };

  const tabItems: any = [
    {
      label: 'Population',
      key: '1',
      children: (
        <div className="l-report-tab">
          <Population setAreDetailsLoading={setAreDetailsLoading} />
        </div>
      ),
    },
    {
      label: 'Dwelling',
      key: '2',
      children: (
        <div className="l-report-tab">
          <Dwelling setAreDetailsLoading={setAreDetailsLoading} />
        </div>
      ),
    },
    {
      label: (
        <span>
          Key
          <br />
          Indicators
        </span>
      ),
      key: '3',
      children: (
        <div className="l-report-tab">
          <KeyIndicators setAreDetailsLoading={setAreDetailsLoading} />
        </div>
      ),
    },
    {
      label: (
        <span>
          Price & Rent
          <br />
          Forecasts
        </span>
      ),
      key: '4',
      children: (
        <div className="l-report-tab">
          <PriceRentForecast setAreDetailsLoading={setAreDetailsLoading} />
        </div>
      ),
    },
    {
      label: 'Projects',
      key: '5',
      children: (
        <div className="l-report-tab">
          <Projects setAreDetailsLoading={setAreDetailsLoading} />
        </div>
      ),
    },
  ];

  return (
    <>
      {isDisclaimerModalActive && (
        <Modal
          wrapClassName={''}
          closable={true}
          footer={false}
          maskClosable={false}
          centered
          title={'Disclaimer'}
          open={true}
          onCancel={disclaimerModalCloseHandler}
          closeIcon={
            <strong className="l-filter-modal__close">
              <Close />
            </strong>
          }
          width={700}
          className="c-privacy-modal"
        >
          <DisclaimerModal />
        </Modal>
      )}
      <div className="l-report-details">
        <div className="l-report-details__content">
          {!!suburb && areDetailsLoading && <SearchLoader />}
          {!!tabItems.length && (
            <Tabs
              defaultActiveKey="1"
              centered={true}
              moreIcon={null}
              // onChange={(activeKey) => {
              //   setActiveTabState(activeKey);
              // }}
              items={tabItems}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SuburbDetails;
