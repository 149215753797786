import React from 'react';

function Building() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4L0.842853 3.26665L0.25 3.39369V4H1ZM15 23V23.75V23ZM1 23H0.25V23.75H1V23ZM12.5809 1.51837L12.7381 2.25172L12.5809 1.51837ZM6 19V18.25H5.25V19H6ZM10 19H10.75V18.25H10V19ZM10 23V23.75H10.75V23H10ZM6 23H5.25V23.75H6V23ZM15 5V4.25C14.5858 4.25 14.25 4.58579 14.25 5H15ZM23 5H23.75C23.75 4.58579 23.4142 4.25 23 4.25V5ZM23 23V23.75C23.4142 23.75 23.75 23.4142 23.75 23H23ZM1.15715 4.73336L12.7381 2.25172L12.4238 0.785019L0.842853 3.26665L1.15715 4.73336ZM14.25 3.47398V23H15.75V3.47398H14.25ZM15 22.25H1V23.75H15V22.25ZM1.75 23V4H0.25V23H1.75ZM12.7381 2.25172C13.5162 2.08498 14.25 2.67817 14.25 3.47398H15.75C15.75 1.7232 14.1357 0.418181 12.4238 0.785019L12.7381 2.25172ZM6 19.75H10V18.25H6V19.75ZM9.25 19V23H10.75V19H9.25ZM10 22.25H6V23.75H10V22.25ZM6.75 23V19H5.25V23H6.75ZM19 14.75H23V13.25H19V14.75ZM19 10.75H23V9.25H19V10.75ZM19 18.75H23V17.25H19V18.75ZM15 5.75H23V4.25H15V5.75ZM22.25 5V23H23.75V5H22.25ZM23 22.25L15 22.25V23.75L23 23.75V22.25ZM15.75 23V5H14.25V23H15.75ZM18.25 0V5H19.75V0H18.25ZM4 15.75H7V14.25H4V15.75ZM9 15.75H12V14.25H9V15.75ZM9 7.75H12V6.25H9V7.75ZM4 7.75H7V6.25H4V7.75ZM4 11.75H7V10.25H4V11.75ZM9 11.75H12V10.25H9V11.75Z"
        fill="#00ADD2"
      />
    </svg>
  );
}

export default Building;
