import React from 'react';

function UnorderedList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10"
      fill="none"
      viewBox="0 0 13 10"
    >
      <path stroke="#AFAFAF" d="M13 1H5M13 5H5M13 9H5"></path>
      <circle cx="1" cy="1" r="0.5" fill="#AFAFAF" stroke="#AFAFAF"></circle>
      <circle cx="1" cy="5" r="0.5" fill="#AFAFAF" stroke="#AFAFAF"></circle>
      <circle cx="1" cy="9" r="0.5" fill="#AFAFAF" stroke="#AFAFAF"></circle>
    </svg>
  );
}

export default UnorderedList;
