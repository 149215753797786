import { CometChat } from '@cometchat-pro/chat';
import { Button, notification } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DebounceAjaxSelect from '../../../../foundation/components/debonce_ajax_select/DebounceAjaxSelect';
import ErrorField from '../../../../foundation/components/error_field/ErrorField';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import TextArea from '../../../../foundation/components/textarea/TextArea';
import messages from '../../../../foundation/messages';
import { useAppDispatch } from '../../../../store/hooks';
import { getDirectMessagesList } from '../../helpers/conversationHelpers';
import fetchUsersByName from '../../helpers/fetchUsersByName';
import { selectccUser } from '../../redux/selectors';
import { setDirectMessagesList } from '../../redux/slice';
import { sendMessage } from '../../utils/controllers/messageController';
import DMValidationSchema from './validation_schema';

type DirectMessageProps = {
  closeModal?: () => any;
};

const DirectMessage = ({ closeModal }: DirectMessageProps) => {
  const dispatch = useAppDispatch();

  const [isCreateMessageFormLoading, setCreateMessageFormLoading] =
    useState(false);

  const ccUser = useSelector(selectccUser);

  const handleFormSubmit = async (values: any) => {
    if (isCreateMessageFormLoading) {
      return;
    }

    try {
      setCreateMessageFormLoading(true);

      await sendMessage(
        values.message,
        values?.name?.value,
        CometChat.RECEIVER_TYPE.USER,
        ccUser,
      );

      const messageList = await getDirectMessagesList();

      dispatch(setDirectMessagesList(messageList));

      setCreateMessageFormLoading(false);

      if (closeModal) {
        closeModal();
      }

      notification.success({
        message: 'Success!',
        description: messages.chat_messages.SUCCESS_SENDING_MESSAGE,
      });
    } catch (error) {
      setCreateMessageFormLoading(false);
      if (closeModal) {
        closeModal();
      }
    }
  };

  return (
    <div className="l-create-channel-modal">
      {isCreateMessageFormLoading && <FullPageLoader />}
      <div className="l-create-channel-modal__info">
        Type in the name of the person you want to share this message with.
      </div>
      <Formik
        initialValues={{
          name: { label: '', value: '' },
          message: '',
        }}
        onSubmit={handleFormSubmit}
        validationSchema={DMValidationSchema}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="l-direct-message-modal__ajax-select-label">
                Name
              </div>
              <DebounceAjaxSelect
                value={[values.name]}
                // @ts-ignore
                fetchOptions={fetchUsersByName}
                onChange={(v) => setFieldValue('name', v)}
                className="l-direct-message-modal__ajax-select"
              />
              <ErrorField
                error={errors && errors.name?.value}
                style={{ marginBottom: 20 }}
              />
              <TextArea
                label="Message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                name="message"
                wrapperClassName="l-create-channel-modal__field-wrapper"
                error={errors && errors.message}
                rows={4}
              />

              <Button
                htmlType="submit"
                type="primary"
                className="l-create-channel-modal__submit-btn"
                disabled={!(isValid && dirty) || isCreateMessageFormLoading}
              >
                Send
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DirectMessage;
