import React from 'react';

function Dwelling() {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 9.68301C1.33301 8.72946 1.78632 7.8327 2.55416 7.26729L10.4997 1.4165L18.4452 7.26729C19.213 7.8327 19.6663 8.72946 19.6663 9.68301V19.0832C19.6663 20.4639 18.5471 21.5832 17.1663 21.5832H14.2497C13.6974 21.5832 13.2497 21.1355 13.2497 20.5832V15.2082C13.2497 14.932 13.0258 14.7082 12.7497 14.7082H8.24967C7.97353 14.7082 7.74967 14.932 7.74967 15.2082V20.5832C7.74967 21.1355 7.30196 21.5832 6.74967 21.5832H3.83301C2.45229 21.5832 1.33301 20.4639 1.33301 19.0832V9.68301Z"
        stroke="#A0A3BD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dwelling;
