import React from 'react';

const Bed = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 18V20M2 18V20M22 13V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V13H22ZM23 18V15C23 13.8954 22.1046 13 21 13H3C1.89543 13 1 13.8954 1 15V18H23ZM6.5 13H9.5C10.0523 13 10.5 12.5523 10.5 12V10C10.5 9.44772 10.0523 9 9.5 9H6.5C5.94772 9 5.5 9.44772 5.5 10V12C5.5 12.5523 5.94772 13 6.5 13ZM14.5 13H17.5C18.0523 13 18.5 12.5523 18.5 12V10C18.5 9.44772 18.0523 9 17.5 9H14.5C13.9477 9 13.5 9.44772 13.5 10V12C13.5 12.5523 13.9477 13 14.5 13Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bed;
