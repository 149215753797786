import { RootState } from '../../../store';

export const selectAllAppRoles = (state: RootState) => state.account.allRoles;

export const selectInfo = (state: RootState) => state.account.info;

export const selectOrganization = (state: RootState) =>
  state.account.info?.organization;

export const selectAccountSubscriptionId = (state: RootState) =>
  state.account.info?.subscription.subscriptionId;

export const selectAccountCurrentPlan = (state: RootState) =>
  state.account.info?.subscription.currentPlanDetails;

export const selectAccountSubscriptionPlans = (state: RootState) =>
  state.account.info?.subscription.subscriptionPlans;

export const selectAccountCanEdit = (state: RootState) =>
  state.account.info?.canEdit;

export const selectAccountMembers = (state: RootState) =>
  state.account.info?.members?.members;

export const selectAccountClients = (state: RootState) =>
  state.account.info?.members?.clients;

export const selectAccountMembersAvailableSlots = (state: RootState) =>
  state.account.info?.members?.availableSlots;

export const selectAccountPaymentMethod = (state: RootState) => {
  if (
    state.account.info?.subscription?.paymentMethods &&
    state.account.info?.subscription?.paymentMethods[0]
  ) {
    return state.account.info?.subscription?.paymentMethods[0];
  }
};
