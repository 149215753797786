import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

// Typescript Props
type Props = {
  children: any;
};

const PublicRoute = ({ children }: Props): JSX.Element => {
  const { sscCode } = useParams();
  const location = useLocation();

  if (sessionStorage.getItem('_u')) {
    return (
      <Navigate
        to="/suburb-search"
        replace
        state={{
          from: location,
          sscCode: sscCode,
        }}
      />
    );
  } else {
    return children;
  }
};

// PropTypes
PublicRoute.propTypes = {
  children: PropTypes.any,
};

export default PublicRoute;
