import { CometChat } from '@cometchat-pro/chat';
import { notification } from 'antd';

import appMessages from '../../../../foundation/messages';

const groupLimit = 100;
export const fetchNextChannels = (searchKey: string) => {
  let groupRequest = null;

  if (searchKey) {
    // By default it returns public groups only.
    groupRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(groupLimit)
      .setSearchKeyword(searchKey)
      .build();
  } else {
    groupRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(groupLimit)
      .build();
  }
  return groupRequest.fetchNext();
};

export const fetchAllChannels = () => {
  let groupRequest = null;

  groupRequest = new CometChat.GroupsRequestBuilder()
    .setLimit(groupLimit)
    .build();

  return groupRequest.fetchNext();
};

export const fetchJoinedChannels = (searchKey: string) => {
  let groupRequest = null;

  if (searchKey) {
    groupRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(15)
      .setSearchKeyword(searchKey)
      .joinedOnly(true)
      .build();
  } else {
    groupRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(15)
      .joinedOnly(true)
      .build();
  }

  return groupRequest.fetchNext();
};

export const leaveChannel = (guid: string) => {
  return new Promise((resolve, reject) => {
    CometChat.leaveGroup(guid)
      .then((hasLeft) => {
        resolve(hasLeft);
      })
      .catch((error) => {
        notification.error({
          description: appMessages.generic.SOMETHING_WENT_WRONG,
          message: 'Error!',
        });

        reject(error);
      });
  });
};

export const fetchBannedMembers = (guid: string) => {
  const groupRequest = new CometChat.BannedMembersRequestBuilder(guid)
    .setLimit(15)
    .build();
  return groupRequest.fetchNext();
};
