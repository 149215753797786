import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Divider, Input, message, Modal } from 'antd';
import React, { useMemo, useState } from 'react';

import CheckOutlined from '../../../../foundation/assets/svgs/CheckOutlined';
import ErrorField from '../../../../foundation/components/error_field/ErrorField';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Privacy from '../../../../foundation/components/static_content/privacy/Privacy';
import Terms from '../../../../foundation/components/static_content/terms/Terms';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { PromoCodeDetailsType } from '../../../billing/helpers/types';
import { checkPromoCode } from '../../../billing/redux/async_thunks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import { selectAccountCurrentPlan } from '../../redux/selectors';
import { Plan } from '../../redux/types';
import { UpdateOrderSteps, UpgradeTypes } from '../../types';

const { confirm } = Modal;

type OrderSummaryProps = {
  selectedPlan: Plan | undefined;
  invoice: any;
  setPromoCodeDetails?: (v: PromoCodeDetailsType | undefined) => void;
  promoCodeDetails?: PromoCodeDetailsType | undefined;
  handleInvoice: (
    plan: any,
    code?: string,
    delPromo?: boolean,
  ) => Promise<unknown>;
  setUpdateStep: (v: UpdateOrderSteps) => void;
  updateStep: UpdateOrderSteps;
  handleSubscription: () => void;
  upgradeType: UpgradeTypes;
  additionalSeats?: number;
};

const OrderSummary = ({
  selectedPlan,
  invoice,
  setPromoCodeDetails,
  promoCodeDetails,
  handleInvoice,
  updateStep,
  setUpdateStep,
  handleSubscription,
  upgradeType,
  additionalSeats,
}: OrderSummaryProps) => {
  const dispatch = useAppDispatch();

  const [isOrderSummaryLoading, setOrderSummaryLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<undefined | string>(undefined);
  const [promoError, setPromoError] = useState<undefined | string>(undefined);

  const [isTermsVisible, setTermsVisibility] = useState(false);
  const [isPrivacyModalVisible, setPrivacyModalVisibility] = useState(false);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  const subscriptionFrequency = useMemo(
    () => currentPlan?.frequency,
    [currentPlan],
  );

  const frequencyString = useMemo(
    () =>
      subscriptionFrequency && subscriptionFrequency === 'Annually'
        ? 'annum'
        : 'month',
    [subscriptionFrequency],
  );

  const onPromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoError(undefined);
    setPromoCode(e.target.value);
  };

  const applyPromoCodeHandler = async () => {
    if (isOrderSummaryLoading) {
      return;
    }
    try {
      if (user && promoCode) {
        const data = {
          userId: user?.userId,
          promoCode,
        };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        setOrderSummaryLoading(true);
        const promoCodeDetails = await dispatch(
          checkPromoCode({ data, options }),
        ).unwrap();

        if (!promoCodeDetails?.isValid) {
          setPromoError('Invalid promo code.');
        } else {
          if (setPromoCodeDetails) {
            setPromoCodeDetails(promoCodeDetails);
          }

          await handleInvoice(selectedPlan, promoCodeDetails.promoCode);
          message.success('Promo code applied');
        }
        setOrderSummaryLoading(false);
      }
    } catch (error: any) {
      if (setPromoCodeDetails) {
        setPromoCodeDetails(undefined);
      }
      setOrderSummaryLoading(false);
      if (error?.message && error?.message.indexOf('Coupon') > -1) {
        setPromoError(error?.message);
      }
    }
  };

  const handleRemovePromoCode = async () => {
    setOrderSummaryLoading(true);
    if (setPromoCodeDetails) {
      setPromoCodeDetails(undefined);
    }
    setPromoCode(undefined);
    setPromoError(undefined);

    await handleInvoice(selectedPlan, undefined, true);
    setOrderSummaryLoading(false);
  };

  const showRemovePromoConfirm = () => {
    confirm({
      title: 'Do you want to remove the promo code?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        handleRemovePromoCode();
      },
    });
  };

  const handleSummaryBtnClick = async () => {
    if (isOrderSummaryLoading) {
      return;
    }

    if (upgradeType === 'plan-update') {
      if (updateStep === 1) {
        setUpdateStep(2);
      } else {
        handleSubscription();
      }
    } else {
      if (updateStep === 1) {
        try {
          setOrderSummaryLoading(true);
          setUpdateStep(2);
          await handleInvoice(selectedPlan);
          setOrderSummaryLoading(false);
        } catch (error) {
          console.log(error);
          setOrderSummaryLoading(false);
        }
      } else if (updateStep === 2) {
        setUpdateStep(3);
      } else {
        handleSubscription();
      }
    }
  };

  const orderSummaryBtnLabel = useMemo(() => {
    if (upgradeType === 'plan-update') {
      if (updateStep === 1) {
        return `Next: Payment Information`;
      } else {
        return `Pay Securely`;
      }
    } else {
      if (updateStep === 1) {
        return `Update order`;
      } else if (updateStep === 2) {
        return `Next: Payment Information`;
      } else {
        return `Pay Securely`;
      }
    }
  }, [updateStep]);

  const isSummaryBtnDisabled = useMemo(() => {
    if (selectedPlan) {
      if (upgradeType === 'plan-update' && currentPlan) {
        if (
          selectedPlan?.subscriptionPlanId === currentPlan?.subscriptionPlanId
        ) {
          return true;
        } else {
          return false;
        }
      } else if (upgradeType === 'seats-update') {
        if (
          !additionalSeats ||
          (additionalSeats && selectedPlan.additionalUsers === additionalSeats)
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  }, [selectedPlan, currentPlan, additionalSeats, upgradeType]);

  const calculatedAddSeatsCost = useMemo(() => {
    if (subscriptionFrequency === 'Annually' && additionalSeats) {
      return additionalSeats * 600;
    } else if (additionalSeats) {
      return additionalSeats * 50;
    }
    return 0;
  }, [subscriptionFrequency, additionalSeats]);

  const sharedModalProps = {
    wrapClassName: '',
    closable: true,
    footer: null as any,
    maskClosable: false,
    centered: true,
    open: true,
  };

  const privacyModalOpts = {
    title: 'Privacy Policy',
    onCancel: () => {
      closeModal();
    },
    ...sharedModalProps,
  };

  const termsModalOpts = {
    title: 'Terms & Conditions',
    onCancel: () => {
      closeModal();
    },
    ...sharedModalProps,
  };

  const closeModal = () => {
    setTermsVisibility(false);
    setPrivacyModalVisibility(false);
  };

  const handlePrivacyClick = () => {
    closeModal();
    setPrivacyModalVisibility((v) => !v);
  };

  const handleTermsClick = (e: any) => {
    e.preventDefault();
    closeModal();
    setTermsVisibility((v) => !v);
  };

  return (
    <>
      {isPrivacyModalVisible && (
        <Modal {...privacyModalOpts} width={700} className="c-privacy-modal">
          <Privacy />
        </Modal>
      )}
      {isTermsVisible && (
        <Modal {...termsModalOpts} width={700} className="c-privacy-modal">
          <Terms privacyPolicyCallback={handlePrivacyClick} />
        </Modal>
      )}
      <div className="l-update-order-summary ">
        {isOrderSummaryLoading && <FullPageLoader />}
        <div className="l-update-order-summary__heading">Order Summary</div>
        <Divider />
        {selectedPlan && (
          <>
            <div className="l-update-order-summary__plan-row">
              <div>
                {selectedPlan.subscriptionPlan}
                <span className="l-update-order-summary__frequency">{` (${
                  frequencyString === 'annum' ? 'annual' : 'monthly'
                })`}</span>
              </div>
              <div>
                ${selectedPlan.subscriptionPlanCost} per {frequencyString}
              </div>
            </div>
            {additionalSeats ? (
              <div className="l-update-order-summary__plan-row">
                <div>{additionalSeats} x Additional Seats</div>
                <div>
                  ${calculatedAddSeatsCost} per {frequencyString}
                </div>
              </div>
            ) : null}
            <Divider />
          </>
        )}

        {invoice && (
          <>
            <div className="l-update-order-summary__prorate-row">
              <div>Prorated cost for upgrade</div>
              <div>${invoice.subTotal}</div>
            </div>
          </>
        )}

        {promoCodeDetails && upgradeType === 'plan-update' && (
          <div
            className="l-update-order-summary__plan-row"
            style={{ marginTop: 10 }}
          >
            <div className="l-update-order-summary__promo-wrapper">
              <CheckOutlined />
              Promo Code{' '}
              <span className="l-update-order-summary__frequency">{` (${promoCodeDetails?.promoCode})`}</span>
              <span>
                <DeleteOutlined
                  className="l-billing-summary__promo-del-icon"
                  onClick={showRemovePromoConfirm}
                />
              </span>
            </div>
          </div>
        )}

        {!promoCodeDetails && upgradeType === 'plan-update' && (
          <>
            <Divider />
            <div className="l-billing-summary__promo-section">
              <div style={{ marginBottom: 10 }}>Optional promo code </div>
              <div
                className="l-billing-summary__promo-field-wrapper"
                style={{ width: '100%' }}
              >
                <div style={{ width: '100%' }}>
                  <Input
                    placeholder="Enter code"
                    className="l-billing-summary__promo-field"
                    style={{ width: '100%' }}
                    value={promoCode}
                    onChange={onPromoCodeChange}
                    status={promoError ? 'error' : undefined}
                  />
                  <ErrorField error={undefined} />
                </div>
                <div>
                  <Button
                    className="l-billing-summary__promo-btn"
                    onClick={applyPromoCodeHandler}
                  >
                    Apply code
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}

        {invoice && (
          <>
            <Divider />
            <div
              className="l-update-order-summary__plan-row"
              style={{ marginBottom: 15 }}
            >
              <div>{`Total (incl. tax)`}</div>
              <div>${invoice.total}</div>
            </div>
          </>
        )}

        <div className="l-update-order-summary__footer-message">
          {upgradeType === 'plan-update' &&
            `*Plan upgrades are calculated pro rata and effective immediately after
        payment.`}

          {upgradeType === 'seats-update' &&
            `*Additional seats are calculated pro rata and added immediately to your plan after payment. `}

          {upgradeType === 'plan-update' && (
            <>
              <br />
              <br />
              *By upgrading you agree to our{' '}
              <a href="#" onClick={handleTermsClick}>
                Term of Services
              </a>
              .
            </>
          )}
        </div>

        <Button
          className="l-update-order-summary__btn"
          type="primary"
          disabled={isSummaryBtnDisabled}
          onClick={handleSummaryBtnClick}
        >
          {orderSummaryBtnLabel}
        </Button>
      </div>
    </>
  );
};

export default OrderSummary;
