import { Button } from 'antd';
import React from 'react';

import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectChannelsList } from '../redux/selectors';
import { setSelectedConversation } from '../redux/slice';
import { setChatWindowType } from '../redux/slice';

type ChannelListItemProps = {
  channel: any;
  channelJoinHandler: (guid: string, type: string) => any;
};

const ChannelListItem = ({
  channel,
  channelJoinHandler,
}: ChannelListItemProps) => {
  const dispatch = useAppDispatch();

  const conversations = useAppSelector(selectChannelsList);
  const [, isDesktopView] = useBreakpoint();

  const {
    guid,
    name: channelName,
    hasJoined: isMember,
    type,
    membersCount,
  } = channel;

  const handleChannelJoin = () => {
    channelJoinHandler(guid, type);
  };

  const handleChannelSelect = () => {
    if (isMember) {
      const conversation = conversations.find((item: any) => {
        return item.conversationWith.guid === channel.guid;
      });

      dispatch(setSelectedConversation({ ...conversation }));

      if (isDesktopView) {
        dispatch(setChatWindowType('chat-window'));
      }
    }
  };

  return (
    <div className="l-chat-browse-channels__list-item">
      <div className="l-chat-browse-channels__list-item-detail">
        <div
          className={`l-chat-browse-channels__list-item-title ${
            isMember ? 'l-chat-browse-channels__list-item-title--member' : ''
          }`}
          onClick={handleChannelSelect}
        >
          {channelName}
        </div>
        <div className="l-chat-browse-channels__list-item-details">
          {isMember && (
            <div>
              <span style={{ color: '#008B06', fontWeight: 600 }}>
                {'Joined'}
              </span>{' '}
              <span style={{ margin: '0px 4px' }}>{`-`}</span>
            </div>
          )}
          <div>
            {membersCount} member{membersCount > 1 ? 's' : ''}
          </div>
        </div>
      </div>
      <div className="l-chat-browse-channels__list-item-actions">
        {!isMember && (
          <Button
            type="primary"
            className="l-chat-browse-channels__list-item-join-btn"
            onClick={handleChannelJoin}
          >
            Join
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChannelListItem;
