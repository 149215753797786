import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Logo from '../../../foundation/assets/svgs/TextLogo';
import InviteTeam from '../components/invite_team/InviteTeam';
import OnboardSuccess from '../components/onboard_success/OnboardSuccess';
import OrganizationSettings from '../components/organization_settings/OrganizationSettings';
import { InviteType, StepTypes } from '../types';

type OnboardingProps = {
  handleCancel: () => void;
};

const Onboarding = ({ handleCancel }: OnboardingProps) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<StepTypes>('company');

  const [allInvites, setAllInvites] = useState<InviteType[]>([]);

  const modalProps = {
    wrapClassName: 'l-onboarding',
    closable: false,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 650,
    onCancel: handleCancel,
  };

  const handleSkip = () => {
    if (step === 'company') {
      setStep('team');
    } else if (step === 'team') {
      navigate('/');
    }
  };

  return (
    <Modal {...modalProps}>
      {step === 'company' && (
        <div className="l-onboarding__section">
          <div className="l-onboarding__section-title">
            Welcome to <Logo />
          </div>
          <div className="l-onboarding__section-sub-title">
            Tell us about your team or company
          </div>
          <OrganizationSettings
            handleSkip={handleSkip}
            setStep={setStep}
            type="onboarding"
          />
        </div>
      )}
      {step === 'team' && (
        <div className="l-onboarding__section">
          <div className="l-onboarding__section-title">Invite users</div>

          <div className="l-onboarding__section-info">
            Invite users to your company. Make sure to carefully select their
            permissions. <br />
            <br />
            Granting admin rights allows access to critical company settings,
            including payment methods and user management. By choosing the
            appropriate level of access for each user, you can ensure that your
            team operates efficiently and securely.
          </div>

          <InviteTeam
            handleSkip={handleSkip}
            setStep={setStep}
            setAllInvites={setAllInvites}
            canSkip={true}
            type="onboarding"
          />
        </div>
      )}

      {step === 'success' && (
        <OnboardSuccess handleCancel={handleCancel} allInvites={allInvites} />
      )}
    </Modal>
  );
};

export default Onboarding;
