import React from 'react';
import { useSelector } from 'react-redux';

import { getGraphByType, getGraphIsLoadingByKey } from '../../redux/selectors';
import View from './view/View';

const LineChart = ({
  graphType,
  isXUnitsInMonths,
  yMaxTicksLimit,
  style,
  isProjects,
}: any) => {
  const graphData = useSelector(getGraphByType(graphType));
  const isLoading = useSelector(getGraphIsLoadingByKey(graphType));

  return (
    <View
      isXUnitsInMonths={isXUnitsInMonths}
      yMaxTicksLimit={yMaxTicksLimit}
      style={style}
      isProjects={isProjects}
      graphType={graphType}
      graphData={graphData}
      isLoading={isLoading}
    />
  );
};

export default LineChart;
