import { Modal, Tabs } from 'antd';
import React from 'react';

import GroupMembers from '../../group_members/GroupMembers';
import ChannelOptions from '../ChannelOptions';

const ChannelModal = ({
  members,
  conversation,
  cancelHandler,
  fetchMembers,
  channelOwner,
  activeKey,
  removeMemberFromChannelMembers,
  updateScopeFromChannelMembers,
}: any) => {
  return (
    <Modal
      title={conversation.conversationWith.name}
      open={true}
      footer={false}
      onCancel={() => {
        cancelHandler();
      }}
      centered
      maskClosable={false}
      width={455}
    >
      <Tabs
        defaultActiveKey={activeKey || 'about'}
        className="c-favorite-tabs"
        centered
        tabBarStyle={{
          width: '100%',
        }}
        items={[
          {
            label: `About`,
            key: 'about',
            children: (
              <ChannelOptions
                conversation={conversation}
                cancelHandler={cancelHandler}
                channelOwner={channelOwner}
              />
            ),
          },
          {
            label: `Members`,
            key: 'members',
            children: (
              <GroupMembers
                members={members}
                conversation={conversation}
                cancelHandler={cancelHandler}
                fetchMembers={fetchMembers}
                channelOwner={channelOwner}
                removeMemberFromChannelMembers={removeMemberFromChannelMembers}
                updateScopeFromChannelMembers={updateScopeFromChannelMembers}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default ChannelModal;
