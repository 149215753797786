import { useAuth0 } from '@auth0/auth0-react';
import { Button, Result } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';

import CheckOutlined from '../../../../foundation/assets/svgs/CheckOutlined';
import ExclamationOutlined from '../../../../foundation/assets/svgs/ExclamantionOutlined';

const LogoutSuccess = () => {
  const { loginWithRedirect } = useAuth0();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const isDelete = query.get('d');
  const isFailed = query.get('f');
  const email = query.get('e');

  const handleLoginClick = () => {
    loginWithRedirect();
  };

  if (email) {
    return (
      <div className="l-custom-result">
        <div className="l-custom-result__content">
          <div className="l-custom-result__icon">
            <CheckOutlined />
          </div>
          <h4>Email Sent!</h4>
          <p>
            Please check <strong>{decodeURI(email)}</strong> for a link to reset
            your password.
          </p>
          <button
            type="button"
            className="l-custom-result__button"
            onClick={handleLoginClick}
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  if (isDelete) {
    return (
      <div className="l-custom-result">
        <div className="l-custom-result__content">
          <div className="l-custom-result__icon">
            <ExclamationOutlined />
          </div>
          <h4>Deleting Account?</h4>
          <p>You&apos;ve requested to delete your account.</p>
          <p>Kindly continue the process via your email.</p>
          <button
            type="button"
            className="l-custom-result__button"
            onClick={handleLoginClick}
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  if (isFailed) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle={
          <p>
            Sorry for the inconvenience.
            <br />
            Something went wrong during account setup.
          </p>
        }
        extra={
          <Button type="primary" onClick={handleLoginClick}>
            Re-login
          </Button>
        }
      />
    );
  }

  return (
    <Result
      status="success"
      title="Logout"
      subTitle="You have been successfully logged out."
      extra={
        <Button type="primary" onClick={handleLoginClick}>
          Start Again
        </Button>
      }
    />
  );
};

export default LogoutSuccess;
