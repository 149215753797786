import { Avatar, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectAvailablePlans,
  selectBillingFrequency,
  selectClientIp,
  selectOccupiedSeats,
  selectStripeCustomerId,
  selectSubscriptionPlanDetails,
  selectUser,
} from '../user/redux/selectors';
import { SubscriptionPlan } from '../user/redux/types';
import {
  BillingTabKeys,
  InvoiceType,
  PromoCodeDetailsType,
  SubscriptionFrequency,
} from './helpers/types';
import PaymentInformation from './payment_information/PaymentInformation';
import PlanInformation from './plan_information/PlanInformation';
import { createCustomer } from './redux/async_thunks';

const Billing = () => {
  const [activeTab, setActiveTab] = useState<BillingTabKeys>('info');
  const [invoiceData, setInvoiceData] = useState<InvoiceType | undefined>(
    undefined,
  );
  const [isBillingLoading, setIsBillingLoading] = useState(true);
  const [subscriptionFrequency, setSubscriptionFrequency] =
    useState<SubscriptionFrequency>('Monthly');
  const [promoCodeDetails, setPromoCodeDetails] = useState<
    PromoCodeDetailsType | undefined
  >(undefined);

  const availablePlans = useAppSelector(selectAvailablePlans);

  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >(availablePlans ? availablePlans[availablePlans.length - 1] : undefined);

  const [additionalUsers, setAdditionalUsers] = useState<number>(0);

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const clientIp = useAppSelector(selectClientIp);

  const subscriptionPlanDetails = useAppSelector(selectSubscriptionPlanDetails);
  const occupiedSeats = useAppSelector(selectOccupiedSeats);
  const previousBillingFrequency = useAppSelector(selectBillingFrequency);

  const customerId = useAppSelector(selectStripeCustomerId);
  const navigate = useNavigate();

  const tabItems = [
    {
      name: 'Confirm plan & seats',
      content: PlanInformation,
      tabKey: 'info',
    },
    {
      name: 'Payment information',
      content: PaymentInformation,
      tabKey: 'payment',
    },
  ];

  const handleStripeCustomer = async () => {
    try {
      if (!customerId) {
        const data = { userId: user.userId };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        await dispatch(
          createCustomer({
            data,
            options,
          }),
        ).unwrap();
      }

      setIsBillingLoading(false);
    } catch (error) {
      console.log(error);
      setIsBillingLoading(false);
    }
  };

  useEffect(() => {
    if (user.userRole !== 9) {
      navigate('/unauthorized');
    }
  }, []);

  useEffect(() => {
    handleStripeCustomer();
  }, []);

  useEffect(() => {
    if (subscriptionPlanDetails && availablePlans) {
      if (subscriptionPlanDetails && subscriptionPlanDetails.frequency) {
        setSubscriptionFrequency(subscriptionPlanDetails.frequency);
      } else if (previousBillingFrequency) {
        const frequency: SubscriptionFrequency =
          previousBillingFrequency as SubscriptionFrequency;

        setSubscriptionFrequency(frequency);
      }

      // The subscriptionPlanDetails is populated when user clicks on the "Next: Payment Information" button.
      // If populated, it will persist (when user refreshes the page)
      // but will for some reason revert back to null after some time.
      // This is true during the subscription renewal process as well.
      if (subscriptionPlanDetails && subscriptionPlanDetails.additionalUsers) {
        // Always use the additionalUsers value if existing
        // and if more than the occupiedSeats value
        if (subscriptionPlanDetails.additionalUsers < occupiedSeats) {
          setAdditionalUsers(occupiedSeats);
        } else {
          setAdditionalUsers(subscriptionPlanDetails.additionalUsers);
        }
      } else if (occupiedSeats) {
        // Use occupiedSeats if existing and if additionalUsers is falsy
        setAdditionalUsers(occupiedSeats);
      }

      for (const item of availablePlans) {
        if (
          item.subscriptionPlanId === subscriptionPlanDetails.subscriptionPlanId
        ) {
          setSelectedPlan(item);
          break;
        }
      }
    }
  }, [subscriptionPlanDetails, availablePlans]);

  const handleTabChange = (activeKey: any) => {
    if (activeKey === 'info' && activeTab === 'payment') {
      setActiveTab(activeKey);
    } else {
      return;
    }
  };

  return (
    <div className="l-billing">
      {isBillingLoading && <FullPageLoader />}
      <Tabs
        centered
        activeKey={activeTab}
        onChange={handleTabChange}
        items={tabItems.map((item, i) => {
          const id = String(i + 1);

          return {
            label: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar className="l-billing__tab-icon">{id}</Avatar>
                <span style={{ marginLeft: 10 }} className="l-billing__tab">
                  {item.name}
                </span>
              </span>
            ),
            key: item.tabKey,
            children: (
              <item.content
                subscriptionFrequency={subscriptionFrequency}
                setSubscriptionFrequency={setSubscriptionFrequency}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                isBillingLoading={isBillingLoading}
                setIsBillingLoading={setIsBillingLoading}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setPromoCodeDetails={setPromoCodeDetails}
                promoCodeDetails={promoCodeDetails}
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
                additionalUsers={additionalUsers}
                setAdditionalUsers={setAdditionalUsers}
              />
            ),
          };
        })}
      />
    </div>
  );
};

export default Billing;
