import React from 'react';

const MousePointer = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.246 4.50713L1.69116 1.35809L4.8402 9.9129L6.76282 7.99027L8.71346 9.94091L10.274 8.3804L8.32334 6.42976L10.246 4.50713Z"
        stroke="#23005C"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default MousePointer;
