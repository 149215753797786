import React from 'react';

import { useAppSelector } from '../../../../../store/hooks';
import {
  selectActiveSuburb,
  selectListContainerSize,
} from '../../../redux/selectors';
import { selectDescriptors, selectPopulation } from '../redux/selectors';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const Population = ({ setAreDetailsLoading }: Props) => {
  const population = useAppSelector(selectPopulation);
  const SUBURB = useAppSelector(selectActiveSuburb);
  const listContainerSize = useAppSelector(selectListContainerSize);
  const descriptor = useAppSelector(selectDescriptors);

  return (
    <View
      population={population}
      suburb={SUBURB}
      listContainerSize={listContainerSize}
      descriptor={descriptor}
      setAreDetailsLoading={setAreDetailsLoading}
    />
  );
};

export default Population;
