import React from 'react';

import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { useAppSelector } from '../../../store/hooks';
import { selectNotifications } from '../redux/selectors';

type Props = {
  handlePopoverVisibility?: (v: any) => void;
};

const Notification = ({ handlePopoverVisibility }: Props) => {
  const [isMobileView] = useBreakpoint();

  const notifications = useAppSelector(selectNotifications);

  return (
    <div className="c-notification">
      {!isMobileView && (
        <div className="c-notification__heading">Notifications</div>
      )}
      <div className="c-notification__content">
        {notifications?.map((notification: any, index: number) => (
          <div className="c-notification__list-item" key={index}>
            <div className="c-notification__list-item-left-side">
              <div className="c-notification__list-icon">
                <span></span>
              </div>
              <div>
                <div className="c-notification__list-main-title">
                  {notification.title}
                </div>
                <div className="c-notification__list-sub-title">
                  {notification.body}
                </div>
              </div>
            </div>
          </div>
        ))}
        {(!notifications || !notifications.length) && (
          <p className="c-notification__empty">
            <strong>Empty</strong>
            <span>No notifications at the moment.</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Notification;
