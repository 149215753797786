import { Popover, Select } from 'antd';
import React from 'react';

import Info from '../../../../../foundation/assets/svgs/Info';
import useMetricKeyValues from '../../../../../foundation/custom_hooks/useMetricKeyValues';
import { useAppSelector } from '../../../../../store/hooks';
import {
  selectFilterTooltips,
  selectOptions,
  selectSearchMetricValues,
} from '../../../redux/selectors';
import { Options, SearchMetricValues } from '../../../redux/types';

const FilterFieldDropDown = ({
  metricChangeHandler,
  metricKey,
  index,
}: any) => {
  const metricValues: SearchMetricValues = useAppSelector(
    selectSearchMetricValues,
  );

  const metricKeyValues = useMetricKeyValues();

  const options: Options = useAppSelector(selectOptions);

  let filterTooltips = useAppSelector(selectFilterTooltips);

  filterTooltips = [...filterTooltips.ssd, ...filterTooltips.aps];

  const keyValue = metricKeyValues.filter((metric: any) => {
    return metric.value === metricKey;
  })[0];

  const getDropdownOptions = () => {
    const dropdownOptions = metricValues?.filters[metricKey]?.map(
      (filter: any) => {
        return {
          label: filter.key || '0',
          value: filter.value,
        };
      },
    );

    return dropdownOptions
      ? [{ label: 'Any', value: null }, ...dropdownOptions]
      : [];
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const checkUndefined = (value: any) => {
    return value === undefined ? null : value;
  };

  let infoPopoverContent: any = null;

  const filterTooltip = filterTooltips.filter((f: any) => {
    return f.key === capitalizeFirstLetter(metricKey);
  });

  if (filterTooltip.length) {
    infoPopoverContent = (
      <p className="l-descriptor">{filterTooltip[0].value}</p>
    );
  }

  return (
    <div className="l-filter-field" key={index}>
      <label className="l-filter-field__label">
        <span>
          {keyValue.key}
          {!!filterTooltip.length && (
            <>
              <Popover
                content={infoPopoverContent}
                title={null}
                placement="top"
                trigger="click"
                overlayClassName="no-override"
              >
                <span className="l-descriptor-trigger">
                  <Info />
                </span>
              </Popover>
            </>
          )}
        </span>
      </label>
      <div className="l-filter-field__input">
        {metricKey !== 'baths' && metricKey !== 'carSpaces' ? (
          <>
            <div className="l-filter-field__select">
              <p>Min</p>
              <Select
                options={getDropdownOptions()}
                // @ts-ignore
                value={checkUndefined(options?.searchMetrics[metricKey]?.min)}
                onChange={(value: any) => {
                  metricChangeHandler(
                    // @ts-ignore
                    [value, options.searchMetrics[metricKey].max],
                    metricKey,
                  );
                }}
              />
            </div>
            <div className="l-filter-field__select">
              <p>Max</p>
              <Select
                options={getDropdownOptions()}
                // @ts-ignore
                value={checkUndefined(options?.searchMetrics[metricKey]?.max)}
                onChange={(value: any) => {
                  metricChangeHandler(
                    // @ts-ignore
                    [options.searchMetrics[metricKey].min, value],
                    metricKey,
                  );
                }}
              />
            </div>
          </>
        ) : (
          <div className="l-filter-field__select">
            <p>Min</p>
            <Select
              options={getDropdownOptions()}
              // @ts-ignore
              value={checkUndefined(options?.searchMetrics[metricKey])}
              onChange={(value: any) => {
                metricChangeHandler(value, metricKey);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterFieldDropDown;
