import { CometChat } from '@cometchat-pro/chat';
import moment from 'moment';
import React, { useMemo } from 'react';

import * as enums from '../../utils/enums';
import ChatMessage from '../chat_message/ChatMessage';

type Props = {
  messages: any[];
  replies?: any[];
  ccUser: any;
  selectedConversation: any;
  getConversationMembers: () => any;
  isDMConversation: boolean;
  isReply: boolean;
  setIsThreadPanelVisible: (v: boolean) => void;
  setParentMessage: (v: any) => void;
  parentMessage?: any;
};

const ChatRenderer = ({
  messages,
  ccUser,
  isDMConversation,
  selectedConversation,
  getConversationMembers,
  setIsThreadPanelVisible,
  setParentMessage,
  parentMessage,
  isReply,
  replies,
}: Props) => {
  const getSenderMessageComponent = (
    message: any,
    isReply: boolean,
    isFirstOfBatch: boolean,
    isHighlighted: boolean,
  ) => {
    let component;
    const messageKey = message._id ? message._id : message.id;

    if (message.deletedAt) {
      component = (
        // <CometChatDeleteMessageBubble key={messageKey} message={message} />
        <div key={messageKey}>Deleted message</div>
      );
    } else {
      switch (message.type) {
        case enums.CUSTOM_TYPE_DRAFTJS:
        case CometChat.MESSAGE_TYPE.TEXT:
        case CometChat.MESSAGE_TYPE.IMAGE:
        case CometChat.MESSAGE_TYPE.FILE:
        case CometChat.MESSAGE_TYPE.MEDIA:
          component = (
            <ChatMessage
              key={messageKey}
              message={message}
              isFirstOfBatch={isFirstOfBatch}
              isDM={isDMConversation}
              isSender={true}
              ccUser={ccUser}
              selectedConversation={selectedConversation}
              members={getConversationMembers()}
              setIsThreadPanelVisible={setIsThreadPanelVisible}
              setParentMessage={setParentMessage}
              isReply={isReply}
              isHighlighted={isHighlighted}
            />
          );
          break;
        default:
          break;
      }
    }

    return component;
  };

  const getReceiverMessageComponent = (
    message: any,
    isReply: boolean,
    isFirstOfBatch: boolean,
    isHighlighted: boolean,
  ) => {
    let component;
    const messageKey = message._id ? message._id : message.id;

    if (message.deletedAt) {
      component = <div key={messageKey}>Deleted Message Component</div>;
    } else {
      switch (message.type) {
        case enums.CUSTOM_TYPE_DRAFTJS:
        case CometChat.MESSAGE_TYPE.IMAGE:
        case CometChat.MESSAGE_TYPE.FILE:
        case CometChat.MESSAGE_TYPE.MEDIA:
        case CometChat.MESSAGE_TYPE.TEXT:
          component = (
            <ChatMessage
              key={messageKey}
              message={message}
              isFirstOfBatch={isFirstOfBatch}
              isSender={false}
              isDM={isDMConversation}
              ccUser={ccUser}
              selectedConversation={selectedConversation}
              members={getConversationMembers()}
              setIsThreadPanelVisible={setIsThreadPanelVisible}
              setParentMessage={setParentMessage}
              isReply={isReply}
              isHighlighted={isHighlighted}
            />
          );

          break;
        default:
          break;
      }
    }
    return component;
  };

  const getComponent = (
    message: any,
    isReply: boolean,
    isFirstOfBatch: boolean,
    isHighlighted: boolean,
  ) => {
    let component;

    switch (message.category) {
      case CometChat.CATEGORY_MESSAGE:
      case CometChat.CATEGORY_CUSTOM:
        if (ccUser?.uid === message.sender?.uid) {
          component = getSenderMessageComponent(
            message,
            isReply,
            isFirstOfBatch,
            isHighlighted,
          );
        } else {
          component = getReceiverMessageComponent(
            message,
            isReply,
            isFirstOfBatch,
            isHighlighted,
          );
        }
        break;

        break;
    }

    return component;
  };

  const batches = useMemo(() => {
    const batches: any[] = [];

    if (messages && messages.length > 0) {
      let batch: any = null;

      let lastSender: any = null;

      let lastMessageDate: any = null;

      for (const message of messages) {
        if (message.type !== 'groupMember') {
          const messageDate = message?.sentAt * 1000;
          const messageSentDate = moment(messageDate);

          if (!batch) {
            batch = {
              user: null,
              messages: [],
            };
            batch.user = message?.sender;
            batch.messages.push(message);
          } else if (
            lastSender === message?.sender?.uid &&
            messageSentDate.diff(lastMessageDate, 'm') <= 5
          ) {
            batch.messages.push(message);
          } else {
            batches.push(batch);
            batch = {
              user: null,
              messages: [],
            };
            batch.user = message?.sender;
            batch.messages.push(message);
          }

          lastMessageDate = messageSentDate;

          lastSender = message?.sender?.uid;
        }
      }

      if (batch) {
        batches.push(batch);
      }
    }
    return batches;
  }, [messages]);

  const components = useMemo(() => {
    const items: any[] = [];

    if (batches && batches.length > 0) {
      for (const batch of batches) {
        const batchMessages = batch.messages.map(
          (message: any, index: number) => {
            const isHighlighted =
              parentMessage && parentMessage.id === message.id;

            return getComponent(message, isReply, index === 0, isHighlighted);
          },
        );

        items.push(batchMessages);
      }
    }

    return items;
  }, [batches, parentMessage]);

  return <>{components}</>;
};

export default ChatRenderer;
