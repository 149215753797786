import React from 'react';

function PolygonMessageIcons() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="13"
      fill="none"
      viewBox="0 0 26 13"
    >
      <path fill="#F7F7FC" d="M13 0l12.124 12.75H.875L12.999 0z"></path>
    </svg>
  );
}

export default PolygonMessageIcons;
