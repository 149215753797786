import React from 'react';

function CarouselForward() {
  return (
    <svg
      width="19"
      height="40"
      viewBox="0 0 19 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.58325 2.5L16.2195 19.9079L2.58325 37.3159"
        stroke="#FCFCFC"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CarouselForward;
