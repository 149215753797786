import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import React from 'react';

const PaymentForm = () => {
  return (
    <form>
      <AddressElement options={{ mode: 'billing' }} />
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              name: '',
              email: '',
              address: {
                line1: '',
              },
            },
          },
          fields: { billingDetails: 'auto' },
        }}
      />
    </form>
  );
};

export default PaymentForm;
