import { Carousel } from 'antd';
import React from 'react';

import Bath from '../../../../../foundation/assets/svgs/Bath';
import Bed from '../../../../../foundation/assets/svgs/Bed';
import Car from '../../../../../foundation/assets/svgs/Car';
import CarouselBack from '../../../../../foundation/assets/svgs/CarouselBack';
import CarouselForward from '../../../../../foundation/assets/svgs/CarouselForward';
import House from '../../../../../foundation/assets/svgs/House';
import StarPlain from '../../../../../foundation/assets/svgs/StarPlain';
import StarPurple from '../../../../../foundation/assets/svgs/StarPurple';
import useSearchType from '../../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import Favorite from '../../../common/favorite/Favorite';
import {
  getIsFavorite,
  selectActivePropertyId,
} from '../../../redux/selectors';
import { selectEnabledSearchMetrics } from '../../../redux/selectors';
import { setActivePropertyId } from '../../../redux/slice';

const Property = ({ property }: any) => {
  const dispatch = useAppDispatch();
  const carouselRef = React.useRef<any>(null);
  const searchType = useSearchType();
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const propertyId = useAppSelector(selectActivePropertyId);

  const appendClassName = (className: string, metric: string) => {
    const modifier = 'h-bold';
    return enabledSearchMetrics.includes(metric)
      ? `${className} ${modifier}`
      : className;
  };

  const isPropertyInFavoriteList = useAppSelector(
    getIsFavorite(property.propertyId, searchType),
  );

  const isPropertyFavorite = property.isFavorite || isPropertyInFavoriteList;

  return (
    <div className="l-property">
      {property.images && property.images.length ? (
        <>
          {property.images && property.images.length && (
            <div className="l-property__carousel-wrapper">
              {property.propertyId === propertyId && (
                <div className="l-property__view">
                  <span>In View</span>
                </div>
              )}
              {property.images.length > 1 && (
                <>
                  <button
                    className="l-property__carousel-prev"
                    onClick={() => {
                      carouselRef?.current?.prev();
                    }}
                  >
                    <CarouselBack />
                  </button>
                  <button
                    className="l-property__carousel-next"
                    onClick={() => {
                      carouselRef?.current?.next();
                    }}
                  >
                    <CarouselForward />
                  </button>
                </>
              )}
              <Carousel ref={carouselRef}>
                {property.images.map((i: any, j: number) => {
                  return (
                    <div
                      className="l-property__carousel-slide"
                      key={j}
                      onClick={() => {
                        dispatch(setActivePropertyId(property.propertyId));
                      }}
                    >
                      <img
                        src={i}
                        style={{ cursor: 'pointer' }}
                        alt="Property"
                        onError={(e: any) => {
                          // TD: Apply a placeholder image
                          // const placeholderImage = '';
                          // e.target.src = placeholderImage;
                        }}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </>
      ) : (
        <div
          className="l-property__carousel-slide l-property__carousel-slide--empty"
          onClick={() => {
            dispatch(setActivePropertyId(property.propertyId));
          }}
        >
          {property.propertyId === propertyId ? (
            <span>In View</span>
          ) : (
            <House />
          )}
        </div>
      )}
      <div className="l-property__content">
        <div className="l-property__heading">
          <button
            className={appendClassName('', 'askingPrice')}
            type="button"
            onClick={() => {
              dispatch(setActivePropertyId(property.propertyId));
            }}
          >
            Offers over {property.askingPrice}
          </button>
          <Favorite className="l-property__favorite" property={property}>
            {isPropertyFavorite ? <StarPurple /> : <StarPlain />}
          </Favorite>
        </div>
        <div className={appendClassName('l-property__tag-umv', 'umvEstimate')}>
          UMV estimate {property.umvEstimate}
        </div>
        <p className="l-property__address">{property.address}</p>
        <ul className="l-property__tag-group">
          <li
            className={appendClassName('l-property__tag-details', 'bedrooms')}
          >
            <Bed />
            {property.beds || '-'}
          </li>
          <li className={appendClassName('l-property__tag-details', 'baths')}>
            <Bath />
            {property.baths || '-'}
          </li>
          <li
            className={appendClassName('l-property__tag-details', 'carSpaces')}
          >
            <Car />
            {property.cars || '-'}
          </li>
          <li
            className={appendClassName('l-property__tag-details', 'landSize')}
          >
            {property.floorSpace || '-'}
          </li>
          <li className="l-property__tag-details">
            {property.propertyType === 'Houses' ? 'House' : 'Unit'}
          </li>
        </ul>
        <ul className="l-property__tag-list">
          <li>
            <span className={appendClassName('', 'averageDaysMarket')}>
              Days on Market
            </span>
            <span
              className={appendClassName(
                'l-property__tag-details',
                'averageDaysMarket',
              )}
            >
              {property.daysOnMarket}
            </span>
          </li>
          <li>
            <span className={appendClassName('', 'grossRentalYield')}>
              Estimated gross rental yield
            </span>
            <span
              className={appendClassName(
                'l-property__tag-details',
                'grossRentalYield',
              )}
            >
              {property.grossRentalYield}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Property;
