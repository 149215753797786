import { Button, Divider, InputNumber, Modal } from 'antd';
import React, { useState } from 'react';

import Close from '../../../../foundation/assets/svgs/Close';

type AddUserModalTypes = {
  handleCancel: () => void;
  onSubmit: (v: number) => void;
  value: number;
  occupiedSeats: number;
};

const AddUserModal = ({
  handleCancel,
  value,
  onSubmit,
  occupiedSeats,
}: AddUserModalTypes) => {
  const [numOfUsers, setNumOfUsers] = useState(value);

  const modalProps = {
    wrapClassName: 'l-plan-add-user-modal',
    closable: true,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 500,
    onCancel: handleCancel,
    closeIcon: <Close />,
  };

  const onInputChange = (v: number | null) =>
    (v || v === 0) && setNumOfUsers(v);

  const handleSave = () => {
    onSubmit(numOfUsers);
  };

  return (
    <Modal {...modalProps}>
      <div className="l-plan-add-user-modal__header">Add additional seats</div>
      <Divider />

      <div className="l-plan-add-user-modal__input-wrapper">
        <div className="l-plan-add-user-modal__input-label">
          How many additional seats do you want to add?
        </div>
        <InputNumber
          className="l-plan-add-user-modal__input"
          min={occupiedSeats || 0}
          max={100}
          defaultValue={value}
          onChange={onInputChange}
        />
      </div>

      <div className="l-plan-add-user-modal__btns-wrapper">
        <Button
          className="l-plan-add-user-modal__btn"
          style={{ marginRight: 10 }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          className="l-plan-add-user-modal__btn"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AddUserModal;
