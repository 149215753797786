import {
  Alert,
  Col,
  Pagination,
  Popover,
  Row,
  Slider,
  Table,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import Info from '../../../../../../foundation/assets/svgs/Info';
import Select from '../../../../../../foundation/components/select/Select';
import useBreakpoint from '../../../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch } from '../../../../../../store/hooks';
import SearchLoader from '../../../../form/search/search_loader/SearchLoader';

const View = ({
  listContainerSize,
  isFirstLoad,
  fetchProjects,
  user,
  suburb,
  projectSettings,
  pageIndex,
  setPageIndex,
  projectsData,
  projectValues,
  isLoading,
  prevOrderType,
  descriptor,
  setIsGraphLoading,
  setKeyIndicatorsGraphData,
  setProjectSettings,
  LineChart,
}: any) => {
  const dispatch = useAppDispatch();
  const [isMobileView] = useBreakpoint();

  const { orderType, sortKey, orderByKey } = projectSettings;
  const [groupedSortedProjects, setGroupedSortedProjects] = useState();

  const projectsDistanceValues = projectValues.distance;
  const projectsOrderTypes = projectValues.orderTypes || [];

  const graphType = 'SuburbTotalWeightedInvesmentPerCapita';

  const isLayoutBroad =
    listContainerSize !== 'compact' && listContainerSize !== 'hidden';

  const { Title } = Typography;

  const scrollToTop = () => {
    const elem = document.getElementById('project-number');

    if (elem) {
      window.scrollTo({
        behavior: 'smooth',
        // @ts-ignore
        top: elem.getBoundingClientRect().top + window.scrollY - elem.clientTop,
      });
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      fetchProjects();
    }
  }, []);

  useEffect(() => {
    if (!isFirstLoad && user && suburb && projectSettings.kmRadius) {
      fetchProjects();
    }
  }, [suburb, pageIndex]);

  useEffect(() => {
    if (user && suburb) {
      dispatch(
        setKeyIndicatorsGraphData({
          graphType: graphType,
          data:
            projectsData && projectsData.graphs && projectsData.graphs.length
              ? { graphs: projectsData.graphs }
              : false,
        }),
      );

      dispatch(setIsGraphLoading({ key: graphType, status: false }));
    }
  }, [projectsData]);

  useEffect(() => {
    if (projectsData && orderType) {
      let newGroupedSortedProjects = projectsData.projects.reduce(
        (projects: any, project: any) => {
          const orderTypeChecked =
            orderType === 'Stage'
              ? 'stageIndex'
              : orderType[0].toLowerCase() + orderType.slice(1);

          const group = projects[project[orderTypeChecked]] || [];

          group.push(project);
          projects[project[orderTypeChecked]] = group.sort(sortProjects);

          return projects;
        },
        {},
      );

      newGroupedSortedProjects = Object.keys(newGroupedSortedProjects)
        .sort()
        .reduce((obj: any, key) => {
          obj[key] = newGroupedSortedProjects[key];
          return obj;
        }, {});
      setGroupedSortedProjects(newGroupedSortedProjects);
    }
  }, [projectsData, sortKey, orderByKey]);

  if (!suburb) {
    return null;
  }

  if (!!suburb && !projectsData && isLoading) {
    return <SearchLoader classNames="l-search-loader--report-transparent" />;
  }

  if (!!suburb && !projectsData && !isLoading) {
    return (
      <Row gutter={[0, 50]}>
        <Col span={24}>
          <Alert
            message="No projects found"
            description={<></>}
            type="warning"
            showIcon
          />
        </Col>
      </Row>
    );
  }

  let columns: any = [
    {
      title: '',
      dataIndex: 'name',
      render: (name: string, project: any) => {
        // Revisit
        return (
          <div className="l-project-details">
            <div className="l-project-details__name-wrap">
              {!isLayoutBroad && (
                <div className="h-image--table">
                  <img src={project.categoryUri} alt="" />
                </div>
              )}
              <div className="l-project-details__name">
                <span className="l-project-details__label">Name</span>
                <Title level={5} className="h-text--name">
                  {capitalizeName(name)}
                </Title>
              </div>
            </div>
            <ul className="l-project-details__items">
              <li className="l-project-details__item" style={{ width: '100%' }}>
                <span className="l-project-details__label">Category</span>
                <span className="l-project-details__value">
                  {project.category}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Stage</span>
                <span className="l-project-details__value">
                  {project.stage}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Post Code</span>
                <span className="l-project-details__value">
                  {project.postCode}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Likelihood</span>
                <span className="l-project-details__value">
                  {project.likelihood}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. Value</span>
                <span className="l-project-details__value">
                  {project.value}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. Start</span>
                <span className="l-project-details__value">
                  {project.commenceDate}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. End</span>
                <span className="l-project-details__value">
                  {project.completionDate || 'n/a'}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  if (isLayoutBroad) {
    columns = [
      {
        title: 'Project',
        dataIndex: 'categoryUri',
        render: (categoryUri: string) => {
          return (
            <div className="h-image--table">
              <img src={categoryUri} alt="" />
            </div>
          );
        },
        width: isLayoutBroad ? '150px' : '0',
      },
      columns[0],
    ];
  }

  const sortKeyOptions = [
    {
      key: 'Name',
      value: 'name',
    },
    {
      key: 'Category',
      value: 'category',
    },
    {
      key: 'Post Code',
      value: 'postCode',
    },
    {
      key: 'Likelihood',
      value: 'likelihood',
    },
    {
      key: 'Estimated Value',
      value: 'realValue',
    },
    {
      key: 'Estimated Start',
      value: 'commenceDate',
    },
    {
      key: 'Estimated End',
      value: 'completionDate',
    },
    {
      key: 'Stage',
      value: 'stage',
    },
  ];

  const orderByOptions = [
    {
      key: 'Ascending',
      value: 'ascending',
    },
    {
      key: 'Descending',
      value: 'descending',
    },
  ];

  const groupByOptions = [
    {
      key: 'No Grouping',
      value: '',
    },
    ...projectsOrderTypes.map((item: any) => {
      if (item === 'PostCode') {
        return {
          key: 'Post Code',
          value: item,
        };
      }

      return {
        key: item,
        value: item,
      };
    }),
  ];

  const {
    totalInvestment,
    weightedInvestmentPerCapita,
    weightedInvestment,
    country,
    countryInvestmentPerCapita,
    totalProjects,
  } = projectsData;

  const sortProjects = (a: any, b: any) => {
    let check = a[sortKey] > b[sortKey];

    if (a === b) {
      return 0;
    }

    if (sortKey === 'commenceDate' || sortKey === 'completionDate') {
      check = Date.parse(a[sortKey]) > Date.parse(b[sortKey]);
    }

    if (sortKey === 'likelihood' || sortKey === 'postCode') {
      check = parseFloat(a[sortKey]) > parseFloat(b[sortKey]);
    }

    if (sortKey === 'likelihood' || sortKey === 'postCode') {
      check = parseFloat(a[sortKey]) > parseFloat(b[sortKey]);
    }

    if (sortKey === 'value') {
      const regex = /\D+/g;

      check =
        parseFloat(a[sortKey].replace(regex, '')) >
        parseFloat(b[sortKey].replace(regex, ''));
    }

    if (orderByKey === 'descending') {
      return check ? -1 : 1;
    }

    return check ? 1 : -1;
  };

  const capitalizeName = (name: string) => {
    const words = name.toLowerCase().split(' ');

    for (let i = 0; i < words.length; i++) {
      if (words[i][0]) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
    }

    return words.join(' ');
  };

  const renderActions = () => {
    const heading = (
      <div className="l-project-actions__heading">
        No. of Projects: <strong>{totalProjects}</strong>
      </div>
    );

    const groupBy = (
      <>
        <span className="l-dashboard-card__label">
          {isLayoutBroad ? 'Group All Projects By' : 'Group Projects'}
        </span>
        <Select
          options={groupByOptions}
          value={projectSettings.orderType}
          onChange={(v: string) => {
            dispatch(
              setProjectSettings({
                ...projectSettings,
                orderType: v,
              }),
            );

            if (pageIndex !== 1) {
              setPageIndex(1);
            } else {
              if (user && suburb && projectSettings.kmRadius) {
                fetchProjects(null, v || null);
              }
            }
          }}
        />
      </>
    );

    const sortBy = (
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <span className="l-dashboard-card__label">
          Sort Page{`${isLayoutBroad ? ' Result By' : ''}`}
        </span>
        <Select
          options={sortKeyOptions}
          value={sortKey}
          onChange={(v: string) => {
            dispatch(
              setProjectSettings({
                ...projectSettings,
                sortKey: v,
              }),
            );
          }}
          placeholder="Order By"
        />
      </span>
    );

    const orderBy = (
      <>
        <Select
          options={orderByOptions}
          value={orderByKey}
          onChange={(v: string) => {
            dispatch(
              setProjectSettings({
                ...projectSettings,
                orderByKey: v,
              }),
            );
          }}
        />
      </>
    );

    if (isLayoutBroad) {
      return (
        <>
          <Col span={24} className="l-project-actions">
            {heading}
          </Col>
          <Col xs={24} sm={10} className="l-project-actions">
            {groupBy}
          </Col>
          <Col
            xs={24}
            sm={8}
            className="l-project-actions l-project-actions--report-margin"
            style={isMobileView ? {} : { alignItems: 'flex-end' }}
          >
            {sortBy}
          </Col>
          <Col
            xs={24}
            sm={6}
            className="l-project-actions  l-project-actions--report-width"
          >
            {orderBy}
          </Col>
        </>
      );
    }

    return (
      <>
        <Col span={24} className="l-project-actions">
          {heading}
        </Col>
        <Col span={10} className="l-project-actions">
          {groupBy}
        </Col>
        <Col span={8} className="l-project-actions">
          {sortBy}
        </Col>
        <Col span={6} className="l-project-actions">
          {orderBy}
        </Col>
      </>
    );
  };

  const renderGroupedSortedProjects = () => {
    if (!groupedSortedProjects) {
      return null;
    }

    return (
      <>
        {Object.keys(groupedSortedProjects).map(
          (key: string, index: number) => (
            <Col span={24} key={index}>
              <div className="l-dashboard-card">
                <div className="l-dashboard-card__header  l-dashboard-card__header--border l-dashboard-card__header--dark">
                  <h5 className="l-dashboard-card__title l-dashboard-card__title--no-padding l-dashboard-card__title--dark">
                    <span className="l-dashboard-card__title-type">
                      {orderType}
                    </span>
                    {prevOrderType && (
                      <strong>
                        {groupedSortedProjects[key][0][prevOrderType]}
                      </strong>
                    )}
                  </h5>
                </div>
                <div className="l-dashboard-card__body l-dashboard-card__body--no-padding">
                  <div className="h-override h-override--hide-table-header">
                    <Table
                      pagination={false}
                      dataSource={groupedSortedProjects[key]}
                      columns={columns}
                      size="large"
                      rowKey="name"
                      className="l-projects-table"
                    />
                  </div>
                </div>
              </div>
            </Col>
          ),
        )}
      </>
    );
  };

  const renderSortedProjects = () => {
    const sortedProjects = [].concat(projectsData.projects).sort(sortProjects);

    return (
      <Col span={24}>
        <div className="l-dashboard-card">
          <div className="l-dashboard-card__body l-dashboard-card__body--no-padding">
            <div className="h-override h-override--hide-table-header">
              <Table
                pagination={false}
                dataSource={sortedProjects}
                columns={columns}
                size="large"
                className="l-projects-table"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <>
      {isLoading && <SearchLoader />}
      <Row gutter={[15, 50]}>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isLayoutBroad ? (isMobileView ? 24 : 8) : 24}>
              <Row gutter={[0, 15]}>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Investment
                        {descriptor['totalInvestment'] && (
                          <>
                            <Popover
                              content={
                                <p className="l-descriptor">
                                  {descriptor['totalInvestment']}
                                </p>
                              }
                              title={null}
                              placement="top"
                              trigger="click"
                            >
                              <span className="l-descriptor-trigger">
                                <Info />
                              </span>
                            </Popover>
                          </>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {totalInvestment || 'N/a'}
                          </span>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Weighted Est. Investment
                        {descriptor['totalWeightedEstInv'] && (
                          <>
                            <Popover
                              content={
                                <p className="l-descriptor">
                                  {descriptor['totalWeightedEstInv']}
                                </p>
                              }
                              title={null}
                              placement="top"
                              trigger="click"
                            >
                              <span className="l-descriptor-trigger">
                                <Info />
                              </span>
                            </Popover>
                          </>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {weightedInvestment || 'N/a'}
                          </span>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Weighted Est. Investment Per Capita
                        {descriptor['totalWeightedEstInvPerCapita'] && (
                          <>
                            <Popover
                              content={
                                <p className="l-descriptor">
                                  {descriptor['totalWeightedEstInvPerCapita']}
                                </p>
                              }
                              title={null}
                              placement="top"
                              trigger="click"
                            >
                              <span className="l-descriptor-trigger">
                                <Info />
                              </span>
                            </Popover>
                          </>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {weightedInvestmentPerCapita || 'N/a'}
                          </span>
                        </dt>
                        <dd className="l-dashboard-card__details">
                          <span>
                            {country} is{' '}
                            <strong>
                              {countryInvestmentPerCapita || 'N/a'}
                            </strong>
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={isLayoutBroad ? (isMobileView ? 24 : 16) : 24}>
              <section className="l-project-wrapper" style={{ padding: '0' }}>
                <div
                  className="l-dashboard-card"
                  style={
                    !isLayoutBroad
                      ? {
                          height: '440px',
                        }
                      : {}
                  }
                >
                  <div
                    className="l-dashboard-card__body"
                    style={{ height: '100%' }}
                  >
                    <div
                      className="l-dashboard-card__title"
                      style={{ paddingBottom: '0' }}
                    >
                      Investment Indicator
                    </div>
                    <LineChart
                      graphType={graphType}
                      maintainAspectRatio={false}
                      isXUnitsInMonths={true}
                      yMaxTicksLimit={5}
                      style={{ height: !isMobileView ? '100%' : '300px' }}
                      isProjects
                    />
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="l-project-controls" id="project-number">
        <div className="l-slider">
          <span className="l-slider__label" style={{ whiteSpace: 'nowrap' }}>
            Distance {listContainerSize === 'wide' && 'Radius'}
          </span>
          <span className="l-slider__wrapper">
            <span className="l-slider__mark-label">
              {projectsDistanceValues.min} km
            </span>
            <Slider
              step={projectsDistanceValues.interval}
              style={{ maxWidth: '250px', width: '100%' }}
              tooltip={{
                formatter: (value: any) => {
                  if (value) {
                    return <span>{value} km</span>;
                  }

                  return null;
                },
              }}
              min={projectsDistanceValues.min}
              max={projectsDistanceValues.max}
              value={projectSettings.kmRadius}
              onChange={(value: any) => {
                dispatch(
                  setProjectSettings({
                    ...projectSettings,
                    kmRadius: value,
                  }),
                );
              }}
              onAfterChange={(value: any) => {
                if (pageIndex !== 1) {
                  setPageIndex(1);
                } else {
                  if (user && suburb && projectSettings.kmRadius) {
                    fetchProjects(value);
                  }
                }
              }}
            />
            <span className="l-slider__mark-label">
              {projectsDistanceValues.max} km
            </span>
          </span>
          {(listContainerSize === 'default' ||
            listContainerSize === 'wide') && (
            <span className="l-slider__active-label">
              {projectSettings.kmRadius} km
            </span>
          )}
        </div>
      </div>

      <Row
        gutter={[0, 50]}
        justify="center"
        style={{ marginTop: '50px', marginBottom: '0' }}
      >
        <Col span={24}>
          <Row gutter={[15, 0]}>{renderActions()}</Row>
        </Col>
      </Row>

      <Row gutter={[0, 10]} justify="center">
        <Col span={24}>
          <Row gutter={[0, 25]}>
            {orderType && groupedSortedProjects
              ? renderGroupedSortedProjects()
              : renderSortedProjects()}
            <Col span={24}>
              <div className="l-project-pagination">
                <Pagination
                  className="custom-pagination"
                  current={pageIndex}
                  total={totalProjects}
                  pageSize={20}
                  onChange={(page: number) => {
                    scrollToTop();
                    setPageIndex(page);
                  }}
                  showSizeChanger={false}
                  showLessItems
                  style={{
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default View;
