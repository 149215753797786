import { Modal } from 'antd';
import React from 'react';

import { setIsDisclaimerModalActive } from '../../../../features/search/redux/slice';
import { useAppDispatch } from '../../../../store/hooks';
import Close from '../../../assets/svgs/Close';
import Template from '../template/Template';

const DisclaimerModal = () => {
  const dispatch = useAppDispatch();

  const disclaimerModalCloseHandler = () => {
    dispatch(setIsDisclaimerModalActive(false));
  };

  return (
    <Modal
      wrapClassName={''}
      closable={true}
      footer={false}
      maskClosable={false}
      centered
      title={'Disclaimer'}
      open={true}
      onCancel={disclaimerModalCloseHandler}
      closeIcon={
        <strong className="l-filter-modal__close">
          <Close />
        </strong>
      }
      width={700}
      className="c-privacy-modal"
    >
      <Template title="Disclaimer">
        <article>
          <p>
            © Copyright 2016. RP Data Pty Ltd trading as CoreLogic Asia Pacific
            (CoreLogic) and its licensors are the sole and exclusive owners of
            all rights, title and interest (including intellectual property
            rights) subsisting in this publication, including any data,
            analytics, statistics and other information contained in this
            publication (Data). All rights reserved.
          </p>
          <p>
            Whilst CoreLogic uses commercially reasonable efforts to ensure the
            Data and information in this publication is current, CoreLogic does
            not warrant the accuracy, currency or completeness of the Data and
            commentary contained in this publication and to the full extent not
            prohibited by law excludes all loss or damage howsoever arising
            (including through negligence) in connection with the Data and
            commentary contained in this publication.
          </p>
        </article>
      </Template>
    </Modal>
  );
};

export default DisclaimerModal;
