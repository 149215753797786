import './styles/antd.style.less';
import './styles/styles.scss';

import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Startup from './features/startup/Startup';
import envConstants from './internals/env/env_constants.json';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const AUTH0_DOMAIN = envConstants.AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = envConstants.AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}`}
  >
    <Provider store={store}>
      <BrowserRouter>
        <Startup />
      </BrowserRouter>
    </Provider>
  </Auth0Provider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
