import debounce from 'debounce';
import { map as pluck } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';

import ArrowLeftTail from '../../../../../foundation/assets/svgs/ArrowLeftTail';
import Close from '../../../../../foundation/assets/svgs/Close';
import Search from '../../../../../foundation/assets/svgs/Search';
import useBreakpoint from '../../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  resetSiderMobileContentValues,
  setSiderMobileContentValues,
} from '../../../../layout/redux/slice';
import AccessControl from '../../../../user/access_control/AccessControl';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import Toggler from '../../../common/toggler/Toggler';
import { postSearchSuggestions } from '../../../redux/async_thunks';
import {
  selectIsMapSearchEnabled,
  selectKeyword,
  selectQueryValues,
  selectSearchType,
  selectSuggestions,
} from '../../../redux/selectors';
import {
  resetSuggestions,
  setKeyword,
  setQueryValues,
} from '../../../redux/slice';

type Props = {
  children?: any;
  isDisabled?: boolean;
  placeholder?: string;
  isCompact?: boolean;
  isMobileViewVisible?: boolean;
  isParentFilter?: boolean;
  init?: boolean;
};

const SearchField = ({
  children,
  isDisabled,
  placeholder,
  isCompact,
  isMobileViewVisible,
  isParentFilter,
  init,
}: Props) => {
  const KEYWORD_MIN_LENGTH = 2;

  const dispatch = useAppDispatch();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const suggestions = useAppSelector(selectSuggestions);
  const queryValues = useAppSelector(selectQueryValues);
  const keyword = useAppSelector(selectKeyword);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const [searchKeywordDisplay, setSearchKeywordDisplay] = useState('');
  const [width, setWidth] = useState<any>(0);

  const phantomTextRef = useRef();
  const inputRef = useRef(null);
  const selectedRef = useRef();

  const [isMobileView] = useBreakpoint();

  const searchTypeForEffect = useAppSelector(selectSearchType);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchSuggestions = async (searchKeyword: string) => {
    try {
      await dispatch(
        postSearchSuggestions({
          data: {
            userId: USER.userId,
            searchKeyword: searchKeyword,
            selectedValues: queryValues.length
              ? pluck(queryValues, 'value')
              : null,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      );
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setKeyword(searchKeyword));
  };

  const setSearchKeywordRealValue = (e: any) => {
    const value = e.target.value;

    if (!value.length) {
      setSearchKeywordDisplay('');
    }

    if (value.length < KEYWORD_MIN_LENGTH) {
      dispatch(setKeyword(undefined));
      dispatch(resetSuggestions());
    } else {
      fetchSuggestions(value);
    }
  };

  const mobileBackHandler = () => {
    if (isDisabled) {
      const data = {
        isVisible: true,
        view: 'search',
        showBackButton: false,
        isClosable: false,
        headerTitle: undefined as any,
        parentConfig: undefined as any,
        isMobileContentHeaderHidden: true,
      };

      dispatch(setSiderMobileContentValues(data));
    } else {
      dispatch(resetSiderMobileContentValues(undefined));
    }
  };

  const scrollToBottom = (ref: any, scrollHeight = 0) => {
    // @ts-ignore
    if (ref && ref.current && ref?.current) {
      // @ts-ignore
      ref.current.scrollTop =
        // @ts-ignore
        ref?.current?.scrollHeight - scrollHeight;
    }
  };

  const isAllSelected =
    suggestions.filter((suggestion: any) => {
      return queryValues.some((queryValue: any) => {
        return queryValue.value === suggestion.value;
      });
    }).length === suggestions.length;

  const classNames = `l-search-field${
    isCompact ? ' l-search-field--compact' : ''
  }`;

  const renderMobileBack = (isNotFixed?: boolean) => {
    return (
      <>
        {isMobileViewVisible && init && (
          <button
            className={`l-search-field__mobile-back${
              isNotFixed ? ' l-search-field__mobile-back--not-fixed' : ''
            }`}
            onClick={() => {
              mobileBackHandler();
            }}
            type="button"
          >
            <ArrowLeftTail color="#262338" />
          </button>
        )}
      </>
    );
  };

  const renderSelectionUI = () => {
    return (
      <div
        className={`l-search-field__selected${
          !isMobileViewVisible ? ' l-search-field__selected--desktop' : ''
        }`}
        // @ts-ignore
        ref={selectedRef}
      >
        {renderMobileBack()}
        {isMobileViewVisible && !isDisabled && !isParentFilter && (
          <button
            className="l-search-field__mobile-search"
            onClick={(e) => {
              e.stopPropagation();

              const data = {
                isVisible: true,
                view: 'filter',
                showBackButton: false,
                isClosable: false,
                headerTitle: undefined as any,
                parentConfig: undefined as any,
                isMobileContentHeaderHidden: true,
              };

              dispatch(setSiderMobileContentValues(data));
            }}
          >
            <Search color="#ffffff" />
          </button>
        )}
        {queryValues.map((queryValue: any, index: string) => {
          return (
            <div
              className="l-search-field__overflow-item-suggestion"
              key={index}
            >
              {queryValue.key}
              <strong
                key={index}
                onClick={() => {
                  dispatch(
                    setQueryValues(
                      queryValues.filter((queryValueCurrent: any) => {
                        return queryValueCurrent.value !== queryValue.value;
                      }),
                    ),
                  );
                  // @ts-ignore
                  inputRef.current.focus();
                }}
                className="l-search-field__overflow-item-remove"
              >
                <Close />
              </strong>
            </div>
          );
        })}
        <div className="l-search-field__overflow-item">
          <span
            className="l-search-field__phantom-text"
            // @ts-ignore
            ref={phantomTextRef}
          >
            <span>{searchKeywordDisplay}</span>
          </span>
          <input
            placeholder={placeholder}
            onChange={debounce(setSearchKeywordRealValue, 200)}
            onKeyUp={() => {
              // @ts-ignore
              setSearchKeywordDisplay(inputRef.current.value);
            }}
            style={{
              width,
            }}
            autoFocus
            defaultValue={keyword}
            ref={inputRef}
          />
        </div>
      </div>
    );
  };

  const renderInputUI = () => {
    return (
      <>
        <div className="l-search-field__input">
          <AccessControl requiredFeature="property-search">
            <Toggler />
          </AccessControl>
          {!isMapSearchEnabled && (
            <div className="l-search-field__input-content">
              {isDisabled && (
                <div
                  className="l-search-field__selected"
                  onClick={() => {
                    mobileBackHandler();
                  }}
                >
                  {renderMobileBack(true)}
                  <input
                    disabled
                    placeholder={
                      queryValues && queryValues.length
                        ? `${pluck(queryValues, 'key').join('; ')}`
                        : placeholder
                    }
                  />
                </div>
              )}
              {!isDisabled && renderSelectionUI()}
              {!!children && children}
            </div>
          )}
        </div>
        {!isDisabled && !isMapSearchEnabled && (
          <div className="l-search-field__suggestions-anchor">
            {suggestions && !!suggestions.length && !isAllSelected && (
              <div className="l-search-field__suggestions">
                <ul className="l-search-field__suggestions-list">
                  {suggestions.map((suggestion: any, index: number) => {
                    if (
                      queryValues.some((queryValue: any) => {
                        return queryValue.value === suggestion.value;
                      })
                    ) {
                      return null;
                    }
                    return (
                      <div
                        className="l-search-field__suggestions-item"
                        key={index}
                        onClick={() => {
                          dispatch(
                            setQueryValues([...queryValues, ...[suggestion]]),
                          );

                          dispatch(resetSuggestions());
                          dispatch(setKeyword(undefined));

                          // @ts-ignore
                          inputRef.current.focus();
                          // @ts-ignore
                          inputRef.current.value = '';
                        }}
                      >
                        <span>
                          {reactStringReplace(
                            suggestion.key,
                            keyword,
                            (match, index) => {
                              return <strong key={index}>{match}</strong>;
                            },
                          )}
                        </span>
                        <small className="l-search-field__bullet"></small>
                        <span className="l-search-field__desc">
                          {suggestion.desc}
                        </span>
                      </div>
                    );
                  })}
                </ul>
              </div>
            )}
            {!suggestions.length && keyword && (
              <div className="l-search-field__suggestions">
                <div className="l-search-field__suggestions-empty">
                  No suggestions found
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (searchKeywordDisplay) {
      // @ts-ignore
      setWidth(phantomTextRef.current.offsetWidth + 100);
    } else {
      setWidth('100%');
    }
  }, [searchKeywordDisplay]);

  useEffect(() => {
    scrollToBottom(selectedRef);
  }, [queryValues]);

  useEffect(() => {
    if (keyword && keyword.length > KEYWORD_MIN_LENGTH) {
      fetchSuggestions(keyword);
    }
  }, [searchTypeForEffect]);

  return (
    <div
      className={
        isMobileViewVisible && init
          ? 'l-search-field l-search-field--mobile'
          : classNames
      }
      onClick={() => {
        if (isMobileView && !isMobileViewVisible) {
          const data = {
            isVisible: true,
            view: 'search',
            showBackButton: false,
            isClosable: false,
            headerTitle: undefined as any,
            parentConfig: undefined as any,
            isMobileContentHeaderHidden: true,
          };

          dispatch(setSiderMobileContentValues(data));
        }
      }}
    >
      {renderInputUI()}
    </div>
  );
};

export default SearchField;
