import { fetchNextConversation } from '../utils/controllers/conversationListController';
import * as enums from '../utils/enums/index';

export const getChannelList = () => {
  return new Promise((resolve, reject) => {
    fetchNextConversation(enums.chatListFilterOptions.GROUPS)
      .then((conversationList: any[]) => {
        const serialized = conversationList.map((conversation: any) => {
          return JSON.parse(JSON.stringify(conversation));
        });

        resolve(serialized);
      })
      .catch((error: any) => reject(error));
  });
};

export const getDirectMessagesList = () => {
  return new Promise((resolve, reject) => {
    fetchNextConversation(enums.chatListFilterOptions.USERS)
      .then((conversationList: any[]) => {
        const serialized = conversationList.map((conversation: any) => {
          return JSON.parse(JSON.stringify(conversation));
        });

        resolve(serialized);
      })
      .catch((error: any) => reject(error));
  });
};
