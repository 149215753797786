import React from 'react';

const FavoriteSuburbMarker = ({ color }: any) => {
  return (
    <svg
      width="35"
      height="38"
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5355_142406)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2379 28.15C20.657 26.1603 26.3333 21.0671 26.3333 17.0833C26.3333 12.0207 22.2293 7.91663 17.1667 7.91663C12.1041 7.91663 8 12.0207 8 17.0833C8 21.0671 13.6763 26.1603 16.0954 28.15C16.7232 28.6663 17.6102 28.6663 18.2379 28.15ZM17.1667 19.8333C18.6854 19.8333 19.9167 18.6021 19.9167 17.0833C19.9167 15.5645 18.6854 14.3333 17.1667 14.3333C15.6479 14.3333 14.4167 15.5645 14.4167 17.0833C14.4167 18.6021 15.6479 19.8333 17.1667 19.8333Z"
          fill={color ? color : '#651FFF'}
        />
        <path
          d="M18.8732 28.9223C20.1021 27.9115 22.1772 26.0979 23.9539 24.0131C24.8424 22.9705 25.6757 21.8377 26.291 20.6839C26.9018 19.5385 27.3333 18.3064 27.3333 17.0833C27.3333 11.4684 22.7816 6.91663 17.1667 6.91663C11.5518 6.91663 7 11.4684 7 17.0833C7 18.3064 7.43155 19.5385 8.04234 20.6839C8.65761 21.8377 9.49092 22.9705 10.3794 24.0131C12.1562 26.0979 14.2313 27.9115 15.4602 28.9223C16.457 29.7422 17.8763 29.7422 18.8732 28.9223ZM18.9167 17.0833C18.9167 18.0498 18.1332 18.8333 17.1667 18.8333C16.2002 18.8333 15.4167 18.0498 15.4167 17.0833C15.4167 16.1168 16.2002 15.3333 17.1667 15.3333C18.1332 15.3333 18.9167 16.1168 18.9167 17.0833Z"
          stroke="#FCFCFC"
          strokeWidth="2"
        />
      </g>
      <circle
        cx="17.5833"
        cy="17.0833"
        r="4.58333"
        fill={color ? color : '#651FFF'}
      />
      <path
        d="M16.549 9.88272C16.7308 9.44726 17.3477 9.44726 17.5295 9.88272L19.089 13.6184C19.1616 13.7924 19.3211 13.9149 19.508 13.9402L23.618 14.4965C24.0785 14.5588 24.2427 15.1407 23.8826 15.4345L20.7091 18.0242C20.5522 18.1522 20.4817 18.3583 20.5274 18.5556L21.542 22.9401C21.6519 23.4148 21.1181 23.7746 20.7193 23.4947L17.3445 21.1261C17.1613 20.9975 16.9172 20.9975 16.7341 21.1261L13.3593 23.4947C12.9605 23.7746 12.4267 23.4148 12.5365 22.9401L13.5511 18.5556C13.5967 18.3583 13.5262 18.1522 13.3694 18.0242L10.1959 15.4345C9.83587 15.1407 10 14.5588 10.4606 14.4965L14.5706 13.9402C14.7575 13.9149 14.9169 13.7924 14.9896 13.6184L16.549 9.88272Z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="filter0_d_5355_142406"
          x="0"
          y="0.916626"
          width="34.333"
          height="36.6206"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5355_142406"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5355_142406"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FavoriteSuburbMarker;
