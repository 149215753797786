import React from 'react';

function Marker() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66666 4.33329L1.29456 3.68211C1.06088 3.81564 0.916664 4.06415 0.916664 4.33329H1.66666ZM16.3333 1.66663H17.0833C17.0833 1.39927 16.941 1.15213 16.7098 1.01794C16.4785 0.883752 16.1934 0.8828 15.9612 1.01544L16.3333 1.66663ZM16.3333 13.6666L16.7054 14.3178C16.9391 14.1843 17.0833 13.9358 17.0833 13.6666H16.3333ZM1.66666 16.3333H0.916664C0.916664 16.6006 1.05898 16.8478 1.29022 16.982C1.52146 17.1162 1.80664 17.1171 2.03877 16.9845L1.66666 16.3333ZM6.33333 1.66663L6.66874 0.995806C6.44445 0.88366 6.17895 0.891029 5.96123 1.01544L6.33333 1.66663ZM11.6667 16.3333L11.3313 17.0041C11.5555 17.1163 11.821 17.1089 12.0388 16.9845L11.6667 16.3333ZM15.5833 1.66663V13.6666H17.0833V1.66663H15.5833ZM2.41666 16.3333V4.33329H0.916664V16.3333H2.41666ZM2.03877 4.98447L6.70543 2.31781L5.96123 1.01544L1.29456 3.68211L2.03877 4.98447ZM5.96123 13.0154L1.29456 15.6821L2.03877 16.9845L6.70543 14.3178L5.96123 13.0154ZM5.99792 2.33745L11.3313 5.00411L12.0021 3.66247L6.66874 0.995806L5.99792 2.33745ZM12.0388 4.98447L16.7054 2.31781L15.9612 1.01544L11.2946 3.68211L12.0388 4.98447ZM15.9612 13.0154L11.2946 15.6821L12.0388 16.9845L16.7054 14.3178L15.9612 13.0154ZM12.0021 15.6625L6.66874 12.9958L5.99792 14.3374L11.3313 17.0041L12.0021 15.6625ZM10.9167 4.33329V16H12.4167V4.33329H10.9167ZM5.58333 1.99996V13.6666H7.08333V1.99996H5.58333Z"
        fill="#651FFF"
      />
    </svg>
  );
}

export default Marker;
