import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  selectClientIp,
  selectUser,
} from '../../../../../user/redux/selectors';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectIsMapSearchEnabled,
  selectMapSearchPropertyType,
  // selectListContainerSize,
  selectResults,
} from '../../../../redux/selectors';
import { postSuburbGraph, postSuburbGraphs } from '../../redux/async_thunks';
import {
  selectDescriptors,
  selectGraphDisplaySettings,
  selectKeyIndicatorsValues,
  selectTimelineType,
} from '../../redux/selectors';
import {
  setGraphDisplaySettings,
  setIsGraphLoading,
  setTimelineType,
} from '../../redux/slice';
import LineChart from '../line_chart/LineChart';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const Graph = ({ setAreDetailsLoading }: Props) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const suburb = useAppSelector(selectActiveSuburb);
  const clientIp = useAppSelector(selectClientIp);
  const sscCode = useAppSelector(selectActiveSuburbSscCode);

  const results = useAppSelector(selectResults);
  let propertyType = results ? results.propertyType : 'Houses';

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  if (isMapSearchEnabled) {
    propertyType = mapSearchPropertyType;
  }

  const keyIndicators = useAppSelector(selectKeyIndicatorsValues);
  const descriptor = useAppSelector(selectDescriptors).graph;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const timelineType = useAppSelector(selectTimelineType);

  const graphDisplaySettings = useAppSelector(selectGraphDisplaySettings);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Graph section',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchGraphData = async (graphType: string, trendlineType = '3') => {
    try {
      // @ts-ignore
      dispatch(setIsGraphLoading({ key: graphType, status: true }));

      await dispatch(
        postSuburbGraph({
          data: {
            userId: user.userId,
            sscCode: sscCode,
            propertyType: propertyType,
            graphType: graphType,
            timelineYears: timelineType,
            trendlineYears: trendlineType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      // @ts-ignore
      dispatch(setIsGraphLoading({ key: graphType, status: false }));
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    // @ts-ignore
    dispatch(setIsGraphLoading({ key: graphType, status: false }));
  };

  const fetchGraphsData = async (trendlineType = '3') => {
    try {
      toggleLoader(true);

      await dispatch(
        postSuburbGraphs({
          data: {
            userId: user.userId,
            sscCode: sscCode,
            propertyType: propertyType,
            timelineYears: timelineType,
            trendlineYears: trendlineType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      toggleLoader(false);
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      user={user}
      suburb={suburb}
      keyIndicators={keyIndicators}
      graphDisplaySettings={graphDisplaySettings}
      timelineType={timelineType}
      fetchGraphData={fetchGraphData}
      fetchGraphsData={fetchGraphsData}
      descriptor={descriptor}
      setGraphDisplaySettings={setGraphDisplaySettings}
      setTimelineType={setTimelineType}
      isLoading={isLoading}
      LineChart={LineChart}
    />
  );
};

export default Graph;
