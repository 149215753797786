import { Modal } from 'antd';
import React, { useEffect } from 'react';

import Close from '../../../../../../foundation/assets/svgs/Close';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectBreakpoint } from '../../../../../layout/redux/selectors';
import { setIsSaveSearchModalActive } from '../../../../redux/slice';
import SaveSearch from '../SaveSearch';

const SaveSearchModal = () => {
  const dispatch = useAppDispatch();
  const breakpoint = useAppSelector(selectBreakpoint);

  const cancelHandler = () => {
    dispatch(setIsSaveSearchModalActive(false));
  };

  useEffect(() => {
    if (breakpoint === 'mobile') {
      cancelHandler();
    }
  }, [breakpoint]);

  return (
    <Modal
      title={'Save Search'}
      open={true}
      footer={null}
      onCancel={cancelHandler}
      centered
      maskClosable={false}
      width={455}
      closeIcon={
        <strong className="l-save-search-modal__close">
          <Close />
        </strong>
      }
    >
      <SaveSearch />
    </Modal>
  );
};

export default SaveSearchModal;
