import { Modal, Tabs } from 'antd';
import {
  ArcElement,
  Chart as ChartJS,
  defaults,
  registerables,
} from 'chart.js';
import { map as pluck } from 'lodash';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line
import { Chart } from 'react-chartjs-2';

import ArrowLeftTail from '../../../../foundation/assets/svgs/ArrowLeftTail';
import PriceRentIcon from '../../../../foundation/assets/svgs/AutoLayout';
import Close from '../../../../foundation/assets/svgs/Close';
import DwellingIcon from '../../../../foundation/assets/svgs/Dwelling';
import Filter from '../../../../foundation/assets/svgs/Filter';
import KeyIndicatorsIcon from '../../../../foundation/assets/svgs/KeyIndicators';
import PopulationIcon from '../../../../foundation/assets/svgs/Population';
import ProjectsIcon from '../../../../foundation/assets/svgs/Projects';
import RelatedArticlesIcon from '../../../../foundation/assets/svgs/RelatedArticles';
import Search from '../../../../foundation/assets/svgs/Search';
import DisclaimerModal from '../../../../foundation/components/static_content/disclaimer_modal/DisclaimerModal';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectClientIp,
  selectUser,
  selectUserFeatures,
} from '../../../user/redux/selectors';
import Heading from '../../common/heading/Heading';
import FilterModal from '../../form/filter/filter_modal/FilterModal';
import SearchLoader from '../../form/search/search_loader/SearchLoader';
import ShareSuburb from '../../form/search/share_suburb/ShareSuburb';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectIsDisclaimerModalActive,
  selectIsFilterModalActive,
  selectIsMapSearchEnabled,
  selectListContainerSize,
  selectQueryValues,
  selectResults,
} from '../../redux/selectors';
import { resetActiveSuburb, setIsFilterModalActive } from '../../redux/slice';
import Dwelling from './dwelling/Dwelling';
import KeyIndicators from './key_indicators/KeyIndicators';
import Population from './population/Population';
import PriceRentForecasts from './price_rent_forecasts/PriceRentForecasts';
import Projects from './projects/Projects';
import { postSuburbPopulation } from './redux/async_thunks';
import RelatedArticles from './related_articles/RelatedArticles';

ChartJS.register(ArcElement);
ChartJS.register(...registerables);

merge(defaults, {
  color: '#696c6e',
  borderColor: '#f2f3f4',
  backgroundColor: [
    '#50C8FC', // Blue
    '#33B433', // Green
    '#EE6D00', // Orange
    '#FDCC62', // Yellow
    '#FF968F', // Pick
    '#651FFF', // Purple
  ],
});

merge(defaults.font, {
  size: 13,
  family: 'Poppins',
});

merge(defaults.plugins.tooltip, {
  backgroundColor: 'rgba(0, 0, 0, 0.78)',
  cornerRadius: 5,
  caretPadding: 3,
  caretSize: 7,
  padding: 15,
  displayColors: false,
  position: 'nearest',
});

merge(defaults.elements.arc, {
  borderWidth: 0,
});

type Props = {
  isLargeScreen?: boolean;
};

const Details = ({ isLargeScreen = true }: Props) => {
  const dispatch = useAppDispatch();
  const [isMobileView] = useBreakpoint();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);
  const SSC_CODE = useAppSelector(selectActiveSuburbSscCode);
  const SUBURB = useAppSelector(selectActiveSuburb);

  const userFeatures = useAppSelector(selectUserFeatures);

  const queryValues = useAppSelector(selectQueryValues);
  const listContainerSize = useAppSelector(selectListContainerSize);
  const isFilterModalActive = useAppSelector(selectIsFilterModalActive);
  const isDisclaimerModalActive = useAppSelector(selectIsDisclaimerModalActive);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const results = useAppSelector(selectResults);
  const hasResults =
    !!results &&
    ((!!results.suburbs && results.suburbs.length) ||
      (!!results.properties && results.properties.length));

  const [areDetailsLoading, setAreDetailsLoading] = useState(false);
  const [activeTabState, setActiveTabState] = useState<string>('1');

  const [preview, setPreview] = useState<any>();

  const [isShareSuburbModalVisible, setIsShareSuburbModalVisible] =
    useState(false);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResultsOptions = {
    afterCb: () => {
      dispatch(resetActiveSuburb());
    },
    pageIndexParam: 1,
  };

  const fetchPopulationDetails = async () => {
    // Note that the main loading indicator is turned of inside detail child components
    setAreDetailsLoading(true);

    try {
      await dispatch(
        postSuburbPopulation({
          data: {
            userId: USER.userId,
            sscCode: SSC_CODE,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
    } catch (error) {
      errorHandler(error);
      setAreDetailsLoading(false);
    }
  };

  const shareSuburbModalCloseHandler = () => {
    setPreview(null);
    setIsShareSuburbModalVisible(false);
  };

  useEffect(() => {
    if (SSC_CODE) {
      fetchPopulationDetails();
    }
  }, [SSC_CODE]);

  if (!SUBURB) {
    return (
      <section
        className={`${
          isLargeScreen
            ? 'l-details l-details--' + listContainerSize
            : 'l-details l-details--tablet-mobile'
        }`}
      >
        <div className="l-details__body">
          <SearchLoader style={{ width: 'calc(100% - 10px)' }} />
        </div>
      </section>
    );
  }

  const isLayoutNarrow =
    listContainerSize === 'compact' || listContainerSize === 'hidden';

  let tabsBodyClassName = `l-details__tabs-body${
    !hasResults ? ' l-details__tabs-body--short' : ''
  }`;

  const suburbProfileComponents: any = {
    'suburb-population': {
      label:
        isLayoutNarrow || isMobileView ? (
          <PopulationIcon />
        ) : (
          <span className="l-details__tab-label">Population</span>
        ),
      key: '1',
      children: (
        <>
          {activeTabState === '1' && (
            <div className={tabsBodyClassName}>
              <Population setAreDetailsLoading={setAreDetailsLoading} />
            </div>
          )}
        </>
      ),
    },
    'suburb-dwelling': {
      label:
        isLayoutNarrow || isMobileView ? (
          <DwellingIcon />
        ) : (
          <span className="l-details__tab-label">Dwelling</span>
        ),
      key: '2',
      children: (
        <>
          {activeTabState === '2' && (
            <div className={tabsBodyClassName}>
              <Dwelling setAreDetailsLoading={setAreDetailsLoading} />
            </div>
          )}
        </>
      ),
    },
    'suburb-market-indicators': {
      label:
        isLayoutNarrow || isMobileView ? (
          <KeyIndicatorsIcon />
        ) : (
          <span className="l-details__tab-label">
            Key
            <br />
            Indicators
          </span>
        ),
      key: '3',
      children: (
        <>
          {activeTabState === '3' && (
            <div className={tabsBodyClassName}>
              <KeyIndicators setAreDetailsLoading={setAreDetailsLoading} />
            </div>
          )}
        </>
      ),
    },
    'suburb-pricerent-forecast': {
      label:
        isLayoutNarrow || isMobileView ? (
          <PriceRentIcon />
        ) : (
          <span className="l-details__tab-label">
            Price & Rent
            <br />
            Forecasts
          </span>
        ),
      key: '4',
      children: (
        <>
          {activeTabState === '4' && (
            <div className={tabsBodyClassName}>
              <PriceRentForecasts setAreDetailsLoading={setAreDetailsLoading} />
            </div>
          )}
        </>
      ),
    },
    'suburb-projects': {
      label:
        isLayoutNarrow || isMobileView ? (
          <ProjectsIcon />
        ) : (
          <span className="l-details__tab-label">Projects</span>
        ),
      key: '5',
      children: (
        <>
          {activeTabState === '5' && (
            <div className={tabsBodyClassName}>
              <Projects setAreDetailsLoading={setAreDetailsLoading} />
            </div>
          )}
        </>
      ),
    },
    'suburb-articles': {
      label:
        isLayoutNarrow || isMobileView ? (
          <RelatedArticlesIcon />
        ) : (
          <span className="l-details__tab-label">
            Related
            <br />
            Articles
          </span>
        ),
      key: '6',
      children: (
        <>
          {activeTabState === '6' && (
            <div className={tabsBodyClassName}>
              <RelatedArticles
                setAreDetailsLoading={setAreDetailsLoading}
                areDetailsLoading={areDetailsLoading}
              />
            </div>
          )}
        </>
      ),
    },
  };

  const tabItems: any = [];

  Object.keys(suburbProfileComponents).forEach((sp: any) => {
    if (userFeatures.includes(sp)) {
      tabItems.push(suburbProfileComponents[sp]);
    }
  });

  if (isMobileView) {
    tabsBodyClassName = 'l-details__tabs-body l-details__tabs-body--mobile';
  }

  return (
    <>
      {isShareSuburbModalVisible && SSC_CODE && (
        <Modal
          title={'Share Suburb'}
          open={true}
          footer={false}
          onCancel={shareSuburbModalCloseHandler}
          centered
          maskClosable={false}
          width={455}
          closeIcon={
            <strong className="l-filter-modal__close">
              <Close />
            </strong>
          }
        >
          <ShareSuburb
            previewLink={preview.linkUrl}
            cancelHandler={shareSuburbModalCloseHandler}
            sscCode={SSC_CODE}
          />
        </Modal>
      )}
      {isFilterModalActive && (
        <FilterModal searchHandlerOptions={fetchResultsOptions} />
      )}
      {isDisclaimerModalActive && <DisclaimerModal />}
      <section
        className={`${
          isLargeScreen
            ? 'l-details l-details--' + listContainerSize
            : 'l-details l-details--tablet-mobile'
        }`}
      >
        {!!SUBURB && areDetailsLoading && (
          <SearchLoader style={{ width: 'calc(100% - 10px)' }} />
        )}
        <div className="l-details__body">
          {isLargeScreen && (
            <>
              {hasResults || isMapSearchEnabled ? (
                <div className="l-details__go-back">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetActiveSuburb());
                    }}
                  >
                    {isMapSearchEnabled ? (
                      <>
                        <Close color={'#651FFF'} />
                        Close
                      </>
                    ) : (
                      <>
                        <ArrowLeftTail />
                        Back to results
                      </>
                    )}
                  </a>
                </div>
              ) : (
                <div
                  className="l-list__search-bar"
                  style={{ marginBottom: '27px' }}
                >
                  <div
                    className="l-list__search-icon"
                    onClick={() => {
                      dispatch(setIsFilterModalActive(true));
                    }}
                  >
                    <Search />
                  </div>
                  <div
                    className="l-list__query-values"
                    onClick={() => {
                      dispatch(setIsFilterModalActive(true));
                    }}
                  >
                    {queryValues && queryValues.length ? (
                      <>{pluck(queryValues, 'key').join('; ')}</>
                    ) : (
                      'Search'
                    )}
                  </div>
                  <div className="l-list__search-bar-actions">
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(setIsFilterModalActive(true));
                      }}
                      className="l-list__button-common l-list__button-common--light"
                    >
                      <Filter />
                      {!isLayoutNarrow && <span>Filter</span>}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          <Heading suburb={SUBURB} isReadOnly />
          <div className="l-details__tabs l-tabs--overrides">
            {!!tabItems.length && (
              <Tabs
                defaultActiveKey="1"
                centered={isLayoutNarrow || isMobileView}
                moreIcon={null}
                onChange={(activeKey) => {
                  setActiveTabState(activeKey);
                }}
                items={tabItems}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
