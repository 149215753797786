import React from 'react';

import ConversationListItemSkeleton from '../skeletons/ConversationListItem';
import ListItem from './ListItem';

type DMListProps = {
  conversations: any[];
  isDMListLoading: boolean;
  setDMListLoading: (v: boolean) => void;
  selectedConversation: any;
};

const DMList = ({
  isDMListLoading,
  selectedConversation,
  conversations,
}: DMListProps) => {
  return (
    <div className="l-chat-panel__section">
      {isDMListLoading && <ConversationListItemSkeleton />}
      {!isDMListLoading &&
        conversations &&
        conversations.map((conversation) => (
          <ListItem
            conversation={conversation}
            key={conversation.conversationId}
            selectedConversation={selectedConversation}
          />
        ))}
    </div>
  );
};

export default DMList;
