import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { detect } from 'detect-browser';
import localforage from 'localforage';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { selectccUser } from '../../../features/chat/redux/selectors';
import { setCometChatUser } from '../../../features/chat/redux/slice';
import { initializeComitChat } from '../../../features/chat/utils/comet_chat';
import Layout from '../../../features/layout/Layout';
import { setListContainerSize } from '../../../features/search/redux/slice';
import AccessControl from '../../../features/user/access_control/AccessControl';
import {
  getAppValues,
  getClientIP,
  getSubscriptionDetails,
  patchRefresh,
  postLogin,
} from '../../../features/user/redux/async_thunks';
import {
  selectClientIp,
  selectIsOnboarded,
  selectIsSubscribed,
  selectUser,
  selectUserFeatures,
} from '../../../features/user/redux/selectors';
import { selectNotifications } from '../../../features/user/redux/selectors';
import { selectUserCometChatConfig } from '../../../features/user/redux/selectors';
import {
  setHasNotifications,
  setIsOnboarded,
} from '../../../features/user/redux/slice';
import { setNotifications } from '../../../features/user/redux/slice';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { decryptData } from '../../../foundation/utils/api';
import { auth0Util } from '../../../foundation/utils/auth0';
import { clearSessionStorage } from '../../../foundation/utils/session_storage';
import {
  getFirebaseToken,
  onForegroundMessage,
} from '../../../internals/firebase/firebase';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useLogout from '../../custom_hooks/useLogout';
import messages from '../../messages';

const PrivateRoute = ({
  requiredFeature,
  children,
}: {
  requiredFeature?: string;
  children: any;
}) => {
  const TOTAL_REFRESH_ATTEMPTS = 12;
  const REFRESH_DELAY = 2000;

  const browser = detect();
  const userFeatures = useAppSelector(selectUserFeatures);

  const { pathname } = useLocation();
  const logoutUser = useLogout();

  const isAfterPayment = pathname === '/success';
  const isMessagingAccessible = userFeatures.includes('messaging');

  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();

  const [timeStamp, setTimeStamp] = useState<number>();
  const [firebaseToken, setFirebaseToken] = useState<any>(undefined);
  const [refreshAttempts, setRefreshAttempts] = useState(
    TOTAL_REFRESH_ATTEMPTS,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isOnboarded = useAppSelector(selectIsOnboarded);

  const ccConfig = useAppSelector(selectUserCometChatConfig);
  const ccUser = useAppSelector(selectccUser);

  const notifications = useAppSelector(selectNotifications);

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);
  const IS_SUBSCRIBED = useAppSelector(selectIsSubscribed);

  const expiredSubscriptionRedirect =
    USER && USER.userRole !== 9 ? '/subscription-expired' : '/billing';

  const { logout } = useAuth0();

  auth0Util.setLogout(logout);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  onForegroundMessage()
    .then((payload) => {
      console.log('Received foreground message: ', payload);

      const {
        notification: { title, body },
      }: any = payload;

      dispatch(
        setNotifications([...(notifications || []), { title, body }].reverse()),
      );

      dispatch(setHasNotifications(true));
    })
    .catch((err) =>
      console.log(
        'An error occured while retrieving foreground message. ',
        err,
      ),
    );

  const handleCometChatIntegration = async () => {
    try {
      if (user && ccConfig && ccConfig.appId && ccConfig.authKey) {
        const ccUser: any = await initializeComitChat(
          ccConfig.appId,
          ccConfig.authKey,
          USER.ccUserId,
        );
        dispatch(setCometChatUser(JSON.parse(JSON.stringify(ccUser))));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: messages.comet_chat.ERROR_INITIAL_COMET_CHAT,
      });
    }
  };

  const handleGetFirebaseToken = async (isRefreshing = false) => {
    getFirebaseToken(isRefreshing)
      .then((firebaseToken) => {
        console.log('Firebase Token ->', firebaseToken);
        setFirebaseToken(firebaseToken);
        localforage.removeItem('fc');
      })
      .catch((err) => {
        setFirebaseToken(null);
        console.error(
          'An error occured while retrieving firebase token. ',
          err,
        );
      });
  };

  const fetchClientIP = async () => {
    try {
      const response = await dispatch(getClientIP())
        // @ts-ignore
        .unwrap();

      setTimeStamp(response.timeStamp);
    } catch (error) {
      errorHandler(error);
    }
  };

  const login = async () => {
    try {
      await dispatch(
        postLogin({
          data: {
            auth0user: user,
            browserToken: firebaseToken,
            browser: browser?.name,
            browserOs: browser?.os,
          },
          options: {
            clientIp: CLIENT_IP,
            timeStamp: timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap()
        .then((responseData: any) => {
          // The component is expecting the user's data from the response
          // Will redirect to the error page if data is unavailable
          if (responseData.status === 204 || !responseData) {
            clearSessionStorage();
            navigate('/error');

            // TODO
            // navigate('/error', {
            //   error: {
            //     responseMessage: 'User does not exist',
            //     responseCode: responseData.status,
            //   },
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      errorHandler(error);
    }
  };

  const setUser = async () => {
    try {
      const user = sessionStorage.getItem('_u');

      if (!!user && !!CLIENT_IP) {
        const parsed = decryptData(user);

        await dispatch(
          patchRefresh({
            data: {
              userId: parsed.userId,
              browserToken: firebaseToken,
              browser: browser?.name,
              browserOs: browser?.os,
            },
            options: {
              token: parsed.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: parsed.sessionId,
            },
          }),
          // @ts-ignore
        ).unwrap();
      } else {
        navigate('/unauthorized');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchAppValues = async () => {
    try {
      if (USER && CLIENT_IP) {
        await dispatch(
          getAppValues({
            options: {
              token: USER.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: USER.sessionId,
            },
          }),
          // @ts-ignore
        ).unwrap();
        dispatch(setIsOnboarded(true));
      } else {
        navigate('/unauthorized');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      if (USER && CLIENT_IP) {
        await dispatch(
          getSubscriptionDetails({
            options: {
              token: USER.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: USER.sessionId,
            },
          }),
          // @ts-ignore
        ).unwrap();
      } else {
        navigate('/unauthorized');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const isRefreshing = !!sessionStorage.getItem('_u') && !USER;

    if (firebaseToken === undefined) {
      handleGetFirebaseToken(isRefreshing);
    }

    dispatch(
      setListContainerSize(window.innerWidth < 1400 ? 'compact' : 'wide'),
    );
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      (firebaseToken || firebaseToken === null) &&
      !sessionStorage.getItem('_u') &&
      isAuthenticated &&
      !isLoading
    ) {
      fetchClientIP();
    }
  }, [isLoading, isAuthenticated, firebaseToken]);

  useEffect(() => {
    if (CLIENT_IP && timeStamp) {
      login();
    }
  }, [CLIENT_IP, timeStamp]);

  useEffect(() => {
    if (
      (firebaseToken || firebaseToken === null) &&
      sessionStorage.getItem('_u') &&
      !USER
    ) {
      setUser();
    }
  }, [firebaseToken]);

  useEffect(() => {
    if (user && ccConfig && ccConfig.appId && USER) {
      handleCometChatIntegration();
    }
  }, [ccConfig, user, USER]);

  useEffect(() => {
    let timer: any;

    if (
      USER &&
      isAfterPayment &&
      isMessagingAccessible &&
      ccConfig &&
      !ccConfig.appId
    ) {
      if (refreshAttempts) {
        setRefreshAttempts(refreshAttempts - 1);
        timer = setTimeout(() => {
          console.log('Unresolved. Refreshing...', refreshAttempts);
          setUser();
        }, REFRESH_DELAY);
      } else {
        console.log('Failed. Logging out...');
        logoutUser('failed');
      }
    } else if (USER) {
      console.log('Proceeding...');
      fetchAppValues();

      if (USER.userRole === 9) {
        fetchSubscriptionDetails();
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [USER]);

  return (
    <>
      {/* For users with access to messaging, make sure that the ccUser object is resolved before proceeding  */}
      {/* Otherwise, if no access to messaging, proceed casually */}
      {!isOnboarded &&
        ((isMessagingAccessible && !ccUser) || !isMessagingAccessible) && (
          <>
            {isAfterPayment ? (
              <FullPageLoader message="Setting up your account. Please wait..." />
            ) : (
              <FullPageLoader />
            )}
          </>
        )}
      {isOnboarded && isAfterPayment && isMessagingAccessible && !ccUser && (
        <FullPageLoader message="Finishing up..." />
      )}
      {isOnboarded &&
        ((isMessagingAccessible && !!ccUser) || !isMessagingAccessible) && (
          <Layout>
            {requiredFeature ? (
              <AccessControl
                requiredFeature={requiredFeature}
                // For private routes within a restricted feature, user is redirected to the billing page if subscription doesn't exist
                redirectRoute={
                  IS_SUBSCRIBED ? '/unauthorized' : expiredSubscriptionRedirect
                }
              >
                {children}
              </AccessControl>
            ) : (
              <>{children}</>
            )}
          </Layout>
        )}
    </>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

export default PrivateRoute;
