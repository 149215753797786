import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import View from '../../../search/view/details/price_rent_forecasts/view/View';
import { postReportDetail } from '../../redux/async_thunks';
import { selectReportUser } from '../../redux/selectors';
import {
  selectKeyIndicatorsValues,
  selectSuburb,
  selectTimelineType,
} from '../../redux/selectors';
import { setTimelineType } from '../../redux/slice';
import LineChart from '../key_indicators/line_chart/LineChart';

type Props = {
  setAreDetailsLoading?: any;
};

const PriceRentForecast = ({ setAreDetailsLoading }: Props) => {
  const suburb = useAppSelector(selectSuburb);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const keyIndicators = useAppSelector(selectKeyIndicatorsValues);
  const user = useAppSelector(selectReportUser);

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const timelineType = useAppSelector(selectTimelineType);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Graph section',
      description: 'Something went wrong with the request',
    });
  };

  const fetchForecastData = async () => {
    try {
      toggleLoader(true);

      await dispatch(
        postReportDetail({
          data: {
            userId: user.userId,
            timelineYears: timelineType,
          },
          options: {
            // @ts-ignore
            token: user.jwtToken.token,
            clientIp: user.clientIp,
            sessionId: user.sessionId,
          },
          endpoint: 'predictions',
        }),
      )
        // @ts-ignore
        .unwrap();

      toggleLoader(false);

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (error: any) {
      console.log(error);
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);
  };

  return (
    <View
      user={user}
      suburb={suburb}
      timelineType={timelineType}
      setTimelineType={setTimelineType}
      keyIndicators={keyIndicators}
      fetchForecastData={fetchForecastData}
      isLoading={isLoading}
      LineChart={LineChart}
    />
  );
};

export default PriceRentForecast;
