import { useAuth0 } from '@auth0/auth0-react';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';

const EmailVerification = () => {
  const { logout, isAuthenticated } = useAuth0();
  const [isLogoutButtonVisible, setIsLogoutButtonVisible] =
    useState<boolean>(false);

  const handleLogoutClick = () => {
    logout({
      returnTo: `${window.location.origin}/logout`,
    });
  };

  const renderLogoutButton = () => {
    return isLogoutButtonVisible ? (
      <Button type="primary" onClick={handleLogoutClick}>
        Logout
      </Button>
    ) : null;
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLogoutButtonVisible(true);
    }
  }, [isAuthenticated]);

  return (
    <Result
      status="warning"
      title="Verify Email"
      subTitle="Your registered email address must be verified before you can proceed. Please check the verification email in your inbox."
      extra={renderLogoutButton()}
    />
  );
};

export default EmailVerification;
