import React from 'react';

const Bin = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 5V4C17 2.34315 15.6569 1 14 1H10C8.34315 1 7 2.34315 7 4V5M1 5H23M15 11V16M9 11V16M3 5H21L20.3375 18.2497C20.2045 20.9107 18.0081 23 15.3438 23H8.65624C5.99187 23 3.79554 20.9107 3.66248 18.2497L3 5Z"
        stroke="#CB0049"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Bin;
