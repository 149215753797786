import { Carousel } from 'antd';
import { map as pluck } from 'lodash';
import React, { useEffect, useState } from 'react';

import ArrowLeftTail from '../../../../foundation/assets/svgs/ArrowLeftTail';
import Bath from '../../../../foundation/assets/svgs/Bath';
import Bed from '../../../../foundation/assets/svgs/Bed';
import Car from '../../../../foundation/assets/svgs/Car';
import CarouselBack from '../../../../foundation/assets/svgs/CarouselBack';
import CarouselForward from '../../../../foundation/assets/svgs/CarouselForward';
import Close from '../../../../foundation/assets/svgs/Close';
import Filter from '../../../../foundation/assets/svgs/Filter';
import House from '../../../../foundation/assets/svgs/House';
import Search from '../../../../foundation/assets/svgs/Search';
import StarPlain from '../../../../foundation/assets/svgs/StarPlain';
import StarPurple from '../../../../foundation/assets/svgs/StarPurple';
import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import Favorite from '../../common/favorite/Favorite';
import FilterModal from '../../form/filter/filter_modal/FilterModal';
import SearchLoader from '../../form/search/search_loader/SearchLoader';
import { postPropertyDetails } from '../../redux/async_thunks';
import {
  getIsFavorite,
  selectActiveProperty,
  selectActivePropertyId,
  selectEnabledSearchMetrics,
  selectIsFilterModalActive,
  selectIsMapSearchEnabled,
  selectListContainerSize,
  selectQueryValues,
  selectResults,
} from '../../redux/selectors';
import {
  resetActiveProperty,
  setIsFilterModalActive,
  setIsSkippingMapFocus,
} from '../../redux/slice';
import EstimateGauge from './estimate_gauge/EstimateGauge';
import Lightbox from './lightbox/Lightbox';
import StaticMap from './static_map/StaticMap';

const PropertyDetails = ({
  isLargeScreen = true,
}: {
  isLargeScreen?: boolean | undefined;
}) => {
  const searchType = useSearchType();
  const carouselRef = React.useRef<any>(null);
  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);
  const PROPERTY_ID = useAppSelector(selectActivePropertyId);
  const PROPERTY = useAppSelector(selectActiveProperty);

  const isPropertyInFavoriteList = useAppSelector(
    getIsFavorite(PROPERTY_ID, searchType),
  );

  const isPropertyFavorite =
    (PROPERTY && PROPERTY.isFavorite) || isPropertyInFavoriteList;

  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isFilterModalActive = useAppSelector(selectIsFilterModalActive);

  const dispatch = useAppDispatch();
  const listContainerSize = useAppSelector(selectListContainerSize);
  const queryValues = useAppSelector(selectQueryValues);
  const results = useAppSelector(selectResults);
  const hasResults =
    !!results && !!results.properties && results.properties.length;

  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const isLayoutNarrow =
    listContainerSize === 'compact' || listContainerSize === 'hidden';

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResultsOptions = {
    afterCb: () => {
      dispatch(resetActiveProperty());
    },
    pageIndexParam: 1,
  };

  const fetchPropertyDetails = async () => {
    setIsDetailLoading(true);

    try {
      await dispatch(
        postPropertyDetails({
          data: {
            userId: USER.userId,
            propertyId: PROPERTY_ID,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
    } catch (e) {
      errorHandler(e);
    }

    setIsDetailLoading(false);
  };

  const handleCloseLightbox = (lightboxCurrentSlide: number) => {
    setIsLightboxVisible(false);
    setCurrentSlide(lightboxCurrentSlide);
    carouselRef.current.goTo(lightboxCurrentSlide, true);
  };

  const appendClassName = (className: string, metric: string) => {
    const modifier = 'h-bold';
    return enabledSearchMetrics.includes(metric)
      ? `${className} ${modifier}`
      : className;
  };

  const getClass = () => {
    return `l-property-details${
      isMapSearchEnabled ? ' l-property-details--' + listContainerSize : ''
    }`;
  };

  useEffect(() => {
    if (PROPERTY_ID) {
      fetchPropertyDetails();
    }
  }, [PROPERTY_ID]);

  if (!PROPERTY) {
    return (
      <section className={getClass()}>
        <div className="l-property-details__body">
          <SearchLoader style={{ width: '100%' }} />
        </div>
      </section>
    );
  }

  return (
    <>
      {isLightboxVisible && (
        <Lightbox
          images={PROPERTY.images}
          handleCloseLightbox={handleCloseLightbox}
          currentSlide={currentSlide}
        />
      )}
      {isFilterModalActive && (
        <FilterModal searchHandlerOptions={fetchResultsOptions} />
      )}
      <section className={getClass()}>
        <div className="l-property-details__body">
          {isDetailLoading && (
            <SearchLoader
              style={{
                width: '100%',
              }}
            />
          )}
          {isLargeScreen && (
            <>
              {hasResults || isMapSearchEnabled ? (
                <div className="l-details__go-back">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetActiveProperty());
                      dispatch(setIsSkippingMapFocus(true));
                    }}
                  >
                    {isMapSearchEnabled ? (
                      <>
                        <Close color={'#651FFF'} />
                        Close
                      </>
                    ) : (
                      <>
                        <ArrowLeftTail />
                        Return to map
                      </>
                    )}
                  </a>
                </div>
              ) : (
                <div
                  className="l-list__search-bar"
                  style={{ marginBottom: '27px' }}
                >
                  <div
                    className="l-list__search-icon"
                    onClick={() => {
                      dispatch(setIsFilterModalActive(true));
                    }}
                  >
                    <Search />
                  </div>
                  <div
                    className="l-list__query-values"
                    onClick={() => {
                      dispatch(setIsFilterModalActive(true));
                    }}
                  >
                    {queryValues && queryValues.length ? (
                      <>{pluck(queryValues, 'key').join('; ')}</>
                    ) : (
                      'Search'
                    )}
                  </div>
                  <div className="l-list__search-bar-actions">
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(setIsFilterModalActive(true));
                      }}
                      className="l-list__button-common l-list__button-common--light"
                    >
                      <Filter />
                      {!isLayoutNarrow && <span>Filter</span>}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          <div
            className={`l-property-details__flex${
              !hasResults ? ' l-property-details__flex--short' : ''
            }`}
          >
            <div className="l-property-details__content">
              <div className="l-property">
                {PROPERTY.images && PROPERTY.images.length ? (
                  <>
                    {PROPERTY.images && PROPERTY.images.length && (
                      <div className="l-property__carousel-wrapper">
                        {PROPERTY.images.length > 1 && (
                          <>
                            <button
                              className="l-property__carousel-prev"
                              onClick={() => {
                                carouselRef?.current?.prev();
                              }}
                            >
                              <CarouselBack />
                            </button>
                            <button
                              className="l-property__carousel-next"
                              onClick={() => {
                                carouselRef?.current?.next();
                              }}
                            >
                              <CarouselForward />
                            </button>
                          </>
                        )}
                        <Carousel
                          ref={carouselRef}
                          afterChange={(current) => {
                            setCurrentSlide(current);
                          }}
                        >
                          {PROPERTY.images.map((i: any, j: number) => {
                            return (
                              <div
                                className="l-property__carousel-slide"
                                key={j}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    PROPERTY.images &&
                                    PROPERTY.images.length
                                  ) {
                                    setIsLightboxVisible(true);
                                  }
                                }}
                              >
                                <img
                                  src={i}
                                  alt="Property"
                                  onError={(e: any) => {
                                    // TD: Apply a placeholder image
                                    // const placeholderImage = '';
                                    // e.target.src = placeholderImage;
                                  }}
                                />
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="l-property__carousel-slide l-property__carousel-slide--empty">
                    <House />
                  </div>
                )}
                <div className="l-property__content">
                  <div className="l-property__heading">
                    <span className={appendClassName('', 'askingPrice')}>
                      Offers over {PROPERTY.askingPrice}
                    </span>
                    <Favorite
                      className="l-list__button-common l-list__button-common--light"
                      property={PROPERTY}
                      style={{ maxWidth: '170px', minWidth: '150px' }}
                    >
                      {isPropertyFavorite ? <StarPurple /> : <StarPlain />}
                      <span>
                        {isPropertyFavorite ? 'Saved' : 'Save'} Property
                      </span>
                    </Favorite>
                  </div>
                  <div
                    className={appendClassName(
                      'l-property__tag-umv',
                      'umvEstimate',
                    )}
                  >
                    UMV estimate {PROPERTY.umvEstimate}
                  </div>
                  <p className="l-property__address">{PROPERTY.address}</p>
                  <ul className="l-property__tag-group">
                    <li
                      className={appendClassName(
                        'l-property__tag-details',
                        'bedrooms',
                      )}
                    >
                      <Bed />
                      {PROPERTY.beds}
                    </li>
                    <li
                      className={appendClassName(
                        'l-property__tag-details',
                        'baths',
                      )}
                    >
                      <Bath />
                      {PROPERTY.baths}
                    </li>
                    <li
                      className={appendClassName(
                        'l-property__tag-details',
                        'carSpaces',
                      )}
                    >
                      <Car />
                      {PROPERTY.cars}
                    </li>
                    <li
                      className={appendClassName(
                        'l-property__tag-details',
                        'landSize',
                      )}
                    >
                      {PROPERTY.floorSpace}
                    </li>
                    <li className="l-property__tag-details">
                      {PROPERTY.propertyType === 'Houses' ? 'House' : 'Unit'}
                    </li>
                  </ul>
                  <p className="l-property__description">
                    <strong>Property Description</strong>
                    <span>{PROPERTY.description}</span>
                  </p>
                  <div className="l-property-details__estimate-group">
                    <EstimateGauge
                      estimate={PROPERTY.estMarketPrice}
                      keyName="Market Value"
                    />
                    <EstimateGauge
                      estimate={PROPERTY.estMarketRent}
                      keyName="Market Rent"
                      unit="pw"
                    />
                  </div>
                  <ul className="l-property__tag-list">
                    <li>
                      <span
                        className={appendClassName('', 'averageDaysMarket')}
                      >
                        Days on Market
                      </span>
                      <span
                        className={appendClassName(
                          'l-property__tag-details',
                          'averageDaysMarket',
                        )}
                      >
                        {PROPERTY.daysOnMarket}
                      </span>
                    </li>
                    <li>
                      <span className={appendClassName('', 'grossRentalYield')}>
                        Estimated gross rental yield
                      </span>
                      <span
                        className={appendClassName(
                          'l-property__tag-details',
                          'grossRentalYield',
                        )}
                      >
                        {PROPERTY.grossRentalYield}
                      </span>
                    </li>
                    {PROPERTY.rentalMarket && (
                      <li>
                        <span>
                          Rental market %<small>(300m radius)</small>
                        </span>
                        <span className="l-property__tag-details">
                          {PROPERTY.rentalMarket}
                        </span>
                      </li>
                    )}
                    {PROPERTY.publicHousing && (
                      <li>
                        <span className={appendClassName('', 'publicHousing')}>
                          Public housing<small>(300m radius)</small>
                        </span>
                        <span
                          className={appendClassName(
                            'l-property__tag-details',
                            'publicHousing',
                          )}
                        >
                          {PROPERTY.publicHousing}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <StaticMap />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyDetails;
