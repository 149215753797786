import React from 'react';

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="11"
      fill="none"
      viewBox="0 0 7 11"
    >
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.975.833L1.333 5.475l4.642 4.643"
      ></path>
    </svg>
  );
}

export default BackIcon;
