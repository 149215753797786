import { notification } from 'antd';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  selectClientIp,
  selectUser,
} from '../../../../../user/redux/selectors';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectIsMapSearchEnabled,
  selectListContainerSize,
  selectMapSearchPropertyType,
  selectResults,
} from '../../../../redux/selectors';
import { postSuburbGrowth } from '../../redux/async_thunks';
import { selectDescriptors, selectGrowth } from '../../redux/selectors';
import View from './view/View';

const Growth = ({
  toggleLoader,
  setIsFirstLoad,
  isFirstLoad,
  isLoading,
}: any) => {
  const dispatch = useAppDispatch();
  const growth = useAppSelector(selectGrowth);

  const user = useAppSelector(selectUser);
  const suburb = useAppSelector(selectActiveSuburb);
  const clientIp = useAppSelector(selectClientIp);
  const sscCode = useAppSelector(selectActiveSuburbSscCode);

  const results = useAppSelector(selectResults);
  let propertyType = results ? results.propertyType : 'Houses';

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  if (isMapSearchEnabled) {
    propertyType = mapSearchPropertyType;
  }

  const listContainerSize = useAppSelector(selectListContainerSize);

  const descriptor = useAppSelector(selectDescriptors).growth;

  const errorHandler = () => {
    notification.error({
      message: 'Error: Growth section',
      description: 'Something went wrong with the request',
    });
  };

  const fetchGrowthData = async () => {
    toggleLoader(true);

    try {
      await dispatch(
        postSuburbGrowth({
          data: {
            userId: user.userId,
            sscCode: sscCode,
            propertyType: propertyType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      growth={growth}
      descriptor={descriptor}
      fetchGrowthData={fetchGrowthData}
      user={user}
      suburb={suburb}
      isLoading={isLoading}
      isFirstLoad={isFirstLoad}
      listContainerSize={listContainerSize}
    />
  );
};

export default Growth;
