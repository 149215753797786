import { Tabs } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch } from '../../../../store/hooks';
import { setSearchTypeWithReset } from '../../redux/slice';

const Toggler = () => {
  const searchType = useSearchType();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tabItems = [
    {
      label: 'Suburb Search',
      key: 'suburb',
      children: <></>,
    },
    {
      label: 'Property Search',
      key: 'property',
      children: <></>,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="suburbs"
      activeKey={searchType}
      className="l-toggler"
      centered={false}
      onTabClick={(key: string) => {
        dispatch(setSearchTypeWithReset(key));
        navigate(`/${key}-search`);
      }}
      items={tabItems}
    />
  );
};

export default Toggler;
