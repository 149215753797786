import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';

import ExclamationOutlined from '../../assets/svgs/ExclamantionOutlined';
import useLogout from '../../custom_hooks/useLogout';

const DeleteSuccess = () => {
  const { isAuthenticated } = useAuth0();
  const [isLogoutButtonVisible, setIsLogoutButtonVisible] =
    useState<boolean>(false);

  const logoutUser = useLogout();

  const handleLogoutClick = () => {
    logoutUser(true);
  };

  const renderLogoutButton = () => {
    return isLogoutButtonVisible ? (
      <button
        type="button"
        className="l-custom-result__button"
        onClick={handleLogoutClick}
      >
        Logout
      </button>
    ) : null;
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLogoutButtonVisible(true);
    }
  }, [isAuthenticated]);

  return (
    <div className="l-custom-result">
      <div className="l-custom-result__content">
        <div className="l-custom-result__icon">
          <ExclamationOutlined />
        </div>
        <h4>No Access</h4>
        <p>
          Your organization&apos;s subscription has expired. Please contact the
          admin.
        </p>
        {renderLogoutButton()}
      </div>
    </div>
  );
};

export default DeleteSuccess;
