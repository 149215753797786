import React from 'react';

function OrderedList() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path stroke="#AFAFAF" d="M14 3H6M14 7H6M14 11H6"></path>
      <path
        fill="#AFAFAF"
        d="M.189 2.003V.89h1.939V6H.882V2.003H.189zm.084 10.038a25.897 25.897 0 001.295-1.106c.266-.243.49-.497.672-.763.182-.266.273-.525.273-.777 0-.191-.044-.34-.133-.448-.089-.107-.222-.161-.399-.161a.499.499 0 00-.42.203c-.098.13-.147.317-.147.56H.259c.01-.397.093-.728.252-.994a1.51 1.51 0 01.637-.588c.266-.126.56-.189.882-.189.555 0 .973.142 1.253.427.285.285.427.656.427 1.113 0 .5-.17.964-.511 1.393a7.6 7.6 0 01-1.302 1.246h1.89v.973H.273v-.889z"
      ></path>
    </svg>
  );
}

export default OrderedList;
