import { RootState } from '../../../store';

export const selectReportKeyword = (state: RootState) => state.aiReport.keyword;

export const selectReportSuggestions = (state: RootState) =>
  state.aiReport.suggestions;

export const selectReportQueryValues = (state: RootState) =>
  state.aiReport.queryValues;

export const selectAiReportResults = (state: RootState) =>
  state.aiReport.report;
