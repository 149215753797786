import { createAsyncThunk } from '@reduxjs/toolkit';

import { endpoint } from '../../../foundation/config/axios_instances';
// import messages from '../../../foundation/messages';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';
import {
  setStripeClientSecretId,
  setStripeCustomerId,
  setStripeSubscriptionId,
  setSubscriptionPlanDetails,
} from '../../user/redux/slice';

export const createCustomer = createAsyncThunk(
  'billing/create-customer',
  async (apiParams: { data: { userId: string }; options: any }, thunkAPI) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Subs/customer`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          const decryptedData = decryptData(response.data);
          thunkAPI.dispatch(
            setStripeCustomerId(decryptedData?.customer?.customerId),
          );
          result = decryptedData;

          return result;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });
  },
);

export type SubscriptionBody = {
  userId: string;
  subscriptionId?: string;
  customerId: string;
  subscriptionPlanTypeId: number;
  subscriptionFrequency: string;
  additionalUsers: number;
  promoCode?: string;
};

export const createSubscription = createAsyncThunk(
  'billing/create-subscription',
  async (apiParams: { data: SubscriptionBody; options: any }, thunkAPI) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/subscribe`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);
        thunkAPI.dispatch(
          setStripeSubscriptionId(decryptedData?.subscriptionId),
        );
        thunkAPI.dispatch(setStripeClientSecretId(decryptedData?.clientSectId));
        thunkAPI.dispatch(
          setSubscriptionPlanDetails(decryptedData?.subscriptionPlanDetails),
        );
        result = decryptedData;

        return result;
      }
    } catch (error: any) {
      console.log(error);
      const errorData = decryptData(error.response.data);
      console.log(errorData);
      return Promise.reject(errorData);
    }
  },
);

export type UpdateSubscriptionBody = {
  userId: string;
  subscriptionId?: string;
  customerId?: string;
  subscriptionPlanTypeId: number;
  subscriptionFrequency: string;
  additionalUsers?: number;
  promoCode?: string;
};

export const updateSubscription = createAsyncThunk(
  'billing/update-subscription',
  async (
    apiParams: { data: UpdateSubscriptionBody; options: any },
    thunkAPI,
  ) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/update-subscription`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);
        thunkAPI.dispatch(
          setStripeSubscriptionId(decryptedData?.subscriptionId),
        );
        thunkAPI.dispatch(setStripeClientSecretId(decryptedData?.clientSectId));
        thunkAPI.dispatch(
          setSubscriptionPlanDetails(decryptedData?.subscriptionPlanDetails),
        );
        result = decryptedData;
        return result;
      }
    } catch (error: any) {
      console.log(error);
      const errorData = decryptData(error.response.data);
      console.log(errorData);
      return Promise.reject(errorData);
    }
  },
);

export type ConfirmPaymentReqBody = {
  userId: string;
  subscriptionId?: string;
  paymentIntentId?: string | null;
};

export const confirmPayment = createAsyncThunk(
  'billing/confirm-payment',
  async (
    apiParams: { data: ConfirmPaymentReqBody; options: any },
    thunkAPI,
  ) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/confirm-payment`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;
        return result.paymentDetails;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);

type CheckPromoCodeType = {
  userId: string;
  promoCode: string;
};
export const checkPromoCode = createAsyncThunk(
  'billing/check-promo-code',
  async (apiParams: { data: CheckPromoCodeType; options: any }, thunkAPI) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/promo-code`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result.couponDetails;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);

// type GetInvoiceType = {
//   userId: string;
//   subscriptionId: string;
//   subscriptionPlanTypeId?: number;
//   subscriptionFrequency?: string;
//   additionalUsers?: number;
//   promoCode?: string;
// };
// export const getInvoice = createAsyncThunk(
//   'billing/fetch-invoice',
//   async (apiParams: { data: GetInvoiceType; options: any }, thunkAPI) => {
//     try {
//       let result;

//       const clientId = getClientID(apiParams.options.clientIp);

//       const encrypted = encryptData(apiParams.data);

//       const headers = {
//         Authorization: `Bearer ${apiParams.options.token}`,
//         clientid: clientId,
//         sessionid: apiParams.options.sessionId,
//         Accept: '*/*',
//         'Content-Type': 'application/json',
//       };

//       const response = await endpoint.post(
//         `${env_constants.RHOMEO_API}/Subs/invoice-preview`,
//         encrypted,
//         {
//           headers: headers,
//         },
//       );

//       if (response && response.data) {
//         const decryptedData = decryptData(response.data);

//         result = decryptedData;

//         return result.couponDetails;
//       }
//     } catch (error: any) {
//       return Promise.reject(decryptData(error.response.data));
//     }
//   },
// );
