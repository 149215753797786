import { Tabs } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { postFavorite } from '../../search/redux/async_thunks';
import { removeSearchItem } from '../redux/async_thunks';
import {
  selectClientIp,
  selectUser,
  selectUserFavorites,
} from '../redux/selectors';
import { selectUserFeatures } from '../redux/selectors';
import { setFavorites } from '../redux/slice';
import { Search } from '../redux/types';
import FavProperties from './Property';
import FavSearches from './Search';
import FavSuburbs from './Suburb';

type FavoriteProps = {
  handlePopoverVisibility?: (v: any) => void;
};

const Favorite = ({ handlePopoverVisibility }: FavoriteProps) => {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const clientIp = useSelector(selectClientIp);
  const favorites = useSelector(selectUserFavorites);

  const userFeatures = useAppSelector(selectUserFeatures);

  const updateFavorites = (value) => {
    dispatch(
      setFavorites({
        ...value,
      }),
    );
  };

  const handleFavDelete = (entry: any) => {
    const searchTypeAttribute =
      entry.searchType === 'suburb' ? 'suburbs' : 'properties';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const result = await dispatch(
          postFavorite({
            data: {
              userId: user.userId,
              [entry.property]: entry.id,
              isFavorite: false,
            },
            options: {
              token: user.jwtToken.token,
              clientIp,
              sessionId: user.sessionId,
              searchType: entry.searchType,
            },
          }),
        )
          // @ts-ignore
          .unwrap();

        updateFavorites({
          ...favorites,
          [searchTypeAttribute]: result[searchTypeAttribute],
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleFavSearchDelete = (search: Search, searchType: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const result = await dispatch(
          removeSearchItem({
            data: {
              userId: user.userId,
              id: search.id,
            },
            options: {
              token: user.jwtToken.token,
              clientIp,
              sessionId: user.sessionId,
              searchType: searchType,
            },
          }),
        )
          // @ts-ignore
          .unwrap();

        updateFavorites({
          ...favorites,
          [`${searchType}Searches`]:
            result && result.searches ? result.searches : [],
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const tabItems = [
    {
      label: `Suburbs`,
      key: 'suburbs',
      children: (
        <FavSuburbs
          handlePopoverVisibility={handlePopoverVisibility}
          handleFavSuburbDelete={handleFavDelete}
        />
      ),
    },
  ];

  if (userFeatures && userFeatures.includes('property-search')) {
    tabItems.push({
      label: `Properties`,
      key: 'properties',
      children: (
        <FavProperties
          handlePopoverVisibility={handlePopoverVisibility}
          handleFavPropertyDelete={handleFavDelete}
        />
      ),
    });
  }

  if (
    userFeatures &&
    (userFeatures.includes('suburb-save-search') ||
      userFeatures.includes('property-save-search'))
  ) {
    tabItems.push({
      label: `Searches`,
      key: 'searches',
      children: (
        <FavSearches
          handleFavSearchDelete={handleFavSearchDelete}
          handlePopoverVisibility={handlePopoverVisibility}
        />
      ),
    });
  }

  return (
    <div>
      <Tabs
        defaultActiveKey="suburbs"
        // onChange={onChange}
        className="c-favorite-tabs"
        centered
        tabBarStyle={{
          width: '100%',
          padding: '0 20px',
        }}
        items={tabItems}
      />
    </div>
  );
};

export default Favorite;
