import { createSlice } from '@reduxjs/toolkit';

import { postReport, postReportDetail } from './async_thunks';

const initialState: any = {
  orgPicture: undefined,

  userId: undefined,
  sessionId: undefined,
  jwtToken: undefined,
  clientIp: undefined,

  suburb: undefined,
  propertyType: undefined,

  projectsValues: {
    orderTypes: [],
    distance: { min: 0, max: 2, interval: 1 },
  },
  monthEndDate: undefined,
  descriptors: {
    dwelling: undefined,
    graph: undefined,
    population: undefined,
    projects: undefined,
  },

  population: undefined,
  dwelling: undefined,

  graph: {
    SupplyDemandIndicator: false,
    SupplyDemandRatio: false,
    RentalVacancyRatio: false,
    VolumeRentalListings: false,
    MedianSalePrice: false,
    GrossRentalYield: false,
    MedianWeeklyAskingRent: false,
    LgaWeightedInvCapita: false,
    SuburbTotalWeightedInvesmentPerCapita: false,
    MedianMarketTime: false,
    VendorDiscount: false,
    MedianSalesPricePrediction: false,
    MedianWeeklyAskingRentPrediction: false,
  },
  growth: undefined,
  keyIndicatorsValues: undefined,
  projects: undefined,

  projectSettings: {
    orderType: 'Stage',
    sortKey: 'realValue',
    orderByKey: 'descending',
    kmRadius: 50,
  },

  timelineType: '10',
  relatedArticles: {},
  isGraphLoading: {},

  // All enabled by default
  graphDisplaySettings: {
    SupplyDemandIndicator: true,
    SupplyDemandRatio: true,
    RentalVacancyRatio: true,
    VolumeRentalListings: true,
    MedianSalePrice: true,
    GrossRentalYield: true,
    MedianWeeklyAskingRent: true,
    MedianMarketTime: true,
    LgaWeightedInvCapita: true,
    MedianSalesPricePrediction: true,
    MedianWeeklyAskingRentPrediction: true,
  },
};

const weightedGraph = {
  key: 'Suburb Total Weighted Investment Per Capita',
  value: 'SuburbTotalWeightedInvesmentPerCapita',
};

export const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resetGraph: (state) => {
      const projectGraph = state.graph['SuburbTotalWeightedInvesmentPerCapita'];

      if (projectGraph) {
        return {
          ...state,
          graph: { SuburbTotalWeightedInvesmentPerCapita: projectGraph },
        };
      }

      return {
        ...state,
        graph: initialState.graph,
      };
    },
    setIsGraphLoading: (state, action: any) => {
      state.isGraphLoading = {
        ...state.isGraphLoading,
        [action.payload.key]: action.payload.status,
      };
    },
    setGraphDisplaySettings: (state, action: any) => {
      state.graphDisplaySettings = action.payload;
    },
    setTimelineType: (state, action: any) => {
      state.timelineType = action.payload;
    },
    setKeyIndicatorsGraphData: (state, action: any) => {
      // @ts-ignore
      state.graph[action.payload.graphType] = action.payload.data;
    },
    setProjectSettings: (state, action: any) => {
      state.projectSettings = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(postReport.fulfilled, (state, action: any) => {
      state.orgPicture = action.payload.orgPicture;

      state.userId = action.payload.userId;
      state.sessionId = action.payload.sessionId;
      state.jwtToken = action.payload.jwtToken;
      state.clientIp = action.payload.clientIp;

      state.suburb = action.payload.suburbReportLogin.suburb;
      state.propertyType = action.payload.suburbReportLogin.propertyType;

      const keyIndicatorValues =
        action.payload.suburbReportLogin.keyIndicatorValues;

      state.projectsValues = action.payload.suburbReportLogin.projectsValues;
      state.monthEndDate = action.payload.suburbReportLogin.monthEndDate;
      state.descriptors = action.payload.suburbReportLogin.descriptors;

      state.population = action.payload.suburbReportLogin.population;

      state.keyIndicatorsValues = {
        ...{
          graphTypes: [...keyIndicatorValues.graphTypes, ...[weightedGraph]],
          timeLineTypes: keyIndicatorValues.timelineTypes,
          trendLineTypes: keyIndicatorValues.trendLineTypes,
        },
      };
    });

    builder.addCase(postReportDetail.fulfilled, (state: any, action: any) => {
      if (action.payload.data) {
        if (action.payload.graphType) {
          state.graph[action.payload.graphType] = {
            graphs: action.payload.data,
          };
        } else if (
          action.payload.endpoint === 'graphs' ||
          action.payload.endpoint === 'predictions'
        ) {
          state.graph = {
            ...state.graph,
            ...action.payload.data,
          };
        } else {
          if (action.payload.endpoint === 'projects') {
            state[action.payload.endpoint] = {
              ...state[action.payload.endpoint],
              ...action.payload.data,
            };
          } else {
            state[action.payload.endpoint] = action.payload.data;
          }
        }
      }
    });
  },
});

export const {
  resetGraph,
  setGraphDisplaySettings,
  setIsGraphLoading,
  setTimelineType,
  setKeyIndicatorsGraphData,
  setProjectSettings,
} = report.actions;

export default report.reducer;
