import Account from '../../features/account/Account';
import UpdatePlan from '../../features/account/update_plan/UpdatePlan';
import UpdateSeats from '../../features/account/update_seats/UpdateSeats';
import AiReport from '../../features/ai_report/AiReport';
import Billing from '../../features/billing/Billing';
import PaymentSuccess from '../../features/billing/payment_success/PaymentSuccess';
import Chat from '../../features/chat/Chat';
import Report from '../../features/report/Report';
import Search from '../../features/search/Search';
import RootRoute from '../../features/startup/root_route/RootRoute';
import DeleteAccount from '../../features/user/delete_account/DeleteAccount';
import ForgotPassword from '../../features/user/forgot_password/ForgotPassword';
import Signup from '../../features/user/sign_up/SignUp';
import DeleteSuccess from '../../features/user/status/delete_success/DeleteSuccess';
// import EmailVerification from '../../features/user/status/email_verification/EmailVerification';
import LogoutSuccess from '../../features/user/status/logout_success/LogoutSuccess';
// import SessionExpired from '../../features/user/status/session_expired/SessionExpired';

export const privateRoutes = [
  {
    path: '/',
    element: RootRoute,
    feature: ['suburb-search', 'messaging'],
  },
  {
    path: '/suburb-search',
    element: Search,
    feature: 'suburb-search',
  },
  {
    path: '/suburb-view/:sscCode',
    element: Search,
    feature: 'suburb-search',
  },
  {
    path: '/property-search',
    element: Search,
    feature: 'property-search',
  },
  {
    path: '/property-view/:propertyId',
    element: Search,
    feature: 'property-search',
  },
  {
    path: '/billing',
    element: Billing,
  },
  {
    path: '/account',
    element: Account,
  },
  {
    path: '/account/update-plan',
    element: UpdatePlan,
  },
  {
    path: '/account/update-seats',
    element: UpdateSeats,
  },
  {
    path: '/success',
    element: PaymentSuccess,
  },
  // {
  //   path: '/email-verification',
  //   element: EmailVerification,
  // },
  {
    path: '/community',
    element: Chat,
    feature: 'messaging',
  },
  {
    path: '/suburb-report-build',
    element: AiReport,
  },
];

export const publicRoutes = [
  {
    path: '/sign-up',
    element: Signup,
  },
  {
    path: '/sign-up/:inviteCode',
    element: Signup,
  },
  {
    path: '/forgot-password',
    element: ForgotPassword,
  },
  // {
  //   path: '/session-expired',
  //   element: SessionExpired,
  // },
  {
    path: '/logout',
    element: LogoutSuccess,
  },
  {
    path: '/delete-profile/:deleteCode',
    element: DeleteAccount,
  },
  {
    path: '/deleted',
    element: DeleteSuccess,
  },
  {
    path: '/suburb-report/:reportLinkId',
    element: Report,
  },
];
