import React, { useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker, NavigationControl, Popup } from 'react-map-gl';

import PropertyMarker from '../../../../../foundation/assets/svgs/PropertyMarker';
import useBreakpoint from '../../../../../foundation/custom_hooks/useBreakpont';
import envConstants from '../../../../../internals/env/env_constants.json';
import { useAppSelector } from '../../../../../store/hooks';
import { mapStyleOptions } from '../../../common/map/map_style_picker/map_style_options';
import { selectActiveProperty } from '../../../redux/selectors';

const StaticMap = () => {
  const MAPBOX_ACCESS_TOKEN = envConstants.MAPBOX_ACCESS_TOKEN;
  const mapRef = useRef<any>();
  const [isMobileView] = useBreakpoint();

  const PROPERTY = useAppSelector(selectActiveProperty);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState<any>(null);

  const DEFAULT_VIEWPORT = {
    initialViewState: {
      latitude: -28.274398,
      longitude: 133.775136,
      zoom: 3.8,
    },
  };

  const DEFAULT_MAP_SETTINGS = {
    attributionControl: false,
    mapStyle: mapStyleOptions[0].path,
    mapboxAccessToken: MAPBOX_ACCESS_TOKEN,
  };

  const [viewport, setViewport] = useState<any>(DEFAULT_VIEWPORT);

  const markerHoverHandler = (suburb: any) => {
    setPopupInfo(suburb);
  };

  const markerBlurHandler = () => {
    setPopupInfo(null);
  };

  const viewportChangeHandler: any = (nextViewport: any) => {
    setViewport(nextViewport);
  };

  const setMapFocus = () => {
    if (mapRef.current) {
      mapRef.current.setCenter({
        lat: PROPERTY.latitude,
        lng: PROPERTY.longitude,
      });

      mapRef.current.setZoom(10);
    }
  };

  useEffect(() => {
    if (PROPERTY) {
      setMapFocus();
    }
  }, [PROPERTY, isMapLoaded]);

  return (
    <div className="l-property-details__map">
      <ReactMapGL
        {...DEFAULT_MAP_SETTINGS}
        {...DEFAULT_VIEWPORT}
        {...viewport}
        ref={mapRef}
        interactiveLayerIds={['suburb-layer']}
        pitchWithRotate={false}
        onViewportChange={viewportChangeHandler}
        onMove={(e) => {
          setViewport(e.viewState);
        }}
        onLoad={() => {
          setIsMapLoaded(true);
        }}
      >
        {!isMobileView && (
          <NavigationControl position="bottom-right" showCompass={false} />
        )}
        {PROPERTY && (
          <>
            <Marker
              longitude={PROPERTY.longitude}
              latitude={PROPERTY.latitude}
              anchor="top"
            >
              <span
                className="l-map__point"
                onMouseEnter={() => {
                  markerHoverHandler(PROPERTY);
                }}
                onMouseLeave={markerBlurHandler}
              >
                <PropertyMarker />
              </span>
            </Marker>
            {!isMobileView && popupInfo && (
              <Popup
                anchor="bottom"
                longitude={Number(popupInfo.longitude)}
                latitude={Number(popupInfo.latitude)}
                onClose={() => setPopupInfo(null)}
                closeButton={false}
                offset={20}
              >
                <span className="l-map__popup">
                  <>
                    <span style={{ color: '#651fff' }}>
                      {popupInfo.askingPrice}
                    </span>
                    <span>{popupInfo.address}</span>
                  </>
                </span>
              </Popup>
            )}
          </>
        )}
      </ReactMapGL>
    </div>
  );
};

export default StaticMap;
