import React from 'react';

type Props = {
  fill?: string;
};

function Logo({ fill }: Props) {
  return (
    <>
      <svg
        width="54"
        height="61"
        viewBox="0 0 54 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.27896 27.0499C4.27896 14.4634 14.4269 4.27896 26.9218 4.27896C39.4167 4.27896 49.5647 14.4634 49.5647 27.0499C49.5647 33.5046 45.812 39.7818 41.0014 45.1202C36.2366 50.4079 30.7202 54.4578 27.8045 56.4298C27.2403 56.8115 26.5404 56.8186 25.9679 56.4478C23.0655 54.5683 17.6462 50.7281 12.9394 45.5797L19.1592 39.6632C21.429 41.1873 24.1607 42.0765 27.1002 42.0765C34.9775 42.0765 41.3634 35.6906 41.3634 27.8133C41.3634 19.9359 34.9775 13.55 27.1002 13.55C19.2228 13.55 12.837 19.9359 12.837 27.8133C12.837 31.2041 14.0202 34.3185 15.9963 36.7663L8.73328 43.675C8.25945 44.1257 8.03749 44.7417 8.07181 45.3472C7.97447 45.9462 8.1312 46.5824 8.55592 47.0802C13.8844 53.325 20.3552 57.9109 23.642 60.0394C25.655 61.343 28.2144 61.3183 30.2017 59.9742C33.2518 57.9113 39.0859 53.6379 44.1802 47.9846C49.2287 42.3821 53.8436 35.1007 53.8436 27.0499C53.8436 12.1211 41.8007 0 26.9218 0C12.0429 0 0 12.1211 0 27.0499C0 30.963 1.00646 34.6491 2.58242 38.0193C3.08293 39.0897 4.35638 39.5516 5.42674 39.0511C6.4971 38.5506 6.95905 37.2771 6.45854 36.2068C5.09229 33.285 4.27896 30.2175 4.27896 27.0499ZM27.1002 37.7975C32.6143 37.7975 37.0844 33.3274 37.0844 27.8133C37.0844 22.2991 32.6143 17.829 27.1002 17.829C21.586 17.829 17.1159 22.2991 17.1159 27.8133C17.1159 30.6639 18.3106 33.2354 20.2265 35.0547C20.3534 35.1401 20.4731 35.2407 20.5829 35.3561C20.6111 35.3858 20.6383 35.416 20.6645 35.4468C22.4024 36.9135 24.648 37.7975 27.1002 37.7975Z"
          fill={fill ? fill : '#651FFF'}
        />
      </svg>
    </>
  );
}

export default Logo;
