import React, { InputHTMLAttributes, useState } from 'react';
import * as Yup from 'yup';

import CloseIcon from '../../assets/svgs/Close';
import ErrorField from '../error_field/ErrorField';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  chips: string[];
  setChips: (v: string[]) => void;
  error: string | undefined;
  setError: (v: string) => void;
};

const ChipInput = ({
  label,
  chips,
  setChips,
  name,
  error,
  setError,
}: Props) => {
  const [value, setValue] = useState<string | undefined>(undefined);

  const yupEmailSchema = Yup.string().email();

  const handleOnBeforeInput = async (
    e: React.CompositionEvent<HTMLInputElement>,
  ) => {
    if (e.data === ',' || e.data === ';' || e.data === ' ') {
      e.preventDefault();
      e.stopPropagation();

      const isValidEmail = yupEmailSchema.isValidSync(value);

      if (isValidEmail && value) {
        const currentChips = [...chips];
        currentChips.push(value);
        setChips(currentChips);
        setError('');
        setValue('');
      } else {
        setError('Please enter a valid email.');
      }
    }
  };

  const handleRemoveChip = (index: number) => () => {
    const newArray = [...chips];
    newArray.splice(index, 1);
    setChips(newArray);
  };

  return (
    <div style={{ marginBottom: 20, width: '100%' }}>
      <div
        className={`slide-up-label-1 ${error ? 'slide-up-label-1--error' : ''}`}
      >
        {chips.map((chip, index) => (
          <span className="tag-element" key={chip}>
            {chip}
            <span onClick={handleRemoveChip(index)}>
              <CloseIcon color="white" />
            </span>
          </span>
        ))}
        <input
          type="text"
          id={name}
          placeholder=" "
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={`chip-input ${
            chips.length === 0
              ? 'chip-input--empty'
              : 'chip-input--contains-chips'
          }`}
          onBeforeInput={handleOnBeforeInput}
        />
        <label
          htmlFor={name}
          className={`label-el ${
            chips.length > 0 ? 'label-el--contains-element' : ''
          }`}
        >
          {label}
        </label>
      </div>
      <ErrorField error={error} />
    </div>
  );
};

export default ChipInput;
