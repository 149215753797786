import React from 'react';

function Info() {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68783 1.77083C8.68783 2.74884 7.895 3.54167 6.91699 3.54167C5.93899 3.54167 5.14616 2.74884 5.14616 1.77083C5.14616 0.792829 5.93899 0 6.91699 0C7.895 0 8.68783 0.792829 8.68783 1.77083Z"
        fill="#A0A3BD"
      />
      <path
        d="M7.98479 5.66667H1.25033L0.541992 7.4375L4.08366 7.79167L1.65743 13.4126C1.65743 13.4126 1.53832 14.2845 1.65743 14.8216C1.79061 15.4222 1.93794 15.7844 2.36047 16.2307C3.13959 17.0536 4.04906 17.0735 5.17263 16.9352C7.09954 16.6981 9.04199 13.8125 9.04199 13.8125L7.98479 12.7081C7.98479 12.7081 6.57863 14.1171 5.87559 14.1171C5.17255 14.1171 5.17263 13.4126 5.17263 13.4126L7.98479 5.66667Z"
        fill="#A0A3BD"
      />
    </svg>
  );
}

export default Info;
