import * as Yup from 'yup';

const ProfileEditTypeValidationSchema = () => {
  const requiredMessage = 'Field is required';
  const fields: any = {
    firstName: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(1, 'First Name must be at least 1 character')
      .max(50, 'First Name must not exceed 50 characters'),
    lastName: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(1, 'Last Name must be at least 1 character')
      .max(50, 'Last Name must not exceed 50 characters'),
    handle: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(3, 'Handle must be at least 3 characters')
      .max(50, 'Handle must not exceed 50 characters'),
    occupation: Yup.string()
      .trim()
      .nullable()
      .max(50, 'Occupation must not exceed 50 characters'),
    bio: Yup.string()
      .trim()
      .nullable()
      .max(500, 'Bio must not exceed 500 characters'),
  };

  return Yup.object().shape(fields);
};

export default ProfileEditTypeValidationSchema;
