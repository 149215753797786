import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../../store/hooks';
import Chat from '../../chat/Chat';
import Search from '../../search/Search';
import { selectUserFeatures } from '../../user/redux/selectors';

const RootRoute = () => {
  const userFeatures = useAppSelector(selectUserFeatures);
  const [isProceed, setIsProceed] = useState<boolean>(false);

  useEffect(() => {
    if (userFeatures) {
      setIsProceed(true);
    }
  }, [userFeatures]);

  if (!isProceed) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      {userFeatures.includes('suburb-search') ? <Search /> : <Chat />}
    </>
  );
};

export default RootRoute;
