import { Checkbox } from 'antd';
import React, { useMemo } from 'react';

import PolygonMessageIcon from '../../../../foundation/assets/svgs/PolygonMessageIcon';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectAccountCurrentPlan,
  selectAccountSubscriptionPlans,
} from '../../redux/selectors';
import { Plan } from '../../redux/types';

type PlanElementsProps = {
  handlePlanCardClick: (plan: any) => () => Promise<void>;
  selectedPlan: Plan | undefined;
  subscriptionFrequency: string | undefined;
};
const PlanElements = ({
  handlePlanCardClick,
  selectedPlan,
  subscriptionFrequency,
}: PlanElementsProps) => {
  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  const availablePlans = useAppSelector(selectAccountSubscriptionPlans);

  const frequencyString = useMemo(
    () =>
      subscriptionFrequency && subscriptionFrequency === 'Annually'
        ? 'annum'
        : 'month',
    [subscriptionFrequency],
  );

  const plans = useMemo(() => {
    return availablePlans?.map((item) => {
      let planRate = 0;

      for (const rate of item.planRates) {
        if (rate.frequency === subscriptionFrequency) {
          planRate = rate.subscriptionAmount;
        }
      }

      const isSelected =
        selectedPlan?.subscriptionPlanId === item.subscriptionPlanId;

      return (
        <div
          className={`l-update-plan__plan-card ${
            isSelected ? ' l-update-plan__plan-card--selected' : ''
          }`}
          key={item.subscriptionPlanId}
          onClick={handlePlanCardClick(item)}
        >
          <div className="l-update-plan__plan-card-checkbox-wrapper">
            <Checkbox style={{ height: 40, width: 40 }} checked={isSelected} />
          </div>
          <div>
            <div className="l-update-plan__plan-name">
              {item.subscriptionPlan}
            </div>
            <div className="l-update-plan__plan-details">
              ${planRate} per {frequencyString}
            </div>
          </div>
        </div>
      );
    });
  }, [availablePlans, selectedPlan, frequencyString, currentPlan]);

  const planMessage = useMemo(() => {
    if (currentPlan) {
      switch (currentPlan.subscriptionPlanId) {
        case 1: {
          return (
            <>
              If you wish to change your billing cycle please contact support at{' '}
              <a href="mailto:support@rhomeo.com">support@rhomeo.com</a>
            </>
          );
        }
        case 2: {
          return (
            <>
              {`If you wish to change your billing cycle or downgrade your plan please contact support at `}
              <a href="mailto:support@rhomeo.com">support@rhomeo.com</a>
            </>
          );
        }
        case 3: {
          return (
            <>
              {`You have already subscribed to our top plan, If you wish to change your billing cycle or downgrade your plan please contact support at `}
              <a href="mailto:support@rhomeo.com">support@rhomeo.com</a>
            </>
          );
        }
      }
    }
  }, [currentPlan]);

  return (
    <div className="l-update-plan__content-section">
      {plans}
      <div className="l-update-plan__message-container">
        <div className="l-update-plan__message-icon">
          <PolygonMessageIcon />
        </div>
        <div className="l-update-plan__message">{planMessage}</div>
      </div>
    </div>
  );
};

export default PlanElements;
