import React from 'react';

function CodeBlock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="#AFAFAF"
        d="M1.8 8.875L.18 6.94 1.8 4.996h2.493L2.673 6.94l1.62 1.935H1.8zm6.652-7.47L6.067 11.782H4.15L6.544 1.405h1.908zm-.204 3.591h2.493l1.62 1.944-1.62 1.935H8.248l1.62-1.935-1.62-1.944z"
      ></path>
      <path stroke="#AFAFAF" d="M13.5 4h2v10.5H3v-4"></path>
    </svg>
  );
}

export default CodeBlock;
