import React from 'react';

function SuburbHomeIcon({ color = '#651FFF' }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M2 8v-.75a.75.75 0 00-.75.75H2zm20 0h.75a.75.75 0 00-.75-.75V8zm0 15v.75a.75.75 0 00.75-.75H22zM2 23h-.75c0 .414.336.75.75.75V23zm4-12v-.75a.75.75 0 00-.75.75H6zm4 0h.75a.75.75 0 00-.75-.75V11zm0 4v.75a.75.75 0 00.75-.75H10zm-4 0h-.75c0 .414.336.75.75.75V15zm8-4v-.75a.75.75 0 00-.75.75H14zm4 0h.75a.75.75 0 00-.75-.75V11zm0 4v.75a.75.75 0 00.75-.75H18zm-4 0h-.75c0 .414.336.75.75.75V15zm-4 3v-.75h-.75V18H10zm4 0h.75v-.75H14V18zm0 5v.75h.75V23H14zm-4 0h-.75v.75H10V23zM1 8l-.382-.646A.75.75 0 001 8.75V8zm22 0v.75a.75.75 0 00.381-1.396L23 8zM1 22.25a.75.75 0 000 1.5v-1.5zm22 1.5a.75.75 0 000-1.5v1.5zM12 1.5l.382-.646a.75.75 0 00-.764 0L12 1.5zM2 8.75h20v-1.5H2v1.5zM21.25 8v15h1.5V8h-1.5zM22 22.25H2v1.5h20v-1.5zM2.75 23V8h-1.5v15h1.5zM6 11.75h4v-1.5H6v1.5zM9.25 11v4h1.5v-4h-1.5zm.75 3.25H6v1.5h4v-1.5zM6.75 15v-4h-1.5v4h1.5zM14 11.75h4v-1.5h-4v1.5zm3.25-.75v4h1.5v-4h-1.5zm.75 3.25h-4v1.5h4v-1.5zm-3.25.75v-4h-1.5v4h1.5zM10 18.75h4v-1.5h-4v1.5zm3.25-.75v5h1.5v-5h-1.5zm.75 4.25h-4v1.5h4v-1.5zm-3.25.75v-5h-1.5v5h1.5zM1 8.75h22v-1.5H1v1.5zm0 15h22v-1.5H1v1.5zm.382-15.104l11-6.5-.764-1.292-11 6.5.764 1.292zm10.236-6.5l11 6.5.764-1.292-11-6.5-.764 1.292z"
      ></path>
    </svg>
  );
}

export default SuburbHomeIcon;
