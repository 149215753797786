import React from 'react';
import { useNavigate } from 'react-router-dom';

import Bin from '../../../../foundation/assets/svgs/Bin';

const DeleteSuccess = () => {
  const navigate = useNavigate();

  const clickHandler = (e: any) => {
    e.preventDefault();
    navigate('/sign-up');
  };

  return (
    <div className="l-custom-result">
      <div className="l-custom-result__content">
        <div className="l-custom-result__icon">
          <Bin />
        </div>
        <h4>Account Deleted</h4>
        <p>Your account has been successfully deleted.</p>
        <button
          type="button"
          className="l-custom-result__button"
          onClick={clickHandler}
        >
          Back to Sign up
        </button>
      </div>
    </div>
  );
};

export default DeleteSuccess;
