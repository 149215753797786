import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { CometChat } from '@cometchat-pro/chat';
import { Avatar, Button, Dropdown, Menu, Modal, notification } from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import DebounceAjaxSelect from '../../../../foundation/components/debonce_ajax_select/DebounceAjaxSelect';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import appMessages from '../../../../foundation/messages';
import fetchUsersByName from '../../helpers/fetchUsersByName';
import { selectccUser } from '../../redux/selectors';
import { fetchBannedMembers } from '../../utils/controllers/channelListController';

const { confirm } = Modal;
type Props = {
  members: any[];
  conversation: any;
  cancelHandler: () => any;
  removeMemberFromChannelMembers: (member: any) => any;
  fetchMembers: (conversation: any) => any;
  updateScopeFromChannelMembers: (member: any, scope: any) => void;
  channelOwner: any;
};

const GroupMembers = ({
  conversation,
  members,
  cancelHandler,
  removeMemberFromChannelMembers,
  fetchMembers,
  updateScopeFromChannelMembers,
  channelOwner,
}: Props) => {
  const [isGroupMembersLoading, setIsGroupMembersLoading] = useState(false);
  const [bannedMembers, setBannedMembers] = useState<any>([]);

  const ccUser: any = useSelector(selectccUser);

  const isCCUserAdmin = useMemo(() => {
    const member: any = members.filter(
      (i) => ccUser && i.uid === ccUser.uid,
    )[0];

    if (member) {
      return member?.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN;
    }

    return false;
  }, [ccUser, members]);

  const isCCUserOwner = useMemo(() => {
    return channelOwner && ccUser.uid === channelOwner.uid;
  }, [ccUser, channelOwner]);

  const fetchBanned = async () => {
    const guid: string = conversation?.conversationWith?.guid;

    return fetchBannedMembers(guid)
      .then((list: any) => {
        setBannedMembers(list);
      })
      .catch(() => {
        notification.error({
          description: appMessages.generic.SOMETHING_WENT_WRONG,
          message: 'Error!',
        });
      });
  };

  const updateMembers = (values: any) => {
    setIsGroupMembersLoading(true);
    const guid: string = conversation?.conversationWith?.guid;
    const membersList: any[] = [];

    const memberToAdd = values.member;

    // if a selected member is already part of the member list, don't add
    const IndexFound = members.findIndex(
      (member) => member.uid === memberToAdd.value,
    );

    if (IndexFound === -1) {
      const newMember: CometChat.GroupMember = new CometChat.GroupMember(
        memberToAdd.value,
        CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT,
      );

      membersList.push(newMember);

      memberToAdd['type'] = 'add';
    }

    if (membersList.length) {
      CometChat.addMembersToGroup(guid, membersList, [])
        .then(async () => {
          await fetchMembers(conversation);
          setIsGroupMembersLoading(false);
          cancelHandler();
          notification.success({
            description: appMessages.channel.SUCCESS_ADD_MEMBER,
            message: 'Success!',
          });
        })
        .catch((error: CometChat.CometChatException) => {
          console.log(error);
          notification.error({
            description: appMessages.channel.ERROR_ADDING_MEMBER,
            message: 'Error!',
          });
          setIsGroupMembersLoading(false);
        });
    }
  };

  const handleMemberRemove = (member: any) => {
    setIsGroupMembersLoading(true);

    const guid: string = conversation?.conversationWith?.guid;

    CometChat.kickGroupMember(guid, member.uid)
      .then((response) => {
        if (response) {
          // Update members
          removeMemberFromChannelMembers(member);
          notification.success({
            description: appMessages.channel.SUCCESS_REMOVE_MEMBER,
            message: 'Success!',
          });
        } else {
          showErrorOnKicking();
        }
        setIsGroupMembersLoading(false);
        handleClose();
      })
      .catch((error: CometChat.CometChatException) => {
        showErrorOnKicking();
        setIsGroupMembersLoading(false);
        handleClose();
      });
  };

  const handleMemberBan = (member: any) => {
    setIsGroupMembersLoading(true);

    const guid: string = conversation?.conversationWith?.guid;

    CometChat.banGroupMember(guid, member.uid)
      .then((response) => {
        if (response) {
          // Update members
          removeMemberFromChannelMembers(member);
          notification.success({
            description: appMessages.channel.SUCCESS_BAN_MEMBER,
            message: 'Success!',
          });
        } else {
          showErrorOnBanning();
        }
        setIsGroupMembersLoading(false);
        handleClose();
      })
      .catch((error: CometChat.CometChatException) => {
        showErrorOnBanning();
        setIsGroupMembersLoading(false);
        handleClose();
      });
  };

  const handleMemberUnban = (member: any) => {
    setIsGroupMembersLoading(true);

    const guid: string = conversation?.conversationWith?.guid;

    CometChat.unbanGroupMember(guid, member.uid)
      .then((response) => {
        if (response) {
          notification.success({
            description: appMessages.channel.SUCCESS_UNBAN_MEMBER,
            message: 'Success!',
          });
        } else {
          showErrorForUnbanning();
        }
        setIsGroupMembersLoading(false);
        handleClose();
      })
      .catch((error: CometChat.CometChatException) => {
        showErrorForUnbanning();
        setIsGroupMembersLoading(false);
        handleClose();
      });
  };

  const handleUpdateMemberScope = (member: any, scope: any) => {
    setIsGroupMembersLoading(true);

    const guid: string = conversation?.conversationWith?.guid;

    CometChat.updateGroupMemberScope(guid, member.uid, scope)
      .then((response) => {
        if (response) {
          // Update members
          updateScopeFromChannelMembers(member, scope);
          notification.success({
            description:
              scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN
                ? appMessages.channel.SUCCESS_UPDATING_USER_SCOPE_TO_ADMIN
                : appMessages.channel
                    .SUCCESS_UPDATING_USER_SCOPE_TO_PARTICIPANT,
            message: 'Success!',
          });
        } else {
          showErrorOnScopeUpdate();
        }
        setIsGroupMembersLoading(false);
        handleClose();
      })
      .catch((error: CometChat.CometChatException) => {
        showErrorOnScopeUpdate();
        setIsGroupMembersLoading(false);
        handleClose();
      });
  };

  const showBanConfirm = (member: any) => {
    confirm({
      title: (
        <div style={{ fontSize: '14px' }}>
          <span style={{ marginBottom: '10px', display: 'block' }}>
            Are you sure you want to ban {member.name} from this channel?
          </span>
          <span>
            The user will be removed and will not be allowed to rejoin.
          </span>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Ban',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      cancelButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      onOk: () => handleMemberBan(member),
      className: 'c-modal',
    });
  };

  const showUnbanConfirm = (member: any) => {
    confirm({
      title: (
        <div style={{ fontSize: '14px' }}>
          <span style={{ marginBottom: '10px', display: 'block' }}>
            Are you sure you want to unban {member.name} from this channel?
          </span>
          <span>The user will be allowed to rejoin and participate.</span>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Unban',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      cancelButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      onOk: () => handleMemberUnban(member),
      className: 'c-modal',
    });
  };

  const showRemoveConfirm = (member: any) => {
    confirm({
      title: (
        <div style={{ fontSize: '14px' }}>
          <span style={{ marginBottom: '10px', display: 'block' }}>
            Are you sure you want to remove {member.name} from this channel?
          </span>
          <span>The user can rejoin anytime.</span>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Remove',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      cancelButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      onOk: () => handleMemberRemove(member),
      className: 'c-modal',
    });
  };

  const showUpdateScopeConfirm = (member: any, scope: any) => {
    const isAdminScope = scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN;

    confirm({
      title: (
        <div style={{ fontSize: '14px' }}>
          <span style={{ marginBottom: '10px', display: 'block' }}>
            {isAdminScope
              ? `You're about to grant ${member.name} administrator permissions to this channel.`
              : `You're about to remove ${member.name} as an administrator from this channel.`}
          </span>
          <span>Are you sure?</span>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: isAdminScope ? 'Grant' : 'Remove',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      cancelButtonProps: {
        loading: isGroupMembersLoading,
        disabled: isGroupMembersLoading,
      },
      onOk: () => handleUpdateMemberScope(member, scope),
      className: 'c-modal',
    });
  };

  const showErrorOnKicking = () => {
    notification.error({
      description: appMessages.channel.ERROR_KICKING_USER,
      message: 'Error!',
    });
  };

  const showErrorOnBanning = () => {
    notification.error({
      description: appMessages.channel.ERROR_BANNING_USER,
      message: 'Error!',
    });
  };

  const showErrorForUnbanning = () => {
    notification.error({
      description: appMessages.channel.ERROR_UNBANNING_USER,
      message: 'Error!',
    });
  };

  const showErrorOnScopeUpdate = () => {
    notification.error({
      description: appMessages.channel.ERROR_UPDATING_USER_SCOPE,
      message: 'Error!',
    });
  };

  const ListItem = ({ member }: { member: any }) => {
    const isAdmin = member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN;
    const isOwner = channelOwner && member.uid === channelOwner?.uid;
    const isSelf = member.uid === ccUser?.uid;
    const moreOptions = (
      <Menu
        items={[
          {
            key: '1',
            label: (
              <button
                type="button"
                className="l-channel-list__more-option"
                onClick={() => {
                  showUpdateScopeConfirm(
                    member,
                    !isAdmin
                      ? CometChat.GROUP_MEMBER_SCOPE.ADMIN
                      : CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT,
                  );
                }}
              >
                {!isAdmin ? 'Promote to admin' : 'Demote to member'}
              </button>
            ),
          },
          {
            key: '2',
            label: (
              <button
                type="button"
                className="l-channel-list__more-option"
                onClick={() => showRemoveConfirm(member)}
              >
                Remove from channel
              </button>
            ),
          },
          {
            key: '3',
            label: (
              <button
                type="button"
                className="l-channel-list__more-option l-channel-list__more-option--ban"
                onClick={() => showBanConfirm(member)}
              >
                Ban member
              </button>
            ),
          },
        ]}
      />
    );

    return (
      <div className="c-form-field" style={{ marginBottom: '0' }}>
        <div
          className={`l-channel-list__list-item l-channel-list__list-item--space-btw l-channel-list__list-item--emp`}
        >
          <div className="l-members-info__wrapper">
            <Avatar
              src={member.avatar}
              size="large"
              className="l-channel-list__list-item-avatar"
            />

            <div className="l-channel-list__list-item-name-wrapper">
              <div className="l-channel-list__list-item-name">
                {member.name}
              </div>
              <div className="l-channel-list__list-item-sub-detail">
                {isOwner ? 'Owner' : member.scope}
              </div>
            </div>
          </div>
          {isCCUserAdmin &&
            ((isCCUserOwner && !isSelf) ||
              (!isSelf && !isOwner && !isAdmin)) && (
              <div className="l-channel-list__list-item-actions">
                <Dropdown
                  overlay={moreOptions}
                  placement="bottomRight"
                  arrow={false}
                  trigger={['click']}
                >
                  <div className="l-channel-list__more">
                    <MoreOutlined />
                  </div>
                </Dropdown>
              </div>
            )}
        </div>
      </div>
    );
  };

  const channelMembers = useMemo(() => {
    return members.map((item: any) => (
      <ListItem member={item} key={item.uid} />
    ));
  }, [members]);

  const handleClose = () => {
    if (isGroupMembersLoading) {
      return;
    }
    cancelHandler();
  };

  useEffect(() => {
    if (isCCUserAdmin) {
      fetchBanned();
    }
  }, []);

  return (
    <div className="l-group-members">
      {isGroupMembersLoading && <FullPageLoader />}
      <Formik
        initialValues={{
          member: { label: '', value: '' },
          message: '',
        }}
        onSubmit={updateMembers}
        enableReinitialize
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {isCCUserAdmin && (
                <div className="c-form-field">
                  <div className="c-form-field__wrapper">
                    <div className="l-group-members__add-member-wrapper">
                      <DebounceAjaxSelect
                        // @ts-ignore
                        fetchOptions={fetchUsersByName}
                        value={[values.member]}
                        onChange={(v: any) => setFieldValue('member', v)}
                      />
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!(isValid && dirty)}
                        style={{ marginLeft: 10 }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="l-members-info__list-container">
                {channelMembers}
              </div>
              {isCCUserAdmin && !!bannedMembers.length && (
                <div className="l-members-info__list-container">
                  <div className="l-members-info__header">Banned Members</div>
                  {bannedMembers.map((member: any, index: number) => {
                    return (
                      <div
                        className="c-form-field"
                        style={{ marginBottom: '0' }}
                        key={index}
                      >
                        <div
                          className={`l-channel-list__list-item l-channel-list__list-item--space-btw l-channel-list__list-item--emp`}
                        >
                          <div className="l-members-info__wrapper">
                            <Avatar
                              src={member.avatar}
                              size="large"
                              className="l-channel-list__list-item-avatar"
                            />

                            <div className="l-channel-list__list-item-name-wrapper">
                              <div className="l-channel-list__list-item-name">
                                {member.name}
                              </div>
                              <div className="l-channel-list__list-item-sub-detail">
                                {member.scope}
                              </div>
                            </div>
                          </div>
                          <div className="l-channel-list__list-item-actions">
                            <div
                              className="l-members-info__action-btn"
                              onClick={() => showUnbanConfirm(member)}
                            >
                              Unban
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

GroupMembers.propTypes = {
  member: PropTypes.any,
};

export default GroupMembers;
