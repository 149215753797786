import React from 'react';

function ChangePictureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#FCFCFC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 5h12a6 6 0 016 6v1M5 5l4-4M5 5l4 4m10 10H7a6 6 0 01-6-6v-1m18 7l-4 4m4-4l-4-4"
      ></path>
    </svg>
  );
}

export default ChangePictureIcon;
