import React from 'react';

const ArrowBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      fill="none"
      viewBox="0 0 19 16"
    >
      <path
        fill="#262338"
        d="M8.258 1.52A.75.75 0 007.176.48l1.082 1.04zM1 8l-.541-.52a.75.75 0 000 1.04L1 8zm6.176 7.52a.75.75 0 001.082-1.04l-1.082 1.04zm-4.91-8.238a.75.75 0 100 1.5v-1.5zM18 8.782a.75.75 0 000-1.5v1.5zM7.176.48l-6.717 7L1.54 8.519l6.717-7L7.176.481zM.459 8.519l6.717 7 1.082-1.038-6.717-7L.46 8.519zm1.808.263H18v-1.5H2.267v1.5z"
      ></path>
    </svg>
  );
};

export default ArrowBackIcon;
