import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import View from '../../../../search/view/details/key_indicators/graph/view/View';
import { postReportDetail } from '../../../redux/async_thunks';
import {
  selectDescriptors,
  selectGraphDisplaySettings,
  selectKeyIndicatorsValues,
  selectReportUser,
  selectSuburb,
  selectTimelineType,
} from '../../../redux/selectors';
import { setIsGraphLoading, setTimelineType } from '../../../redux/slice';
import LineChart from '../line_chart/LineChart';

type Props = {
  setAreDetailsLoading?: any;
};

const Graph = ({ setAreDetailsLoading }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const user: any = useAppSelector(selectReportUser);
  const suburb: any = useAppSelector(selectSuburb);

  const keyIndicators = useAppSelector(selectKeyIndicatorsValues);
  const descriptor = useAppSelector(selectDescriptors);

  const timelineType = useAppSelector(selectTimelineType);

  const graphDisplaySettings = useAppSelector(selectGraphDisplaySettings);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Graph section',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchGraphData = async (graphType: string, trendlineType = '3') => {
    try {
      // @ts-ignore
      dispatch(setIsGraphLoading({ key: graphType, status: true }));

      await dispatch(
        postReportDetail({
          data: {
            userId: user.userId,
            graphType: graphType,
            timelineYears: timelineType,
            trendlineYears: trendlineType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: user.clientIp,
            sessionId: user.sessionId,
          },
          endpoint: 'graph',
        }),
        // @ts-ignore
      ).unwrap();

      // @ts-ignore
      dispatch(setIsGraphLoading({ key: graphType, status: false }));
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    // @ts-ignore
    dispatch(setIsGraphLoading({ key: graphType, status: false }));
  };

  const fetchGraphsData = async (trendlineType = '3') => {
    try {
      toggleLoader(true);

      await dispatch(
        postReportDetail({
          data: {
            userId: user.userId,
            timelineYears: timelineType,
            trendlineYears: trendlineType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: user.clientIp,
            sessionId: user.sessionId,
          },
          endpoint: 'graphs',
        }),
        // @ts-ignore
      ).unwrap();

      toggleLoader(false);
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      user={user}
      suburb={suburb}
      keyIndicators={keyIndicators}
      graphDisplaySettings={graphDisplaySettings}
      timelineType={timelineType}
      fetchGraphData={fetchGraphData}
      fetchGraphsData={fetchGraphsData}
      descriptor={descriptor.graph}
      setTimelineType={setTimelineType}
      isLoading={isLoading}
      LineChart={LineChart}
    />
  );
};

export default Graph;
