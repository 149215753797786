import React from 'react';

function Send({ style }: any) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style || {}}
    >
      <path
        d="M1.92023 8.62027C1.29942 8.3815 1.27359 7.51272 1.87911 7.23749L15.9303 0.850581C16.5612 0.563816 17.2102 1.21282 16.9234 1.84371L10.5365 15.8949C10.2613 16.5004 9.39251 16.4746 9.15374 15.8538L7.26409 10.9407C7.18791 10.7426 7.03138 10.5861 6.83332 10.5099L1.92023 8.62027Z"
        stroke="#14142B"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Send;
