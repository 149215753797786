import React from 'react';

function Code() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="11"
      fill="none"
      viewBox="0 0 13 11"
    >
      <path
        fill="#AFAFAF"
        d="M1.8 7.875L.18 5.94 1.8 3.996h2.493L2.673 5.94l1.62 1.935H1.8zM8.452.405L6.067 10.782H4.15L6.544.405h1.908zm-.204 3.591h2.493l1.62 1.944-1.62 1.935H8.248l1.62-1.935-1.62-1.944z"
      ></path>
    </svg>
  );
}

export default Code;
