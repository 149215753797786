import React from 'react';

const Minimize = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1L9.28105 6.71895M9.28105 6.71895L14.2222 6.71895M9.28105 6.71895V1.77778M1 15L6.71895 9.28105M6.71895 9.28105H1.77778M6.71895 9.28105V14.2222"
        stroke="#23005C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Minimize;
