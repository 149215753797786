import { Button, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookShareButton } from 'react-share';

import Check from '../../../foundation/assets/svgs/Check';
import FacebookIcon from '../../../foundation/assets/svgs/FacebookIcon';
import LinkIcon from '../../../foundation/assets/svgs/LinkIcon';
import ChipInput from '../../../foundation/components/chip_input/ChipInput';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { inviteUser } from '../redux/async_thunks';
import {
  selectClientIp,
  selectUser,
  selectUserInviteLink,
  selectUserInviteMessage,
} from '../redux/selectors';

type Props = {
  handleClose: () => void;
};
const InviteForm = ({ handleClose }: Props) => {
  const dispatch = useDispatch();

  const [isInviteFormLoading, setInviteFormLoading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [chips, setChips] = useState<string[]>([]);

  const inviteLink = useSelector(selectUserInviteLink);
  const inviteMessage = useSelector(selectUserInviteMessage);
  const user = useSelector(selectUser);
  const clientIp = useSelector(selectClientIp);

  const [error, setError] = useState<string | undefined>(undefined);

  const [isCopied, setIsCopied] = useState(false);

  const handleInvite = async (e) => {
    setIsFormSubmitting(true);
    e.stopPropagation();

    if (!chips.length || error) {
      return false;
    }

    try {
      setInviteFormLoading(true);
      const data = {
        userId: user?.userId,
        emails: chips.join(','),
      };

      const options = {
        token: user?.jwtToken.token,
        clientIp: clientIp,
        sessionId: user?.sessionId,
      };

      await dispatch(
        // @ts-ignore
        inviteUser({
          options,
          data,
        }),
      ).unwrap();

      notification.success({
        message: 'Success',
        description: 'Invitation sent successfully!',
      });

      setInviteFormLoading(false);
      handleClose();
    } catch (error) {
      setInviteFormLoading(false);
      handleClose();
    }
  };

  const copyToClipboard = () => {
    const successMessage = 'Copied to clipboard!';

    navigator.permissions
      // @ts-ignore
      .query({ name: 'clipboard-read' })
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard
            .writeText(inviteLink || '')
            .then(() => {
              message.info(successMessage);
              setIsCopied(true);
            })
            .catch(() => {
              alert('Unable to copy to clipboard at this time');
            });
        } else {
          const element = document.createElement('textarea');

          element.value = inviteLink || '';
          document.body.appendChild(element);
          element.select();
          document.execCommand('copy');
          document.body.removeChild(element);

          message.info(successMessage);
          setIsCopied(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (chips.length) {
      setIsFormSubmitting(false);
    }
  }, [chips]);

  return (
    <div className="c-invite-form__wrapper">
      {isInviteFormLoading && <FullPageLoader />}

      <div className="c-invite-form__description">
        {`Securely invite and share Rhomeo with friends. Just enter their email to send them an invitation.`}
      </div>

      <ChipInput
        label="Press comma after entering each email"
        name="invite_email"
        chips={chips}
        setChips={setChips}
        error={error}
        setError={setError}
      />
      {!chips.length && isFormSubmitting && (
        <p className="c-invite-form__error">
          At least 1 email address is required.
        </p>
      )}
      <Button
        type="primary"
        className="c-invite-form__share-btn c-invite-form__share-btn--email"
        onClick={handleInvite}
      >
        Invite
      </Button>

      <div className="c-invite-form__share-title">Share Link</div>

      <div className="c-invite-form__share-btns-wrapper">
        <Button
          icon={isCopied ? <Check /> : <LinkIcon />}
          type="primary"
          className="c-invite-form__share-btn"
          onClick={copyToClipboard}
        >
          {isCopied ? 'Link Copied' : 'Copy Link'}
        </Button>

        <FacebookShareButton
          url={inviteLink || ''}
          quote={inviteMessage}
          style={{
            width: '100%',
          }}
        >
          <Button
            icon={<FacebookIcon />}
            type="primary"
            className="c-invite-form__share-btn c-invite-form__share-btn--facebook"
          >
            Share on Facebook
          </Button>
        </FacebookShareButton>
      </div>
    </div>
  );
};

export default InviteForm;
