import React from 'react';
import { useNavigate } from 'react-router-dom';

import LogoIcon from '../../../foundation/assets/svgs/Logo';
import { useAppDispatch } from '../../../store/hooks';
import { resetSearch } from '../../search/redux/slice';

const Logo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clickHandler = (e: any) => {
    e.preventDefault();
    dispatch(resetSearch());

    navigate('/suburb-search');
  };

  return (
    <a
      href="#"
      onClick={clickHandler}
      style={{ color: '#fff', display: 'flex', alignItems: 'center' }}
    >
      <LogoIcon fill="#fff" />
      <div>rhomeo</div>
    </a>
  );
};

export default Logo;
