import { CometChat } from '@cometchat-pro/chat';
import { notification } from 'antd';
import { Button, Radio } from 'antd';
import { convertToRaw, Editor, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { withFormik } from 'formik';
import IframeResizer from 'iframe-resizer-react';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import DebounceAjaxSelect from '../../../../../foundation/components/debonce_ajax_select/DebounceAjaxSelect';
import FullPageLoader from '../../../../../foundation/components/full_page_loader/FullPageLoader.index';
import appMessages from '../../../../../foundation/messages';
import { selectccUser } from '../../../../chat/redux/selectors';
import { fetchJoinedChannels } from '../../../../chat/utils/controllers/channelListController';
import { fetchNextUsers } from '../../../../chat/utils/controllers/dmListController';
import { sendCustomMessage } from '../../../../chat/utils/controllers/messageController';
import * as enums from '../../../../chat/utils/enums/index';

type Props = {
  previewLink: string;
  cancelHandler: () => void;
  sscCode: number;
};

interface LabelValue {
  label: string;
  value: string;
}

const SuburbShare = ({
  cancelHandler,
  previewLink,
  sscCode,
}: Props): JSX.Element => {
  const editorRef = useRef(null);

  const ccUser = useSelector(selectccUser);

  const receiverOptions = [
    {
      label: 'Channel',
      value: 'channel',
    },
    {
      label: 'User',
      value: 'user',
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendMessageHandler = async (values: any) => {
    const contentState = values.editorState.getCurrentContent();
    setIsLoading(true);

    if (
      contentState.hasText() &&
      contentState.getPlainText().trim().length > 0
    ) {
      const raw = convertToRaw(contentState);

      const options = {
        blockRenderers: {
          unstyled: (block: any) => {
            return `<p class="chat-share-suburb" data-ssc-code="${sscCode}" data-purl="${previewLink}">${block.getText()}</p>`;
          },
        },
      };

      const html = stateToHTML(contentState, options);

      editorMessageSendHandler(raw, html, values);
    } else {
      setIsLoading(false);
    }
  };

  const editorMessageSendHandler = async (
    raw: any,
    html: string,
    values: any,
  ) => {
    const isChannel = values.selectedReceiver === 'channel';

    const customData = {
      rawEditorState: raw,
      html,
    };

    await sendCustomMessage(
      customData,
      enums.CUSTOM_TYPE_DRAFTJS,
      isChannel ? values.receivingChannel.value : values.receivingUser.value,
      isChannel ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER,
      ccUser,
    );

    notification.success({
      description: appMessages.channel.SUCCESS_SHARE_SUBURB_PREVIEW,
      message: 'Success!',
    });

    setIsLoading(false);
    cancelHandler();
  };

  const formikEnhancer = withFormik({
    mapPropsToValues: (props) => ({
      editorState: EditorState.createEmpty(),
      receivingChannel: '',
      receivingUser: '',
      selectedReceiver: receiverOptions[0].value,
    }),
    validationSchema: Yup.object().shape({
      editorState: Yup.object().test(
        'has text',
        'Message is required',
        (editorState) => {
          return (
            editorState &&
            editorState.getCurrentContent &&
            // @ts-ignore
            editorState.getCurrentContent().hasText()
          );
        },
      ),
      receivingChannel: Yup.object().when('selectedReceiver', {
        is: 'channel',
        then: Yup.object().required('Receiving channel is required'),
      }),
      receivingUser: Yup.object().when('selectedReceiver', {
        is: 'user',
        then: Yup.object().required('Receiving user is required'),
      }),
    }),
    handleSubmit: (values) => {
      if (previewLink) {
        sendMessageHandler(values);
      }
    },
    displayName: 'MyForm',
  });

  const fetchChannels = (name: string): Promise<LabelValue[]> => {
    return fetchJoinedChannels(name)
      .then((groupList: any) => {
        return groupList.map((item: any) => {
          return {
            label: item.name,
            value: item.guid,
          };
        });
      })
      .catch((error: any) => {
        notification.error({
          description: appMessages.generic.SOMETHING_WENT_WRONG,
          message: 'Error!',
        });
      });
  };

  const fetchUsers = (name: string): Promise<LabelValue[]> => {
    return fetchNextUsers(name)
      .then((userList: any) => {
        return userList.map((item: any) => {
          return {
            label: item.name,
            value: item.uid,
          };
        });
      })
      .catch((error: any) => {
        notification.error({
          description: appMessages.generic.SOMETHING_WENT_WRONG,
          message: 'Error!',
        });
      });
  };

  const MyForm = ({
    errors,
    touched,
    values,
    handleBlur,
    handleSubmit,
    setFieldValue,
  }: any) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="c-info" style={{ marginBottom: 15 }}>
          Type in the channel name or list of people you want to share this
          suburb to.
        </div>
        <div className="c-form-field">
          <div className="c-form-field__label">Suburb preview</div>
          <div className="c-form-field__wrapper">
            <div className="h-iframe-wrapper">
              <div className="h-iframe-overlay h-iframe-overlay--read-only"></div>
              <IframeResizer
                title="Share Suburb"
                src={previewLink}
                checkOrigin={false}
                className="h-iframe"
                maxWidth={450}
                resizeFrom={'parent'}
                heightCalculationMethod={'lowestElement'}
                inPageLinks={true}
                autoResize={false}
              />
            </div>
          </div>
        </div>
        <div className="c-form-field">
          <div className="c-form-field__label">Message</div>
          <div className="c-form-field__wrapper">
            <div
              style={{
                padding: '4px 11px',
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                height: '100px',
              }}
            >
              <Editor
                editorState={values.editorState}
                onChange={(editorState) => {
                  setFieldValue('editorState', editorState);
                }}
                onBlur={handleBlur}
                ref={editorRef}
                placeholder="Check this out"
              />
            </div>
            {touched && touched.editorState && (
              <div className="c-form-field__error">
                {errors.editorState ? errors.editorState : undefined}
              </div>
            )}
          </div>
        </div>
        <div className="c-form-field">
          <div className="c-form-field__label">Send to</div>
          <div className="c-form-field__wrapper receiver">
            <Radio.Group
              options={receiverOptions}
              optionType="button"
              buttonStyle="solid"
              value={values.selectedReceiver}
              onChange={(e) => {
                setFieldValue('selectedReceiver', e.target.value);
              }}
            />
          </div>
        </div>
        {values.selectedReceiver === 'channel' && (
          <div className="c-form-field">
            <div className="c-form-field__label">Receiving channel</div>
            <div className="c-form-field__wrapper">
              <DebounceAjaxSelect
                fetchOptions={fetchChannels}
                value={values.receivingChannel}
                onChange={(v: any) => setFieldValue('receivingChannel', v)}
              />
              {touched && touched.receivingChannel && (
                <div className="c-form-field__error">
                  {errors.receivingChannel
                    ? errors.receivingChannel
                    : undefined}
                </div>
              )}
            </div>
          </div>
        )}
        {values.selectedReceiver !== 'channel' && (
          <div className="c-form-field">
            <div className="c-form-field__label">Receiving user</div>
            <div className="c-form-field__wrapper">
              <DebounceAjaxSelect
                fetchOptions={fetchUsers}
                value={values.receivingUser}
                onChange={(v: any) => setFieldValue('receivingUser', v)}
              />
              {touched && touched.receivingUser && (
                <div className="c-form-field__error">
                  {errors.receivingUser ? errors.receivingUser : undefined}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="c-form__btn-wrapper">
          <Button type="primary" htmlType="submit" disabled={!previewLink}>
            Share
          </Button>
        </div>
      </form>
    );
  };

  const Form = formikEnhancer(MyForm);

  return (
    <div className="l-suburb-share">
      {isLoading && <FullPageLoader />}
      <Form />
    </div>
  );
};

export default SuburbShare;
