import { initializeApp } from 'firebase/app';
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import localforage from 'localforage';

import env_constants from '../env/env_constants.json';

const firebaseApp = initializeApp(env_constants.FIREBASE_CONFIG);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = async (isRefreshing: boolean) => {
  // file deepcode ignore PromiseNotCaughtGeneral

  if ('serviceWorker' in navigator) {
    await localforage.setItem(
      'fc',
      JSON.stringify(env_constants.FIREBASE_CONFIG),
    );

    return await window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) {
          if (isRefreshing) {
            return serviceWorker;
          }

          serviceWorker.unregister();
        }

        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        );
      });
  }

  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async (isRefreshing: boolean) => {
  return await getOrRegisterServiceWorker(isRefreshing).then(
    (serviceWorkerRegistration) => {
      return getToken(messaging, {
        vapidKey: env_constants.FIREBASE_VAPID_KEY,
        serviceWorkerRegistration,
      });
    },
  );
};

export const deleteFirebaseToken = async () => {
  return await deleteToken(messaging);
};

export const onForegroundMessage = () =>
  new Promise((resolve) =>
    onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      resolve(payload);
    }),
  );
