import { notification } from 'antd';
import axios from 'axios';

import { deleteFirebaseToken } from '../../internals/firebase/firebase';
import { decryptData } from '../utils/api';
import { auth0Util } from '../utils/auth0';
import { clearSessionStorage } from '../utils/session_storage';

const isValidHTML = (htmlString: string) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    return doc instanceof Document && doc.documentElement.nodeName === 'HTML';
  } catch (error) {
    // Parsing error occurred, content is not valid HTML
    return false;
  }
};

const commonErrorHandler = () => {
  // The status 401 is for authentication related errors
  // If encountered, user is forcibly logged out of the Auth0 session
  clearSessionStorage();
  deleteFirebaseToken();

  auth0Util.getLogout()({
    returnTo: `${window.location.origin}/session-expired`,
  });
};

/*
 * Axios instance for common endpoints
 **/
export const endpoint = axios.create({});

endpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data) {
      if (!isValidHTML(error.response.data)) {
        const decryptedError = decryptData(error.response.data);

        if (decryptedError?.message) {
          notification.error({
            message: 'Error!',
            description: decryptedError.message,
          });
        }
      }
    }

    if (error?.response?.status === 401) {
      commonErrorHandler();
    }

    return Promise.reject(error);
  },
);

/*
 * Axios instance for authentication endpoints
 **/
export const authEndpoint = axios.create({});

authEndpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error?.response?.status === 401) {
        commonErrorHandler();
      } else {
        // The status 409 is for unverified email address
        if (error?.response?.status === 409) {
          window.location.href = '/email-verification';
        } else {
          window.location.href = '/error';
        }
      }
    } catch (e) {
      window.location.href = '/error';
    }

    return Promise.reject(error);
  },
);
