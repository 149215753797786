import { createAsyncThunk } from '@reduxjs/toolkit';
import { forEach } from 'lodash';

import { endpoint } from '../../../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../../../foundation/utils/api';
import env_constants from '../../../../../internals/env/env_constants.json';

export const postSuburbPopulation = createAsyncThunk(
  'suburb/population',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/population`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data).population;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbDwelling = createAsyncThunk(
  'suburb/dwelling',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/dwelling`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data).dwelling;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbRelatedArticles = createAsyncThunk(
  'suburb/relatedArticles',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/articles`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);

          result = {
            data: {
              articles: result.articles,
              authCode: result.authCode,
            },
            hashTagValue: apiParams.data.hashTag,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbGraph = createAsyncThunk(
  'suburb/graph',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/graph`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = {
            graphType: apiParams.data.graphType,
            data: decryptData(response.data),
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbGraphs = createAsyncThunk(
  'suburb/graphs',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/graphs`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        const graphResults: any = {};
        const decrypted = decryptData(response.data);

        forEach(decrypted.allGraphs, (data) => {
          graphResults[data.graphType] = {
            graphs: data.graphs,
          };
        });

        result = {
          data: graphResults,
        };
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbForecastGraph = createAsyncThunk(
  'suburb/prediction',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/prediction`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = {
            graphType: apiParams.data.graphType,
            data: decryptData(response.data),
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbForecastGraphs = createAsyncThunk(
  'suburb/predictions',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/predictions`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          const graphResults: any = {};
          const decrypted = decryptData(response.data);

          forEach(decrypted.allGraphs, (data) => {
            graphResults[data.graphType] = {
              graphs: data.graphs,
            };
          });

          result = {
            data: graphResults,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbGrowth = createAsyncThunk(
  'suburb/growth',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/growth`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbProjects = createAsyncThunk(
  'suburb/projects',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Suburb/projects`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = {
            ...decryptData(response.data).suburbProjects,
          };

          if (apiParams.data.pageIndex !== 1) {
            result = {
              projects: result.projects,
            };
          }
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
