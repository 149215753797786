import { CometChat } from '@cometchat-pro/chat';
import { Avatar, Button, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AddMember from '../../../foundation/assets/svgs/AddMember';
import LeftCaret from '../../../foundation/assets/svgs/LeftCaret';
import MenuIcon from '../../../foundation/assets/svgs/MenuIcon';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import appMessages from '../../../foundation/messages';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganizationDetails } from '../../account/redux/async_thunks';
import { selectInfo } from '../../account/redux/selectors';
import { resetSiderMobileContentValues } from '../../layout/redux/slice';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import ChannelModal from '../components/channel_options/channel_modal/ChannelModal';
import InviteMembers from '../components/invite_members/InviteMembers';
import Editor from '../editor/Editor';
import useReceiveNewMessage from '../helpers/useReceiveNewMessage';
import useReceiverInformation from '../helpers/useReceiverInformation';
import {
  selectChannelsList,
  selectDirectMessagesList,
  selectSelectedConversation,
} from '../redux/selectors';
import { selectccUser } from '../redux/selectors';
import { setChannelsList, setDirectMessagesList } from '../redux/slice';
import { fetchChannelMembers } from '../utils/controllers/conversationListController';
import {
  fetchPreviousMessages,
  markMessageAsDelivered,
  markMessageAsRead,
  sendCustomMessage,
  sendMediaMessage,
} from '../utils/controllers/messageController';
import * as enums from '../utils/enums';
import ProfileView from './chat_message/ProfileView';
import ChatRenderer from './chat_renderer/ChatRenderer';
import ChatThread from './chat_thread/ChatThread';

const ChatWindow = () => {
  const dispatch = useAppDispatch();
  let lastScrollTop: number | undefined = 0;

  const [isInviteButtonLoading, setIsInviteButtonLoading] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(true);
  const [isMobileView] = useBreakpoint();

  const [messages, setMessages] = useState<any[]>([]);
  const [replies, setReplies] = useState<any[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [parentMessage, setParentMessage] = useState<any>(null);

  const [isChannelModalVisible, setIsChannelModalVisible] = useState(false);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [channelModalActiveKey, setChannelModalActiveKey] = useState('about');

  const [isThreadPanelVisible, setIsThreadPanelVisible] =
    useState<boolean>(false);
  // const [editorSize, setEditorSize] = useState(100);

  const selectedConversation = useAppSelector(selectSelectedConversation);
  const ccUser = useAppSelector(selectccUser);

  const channelsList = useAppSelector(selectChannelsList);

  const directMessagesList = useAppSelector(selectDirectMessagesList);

  const messagesEnd = useRef<HTMLDivElement>(null);

  // Invite user details
  const user = useAppSelector(selectUser);
  const clientIp = useAppSelector(selectClientIp);
  const organisationInfo = useAppSelector(selectInfo);

  const conversationType = useMemo(
    () => selectedConversation?.conversationType,
    [selectedConversation],
  );

  /**
   * Custom Hooks
   */
  const [receiverId, receiverType] = useReceiverInformation();

  const [newMessage, newMessageType] = useReceiveNewMessage();

  const isGroupConversation = useMemo(() => {
    return !!selectedConversation?.conversationWith?.guid;
  }, [selectedConversation]);

  const fetchMessages = (parentMessageId: number, isNewChat?: boolean) => {
    const promise = new Promise<any[]>((resolve, reject) => {
      fetchPreviousMessages(
        selectedConversation?.conversationType,
        selectedConversation?.conversationWith,
        parentMessageId,
        !isNewChat && messages.length > 10 ? messages[0].id : undefined,
      )
        .then((messageList) => {
          resolve(messageList);
        })
        .catch((error) => reject(error));
    });

    return promise;
  };

  const fetchMembers = (item: any) => {
    return new Promise((resolve, reject) => {
      if (item.conversationType === CometChat.ACTION_TYPE.TYPE_GROUP) {
        fetchChannelMembers(item?.conversationWith?.guid)
          .then((channelMemebers) => {
            setMembers(JSON.parse(JSON.stringify(channelMemebers)));
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  };

  const getChannelOwner = () => {
    if (!members || !members.length) {
      return null;
    }

    return members.find((member: any) => {
      return member.uid === selectedConversation.conversationWith.owner;
    });
  };

  const removeMemberFromChannelMembers = (member: any) => {
    const oldMembers = [...members];

    const updtedMembers = oldMembers.filter(
      (v: any) => v.uid.toString() !== member?.uid.toString(),
    );

    setMembers(updtedMembers);
  };

  const updateScopeFromChannelMembers = (member: any, scope: any) => {
    const oldMembers = [...members];

    const updtedMembers = oldMembers.map((v: any) => {
      if (v.uid === member.uid) {
        return {
          ...v,
          ...{
            scope: scope,
          },
        };
      }
      return v;
    });

    setMembers(updtedMembers);
  };

  const scrollToBottom = (ref?: any, scrollHeight = 0) => {
    if (ref && ref.current && ref?.current) {
      ref.current.scrollTop = ref?.current?.scrollHeight - scrollHeight;
    }
  };

  const updateChatListUnreadStatus = () => {
    // Check if conversation is with a user vs group
    // Then unset the unread property
    if (
      selectedConversation &&
      selectedConversation.conversationType === CometChat.RECEIVER_TYPE.USER
    ) {
      dispatch(
        setDirectMessagesList(
          directMessagesList.map((conversation: any) => {
            if (
              conversation.conversationId ===
              selectedConversation.conversationId
            ) {
              return {
                ...conversation,
                ...{ unreadMessageCount: 0 },
              };
            }

            return conversation;
          }),
        ),
      );
    } else {
      dispatch(
        setChannelsList(
          channelsList.map((conversation: any) => {
            if (
              conversation.conversationId ===
              selectedConversation.conversationId
            ) {
              return {
                ...conversation,
                ...{ unreadMessageCount: 0 },
              };
            }

            return conversation;
          }),
        ),
      );
    }
  };

  const fetchData = async (isNew?: boolean) => {
    try {
      setIsChatLoading(true);
      await Promise.all([
        messageHandler([], undefined, isNew),
        fetchMembers(selectedConversation),
      ]);

      if (isNew) {
        updateChatListUnreadStatus();
      }

      setIsChatLoading(false);
    } catch (error) {
      console.log(error);
      setIsChatLoading(false);
      notification.error({
        description: appMessages.chat_messages.ERROR_FETCHING_MESSAGES,
        message: 'Error!',
      });
    }
  };

  const delay = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 200);
    });

  const messageHandler = async (
    currentList: any,
    parentMessageId?: any,
    isNewChat?: boolean,
  ) => {
    return new Promise((resolve, reject) => {
      fetchMessages(parentMessageId, isNewChat)
        .then((list: any[]) => {
          if (list.length === 0) {
            // Show No Messages found error
          } else {
            // Remove error
          }

          // updating messagecount variable
          // TODO: Manage Message count
          // this.messageCount = messageList.length;

          list.forEach((message) => {
            // if the sender of the message is not the loggedin user
            if (message.getSender().getUid() !== ccUser?.uid) {
              // mark the message as delivered
              markMessageAsDelivered(message, ccUser);

              // mark the message as read
              if (!message.readAt || message.readAt === false) {
                markMessageAsRead(message);
              }
            }
          });

          if (parentMessageId) {
            setReplies(list.concat(currentList));
            resolve(true);
          } else {
            setMessages(list.concat(currentList));
            delay()
              .then(() => {
                scrollToBottom(messagesEnd, lastScrollTop);
                lastScrollTop = messagesEnd?.current?.scrollHeight;
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((error) => {
          reject(error);
          if (currentList.length === 0) {
            // this.setState({ decoratorMessage: 'SOMETHING_WRONG' });
          }

          if (error && error?.code && error.code === 'ERR_GUID_NOT_FOUND') {
            // reject(error);
          }
        });
    });
  };

  useEffect(() => {
    if (selectedConversation) {
      setMessages([]);
      setReplies([]);
      setMembers([]);
      setIsThreadPanelVisible(false);
      fetchData(true);
    }
  }, [selectedConversation]);

  const handleScroll = (e: any) => {
    const scrollTop = e.currentTarget.scrollTop;
    const scrollHeight = e.currentTarget.scrollHeight;
    const clientHeight = e.currentTarget.clientHeight;

    lastScrollTop = scrollHeight - scrollTop;

    if (lastScrollTop === clientHeight) {
      // this.props.actionGenerated(enums.ACTIONS["CLEAR_UNREAD_MESSAGES"]);
    }

    const top = Math.round(scrollTop) === 0;

    // if (isReplies) {
    //   if (top && replies.length) {
    //     messageHandler(replies, parentMessage.id);
    //   }
    // } else {
    if (top && messages.length) {
      // TODO: Check for no more old messages and stop calling this function.
      messageHandler([...messages]);
    }
    // }
  };

  /**
   * Check if the conversation is through direct messages and not from channels.
   */
  const isDMConversation = useMemo(
    () =>
      selectedConversation && selectedConversation.conversationType === 'user',
    [selectedConversation],
  );

  const getConversationMembers = useCallback(() => {
    if (!!members && members.length) {
      return members;
    } else if (
      selectedConversation &&
      selectedConversation.conversationWith &&
      selectedConversation.conversationWith.metadata
    ) {
      return [ccUser, selectedConversation.conversationWith];
    }

    return [];
  }, [members]);

  const updateReplies = (msg: any) => {
    setReplies((prev) => {
      return [...prev, msg];
    });

    setMessages(
      messages.map((message: any) => {
        if (message.id === msg.parentMessageId) {
          return {
            ...message,
            ...{ replyCount: message.replyCount ? message.replyCount + 1 : 1 },
          };
        }

        return message;
      }),
    );
  };

  const updateMessages = (msg: any) => {
    setMessages((prev) => {
      return [...prev, msg];
    });

    delay()
      .then(() => {
        scrollToBottom(messagesEnd);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (newMessage && newMessageType) {
      messageReceivedHandler(newMessage);
    }
  }, [newMessage, newMessageType]);

  const messageReceivedHandler = (message: any) => {
    if (message && message.parentMessageId) {
      updateReplies(message);
    } else {
      updateMessages(message);
    }

    markMessageAsRead(message);
  };

  const sendMediaMessageHandler = (messageInput: any, messageType: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const message = await sendMediaMessage(
          messageInput,
          messageType,
          receiverId,
          receiverType,
          ccUser,
        );
        updateMessages(message);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const editorMessageSendHandler = async (raw: any, html: string) => {
    const customData = {
      rawEditorState: raw,
      html,
    };

    const message = await sendCustomMessage(
      customData,
      enums.CUSTOM_TYPE_DRAFTJS,
      receiverId,
      receiverType,
      ccUser,
    );

    updateMessages(message);
  };

  const groupMembersModalClose = () => {
    setIsChannelModalVisible(false);
  };

  const fetchAccountInfo = async () => {
    setIsInviteButtonLoading(true);

    try {
      const options = {
        token: user.jwtToken.token,
        clientIp: clientIp,
        sessionId: user.sessionId,
      };

      await dispatch(getOrganizationDetails({ options })).unwrap();

      setIsInviteButtonLoading(false);
      setIsInviteModalVisible(true);
    } catch (error) {
      setIsInviteButtonLoading(false);
      console.log(error);
    }
  };

  const handleInviteModalCancel = () => {
    setIsInviteModalVisible(false);
  };

  const onInviteBtnClick = () => {
    // To make sure that the account information is available before allowing user invitations
    if (!organisationInfo) {
      fetchAccountInfo();
    } else {
      setIsInviteModalVisible(true);
    }
  };

  if (selectedConversation) {
    return (
      <div className="l-chat-window">
        {isChannelModalVisible && !isDMConversation && (
          <ChannelModal
            members={getConversationMembers()}
            conversation={selectedConversation}
            cancelHandler={groupMembersModalClose}
            fetchMembers={fetchMembers}
            channelOwner={getChannelOwner()}
            activeKey={channelModalActiveKey}
            removeMemberFromChannelMembers={removeMemberFromChannelMembers}
            updateScopeFromChannelMembers={updateScopeFromChannelMembers}
          />
        )}
        {isInviteModalVisible && (
          <InviteMembers
            selectedConversation={selectedConversation}
            handleCancel={handleInviteModalCancel}
            members={getConversationMembers()}
            fetchMembers={fetchMembers}
            handleChannelModalDisplay={() => {
              setIsChannelModalVisible(true);
              setChannelModalActiveKey('members');
            }}
          />
        )}
        <section
          className={`l-chat-window__chat-section ${
            isThreadPanelVisible
              ? ' l-chat-window__chat-section--thread-visible'
              : ''
          }`}
        >
          {isChatLoading && (
            <FullPageLoader style={{ background: 'rgba(255,255,255,0.5)' }} />
          )}
          <div className="l-chat-window__header">
            <div className="l-chat-window__header-left-side">
              {isMobileView && (
                <div
                  className="l-chat-window__header-back-icon"
                  onClick={() => {
                    dispatch(resetSiderMobileContentValues(undefined));
                  }}
                >
                  <LeftCaret />
                </div>
              )}
              <div
                className="l-chat-window__header-menu-icon"
                onClick={() => {
                  setIsChannelModalVisible(true);
                  setChannelModalActiveKey('about');
                }}
              >
                <MenuIcon />
              </div>
              <div className="l-chat-window__header-conversation-title">
                {!isGroupConversation ? (
                  <ProfileView
                    pid={selectedConversation?.conversationWith?.metadata.pid}
                    trigger={'click'}
                    isFollowEnabled
                  >
                    <span className="l-chat-window__header-conversation-title-text">
                      {selectedConversation?.conversationWith?.name}
                    </span>
                  </ProfileView>
                ) : (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsChannelModalVisible(true);
                      setChannelModalActiveKey('about');
                    }}
                  >
                    {selectedConversation?.conversationWith?.name}
                  </span>
                )}
              </div>
            </div>
            {conversationType === CometChat.RECEIVER_TYPE.GROUP && (
              <div className="l-chat-window__header-right-side">
                <Avatar.Group
                  maxCount={2}
                  maxPopoverTrigger="click"
                  size={35}
                  maxStyle={{
                    color: '#f56a00',
                    backgroundColor: '#fde3cf',
                    cursor: 'pointer',
                  }}
                >
                  {members.map((item: any) => (
                    <Avatar
                      onClick={() => {
                        setIsChannelModalVisible(true);
                        setChannelModalActiveKey('members');
                      }}
                      src={item.avatar}
                      key={item.uid}
                    />
                  ))}
                </Avatar.Group>
                {selectedConversation &&
                  selectedConversation.conversationWith &&
                  selectedConversation.conversationWith.scope ===
                    CometChat.GROUP_MEMBER_SCOPE.ADMIN && (
                    <Button
                      className="l-chat-browse-channels__header-btn-round"
                      onClick={onInviteBtnClick}
                      style={
                        isInviteButtonLoading
                          ? { padding: '0 10px', borderRadius: '50px' }
                          : {}
                      }
                      loading={isInviteButtonLoading}
                    >
                      <AddMember />
                    </Button>
                  )}
              </div>
            )}
          </div>
          <div
            className="l-chat-window__msg-container"
            ref={messagesEnd}
            onScroll={handleScroll}
          >
            <ChatRenderer
              messages={messages}
              ccUser={ccUser}
              isDMConversation={isDMConversation}
              selectedConversation={selectedConversation}
              getConversationMembers={getConversationMembers}
              setIsThreadPanelVisible={setIsThreadPanelVisible}
              setParentMessage={setParentMessage}
              isReply={false}
              replies={replies}
              parentMessage={parentMessage}
            />
          </div>
          <div className="l-chat-window__msg-editor-container">
            <Editor
              editorMessageSendHandler={editorMessageSendHandler}
              sendMediaMessageHandler={sendMediaMessageHandler}
              conversationMembers={getConversationMembers()}
              isReplying={false}
              selectedConversation={selectedConversation}
            />
          </div>
        </section>

        {isThreadPanelVisible && (
          <ChatThread
            ccUser={ccUser}
            selectedConversation={selectedConversation}
            getConversationMembers={getConversationMembers}
            receiverId={receiverId}
            receiverType={receiverType}
            parentMessage={parentMessage}
            updateReplies={updateReplies}
            setIsThreadPanelVisible={setIsThreadPanelVisible}
            messageHandler={messageHandler}
            scrollToBottom={scrollToBottom}
            replies={replies}
            setParentMessage={setParentMessage}
            isDMConversation={isDMConversation}
            setReplies={setReplies}
          />
        )}
      </div>
    );
  }
  return null;
};

export default ChatWindow;
