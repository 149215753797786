import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React, { useEffect } from 'react';
import type { ControlPosition, MapRef } from 'react-map-gl';
import { useControl } from 'react-map-gl';

import { useAppSelector } from '../../../../../store/hooks';
import {
  selectIsHeatmapActive,
  selectSuburbMetrics,
} from '../../../redux/selectors';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  isPolygonToolEnabled?: boolean;
  isDeleteAllPolygon?: boolean;
  setIsDeleteAllPolygon?: any;
};

export default function DrawControl(props: DrawControlProps) {
  const suburbMetrics = useAppSelector(selectSuburbMetrics);
  const isHeatmapActive = useAppSelector(selectIsHeatmapActive);
  const { isPolygonToolEnabled, isDeleteAllPolygon, setIsDeleteAllPolygon } =
    props;

  // This hook returns a local mapbox draw instance, assigning to a variable to access its functions
  const draw = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    // @ts-ignore
    ({ map }: { map: MapRef }) => {
      // @ts-ignore
      map.on('draw.create', props.onCreate);
      // @ts-ignore
      map.on('draw.update', props.onUpdate);
      // @ts-ignore
      map.on('draw.delete', props.onDelete);
    },
    ({ map }: { map: MapRef }) => {
      // @ts-ignore
      map.off('draw.create', props.onCreate);
      // @ts-ignore
      map.off('draw.update', props.onUpdate);
      // @ts-ignore
      map.off('draw.delete', props.onDelete);
    },
    {
      position: props.position,
    },
  );

  /* Workarounds */

  useEffect(() => {
    if (isPolygonToolEnabled) {
      // Enable the drawing mode
      draw.changeMode('draw_polygon');
    }
  }, [isPolygonToolEnabled]);

  useEffect(() => {
    if (!isHeatmapActive || !isPolygonToolEnabled) {
      // Dynamically remove all drawn polygons in the map & cancel the drawing mode
      draw.deleteAll();
    }
  }, [suburbMetrics, isHeatmapActive, isPolygonToolEnabled]);

  useEffect(() => {
    if (isDeleteAllPolygon) {
      draw.deleteAll();
      draw.changeMode('draw_polygon');
      setIsDeleteAllPolygon(false);
    }
  }, [isDeleteAllPolygon]);

  return <></>;
}
