import React from 'react';

import PolygonMessageIcon from '../../../../foundation/assets/svgs/PolygonMessageIcon';
import { useAppSelector } from '../../../../store/hooks';
import { selectAccountPaymentMethod } from '../../redux/selectors';

const PaymentElements = () => {
  const paymentMethod = useAppSelector(selectAccountPaymentMethod);
  return (
    <div className="l-update-plan__content-section">
      <div
        className={`l-update-plan__plan-card l-update-plan__plan-card--payment l-update-plan__plan-card--selected`}
      >
        <div className="l-update-plan__card-info">
          **** **** **** {paymentMethod?.last4}
        </div>
        <div className="l-update-plan__card-expiry">{`Expires at ${paymentMethod?.expiryDate}`}</div>
      </div>
      <div className="l-update-plan__message-container">
        <div className="l-update-plan__message-icon">
          <PolygonMessageIcon />
        </div>
        <div className="l-update-plan__message">{`If you would like to use a different payment method please update your payment details via the settings page.`}</div>
      </div>
    </div>
  );
};

export default PaymentElements;
