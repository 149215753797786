import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';

import Bin from '../../../foundation/assets/svgs/Bin';
import Email from '../../../foundation/assets/svgs/Email';
import Lock from '../../../foundation/assets/svgs/Lock';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import useLogout from '../../../foundation/custom_hooks/useLogout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteProfileRequest,
  postChangePassword,
} from '../redux/async_thunks';
import {
  selectClientIp,
  selectUser,
  selectUserProfile,
} from '../redux/selectors';

const Settings = () => {
  const dispatch = useAppDispatch();
  const logoutUser = useLogout();

  const [isLoading, setIsLoading] = useState(false);

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const PROFILE = useAppSelector(selectUserProfile);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const requestPasswordUpdate = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        postChangePassword({
          data: {
            userId: USER.userId,
            clientIp: CLIENT_IP,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      logoutUser(USER.email);
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const requestDeleteAccount = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        deleteProfileRequest({
          data: {
            userId: USER.userId,
            clientIp: CLIENT_IP,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      logoutUser('delete');
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const confirmPasswordChangeHandler = () => {
    Modal.confirm({
      title: <strong>Confirm Password Update</strong>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>
            You will be logged out of your current session & will receive an
            email for password change.
          </p>
          <br />
          <p>Proceed with this action?</p>
        </>
      ),
      okText: 'Proceed',
      onOk: requestPasswordUpdate,
      cancelText: 'Cancel',
      centered: true,
      width: 400,
    });
  };

  const confirmDeleteAccountHandler = () => {
    Modal.confirm({
      title: <strong>Confirm Delete Account</strong>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>
            You will be logged out of your current session. An email containing
            the next steps will be sent to you.
          </p>
          <br />
          <p>Proceed with this action?</p>
        </>
      ),
      okText: 'Proceed',
      onOk: requestDeleteAccount,
      cancelText: 'Cancel',
      centered: true,
      width: 400,
    });
  };

  return (
    <>
      <div className="l-settings">
        {isLoading && <FullPageLoader />}
        <dl className="l-settings__list">
          <dt
            className="l-settings__list-label"
            style={{ width: '100%', flex: '1 1 100%' }}
          >
            <div className="l-settings__icon">
              <Email />
            </div>
            <div>
              <strong>Email</strong>
              <span>{USER.email}</span>
            </div>
          </dt>
          {PROFILE && PROFILE.allowPasswordChange && (
            <>
              <dt className="l-settings__list-label">
                <div className="l-settings__icon">
                  <Lock />
                </div>
                <div>
                  <strong>Password</strong>
                  <span>**********</span>
                </div>
              </dt>
              <dd className="l-settings__list-action">
                <button
                  className="l-settings__button"
                  onClick={confirmPasswordChangeHandler}
                >
                  Update
                </button>
              </dd>
            </>
          )}
        </dl>
        <button
          className="l-settings__delete-button"
          onClick={confirmDeleteAccountHandler}
        >
          <span>Delete Account</span>
          <Bin />
        </button>
      </div>
    </>
  );
};

export default Settings;
