import { createSlice } from '@reduxjs/toolkit';

import { LayoutState } from './types';

const initialState: LayoutState = {
  breakpoint: 'desktop',
  mobile_content_sider: {
    isVisible: false,
    view: undefined,
    showHeader: true,
    showBackButton: undefined,
    isClosable: undefined,
    headerTitle: undefined as any,
    parentConfig: undefined as any,
  },
};

export const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setBreakpoint: (state, action) => {
      state.breakpoint = action.payload;
    },
    setSiderMobileContentValues: (state, action) => {
      state.mobile_content_sider = action.payload;
    },
    resetSiderMobileContentValues: (state, action) => {
      state.mobile_content_sider = {
        isVisible: false,
        view: undefined,
        showHeader: true,
        showBackButton: undefined,
        isClosable: undefined,
        headerTitle: undefined as any,
        parentConfig: undefined as any,
      };
    },
  },
});

export const {
  setBreakpoint,
  setSiderMobileContentValues,
  resetSiderMobileContentValues,
} = layout.actions;

export default layout.reducer;
