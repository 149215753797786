import React from 'react';
import { useNavigate } from 'react-router-dom';

import ExclamationOutlined from '../../assets/svgs/ExclamantionOutlined';

const DeleteSuccess = () => {
  const navigate = useNavigate();

  const clickHandler = (e: any) => {
    e.preventDefault();
    navigate('/sign-up');
  };

  return (
    <div className="l-custom-result">
      <div className="l-custom-result__content">
        <div className="l-custom-result__icon">
          <ExclamationOutlined />
        </div>
        <h4>Unauthorized</h4>
        <p>Access to this page is not allowed.</p>
        <button
          type="button"
          className="l-custom-result__button"
          onClick={clickHandler}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default DeleteSuccess;
