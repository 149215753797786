import { Pagination as AntPagination } from 'antd';
import React from 'react';

import useSearch from '../../../../foundation/custom_hooks/useSearch';
import { useAppSelector } from '../../../../store/hooks';
import { selectOptions, selectResults } from '../../redux/selectors';

const Pagination = ({ itemsPerPage, searchHandlerOptions }: any) => {
  const options = useAppSelector(selectOptions);
  const results = useAppSelector(selectResults);
  const fetchResults = useSearch();

  return (
    <div>
      <AntPagination
        current={options.pageIndex}
        total={results.pageCount * itemsPerPage}
        pageSize={itemsPerPage}
        onChange={(pageNumber: number) => {
          fetchResults({
            ...searchHandlerOptions,
            isUsingLastSearchValues: true,
            pageIndexParam: pageNumber,
          });
        }}
        showSizeChanger={false}
        hideOnSinglePage={true}
        className="custom-pagination"
      />
    </div>
  );
};

export default Pagination;
