import { createSlice } from '@reduxjs/toolkit';

import {
  cancelSubscription,
  getOrganizationDetails,
  updateOrganization,
  updatePaymentMethod,
} from './async_thunks';
import { AccountState } from './types';

const initialState: AccountState = {
  allRoles: [],
  info: undefined,
};

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setAllAppRoles: (state, action) => {
      state.allRoles = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrganizationDetails.fulfilled, (state, action) => {
      state.info = action.payload;
    });
    builder.addCase(updatePaymentMethod.fulfilled, (state, action) => {
      const subscription = state.info?.subscription;
      if (subscription && state.info) {
        state.info = {
          ...state.info,
          subscription: {
            ...state.info.subscription,
            paymentMethods: action.payload,
          },
        };
      }
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.info = action.payload;
    });

    builder.addCase(cancelSubscription.fulfilled, (state, action) => {
      const subscription = state.info?.subscription;

      if (subscription && state.info) {
        state.info = {
          ...state.info,
          subscription: {
            ...state.info.subscription,
            currentPlanDetails: action.payload,
          },
        };
      }
    });
  },
});

export const { setAllAppRoles } = chat.actions;

export default chat.reducer;
