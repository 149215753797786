import { useSelector } from 'react-redux';

import { selectSearchType } from '../../features/search/redux/selectors';

export const metricKeyValues: any = {
  suburb: [
    {
      key: 'Median Price',
      value: 'medianSalePrice',
    },
    {
      key: 'Last 12 Months Median Price Growth',
      value: 'medianPriceGrowth1',
    },
    {
      key: 'Last 3 Years Median Price Growth',
      value: 'medianPriceGrowth3',
    },
    {
      key: 'Last 3 Months Sales Volume Growth',
      value: 'quarterSalesGrowth',
    },
    {
      key: 'Last 12 Months Sales Volume Growth',
      value: 'yearSalesGrowth',
    },
    {
      key: 'Median Gross Rental Yield',
      value: 'grossRentalYield',
    },
    {
      key: 'Last 12 Months Rental Growth',
      value: 'yearRentalGrowth1',
    },
    {
      key: 'Vacancy Rate',
      value: 'vacancyRate',
    },
    {
      key: 'Average Days On Market',
      value: 'averageDaysMarket',
    },
    {
      key: 'Population',
      value: 'population',
    },
    {
      key: '3 Year Population Growth Since 2021 Census',
      value: 'populationForecastRate3',
    },
    {
      key: '5 Year Population Growth Since 2021 Census',
      value: 'populationForecastRate5',
    },
    {
      key: '15 Month Price Growth Forecast',
      value: 'medianSalePriceForecast15',
    },
    {
      key: '15 Month Rent Growth Forecast',
      value: 'medianWeeklyAskingRentForecast15',
    },
    {
      key: 'LGA Total Estimated Project Investment',
      value: 'lgaTotalEstInvestment',
    },
  ],
  property: [
    {
      key: 'Asking Price',
      value: 'askingPrice',
    },
    {
      key: 'Bedrooms',
      value: 'bedrooms',
    },
    {
      key: 'Baths',
      value: 'baths',
    },
    {
      key: 'Car Spaces',
      value: 'carSpaces',
    },
    {
      key: 'Land Size',
      value: 'landSize',
    },
    {
      key: 'Average Days On Market',
      value: 'averageDaysMarket',
    },
    {
      key: 'Gross Rental Yield',
      value: 'grossRentalYield',
    },
    {
      key: 'Public Housing',
      value: 'publicHousing',
    },
    {
      key: 'UMV % Estimate',
      value: 'umvEstimate',
    },
  ],
};

const useMetricKeyValues = () => {
  const searchType = useSelector(selectSearchType);
  return metricKeyValues[searchType];
};

export default useMetricKeyValues;
