import React from 'react';

import { useAppSelector } from '../../../../store/hooks';
import View from '../../../search/view/details/population/view/View';
import {
  selectDescriptors,
  selectPopulation,
  selectSuburb,
} from '../../redux/selectors';

type Props = {
  setAreDetailsLoading?: any;
};

const Population = ({ setAreDetailsLoading }: Props) => {
  const population = useAppSelector(selectPopulation);
  const suburb = useAppSelector(selectSuburb);
  const descriptor = useAppSelector(selectDescriptors);

  return (
    <View
      population={population}
      suburb={suburb}
      descriptor={descriptor}
      setAreDetailsLoading={setAreDetailsLoading}
    />
  );
};

export default Population;
