import React from 'react';

function MailBird() {
  return (
    <svg
      width="514"
      height="218"
      viewBox="0 0 514 218"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3129_122696)">
        <rect width="514" height="218" fill="#EFF0F7" />
        <path
          d="M230.509 49.0058C230.509 44.567 219.888 40.9686 206.796 40.9686C201.983 40.9686 197.507 41.4559 193.768 42.2905C193.162 40.7897 192.244 39.4336 191.076 38.3112C189.907 37.1888 188.514 36.3253 186.988 35.7773C185.461 35.2293 183.836 35.0092 182.218 35.1313C180.6 35.2535 179.027 35.7153 177.6 36.4863C175.366 30.3831 168.689 25.9537 160.8 25.9537C152.31 25.9537 145.222 31.0811 143.569 37.9047C140.793 36.1706 137.439 35.6075 134.246 36.3391C133.665 30.4384 128.345 25.8172 121.855 25.8172C120.914 25.8166 119.976 25.9157 119.056 26.1126C119.607 24.3134 119.885 22.4425 119.882 20.5615C119.882 9.20375 109.797 -0.00341797 97.3557 -0.00341797C85.8704 -0.00341797 76.3938 7.84304 75.0047 17.9848C73.5515 17.4394 72.0115 17.1602 70.4588 17.1609C63.2598 17.1609 57.3461 23.0769 56.7277 30.6197H56.6828C52.3387 30.6197 48.4524 32.2888 45.862 34.9137C43.8203 32.9397 40.8227 31.6932 37.4829 31.6932C31.3343 31.7026 26.3528 35.9201 26.3528 41.1299C26.3528 41.3935 26.367 41.6549 26.3918 41.9126C24.9119 41.8079 23.3765 41.7514 21.8021 41.7514C9.76456 41.7514 0.00585938 45.0001 0.00585938 49.0093H230.509V49.0058Z"
          fill="white"
        />
        <path
          d="M335.596 207.304C338.042 207.299 340.485 207.509 342.895 207.932C343.097 199.544 349.422 192.813 357.19 192.813C358.835 192.813 360.466 193.118 361.999 193.714C362.544 179.422 374.348 168 388.832 168C403.665 168 415.69 179.971 415.69 194.748C415.69 194.895 415.69 195.042 415.69 195.188C416.939 194.895 418.217 194.748 419.5 194.748C423.149 194.736 426.696 195.945 429.573 198.182C430.662 191.189 437.618 185.797 446.038 185.797C455.213 185.797 462.651 192.2 462.651 200.1C462.65 201.377 462.454 202.647 462.068 203.865C463.913 202.91 466.165 202.348 468.595 202.348C474.845 202.348 479.911 206.055 479.911 210.629C479.911 211.135 479.849 211.638 479.726 212.129C483.311 211.187 487.786 210.629 492.641 210.629C504.437 210.629 514 213.929 514 218H314C314 212.093 323.669 207.304 335.596 207.304Z"
          fill="white"
        />
        <path
          d="M244.624 117.087H135.604C135.604 117.087 135.604 129.734 167.114 139.327L261.645 125.286L244.624 117.087Z"
          fill="#6D778E"
        />
        <path
          d="M146.063 117.087H135.604C135.604 117.087 135.604 129.734 167.114 139.327L169.962 138.904C154.075 131.106 148.22 122.425 146.063 117.087Z"
          fill="#596175"
        />
        <path
          d="M308.808 108.573C309.55 102.805 307.542 97.4224 303.921 94.1184L303.908 94.1066L252.342 46.2168C252.342 46.2168 234.45 97.4613 259.05 119.211C270.221 129.088 293.174 123.606 297.437 122.473C303.186 121.057 307.904 115.6 308.808 108.573Z"
          fill="#596175"
        />
        <path
          d="M329.896 104.827C329.896 104.827 374.672 103.225 381.823 96.5066H329.896V104.827Z"
          fill="#C97082"
        />
        <path
          d="M375.935 99.3234C378.622 98.4994 380.693 97.5683 381.823 96.5066H329.896V99.3234H375.935Z"
          fill="#9C5766"
        />
        <path
          d="M351.102 110.373L392.44 145.31L419.908 94.1383L397.254 71.9443L351.102 110.373Z"
          fill="#651FFF"
        />
        <path
          d="M390.438 143.617L392.44 145.31L419.908 94.1382L418.43 92.6892L394.688 100.118L390.438 143.617Z"
          fill="#C3BEFC"
        />
        <path
          d="M392.44 145.31L419.908 94.1382L394.921 99.9683L392.44 145.31Z"
          fill="#651FFF"
        />
        <path
          d="M398.054 72.7283L397.254 71.9443L351.102 110.373L353.445 112.354L402.617 106.539L398.054 72.7283Z"
          fill="#C3BEFC"
        />
        <path
          d="M397.254 71.9443L351.102 110.373L400.779 104.817L397.254 71.9443Z"
          fill="#651FFF"
        />
        <path
          d="M336.136 86.2246C336.136 86.2246 380.911 88.4787 388.063 97.9378H336.136V86.2246Z"
          fill="#C97082"
        />
        <path
          d="M370.171 89.5805C369.762 93.0423 360.792 95.0928 350.134 94.1605C339.476 93.2283 331.167 89.6652 331.575 86.2034C331.983 82.7416 340.954 80.6911 351.612 81.6234C362.27 82.5556 370.579 86.1175 370.171 89.5805Z"
          fill="#DEB680"
        />
        <path
          d="M351.611 81.6247C342.22 80.8008 334.139 82.298 332.073 85.0383C335.716 83.6187 341.818 83.0019 348.606 83.5963C359.264 84.5286 367.572 88.0905 367.165 91.5523C367.108 91.9823 366.936 92.389 366.667 92.7294C368.744 91.9195 370.022 90.846 370.171 89.5924C370.579 86.1177 362.269 82.5558 351.611 81.6247Z"
          fill="#F1E1CA"
        />
        <path
          d="M357.785 88.4388C357.785 74.8493 344.567 63.8318 328.261 63.8318C318.194 63.8318 309.307 68.0316 303.977 74.4432L303.935 74.4927C295.571 84.452 297.798 88.5895 281.881 88.5895H281.87C273.675 87.9362 265.852 89.4476 259.359 92.6893L219.588 109.576C218.177 110.015 216.811 110.587 215.509 111.284L202.357 117.089C202.357 117.089 202.375 117.149 202.412 117.263C203.338 120.283 204.585 123.196 206.13 125.953C212.621 138.559 228.435 160.158 260.492 160.158C260.877 160.158 261.259 160.155 261.64 160.149C288.406 160.149 321.867 154.97 353.706 100.925C356.298 97.2646 357.785 92.9965 357.785 88.4388Z"
          fill="#828EAA"
        />
        <path
          d="M340.003 121.198L312.765 101.938L297.17 83.7209C294.845 86.6178 292.293 88.1327 285.527 88.4999C289.008 92.9375 293.939 100.687 297.348 112.145C301.291 125.399 301.902 142.867 301.89 152.664C314.312 147.107 327.193 137.536 340.003 121.198Z"
          fill="#651FFF"
        />
        <path
          d="M335.124 107.53C319.02 107.53 305.965 96.364 305.965 82.5898C305.965 77.3093 307.885 72.4138 311.158 68.3823C308.435 69.9797 306.007 72.0295 303.977 74.4431L303.934 74.4926C300.354 78.756 298.712 81.953 296.759 84.2141C299.945 90.847 305.331 101.227 309.797 105.802C318.332 114.544 328.715 120.877 336.915 125.007C342.235 118.669 347.549 111.202 352.823 102.407C347.915 105.621 341.781 107.53 335.124 107.53Z"
          fill="#C3BEFC"
        />
        <path
          d="M338.458 101.752C347.812 101.752 355.395 95.8908 355.395 88.6612C355.395 81.4316 347.812 75.5708 338.458 75.5708C329.103 75.5708 321.52 81.4316 321.52 88.6612C321.52 95.8908 329.103 101.752 338.458 101.752Z"
          fill="#A7B3D7"
        />
        <path
          d="M345.514 90.0313C342.166 90.0313 339.364 87.8596 338.64 84.9463C338.52 85.3931 338.458 85.8537 338.458 86.3164C338.458 89.6629 341.617 92.3761 345.514 92.3761C349.411 92.3761 352.571 89.6688 352.571 86.3164C352.57 85.8537 352.509 85.3931 352.388 84.9463C351.665 87.8631 348.863 90.0313 345.514 90.0313Z"
          fill="#596175"
        />
        <path
          opacity="0.2"
          d="M261.333 149.624C260.912 149.629 260.489 149.632 260.063 149.632C228.745 149.632 211.441 131.949 202.884 118.702C203.745 121.21 204.831 123.636 206.13 125.95C212.621 138.557 228.435 160.155 260.492 160.155C260.877 160.155 261.259 160.152 261.64 160.147C287.966 160.147 320.766 155.137 352.132 103.552C320.332 145.26 287.861 149.624 261.333 149.624Z"
          fill="#373D4D"
        />
        <path
          opacity="0.2"
          d="M274.906 159.577L280.02 141.332L279.997 141.325C296.393 127.025 295.727 107.072 295.727 107.072L291.738 104.087C291.738 104.087 292.446 125.283 274.452 139.635L235.932 127.925C248.337 116.272 250.135 101.441 250.381 96.4971L245.853 98.4193C244.673 105.544 241.122 117.102 230.47 126.27L228.228 125.586L220.036 144.582C229.042 153.034 242.091 160.154 260.498 160.154C260.882 160.154 261.265 160.151 261.646 160.146C265.903 160.148 270.337 160.019 274.906 159.577Z"
          fill="#373D4D"
        />
        <path
          d="M288.991 104.712C288.991 104.712 289.757 127.614 269.486 141.93L273.474 144.916C293.745 130.601 292.979 107.697 292.979 107.697L288.991 104.712Z"
          fill="#BA8758"
        />
        <path
          d="M243.68 92.4421C243.68 92.4421 244.446 115.346 224.175 129.662L228.163 132.648C248.434 118.331 247.668 95.4284 247.668 95.4284L243.68 92.4421Z"
          fill="#BA8758"
        />
        <path
          d="M259.34 186.212L265.588 183.638L277.272 141.958L225.474 126.21L259.34 186.212Z"
          fill="#9B7249"
        />
        <path
          d="M225.474 126.21L275.841 143.684L259.34 186.212L210.007 162.08L225.474 126.21Z"
          fill="#BA8758"
        />
        <path
          d="M277.272 141.958L263.394 158.709L223.075 141.145L225.474 126.21L277.272 141.958Z"
          fill="#C69B73"
        />
        <path
          d="M227.346 146.371C227.188 146.775 226.875 147.1 226.477 147.275C226.078 147.449 225.627 147.459 225.222 147.301C224.817 147.143 224.491 146.831 224.317 146.434C224.142 146.037 224.133 145.587 224.291 145.183L226.887 138.552C227.046 138.148 227.358 137.823 227.756 137.649C228.155 137.475 228.606 137.465 229.011 137.622C229.416 137.78 229.742 138.092 229.917 138.489C230.091 138.887 230.1 139.337 229.942 139.741L227.346 146.371Z"
          fill="#E5D1BD"
        />
        <path
          d="M260.786 160.123C260.627 160.528 260.315 160.852 259.916 161.027C259.518 161.201 259.067 161.21 258.661 161.052C258.256 160.894 257.931 160.582 257.756 160.185C257.581 159.788 257.572 159.338 257.729 158.933L260.325 152.302C260.484 151.898 260.797 151.573 261.195 151.399C261.593 151.225 262.044 151.215 262.45 151.372C262.855 151.53 263.18 151.842 263.355 152.239C263.529 152.636 263.539 153.086 263.381 153.491L260.786 160.123Z"
          fill="#E5D1BD"
        />
        <path
          d="M299.502 106.254C300.244 100.486 296.619 94.8962 290.576 91.2826C290.569 91.2787 290.561 91.2744 290.554 91.2696L204.42 38.9575C204.42 38.9575 179.763 89.3368 220.769 113.185C239.391 124.014 275.664 120.237 282.392 119.423C291.477 118.431 298.593 113.281 299.502 106.254Z"
          fill="#A7B3D7"
        />
        <path
          d="M296.752 96.742C295.218 94.6633 293.105 92.7987 290.571 91.2827C290.564 91.2788 290.556 91.2744 290.549 91.2697L273.362 80.8313L296.752 96.742Z"
          fill="#B7C0DE"
        />
        <path
          d="M213.789 44.6488L204.41 38.9529C204.41 38.9529 182.619 83.4774 214.087 108.627C200.831 85.1382 210.073 54.7411 213.789 44.6488Z"
          fill="#C4CBE4"
        />
        <path
          d="M290.571 91.2826C290.564 91.2787 290.556 91.2743 290.549 91.2696L204.415 38.9575C204.415 38.9575 204.015 39.7732 203.404 41.2458L276.252 96.736L276.272 96.7525C281.937 101.01 285.006 107.126 283.7 113.055C283.14 115.598 281.833 117.836 279.978 119.681C280.981 119.576 281.792 119.482 282.377 119.411C291.465 118.426 298.589 113.276 299.49 106.249C300.239 100.491 296.614 94.8962 290.571 91.2826Z"
          fill="#6D778E"
        />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
            <path
              d="M302.452 86.6626C301.022 96.9574 303.618 105.225 307.871 113.247C308.494 114.424 310.531 114.696 312.755 114.352C310.484 112.054 305.813 104.818 305.519 84.1083L299.759 80.0803C299.096 81.0526 298.506 81.9342 297.93 82.7276L297.971 82.7606H297.977C298.178 82.9136 298.381 83.0646 298.586 83.2137L298.729 83.3173C298.932 83.4648 299.135 83.6092 299.338 83.7505C300.4 84.4932 301.488 85.1712 302.576 85.7915C302.526 86.0882 302.485 86.3766 302.452 86.6626Z"
              fill="#394050"
            />
          </g>
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
            <path
              d="M354.676 77.4389C354.192 76.6333 353.66 75.8567 353.084 75.113C348.128 77.3495 338.794 80.5794 324.469 81.7188C324.469 81.7188 322.699 86.1918 326.357 88.7519C326.729 87.8279 327.056 87.0616 327.246 86.7109C328.432 84.5227 329.315 83.9871 331.557 83.6222C344.531 81.507 351.972 78.6325 354.676 77.4389Z"
              fill="#394050"
            />
          </g>
        </g>
        <path
          d="M326.233 89.9055C326.233 89.9055 322.171 94.3855 320.425 109.904C319.998 113.699 310.365 116.072 308.961 113.153C304.956 104.827 302.617 96.2759 304.427 85.6091C304.687 84.0789 306.537 75.3779 314.804 75.5698C323.071 75.7616 326.233 89.9055 326.233 89.9055Z"
          fill="#896432"
        />
        <path
          d="M314.804 75.5708C306.543 75.3789 304.687 84.074 304.427 85.6101C304.33 86.1775 304.247 86.739 304.175 87.2945C311.759 92.1206 319.721 93.6944 323.412 94.0875C323.591 94.1063 323.779 94.1193 323.97 94.1228C325.21 91.0353 326.235 89.9053 326.235 89.9053C326.235 89.9053 323.071 75.7614 314.804 75.5708Z"
          fill="#735427"
        />
        <path
          d="M298.855 81.3987C298.855 81.3987 305.957 58.2466 328.477 58.2466C346.415 58.2466 353.968 76.3302 353.968 76.3302C353.968 76.3302 349.248 79.6601 333.247 81.9672C331.107 82.2756 330.257 82.7912 329.076 84.9464C328.639 85.748 327.453 88.7637 326.655 90.8189C326.098 92.2526 324.56 92.3656 323.409 92.2432C318.839 91.7559 307.7 89.4547 298.855 81.3987Z"
          fill="#9F743C"
        />
        <path
          d="M330.92 60.6231C338.184 60.6231 343.745 63.5894 347.796 67.1183C343.666 62.6548 337.338 58.2466 328.482 58.2466C305.962 58.2466 298.86 81.3987 298.86 81.3987C299.686 82.1512 300.53 82.8544 301.391 83.508C302.26 80.9361 309.827 60.6231 330.92 60.6231Z"
          fill="#BA8748"
        />
        <path
          d="M334.406 69.1286C316.634 73.9429 302.846 73.1695 302.846 73.1695C302.846 73.1695 299.052 73.7416 300.014 78.4358C300.014 78.4358 318.638 82.492 339.269 75.3848C339.269 75.3848 340.958 72.8764 339.901 71.0896C338.843 69.3028 337.318 68.3399 334.406 69.1286Z"
          fill="#603913"
        />
        <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
          <path
            d="M339.123 60.623C333.667 61.5082 329.585 65.0654 329.585 69.3182C329.585 74.2372 335.044 78.2252 341.777 78.2252C346.426 78.2252 350.465 76.3254 352.522 73.5298C350.461 69.9868 346.13 63.9507 339.123 60.623Z"
            fill="#8E6834"
          />
        </g>
        <path
          d="M353.969 68.3825C353.969 63.8036 348.741 60.0911 342.293 60.0911C335.844 60.0911 330.616 63.8036 330.616 68.3825C330.616 72.9613 335.85 76.6809 342.293 76.6809C348.735 76.6809 353.969 72.9625 353.969 68.3825Z"
          fill="#603913"
        />
        <path
          d="M351.102 68.3825C351.102 64.9277 347.158 62.1274 342.293 62.1274C337.428 62.1274 333.49 64.9277 333.49 68.3825C333.49 71.8372 337.434 74.6387 342.299 74.6387C347.163 74.6387 351.102 71.8384 351.102 68.3825Z"
          fill="#00B7F1"
        />
        <path
          opacity="0.5"
          d="M345.47 62.5488L334.739 71.6006C334.995 71.9013 335.276 72.1794 335.58 72.4317L346.606 62.929C346.235 62.7809 345.856 62.6539 345.47 62.5488Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M339.566 74.3267L349.907 65.2337C349.257 64.463 348.46 63.8278 347.563 63.3645L336.477 73.0743C337.43 73.6591 338.474 74.0823 339.566 74.3267Z"
          fill="white"
        />
        <path
          d="M141.243 119.2C141.243 119.761 141.019 120.3 140.62 120.698C140.222 121.095 139.682 121.318 139.118 121.318H121.446C120.882 121.318 120.342 121.095 119.943 120.698C119.545 120.3 119.321 119.761 119.321 119.2C119.321 118.638 119.545 118.099 119.943 117.701C120.342 117.304 120.882 117.081 121.446 117.081H139.118C139.682 117.081 140.222 117.304 140.62 117.701C141.019 118.099 141.243 118.638 141.243 119.2Z"
          fill="#596175"
        />
        <path
          d="M100.826 119.2C100.826 119.761 101.05 120.3 101.448 120.698C101.847 121.095 102.387 121.318 102.95 121.318H112.019C112.581 121.317 113.12 121.093 113.517 120.696C113.914 120.299 114.137 119.76 114.137 119.2C114.137 118.639 113.914 118.101 113.517 117.703C113.12 117.306 112.581 117.082 112.019 117.081H102.945C102.381 117.081 101.841 117.304 101.443 117.701C101.044 118.099 100.82 118.638 100.82 119.2H100.826Z"
          fill="#596175"
        />
        <path
          d="M134.375 133.082C134.376 133.643 134.601 134.18 134.999 134.577C135.397 134.973 135.936 135.195 136.499 135.195H164.902C165.463 135.193 166.001 134.97 166.398 134.574C166.795 134.179 167.018 133.642 167.02 133.082C167.02 132.521 166.797 131.983 166.4 131.586C166.003 131.189 165.464 130.965 164.902 130.963H136.493C135.93 130.963 135.389 131.187 134.991 131.584C134.592 131.981 134.369 132.52 134.369 133.082H134.375Z"
          fill="#6D778E"
        />
        <path
          d="M71.6385 133.082C71.6401 133.643 71.8646 134.18 72.2628 134.577C72.661 134.973 73.2004 135.195 73.7628 135.195H105.179C105.741 135.195 106.28 134.973 106.679 134.577C107.077 134.18 107.301 133.643 107.303 133.082C107.303 132.52 107.079 131.981 106.681 131.584C106.282 131.187 105.742 130.963 105.179 130.963H73.7581C73.1947 130.963 72.6544 131.187 72.256 131.584C71.8576 131.981 71.6338 132.52 71.6338 133.082H71.6385Z"
          fill="#6D778E"
        />
        <path
          d="M128.754 133.082C128.753 133.643 128.528 134.18 128.13 134.577C127.732 134.973 127.192 135.195 126.63 135.195H114.74C114.178 135.195 113.638 134.973 113.24 134.577C112.842 134.18 112.617 133.643 112.616 133.082C112.616 132.52 112.84 131.981 113.238 131.584C113.636 131.187 114.177 130.963 114.74 130.963H126.636C127.199 130.963 127.74 131.187 128.138 131.584C128.536 131.981 128.76 132.52 128.76 133.082H128.754Z"
          fill="#6D778E"
        />
        <path
          d="M176.558 161.123C176.56 161.684 176.784 162.221 177.182 162.617C177.58 163.013 178.12 163.236 178.682 163.236H218.274C218.836 163.236 219.376 163.013 219.774 162.617C220.172 162.221 220.397 161.684 220.398 161.123C220.398 160.561 220.175 160.022 219.776 159.624C219.378 159.227 218.837 159.004 218.274 159.004H178.678C178.114 159.004 177.574 159.227 177.175 159.624C176.777 160.022 176.553 160.561 176.553 161.123H176.558Z"
          fill="#BA8758"
        />
        <path
          d="M152.255 161.123C152.257 161.684 152.481 162.221 152.879 162.617C153.278 163.013 153.817 163.236 154.379 163.236H165.908C166.471 163.236 167.01 163.013 167.408 162.617C167.807 162.221 168.031 161.684 168.033 161.123C168.033 160.561 167.809 160.022 167.41 159.624C167.012 159.227 166.472 159.004 165.908 159.004H154.378C153.815 159.004 153.274 159.227 152.876 159.624C152.478 160.022 152.254 160.561 152.254 161.123H152.255Z"
          fill="#BA8758"
        />
        <path
          d="M265.867 177.975C265.867 178.536 265.644 179.074 265.247 179.471C264.85 179.868 264.311 180.092 263.749 180.094H232.9C232.336 180.094 231.796 179.871 231.398 179.473C230.999 179.076 230.775 178.537 230.775 177.975C230.775 177.413 230.999 176.874 231.398 176.477C231.796 176.08 232.336 175.856 232.9 175.856H263.743C264.305 175.858 264.844 176.082 265.241 176.479C265.638 176.876 265.861 177.414 265.861 177.975H265.867Z"
          fill="#9B7249"
        />
        <path
          d="M178.752 177.975C178.752 178.537 178.976 179.076 179.374 179.473C179.773 179.871 180.313 180.094 180.876 180.094H200.12C200.683 180.094 201.224 179.871 201.622 179.473C202.02 179.076 202.244 178.537 202.244 177.975C202.244 177.413 202.02 176.874 201.622 176.477C201.224 176.08 200.683 175.856 200.12 175.856H180.87C180.307 175.856 179.767 176.08 179.368 176.477C178.97 176.874 178.746 177.413 178.746 177.975H178.752Z"
          fill="#9B7249"
        />
        <path
          d="M224.789 177.975C224.789 178.537 224.566 179.076 224.167 179.473C223.769 179.871 223.229 180.094 222.665 180.094H210.722C210.159 180.094 209.618 179.871 209.22 179.473C208.821 179.076 208.598 178.537 208.598 177.975C208.598 177.413 208.821 176.874 209.22 176.477C209.618 176.08 210.159 175.856 210.722 175.856H222.673C223.237 175.856 223.777 176.08 224.175 176.477C224.574 176.874 224.798 177.413 224.798 177.975H224.789Z"
          fill="#9B7249"
        />
        <path
          d="M206.174 48.3273C206.174 48.8892 205.95 49.4281 205.551 49.8254C205.153 50.2228 204.613 50.446 204.049 50.446H191.934C191.37 50.446 190.83 50.2228 190.432 49.8254C190.033 49.4281 189.81 48.8892 189.81 48.3273C189.81 47.7653 190.033 47.2264 190.432 46.8291C190.83 46.4317 191.37 46.2085 191.934 46.2085H204.055C204.619 46.2085 205.159 46.4317 205.557 46.8291C205.956 47.2264 206.179 47.7653 206.179 48.3273H206.174Z"
          fill="#C4CBE4"
        />
        <path
          d="M162.419 48.3273C162.418 48.6058 162.472 48.8817 162.578 49.1392C162.685 49.3967 162.841 49.6306 163.039 49.8275C163.236 50.0245 163.471 50.1805 163.729 50.2866C163.987 50.3928 164.264 50.4469 164.543 50.446H180.74C181.303 50.4445 181.842 50.2205 182.239 49.8234C182.636 49.4262 182.859 48.8882 182.859 48.3273C182.859 47.7663 182.636 47.2283 182.239 46.8311C181.842 46.434 181.303 46.2101 180.74 46.2085H164.537C163.973 46.2085 163.433 46.4317 163.035 46.8291C162.636 47.2264 162.413 47.7653 162.413 48.3273H162.419Z"
          fill="#C4CBE4"
        />
        <path
          d="M126.673 48.3273C126.673 48.8892 126.897 49.4281 127.295 49.8254C127.693 50.2228 128.234 50.446 128.797 50.446H155.026C155.59 50.446 156.13 50.2228 156.528 49.8254C156.927 49.4281 157.15 48.8892 157.15 48.3273C157.15 47.7653 156.927 47.2264 156.528 46.8291C156.13 46.4317 155.59 46.2085 155.026 46.2085H128.791C128.228 46.2085 127.688 46.4317 127.289 46.8291C126.891 47.2264 126.667 47.7653 126.667 48.3273H126.673Z"
          fill="#C4CBE4"
        />
        <path
          d="M210.361 72.9906C210.361 73.5525 210.137 74.0914 209.739 74.4888C209.34 74.8861 208.8 75.1094 208.237 75.1094H191.407C190.844 75.1094 190.304 74.8861 189.905 74.4888C189.507 74.0914 189.283 73.5525 189.283 72.9906C189.283 72.4287 189.507 71.8898 189.905 71.4924C190.304 71.0951 190.844 70.8718 191.407 70.8718H208.237C208.516 70.8709 208.792 70.9251 209.051 71.0312C209.309 71.1374 209.543 71.2934 209.741 71.4903C209.938 71.6873 210.095 71.9212 210.201 72.1787C210.307 72.4362 210.362 72.7121 210.361 72.9906Z"
          fill="#A7B3D7"
        />
        <path
          d="M156.133 72.9906C156.133 73.5515 156.356 74.0895 156.753 74.4867C157.15 74.8839 157.689 75.1078 158.251 75.1094H181.625C182.189 75.1094 182.729 74.8861 183.127 74.4888C183.526 74.0914 183.75 73.5525 183.75 72.9906C183.751 72.7121 183.696 72.4362 183.59 72.1787C183.483 71.9212 183.327 71.6873 183.13 71.4903C182.932 71.2934 182.698 71.1374 182.439 71.0312C182.181 70.9251 181.905 70.8709 181.625 70.8718H158.251C157.689 70.8734 157.15 71.0973 156.753 71.4945C156.356 71.8917 156.133 72.4297 156.133 72.9906Z"
          fill="#A7B3D7"
        />
        <path
          d="M134.271 72.9906C134.271 73.5525 134.495 74.0914 134.894 74.4888C135.292 74.8861 135.832 75.1094 136.396 75.1094H147.427C147.99 75.1094 148.53 74.8861 148.929 74.4888C149.327 74.0914 149.551 73.5525 149.551 72.9906C149.552 72.7121 149.498 72.4362 149.391 72.1787C149.285 71.9212 149.128 71.6873 148.931 71.4903C148.733 71.2934 148.499 71.1374 148.241 71.0312C147.983 70.9251 147.706 70.8709 147.427 70.8718H136.396C135.832 70.8718 135.292 71.0951 134.894 71.4924C134.495 71.8898 134.271 72.4287 134.271 72.9906Z"
          fill="#A7B3D7"
        />
        <path
          d="M279.775 95.0116C279.775 95.5735 279.551 96.1124 279.153 96.5098C278.755 96.9071 278.214 97.1303 277.651 97.1303H246.809C246.245 97.1303 245.705 96.9071 245.307 96.5098C244.908 96.1124 244.685 95.5735 244.685 95.0116C244.685 94.4496 244.908 93.9107 245.307 93.5134C245.705 93.116 246.245 92.8928 246.809 92.8928H277.656C278.219 92.8928 278.759 93.116 279.158 93.5134C279.556 93.9107 279.78 94.4496 279.78 95.0116H279.775Z"
          fill="#6D778E"
        />
        <path
          d="M239.416 95.0116C239.416 95.5735 239.193 96.1124 238.794 96.5098C238.396 96.9071 237.856 97.1303 237.292 97.1303H206.45C205.887 97.1303 205.346 96.9071 204.948 96.5098C204.549 96.1124 204.326 95.5735 204.326 95.0116C204.326 94.4496 204.549 93.9107 204.948 93.5134C205.346 93.116 205.887 92.8928 206.45 92.8928H237.297C237.86 92.8928 238.401 93.116 238.799 93.5134C239.197 93.9107 239.421 94.4496 239.421 95.0116H239.416Z"
          fill="#6D778E"
        />
        <path
          d="M180.34 95.0128C180.34 95.5747 180.564 96.1136 180.962 96.511C181.361 96.9083 181.901 97.1316 182.464 97.1316H196.521C197.085 97.1316 197.625 96.9083 198.023 96.511C198.422 96.1136 198.646 95.5747 198.646 95.0128C198.646 94.4509 198.422 93.912 198.023 93.5146C197.625 93.1173 197.085 92.894 196.521 92.894H182.46C181.896 92.894 181.356 93.1173 180.958 93.5146C180.559 93.912 180.335 94.4509 180.335 95.0128H180.34Z"
          fill="#6D778E"
        />
      </g>
      <defs>
        <clipPath id="clip0_3129_122696">
          <rect width="514" height="218" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MailBird;
