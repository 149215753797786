import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Col, notification, Row, Slider } from 'antd';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import SearchLoader from '../../../form/search/search_loader/SearchLoader';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
} from '../../../redux/selectors';
import { selectListContainerSize } from '../../../redux/selectors';
import { postSuburbRelatedArticles } from '../redux/async_thunks';
import {
  getRelatedArticlesByHashTag,
  selectArticlesDistance,
  selectHashTags,
} from '../redux/selectors';
import { resetRelatedArticles } from '../redux/slice';
import ArticleCard from './article_card/ArticleCard';

type Props = {
  setAreDetailsLoading?: any;
  areDetailsLoading?: boolean;
};

const RelatedArticles = ({
  setAreDetailsLoading,
  areDetailsLoading,
}: Props) => {
  const dispatch = useAppDispatch();

  const listContainerSize = useAppSelector(selectListContainerSize);

  const USER = useAppSelector(selectUser);
  const SUBURB = useAppSelector(selectActiveSuburb);
  const CLIENT_IP = useAppSelector(selectClientIp);
  const SSC_CODE = useAppSelector(selectActiveSuburbSscCode);

  let hashTags: any = useAppSelector(selectHashTags);
  const articlesDistanceValues = useAppSelector(selectArticlesDistance);
  const defaultHashTagKey = '#All';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [kmRadius, setKmRadius] = useState<number>(articlesDistanceValues.max);

  const pageInitialState = {
    pageIndex: 1,
    authCode: null as any,
  };

  const [page, setPage] = useState(pageInitialState);

  const [selectedHashTagKey, setSelectedHashTagKey] =
    useState<any>(defaultHashTagKey);

  const relatedArticles: any = useAppSelector(
    getRelatedArticlesByHashTag(
      hashTags.filter((h: any) => {
        return h.key === selectedHashTagKey;
      })[0].value,
    ),
  );

  const errorHandler = () => {
    notification.error({
      message: 'Error: Related articles tab',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchRelatedArticlesDetails = async (hashTagValue: any) => {
    toggleLoader(true);

    try {
      await dispatch(
        postSuburbRelatedArticles({
          data: {
            userId: USER.userId,
            sscCode: SSC_CODE,
            hashTag: hashTagValue,
            pageIndex: page.pageIndex,
            authCode: page.authCode,
            userAgent: navigator.userAgent,
            kmRadius: kmRadius,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      toggleLoader(false);
    } catch (error) {
      errorHandler();
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  const readyFetch = () => {
    if (USER && hashTags && SUBURB && selectedHashTagKey) {
      dispatch(resetRelatedArticles());

      const hashTag = hashTags.filter((h: any) => {
        return h.key === selectedHashTagKey;
      })[0];

      fetchRelatedArticlesDetails(hashTag.value);
    }
  };

  useEffect(() => {
    if (!isFirstLoad && USER && SUBURB) {
      setPage(pageInitialState);
    }
  }, [SUBURB, selectedHashTagKey]);

  useEffect(() => {
    if (isFirstLoad) {
      setPage(pageInitialState);
    }
  }, []);

  useEffect(() => {
    readyFetch();
  }, [page]);

  if (!!hashTags && hashTags.length) {
    hashTags = [...hashTags].sort((a: any, b: any) => {
      if (a.value < b.value) {
        return -1;
      }

      if (a.value > b.value) {
        return 1;
      }

      return 0;
    });
  }

  const hasArticles =
    !!relatedArticles &&
    !!relatedArticles.articles &&
    !!relatedArticles.articles.length;

  return (
    <>
      {isLoading && <SearchLoader />}
      <Row gutter={[0, 50]}>
        <Col span={24}>
          <Row gutter={[0, 20]}>
            <Col span={24} className="l-controls">
              <ul className="l-hashtag-options">
                {hashTags.map((h: any, i: any) => {
                  return (
                    <li
                      className={`l-hashtag-options__option${
                        h.key === selectedHashTagKey
                          ? ' l-hashtag-options__option--active'
                          : ''
                      }`}
                      key={i}
                      data-key={h.key}
                      onClick={(e) => {
                        setSelectedHashTagKey(e.currentTarget.dataset.key);
                      }}
                    >
                      {h.key}
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Row gutter={[0, 0]} align="middle" justify="space-between">
                <Col span={24} className="l-controls">
                  <div className="l-slider">
                    <span
                      className="l-slider__label"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Distance {listContainerSize === 'wide' && 'Radius'}
                    </span>
                    <span className="l-slider__wrapper">
                      <span className="l-slider__mark-label">
                        {articlesDistanceValues.min} km
                      </span>
                      <Slider
                        step={articlesDistanceValues.interval}
                        style={{ maxWidth: '250px', width: '100%' }}
                        tooltip={{
                          formatter: (value: any) => {
                            if (value) {
                              return <span>{value} km</span>;
                            }

                            return null;
                          },
                        }}
                        min={articlesDistanceValues.min}
                        max={articlesDistanceValues.max}
                        value={kmRadius}
                        onChange={(value: any) => {
                          setKmRadius(value);
                        }}
                        onAfterChange={() => {
                          setPage(pageInitialState);
                        }}
                      />
                      <span className="l-slider__mark-label">
                        {articlesDistanceValues.max} km
                      </span>
                    </span>
                    {(listContainerSize === 'default' ||
                      listContainerSize === 'wide') && (
                      <span className="l-slider__active-label">
                        {kmRadius} km
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                {hasArticles && (
                  <Col span={12}>
                    {page.pageIndex > 1 && (
                      <div
                        className="c-article-nav"
                        onClick={() => {
                          setPage({
                            pageIndex: page.pageIndex - 1,
                            authCode: relatedArticles.authCode,
                          });
                        }}
                      >
                        {/* <i className="fas fa-arrow-left"></i> */}
                        <ArrowLeftOutlined />
                        <span>Prev</span>
                      </div>
                    )}
                  </Col>
                )}
                {hasArticles && (
                  <Col span={12}>
                    <div
                      className="c-article-nav c-article-nav--right"
                      onClick={() => {
                        setPage({
                          pageIndex: page.pageIndex + 1,
                          authCode: relatedArticles.authCode,
                        });
                      }}
                    >
                      <span>Next</span>
                      {/* <i className="fas fa-arrow-right"></i> */}
                      <ArrowRightOutlined />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            {selectedHashTagKey && (
              <Col span={24}>
                <ArticleCard
                  isLoading={isLoading || areDetailsLoading}
                  hashTagKey={selectedHashTagKey}
                  hashTagValue={
                    hashTags.filter((h: any) => {
                      return selectedHashTagKey === h.key;
                    })[0].value
                  }
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RelatedArticles;
