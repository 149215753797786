import { Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { resetSiderMobileContentValues } from '../../layout/redux/slice';
import InviteForm from './InviteForm';

type Props = {
  isVisible: boolean;
  setInviteViewVisibility?: (v: boolean) => void;
  handleClose?: () => void;
};

const Invite = ({ isVisible, setInviteViewVisibility }: Props) => {
  const dispatch = useDispatch();

  const [isMobileView, isDesktopView] = useBreakpoint();

  const closeModal = () => {
    if (setInviteViewVisibility) {
      setInviteViewVisibility(false);
    }
  };

  const handleInviteFormClose = () => {
    if (isDesktopView) {
      closeModal();
    } else {
      dispatch(resetSiderMobileContentValues(undefined));
      closeModal();
    }
  };

  const modalOpts = {
    title: 'Invite',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null as any,
    maskClosable: false,
    centered: true,
  };

  return (
    <>
      {isVisible && isDesktopView && (
        <Modal {...modalOpts}>
          <InviteForm handleClose={handleInviteFormClose} />
        </Modal>
      )}
      {isVisible && isMobileView && (
        <InviteForm handleClose={handleInviteFormClose} />
      )}
    </>
  );
};

export default Invite;
