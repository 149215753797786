import { Popover } from 'antd';
import React, { useState } from 'react';

import StarIcon from '../../../foundation/assets/svgs/StarIcon';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch } from '../../../store/hooks';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import Favorite from './Favorite';

const FavHeader = () => {
  const dispatch = useAppDispatch();

  const [isMobileView, isDesktopView] = useBreakpoint();
  const [isFavoritePopoverVisible, setFavoritePopoverVisibility] =
    useState(false);

  const handlePopoverVisibility = (v: any) => {
    const visibility = v ? v : !isFavoritePopoverVisible;
    if (isDesktopView) {
      setFavoritePopoverVisibility(visibility);
    } else {
      const data = {
        isVisible: true,
        view: 'favorites',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Favorites',
        parentConfig: undefined as any,
      };
      dispatch(setSiderMobileContentValues(data));
    }
  };

  return (
    <>
      {isDesktopView && (
        <Popover
          content={
            <Favorite handlePopoverVisibility={handlePopoverVisibility} />
          }
          className="c-layout-header__fav-popover "
          arrowPointAtCenter
          overlayInnerStyle={{
            borderRadius: 10,
            boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
            border: '1px solid #D9DBE9',
            width: 370,
          }}
          showArrow={false}
          open={isFavoritePopoverVisible}
          trigger="click"
          onOpenChange={handlePopoverVisibility}
        >
          <div
            className="c-layout-header__favourites-trigger"
            onClick={handlePopoverVisibility}
          >
            <StarIcon
              style={{ height: '24px', width: '24px' }}
              strokeColor={isMobileView ? 'white' : ''}
            />
          </div>
        </Popover>
      )}
      {isMobileView && (
        <div
          className="c-layout-header__favourites-trigger"
          onClick={handlePopoverVisibility}
        >
          <StarIcon
            style={{ height: '24px', width: '24px' }}
            strokeColor={isMobileView ? 'white' : ''}
          />
        </div>
      )}
    </>
  );
};

export default FavHeader;
