import { Skeleton } from 'antd';
import React from 'react';

const ConversationListItemSkeleton = () => {
  const ListItem = () => (
    <div className="l-chat-panel__list-item">
      <div>
        <Skeleton.Avatar size="small" style={{ marginRight: 10 }} active />
        <Skeleton.Input active />
      </div>
    </div>
  );
  return (
    <>
      <ListItem />
      <ListItem />
      <ListItem />
    </>
  );
};

export default ConversationListItemSkeleton;
