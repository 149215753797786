import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Logo from '../../../foundation/assets/svgs/Logo';
import Map from '../../../foundation/assets/svgs/Map';
import FloatLabel from '../../../foundation/components/float_label/FloatLabel';
import { useAppDispatch } from '../../../store/hooks';
import { deleteProfile, getClientIP } from '../redux/async_thunks';

const DeleteAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadingIcon = <Loading3QuartersOutlined spin />;
  const loadingTip = 'Loading...';

  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const { deleteCode } = useParams<{ deleteCode: string }>();

  const [form] = Form.useForm();
  const otp = Form.useWatch('otp', form);

  const errorHandler = () => {
    setFormErrorMessage('Something went wrong with the request');
  };

  const proceedAccountDelete = async (values: any) => {
    let timeStamp = 0;
    let clientIp = '';
    let isRequestFailed = false;

    setIsFormLoading(true);
    setFormErrorMessage('');

    try {
      const response = await dispatch(getClientIP())
        // @ts-ignore
        .unwrap();

      timeStamp = response.timeStamp;
      clientIp = response.ip;
    } catch (error: any) {
      errorHandler();
      isRequestFailed = true;
    }

    if (isRequestFailed) {
      setIsFormLoading(false);
      return false;
    }

    try {
      await dispatch(
        deleteProfile({
          data: {
            otp: values.otp,
            deleteCode: deleteCode,
          },
          options: {
            clientIp: clientIp,
            timeStamp: timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      navigate('/deleted');
    } catch (error: any) {
      if (error.message) {
        setFormErrorMessage(error.message);
      } else {
        setFormErrorMessage('Something went wrong with the request.');
      }
    }

    setIsFormLoading(false);
  };

  return (
    <main className="l-common-form">
      <div className="l-common-form__body">
        <div className="l-common-form__fields">
          <div className="l-common-form__heading">
            <Logo />
            <h3>Delete account</h3>
          </div>
          <Spin
            tip={loadingTip}
            spinning={isFormLoading}
            indicator={loadingIcon}
          >
            <p style={{ textAlign: 'center' }}>
              Please note that this action is permanent.
              <br />
              Your account will no longer be available & cannot be restored once
              you proceed.
            </p>
            <br />
            {formErrorMessage && (
              <p className="l-common-form__alert l-common-form__alert--error">
                {formErrorMessage}
              </p>
            )}
            <Form onFinish={proceedAccountDelete} form={form}>
              <FloatLabel label="Otp" value={otp}>
                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'OTP is required',
                    },
                  ]}
                >
                  <Input className="l-common-form__input" />
                </Form.Item>
              </FloatLabel>
              <Form.Item>
                <Button
                  className="l-common-form__button"
                  type="primary"
                  htmlType="submit"
                >
                  Proceed delete
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
      <div className="l-common-form__hero">
        <Map />
      </div>
    </main>
  );
};

export default DeleteAccount;
