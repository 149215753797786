import { createAsyncThunk } from '@reduxjs/toolkit';

import { endpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const postAiSuburbReportSuggestions = createAsyncThunk(
  'aiReport/suggestions',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      locationType: 's', // Return suburbs only
      userId: apiParams.data.userId,
      searchKeyword: apiParams.data.searchKeyword,
      selectedValues: apiParams.data.selectedValues,
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Search/location-suggestion`,
        encryptData(data),
        {
          headers: {
            Authorization: `Bearer ${apiParams.options.token}`,
            clientid: clientId,
            sessionid: apiParams.options.sessionId,
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        if (response?.data) {
          result = decryptData(response.data).searchSuggestions;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postAiSuburbReport = createAsyncThunk(
  'aiReport/results',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);
    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Ai/build-report`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 204) {
          result = undefined;
        } else {
          if (response?.data) {
            const data = decryptData(response.data);

            result = {
              investmentSummary: data.investmentSummary,
              economyAndPopulation: data.economyAndPopulation,
              priceAndRentForecasts: data.priceAndRentForecasts,
              keyProjects: data.projects || data.keyProjects,
              dwelling: data.dwelling,
              keyMarketIndicators:
                data.marketKeyIndicators || data.keyMarketIndicators,
              propertyType: data.propertyType,
              suburb: data.suburb,
            };
          }
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
