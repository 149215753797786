import React from 'react';
import { useSelector } from 'react-redux';

import SuburbHomeIcon from '../../../foundation/assets/svgs/SuburbHomeIcon';
import { useAppDispatch } from '../../../store/hooks';
import { resetSiderMobileContentValues } from '../../layout/redux/slice';
import {
  resetActiveSuburb,
  resetGpsPoints,
  resetResults,
  setActivePropertyId,
  setIsFirstAttempt,
  setIsMapSearchEnabled,
  // setSearchType,
} from '../../search/redux/slice';
import { selectUserPropertyFavorites } from '../redux/selectors';
import FavListItem from './FavListItem';

const FavProperties = ({
  handleFavPropertyDelete,
  handlePopoverVisibility,
}: {
  handleFavPropertyDelete: any;
  handlePopoverVisibility?: (param: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const favoriteProperties = useSelector(selectUserPropertyFavorites);

  const handleFavoritePropertyView = (property: any) => {
    dispatch(resetActiveSuburb());
    dispatch(setActivePropertyId(property.propertyId));
    dispatch(setIsFirstAttempt(false));
    dispatch(resetResults());
    dispatch(resetGpsPoints());
    dispatch(setIsMapSearchEnabled(false));

    if (handlePopoverVisibility) {
      handlePopoverVisibility(false);
    } else {
      dispatch(resetSiderMobileContentValues(undefined));
    }
  };

  return (
    <div className="c-favorite__content">
      {favoriteProperties?.map((property) => (
        <FavListItem
          icon={<SuburbHomeIcon />}
          deleteListItem={() =>
            handleFavPropertyDelete({
              property: 'propertyId',
              // @ts-ignore
              id: property.propertyId,
              searchType: 'property',
            })
          }
          viewListItem={() => handleFavoritePropertyView(property)}
          // @ts-ignore
          key={property.propertyId}
          // @ts-ignore
          mainTitle={`${property.address}`}
          subTitle={'Saved property'}
        />
      ))}
      {(!favoriteProperties || !favoriteProperties.length) && (
        <p className="c-favorite__empty">
          <strong>No saved properties, yet.</strong>
          <span>Your favourite properties will appear here when saved.</span>
        </p>
      )}
    </div>
  );
};

export default FavProperties;
