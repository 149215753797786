import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectIsMapSearchEnabled,
  selectListContainerSize,
  selectMapSearchPropertyType,
  selectResults,
} from '../../../redux/selectors';
import { postSuburbDwelling } from '../redux/async_thunks';
import { selectDescriptors, selectDwelling } from '../redux/selectors';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const Dwelling = ({ setAreDetailsLoading }: Props) => {
  const dispatch = useAppDispatch();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const SSC_CODE = useAppSelector(selectActiveSuburbSscCode);

  const RESULTS = useAppSelector(selectResults);

  let PROPERTY_TYPE = RESULTS ? RESULTS.propertyType : 'Houses';

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  if (isMapSearchEnabled) {
    PROPERTY_TYPE = mapSearchPropertyType;
  }

  const user = useAppSelector(selectUser);
  const suburb = useAppSelector(selectActiveSuburb);

  const dwelling = useAppSelector(selectDwelling);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const descriptor = useAppSelector(selectDescriptors).dwelling;

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const listContainerSize = useAppSelector(selectListContainerSize);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Dwelling tab',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchDwellingDetails = async () => {
    toggleLoader(true);

    try {
      await dispatch(
        postSuburbDwelling({
          data: {
            userId: user.userId,
            sscCode: SSC_CODE,
            propertyType: PROPERTY_TYPE,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: user.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      toggleLoader(false);
    } catch (error) {
      errorHandler();
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      dwelling={dwelling}
      descriptor={descriptor}
      isFirstLoad={isFirstLoad}
      fetchDwellingDetails={fetchDwellingDetails}
      suburb={suburb}
      user={user}
      isLoading={isLoading}
      listContainerSize={listContainerSize}
    />
  );
};

export default Dwelling;
