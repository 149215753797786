import React from 'react';

function Projects() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-building"
    >
      <path
        d="M1.41699 4.16667L1.25985 3.43332L0.666992 3.56036V4.16667H1.41699ZM14.2503 21.5833V22.3333H14.2503L14.2503 21.5833ZM1.41699 21.5833H0.666992V22.3333H1.41699V21.5833ZM12.0329 1.89184L12.19 2.62519L12.0329 1.89184ZM6.00033 17.9167V17.1667H5.25033V17.9167H6.00033ZM9.66699 17.9167H10.417V17.1667H9.66699V17.9167ZM9.66699 21.5833V22.3333H10.417V21.5833H9.66699ZM6.00033 21.5833H5.25033V22.3333H6.00033V21.5833ZM14.2503 5.08333V4.33333C13.8361 4.33333 13.5003 4.66912 13.5003 5.08333H14.2503ZM21.5837 5.08333H22.3337C22.3337 4.66912 21.9979 4.33333 21.5837 4.33333V5.08333ZM21.5837 21.5833V22.3333C21.9979 22.3333 22.3337 21.9975 22.3337 21.5833H21.5837ZM1.57414 4.90002L12.19 2.62519L11.8757 1.15849L1.25985 3.43332L1.57414 4.90002ZM13.5003 3.68448V21.5833H15.0003V3.68448H13.5003ZM14.2503 20.8333H1.41699V22.3333H14.2503V20.8333ZM2.16699 21.5833V4.16667H0.666992V21.5833H2.16699ZM12.19 2.62519C12.8644 2.48068 13.5003 2.99478 13.5003 3.68448H15.0003C15.0003 2.03981 13.4839 0.813883 11.8757 1.15849L12.19 2.62519ZM6.00033 18.6667H9.66699V17.1667H6.00033V18.6667ZM8.91699 17.9167V21.5833H10.417V17.9167H8.91699ZM9.66699 20.8333H6.00033V22.3333H9.66699V20.8333ZM6.75033 21.5833V17.9167H5.25033V21.5833H6.75033ZM17.917 14.0833H21.5837V12.5833H17.917V14.0833ZM17.917 10.4167H21.5837V8.91667H17.917V10.4167ZM17.917 17.75H21.5837V16.25H17.917V17.75ZM14.2503 5.83333H21.5837V4.33333H14.2503V5.83333ZM20.8337 5.08333V21.5833H22.3337V5.08333H20.8337ZM21.5837 20.8333L14.2503 20.8333L14.2503 22.3333L21.5837 22.3333V20.8333ZM15.0003 21.5833V5.08333H13.5003V21.5833H15.0003ZM17.167 0.5V5.08333H18.667V0.5H17.167ZM4.16699 15H6.91699V13.5H4.16699V15ZM8.75033 15H11.5003V13.5H8.75033V15ZM8.75033 7.66667H11.5003V6.16667H8.75033V7.66667ZM4.16699 7.66667H6.91699V6.16667H4.16699V7.66667ZM4.16699 11.3333H6.91699V9.83333H4.16699V11.3333ZM8.75033 11.3333H11.5003V9.83333H8.75033V11.3333Z"
        fill="#A0A3BD"
      />
    </svg>
  );
}

export default Projects;
