import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedConversation } from '../redux/selectors';

const useReceiverInformation = () => {
  const selectedConversation = useSelector(selectSelectedConversation);

  /**
   * Check if the conversation is through direct messages and not from channels.
   */
  const isDMConversation = useMemo(
    () =>
      selectedConversation && selectedConversation.conversationType === 'user',
    [selectedConversation],
  );

  const receiverId = useMemo(
    () =>
      isDMConversation
        ? selectedConversation?.conversationWith?.uid
        : selectedConversation?.conversationWith?.guid,
    [selectedConversation, isDMConversation],
  );

  const receiverType = useMemo(
    () => selectedConversation?.conversationType,
    [selectedConversation],
  );

  return [receiverId, receiverType];
};

export default useReceiverInformation;
