import { CometChat } from '@cometchat-pro/chat';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectccUser, selectSelectedConversation } from '../redux/selectors';
import {
  attachMessageListeners,
  markMessageAsDelivered,
  removeMessageListeners,
} from '../utils/controllers/messageController';
import * as enums from '../utils/enums';
import useReceiverInformation from './useReceiverInformation';

const useReceiveNewMessage = () => {
  const messageListenerId = 'message_listener_1' + Date.now();

  const selectedConversation = useSelector(selectSelectedConversation);

  const ccUser = useSelector(selectccUser);

  const [receiverId, receiverType] = useReceiverInformation();

  const [message, setMessage] = useState<any>(undefined);
  const [messageType, setMessageType] = useState<any>(undefined);

  const onMessageReceived = (message: any) => {
    // mark the message as delivered
    markMessageAsDelivered(message, ccUser);

    setMessage(message);

    /**
     * message receiver is chat window group
     */
    if (
      receiverType === CometChat.RECEIVER_TYPE.GROUP &&
      message.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP &&
      message.getReceiverId() === receiverId
    ) {
      setMessageType(CometChat.RECEIVER_TYPE.GROUP);
    } else if (
      receiverType === CometChat.RECEIVER_TYPE.USER &&
      message.getReceiverType() === CometChat.RECEIVER_TYPE.USER
    ) {
      /**
       * If the message sender is chat window user and message receiver is logged-in user
       * OR
       * If the message sender is logged-in user and message receiver is chat window user
       */
      if (
        (message.getSender().uid === receiverId &&
          message.getReceiverId() === ccUser?.uid) ||
        (message.getSender().uid === ccUser?.uid &&
          message.getReceiverId() === receiverId)
      ) {
        setMessageType(CometChat.RECEIVER_TYPE.USER);
      }
    }
  };

  const messageListener = (key: string, message: any) => {
    switch (key) {
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
      case enums.CUSTOM_MESSAGE_RECEIVED:
        onMessageReceived(message);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedConversation && selectedConversation?.conversationId) {
      attachMessageListeners(messageListenerId, messageListener);
    }

    return () => {
      removeMessageListeners(messageListenerId);
    };
  }, [selectedConversation?.conversationId]);

  return [message, messageType];
};

export default useReceiveNewMessage;
