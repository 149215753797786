import { createAsyncThunk } from '@reduxjs/toolkit';

import { endpoint } from '../../../foundation/config/axios_instances';
// import messages from '../../../foundation/messages';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';
import { setOrganizationDetailsInProfile } from '../../user/redux/slice';

export type UpdateOrganizationProps = {
  userId: string | undefined;
  orgId: string;
  orgName?: string;
  members?: any;
};

export const updateOrganization = createAsyncThunk(
  'account/update-org',
  async (
    apiParams: {
      data: UpdateOrganizationProps;
      picture: any;
      options: any;
    },
    thunkAPI,
  ) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const data = {
        ...apiParams.data,
      };

      const encrypted = encryptData(data);

      const formData = new FormData();
      formData.append('e', encrypted);

      if (apiParams.picture) {
        formData.append('f', apiParams.picture || null);
      }

      /**
       * We should create this header by using a function because it is shared
       * among so many files.
       */
      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'content-type': 'multipart/form-data',
      };

      const response = await endpoint.put(
        `${env_constants.RHOMEO_API}/Org`,
        formData,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        result = decryptData(response.data);

        thunkAPI.dispatch(
          setOrganizationDetailsInProfile({
            orgId: result.organization.orgId,
            orgName: result.organization.orgName,
          }),
        );
      }

      return result;
    } catch (error: any) {
      const decrypted = decryptData(error.response.data);
      return Promise.reject(decrypted);
    }
  },
);

export type InviteTeamProps = {
  userId: string | undefined;
  invitees: {
    email: string;
    roleId: number;
    groupId?: string | null;
  }[];
};

export const inviteTeam = createAsyncThunk(
  'account/invite-team',
  async (apiParams: { data: InviteTeamProps; options: any }) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Org/invite`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result;
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        return Promise.reject(decryptData(error.response.data));
      }

      return Promise.reject();
    }
  },
);

export const getOrganizationDetails = createAsyncThunk(
  'account/get-org',
  async (apiParams: { options: any }) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.get(`${env_constants.RHOMEO_API}/Org`, {
        headers: headers,
      });

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);

export type UpdatePaymentMethodProps = {
  userId: string;
  paymentMethodId: string;
  customerId: string;
  subscriptionId: string;
};

export const updatePaymentMethod = createAsyncThunk(
  'account/update-payment-method',
  async (apiParams: { data: UpdatePaymentMethodProps; options: any }) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/payment-method`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result.paymentMethods;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);

export type CancelSubscriptionType = {
  userId: string | undefined;
  subscriptionId: string;
};

export const cancelSubscription = createAsyncThunk(
  'account/cancel-subscription',
  async (apiParams: { data: CancelSubscriptionType; options: any }) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.delete(
        `${env_constants.RHOMEO_API}/Subs/cancel`,
        {
          headers: headers,
          data: encrypted,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result.subscriptionPlanDetails;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);

type GetInvoiceType = {
  userId: string;
  subscriptionId: string;
  subscriptionPlanTypeId?: number;
  subscriptionFrequency?: string;
  additionalUsers?: number;
  promoCode?: string;
};

export const getInvoice = createAsyncThunk(
  'account/fetch-invoice',
  async (apiParams: { data: GetInvoiceType; options: any }, thunkAPI) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);

      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Subs/invoice-preview`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        const decryptedData = decryptData(response.data);

        result = decryptedData;

        return result.invoiceDetails;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);
