import { Radio } from 'antd';
import React from 'react';

import FullPageLoader from '../../../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../../../foundation/custom_hooks/useBreakpont';
import useSearch from '../../../../../foundation/custom_hooks/useSearch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  selectIsSearchFeatureLoading,
  selectOptions,
  selectSearchMetricValues,
} from '../../../redux/selectors';
import { setOptions } from '../../../redux/slice';

const Sort = ({ cancelHandler }: any) => {
  const RadioGroup = Radio.Group;

  const fetchResults = useSearch();
  const dispatch = useAppDispatch();
  const options = useAppSelector(selectOptions);
  const searchMetricValues = useAppSelector(selectSearchMetricValues);
  const isSearchFeatureLoading = useAppSelector(selectIsSearchFeatureLoading);

  const [isMobileView] = useBreakpoint();

  const sortTypeOptions = searchMetricValues?.sortTypes.map((option: any) => {
    return {
      label: option.key,
      value: option.value,
    };
  });

  const sortTypeChangeHandler = (e: any) => {
    dispatch(
      setOptions({
        ...options,
        sortType: e.target.value,
      }),
    );
  };

  const orderChangeHandler = (e: any) => {
    dispatch(
      setOptions({
        ...options,
        orderAsc: e.target.value,
      }),
    );
  };

  return (
    <>
      {isMobileView && isSearchFeatureLoading && (
        <FullPageLoader style={{ position: 'fixed' }} />
      )}
      <div className={`l-sorting${isMobileView ? ' l-sorting--mobile' : ''}`}>
        <div className="l-sorting__content">
          <div className="l-sorting__control">
            <RadioGroup
              options={sortTypeOptions}
              optionType="default"
              value={options ? options.sortType : null}
              onChange={sortTypeChangeHandler}
            />
          </div>
          <hr className="l-sorting__divider" />
          <div className="l-sorting__control">
            <RadioGroup
              options={[
                {
                  label: 'Ascending / A-Z',
                  value: true,
                },
                {
                  label: 'Descending / Z-A',
                  value: false,
                },
              ]}
              optionType="default"
              value={options.orderAsc}
              onChange={orderChangeHandler}
            />
          </div>
        </div>
        <div className="l-sorting__footer">
          <div className="l-sorting__button-group">
            <button
              className="l-sorting__button"
              type="button"
              style={{ width: '100%' }}
              onClick={() => {
                fetchResults({
                  isUsingLastSearchValues: true,
                  beforeCb: () => {
                    if (cancelHandler) {
                      cancelHandler();
                    }
                  },
                });
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sort;
