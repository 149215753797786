import React from 'react';

type Props = {
  strokeColor?: string;
};
function BellIcon({ strokeColor }: Props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66675 1.33333L6.30886 5.97544L1.66675 10.6176"
        stroke="#23005C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BellIcon;
