import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#651FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.812 10.667L1.333 6m0 0l4.479-4.667M1.333 6H6a8.667 8.667 0 018.667 8.667"
      ></path>
    </svg>
  );
}

export default Icon;
