import React from 'react';

type Props = {
  strokeColor?: string;
};
function BellIcon({ strokeColor }: Props) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97561 1.33333L1.3335 5.97544L5.97561 10.6176"
        stroke="#23005C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BellIcon;
