import React from 'react';

function StarPlain() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3079 1.65798C10.5645 1.04321 11.4355 1.04321 11.6921 1.65798L13.8937 6.93186C13.9963 7.17756 14.2214 7.35046 14.4852 7.38617L20.2876 8.17149C20.9378 8.25949 21.1695 9.08098 20.6612 9.49579L16.1809 13.1518C15.9594 13.3326 15.86 13.6235 15.9244 13.902L17.3568 20.0919C17.5119 20.762 16.7583 21.27 16.1953 20.8748L11.4309 17.5309C11.1723 17.3494 10.8277 17.3494 10.5691 17.5309L5.80471 20.8749C5.24167 21.27 4.48808 20.7621 4.64315 20.0919L6.07546 13.902C6.1399 13.6235 6.04043 13.3326 5.81895 13.1518L1.33881 9.49579C0.830482 9.08097 1.06223 8.25949 1.7124 8.17149L7.5148 7.38617C7.77863 7.35046 8.00376 7.17756 8.10632 6.93186L10.3079 1.65798Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeMiterlimit="3.3292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StarPlain;
