import React from 'react';

function ArrowRightTeal() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66675 5.44906C6.9882 5.74601 6.9882 6.25395 6.66675 6.55089L2.25891 10.6227C1.77855 11.0664 1 10.7257 1 10.0718V1.92819C1 1.27424 1.77855 0.933538 2.25891 1.37728L6.66675 5.44906Z"
        fill="#00ADD2"
        stroke="#00ADD2"
      />
    </svg>
  );
}

export default ArrowRightTeal;
