import React from 'react';

function ForwardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      fill="none"
      viewBox="0 0 8 13"
    >
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.667 1.833l4.642 4.642-4.642 4.643"
      ></path>
    </svg>
  );
}

export default ForwardIcon;
