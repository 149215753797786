import {
  FileOutlined,
  LogoutOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { TeamOutlined } from '@ant-design/icons';
import { Avatar, Button, Layout, Menu } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import BackIcon from '../../../foundation/assets/svgs/BackIcon';
import ForwardIcon from '../../../foundation/assets/svgs/ForwardIcon';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import useLogout from '../../../foundation/custom_hooks/useLogout';
import { useAppSelector } from '../../../store/hooks';
import { resetAiReport } from '../../ai_report/redux/slice';
import { resetSearch } from '../../search/redux/slice';
import {
  selectIsSubscribed,
  selectUserFeatures,
  selectUserProfile,
} from '../../user/redux/selectors';
import Logo from '../logo/Logo';
import {
  resetSiderMobileContentValues,
  setBreakpoint,
  setSiderMobileContentValues,
} from '../redux/slice';

const { Sider } = Layout;

type Props = {
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapsed: boolean;
};

const AppSider = ({ setIsCollapsed, isCollapsed }: Props) => {
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const [isMobileView, isDesktopView] = useBreakpoint();

  const profile = useSelector(selectUserProfile);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logoutUser = useLogout();

  const handleCollapsedLogic = () => {
    setIsCollapsed((v: boolean) => !v);
  };

  const handleBreakpointChange = (broken) => {
    dispatch(setBreakpoint(broken ? 'mobile' : 'desktop'));

    if (!broken) {
      dispatch(resetSiderMobileContentValues(undefined));
    }
  };

  const userFeatures = useAppSelector(selectUserFeatures);

  const menuItems = useMemo(() => {
    const items: any = [];

    if (userFeatures.includes('suburb-search')) {
      items.push({
        key: '/suburb-search',
        icon: <SearchOutlined />,
        label: 'Search',
        onClick: () => {
          dispatch(resetSearch());
        },
      });
    }

    if (isSubscribed) {
      items.push({
        key: '/suburb-report-build',
        icon: <FileOutlined />,
        label: 'Report Builder',
        onClick: () => {
          dispatch(resetAiReport());
        },
      });
    }

    if (userFeatures.includes('messaging')) {
      items.push({
        key: '/community',
        icon: <TeamOutlined />,
        label: 'Community',
      });
    }

    if (isDesktopView) {
      if (isSubscribed) {
        /*
        items.push({
          key: '/help',
          icon: <QuestionCircleOutlined />,
          label: 'Help',
        });
        */
      }
    } else {
      items.push({
        key: 'profile',
        icon: <Avatar size={'small'} src={profile?.picture} />,
        label: 'Profile',
      });
      if (isSubscribed) {
        /*
        items.push({
          key: '4',
          icon: <QuestionCircleOutlined />,
          label: 'Help',
        });
        */
      }
    }
    return items;
  }, [isMobileView, isDesktopView]);

  const handleMenuSelect = (v) => {
    if (v.key !== 'profile') {
      navigate(v.key);
    }
    if (isMobileView) {
      handleCollapsedLogic();
    }
    if (v.key === 'profile' && isMobileView) {
      dispatch(
        setSiderMobileContentValues({
          isVisible: true,
          view: 'profile',
          showBackButton: false,
          isClosable: true,
          headerTitle: 'Profile',
        }),
      );
    }
  };

  const handleBottomMenuSelect = (v) => {
    if (v.key === 'logout') {
      logoutUser(true);
    }
  };

  return (
    <Sider
      collapsed={isCollapsed}
      breakpoint="md"
      onBreakpoint={handleBreakpointChange}
      className="c-layout-sider"
      zeroWidthTriggerStyle={{ display: 'none' }}
      collapsedWidth={isMobileView ? 0 : undefined}
      width={isMobileView && !isCollapsed ? '100%' : undefined}
    >
      <Button
        shape="circle"
        icon={isCollapsed ? <ForwardIcon /> : <BackIcon />}
        onClick={handleCollapsedLogic}
        className="c-layout-sider__collap-btn"
      />
      <div>
        {isDesktopView && (
          <div className="c-layout-logo">
            <Logo />
          </div>
        )}
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          className="c-layout-menu"
          items={menuItems}
          onSelect={handleMenuSelect}
        />
      </div>
      <div className="c-layout-sider__bottom-menu-container">
        <Menu
          theme="dark"
          mode="inline"
          className="c-layout-menu"
          onSelect={handleBottomMenuSelect}
          selectedKeys={[]}
          items={[
            {
              key: 'logout',
              icon: <LogoutOutlined />,
              label: 'Logout',
            },
          ]}
        />
        {isMobileView && (
          <div>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size={'large'}
              className="c-layout-sider__invite-btn"
            >
              Invite
            </Button>
          </div>
        )}
      </div>
    </Sider>
  );
};

export default AppSider;
