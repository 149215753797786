import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../store/hooks';
import { selectStripeClientSecretId } from '../../user/redux/selectors';
import SectionTitle from '../components/section_components/SectionTitle';
import {
  BillingTabKeys,
  InvoiceType,
  PromoCodeDetailsType,
  SubscriptionFrequency,
} from '../helpers/types';
import Review from '../review/Review';
import PaymentForm from './PaymentForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51MQkEHHv4F9dyBb7nIZHXAytCMmqOaCTMDd8v1vYe5ycJJi2Hr19zsLqOmUG8V7NvVJxBuqb4EdBVcw69H7as5FH00HPWt2dC5',
);

type PaymentInformationTypes = {
  subscriptionFrequency: SubscriptionFrequency;
  setSubscriptionFrequency: (v: SubscriptionFrequency) => void;
  selectedPlan: any;
  additionalUsers: number;
  setAdditionalUsers: (v: number) => void;
  setSelectedPlan: (v: any) => void;
  setActiveTab: (v: BillingTabKeys) => void;
  isBillingLoading: boolean;
  setIsBillingLoading: (v: boolean) => void;
  activeTab: BillingTabKeys;
  promoCodeDetails: PromoCodeDetailsType | undefined;
  setPromoCodeDetails: (v: PromoCodeDetailsType) => void;
  invoiceData: InvoiceType | undefined;
  setInvoiceData: (v: InvoiceType) => void;
};

const PaymentInformation = ({
  subscriptionFrequency,
  selectedPlan,
  additionalUsers,
  setSubscriptionFrequency,
  setActiveTab,
  isBillingLoading,
  setIsBillingLoading,
  activeTab,
  invoiceData,
}: PaymentInformationTypes) => {
  const clientSecret = useAppSelector(selectStripeClientSecretId);

  const stripeOptions = useMemo(() => {
    const options = {
      // passing the client secret obtained from the server
      clientSecret: clientSecret,
    };

    return options;
  }, [clientSecret]);

  if (activeTab === 'info') {
    return null;
  } else {
    return (
      <div className="bill-pay-info">
        <Elements stripe={stripePromise} options={stripeOptions}>
          <SectionTitle title="Payment Information" />
          <div className="bill-pay-info__sections">
            <div className="bill-pay-info__section-payment-info">
              <div className="bill-pay-info__payment-message">
                Enter your payment details
              </div>
              <div>
                <PaymentForm />
              </div>
            </div>

            <Review
              subscriptionFrequency={subscriptionFrequency}
              selectedPlan={selectedPlan}
              additionalUsers={additionalUsers}
              isBillingLoading={isBillingLoading}
              setIsBillingLoading={setIsBillingLoading}
              setActiveTab={setActiveTab}
              invoiceData={invoiceData}
            />
          </div>
        </Elements>
      </div>
    );
  }
};

export default PaymentInformation;
