import { RootState } from '../../../store';

export const selectSuggestions = (state: RootState) => state.search.suggestions;

export const selectQueryValues = (state: RootState) => state.search.queryValues;

export const selectOptions = (state: RootState) => state.search.options;

export const selectResults = (state: RootState) => state.search.results;

export const selectKeyword = (state: RootState) => state.search.keyword;

export const selectEnabledSearchMetrics = (state: RootState) =>
  state.search.enabledSearchMetrics;

export const selectListContainerSize = (state: RootState) => {
  if (
    !!state.search.isMapSearchEnabled &&
    (!!state.search.activeSuburbSscCode || !!state.search.activePropertyId)
  ) {
    return 'compact';
  }

  return state.search.isMapSearchEnabled
    ? 'hidden'
    : state.search.listContainerSize;
};

export const selectActiveSuburb = (state: RootState) =>
  state.search.activeSuburb;

export const selectActiveSuburbSscCode = (state: RootState) =>
  state.search.activeSuburbSscCode;

export const selectActiveProperty = (state: RootState) =>
  state.search.activeProperty;

export const selectActivePropertyId = (state: RootState) =>
  state.search.activePropertyId;

export const selectHasActiveResultInView = (state: RootState) =>
  !!state.search.activeSuburbSscCode || !!state.search.activePropertyId;

export const getIsFavorite =
  (id: any, searchType: string) => (state: RootState) => {
    const favorites: any = state.user.favorites;

    const searchTypeAttribute =
      searchType === 'suburb' ? 'suburbs' : 'properties';

    const searchDataAttribute =
      searchType === 'suburb' ? 'sscCode' : 'propertyId';

    if (
      !favorites ||
      !favorites[searchTypeAttribute] ||
      !favorites[searchTypeAttribute].length
    ) {
      return false;
    }

    return favorites[searchTypeAttribute].some((entry: any) => {
      return entry[searchDataAttribute] === id;
    });
  };

export const selectIsFirstAttempt = (state: RootState) =>
  state.search.isFirstAttempt;

export const selectIsSearchFeatureLoading = (state: RootState) =>
  state.search.isSearchFeatureLoading;

export const selectSuburbMetricsValues = (state: RootState) =>
  state.search.suburbMetricsValues;

export const selectSuburbMetricsLegend = (state: RootState) =>
  state.search.suburbMetrics?.metricsLegend;

export const selectSuburbSelectedMetricsOptions = (state: RootState) =>
  state.search.selectedSuburbMetricsOptions;

export const selectSuburbMetrics = (state: RootState) =>
  state.search.suburbMetrics?.metrics;

export const selectIsFilterModalActive = (state: RootState) =>
  state.search.isFilterModalActive;

export const selectIsSaveSearchModalActive = (state: RootState) =>
  state.search.isSaveSearchModalActive;

export const selectIsSortModalActive = (state: RootState) =>
  state.search.isSortModalActive;

export const selectIsHeatmapActive = (state: RootState) =>
  state.search.isHeatmapActive;

export const selectLastSearchValues = (state: RootState) =>
  state.search.lastSearchValues;

export const selectSearchType = (state: RootState) => state.search.searchType;

export const selectSearchMetricValues = (state: any) => {
  return state.search.searchMetricValues[state.search.searchType];
};

export const selectSearchResultsPropertyType = (state: RootState) => {
  if (state.search.results && state.search.results.propertyType) {
    return state.search.results.propertyType;
  }
};

export const selectIsSkippingMapFocus = (state: RootState) => {
  return state.search.isSkippingMapFocus;
};

export const selectIsDisclaimerModalActive = (state: RootState) =>
  state.search.isDisclaimerModalActive;

export const selectFilterTooltips = (state: RootState) =>
  state.search.filterTooltips;

export const selectIsMapSearchEnabled = (state: RootState) =>
  state.search.isMapSearchEnabled;

export const selectGpsPoints = (state: RootState) => state.search.gpsPoints;
export const selectMapSummary = (state: RootState) => state.search.mapSummary;

export const selectMapSearchPropertyType = (state: RootState) =>
  state.search.mapSearchPropertyType;

export const selectIsMapSearchLoading = (state: RootState) =>
  state.search.isMapSearchLoading;

export const selectMapSearchSscCodes = (state: RootState) =>
  state.search.mapSearchSscCodes;
