import { notification } from 'antd';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import View from '../../../../search/view/details/key_indicators/growth/view/View';
import { postReportDetail } from '../../../redux/async_thunks';
import {
  selectDescriptors,
  selectGrowth,
  selectReportUser,
  selectSuburb,
} from '../../../redux/selectors';

const Growth = ({
  toggleLoader,
  setIsFirstLoad,
  isFirstLoad,
  isLoading,
}: any) => {
  const dispatch = useAppDispatch();
  const growth = useAppSelector(selectGrowth);

  const user: any = useAppSelector(selectReportUser);
  const suburb = useAppSelector(selectSuburb);

  const descriptor: any = useAppSelector(selectDescriptors);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Growth section',
      description: 'Something went wrong with the request',
    });
  };

  const fetchGrowthData = async () => {
    toggleLoader(true);

    try {
      await dispatch(
        postReportDetail({
          data: {
            userId: user.userId,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: user.clientIp,
            sessionId: user.sessionId,
          },
          endpoint: 'growth',
        }),
        // @ts-ignore
      ).unwrap();
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      growth={growth}
      descriptor={descriptor.growth}
      fetchGrowthData={fetchGrowthData}
      user={user}
      suburb={suburb}
      isLoading={isLoading}
      isFirstLoad={isFirstLoad}
    />
  );
};

export default Growth;
