import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useIsLightPlanId } from '../../../foundation/custom_hooks/useIsLightPlanId';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Onboarding from '../../account/onboarding/Onboarding';
import {
  selectClientIp,
  selectSubscriptionPlanDetails,
  selectUser,
} from '../../user/redux/selectors';
import { getParsedValue } from '../helpers/getParsedValue';
import { confirmPayment, ConfirmPaymentReqBody } from '../redux/async_thunks';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const isLightPlan = useIsLightPlanId();

  const [paymentSuccObj, setPaymentSuccObj] = useState<any>(undefined);

  const [isPaymentSuccessLoading, setPaymentSuccessLoading] = useState(true);

  const [isOboardingVisible, setOnboardingVisibility] = useState(false);

  const [isUpdateView, setIsUpdateView] = useState(false);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const subscriptionPlanDetails = useAppSelector(selectSubscriptionPlanDetails);

  const parsed: any = queryString.parse(location.search);

  const handleGoToRhomeo = () => {
    navigate('/');
  };

  useEffect(() => {
    if (location.state && location.state.update) {
      setIsUpdateView(true);
    } else {
      setIsUpdateView(false);
    }
  }, [location.state]);

  const handleConfirmPayment = async () => {
    try {
      const data: ConfirmPaymentReqBody = {
        userId: user.userId,
        subscriptionId: subscriptionPlanDetails.subscriptionId,
      };

      if (parsed.payment_intent) {
        data.paymentIntentId = parsed.payment_intent;
      }

      const options = {
        token: user.jwtToken.token,
        clientIp: clientIp,
        sessionId: user.sessionId,
      };

      const response = await dispatch(
        confirmPayment({ data, options }),
      ).unwrap();

      setPaymentSuccObj(response);

      setPaymentSuccessLoading(false);
    } catch (error) {
      setPaymentSuccessLoading(false);
    }
  };

  useEffect(() => {
    if (subscriptionPlanDetails.subscriptionId) {
      handleConfirmPayment();
    }
  }, [subscriptionPlanDetails]);

  const handleSetupClick = () => {
    setOnboardingVisibility(true);
  };

  const handleOnboardingCancel = () => {
    setOnboardingVisibility(false);
  };
  return (
    <div className="l-success">
      {isOboardingVisible && (
        <Onboarding handleCancel={handleOnboardingCancel} />
      )}
      {isPaymentSuccessLoading && <FullPageLoader />}
      <div className="l-success__wrapper">
        <div className="l-success__details-wrapper">
          <CheckCircleOutlined className="l-success__success-icon" />
          <div className="l-success__details-title">
            Thank you for your payment! <br />
            {isUpdateView && (
              <span
                style={{ fontSize: 21 }}
              >{`Your plan has successfully been upgraded.`}</span>
            )}
          </div>
          <div className="l-success__details-sub-title">
            A Receipt for this transaction has been sent via email for your
            records.
          </div>

          <div className="l-success__details-item-wrapper">
            <div className="l-success__details-item-title">
              Total Payment Amount
            </div>
            <div
              className="l-success__details-item-title"
              style={{ fontSize: 20 }}
            >
              {paymentSuccObj &&
                `$${getParsedValue(paymentSuccObj.paidAmount)}`}
            </div>
          </div>

          <div className="l-success__details-item-wrapper">
            <div className="l-success__details-item-title">Payment Status</div>
            <div className="l-success__details-item-value">
              {paymentSuccObj && paymentSuccObj.paidSubscription
                ? 'Approved'
                : 'Pending'}
            </div>
          </div>

          <div className="l-success__details-item-wrapper">
            <div className="l-success__details-item-title">Invoice Number</div>
            <div className="l-success__details-item-value">
              {paymentSuccObj && paymentSuccObj.invoiceNumber}
            </div>
          </div>

          <div className="l-success__details-item-wrapper">
            <div className="l-success__details-item-title">Payment Date</div>
            <div className="l-success__details-item-value">
              {paymentSuccObj &&
                moment(paymentSuccObj.paymentDate).format('DD/MM/YYYY')}
            </div>
          </div>

          {paymentSuccObj && (
            <div className="l-success__details-item-wrapper">
              <div className="l-success__details-item-title">Invoice</div>
              <div className="l-success__details-item-value">
                <a
                  href={paymentSuccObj.invoiceUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Invoice
                </a>
              </div>
            </div>
          )}
        </div>
        {!isUpdateView && (
          <div
            className={`l-success__btns-wrapper  ${
              isLightPlan ? ' l-success__btns-wrapper--center' : ''
            }`}
          >
            <Button
              className="l-success__btn"
              style={{ marginRight: 15 }}
              onClick={handleGoToRhomeo}
            >
              Go to rhomeo
            </Button>
            {!isLightPlan && (
              <Button
                type="primary"
                className="l-success__btn"
                onClick={handleSetupClick}
              >
                Set up company profile
              </Button>
            )}
          </div>
        )}
        {isUpdateView && (
          <div
            className={`l-success__btns-wrapper  ${
              isLightPlan ? ' l-success__btns-wrapper--center' : ''
            }`}
          >
            <Button
              className="l-success__btn"
              style={{ marginRight: 15 }}
              onClick={() => navigate('/account')}
            >
              Back to settings
            </Button>

            <Button
              type="primary"
              className="l-success__btn"
              onClick={handleGoToRhomeo}
            >
              Go to rhomeo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
