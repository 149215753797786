import { Avatar, Button, notification, Upload } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChangePictureIcon from '../../../foundation/assets/svgs/ChangePictureIcon';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import Input from '../../../foundation/components/input/Input';
import TextArea from '../../../foundation/components/textarea/TextArea';
import generateBase64 from '../../../foundation/utils/generateBase64';
import { updateProfile } from '../redux/async_thunks';
import {
  selectClientIp,
  selectUser,
  selectUserProfile,
} from '../redux/selectors';
import ProfileEditTypeValidationSchema from './validation_schema';

type Props = {
  handleClose: () => any;
};
const ProfileEditForm = ({ handleClose }: Props) => {
  const dispatch = useDispatch();
  const profile = useSelector(selectUserProfile);
  const user = useSelector(selectUser);
  const clientIp = useSelector(selectClientIp);
  const [isProfileFormLoading, setProfileFormLoading] = useState(false);
  const [uploadPreview, setUploadPreview] = useState<any>();
  const [uploadFile, setUploadFile] = useState<any>();

  const handleFormSubmit = async (values: any) => {
    if (isProfileFormLoading) {
      return;
    }

    if (profile && user) {
      const edited = _.reduce(
        values,
        (result, value, key) => {
          return _.isEqual(value, profile[key])
            ? result
            : // @ts-ignore
              result.concat(key);
        },
        [],
      );

      const data: any = {
        userId: user?.userId,
        firstName: values.firstName,
        lastName: values.lastName,
      };

      for (let i = 0; i < edited.length; i++) {
        data[edited[i]] = values[edited[i]];
      }

      if (uploadFile) {
        data.picture = values.picture;
      }

      const options = {
        token: user?.jwtToken.token,
        clientIp: clientIp,
        sessionId: user?.sessionId,
      };

      try {
        setProfileFormLoading(true);

        await dispatch(
          // @ts-ignore
          updateProfile({
            options,
            data,
          }),
        ).unwrap();

        notification.success({
          message: 'Success',
          description: 'Profile Save Successfully!',
        });
        setProfileFormLoading(false);
        handleClose();
      } catch (error) {
        setProfileFormLoading(false);
        handleClose();
      }
    }
  };

  const ErrorField = ({ error }: { error: string | undefined }) => {
    return <div className="c-profile-form__error">{error}</div>;
  };

  const onChange =
    (setFieldValue, values) =>
    async ({ file }) => {
      if (file.status === 'uploading') {
        return;
      }

      if (file.status === 'done') {
        const preview = await generateBase64(file.originFileObj);

        setUploadFile(file.originFileObj);
        setFieldValue('picture', file.originFileObj);
        setUploadPreview(preview);
      }
    };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <div className="l-profile-edit-form">
      {isProfileFormLoading && <FullPageLoader />}

      <Formik
        initialValues={{
          ...profile,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={ProfileEditTypeValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="c-profile-form__wrapper">
              <ImgCrop rotate>
                <Upload
                  accept=".png,.jpg,.jpeg,.jfif"
                  maxCount={1}
                  onChange={onChange(setFieldValue, values)}
                  customRequest={dummyRequest}
                  onPreview={onPreview}
                  showUploadList={false}
                  name="picture"
                  onRemove={() => {
                    setUploadPreview('');
                    setUploadFile('');
                  }}
                >
                  <div className="c-profile-form__avatar-wrapper">
                    <div className="c-profile-form__avatar-overlay">
                      <ChangePictureIcon />
                    </div>

                    <Avatar
                      src={uploadFile ? uploadPreview : profile?.picture}
                      size={120}
                      className="c-profile-form__avatar"
                    />
                  </div>
                </Upload>
              </ImgCrop>
              <Input
                label="First Name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                name="firstName"
              />
              <ErrorField error={errors && errors.firstName} />
              <Input
                label="Last Name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                name="lastName"
              />
              <ErrorField error={errors && errors.lastName} />
              <Input
                label="Handle"
                value={values.handle}
                onChange={handleChange}
                onBlur={handleBlur}
                name="handle"
              />
              <ErrorField error={errors && errors.handle} />
              <Input
                label="Occupation"
                value={values.occupation}
                onChange={handleChange}
                onBlur={handleBlur}
                name="occupation"
              />
              <ErrorField error={errors && errors.occupation} />
              <TextArea
                label="Bio"
                value={values.bio}
                onChange={handleChange}
                onBlur={handleBlur}
                name="bio"
                rows={4}
              />
              <ErrorField error={errors && errors.bio} />

              <Button
                type="primary"
                htmlType="submit"
                className="c-profile-form__save-btn"
                disabled={!(isValid && dirty) || isProfileFormLoading}
              >
                Save
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileEditForm;
