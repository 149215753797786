import React from 'react';

type Props = {
  strokeColor?: string;
  style?: any;
};
function StarIcon({ strokeColor, style }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      style={style || {}}
    >
      <path
        stroke={strokeColor ? strokeColor : '#4E4B66'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.329"
        strokeWidth="1.5"
        d="M10.308 1.658a.75.75 0 011.384 0l2.202 5.274a.75.75 0 00.591.454l5.803.785a.75.75 0 01.373 1.325l-4.48 3.656a.75.75 0 00-.257.75l1.433 6.19c.155.67-.599 1.178-1.162.783l-4.764-3.344a.75.75 0 00-.862 0l-4.764 3.344c-.563.395-1.317-.113-1.162-.783l1.432-6.19a.75.75 0 00-.256-.75l-4.48-3.656a.75.75 0 01.373-1.325l5.803-.785a.75.75 0 00.591-.454l2.202-5.274z"
      ></path>
    </svg>
  );
}

export default StarIcon;
