import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const postSearchSuggestions = createAsyncThunk(
  'search/suggestions',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      userId: apiParams.data.userId,
      searchKeyword: apiParams.data.searchKeyword,
      selectedValues: apiParams.data.selectedValues,
    };

    const encrypted = encryptData(data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Search/location-suggestion`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data).searchSuggestions;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postMapSearchResults = createAsyncThunk(
  'search/mapResults',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);
    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Search/${apiParams.options.searchType}`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        if (response.status === 204) {
          result = {
            mapSearchPropertyType: 'Houses',
            gpsPoints: undefined,
            mapSummary: 'No results found',
          };
        } else {
          if (response && response.data) {
            const decrypted = decryptData(response.data);

            result = {
              mapSearchPropertyType: apiParams.data.propertyType,
              gpsPoints: decrypted.gpsPoints,
              mapSummary: decrypted.mapSummary,
            };
          }
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSearchResults = createAsyncThunk(
  'search/results',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);
    const encrypted = encryptData(apiParams.data);
    const searchType = apiParams.options.searchType;
    const searchTypeAttribute =
      searchType === 'suburb' ? 'suburbs' : 'properties';

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Search/${apiParams.options.searchType}`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        if (response.status === 204) {
          result = {
            [searchTypeAttribute]: [],
            pageCount: 0,
            pageIndex: 1,
            propertyType: 'Houses',
            resultCount: 0,
          };
        } else {
          if (response && response.data) {
            const decrypted = decryptData(response.data);

            result = {
              [searchTypeAttribute]: decrypted[searchTypeAttribute],
              pageCount: decrypted.pageCount,
              resultCount: decrypted.resultCount,
              pageIndex: apiParams.data.pageIndex,
              propertyType: apiParams.data.propertyType,
              lastSearchValues: apiParams.data.searchValues,
              searchType,
            };
          }
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postPropertyDetails = createAsyncThunk(
  'search/propertyDetails',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      userId: apiParams.data.userId,
      propertyId: apiParams.data.propertyId,
    };

    const encrypted = encryptData(data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Property/details`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postFavorite = createAsyncThunk(
  'favorite/flag',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const searchType = apiParams.options.searchType;
    const searchTypeAttribute =
      searchType === 'suburb' ? 'suburbs' : 'properties';
    const searchDataAttribute =
      searchType === 'suburb' ? 'sscCode' : 'propertyId';

    const clientId = getClientID(apiParams.options.clientIp);
    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Favorite/${apiParams.options.searchType}`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        if (response && response.data) {
          result = {
            [searchTypeAttribute]: decryptData(response.data)[
              searchTypeAttribute
            ],
            updatedValue: {
              entry: apiParams.data[searchDataAttribute],
              isFavorite: apiParams.data.isFavorite,
              searchDataAttribute: searchDataAttribute,
              searchTypeAttribute: searchTypeAttribute,
            },
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postFavoriteAddSearch = createAsyncThunk(
  'favorite/addSearch',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await axios
      .post(
        `${env_constants.RHOMEO_API}/Favorite/${apiParams.options.searchType}-search`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        if (response && response.data) {
          result = {
            [`${apiParams.options.searchType}Searches`]: decryptData(
              response.data,
            ).searches,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbPreview = createAsyncThunk(
  'favorite/suburbPreview',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Favorite/suburb-share`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          const decrypted = decryptData(response.data);
          result = decrypted;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSuburbMetrics = createAsyncThunk(
  'search/suburbMetrics',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Metrics`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const suburbShare = createAsyncThunk(
  'suburb/share',
  async (apiParams: { data: any; options: any }) => {
    try {
      let result;

      const clientId = getClientID(apiParams.options.clientIp);
      const encrypted = encryptData(apiParams.data);

      const headers = {
        Authorization: `Bearer ${apiParams.options.token}`,
        clientid: clientId,
        sessionid: apiParams.options.sessionId,
        Accept: '*/*',
        'Content-Type': 'application/json',
      };

      const response = await endpoint.post(
        `${env_constants.RHOMEO_API}/Suburb/share`,
        encrypted,
        {
          headers: headers,
        },
      );

      if (response && response.data) {
        result = decryptData(response.data);
        return result;
      }
    } catch (error: any) {
      return Promise.reject(decryptData(error.response.data));
    }
  },
);
