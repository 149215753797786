import { each, isEmpty } from 'lodash';

export const prepareSearchMetrics = (
  options: any,
  enabledSearchMetrics: any,
  searchMetricValues: any,
) => {
  let searchMetricsChecked: any = {};

  const assignValues = (metricValue: any, metricKey: string) => {
    const minMaxLimits = searchMetricValues?.filters[metricKey];
    searchMetricsChecked[metricKey] = {};

    if (metricKey === 'baths' || metricKey === 'carSpaces') {
      searchMetricsChecked[metricKey] = metricValue;
    }

    if (metricValue.min !== null && metricValue.min !== minMaxLimits?.min) {
      searchMetricsChecked[metricKey].min = metricValue.min;
    }

    if (metricValue.max !== null && metricValue.max !== minMaxLimits?.max) {
      searchMetricsChecked[metricKey].max = metricValue.max;
    }
  };

  if (!enabledSearchMetrics || !enabledSearchMetrics.length) {
    return null;
  }

  each(options.searchMetrics, (metricValue: any, metricKey: string) => {
    if (enabledSearchMetrics.includes(metricKey)) {
      assignValues(metricValue, metricKey);
    }
  });

  // Remove empty objects
  searchMetricsChecked = Object.keys(searchMetricsChecked)
    .filter((key) => {
      return !!searchMetricsChecked[key] || !isEmpty(searchMetricsChecked[key]);
    })
    .reduce((obj: any, key) => {
      obj[key] = searchMetricsChecked[key];
      return obj;
    }, {});

  return isEmpty(searchMetricsChecked) ? null : searchMetricsChecked;
};
