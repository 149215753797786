import React from 'react';

function FavoritePropertyMarker() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5355_142416)">
        <circle cx="16" cy="15" r="11" fill="#E61B00" />
        <circle cx="16" cy="15" r="10" stroke="#FCFCFC" strokeWidth="2" />
      </g>
      <path
        d="M15.549 8.3266C15.7308 7.89113 16.3477 7.89113 16.5295 8.3266L18.089 12.0623C18.1616 12.2363 18.3211 12.3588 18.508 12.3841L22.618 12.9403C23.0785 13.0027 23.2427 13.5846 22.8826 13.8784L19.7091 16.4681C19.5522 16.5961 19.4817 16.8022 19.5274 16.9994L20.542 21.3839C20.6519 21.8586 20.1181 22.2185 19.7193 21.9385L16.3445 19.5699C16.1613 19.4414 15.9172 19.4414 15.7341 19.5699L12.3593 21.9386C11.9605 22.2185 11.4267 21.8587 11.5365 21.384L12.5511 16.9994C12.5967 16.8022 12.5262 16.5961 12.3694 16.4681L9.19593 13.8784C8.83587 13.5846 9.00002 13.0027 9.46056 12.9403L13.5706 12.3841C13.7575 12.3588 13.9169 12.2363 13.9896 12.0623L15.549 8.3266Z"
        fill="#FCFCFC"
      />
      <defs>
        <filter
          id="filter0_d_5355_142416"
          x="0"
          y="0"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5355_142416"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5355_142416"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default FavoritePropertyMarker;
