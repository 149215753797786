import { createSlice } from '@reduxjs/toolkit';

import { cancelSubscription } from '../../account/redux/async_thunks';
import {
  postFavorite,
  postFavoriteAddSearch,
} from '../../search/redux/async_thunks';
import {
  getClientIP,
  getSubscriptionDetails,
  patchRefresh,
  postLogin,
  updateProfile,
} from './async_thunks';
import { UserState } from './types';

const subscribeChallengeDefault: any = {
  customerId: undefined,
  subscriptionPlans: undefined,
  subscriptionPlanDetails: {
    subscriptionId: undefined,
    subscriptionPlanId: undefined,
    subscriptionPlan: undefined,
    subscriptionPlanCost: undefined,
    additionalUsers: undefined,
    additionalUsersCost: undefined,
    frequency: undefined,
    clientSectId: undefined,
  },
};

const initialState: UserState = {
  user: undefined,
  clientIP: undefined,
  featureFlag: {
    signUp: false,
  },
  invite: undefined,
  profile: undefined,
  favorites: undefined,
  ccConfig: undefined,
  stripeConfig: undefined,
  subscribed: false,
  subscribeChallenge: subscribeChallengeDefault,
  isOnboarded: false,
  notifications: undefined,
  hasNotifications: false,
  occupiedSeats: 0,
  billingFrequency: undefined,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setClientIp: (state, action) => {
      state.clientIP = action.payload;
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setFavorites: (state, action) => {
      return {
        ...state,
        favorites: action.payload,
      };
    },
    setIsOnboarded: (state, action) => {
      state.isOnboarded = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setHasNotifications: (state, action) => {
      state.hasNotifications = action.payload;
    },
    setStripeCustomerId: (state, action) => {
      state.subscribeChallenge.customerId = action.payload;
    },
    setStripeSubscriptionId: (state, action) => {
      state.subscribeChallenge.subscriptionPlanDetails.subscriptionId =
        action.payload;
    },
    setStripeClientSecretId: (state, action) => {
      state.subscribeChallenge.subscriptionPlanDetails.clientSectId =
        action.payload;
    },
    setSubscriptionPlanDetails: (state, action) => {
      state.subscribeChallenge.subscriptionPlanDetails = action.payload;
    },
    setOrganizationDetailsInProfile: (state, action) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getClientIP.fulfilled, (state, action) => {
        state.clientIP = action.payload.ip;
      })
      .addCase(patchRefresh.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          jwtToken: action.payload.jwtToken,
          userRole: action.payload.userRole,
          userId: action.payload.userId,
          sessionId: action.payload.sessionId,
          email: action.payload.email,
          name: action.payload.name,
          picture: action.payload.picture,
          ccUserId: action.payload.ccUserId,
          ccToken: action.payload.ccToken,
          features: action.payload.features,
        };

        state.featureFlag = action.payload.featureFlag;
        state.profile = action.payload.profile;
        state.invite = action.payload.invite;
        state.favorites = action.payload.favorites;
        state.ccConfig = action.payload.ccConfig;
        state.stripeConfig = action.payload.stripeConfig;
        state.subscribed = action.payload.subscribed;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.user = {
          jwtToken: action.payload.jwtToken,
          userRole: action.payload.userRole,
          userId: action.payload.userId,
          sessionId: action.payload.sessionId,
          email: action.payload.email,
          name: action.payload.name,
          picture: action.payload.picture,
          ccUserId: action.payload.ccUserId,
          ccToken: action.payload.ccToken,
          features: action.payload.features,
        };

        state.featureFlag = action.payload.featureFlag;
        state.profile = action.payload.profile;
        state.invite = action.payload.invite;
        state.favorites = action.payload.favorites;
        state.ccConfig = action.payload.ccConfig;
        state.stripeConfig = action.payload.stripeConfig;
        state.subscribed = action.payload.subscribed;
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.subscribeChallenge = {
            subscriptionPlans: action.payload.subscriptionPlans,
            subscriptionPlanDetails: action.payload.subscriptionPlanDetails,
            customerId: action.payload.customerId,
          };

          if (action.payload.occupiedSeats) {
            state.occupiedSeats = action.payload.occupiedSeats;
          }

          if (action.payload.billingFrequency) {
            state.billingFrequency = action.payload.billingFrequency;
          }

          if (action.payload.subscriptionPlanDetails === null) {
            state.subscribeChallenge.subscriptionPlanDetails = {
              subscriptionId: undefined,
              subscriptionPlanId: undefined,
              subscriptionPlan: undefined,
              subscriptionPlanCost: undefined,
              additionalUsers: undefined,
              additionalUsersCost: undefined,
              frequency: undefined,
              clientSectId: undefined,
            };
          }
        } else {
          state.subscribeChallenge = subscribeChallengeDefault;
        }
      })
      .addCase(getSubscriptionDetails.rejected, (state) => {
        state.subscribeChallenge = subscribeChallengeDefault;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.subscribeChallenge.subscriptionPlanDetails = {
          ...action.payload,
        };
      })
      .addCase(postFavoriteAddSearch.fulfilled, (state, action: any) => {
        state.favorites = {
          ...state.favorites,
          ...action.payload,
        };
      })
      .addCase(postFavorite.fulfilled, (state, action: any) => {
        state.favorites = {
          ...state.favorites,
          ...action.payload,
        };
      });
  },
});

export const {
  setClientIp,
  setFavorites,
  setIsOnboarded,
  setNotifications,
  setHasNotifications,
  setStripeCustomerId,
  setStripeSubscriptionId,
  setStripeClientSecretId,
  setSubscriptionPlanDetails,
  setOrganizationDetailsInProfile,
} = user.actions;

export default user.reducer;
