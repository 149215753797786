import React from 'react';

function SortChannelIcon({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color ? color : '#6E7191'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.25 3.75v9a4.5 4.5 0 01-4.5 4.5H9m5.25-13.5l3 3m-3-3l-3 3m-7.5 7.5v-9a4.5 4.5 0 014.5-4.5H9m-5.25 13.5l-3-3m3 3l3-3"
      ></path>
    </svg>
  );
}

export default SortChannelIcon;
