import React from 'react';

function BlockQuote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path stroke="#AFAFAF" d="M13 1H6M16 5H6"></path>
      <path stroke="#AFAFAF" strokeWidth="3" d="M2 10V0"></path>
      <path stroke="#AFAFAF" d="M12 9H6"></path>
    </svg>
  );
}

export default BlockQuote;
