export default {
  ERROR_JOIN_CHANNEL: 'Something went wrong while joining the channel',
  ERROR_CREATE_CHANNEL:
    'Something went wrong while making channel creation request',
  SUCCESS_CREATE_CHANNEL: 'Channel creation was successful',
  SUCCESS_ADD_MEMBER: 'Member has been added to the channel successfully.',
  SUCCESS_REMOVE_MEMBER: 'Member has been removed from the channel.',
  SUCCESS_BAN_MEMBER: 'Member has been banned from the channel.',
  SUCCESS_UNBAN_MEMBER: 'Member has been unbanned from the channel.',
  SUCCESS_SHARE_SUBURB_PREVIEW: 'Suburb preview has been shared',
  SUCCESS_JOIN_CHANNEL: 'You just joined the channel. Enjoy!',
  SUCCESS_LEAVE_CHANNEL: 'You left the channel but you can rejoin anytime',
  SUCCESS_UPDATING_USER_SCOPE_TO_ADMIN:
    'Member has been granted administrator permissions',
  SUCCESS_UPDATING_USER_SCOPE_TO_PARTICIPANT: 'Member is no longer an admin',
  ERROR_KICKING_USER: 'Something went wrong while removing user from channel',
  ERROR_BANNING_USER: 'Something went wrong while banning user from channel',
  ERROR_ADDING_MEMBER: 'Something went wrong while adding user to channel',
  ERROR_UPDATING_USER_SCOPE:
    "Something went wrong while updating the user's scope",
  ERROR_UNBANNING_USER:
    'Something went wrong while unbanning user from channel',
};
