import { useMemo } from 'react';

import { SubscriptionFrequency } from '../types';

export const useFrequencyString = (
  subscriptionFrequency: SubscriptionFrequency,
) => {
  const frequencyString = useMemo(
    () => (subscriptionFrequency === 'Annually' ? 'yr' : 'mo'),
    [subscriptionFrequency],
  );

  return frequencyString;
};

export const useAdditionUsersLabel = (additionalUsers: number) => {
  const additionalUsersLabel = useMemo(
    () => (additionalUsers > 1 ? `${additionalUsers} seats` : '1 seat'),
    [additionalUsers],
  );

  return additionalUsersLabel;
};
