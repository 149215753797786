import { UploadOutlined } from '@ant-design/icons';
import { Avatar, notification, Switch } from 'antd';
import { Form, Input, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react';

import CheckSvg from '../../../../foundation/assets/svgs/Check';
import CloseSvg from '../../../../foundation/assets/svgs/Close';
import EditIcon from '../../../../foundation/assets/svgs/EditIcon';
import FloatLabel from '../../../../foundation/components/float_label/FloatLabel';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import appMessages from '../../../../foundation/messages';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setSiderMobileContentValues } from '../../../layout/redux/slice';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import {
  fetchChannelNotificationSettings,
  updateChannel,
  updateChannelNotificationSettings,
} from '../../redux/async_thunks';
import { selectChannelsList } from '../../redux/selectors';
import { setChannelsList, setChatWindowType } from '../../redux/slice';
import { setSelectedConversation } from '../../redux/slice';
import { leaveChannel } from '../../utils/controllers/channelListController';

type Props = {
  cancelHandler: () => any;
  conversation: any;
  channelOwner: any;
};

const ChannelOptionsModal = ({
  cancelHandler,
  conversation,
  channelOwner,
}: Props) => {
  // file deepcode ignore PromiseNotCaughtGeneral
  const dispatch = useAppDispatch();
  const { TextArea } = Input;

  const USER = useAppSelector(selectUser);
  const CLIENT_IP = useAppSelector(selectClientIp);

  const channelsList = useAppSelector(selectChannelsList);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [pictureValidationError, setPictureValidationError] =
    useState<string>();
  const [detailsValidationError, setDetailsValidationError] =
    useState<string>();
  const [uploadFile, setUploadFile] = useState<any>();
  const [uploadPreview, setUploadPreview] = useState<any>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);

  const [channelDetails, setChannelDetails] = useState(
    conversation.conversationWith,
  );

  const [isMobileView, isDesktopView] = useBreakpoint();

  const [pictureForm] = Form.useForm();
  const [detailsForm] = Form.useForm();

  const name = Form.useWatch('name', detailsForm);
  const description = Form.useWatch('description', detailsForm);

  const [settings, setSettings] = useState({
    appNotify: false,
    emailNotify: false,
  });

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const errorHandler = () => {
    notification.error({
      description: appMessages.generic.SOMETHING_WENT_WRONG,
      message: 'Error!',
    });
  };

  const formErrorHandler = (error: any, cb: any) => {
    console.log(error);

    if ((error.code === 400 || error.code === 409) && error.message !== '') {
      cb();
    } else {
      errorHandler();
    }
  };

  const handleLeaveChannel = async () => {
    setIsLoading(true);

    await leaveChannel(channelDetails.guid).then(() => {
      setIsLoading(false);
      cancelHandler();

      dispatch(
        setChannelsList(
          channelsList.filter((conversation: any) => {
            return conversation.conversationWith.guid !== channelDetails.guid;
          }),
        ),
      );

      dispatch(setSelectedConversation(undefined));

      if (isDesktopView) {
        dispatch(setChatWindowType('channel-browse'));
      } else if (isMobileView) {
        const data = {
          isVisible: true,
          view: 'browse_channels',
          showBackButton: false,
          isClosable: true,
          headerTitle: 'All Channels',
          parentConfig: undefined as any,
        };

        dispatch(setSiderMobileContentValues(data));
      }

      notification.success({
        description: appMessages.channel.SUCCESS_LEAVE_CHANNEL,
        message: 'Success!',
      });
    });

    setIsLoading(false);
  };

  const updateSettings = async (update: any) => {
    if (update) {
      try {
        const response = await dispatch(
          updateChannelNotificationSettings({
            data: {
              userId: USER.userId,
              groupId: channelDetails.guid,
              appNotify: update.appNotify,
              emailNotify: update.emailNotify,
            },
            options: {
              token: USER.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: USER.sessionId,
            },
          }),
          // @ts-ignore
        ).unwrap();

        setSettings(response);
      } catch (error) {
        console.log(error);
        errorHandler();
      }
    }
  };

  const updateChannelPicture = async () => {
    setPictureValidationError('');
    setIsLoading(true);

    const data = {
      userId: USER.userId,
      groupId: channelDetails.guid,
      channelPurpose: channelDetails.description,
    };

    try {
      const response = await dispatch(
        updateChannel({
          data: data,
          file: {
            picture: uploadFile,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      setIsLoading(false);
      setUploadFile(false);
      setUploadPreview(false);

      const update = {
        ...conversation,
        ...{
          conversationWith: {
            ...conversation.conversationWith,
            ...{ icon: response.pictureUrl },
          },
        },
      };

      setChannelDetails(update.conversationWith);

      dispatch(setSelectedConversation(update));

      dispatch(
        setChannelsList(
          channelsList.map((conversation: any) => {
            if (conversation.conversationWith.guid === channelDetails.guid) {
              return update;
            }

            return conversation;
          }),
        ),
      );

      pictureForm.resetFields();
    } catch (error: any) {
      formErrorHandler(error, () => {
        setPictureValidationError(error.message);
      });
    }

    setIsLoading(false);
  };

  const uploadChangeHandler = async (info: any) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true);
      return;
    }

    if (info.file.status === 'done') {
      setIsUploading(false);
      const preview = await getBase64(info.file.originFileObj);

      setUploadFile(info.file.originFileObj);
      setUploadPreview(preview);
    }
  };

  const updateChannelDetails = async (values: any) => {
    setDetailsValidationError('');
    setIsLoading(true);

    const data: any = {
      userId: USER.userId,
      groupId: channelDetails.guid,
    };

    if (channelDetails.name !== values.name) {
      data.channelName = values.name;
    }

    if (channelDetails.description !== values.description) {
      data.channelPurpose = values.description;
    }

    if (
      data.channelPurpose !== '' &&
      !data.channelPurpose &&
      !data.channelName
    ) {
      setIsEditing(false);
      setIsLoading(false);
      setDetailsValidationError('');
      detailsForm.resetFields();

      return false;
    }

    try {
      await dispatch(
        updateChannel({
          data: data,
          file: {
            picture: uploadFile,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      setIsLoading(false);
      setIsEditing(false);

      const update = {
        ...conversation,
        ...{
          conversationWith: {
            ...conversation.conversationWith,
            ...{
              name: data.channelName || channelDetails.name,
              description: data.channelPurpose,
            },
          },
        },
      };

      setChannelDetails(update.conversationWith);
      dispatch(setSelectedConversation(update));

      dispatch(
        setChannelsList(
          channelsList.map((conversation: any) => {
            if (conversation.conversationWith.guid === channelDetails.guid) {
              return update;
            }

            return conversation;
          }),
        ),
      );
    } catch (error: any) {
      formErrorHandler(error, () => {
        setDetailsValidationError(error.message);
      });
    }

    setIsLoading(false);
  };

  const fetchSettings = async () => {
    try {
      const response = await dispatch(
        fetchChannelNotificationSettings({
          data: {
            userId: USER.userId,
            groupId: channelDetails.guid,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      setSettings(response);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const renderEditoIcon = () => {
    if (channelDetails.scope !== 'admin') {
      return <></>;
    }

    return (
      <button
        className="edit-details"
        type="button"
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <EditIcon />
      </button>
    );
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div className="l-channel-options">
      {(isLoading || isUploading) && <FullPageLoader />}
      {isEditing ? (
        <Form
          onFinish={updateChannelDetails}
          form={detailsForm}
          initialValues={{
            name: channelDetails.name,
            description: channelDetails.description,
          }}
        >
          <FloatLabel label="Channel Name" value={name}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Channel name is required',
                },
              ]}
            >
              <Input className="l-common-form__input" />
            </Form.Item>
          </FloatLabel>
          <FloatLabel label="Description" value={description}>
            <Form.Item name="description">
              <TextArea className="l-common-form__textarea" rows={4} />
            </Form.Item>
          </FloatLabel>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              className="l-channel-options__confirm proceed"
              style={{ marginTop: '0' }}
            >
              <p>Save changes?</p>
              {detailsValidationError && (
                <div className="error">{detailsValidationError}</div>
              )}
              <div>
                <button
                  type="button"
                  style={{ opacity: 0.3 }}
                  onClick={() => {
                    setIsEditing(false);
                    setDetailsValidationError('');
                    detailsForm.resetFields();
                  }}
                >
                  <CloseSvg />
                </button>
                <button type="submit">
                  <CheckSvg />
                </button>
              </div>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <>
          {channelDetails.description ? (
            <div className="l-channel-options__detail">
              <span>Description {renderEditoIcon()}</span>
              <p>{channelDetails.description}</p>
            </div>
          ) : (
            <>
              {channelDetails.scope === 'admin' && (
                <div className="l-channel-options__detail">
                  <span>Description {renderEditoIcon()}</span>
                  <p>N/a</p>
                </div>
              )}
            </>
          )}
        </>
      )}
      {channelOwner && (
        <div className="l-channel-options__detail">
          <span>Created by</span>
          <p>
            <Avatar src={channelOwner.avatar} size="large" />
            <span>{channelOwner.name}</span>
          </p>
        </div>
      )}
      <div className="l-channel-options__detail">
        {channelDetails.scope === 'admin' ? (
          <Form onFinish={updateChannelPicture} form={pictureForm}>
            <Form.Item name="file-upload">
              <ImgCrop modalTitle="Edit Channel Picture">
                <Upload
                  name="file-upload"
                  accept=".png,.jpg,.jpeg,.jfif"
                  maxCount={1}
                  customRequest={dummyRequest}
                  onChange={uploadChangeHandler}
                  showUploadList={{ showRemoveIcon: true }}
                  onRemove={() => {
                    setUploadPreview('');
                    setUploadFile('');
                  }}
                >
                  <div className="img-cont">
                    <div className="img-cont__overlay">
                      <UploadOutlined />
                    </div>
                    {!isUploading && !uploadFile ? (
                      <>
                        {channelDetails.icon && (
                          <img src={channelDetails.icon} alt="" />
                        )}
                      </>
                    ) : (
                      <img src={uploadPreview} alt="" />
                    )}
                  </div>
                </Upload>
              </ImgCrop>
            </Form.Item>
            {!!uploadFile && (
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  className="l-channel-options__confirm proceed"
                  style={{ marginTop: '0' }}
                >
                  <p>Update channel picture?</p>
                  {pictureValidationError && (
                    <div className="error">{pictureValidationError}</div>
                  )}
                  <div>
                    <button
                      type="button"
                      style={{ opacity: 0.3 }}
                      onClick={() => {
                        setPictureValidationError('');
                        setUploadPreview('');
                        setUploadFile('');
                        pictureForm.resetFields();
                      }}
                    >
                      <CloseSvg />
                    </button>
                    <button type="submit">
                      <CheckSvg />
                    </button>
                  </div>
                </div>
              </Form.Item>
            )}
          </Form>
        ) : (
          <div className="img-cont">
            {channelDetails.icon && <img src={channelDetails.icon} alt="" />}
          </div>
        )}
      </div>
      {!isUploading && !uploadFile && (
        <>
          <ul className="l-channel-options__switch">
            <li className="l-channel-options__switch-field">
              Send notifications to app
              <Switch
                checked={settings.appNotify}
                onChange={(checked: boolean) => {
                  updateSettings({
                    ...settings,
                    ...{ appNotify: checked },
                  });
                }}
              />
            </li>
            <li className="l-channel-options__switch-field">
              Send notifications to email
              <Switch
                checked={settings.emailNotify}
                onChange={(checked: boolean) => {
                  updateSettings({
                    ...settings,
                    ...{ emailNotify: checked },
                  });
                }}
              />
            </li>
          </ul>
          {!isConfirmVisible && (
            <button
              className="l-channel-options__button"
              onClick={() => {
                setIsConfirmVisible(true);
              }}
            >
              Leave channel
            </button>
          )}
          {isConfirmVisible && (
            <div className="l-channel-options__confirm">
              <p>
                You are about to leave this channel.
                <br />
                Are you sure?
              </p>
              <div>
                <button
                  type="button"
                  style={{ opacity: 0.3 }}
                  onClick={() => {
                    setIsConfirmVisible(false);
                  }}
                >
                  <CloseSvg />
                </button>
                <button type="submit" onClick={handleLeaveChannel}>
                  <CheckSvg />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChannelOptionsModal;
