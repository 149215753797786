import React from 'react';

import ConversationListItemSkeleton from '../skeletons/ConversationListItem';
import ListItem from './ListItem';

type Props = {
  setIsChannelListLoading: (v: boolean) => void;
  conversations: any[];
  isChannelListLoading: boolean;
  selectedConversation: any;
};

const ChannelList = ({
  conversations,
  selectedConversation,
  isChannelListLoading,
}: Props) => {
  return (
    <div className="l-chat-panel__section">
      {isChannelListLoading && <ConversationListItemSkeleton />}
      {!isChannelListLoading &&
        conversations &&
        conversations.map((conversation) => (
          <ListItem
            conversation={conversation}
            key={conversation.conversationId}
            selectedConversation={selectedConversation}
          />
        ))}
    </div>
  );
};

export default ChannelList;
