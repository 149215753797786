import { Avatar, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import EditIcon from '../../../foundation/assets/svgs/EditIcon';
import Privacy from '../../../foundation/components/static_content/privacy/Privacy';
import Terms from '../../../foundation/components/static_content/terms/Terms';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
// import { useIsLightPlanId } from '../../../foundation/custom_hooks/useIsLightPlanId';
import useLogout from '../../../foundation/custom_hooks/useLogout';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import { selectIsSubscribed, selectUserProfile } from '../redux/selectors';
// import Settings from '../settings/Settings';
import ProfileEditForm from './ProfileEditForm';

const ProfileSiderConfigs = {
  isVisible: true,
  view: 'profile',
  showBackButton: false,
  isClosable: true,
  headerTitle: 'Profile',
};

type Props = {
  closeProfilePopover?: () => void;
};

const Profile = ({ closeProfilePopover }: Props) => {
  const navigate = useNavigate();
  const [isMobileView, isDesktopView] = useBreakpoint();
  const [isProfileModalVisible, setProfileModalVisibility] = useState(false);
  const [isTermsVisible, setTermsVisibility] = useState(false);
  const [isPrivacyModalVisible, setPrivacyModalVisibility] = useState(false);
  // const [isSettingsModalVisible, setSettingsModalVisibility] = useState(false);

  // const isLightPlan = useIsLightPlanId();

  const dispatch = useDispatch();

  const logoutUser = useLogout();

  const profile = useSelector(selectUserProfile);

  const isSubscribed = useSelector(selectIsSubscribed);

  const closeModal = () => {
    setProfileModalVisibility(false);
    setPrivacyModalVisibility(false);
    setTermsVisibility(false);
    // setSettingsModalVisibility(false);
  };

  const sharedModalProps = {
    wrapClassName: '',
    closable: true,
    footer: null as any,
    maskClosable: false,
    centered: true,
    open: true,
  };

  const modalOpts = {
    title: 'Profile',
    onCancel: () => {
      closeModal();
    },
    ...sharedModalProps,
  };

  const privacyModalOpts = {
    title: 'Privacy Policy',
    onCancel: () => {
      closeModal();
    },
    ...sharedModalProps,
  };

  const termsModalOpts = {
    title: 'Terms & Conditions',
    onCancel: () => {
      closeModal();
    },
    ...sharedModalProps,
  };

  // const settingsModalOpts = {
  //   title: 'Account Settings',
  //   onCancel: () => {
  //     closeModal();
  //   },
  //   ...sharedModalProps,
  // };

  const handleEditProfileBtnClick = () => {
    if (closeProfilePopover) {
      closeProfilePopover();
    }

    if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'profile_edit',
        showBackButton: true,
        isClosable: false,
        headerTitle: 'Edit Profile',
        parentConfig: ProfileSiderConfigs,
      };
      dispatch(setSiderMobileContentValues(data));
    } else {
      setProfileModalVisibility((v) => !v);
    }
  };

  const handlePrivacyClick = () => {
    closeModal();
    if (closeProfilePopover) {
      closeProfilePopover();
    }
    if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'privacy',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Privacy Policy',
        parentConfig: undefined as any,
      };
      dispatch(setSiderMobileContentValues(data));
    } else {
      setPrivacyModalVisibility((v) => !v);
    }
  };

  const handleTermsClick = () => {
    closeModal();
    if (closeProfilePopover) {
      closeProfilePopover();
    }

    if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'terms',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Terms & Conditions',
        parentConfig: undefined as any,
      };
      dispatch(setSiderMobileContentValues(data));
    } else {
      setTermsVisibility((v) => !v);
    }
  };

  const handleSettingsClick = () => {
    closeModal();

    if (closeProfilePopover) {
      closeProfilePopover();
    }

    // if (isLightPlan) {
    //   if (isMobileView) {
    //     const data = {
    //       isVisible: true,
    //       view: 'settings',
    //       showBackButton: false,
    //       isClosable: true,
    //       headerTitle: 'Account Settings',
    //       parentConfig: undefined as any,
    //     };
    //     dispatch(setSiderMobileContentValues(data));
    //   } else {
    //     setSettingsModalVisibility((v) => !v);
    //   }
    // } else {
    navigate('/account');
    // }
  };

  const handleLogout = () => {
    logoutUser(true);
  };

  const ProfileContent = () => {
    return (
      <>
        <Avatar
          src={profile?.picture}
          size={100}
          className="c-profile-popover__usr-avatar"
        />
        <div className="c-profile-popover__name">{`${profile?.firstName} ${profile?.lastName}`}</div>
        <div className="c-profile-popover__handle">{profile?.handle}</div>
        <div className="c-profile-popover__followers">
          {`${profile?.followers} Followers`}
        </div>

        <Button
          icon={<EditIcon />}
          className="c-profile-popover__edit-btn"
          onClick={handleEditProfileBtnClick}
        >
          Edit Profile
        </Button>

        <div className="c-profile-popover__list">
          {isSubscribed && (
            <div
              className="c-profile-popover__list-item"
              onClick={handleSettingsClick}
            >
              Account Settings
            </div>
          )}
          <div
            className="c-profile-popover__list-item"
            onClick={handlePrivacyClick}
          >
            Privacy Policy
          </div>
          <div
            className="c-profile-popover__list-item"
            onClick={handleTermsClick}
          >{`Terms & Conditions`}</div>
          <div
            className="c-profile-popover__list-item primary-color"
            onClick={handleLogout}
          >
            Logout
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isProfileModalVisible && (
        <Modal {...modalOpts} width={400}>
          <ProfileEditForm handleClose={closeModal} />
        </Modal>
      )}
      {isPrivacyModalVisible && (
        <Modal {...privacyModalOpts} width={700} className="c-privacy-modal">
          <Privacy />
        </Modal>
      )}
      {isTermsVisible && (
        <Modal {...termsModalOpts} width={700} className="c-privacy-modal">
          <Terms privacyPolicyCallback={handlePrivacyClick} />
        </Modal>
      )}
      {/* {isSettingsModalVisible && (
        <Modal {...settingsModalOpts} width={400} className="c-privacy-modal">
          <Settings />
        </Modal>
      )} */}

      {isDesktopView && (
        <div className="c-profile-popover">
          <ProfileContent />
        </div>
      )}
      {isMobileView && (
        <div className="c-profile-mobile-view">
          <ProfileContent />
        </div>
      )}
    </>
  );
};

export default Profile;
