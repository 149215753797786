import React from 'react';

function Filter() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53855 1.77199C7.70964 1.36214 8.29028 1.36214 8.46137 1.77198L9.92909 5.28791C9.99746 5.4517 10.1475 5.56697 10.3234 5.59078L14.1917 6.11433C14.6251 6.17299 14.7796 6.72065 14.4407 6.9972L11.4539 9.43455C11.3063 9.55504 11.2399 9.74899 11.2829 9.93466L12.2378 14.0612C12.3412 14.508 11.8388 14.8467 11.4635 14.5832L8.2872 12.3539C8.11482 12.233 7.88511 12.233 7.71272 12.3539L4.53643 14.5833C4.16107 14.8467 3.65868 14.508 3.76206 14.0613L4.71693 9.93465C4.75989 9.74899 4.69358 9.55504 4.54593 9.43455L1.55916 6.99719C1.22028 6.72065 1.37478 6.17299 1.80823 6.11433L5.67649 5.59078C5.85238 5.56697 6.00246 5.4517 6.07084 5.28791L7.53855 1.77199Z"
        stroke="#651FFF"
        strokeWidth="1.5"
        strokeMiterlimit="3.3292"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Filter;
