import React from 'react';

import DevicesImg from '../../../../foundation/assets/svgs/Devices';
import FilterIcon from '../../../../foundation/assets/svgs/Filter';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import useSearch from '../../../../foundation/custom_hooks/useSearch';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectIsFilterModalActive } from '../../redux/selectors';
import {
  resetSuggestions,
  setIsFilterModalActive,
  setIsFirstAttempt,
  setKeyword,
} from '../../redux/slice';
import FilterModal from '../filter/filter_modal/FilterModal';
import SearchField from './search_field/SearchField';

const Search = () => {
  const dispatch = useAppDispatch();

  const isFilterModalActive = useAppSelector(selectIsFilterModalActive);

  const [isMobileView, isDesktopView] = useBreakpoint();

  const fetchResults = useSearch();
  const fetchResultsOptions = {
    afterCb: (hasNoResults: boolean) => {
      if (!hasNoResults) {
        dispatch(setIsFirstAttempt(false));
      }
    },
  };

  return (
    <>
      {isFilterModalActive && (
        <FilterModal searchHandlerOptions={fetchResultsOptions} init />
      )}
      <div className="l-search">
        <div className="l-search__bg">
          <DevicesImg />
        </div>
        <div className="l-search__heading">
          <h1>Explore Suburbs</h1>
          <h4 style={{ marginBottom: '6px' }}>
            Get advanced insight into suburb profiles and choose the right
            investment location for you
          </h4>
          <SearchField
            placeholder={
              isDesktopView
                ? 'Search for a State, LGA, Postcode or Suburb'
                : 'State, LGA, Postcode, or Suburb'
            }
            isDisabled={isFilterModalActive || isMobileView}
          >
            {isDesktopView && (
              <div className="l-search-field__button-group">
                <button
                  onClick={() => {
                    dispatch(setIsFilterModalActive(true));
                    dispatch(setKeyword(undefined));
                    dispatch(resetSuggestions());
                  }}
                  className="l-search-field__button l-search-field__button--light"
                >
                  <FilterIcon />
                  <span>Filter</span>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchResults(fetchResultsOptions);
                  }}
                  className="l-search-field__button"
                >
                  Search
                </button>
              </div>
            )}
          </SearchField>
        </div>
      </div>
    </>
  );
};

export default Search;
