import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '../../../store/hooks';
import { selectUserFeatures } from '../redux/selectors';

interface Props {
  requiredFeature: any;
  redirectRoute?: string;
  renderNoAccessComponent?: any;
  children: any;
}

const AccessControl = ({
  requiredFeature,
  redirectRoute,
  renderNoAccessComponent,
  children,
}: Props) => {
  const location = useLocation();
  const userFeatures = useAppSelector(selectUserFeatures);
  const [hasAccess, setHasAccess] = useState<any>(undefined);
  const [proceedRedirect, setProceedRedirect] = useState<boolean>(false);

  useEffect(() => {
    const isIncluded = Array.isArray(requiredFeature)
      ? requiredFeature.find((feature) => {
          // Set access to the first matching feature
          return userFeatures.includes(feature);
        })
      : userFeatures.includes(requiredFeature);

    setHasAccess(!!isIncluded);
    setProceedRedirect(true);
  }, [userFeatures, location, requiredFeature]);

  useEffect(() => {
    if (redirectRoute && proceedRedirect) {
      setProceedRedirect(false);
    }
  }, [redirectRoute, proceedRedirect]);

  if (!hasAccess) {
    if (typeof hasAccess === 'boolean' && redirectRoute && proceedRedirect) {
      return <Navigate to={redirectRoute} replace={true} />;
    }

    if (renderNoAccessComponent) {
      return <>{renderNoAccessComponent()}</>;
    }

    return <></>;
  }

  return children;
};

export default AccessControl;
