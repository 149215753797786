import React from 'react';

function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        stroke="#FCFCFC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11 6l3.318-3.374a4.484 4.484 0 116.367 6.314l-4.005 4.005a4 4 0 01-5.548.104L10 12m2.356 4.813l-3.318 3.374a4.484 4.484 0 11-6.367-6.314l4.005-4.005a4 4 0 015.548-.105l1.132 1.05"
      ></path>
    </svg>
  );
}

export default LinkIcon;
