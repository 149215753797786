import React from 'react';

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FCFCFC"
        stroke="#FCFCFC"
        d="M23.5 12.073c0 5.574-3.915 10.226-9.125 11.326v-7.336h2.726l.064-.424.532-3.49.088-.576H14.375V9.81c0-.406.1-.745.298-.976.186-.217.522-.41 1.158-.41h2.013V4.53l-.416-.07-.084.492.084-.493h0-.005l-.013-.003-.049-.008a20.473 20.473 0 00-.811-.11c-.517-.06-1.21-.122-1.892-.122-1.473 0-2.745.451-3.65 1.362-.904.91-1.383 2.224-1.383 3.835v2.16H6.578v4.49h3.047V23.4C4.415 22.299.5 17.646.5 12.072.5 5.68 5.652.5 12 .5s11.5 5.179 11.5 11.573z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
