import { CometChat } from '@cometchat-pro/chat';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../store/hooks';
import {
  selectChannelsList,
  selectDirectMessagesList,
  selectSelectedConversation,
} from '../redux/selectors';
import { setChannelsList, setDirectMessagesList } from '../redux/slice';
import {
  attachMessageListeners,
  removeMessageListeners,
} from '../utils/controllers/messageController';
import * as enums from '../utils/enums';

const useMessagesUnreadCountListener = () => {
  const dispatch = useAppDispatch();

  const selectedConversation = useSelector(selectSelectedConversation);
  const channelLists = useSelector(selectChannelsList);

  const dmLists = useSelector(selectDirectMessagesList);

  const messageListenerId = 'message_listener_' + Date.now();

  const updateChannelsList = (message: any) => {
    dispatch(
      setChannelsList(
        channelLists.map((conversation: any) => {
          if (conversation.conversationId === message.conversationId) {
            return {
              ...conversation,
              ...{
                unreadMessageCount: conversation.unreadMessageCount + 1,
              },
            };
          }

          return conversation;
        }),
      ),
    );
  };

  const updateDirectMessagesList = (message: any) => {
    dispatch(
      setDirectMessagesList(
        dmLists.map((conversation: any) => {
          if (conversation.conversationId === message.conversationId) {
            return {
              ...conversation,
              ...{
                unreadMessageCount: conversation.unreadMessageCount + 1,
              },
            };
          }

          return conversation;
        }),
      ),
    );
  };

  const onMessageReceived = (message: any) => {
    if (message.getReceiverType() === CometChat.RECEIVER_TYPE.GROUP) {
      if (
        !selectedConversation ||
        selectedConversation.conversationId !== message.conversationId
      ) {
        updateChannelsList(message);
      }
    } else if (message.getReceiverType() === CometChat.RECEIVER_TYPE.USER) {
      if (
        !selectedConversation ||
        selectedConversation.conversationId !== message.conversationId
      ) {
        updateDirectMessagesList(message);
      }
    }
  };

  const messageListener = (key: string, message: any) => {
    switch (key) {
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
      case enums.CUSTOM_MESSAGE_RECEIVED:
        onMessageReceived(message);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ((dmLists && dmLists.length) || (channelLists && channelLists.length)) {
      attachMessageListeners(messageListenerId, messageListener);
    }

    return () => {
      removeMessageListeners(messageListenerId);
    };
  }, [dmLists, selectedConversation, channelLists]);
};

export default useMessagesUnreadCountListener;
