import { Button, Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import ArrowBackIcon from '../../../foundation/assets/svgs/ArrowBackIcon';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { PromoCodeDetailsType } from '../../billing/helpers/types';
import {
  updateSubscription,
  UpdateSubscriptionBody,
} from '../../billing/redux/async_thunks';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import { getInvoice } from '../redux/async_thunks';
import { selectAccountCurrentPlan } from '../redux/selectors';
import { Plan } from '../redux/types';
import { UpdateOrderSteps } from '../types';
import OrderSummary from './order_summary/OrderSummary';
import PaymentElements from './payment_elements/PaymentElements';
import PlanElements from './plan_elements/PlanElements';

const UpdatePlan = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isUpdatePlanLoading, setUpdatePlanLoading] = useState(false);

  const [updateStep, setUpdateStep] = useState<UpdateOrderSteps>(1);

  const [promoCodeDetails, setPromoCodeDetails] = useState<
    PromoCodeDetailsType | undefined
  >(undefined);

  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(undefined);

  const [invoiceDetails, setInvoiceDetails] = useState<any>(undefined);

  const [additionalSeats, setAdditionalSeats] = useState(0);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  useEffect(() => {
    if (currentPlan) {
      setSelectedPlan(currentPlan);
    }
  }, [currentPlan]);

  /**
   * If a user directly tries visiting the update-plan page we will redirect the
   * user back to the Account page.
   */
  useEffect(() => {
    if (!currentPlan) {
      navigate('/account', { replace: true });
    }

    if (currentPlan && currentPlan.additionalUsers) {
      setAdditionalSeats(currentPlan.additionalUsers);
    }
  }, [currentPlan]);

  const subscriptionFrequency = useMemo(
    () => currentPlan?.frequency,
    [currentPlan],
  );

  const handleInvoice = (plan: any, promoCode?: string, delPromo?: boolean) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (currentPlan) {
          const options = {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          };

          const data: any = {
            userId: user.userId,
            subscriptionId: currentPlan.subscriptionId,
            subscriptionPlanTypeId: plan.subscriptionPlanId,
            subscriptionFrequency: subscriptionFrequency,
          };

          if (additionalSeats) {
            data.additionalUsers = additionalSeats;
          }

          if (promoCodeDetails) {
            data.promoCode = promoCodeDetails?.promoCode;
          }

          if (promoCode) {
            data.promoCode = promoCode;
          }

          if (delPromo) {
            delete data.promoCode;
          }

          console.log(data);
          const invoice = await dispatch(
            getInvoice({ data, options }),
          ).unwrap();

          console.log(invoice);
          setInvoiceDetails(invoice);

          resolve(invoice);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handlePlanCardClick = (plan: any) => async () => {
    if (
      currentPlan &&
      currentPlan.subscriptionPlanId === plan.subscriptionPlanId
    ) {
      setSelectedPlan(currentPlan);
      setInvoiceDetails(undefined);
      setPromoCodeDetails(undefined);
    } else if (currentPlan) {
      try {
        setUpdatePlanLoading(true);
        await handleInvoice(plan);
        setUpdatePlanLoading(false);
      } catch (error) {
        console.log(error);
        setUpdatePlanLoading(false);
      }

      let planRate = 0;
      for (const rate of plan.planRates) {
        if (rate.frequency === subscriptionFrequency) {
          planRate = rate.subscriptionAmount;
          break;
        }
      }

      const updatedPlan: any = {
        subscriptionId: currentPlan.subscriptionId,
        subscriptionPlanId: plan.subscriptionPlanId,
        subscriptionPlan: plan.subscriptionPlan,
        subscriptionPlanCost: planRate,
        frequency: subscriptionFrequency,
      };

      setSelectedPlan(updatedPlan);
    }
  };

  const onBackBtnClick = () => {
    if (updateStep === 1) {
      navigate('/account');
    } else {
      setUpdateStep(1);
    }
  };

  const stepHeading = useMemo(() => {
    if (updateStep === 1) {
      return `Upgrade Your Plan`;
    } else {
      return `Payment information`;
    }
  }, [updateStep]);

  const stepSubHeading = useMemo(() => {
    if (updateStep === 1) {
      return `Your rhomeo plan`;
    } else {
      return `Payment method`;
    }
  }, [updateStep]);

  const handleSubscription = async () => {
    if (isUpdatePlanLoading) {
      return;
    }
    try {
      if (selectedPlan) {
        setUpdatePlanLoading(true);
        const data: UpdateSubscriptionBody = {
          userId: user.userId,
          subscriptionId: selectedPlan?.subscriptionId,
          subscriptionPlanTypeId: selectedPlan?.subscriptionPlanId,
          subscriptionFrequency: selectedPlan?.frequency,
        };

        if (additionalSeats) {
          data.additionalUsers = additionalSeats;
        }

        if (promoCodeDetails && promoCodeDetails.promoCode) {
          data.promoCode = promoCodeDetails.promoCode;
        }

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        const subscription = await dispatch(
          updateSubscription({ data, options }),
        ).unwrap();

        console.log(subscription);
        setUpdatePlanLoading(false);

        navigate('/success', {
          state: {
            update: true,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setUpdatePlanLoading(false);
    }
  };

  return (
    <div className="l-update-plan">
      {isUpdatePlanLoading && <FullPageLoader />}
      <Button
        className="l-update-plan__back-btn"
        icon={<ArrowBackIcon />}
        onClick={onBackBtnClick}
      >
        Back
      </Button>

      <div className="l-update-plan__heading">{stepHeading}</div>

      <Divider />

      <div className="l-update-plan__sub-heading">{stepSubHeading}</div>
      <div className="l-update-plan__content-container">
        {updateStep === 1 && (
          <PlanElements
            handlePlanCardClick={handlePlanCardClick}
            subscriptionFrequency={subscriptionFrequency}
            selectedPlan={selectedPlan}
          />
        )}
        {updateStep === 2 && <PaymentElements />}
        <div className="l-update-plan__content-section">
          <OrderSummary
            selectedPlan={selectedPlan}
            invoice={invoiceDetails}
            setPromoCodeDetails={setPromoCodeDetails}
            promoCodeDetails={promoCodeDetails}
            handleInvoice={handleInvoice}
            setUpdateStep={setUpdateStep}
            updateStep={updateStep}
            handleSubscription={handleSubscription}
            upgradeType="plan-update"
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatePlan;
