import React, { useEffect, useRef, useState } from 'react';
import { useSize } from 'react-hook-size';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectIsOnboarded } from '../user/redux/selectors';
import Map from './common/map/Map';
import SearchForm from './form/search/Search';
import SearchLoader from './form/search/search_loader/SearchLoader';
import {
  selectActivePropertyId,
  selectActiveSuburbSscCode,
  selectHasActiveResultInView,
  selectIsFirstAttempt,
  selectIsMapSearchEnabled,
  selectIsSearchFeatureLoading,
} from './redux/selectors';
import {
  setActivePropertyId,
  setActiveSuburbSscCode,
  setIsFirstAttempt,
  setSearchTypeWithReset,
} from './redux/slice';
import Bar from './results/bar/Bar';
import List from './results/list/List';
import View from './view/View';

const Search = () => {
  const routeParams = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchContainerRef = useRef<any>(null);
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const isFirstAttempt = useAppSelector(selectIsFirstAttempt);
  const isSearchFeatureLoading = useAppSelector(selectIsSearchFeatureLoading);

  const hasActiveResultInView = useAppSelector(selectHasActiveResultInView);
  const isOnboarded = useAppSelector(selectIsOnboarded);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const [isMobileMapActive, setIsMobileMapActive] = useState(false);

  const { width } = useSize(searchContainerRef);

  const PROPERTY_ID = useAppSelector(selectActivePropertyId);
  const SSC_CODE = useAppSelector(selectActiveSuburbSscCode);

  useEffect(() => {
    // This doesn't include the sider width
    setIsLargeScreen(!!width && width >= 992);
  }, [width, isOnboarded]);

  useEffect(() => {
    if (routeParams && routeParams.sscCode) {
      dispatch(setActiveSuburbSscCode(routeParams.sscCode));
      dispatch(setIsFirstAttempt(false));
      navigate('/suburb-search');
    }

    if (location.pathname.includes('property')) {
      dispatch(setSearchTypeWithReset('property'));
    }

    if (routeParams && routeParams.propertyId) {
      dispatch(setActivePropertyId(routeParams.propertyId));
      dispatch(setIsFirstAttempt(false));
      navigate('/property-search');
    }
  }, []);

  return (
    <>
      <div
        className={`l-search-main${
          isFirstAttempt ? '' : ' l-search-main--padded'
        }`}
        ref={searchContainerRef}
      >
        {isOnboarded && (
          <>
            {isSearchFeatureLoading && <SearchLoader />}
            {hasActiveResultInView || !isFirstAttempt ? (
              <>
                {isLargeScreen && (
                  <>
                    {!isMapSearchEnabled ? (
                      <>
                        {!SSC_CODE && <List />}
                        {hasActiveResultInView && <View />}
                        {!PROPERTY_ID && <Map />}
                      </>
                    ) : (
                      <>
                        <List />
                        {hasActiveResultInView && <View />}
                        <Map />
                      </>
                    )}
                  </>
                )}
                {!isLargeScreen && (
                  <div className="l-search-main__tablet-mobile-wrapper">
                    <Bar
                      setIsMobileMapActive={setIsMobileMapActive}
                      isMobileMapActive={isMobileMapActive}
                    />
                    {!isMobileMapActive ? (
                      <>
                        {hasActiveResultInView ? (
                          <View isLargeScreen={isLargeScreen} />
                        ) : (
                          <List isLargeScreen={isLargeScreen} />
                        )}
                      </>
                    ) : (
                      <Map
                        isLargeScreen={isLargeScreen}
                        setIsMobileMapActive={setIsMobileMapActive}
                      />
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <SearchForm />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Search;
