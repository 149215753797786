import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  classNames?: string;
  message?: string;
  style?: any;
};

function SearchLoader({ classNames, message, style }: Props): JSX.Element {
  return (
    <div style={style || {}} className={`l-search-loader ${classNames || ''}`}>
      <div className="l-search-loader__spin-box">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {message && <p className="l-search-loader__message">{message}</p>}
    </div>
  );
}

SearchLoader.propTypes = {
  classNames: PropTypes.string,
};

export default SearchLoader;
