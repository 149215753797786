import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import ArrowRight from '../../../../foundation/assets/svgs/ArrowRight';
import { fetchPreviousMessages } from '../../utils/controllers/messageController';

type ReplyToThreadProps = {
  message: any;
  selectedConversation: any;
  handleReplyInThreadClick: () => void;
};

const ReplyToThread = ({
  message,
  handleReplyInThreadClick,
  selectedConversation,
}: ReplyToThreadProps) => {
  const [lastReply, setLastReply] = useState<any>(undefined);

  const replyCount = useMemo(() => {
    if (message.replyCount) {
      return message.replyCount;
    }
    return undefined;
  }, [message]);

  const fetchMessages = (parentMessageId: number) => {
    const promise = new Promise<any[]>((resolve, reject) => {
      fetchPreviousMessages(
        selectedConversation?.conversationType,
        selectedConversation?.conversationWith,
        parentMessageId,
        undefined,
      )
        .then((messageList) => {
          setLastReply(messageList[messageList.length - 1]);
        })
        .catch((error: any) => reject(error));
    });

    return promise;
  };

  const messageTime = useMemo(() => {
    if (lastReply) {
      const messageDate = lastReply?.sentAt * 1000;
      const messageSentDate = moment(messageDate).format('hh:mm A');
      return messageSentDate;
    }
    return null;
  }, [lastReply]);

  useEffect(() => {
    if (replyCount) {
      fetchMessages(message.id);
    }
  }, [replyCount]);

  if (replyCount) {
    return (
      <div
        className="chat-message__view-replies"
        onClick={handleReplyInThreadClick}
      >
        <div className="chat-message__replies-info-wrapper">
          <div className="chat-message__replies-count">
            {replyCount > 1 ? `${replyCount} replies` : `${replyCount} reply`}
          </div>
          <div className="chat-message__reply-to-th"> Reply to thread</div>
          <div className="chat-message__last-reply">
            Last reply at {messageTime}
          </div>
        </div>
        <div>
          <ArrowRight />
        </div>
      </div>
    );
  }
  return null;
};

export default ReplyToThread;
