import React from 'react';

function Map() {
  return (
    <svg
      width="812"
      height="934"
      viewBox="0 0 812 934"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3583_133120)">
        <rect width="812" height="934" rx="49.0232" fill="#EBECFE" />
        <path
          d="M695.461 794.42C1057.97 735.792 1021.61 371.295 807.872 361.843C343.736 341.324 470.724 175.547 329.376 152.854C212.743 134.129 217.224 270.099 161.678 329.614C98.353 397.451 -91.8116 438.008 -67.9889 556.995C-16.5332 814.015 404.612 841.453 695.461 794.42Z"
          fill="url(#paint0_linear_3583_133120)"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M9.40628 390.965L29.255 433.713C29.539 434.323 29.947 434.868 30.453 435.313C30.9591 435.758 31.5521 436.092 32.1944 436.295C32.8367 436.498 33.5142 436.565 34.1838 436.492C34.8535 436.419 35.5006 436.208 36.0841 435.871L90.3369 404.586C91.1983 404.081 91.8827 403.322 92.2966 402.413C92.7105 401.504 92.8338 400.49 92.6496 399.508C92.4655 398.527 91.9828 397.626 91.2676 396.929C90.5523 396.232 89.6393 395.773 88.6534 395.614L14.5519 384.13C13.684 383.995 12.7957 384.098 11.9819 384.428C11.168 384.759 10.4591 385.304 9.93078 386.005C9.40249 386.707 9.07466 387.539 8.98228 388.412C8.88991 389.286 9.03646 390.168 9.40628 390.965Z"
            fill="#E8ECF7"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M379.852 860.018C363.64 850.661 337.363 850.661 321.156 860.018C304.948 869.375 304.948 884.553 321.156 893.91C337.363 903.267 363.645 903.267 379.852 893.91C396.059 884.553 396.059 869.375 379.852 860.018ZM334.993 885.914C326.433 880.969 326.433 872.958 334.993 868.013C343.553 863.068 357.433 863.073 365.988 868.013C374.543 872.953 374.548 880.969 365.988 885.914C357.428 890.86 343.564 890.849 335.004 885.909L334.993 885.914Z"
            fill="#E8ECF7"
          />
        </g>
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M311.375 700.623C314.23 702.269 318.864 702.269 321.719 700.623C324.574 698.976 324.58 696.295 321.719 694.649C318.859 693.002 314.23 692.997 311.375 694.649C308.52 696.3 308.52 698.966 311.375 700.623Z"
            fill="#E8ECF7"
          />
          <path
            d="M336.685 715.231C339.54 716.877 344.174 716.877 347.029 715.231C349.884 713.584 349.89 710.903 347.029 709.257C344.169 707.61 339.54 707.605 336.685 709.257C333.83 710.909 333.83 713.579 336.685 715.231Z"
            fill="#E8ECF7"
          />
          <path
            d="M362.003 729.844C364.858 731.491 369.486 731.491 372.347 729.844C375.207 728.198 375.202 725.517 372.347 723.87C369.491 722.224 364.858 722.218 362.003 723.87C359.148 725.522 359.142 728.192 362.003 729.844Z"
            fill="#E8ECF7"
          />
          <path
            d="M392.485 745.687C396.525 745.687 399.8 743.797 399.8 741.465C399.8 739.134 396.525 737.243 392.485 737.243C388.445 737.243 385.171 739.134 385.171 741.465C385.171 743.797 388.445 745.687 392.485 745.687Z"
            fill="#E8ECF7"
          />
          <path
            d="M286.064 715.231C288.919 716.877 293.553 716.877 296.408 715.231C299.263 713.584 299.263 710.903 296.408 709.257C293.553 707.61 288.919 707.605 286.064 709.257C283.209 710.909 283.203 713.579 286.064 715.231Z"
            fill="#E8ECF7"
          />
          <path
            d="M311.375 729.844C314.23 731.491 318.864 731.491 321.719 729.844C324.574 728.198 324.58 725.517 321.719 723.87C318.859 722.224 314.23 722.218 311.375 723.87C308.52 725.522 308.52 728.192 311.375 729.844Z"
            fill="#E8ECF7"
          />
          <path
            d="M336.686 744.458C339.546 746.104 344.174 746.104 347.035 744.458C349.895 742.811 349.89 740.13 347.035 738.484C344.18 736.837 339.551 736.832 336.691 738.484C333.831 740.135 333.831 742.806 336.686 744.458Z"
            fill="#E8ECF7"
          />
          <path
            d="M372.347 753.102C369.486 751.45 364.858 751.445 362.003 753.102C359.148 754.759 359.142 757.419 362.003 759.071C364.863 760.723 369.486 760.718 372.347 759.071C375.207 757.425 375.202 754.749 372.347 753.102Z"
            fill="#E8ECF7"
          />
          <path
            d="M260.748 729.844C263.609 731.491 268.237 731.491 271.098 729.844C273.958 728.198 273.953 725.517 271.098 723.87C268.242 722.224 263.609 722.218 260.748 723.87C257.888 725.522 257.893 728.192 260.748 729.844Z"
            fill="#E8ECF7"
          />
          <path
            d="M286.064 744.458C288.919 746.104 293.553 746.104 296.408 744.458C299.263 742.811 299.263 740.13 296.408 738.484C293.553 736.837 288.919 736.832 286.064 738.484C283.209 740.135 283.203 742.806 286.064 744.458Z"
            fill="#E8ECF7"
          />
          <path
            d="M311.375 759.071C314.23 760.718 318.864 760.718 321.719 759.071C324.574 757.425 324.58 754.749 321.719 753.102C318.859 751.456 314.23 751.445 311.375 753.102C308.52 754.759 308.52 757.419 311.375 759.071Z"
            fill="#E8ECF7"
          />
          <path
            d="M347.035 767.71C344.175 766.064 339.547 766.064 336.686 767.71C333.826 769.357 333.831 772.038 336.686 773.684C339.541 775.331 344.175 775.336 347.035 773.684C349.896 772.033 349.89 769.362 347.035 767.71Z"
            fill="#E8ECF7"
          />
          <path
            d="M235.437 744.458C238.293 746.104 242.926 746.104 245.781 744.458C248.636 742.811 248.642 740.13 245.781 738.484C242.921 736.837 238.293 736.832 235.437 738.484C232.582 740.135 232.582 742.806 235.437 744.458Z"
            fill="#E8ECF7"
          />
          <path
            d="M260.748 759.071C263.609 760.718 268.237 760.718 271.098 759.071C273.958 757.424 273.953 754.749 271.098 753.097C268.242 751.445 263.609 751.445 260.748 753.097C257.888 754.749 257.893 757.419 260.748 759.071Z"
            fill="#E8ECF7"
          />
          <path
            d="M286.064 773.685C288.919 775.336 293.553 775.336 296.408 773.685C299.263 772.033 299.263 769.362 296.408 767.71C293.553 766.059 288.919 766.064 286.064 767.71C283.209 769.357 283.203 772.033 286.064 773.685Z"
            fill="#E8ECF7"
          />
          <path
            d="M321.719 782.324C318.864 780.677 314.23 780.677 311.375 782.324C308.52 783.97 308.52 786.651 311.375 788.298C314.23 789.945 318.864 789.95 321.719 788.298C324.574 786.646 324.58 783.976 321.719 782.324Z"
            fill="#E8ECF7"
          />
          <path
            d="M220.47 753.097C217.615 751.445 212.981 751.445 210.126 753.097C207.271 754.749 207.266 757.419 210.126 759.071C212.986 760.723 217.615 760.718 220.47 759.071C223.325 757.424 223.33 754.749 220.47 753.097Z"
            fill="#E8ECF7"
          />
          <path
            d="M245.781 767.71C242.926 766.064 238.293 766.064 235.437 767.71C232.582 769.357 232.582 772.033 235.437 773.684C238.293 775.336 242.926 775.336 245.781 773.684C248.636 772.033 248.663 769.362 245.781 767.71Z"
            fill="#E8ECF7"
          />
          <path
            d="M271.098 782.324C268.237 780.677 263.609 780.677 260.748 782.324C257.888 783.97 257.893 786.651 260.748 788.298C263.603 789.945 268.237 789.95 271.098 788.298C273.958 786.646 273.953 783.976 271.098 782.324Z"
            fill="#E8ECF7"
          />
          <path
            d="M296.408 796.937C293.553 795.291 288.919 795.291 286.064 796.937C283.209 798.584 283.203 801.265 286.064 802.911C288.924 804.558 293.553 804.563 296.408 802.911C299.263 801.26 299.263 798.589 296.408 796.937Z"
            fill="#E8ECF7"
          />
        </g>
        <path
          d="M617.778 408.746L601.328 418.245L584.878 408.746L601.328 399.252L617.778 408.746Z"
          fill="#F1F1F6"
        />
        <path
          d="M642.657 423.111L626.207 432.605L609.757 423.111L626.207 413.612L642.657 423.111Z"
          fill="#F1F1F6"
        />
        <path
          d="M637.738 361.085L597.112 384.538L593.502 382.453L634.123 359L637.738 361.085Z"
          fill="#CED3F8"
        />
        <path
          d="M668.632 371.972L621.99 398.903L618.38 396.819L665.017 369.887L668.632 371.972Z"
          fill="#F1F1F6"
        />
        <path
          d="M677.262 395.716L646.869 413.263L643.253 411.179L673.647 393.631L677.262 395.716Z"
          fill="#F1F1F6"
        />
        <path
          d="M560 394.386L576.45 403.885L592.9 394.386L576.45 384.886L560 394.386ZM586.176 393.404V395.367H578.155V400.001H574.751V395.367H566.724V393.42H574.751V388.786H578.155V393.42L586.176 393.404Z"
          fill="#CED3F8"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M232.354 511.799L145.518 561.935C114.017 580.121 62.7615 580.121 31.2548 561.935L-84.1485 495.327L-107 508.521L8.40318 575.123C52.5073 600.587 124.266 600.587 168.37 575.123L255.206 524.987L232.354 511.799Z"
          fill="url(#paint1_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M492.066 393.012L600.255 330.537C631.757 312.351 683.012 312.351 714.514 330.537L829.938 397.166L852.79 383.972L737.392 317.343C693.287 291.879 621.529 291.879 577.425 317.343L469.236 379.819L492.066 393.012Z"
          fill="url(#paint2_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M519.293 378.631C478.799 355.247 423.686 345.478 370.937 349.267C408.983 351.996 445.799 361.764 475.01 378.631C544.72 418.877 544.71 484.129 475.01 524.375C445.799 541.263 408.977 551.011 370.921 553.739C423.696 557.528 478.794 547.754 519.293 524.375C588.999 484.129 589.004 418.877 519.293 378.631Z"
          fill="url(#paint3_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M277.932 378.631C307.137 361.743 343.958 351.996 382.009 349.267C329.234 345.478 274.142 355.247 233.643 378.631C163.932 418.877 163.932 484.129 233.643 524.375C274.142 547.754 329.255 557.528 382.004 553.739C343.953 551.011 307.137 541.242 277.932 524.375C208.216 484.129 208.216 418.877 277.932 378.631Z"
          fill="url(#paint4_linear_3583_133120)"
        />
        <path
          d="M485.438 378.811C415.389 338.374 301.78 338.374 231.753 378.811C161.726 419.247 161.705 484.836 231.753 525.277C301.802 565.719 415.411 565.724 485.433 525.277C555.455 484.831 555.497 419.263 485.438 378.811ZM464.513 513.197C406.022 546.973 311.185 546.973 252.694 513.197C194.204 479.421 194.193 424.672 252.694 390.896C311.196 357.12 406.027 357.12 464.518 390.896C523.008 424.672 523.003 479.432 464.513 513.197Z"
          fill="#A59BA5"
        />
        <path
          d="M252.684 349.7C311.18 315.924 406.017 315.924 464.508 349.7C502.902 371.865 516.095 403.071 504.089 431.454C497.798 416.592 484.61 402.507 464.508 390.901C406.017 357.125 311.18 357.125 252.684 390.901C232.582 402.512 219.394 416.592 213.103 431.454C201.097 403.066 214.296 371.865 252.684 349.7Z"
          fill="#34318F"
        />
        <g style={{ mixBlendMode: 'screen' }}>
          <path
            d="M464.823 362.54C406.343 328.764 311.517 328.764 253.031 362.54C194.546 396.316 194.546 451.054 253.031 484.82C311.517 518.586 406.338 518.596 464.823 484.82C523.308 451.044 523.314 396.311 464.823 362.54Z"
            fill="#686A6D"
          />
        </g>
        <g style={{ mixBlendMode: 'screen' }}>
          <path
            d="M268.078 368.583C280.824 360.471 302.013 359.553 315.412 366.535C328.812 373.517 329.334 385.756 316.594 393.862C303.855 401.968 282.66 402.892 269.261 395.91C255.861 388.928 255.333 376.694 268.078 368.583Z"
            fill="#A59BA5"
          />
        </g>
        <g style={{ mixBlendMode: 'screen' }}>
          <path
            d="M332.306 353.711C337.583 350.36 346.349 349.98 351.901 352.872C357.453 355.764 357.659 360.825 352.392 364.181C347.125 367.538 338.338 367.923 332.792 365.031C327.245 362.139 327.023 357.057 332.306 353.711Z"
            fill="#A59BA5"
          />
        </g>
        <path
          d="M464.824 368.667C406.344 334.891 311.517 334.891 253.032 368.667C225.135 384.774 210.58 405.647 209.292 426.72C207.883 403.583 222.438 380.183 253.032 362.519C311.517 328.743 406.344 328.743 464.824 362.519C495.433 380.183 509.978 403.583 508.569 426.72C507.26 405.641 492.721 384.769 464.824 368.667Z"
          fill="#F7F7FC"
        />
        <path
          d="M485.438 337.609C415.39 297.168 301.781 297.168 231.754 337.609C161.726 378.051 161.705 443.608 231.775 484.076C301.844 524.544 415.432 524.528 485.454 484.076C555.476 443.624 555.497 378.061 485.438 337.609ZM464.513 471.996C406.022 505.772 311.186 505.772 252.695 471.996C194.204 438.22 194.194 383.465 252.695 349.7C311.196 315.934 406.028 315.924 464.518 349.7C523.009 383.476 523.004 438.225 464.513 471.996Z"
          fill="#807DFB"
        />
        <path
          d="M231.774 484.076C301.828 524.528 415.432 524.528 485.454 484.076C520.032 464.111 537.511 438.035 537.96 411.869V451.028C538.424 477.875 520.945 504.801 485.464 525.288C415.421 565.729 301.839 565.729 231.785 525.288C196.315 504.806 178.836 477.88 179.3 451.033V412.054C179.791 438.151 197.259 464.158 231.774 484.076Z"
          fill="url(#paint5_linear_3583_133120)"
        />
        <path
          d="M485.433 489.586C489.856 487.031 493.993 484.392 497.846 481.669L528.017 499.085L501.873 514.173L471.696 496.758C476.438 494.524 481.018 492.133 485.433 489.586Z"
          fill="#F7F7FC"
        />
        <path
          d="M501.867 544.356L471.696 526.929V496.737L501.867 514.163V544.356Z"
          fill="#D0D4F6"
        />
        <path
          d="M490.515 535.817C490.515 520.237 501.45 501.291 514.94 493.501C521.964 489.454 528.265 489.243 532.72 492.14V492.113L706.629 592.529L673.381 652.898L499.466 552.488C494.009 550.467 490.515 544.625 490.515 535.817Z"
          fill="url(#paint6_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M512.945 560.241L521.452 565.149C517.758 562.336 515.505 557.233 515.505 550.203C515.505 534.619 526.445 515.678 539.94 507.888C546.953 503.835 553.265 503.629 557.72 506.521L546.193 499.866C541.745 496.969 535.433 497.18 528.419 501.228C514.924 509.023 503.989 527.964 503.989 543.548C503.968 552.367 507.467 558.209 512.945 560.241Z"
          fill="url(#paint7_linear_3583_133120)"
        />
        <path
          d="M669.375 650.576C673.228 653.248 677.586 655.106 682.182 656.037C686.777 656.967 691.515 656.951 696.104 655.988C700.693 655.026 705.038 653.137 708.872 650.439C712.707 647.74 715.951 644.287 718.405 640.292C720.86 636.297 722.475 631.844 723.15 627.203C723.825 622.563 723.546 617.834 722.331 613.305C721.116 608.776 718.991 604.543 716.084 600.863C713.177 597.184 709.551 594.136 705.426 591.906C701.067 589.795 695.261 590.238 688.86 593.943C675.365 601.733 664.425 620.679 664.425 636.258C664.425 643.04 666.494 648.059 669.95 650.935L669.375 650.576Z"
          fill="url(#paint8_linear_3583_133120)"
        />
        <path
          d="M243.56 164.622C243.56 141.523 259.783 113.43 279.78 101.883C299.776 90.3361 316.005 99.6984 316.005 122.798C316.005 145.898 299.787 173.99 279.78 185.537C259.773 197.084 243.56 187.722 243.56 164.622Z"
          fill="#00ADD2"
        />
        <path
          d="M296.979 94.5106C290.409 90.6211 281.3 91.1172 271.22 96.9382C251.213 108.491 235 136.578 235 159.677C235 171.726 239.438 179.99 246.5 183.547V183.584L253.846 187.806C247.513 183.933 243.587 175.932 243.587 164.585C243.587 141.486 259.81 113.394 279.806 101.846C289.802 96.078 298.847 95.5133 305.402 99.3026L297.011 94.4631L296.979 94.5106ZM305.423 99.3606C305.47 99.388 305.514 99.4198 305.555 99.4556L305.423 99.3606ZM255.055 188.551V188.514C254.891 188.429 254.717 188.366 254.527 188.281L255.055 188.551Z"
          fill="#007E9A"
        />
        <path
          d="M306.242 126.455C306.242 117.98 303.408 111.921 298.811 108.786C293.797 106.364 287.132 106.939 279.78 111.182C264.227 120.154 251.614 142.008 251.614 159.973C251.614 168.454 254.448 174.512 259.05 177.647C264.064 180.07 270.729 179.489 278.049 175.251C293.639 166.269 306.242 144.42 306.242 126.455Z"
          fill="white"
        />
        <path
          d="M298.811 108.786C303.407 111.921 306.241 117.98 306.241 126.455C306.241 144.399 293.633 166.269 278.07 175.251C270.729 179.473 264.064 180.07 259.071 177.647C264.222 181.152 271.589 180.983 279.801 176.238C295.37 167.266 307.978 145.412 307.978 127.448C307.957 117.959 304.415 111.483 298.811 108.786Z"
          fill="#3BC9E9"
        />
        <path
          d="M294.868 150.067L279.035 140.937V121.246C279.035 119.996 278.159 119.489 277.078 120.112C276.507 120.483 276.032 120.984 275.692 121.574C275.352 122.164 275.156 122.825 275.12 123.505V144.135C275.117 144.401 275.169 144.665 275.273 144.911C275.358 145.115 275.506 145.286 275.695 145.402L292.055 154.859C292.82 155.302 294.061 154.59 294.826 153.276C295.591 151.962 295.633 150.505 294.868 150.067Z"
          fill="url(#paint9_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M388.696 467.948H360.329L457.572 395.656L485.944 411.8L388.696 467.948Z"
          fill="url(#paint10_linear_3583_133120)"
        />
        <path
          d="M457.572 395.656V411.8L432.198 426.456V410.307L457.572 395.656Z"
          fill="#BBC0E6"
        />
        <path
          d="M360.329 451.804L247.453 386.632L344.697 330.49L457.572 395.656L360.329 451.804Z"
          fill="#F6F6FB"
        />
        <path
          d="M385.703 437.154V453.297L360.329 467.948V451.804L385.703 437.154Z"
          fill="#BBC0E6"
        />
        <path
          d="M360.329 451.804V467.948L247.453 402.776V386.632L360.329 451.804Z"
          fill="#CED3F8"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M410.623 467.684H424.603L470.412 441.238L457.118 432.768L410.623 467.684Z"
          fill="url(#paint11_linear_3583_133120)"
        />
        <path
          d="M432.198 410.307L450.131 420.661L403.636 447.508L385.703 437.154L432.198 410.307Z"
          fill="#F6F6FB"
        />
        <path
          d="M450.132 420.661V428.731L403.637 455.577V447.503L450.132 420.661Z"
          fill="#BBC0E6"
        />
        <path
          d="M457.118 432.768L457.123 440.837L410.629 467.684L410.623 459.609L457.118 432.768Z"
          fill="#BBC0E6"
        />
        <path
          d="M450.132 428.731L457.119 432.768L410.624 459.609L403.637 455.577L450.132 428.731Z"
          fill="#F6F6FB"
        />
        <path
          d="M403.636 455.577V447.503L385.703 437.154V453.297L410.623 467.684V459.609L403.636 455.577Z"
          fill="#CED3F8"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M450.131 420.661L432.198 410.307L457.572 395.656L443.439 387.497L359.273 443.64H374.462L385.703 437.154L396.939 443.64H410.328L450.131 420.661Z"
          fill="url(#paint12_linear_3583_133120)"
        />
        <path
          d="M359.278 393.239L359.273 443.64L261.581 387.239V336.833L359.278 393.239Z"
          fill="#CED3F8"
        />
        <path
          d="M359.273 443.64L359.279 393.239L401.356 320.352L443.439 344.644V395.05L359.273 443.64Z"
          fill="#BBC0E6"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M343.035 393.72L341.261 390.342L320.357 380.631V406.813L343.035 419.907V393.72Z"
            fill="#F6F6FB"
          />
        </g>
        <path
          d="M339.82 391.176L317.143 378.082V404.264L339.82 417.358V391.176Z"
          fill="#E1E6FF"
        />
        <path
          d="M339.821 391.176L341.261 390.342V416.524L339.821 417.358V391.176Z"
          fill="#BBC0E6"
        />
        <path
          d="M317.143 378.082L318.589 377.249L341.261 390.342L339.82 391.176L317.143 378.082Z"
          fill="white"
        />
        <path
          d="M337.303 392.627L319.665 382.447V402.813L337.303 412.993V392.627Z"
          fill="#6B67E9"
        />
        <path
          d="M337.303 412.993V411.109L321.296 401.868L319.665 402.813L337.303 412.993Z"
          fill="white"
        />
        <path
          d="M319.665 402.813L321.296 401.868V383.386L319.665 382.447V402.813Z"
          fill="#BBC0E6"
        />
        <path
          d="M330.268 388.569L328.484 387.54V406.016L330.268 407.045V388.569Z"
          fill="#BBC0E6"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M303.168 370.704L301.395 367.321L280.491 357.611V383.793L303.168 396.886V370.704Z"
            fill="#F6F6FB"
          />
        </g>
        <path
          d="M299.954 368.155L277.276 355.062V381.249L299.954 394.337V368.155Z"
          fill="#E1E6FF"
        />
        <path
          d="M299.954 368.155L301.395 367.321V393.509L299.954 394.337V368.155Z"
          fill="#BBC0E6"
        />
        <path
          d="M277.276 355.062L278.717 354.233L301.395 367.321L299.954 368.155L277.276 355.062Z"
          fill="white"
        />
        <path
          d="M297.431 369.612L279.793 359.426V379.792L297.431 389.973V369.612Z"
          fill="#6B67E9"
        />
        <path
          d="M297.431 389.973V388.088L281.424 378.848L279.793 379.792L297.431 389.973Z"
          fill="white"
        />
        <path
          d="M279.793 379.792L281.424 378.848V360.371L279.793 359.426V379.792Z"
          fill="#BBC0E6"
        />
        <path
          d="M290.397 365.548L288.613 364.519V383.001L290.397 384.03V365.548Z"
          fill="#BBC0E6"
        />
        <path
          d="M303.667 258.182L253.829 344.504L356.513 403.789L406.351 317.467L303.667 258.182Z"
          fill="#651FFF"
        />
        <path
          d="M406.354 317.465L356.513 403.784L359.009 405.225L406.354 323.228L453.693 350.56L456.189 346.238L406.354 317.465Z"
          fill="#423EAF"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M346.682 283.019L328.459 290.819V331.413L382.749 303.838L346.682 283.019Z"
          fill="url(#paint13_linear_3583_133120)"
        />
        <path
          d="M328.459 285.272L346.682 274.749V299.843L328.459 331.413V285.272Z"
          fill="#BBC0E6"
        />
        <path
          d="M328.458 331.413L320.579 326.864V280.723L328.458 285.272V331.413Z"
          fill="#CED3F8"
        />
        <path
          d="M328.458 285.272L320.579 280.723L320.573 271.403L328.458 275.952V285.272Z"
          fill="#6B67E9"
        />
        <path
          d="M346.682 274.749L328.459 285.272V275.952L346.682 265.429V274.749Z"
          fill="#5652CC"
        />
        <path
          d="M346.681 265.429L328.458 275.952L320.573 271.403L338.802 260.879L346.681 265.429Z"
          fill="#807DFB"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M403.637 455.577H417.617L457.119 432.768L450.132 428.731L403.637 455.577Z"
          fill="url(#paint14_linear_3583_133120)"
        />
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M396.939 443.64L399.889 461.483L385.703 453.297V437.154L396.939 443.64Z"
            fill="#EFEFF3"
          />
        </g>
        <path
          d="M391.055 375.913L389.329 374.916V426.292L391.055 427.29V375.913Z"
          fill="#A1A9D6"
        />
        <path
          d="M418.207 360.239L416.482 359.242L389.329 374.916L391.055 375.913L418.207 360.239Z"
          fill="#EFEFF4"
        />
        <path
          d="M391.054 375.913V427.29L394.073 425.548V377.655L415.194 365.464V413.616L418.207 411.874V360.239L391.054 375.913Z"
          fill="#C5CBEA"
        />
        <path
          d="M415.194 413.616L413.236 412.486V366.593L415.194 365.464V413.616Z"
          fill="#A1A9D6"
        />
        <path
          d="M413.236 412.486V366.593L394.073 377.655V423.553L413.236 412.486Z"
          fill="#CED3F8"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M567.407 773.111H512.263V783.249H567.407V773.111Z"
          fill="url(#paint15_linear_3583_133120)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M592.475 758.471H537.336V768.604H592.475V758.471Z"
          fill="url(#paint16_linear_3583_133120)"
        />
        <path
          d="M542.994 765.491C542.994 757.532 537.948 757.3 537.948 757.3C537.948 757.3 535.22 757.3 530.776 755.458C526.333 753.616 520.221 754.339 523.673 760.704C523.708 760.786 523.748 760.865 523.794 760.941C524.081 761.353 524.463 761.69 524.908 761.923L535.938 768.393C537.616 769.348 542.957 767.132 542.994 765.491Z"
          fill="url(#paint17_linear_3583_133120)"
        />
        <path
          d="M542.961 764.799C542.866 766.435 537.599 768.609 535.937 767.654L524.907 761.195C524.356 760.865 523.836 760.485 523.355 760.06C523.455 760.276 523.545 760.487 523.672 760.725C523.707 760.807 523.747 760.886 523.793 760.962C524.08 761.374 524.462 761.711 524.907 761.944L535.937 768.414C537.52 769.311 542.27 767.422 542.924 765.828C542.95 765.723 542.966 765.615 542.971 765.506C542.987 765.271 542.983 765.034 542.961 764.799Z"
          fill="#131147"
        />
        <path
          d="M540.941 756.255C539.643 756.255 535.067 757.416 535.067 757.416C535.034 757.84 534.894 758.248 534.661 758.603C534.616 758.652 534.574 758.703 534.534 758.756C533.632 759.384 532.714 760.371 534.851 761.717C535.421 762.139 536.123 762.344 536.83 762.293C537.538 762.243 538.204 761.941 538.709 761.443C538.986 760.867 539.417 760.379 539.954 760.034C540.308 759.775 540.713 759.595 541.142 759.506C541.14 759.469 541.14 759.432 541.142 759.395C541.142 759.395 541.485 756.255 540.941 756.255Z"
          fill="white"
        />
        <path
          d="M541.025 678.401L518.596 678.797C520.448 693.785 524.184 707.396 526.675 721.909C529.351 736.016 532.37 745.927 534.998 757.664C535.964 759.849 540.376 759.02 541.268 756.92C541.964 746.111 542.767 732.944 539.563 720.468C540.096 707.026 541.336 691.969 541.025 678.401Z"
          fill="#20196F"
        />
        <path
          d="M517.856 780.104C517.856 772.145 512.816 771.913 512.816 771.913C512.816 771.913 510.083 771.913 505.639 770.071C501.195 768.229 495.084 768.953 498.535 775.317C498.572 775.396 498.614 775.475 498.657 775.555C498.946 775.968 499.332 776.303 499.781 776.531L510.8 783.001C512.484 783.978 517.819 781.761 517.856 780.104Z"
          fill="url(#paint18_linear_3583_133120)"
        />
        <path
          d="M517.83 779.407C517.735 781.038 512.468 783.218 510.8 782.262L499.781 775.803C499.227 775.476 498.705 775.096 498.224 774.668C498.324 774.884 498.414 775.095 498.536 775.333C498.572 775.412 498.615 775.491 498.657 775.57C498.946 775.983 499.332 776.319 499.781 776.547L510.8 783.017C512.384 783.92 517.16 782.03 517.788 780.442C517.818 780.335 517.836 780.225 517.841 780.114C517.85 779.879 517.847 779.643 517.83 779.407Z"
          fill="#131147"
        />
        <path
          d="M515.793 770.879C514.5 770.879 509.924 772.045 509.924 772.045C509.888 772.468 509.748 772.876 509.518 773.233C509.473 773.281 509.43 773.332 509.391 773.386C508.483 774.014 507.565 775.001 509.708 776.346C510.278 776.767 510.979 776.971 511.686 776.922C512.393 776.873 513.059 776.573 513.566 776.077C513.843 775.502 514.272 775.013 514.806 774.663C515.162 774.405 515.568 774.225 515.998 774.135C515.998 774.103 515.998 774.03 515.998 774.03C515.998 774.03 516.352 770.879 515.793 770.879Z"
          fill="white"
        />
        <path
          d="M525.208 714.035C526.569 707.591 542.286 706.789 541.257 681.462C536.322 680.734 511.439 692.407 506.51 691.679C505.892 706.762 507.349 720.806 507.444 735.53C507.781 749.88 509.138 760.15 509.819 772.161C510.41 774.478 514.896 774.383 516.12 772.457C518.574 761.902 521.514 749.04 520.389 736.211C521.857 729.102 523.609 721.576 525.208 714.035Z"
          fill="url(#paint19_linear_3583_133120)"
        />
        <path
          d="M529.879 632.276C547.432 644.002 549.527 667.302 540.038 682.079C540.038 682.079 542.075 649.327 529.879 632.276Z"
          fill="#3BC9E9"
        />
        <path
          d="M530.681 695.817C519.999 701.817 508.816 701.828 505.011 694.46C506.436 680.739 507.771 669.566 505.011 656.415C505.818 649.148 506.394 648.055 505.465 643.817C505.792 642.398 510.167 639.363 513.344 636.429C516.785 633.294 519.107 630.307 520.88 629.943C535.494 626.961 542.587 652.995 541.368 680.749C540.249 688.154 535.942 692.867 530.681 695.817Z"
          fill="#00ADD2"
        />
        <path
          d="M528.987 631.626C525.821 629.336 526.074 624.602 526.349 621.457C526.868 619.696 527.07 617.857 526.945 616.026C526.475 609.102 521.667 603.418 516.279 604.152C505.439 605.635 507.766 611.239 508.231 618.163C508.664 624.581 511.872 628.798 516.786 629.209C516.86 630.265 516.427 633.474 515.102 634.804C518.665 636.915 523.873 635.933 525.932 635.004C528.111 634.012 530.481 633.162 528.987 631.626Z"
          fill="#FCD1A9"
        />
        <path
          d="M511.94 600.774C512.198 599.903 513.856 599.339 519.487 601.508L520.943 602.072C531.366 606.59 528.859 618.612 527.276 621.805C526.621 622.952 526.334 624.273 526.453 625.589C522.358 625.42 521.059 627.7 518.436 625.425C516.552 623.784 513.739 621.852 513.264 621.045C512.923 620.441 512.788 619.743 512.879 619.055C512.879 613.292 510.209 616.812 510.293 618.137C509.206 617.609 509.28 615.292 509.349 612.717C500.514 606.115 505.264 601.555 511.94 600.774Z"
          fill="#362E94"
        />
        <path
          d="M506.295 699.532C506.621 699.905 507.02 700.206 507.467 700.418C507.915 700.63 508.401 700.748 508.896 700.764C509.39 700.781 509.883 700.695 510.344 700.513C510.804 700.331 511.222 700.057 511.572 699.706C513.298 697.886 512.3 695.864 513.25 695.257C515.842 693.595 516.417 692.033 516 690.465C513.931 691.779 511.778 693.89 509.989 692.608C508.2 691.326 505.83 689.563 505.703 687.737C503.012 688.354 502.875 694.186 504.273 696.529C504.873 697.578 505.548 698.582 506.295 699.532Z"
          fill="#FCD1A9"
        />
        <path
          d="M509.914 694.951C511.249 698.207 510.315 700.229 507.043 700.577C498.889 696.403 494.145 684.686 494.007 678.258C493.775 667.529 498.984 654.061 505.618 643.496C507.154 641.226 512.115 642.086 514.558 645.311C517.862 649.665 516.447 656.008 514.558 658.589C509.761 666.247 504.42 671.952 503.898 678.084C503.423 683.773 505.766 691.257 509.914 694.951Z"
          fill="#3BC9E9"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M559.416 173.412H514.505L543.014 189.878L514.505 206.333H559.416L587.92 189.878L559.416 173.412Z"
          fill="url(#paint20_linear_3583_133120)"
        />
        <path
          d="M514.505 206.333L543.014 189.878V156.455L514.505 172.911V206.333Z"
          fill="#BBBFE2"
        />
        <path
          d="M514.504 206.333L486 189.878V156.455L514.504 172.911V206.333Z"
          fill="#CED2F4"
        />
        <path
          d="M514.504 172.911L486 156.455L514.504 140L543.013 156.455L514.504 172.911Z"
          fill="#F5F5FA"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M314.981 615.973H236.277L291.554 647.892L236.277 679.81H314.981L370.263 647.892L314.981 615.973Z"
          fill="url(#paint21_linear_3583_133120)"
        />
        <path
          d="M236.277 679.81L181 647.892V600.02L236.277 631.933V679.81Z"
          fill="#D3D7EF"
        />
        <path
          d="M236.277 631.933L263.92 589.908L208.638 558L181 600.02L236.277 631.933Z"
          fill="#EAEDF7"
        />
        <path
          d="M263.921 589.908L291.559 600.02L236.277 568.128L208.639 558L263.921 589.908Z"
          fill="#DCE0F1"
        />
        <path
          d="M263.921 589.908L291.559 600.02V647.892L236.277 679.81V631.933L263.921 589.908Z"
          fill="#BDC0E0"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M138.098 291.419H51.1243L85.2434 311.126L51.1243 330.827H138.098L172.222 311.126L138.098 291.419Z"
          fill="url(#paint22_linear_3583_133120)"
        />
        <path
          d="M51.1244 330.827L17 311.126V236.713L51.1244 256.414V330.827Z"
          fill="#D3D7EF"
        />
        <path
          d="M51.1244 256.414L68.1866 230.469L34.0622 210.779L17 236.713L51.1244 256.414Z"
          fill="#EAEDF7"
        />
        <path
          d="M68.186 230.469L85.2429 236.713L51.1238 217.028L34.0616 210.779L68.186 230.469Z"
          fill="#DCE0F1"
        />
        <path
          d="M68.1865 230.469L85.2434 236.713V311.126L51.1243 330.827V256.414L68.1865 230.469Z"
          fill="#BDC0E0"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M774.146 523.74C766.535 525.145 758.816 525.879 751.078 525.935C748.492 525.949 745.908 525.827 743.335 525.571C744.117 524.559 745.114 523.733 746.254 523.154C751.732 519.988 762.699 517.259 774.146 515.903V501.4C764.044 502.251 753.994 503.632 744.037 505.538L680.459 517.771L701.648 530.01L710.963 535.393C720.278 540.77 746.233 541.858 774.146 538.675V523.74Z"
          fill="url(#paint23_linear_3583_133120)"
        />
        <path
          d="M696.102 461.539C696.136 459.648 695.777 457.769 695.046 456.024C700.234 455.454 703.891 459.191 703.891 466.052C703.891 475.066 697.558 486.027 689.753 490.539C683.816 493.964 678.744 492.65 676.649 487.9C678.517 487.649 680.314 487.015 681.926 486.037C689.774 481.525 696.102 470.559 696.102 461.539Z"
          fill="#3BC9E9"
        />
        <path
          d="M660.775 457.47C672.475 437.205 691.447 426.233 703.158 432.998L710.969 437.505C699.263 430.745 680.285 441.727 668.58 461.983C656.874 482.238 656.885 504.129 668.58 510.9L660.796 506.414C649.069 499.648 649.074 477.741 660.775 457.47Z"
          fill="#8ADCF0"
        />
        <path
          d="M668.58 510.921L689.775 523.154L710.969 486.454C722.675 466.183 722.675 444.266 710.969 437.511C699.264 430.756 680.286 441.733 668.58 461.988C656.875 482.243 656.88 504.166 668.58 510.921ZM675.642 482.38C675.642 473.361 681.975 462.394 689.775 457.887C697.575 453.38 703.913 457.032 703.913 466.057C703.913 475.081 697.58 486.032 689.775 490.544C681.969 495.057 675.642 491.394 675.642 482.38Z"
          fill="#00ADD2"
        />
        <path
          d="M783.056 657.515V663.046L701.645 710.053C685.506 706.929 666.998 709.05 654.147 716.471C636.056 726.915 636.056 743.85 654.147 754.294C672.238 764.739 701.571 764.739 719.662 754.294C732.523 746.874 736.234 736.177 730.814 726.851L748.757 716.507V711.436H757.539V706.369H766.315V701.303H775.102V696.237H783.884V691.165H792.666V657.521L783.056 657.515ZM686.303 743.143C685.724 744.497 684.687 745.603 683.374 746.267C679.152 748.711 672.291 748.711 668.069 746.267C666.756 745.603 665.719 744.497 665.14 743.143C664.412 741.143 665.383 738.979 668.069 737.428C672.291 734.984 679.152 734.984 683.374 737.428C686.034 738.984 687.01 741.148 686.303 743.143Z"
          fill="url(#paint24_linear_3583_133120)"
        />
        <path
          d="M783.055 679.649H774.273V684.716L765.491 684.721L765.497 689.787H756.709V694.859H747.933V699.926H739.151V704.997L715.888 718.429L686.746 701.609L783.055 646V679.649Z"
          fill="#E8ECF7"
        />
        <path
          d="M739.152 699.926V704.997V711.436H747.934V699.926H739.152Z"
          fill="url(#paint25_linear_3583_133120)"
        />
        <path
          d="M747.934 694.859V699.926V706.369H756.715V694.859H747.934Z"
          fill="url(#paint26_linear_3583_133120)"
        />
        <path
          d="M756.711 689.787V694.859V701.303H765.493V689.787H756.711Z"
          fill="url(#paint27_linear_3583_133120)"
        />
        <path
          d="M765.487 684.726V689.793V696.237H774.269V684.726H765.487Z"
          fill="url(#paint28_linear_3583_133120)"
        />
        <path
          d="M774.264 679.655V684.726V691.17H783.046V679.655H774.264Z"
          fill="url(#paint29_linear_3583_133120)"
        />
        <path
          d="M715.888 718.429V729.944L739.151 716.513V711.436V704.997L715.888 718.429Z"
          fill="#BBC0E6"
        />
        <path
          d="M691.513 704.353L693.75 705.646L783.056 654.085V651.499L691.513 704.353Z"
          fill="#BBC0E6"
        />
        <path
          d="M698.332 708.29L783.057 659.378V654.085L693.751 705.646L698.332 708.29Z"
          fill="#CED3F8"
        />
        <path
          d="M723.61 734.398V724.777C723.225 731.316 718.723 737.792 710.078 742.784C691.987 753.228 662.649 753.228 644.563 742.784C635.591 737.596 631.058 730.81 631 724.012V735.522C631.058 742.32 635.576 749.107 644.563 754.294C662.649 764.739 691.987 764.739 710.078 754.294C719.551 748.822 724.069 741.56 723.61 734.398Z"
          fill="url(#paint30_linear_3583_133120)"
        />
        <path
          d="M710.083 704.955C691.991 694.511 662.654 694.511 644.568 704.955C626.482 715.399 626.476 732.34 644.568 742.784C662.659 753.228 691.991 753.228 710.083 742.784C728.174 732.34 728.174 715.399 710.083 704.955ZM676.708 731.638C676.122 732.989 675.085 734.094 673.773 734.762C669.551 737.206 662.691 737.206 658.469 734.762C657.157 734.094 656.119 732.989 655.534 731.638C654.806 729.638 655.782 727.474 658.469 725.923C662.691 723.479 669.551 723.479 673.773 725.923C676.465 727.474 677.436 729.638 676.708 731.638Z"
          fill="#E8ECF7"
        />
        <path
          d="M705.392 710.254C689.891 701.282 664.755 701.282 649.26 710.254C642.077 714.396 638.23 719.753 637.702 725.163C638.23 730.593 642.077 735.929 649.26 740.072C664.76 749.043 689.897 749.043 705.392 740.072C712.569 735.929 716.416 730.572 716.949 725.168C716.416 719.743 712.569 714.402 705.392 710.254ZM676.708 731.638C676.123 732.989 675.085 734.094 673.774 734.762C669.552 737.206 662.691 737.206 658.469 734.762C657.158 734.094 656.12 732.989 655.535 731.638C654.807 729.638 655.783 727.474 658.469 725.923C662.691 723.479 669.552 723.479 673.774 725.923C676.465 727.474 677.436 729.638 676.708 731.638Z"
          fill="#807DFB"
        />
        <path
          d="M649.261 710.254C664.761 701.282 689.898 701.282 705.393 710.254C712.57 714.402 716.417 719.753 716.95 725.168C717.563 718.877 713.71 712.47 705.393 707.668C689.893 698.696 664.756 698.696 649.261 707.668C640.938 712.47 637.091 718.877 637.703 725.163C638.231 719.737 642.078 714.396 649.261 710.254Z"
          fill="url(#paint31_linear_3583_133120)"
        />
        <path
          d="M673.774 734.762C677.996 732.319 677.996 728.361 673.774 725.923C669.552 723.484 662.691 723.479 658.469 725.923C654.247 728.366 654.247 732.319 658.469 734.762C662.686 732.319 669.546 732.319 673.774 734.762Z"
          fill="url(#paint32_linear_3583_133120)"
        />
        <path
          d="M698.294 724.92L675.5 711.763L672.044 713.763L694.832 726.92L698.294 724.92Z"
          fill="#5652CC"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M417.627 605.516C409.932 601.072 399.683 599.024 389.656 599.325H373.908V601.922C371.008 602.82 368.214 604.03 365.575 605.532C351.257 613.86 351.325 627.354 365.749 635.682C368.336 637.145 371.071 638.331 373.908 639.218V641.915H392.796C401.879 641.762 410.887 639.704 417.796 635.687C432.103 627.338 432.024 613.844 417.627 605.516Z"
          fill="url(#paint33_linear_3583_133120)"
        />
        <path
          d="M338 610.814C338 605.489 341.515 600.159 348.555 596.095C355.595 592.032 364.82 590 374.045 590C383.271 590 392.517 592.032 399.53 596.095C406.544 600.159 410.086 605.489 410.086 610.814C410.086 616.139 406.571 621.464 399.53 625.528C392.49 629.592 383.276 631.624 374.045 631.624C364.815 631.624 355.574 629.592 348.56 625.528C341.546 621.464 338 616.139 338 610.814Z"
          fill="#8ADCF0"
        />
        <path
          d="M399.531 625.528C392.496 629.592 383.276 631.624 374.046 631.624C364.815 631.624 355.574 629.592 348.56 625.528C341.547 621.464 338.005 616.139 338.005 610.814V621.09C338.005 626.415 341.526 631.74 348.56 635.803C355.595 639.867 364.82 641.899 374.046 641.899C383.271 641.899 392.517 639.867 399.531 635.803C406.544 631.74 410.086 626.415 410.086 621.084V610.814C410.096 616.139 406.576 621.449 399.531 625.528Z"
          fill="#007E9A"
        />
        <path
          d="M353.347 622.763C359.062 626.066 366.541 627.718 374.045 627.718C381.55 627.718 389.033 626.066 394.749 622.763C400.027 619.718 402.855 615.802 403.262 611.817C402.855 607.827 400.021 603.901 394.749 600.866C389.028 597.563 381.555 595.916 374.045 595.916C366.535 595.916 359.062 597.563 353.342 600.866C348.064 603.911 345.235 607.827 344.824 611.812C345.235 615.796 348.069 619.718 353.347 622.763Z"
          fill="#3BC9E9"
        />
        <path
          d="M353.342 600.866C359.063 597.563 366.536 595.916 374.046 595.916C381.556 595.916 389.029 597.563 394.75 600.866C400.027 603.911 402.856 607.827 403.262 611.817C403.3 611.484 403.319 611.149 403.32 610.814C403.32 606.487 400.465 602.159 394.75 598.861C389.034 595.562 381.556 593.911 374.046 593.911C366.536 593.911 359.063 595.562 353.342 598.861C347.621 602.159 344.772 606.487 344.772 610.814C344.772 611.147 344.772 611.479 344.824 611.812C345.236 607.827 348.07 603.911 353.342 600.866Z"
          fill="#007E9A"
        />
        <path
          d="M388.463 606.513L391.233 604.914V602.914L388.463 604.508V606.513Z"
          fill="#007E9A"
        />
        <path
          d="M375.697 609.838C374.642 609.242 374.114 608.45 374.114 607.611C374.114 606.772 374.684 605.975 375.697 605.379C376.895 604.77 378.22 604.452 379.563 604.452C380.907 604.452 382.231 604.77 383.429 605.379C385.54 606.608 385.54 608.614 383.429 609.838L386.933 611.865C390.4 609.87 390.902 606.809 388.469 604.476L391.24 602.893L387.73 600.866L384.959 602.45C380.954 601.04 375.65 601.331 372.188 603.331C370.214 604.466 369.132 605.97 369.132 607.59C369.132 609.21 370.214 610.709 372.188 611.844C374.299 613.073 374.299 615.079 372.188 616.308C370.99 616.917 369.666 617.235 368.322 617.235C366.978 617.235 365.654 616.917 364.456 616.308C362.345 615.079 362.345 613.073 364.456 611.844L360.952 609.817C357.485 611.817 356.983 614.878 359.411 617.205L356.857 618.678L360.366 620.71L362.92 619.227C366.931 620.636 372.235 620.346 375.697 618.345C379.761 616.029 379.761 612.213 375.697 609.838Z"
          fill="#8ADCF0"
        />
        <path
          d="M356.857 620.715L360.366 622.742V620.736L356.857 618.71V620.715Z"
          fill="#007E9A"
        />
        <path
          d="M360.366 622.742L362.921 621.264V619.258L360.366 620.736V622.742Z"
          fill="#007E9A"
        />
        <path
          d="M383.424 611.865L386.934 613.891V611.886L383.424 609.859V611.865Z"
          fill="#007E9A"
        />
        <path
          d="M364.456 613.891V611.886C362.942 612.762 362.509 613.997 363.142 615.121C363.466 614.604 363.919 614.18 364.456 613.891Z"
          fill="#007E9A"
        />
        <path
          d="M357.906 614.018V616.271C357.937 616.824 358.086 617.364 358.345 617.855L359.4 617.242C358.937 616.844 358.564 616.352 358.306 615.799C358.048 615.246 357.91 614.644 357.901 614.034L357.906 614.018Z"
          fill="#007E9A"
        />
        <path
          d="M372.187 613.891C372.725 614.18 373.178 614.604 373.501 615.121C374.129 614.023 373.697 612.762 372.187 611.886C370.213 610.751 369.132 609.247 369.132 607.632V609.632C369.132 611.242 370.213 612.751 372.187 613.891Z"
          fill="#007E9A"
        />
        <path
          d="M389.972 607.627C389.972 609.173 388.97 610.714 386.933 611.886V613.891C389.044 612.688 390.025 611.099 389.967 609.516V607.616L389.972 607.627Z"
          fill="#007E9A"
        />
        <path
          d="M375.698 618.377C372.236 620.377 366.932 620.667 362.921 619.258V621.264C366.932 622.668 372.236 622.377 375.698 620.383C377.714 619.216 378.727 617.686 378.743 616.16V614.123C378.738 615.67 377.725 617.227 375.698 618.377Z"
          fill="#007E9A"
        />
        <path
          d="M383.428 605.4C382.231 604.791 380.906 604.473 379.563 604.473C378.219 604.473 376.895 604.791 375.697 605.4C374.641 605.996 374.114 606.788 374.114 607.632C374.119 607.986 374.217 608.332 374.399 608.635C374.723 608.116 375.178 607.692 375.718 607.405C376.916 606.797 378.24 606.481 379.584 606.481C380.927 606.481 382.252 606.797 383.45 607.405C383.987 607.694 384.439 608.12 384.758 608.64C385.371 607.532 384.938 606.27 383.428 605.4Z"
          fill="#007E9A"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M463.493 618.968C457.624 614.525 449.829 612.482 442.166 612.778H430.16V615.364C427.903 616.288 425.775 617.5 423.827 618.968C412.908 627.296 412.971 640.791 423.964 649.119C425.881 650.551 427.97 651.739 430.181 652.654V655.351H444.541C451.465 655.198 458.331 653.14 463.604 649.124C474.544 640.791 474.491 627.296 463.493 618.968Z"
          fill="url(#paint34_linear_3583_133120)"
        />
        <path
          d="M448.892 609.838C445.09 606.029 440.467 603.14 435.376 601.394C427.027 598.491 417.918 598.681 411.221 602.566C408.002 604.357 405.47 607.168 404.023 610.556L400.856 618.192C402.3 614.802 404.833 611.991 408.055 610.202C412.414 607.674 417.792 606.709 423.317 607.147C431.766 607.84 439.699 611.492 445.72 617.459C452.507 624.356 454.476 632.69 451.747 639.292L454.914 631.655C457.647 625.064 455.679 616.731 448.892 609.838Z"
          fill="#007E9A"
        />
        <path
          d="M448.052 611.87C458.111 622.092 457.552 635.466 446.875 641.688C436.199 647.91 419.274 644.659 409.215 634.437C399.156 624.214 399.678 610.836 410.381 604.613C421.084 598.391 437.988 601.642 448.052 611.87Z"
          fill="#3BC9E9"
        />
        <path
          d="M460.807 634.115C459.752 636.658 458.696 639.207 457.641 641.751C455.854 645.927 452.73 649.39 448.759 651.594C435.565 659.262 414.808 655.288 402.406 642.67C394.047 634.173 391.619 623.934 394.97 615.812C396.026 613.269 397.081 610.719 398.137 608.176C394.786 616.298 397.208 626.536 405.573 635.033C417.975 647.63 438.726 651.625 451.925 643.957C455.896 641.753 459.021 638.29 460.807 634.115Z"
          fill="#007E9A"
        />
        <path
          d="M453.373 607.231C465.77 619.828 465.126 636.257 451.927 643.931C438.728 651.604 417.977 647.625 405.575 635.006C393.172 622.388 393.827 605.98 407.021 598.307C420.214 590.633 440.965 594.628 453.373 607.231ZM410.055 632.405C420.114 642.622 437.007 645.873 447.715 639.656C458.423 633.439 458.951 620.055 448.892 609.838C438.833 599.621 421.935 596.359 411.221 602.576C400.508 608.793 399.991 622.177 410.055 632.405Z"
          fill="#8ADCF0"
        />
        <path
          d="M414.043 627.555L415.516 626.726C414.228 624.689 413.932 622.504 414.634 620.657L413.8 622.652C413.235 624.255 413.323 626.015 414.043 627.555Z"
          fill="#007E9A"
        />
        <path
          d="M426.842 624.193C427.265 624.587 427.605 625.061 427.842 625.589C428.079 626.117 428.207 626.686 428.219 627.264C429.491 625.945 429.328 623.829 427.692 622.161C425.913 620.346 424.974 618.219 425.053 616.166C425.083 615.438 425.249 614.721 425.544 614.055L424.747 615.986C424.424 616.684 424.244 617.44 424.219 618.208C424.124 620.251 425.063 622.383 426.842 624.193Z"
          fill="#007E9A"
        />
        <path
          d="M420.472 620.768C419.998 621.028 419.601 621.409 419.323 621.873C419.045 622.337 418.895 622.866 418.888 623.407C419.114 623.171 419.372 622.969 419.654 622.805L420.472 620.768Z"
          fill="#007E9A"
        />
        <path
          d="M443.228 615.643C443.467 616.021 443.677 616.416 443.856 616.826L445.054 616.129L445.893 614.097L443.228 615.643Z"
          fill="#007E9A"
        />
        <path
          d="M438.267 621.581L437.422 623.618L440.589 626.832L441.433 624.8L438.267 621.581Z"
          fill="#007E9A"
        />
        <path
          d="M441.434 624.8L440.59 626.832C441.737 626.198 442.645 625.206 443.176 624.008V623.971L444.005 621.966C443.475 623.162 442.574 624.156 441.434 624.8Z"
          fill="#007E9A"
        />
        <path
          d="M445.893 614.102L443.254 615.649C445.407 619.042 444.779 622.868 441.46 624.8L438.293 621.586C440.341 620.393 440.441 617.834 438.515 615.876C436.589 613.918 433.353 613.295 431.306 614.482C430.842 614.737 430.452 615.108 430.175 615.558C429.897 616.009 429.741 616.524 429.723 617.052C429.724 617.647 429.847 618.235 430.085 618.779C430.323 619.324 430.67 619.814 431.105 620.219C434.8 623.95 434.583 628.832 430.678 631.101C427.353 633.033 422.392 632.457 418.719 629.94L416.265 631.37L413.098 628.156L415.515 626.726C413.368 623.327 413.996 619.501 417.32 617.57L420.487 620.789C418.434 621.976 418.334 624.541 420.265 626.499C422.197 628.457 425.421 629.08 427.474 627.887C429.527 626.694 429.628 624.135 427.696 622.182C425.917 620.367 424.978 618.24 425.057 616.187C425.096 615.174 425.4 614.189 425.938 613.33C426.476 612.47 427.229 611.767 428.123 611.289C431.448 609.358 436.409 609.938 440.077 612.456L442.716 610.904L445.893 614.102Z"
          fill="#8ADCF0"
        />
        <path
          d="M413.046 628.135L412.207 630.167L415.368 633.381L416.212 631.349L413.046 628.135Z"
          fill="#007E9A"
        />
        <path
          d="M416.213 631.349L415.369 633.381L417.828 631.956L418.667 629.919L416.213 631.349Z"
          fill="#007E9A"
        />
        <path
          d="M432.726 629.133C432.577 629.353 432.413 629.563 432.235 629.761C431.781 630.28 431.246 630.721 430.651 631.07C427.327 633.001 422.366 632.426 418.693 629.909L417.854 631.946C421.548 634.463 426.482 635.038 429.812 633.101C430.889 632.496 431.755 631.575 432.293 630.463L433.158 628.383C433.034 628.644 432.889 628.895 432.726 629.133Z"
          fill="#007E9A"
        />
        <path
          d="M439.043 620.974C440.3 619.654 440.136 617.543 438.516 615.876C436.589 613.918 433.354 613.295 431.307 614.482C430.843 614.737 430.453 615.108 430.176 615.558C429.898 616.009 429.742 616.524 429.723 617.053C429.723 617.053 429.723 617.1 429.723 617.121C429.948 616.884 430.206 616.682 430.489 616.519C432.536 615.327 435.766 615.95 437.698 617.913C438.518 618.685 439.011 619.743 439.075 620.868L439.043 620.974Z"
          fill="#007E9A"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M124.353 509.401C117.698 505.559 108.858 503.791 100.172 504.049H90.4716V506.282C87.9635 507.055 85.5478 508.101 83.2678 509.401C70.8868 516.599 70.9607 528.268 83.4209 535.466C85.6548 536.735 88.0186 537.759 90.4716 538.522V540.855H102.89C110.743 540.723 118.527 538.939 124.527 535.471C136.882 528.268 136.819 516.599 124.353 509.401Z"
          fill="url(#paint35_linear_3583_133120)"
        />
        <path
          d="M69.394 535.493C63.1191 531.872 59.9895 527.117 60 522.373L60.0317 511.358C60.0317 516.108 63.1454 520.858 69.4151 524.489C81.8912 531.682 102.035 531.682 114.422 524.489C120.575 520.916 123.652 516.235 123.668 511.548V522.552C123.668 527.239 120.575 531.914 114.422 535.498C102.009 542.67 81.8648 542.67 69.394 535.493Z"
          fill="url(#paint36_linear_3583_133120)"
        />
        <path
          d="M69.4156 524.463C56.9554 517.264 56.8868 505.59 69.2678 498.397C81.6489 491.204 101.809 491.198 114.274 498.397C126.74 505.595 126.803 517.264 114.422 524.463C102.041 531.661 81.8916 531.656 69.4156 524.463Z"
          fill="white"
        />
        <path
          d="M97.216 505.411C96.8203 504.3 96.0086 503.386 94.952 502.862C91.7855 501.036 86.6557 501.036 83.4892 502.862C81.906 503.775 81.1143 504.973 81.1196 506.171V517.465H100.646C102.644 517.507 104.619 517.036 106.383 516.098C109.55 514.272 109.55 511.306 106.383 509.48C105.019 508.737 103.514 508.293 101.966 508.176L97.6857 507.886L97.216 505.411Z"
          fill="#807DFB"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M673.038 177.917C672.424 175.744 670.666 173.654 667.771 171.978C660.398 167.723 648.454 167.732 641.089 171.978C637.398 174.102 635.557 176.888 635.565 179.675V205.976H681.108C685.756 206.075 690.352 204.978 694.454 202.791C701.827 198.536 701.827 191.644 694.454 187.389C691.28 185.655 687.775 184.614 684.169 184.337L674.216 183.673L673.038 177.917Z"
          fill="url(#paint37_linear_3583_133120)"
        />
        <path
          d="M681.897 193.004C677.789 195.183 673.193 196.279 668.543 196.189H623V205.984H668.543C673.194 206.075 677.79 204.976 681.897 202.791C685.588 200.66 687.429 197.864 687.421 195.061V185.315C687.404 188.102 685.563 190.881 681.897 193.004Z"
          fill="#651FFF"
        />
        <path
          d="M660.481 168.122C659.868 165.957 658.118 163.867 655.206 162.191C647.849 157.936 635.897 157.936 628.532 162.191C624.85 164.314 623 167.11 623 169.897V196.189H668.543C673.193 196.279 677.789 195.183 681.897 193.004C689.254 188.749 689.262 181.848 681.897 177.593C678.722 175.865 675.217 174.827 671.612 174.55L661.659 173.878L660.481 168.122Z"
          fill="#807DFB"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3583_133120"
          x1="-43.7651"
          y1="415.743"
          x2="929.888"
          y2="585.932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFEFF3" />
          <stop offset="0.5" stopColor="#FBFBFC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3583_133120"
          x1="25.2543"
          y1="432.14"
          x2="122.952"
          y2="601.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DAE0F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3583_133120"
          x1="612.156"
          y1="297.289"
          x2="709.848"
          y2="466.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DAE0F5" />
          <stop offset="0.56" stopColor="#E6EAF8" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3583_133120"
          x1="370.921"
          y1="451.503"
          x2="571.572"
          y2="451.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6CBE7" />
          <stop offset="0.26" stopColor="#CFD3EB" />
          <stop offset="0.68" stopColor="#E8EAF5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3583_133120"
          x1="181.358"
          y1="451.503"
          x2="382.009"
          y2="451.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6CBE7" />
          <stop offset="0.26" stopColor="#CFD3EB" />
          <stop offset="0.68" stopColor="#E8EAF5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3583_133120"
          x1="179.258"
          y1="483.743"
          x2="537.939"
          y2="483.743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#651FFF" />
          <stop offset="0.28" stopColor="#651FFF" />
          <stop offset="0.74" stopColor="#3A379A" />
          <stop offset="1" stopColor="#34318F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3583_133120"
          x1="576.855"
          y1="597.347"
          x2="611.397"
          y2="537.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4141AA" />
          <stop offset="0.21" stopColor="#4949B3" />
          <stop offset="0.56" stopColor="#5E5ECC" />
          <stop offset="1" stopColor="#7F7FF4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3583_133120"
          x1="508.512"
          y1="557.861"
          x2="543.059"
          y2="498.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6CBE7" />
          <stop offset="0.26" stopColor="#CFD3EB" />
          <stop offset="0.68" stopColor="#E8EAF5" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3583_133120"
          x1="664.05"
          y1="642.259"
          x2="721.243"
          y2="609.237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4141AA" />
          <stop offset="0.21" stopColor="#4949B3" />
          <stop offset="0.56" stopColor="#5E5ECC" />
          <stop offset="1" stopColor="#7F7FF4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3583_133120"
          x1="291.95"
          y1="156.009"
          x2="270.359"
          y2="122.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#423EAF" />
          <stop offset="1" stopColor="#6B67E9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3583_133120"
          x1="360.329"
          y1="431.802"
          x2="485.944"
          y2="431.802"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F1F6" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3583_133120"
          x1="410.623"
          y1="450.226"
          x2="470.412"
          y2="450.226"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F1F6" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_3583_133120"
          x1="359.273"
          y1="415.568"
          x2="457.572"
          y2="415.568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE0F5" />
          <stop offset="0.56" stopColor="#E4E7F4" />
          <stop offset="1" stopColor="#EFEFF3" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_3583_133120"
          x1="328.459"
          y1="307.216"
          x2="382.749"
          y2="307.216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4CAE9" />
          <stop offset="0.25" stopColor="#C9CEEB" />
          <stop offset="0.52" stopColor="#D6DAF0" />
          <stop offset="0.81" stopColor="#ECEEF8" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_3583_133120"
          x1="403.637"
          y1="442.157"
          x2="457.119"
          y2="442.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE0F5" />
          <stop offset="0.36" stopColor="#E4E8F8" />
          <stop offset="0.97" stopColor="#FEFEFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_3583_133120"
          x1="512.268"
          y1="778.178"
          x2="567.413"
          y2="778.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4CAE9" />
          <stop offset="0.09" stopColor="#CED3ED" />
          <stop offset="0.41" stopColor="#E9EBF7" />
          <stop offset="0.69" stopColor="#F9FAFD" />
          <stop offset="0.9" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_3583_133120"
          x1="537.336"
          y1="763.543"
          x2="592.475"
          y2="763.543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4CAE9" />
          <stop offset="0.09" stopColor="#CED3ED" />
          <stop offset="0.41" stopColor="#E9EBF7" />
          <stop offset="0.69" stopColor="#F9FAFD" />
          <stop offset="0.9" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_3583_133120"
          x1="522.665"
          y1="761.601"
          x2="542.994"
          y2="761.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#423EAF" />
          <stop offset="0.3" stopColor="#3A35A0" />
          <stop offset="0.89" stopColor="#241E77" />
          <stop offset="1" stopColor="#20196F" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_3583_133120"
          x1="497.533"
          y1="776.235"
          x2="517.856"
          y2="776.235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#423EAF" />
          <stop offset="0.3" stopColor="#3A35A0" />
          <stop offset="0.89" stopColor="#241E77" />
          <stop offset="1" stopColor="#20196F" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_3583_133120"
          x1="506.356"
          y1="727.667"
          x2="541.304"
          y2="727.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#423EAF" />
          <stop offset="0.3" stopColor="#353097" />
          <stop offset="0.75" stopColor="#261F7A" />
          <stop offset="1" stopColor="#20196F" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_3583_133120"
          x1="514.505"
          y1="189.872"
          x2="587.92"
          y2="189.872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D6F0" />
          <stop offset="0.26" stopColor="#E1E4F5" />
          <stop offset="0.71" stopColor="#F7F8FC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_3583_133120"
          x1="236.277"
          y1="647.892"
          x2="370.263"
          y2="647.892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C8E1" />
          <stop offset="0.19" stopColor="#D7D9EA" />
          <stop offset="0.48" stopColor="#EDEEF6" />
          <stop offset="0.75" stopColor="#FAFBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_3583_133120"
          x1="51.119"
          y1="311.126"
          x2="172.222"
          y2="311.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C8E1" />
          <stop offset="0.19" stopColor="#D7D9EA" />
          <stop offset="0.48" stopColor="#EDEEF6" />
          <stop offset="0.75" stopColor="#FAFBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_3583_133120"
          x1="680.459"
          y1="520.927"
          x2="774.146"
          y2="520.927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4CAE9" />
          <stop offset="0.24" stopColor="#D5DAF0" />
          <stop offset="0.73" stopColor="#F4F5FB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_3583_133120"
          x1="640.568"
          y1="709.821"
          x2="792.624"
          y2="709.821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C9E4" />
          <stop offset="0.38" stopColor="#D4D7EB" />
          <stop offset="1" stopColor="#F6F6FB" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_3583_133120"
          x1="739.152"
          y1="705.683"
          x2="747.934"
          y2="705.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_3583_133120"
          x1="747.934"
          y1="700.612"
          x2="756.715"
          y2="700.612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_3583_133120"
          x1="756.711"
          y1="695.545"
          x2="765.493"
          y2="695.545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_3583_133120"
          x1="765.487"
          y1="690.479"
          x2="774.269"
          y2="690.479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_3583_133120"
          x1="774.264"
          y1="685.412"
          x2="783.046"
          y2="685.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_3583_133120"
          x1="631"
          y1="743.069"
          x2="723.641"
          y2="743.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3F8" />
          <stop offset="0.3" stopColor="#C7CCF2" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_3583_133120"
          x1="637.64"
          y1="713.061"
          x2="717.014"
          y2="713.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9AFD3" />
          <stop offset="0.7" stopColor="#B5BADF" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_3583_133120"
          x1="655.287"
          y1="729.427"
          x2="676.951"
          y2="729.427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBC0E6" />
          <stop offset="1" stopColor="#A9AFD3" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_3583_133120"
          x1="354.861"
          y1="620.599"
          x2="428.467"
          y2="620.599"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.45" stopColor="#C4C9E4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_3583_133120"
          x1="415.658"
          y1="634.051"
          x2="471.779"
          y2="634.051"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.45" stopColor="#C4C9E4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_3583_133120"
          x1="74.0269"
          y1="522.441"
          x2="133.747"
          y2="522.441"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.43" stopColor="#C4CAE9" />
          <stop offset="0.58" stopColor="#D7DBF0" />
          <stop offset="0.85" stopColor="#F4F5FB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_3583_133120"
          x1="60"
          y1="526.099"
          x2="123.668"
          y2="526.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED3F8" />
          <stop offset="0.42" stopColor="#C4C9EF" />
          <stop offset="1" stopColor="#BBC0E6" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_3583_133120"
          x1="635.557"
          y1="187.389"
          x2="699.978"
          y2="187.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C9E5" />
          <stop offset="0.25" stopColor="#C8CDE7" />
          <stop offset="0.52" stopColor="#D6D9ED" />
          <stop offset="0.8" stopColor="#EBEDF6" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_3583_133120">
          <rect width="812" height="934" rx="49.0232" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Map;
