import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import ChannelRequest from '../../features/admin/channel_request/ChannelRequest';
import EmailVerification from '../../features/user/status/email_verification/EmailVerification';
import SessionExpired from '../../features/user/status/session_expired/SessionExpired';
import NotFound from '../../foundation/components/not_found/NotFound';
import PrivateRoute from '../../foundation/components/private_route/PrivateRoute';
import PublicRoute from '../../foundation/components/public_route/PublicRoute';
import SubscriptionExpired from '../../foundation/components/subscription_expired/SubscriptionExpired';
import Unauthorized from '../../foundation/components/unauthorized/Unauthorized';
import { privateRoutes, publicRoutes } from '../../foundation/config/routes';
import { auth0Util } from '../../foundation/utils/auth0';
import env_constants from '../../internals/env/env_constants.json';
import { setClientIp } from '../user/redux/slice';
import Error from '../user/status/error/Error';

const Startup = () => {
  const dispatch = useDispatch();

  const { logout } = useAuth0();

  auth0Util.setLogout(logout);

  // Add to store (client IP & session key), for component utilization
  const clientIp = sessionStorage.getItem('_c');

  if (clientIp) {
    dispatch(setClientIp(clientIp));
  }

  // @ts-ignore
  window.piAId = env_constants.PI_A_ID;
  // @ts-ignore
  window.piCId = env_constants.PI_C_ID;
  // @ts-ignore
  window.piHostname = env_constants.PI_HOST_NAME;

  return (
    <Routes>
      {privateRoutes.map((route: any) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateRoute requiredFeature={route.feature}>
              <route.element />
            </PrivateRoute>
          }
        />
      ))}
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PublicRoute>
              <route.element />
            </PublicRoute>
          }
        />
      ))}
      <Route path="/channel-request" element={<ChannelRequest />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/subscription-expired" element={<SubscriptionExpired />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/session-expired" element={<SessionExpired />} />
      {/* We need to think about this Error component whether it should be Private or Public */}
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Startup;
