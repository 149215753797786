import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import account from '../features/account/redux/slice';
import aiReport from '../features/ai_report/redux/slice';
import chat from '../features/chat/redux/slice';
import layout from '../features/layout/redux/slice';
import report from '../features/report/redux/slice';
import search from '../features/search/redux/slice';
import details from '../features/search/view/details/redux/slice';
import user from '../features/user/redux/slice';

export interface IRootState {
  router: RouterState;
}

// Root Reducer
const root = (history: History) => {
  const combinedReducer = combineReducers({
    router: connectRouter(history),
    user,
    chat,
    layout,
    search,
    details,
    account,
    report,
    aiReport,
  });

  return (state: any, action: any) => {
    return combinedReducer(state, action);
  };
};

export default root;
