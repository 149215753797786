import React from 'react';

function Lock() {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10V6C6 4.67392 6.52678 3.40215 7.46447 2.46447C8.40215 1.52678 9.67392 1 11 1C12.3261 1 13.5979 1.52678 14.5355 2.46447C15.4732 3.40215 16 4.67392 16 6V10M1 12C1 10.3431 2.34315 9 4 9H18C19.6569 9 21 10.3431 21 12V20C21 21.6569 19.6569 23 18 23H4C2.34315 23 1 21.6569 1 20V12Z"
        stroke="#651fff"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Lock;
