import CryptoES from 'crypto-es';

import env_constants from '../../internals/env/env_constants.json';

const BINARY_ENCODING = 'base64';

export const getPassKeyAndIV = () => {
  const sessionKey = Buffer.from(
    env_constants.DEFAULT_SESSION_KEY,
    BINARY_ENCODING,
  ).toString('utf8');

  const splitAt = 32;

  // [AES key, IV] respectively
  return [sessionKey.substring(0, splitAt), sessionKey.substring(splitAt)];
};

export const getPasswordHash = (param: string) => {
  return CryptoES.HmacSHA512(param, env_constants.HASH_KEY).toString();
};

export const getClientID = (clientIp: string, timeStamp?: string) => {
  const baseString = `${navigator.userAgent}/${clientIp}`;

  if (timeStamp) {
    return `${baseString}/${timeStamp}`;
  } else {
    return encryptData(baseString);
  }
};

export const decryptData = (data: any) => {
  const passKeyIV = getPassKeyAndIV();
  const decrypted = CryptoES.AES.decrypt(
    data,
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const encryptData = (data: any) => {
  const passKeyIV = getPassKeyAndIV();
  const encrypted = CryptoES.AES.encrypt(
    typeof data === 'string' ? data : JSON.stringify(data),
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return encrypted.toString();
};

export const getRandomString = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const charactersLength = characters.length;

  const values = new Uint32Array(length);
  crypto.getRandomValues(values);

  for (let i = 0; i < length; i++) {
    result += characters[values[i] % charactersLength];
  }

  return result;
};
