import React, { useState } from 'react';

import { mapStyleOptions } from './map_style_options';

const MapStylePicker = ({ onMapStyleChange }: any) => {
  const [activeMapStyle, setActiveMapStyle] = useState(mapStyleOptions[0]);

  const handleOnClick = (e: any) => {
    const path = e.target.getAttribute('data-map-style');
    const mapStyle = mapStyleOptions.filter((style: any) => {
      return style.path === path;
    })[0];

    setActiveMapStyle(mapStyle);
    onMapStyleChange(mapStyle);
  };

  return (
    <>
      <ul className="l-map-style-picker">
        {mapStyleOptions.map((style: any, index: number) => {
          return (
            <li
              key={index}
              className={`l-map-style-picker__option${
                style.path === activeMapStyle.path
                  ? ' l-map-style-picker__option--active'
                  : ''
              }`}
            >
              <button>
                <img
                  src={`map_styles/${style.img}.png`}
                  data-map-style={style.path}
                  onClick={handleOnClick}
                  alt=""
                />
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default MapStylePicker;
