import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';

import PolygonMessageIcons from '../../../../foundation/assets/svgs/PolygonMessageIcon';
import { UpdateOrderSteps } from '../../types';

type SeatElementsProps = {
  subscriptionFrequency: string | undefined;
  setAdditionalSeats: React.Dispatch<React.SetStateAction<number>>;
  additionalSeats: number;
  minSeatsAllowed: number;
  updateStep: UpdateOrderSteps;
};

const SeatElements = ({
  subscriptionFrequency,
  additionalSeats,
  setAdditionalSeats,
  minSeatsAllowed,
  updateStep,
}: SeatElementsProps) => {
  const handleAddSeat = () => {
    setAdditionalSeats((v) => v + 1);
  };

  const handleRemoveSeat = () => {
    const newSeats = additionalSeats - 1;
    if (newSeats >= minSeatsAllowed) {
      setAdditionalSeats(newSeats);
    }
  };

  return (
    <div className="l-update-plan__content-container">
      <div className="l-update-plan__content-section">
        <div
          className={`l-update-plan__plan-card l-update-plan__plan-card--payment l-update-plan__plan-card--selected`}
        >
          <div>
            <div className="l-update-plan__plan-name">Additional Seats</div>
            <div className="l-update-plan__plan-details">
              $50 per month per seat
            </div>
          </div>
          <div>
            <Input
              disabled
              type="number"
              className="l-billing-summary__promo-field l-update-plan__add-user-input"
              style={{ width: 80 }}
              value={additionalSeats}
            />

            <Button
              className="l-update-plan__add-user-btn"
              shape="circle"
              icon={<MinusOutlined />}
              size={'large'}
              onClick={handleRemoveSeat}
              disabled={updateStep === 2 || additionalSeats === minSeatsAllowed}
            />

            <Button
              className="l-update-plan__add-user-btn"
              shape="circle"
              icon={<PlusOutlined />}
              size={'large'}
              onClick={handleAddSeat}
              disabled={updateStep === 2}
            />
          </div>
        </div>
        <div className="l-update-plan__message-container">
          <div className="l-update-plan__message-icon">
            <PolygonMessageIcons />
          </div>
          <div className="l-update-plan__message">
            If you would like to reduce the number of additional seats please
            contact support at{' '}
            <a href="mailto:support@rhomeo.com">support@rhomeo.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatElements;
