import { Popover } from 'antd';
import { isEmpty, omit } from 'lodash';
import React from 'react';

import { metricKeyValues } from '../../../../foundation/custom_hooks/useMetricKeyValues';
import { useAppSelector } from '../../../../store/hooks';
import Heading from '../../common/heading/Heading';
import { selectEnabledSearchMetrics } from '../../redux/selectors';

const Suburbs = ({
  results,
  isLargeScreen,
  isMobileView,
  listContainerSize,
}: any) => {
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const renderMetricSummary = (result: any, index: number) => {
    const halfView =
      listContainerSize === 'default' || listContainerSize === 'wide';

    const DEFAULT_METRICS: any = {
      medianSalePrice: null,
      grossRentalYield: null,
      medianPriceGrowth1: null,
      medianPriceGrowth3: null,
      averageDaysMarket: null,
      vacancyRate: null,
      population: null,
      populationForecastRate3: null,
      populationForecastRate5: null,
      lgaTotalEstInvestment: null,
      quarterSalesGrowth: null,
      yearSalesGrowth: null,
      yearRentalGrowth1: null,
      medianSalePriceForecast15: null,
      medianWeeklyAskingRentForecast15: null,
    };

    const metrics: any = {
      ...DEFAULT_METRICS,
      ...omit(result, [
        'suburbName',
        'postCode',
        'lgaName',
        'state',
        'suburbCode',
        'sscCode',
        'latitude',
        'longitude',
        'isFavorite',
        'populationForecastDesc',
      ]),
    };

    const iteratee = (key: string) => {
      return { [key]: metrics[key] };
    };

    const renderMetric = (key: string, index: number) => {
      const classNameBase = 'l-metric-summary__details';

      let className = classNameBase;
      let value = metrics[key];

      const isForecastValue =
        (key === 'populationForecastRate3' ||
          key === 'populationForecastRate5') &&
        !!result.populationForecastDesc;

      if (!metrics[key]) {
        className = `${className} ${classNameBase}--muted`;
        value = 'no data';
      }

      if (enabledSearchMetrics.includes(key)) {
        className = `${className} ${classNameBase}--bold`;
      }

      return (
        <dl className={className} key={index}>
          <dt className="l-metric-summary__metric-name">
            {
              metricKeyValues.suburb.filter((metricKeyValue: any) => {
                return metricKeyValue.value === key;
              })[0].key
            }
          </dt>
          <dd className="l-metric-summary__metric-value">
            <span>{value}</span>
            {isForecastValue && (
              <Popover
                content={
                  <p className="l-descriptor">
                    {result.populationForecastDesc}
                  </p>
                }
                title={null}
                placement="top"
                trigger="click"
              >
                <span className="l-descriptor-trigger--small">i</span>
              </Popover>
            )}
          </dd>
        </dl>
      );
    };

    const renderSummary = () => {
      const render = (metricsObject: any, withBorder: boolean) => {
        return (
          <>
            {!isEmpty(metricsObject) && (
              <div
                className={`l-metric-summary__section${
                  withBorder ? ' l-metric-summary__section--border' : ''
                }`}
              >
                {Object.keys(metricsObject).map((key, index) => {
                  return renderMetric(key, index);
                })}
              </div>
            )}
          </>
        );
      };

      if ((halfView && isLargeScreen) || (!isMobileView && !isLargeScreen)) {
        const metricsObjectHalfLength = Math.round(
          Object.keys(metrics).length / 2,
        );

        const firstHalf = Object.keys(metrics)
          .slice(0, metricsObjectHalfLength)
          .map(iteratee);

        const secondHalf = Object.keys(metrics)
          .slice(metricsObjectHalfLength)
          .map(iteratee);

        return (
          <>
            {render(Object.assign({}, ...firstHalf), false)}
            {render(Object.assign({}, ...secondHalf), true)}
          </>
        );
      }

      return <>{render(metrics, false)}</>;
    };

    return (
      <li className="l-metric-summary" key={index}>
        <Heading suburb={result} />
        <div className="l-metric-summary__content">{renderSummary()}</div>
      </li>
    );
  };
  return (
    <>
      {results.suburbs.map((result: any, index: number) => {
        return <>{renderMetricSummary(result, index)}</>;
      })}
    </>
  );
};

export default Suburbs;
