import React from 'react';

function ArrowRightPurple() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66675 5.44913C6.9882 5.74607 6.9882 6.25401 6.66675 6.55096L2.25891 10.6227C1.77855 11.0665 1 10.7258 1 10.0718V1.92825C1 1.2743 1.77855 0.933599 2.25891 1.37734L6.66675 5.44913Z"
        fill="#651FFF"
        stroke="#651FFF"
      />
    </svg>
  );
}

export default ArrowRightPurple;
