import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useBreakpoint from '../../foundation/custom_hooks/useBreakpont';
import Header from './header/Header';
import MobileContentSider from './mobile_content_sider/MobileContentSider';
import { selectMobileContentSiderIsVisible } from './redux/selectors';
import Sider from './sider/Sider';

const { Content } = Layout;

type Props = {
  children: any;
};
const AppLayout = ({ children }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [isMobileView, isDesktopView] = useBreakpoint();

  const isMobileContentSiderVisible = useSelector(
    selectMobileContentSiderIsVisible,
  );

  useEffect(() => {
    if (isMobileView) {
      setIsCollapsed(true);
    }
  }, [isMobileView]);

  const outerLayoutRenderer = () => {
    if (isMobileView && !isMobileContentSiderVisible) {
      return (
        <Header setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
      );
    } else if (isMobileView && isMobileContentSiderVisible) {
      return <MobileContentSider />;
    } else if (isDesktopView) {
      return (
        <Sider setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
      );
    }
  };

  const innerLayoutRenderer = () => {
    if (isMobileView && !isMobileContentSiderVisible) {
      return (
        <Sider setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
      );
    } else if (!isMobileContentSiderVisible) {
      return (
        <Header setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
      );
    }
  };

  return (
    <Layout className="c-app-layout">
      {outerLayoutRenderer()}

      <Layout>
        {innerLayoutRenderer()}
        <Content
          style={{
            display: isMobileView && !isCollapsed ? 'none' : 'flex',
          }}
          className="c-app-content l-layout"
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
