import React from 'react';

function MarkerLight({
  color = '#651FFF',
  colorFill = 'none',
}: {
  color?: string;
  colorFill?: string;
}) {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill={colorFill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1687 23.0727C14.8077 20.9022 21 15.346 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 15.346 7.19234 20.9022 9.83134 23.0727C10.5162 23.636 11.4838 23.636 12.1687 23.0727ZM11 14C12.6569 14 14 12.6569 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 12.6569 9.34315 14 11 14Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export default MarkerLight;
