import React from 'react';

type SectionTitleProps = {
  title: string;
};
const SectionTitle = ({ title }: SectionTitleProps) => {
  return <div className="l-billing__section-title">{title}</div>;
};

export default SectionTitle;
