import { Alert, Col, Popover, Row, Table } from 'antd';
import { omit } from 'lodash';
import React, { useEffect } from 'react';

import Info from '../../../../../../../foundation/assets/svgs/Info';
import useBreakpoint from '../../../../../../../foundation/custom_hooks/useBreakpont';
import SearchLoader from '../../../../../form/search/search_loader/SearchLoader';

const View = ({
  growth,
  descriptor,
  fetchGrowthData,
  user,
  suburb,
  isLoading,
  isFirstLoad,
  listContainerSize,
}: any) => {
  const labelReference = [
    {
      key: 'growth6Months',
      value: 'Past Growth 6 Months',
    },
    {
      key: 'growth12Months',
      value: 'Past Growth 12 Months',
    },
    {
      key: 'growth3Years',
      value: 'Past Growth 3 Years',
    },
    {
      key: 'growth5Years',
      value: 'Past Growth 5 Years',
    },
  ];

  const [isMobileView] = useBreakpoint();

  const { Column, ColumnGroup } = Table;

  useEffect(() => {
    if (!isFirstLoad && user && suburb) {
      fetchGrowthData();
    }
  }, [suburb]);

  useEffect(() => {
    if (isFirstLoad) {
      fetchGrowthData();
    }
  }, []);

  const renderValue = (entry: any) => {
    if (!entry || entry.value === 'n/a') {
      return <span className="h-text--muted">n/a</span>;
    }

    const className =
      entry.color === '#00FF00' ? 'h-text--positive' : 'h-text--negative';

    const value =
      entry.value.includes('-') && entry.value !== '0%'
        ? entry.value
        : `+${entry.value}`;

    return (
      <>
        <strong className={className}>{value}</strong>
      </>
    );
  };

  const renderTitle = (labelRef: any) => {
    return (
      <div className="l-table-descriptor">
        <span>{labelRef.value}</span>
        <Popover
          content={
            <p className="l-descriptor">
              {
                descriptor[
                  `past${labelRef.key
                    .charAt(0)
                    .toUpperCase()}${labelRef.key.slice(1)}`
                ]
              }
            </p>
          }
          title={null}
          placement="top"
          trigger="click"
        >
          <span style={{ cursor: 'pointer', marginLeft: '8px' }}>
            <Info />
          </span>
        </Popover>
      </div>
    );
  };

  if (!!suburb && !growth && isLoading) {
    return <SearchLoader classNames="l-search-loader--report-transparent" />;
  }

  if ((!growth || !growth.length) && !isLoading) {
    return (
      <Col span={24}>
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <div className="l-dashboard-card">
              <div className="l-dashboard-card__body">
                <Alert
                  message="No Median Sales Price data available"
                  type="warning"
                  showIcon
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Row gutter={[15, 15]}>
        <>
          {growth.map((indicator: any, index: number) => {
            const verticalData: any[] = [];
            const data = omit(indicator, 'indicator');

            for (const key in data) {
              verticalData.push({
                dataKey: renderTitle(
                  labelReference.filter((label: any) => {
                    return label.key === key;
                  })[0],
                ),
                dataValue: renderValue(data[key]),
              });
            }

            return (
              <Col
                span={
                  listContainerSize === 'default' ||
                  listContainerSize === 'wide' ||
                  !isMobileView
                    ? !isMobileView
                      ? 12
                      : 24
                    : 24
                }
                key={index}
              >
                <div
                  className="l-dashboard-card"
                  style={{
                    height: 'auto',
                  }}
                >
                  <div className="l-growth l-dashboard-card__body l-dashboard-card__body--no-padding">
                    <Table pagination={false} dataSource={verticalData}>
                      <ColumnGroup
                        title={
                          <div className="l-growth__box-in">
                            {indicator.indicator}
                          </div>
                        }
                      >
                        <Column
                          title=""
                          dataIndex="dataKey"
                          key="dataKey"
                          className="h-display-none"
                          width={'50%'}
                        />
                        <Column
                          title=""
                          dataIndex="dataValue"
                          key="dataValue"
                          className="h-display-none"
                          width={'50%'}
                        />
                      </ColumnGroup>
                    </Table>
                  </div>
                </div>
              </Col>
            );
          })}
        </>
      </Row>
    </Col>
  );
};

export default View;
