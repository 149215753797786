import { CometChat } from '@cometchat-pro/chat';

import env_constants from '../../../internals/env/env_constants.json';

/**
 *
 * Initializes the Comet Chat feature.
 * sample uid: superhero1
 */
export const initializeComitChat = (
  appID: string,
  authKey: string,
  uid: string,
) => {
  return new Promise((resolve, reject) => {
    const region = env_constants.COMET_CHAT_REGION;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();

    CometChat.init(appID, appSetting).then(
      () => {
        console.log('Initialization completed successfully');

        if (CometChat.setSource) {
          CometChat.setSource('ui-kit', 'web', 'reactjs');
        }

        // You can now call login function.
        CometChat.login(uid, authKey).then(
          (user) => {
            console.log('Login Successful:', { user });
            resolve(user);
          },
          (error) => {
            console.log('Login failed with exception:', { error });
            reject(error);
          },
        );
      },
      (error) => {
        console.log('Initialization failed with error:', error);
        reject(error);
        // Check the reason for error and take appropriate action.
      },
    );
  });
};
