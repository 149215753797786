export default (name: string) => {
  // Split the name into words
  const words = name.trim().split(' ');

  // Get the first letter of the first word
  let initials = words[0].charAt(0);

  // If there is a second word, get its first letter
  if (words.length > 1) {
    const secondWordInitial = words[1].charAt(0);

    // Check if there is a last word in the name
    if (words.length === 2) {
      initials += secondWordInitial;
    } else {
      // If the second word is not a single character, get the first letter of the last word
      const lastWordInitial = words[words.length - 1].charAt(0);
      initials += lastWordInitial;
    }
  }

  // If the initials are longer than 2 characters, truncate them
  if (initials.length > 2) {
    initials = initials.substring(0, 2);
  }

  // Convert the initials to uppercase and return them
  return initials.toUpperCase();
};
