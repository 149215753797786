import { Button, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import LinkIcon from '../../../../foundation/assets/svgs/LinkIcon';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectClientIp,
  selectOrgId,
  selectUser,
} from '../../../user/redux/selectors';
import { suburbShare } from '../../redux/async_thunks';
import { selectSearchResultsPropertyType } from '../../redux/selectors';

type ShareReportModalType = {
  suburb: any;
  handleClose: () => void;
};
const ShareReportModal = ({ suburb, handleClose }: ShareReportModalType) => {
  const [isShareReportModalLoading, setShareReportModalLoading] =
    useState(false);

  const [reportLink, setReportLink] = useState<undefined | string>(undefined);

  const [shareModalType, setShareModalType] = useState<'generate' | 'share'>(
    'generate',
  );

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const clientIp = useAppSelector(selectClientIp);
  const organizationId = useAppSelector(selectOrgId);
  const propertyType = useAppSelector(selectSearchResultsPropertyType);

  const handleReportModalCancel = () => {
    if (isShareReportModalLoading) {
      return;
    }
    handleClose();
  };
  const modalProps = {
    wrapClassName: 'l-share-report-modal',
    closable: true,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title:
      shareModalType === 'generate'
        ? // ? 'Select suburb metrics'
          'Share Suburb Report'
        : 'Share Suburb Report',
    width: 450,
    onCancel: handleReportModalCancel,
    closeIcon: (
      <strong className="l-share-report-modal__close">
        <Close />
      </strong>
    ),
  };

  useEffect(() => {
    handleSuburbShare();
  }, []);

  const handleSuburbShare = async () => {
    if (isShareReportModalLoading) {
      return;
    }

    try {
      setShareReportModalLoading(true);

      if (user) {
        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        const reqData = {
          userId: user.userId,
          orgId: organizationId,
          propertyType: propertyType,
          suburbCode: suburb?.suburbCode,
        };

        const response: any = await dispatch(
          suburbShare({
            data: reqData,
            options,
          }),
        ).unwrap();

        setReportLink(response.suburbShareLink);

        setShareModalType('share');

        setShareReportModalLoading(false);
      }
    } catch (error) {
      setShareReportModalLoading(false);
    }
  };

  const handleCopyReportLink = () => {
    if (reportLink) {
      navigator.clipboard.writeText(reportLink);
      message.success('Link copied!');
    }
  };

  return (
    <Modal {...modalProps}>
      <div
        className={`l-share-report-modal__content-wrapper`}
        style={{ minHeight: '200px' }}
      >
        {isShareReportModalLoading && <FullPageLoader />}
        {shareModalType === 'generate' && (
          <>
            {/* <div className="l-share-report-modal__subtitle">
              Select which suburb metrics you would like to display on your
              report?
            </div>
            <div className="l-share-report-modal__options-wrapper">
              Options to come here later.
            </div>
            <div className="l-share-report-modal__btn-wrapper">
              <Button
                className="l-share-report-modal__btn"
                type="primary"
                onClick={handleSuburbShare}
              >
                Generate Report
              </Button>
            </div> */}
          </>
        )}

        {shareModalType === 'share' && (
          <>
            <div className="l-share-report-modal__share-subtitle">
              Generated suburb report link for{' '}
              <span className="l-share-report-modal__suburb-name">
                {suburb.name}
              </span>
            </div>
            <div className="l-share-report-modal__link-wrapper">
              <div className="l-share-report-modal__report-link-label">
                Suburb report link
              </div>
              <div className="l-share-report-modal__report-link">
                {reportLink}
              </div>
            </div>
            <div className="l-share-report-modal__btn-wrapper">
              <Button className="l-share-report-modal__report-btn l-share-report-modal__report-btn--view">
                <a
                  href={`https://${reportLink}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Report
                </a>
              </Button>
              <Button
                type="primary"
                onClick={handleCopyReportLink}
                className="l-share-report-modal__report-btn"
                icon={<LinkIcon />}
              >
                Copy link
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ShareReportModal;
