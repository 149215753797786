import { CopyOutlined } from '@ant-design/icons';
import { BackTop, message, notification } from 'antd';
import { debounce } from 'debounce';
import { map as pluck, omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';

import ArrowLeftTail from '../../foundation/assets/svgs/ArrowLeftTail';
import Close from '../../foundation/assets/svgs/Close';
import PoweredLogo from '../../foundation/assets/svgs/PoweredLogo';
import Search from '../../foundation/assets/svgs/Search';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SearchLoader from '../search/form/search/search_loader/SearchLoader';
import { selectClientIp, selectUser } from '../user/redux/selectors';
import {
  postAiSuburbReport,
  postAiSuburbReportSuggestions,
} from './redux/async_thunks';
import {
  selectAiReportResults,
  selectReportKeyword,
  selectReportQueryValues,
  selectReportSuggestions,
} from './redux/selectors';
import {
  resetAiReport,
  resetSuggestions,
  setKeyword,
  setQueryValues,
} from './redux/slice';

const AiReport = () => {
  const KEYWORD_MIN_LENGTH = 2;
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const suggestions = useAppSelector(selectReportSuggestions);
  const queryValues = useAppSelector(selectReportQueryValues);
  const keyword = useAppSelector(selectReportKeyword);
  const report = useAppSelector(selectAiReportResults);

  const [searchKeywordDisplay, setSearchKeywordDisplay] = useState('');
  const [copyText, setCopyText] = useState('');
  const [blocks, setBlocks]: any = useState([]);
  const [width, setWidth] = useState<any>(0);

  const phantomTextRef = useRef();
  const inputRef = useRef(null);
  const selectedRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  function parseEscapeSequencesToHTML(text: any) {
    const escapeSequences: any = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '\n': '<br>',
      '\r': '',
      '\t': '&nbsp;&nbsp;&nbsp;&nbsp;',
    };

    return text.replace(
      /[&<>"'\n\r\t]/g,
      (match: any) => escapeSequences[match],
    );
  }

  function ParsedText({ text }: any) {
    const parsedText = parseEscapeSequencesToHTML(text);
    return <div dangerouslySetInnerHTML={{ __html: parsedText }} />;
  }

  function convertCamelCaseToHeading(text: any) {
    const words = text.replace(/([A-Z])/g, ' $1').split(' ');
    const heading = words
      .map((word: string, index: number) => {
        if (index > 0 && (word === 'And' || word === 'and')) {
          return '&';
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return heading;
  }

  function removeEscapeSequencesAtStart(text: any) {
    if (text.startsWith('\n\n')) {
      return text.slice(2);
    }
    return text;
  }

  function extractStateAndPostcode(pattern: any) {
    const stateMatch = pattern.match(/^[A-Za-z]{2,3}/);
    const postCodeMatch = pattern.match(/\d+/);

    const state = stateMatch ? stateMatch[0] : '';
    const postCode = postCodeMatch ? postCodeMatch[0] : '';

    return { state, postCode };
  }

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const buildReport = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        postAiSuburbReport({
          data: {
            userId: USER.userId,
            suburbCode: pluck(queryValues, 'value')[0],
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      ).unwrap();

      if (!response) {
        notification.warning({
          message: 'No suburb report found',
          description: 'Sorry, please try entering a different suburb.',
          placement: 'topRight',
          top: 100,
        });
      }
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };

  const fetchSuggestions = async (searchKeyword: string) => {
    try {
      await dispatch(
        postAiSuburbReportSuggestions({
          data: {
            userId: USER.userId,
            searchKeyword: searchKeyword,
            selectedValues: queryValues.length
              ? pluck(queryValues, 'value')
              : null,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      );
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setKeyword(searchKeyword));
  };

  const setSearchKeywordRealValue = (e: any) => {
    const value = e.target.value;

    if (!value.length) {
      setSearchKeywordDisplay('');
    }

    if (value.length < KEYWORD_MIN_LENGTH) {
      dispatch(setKeyword(undefined));
      dispatch(resetSuggestions());
    } else {
      fetchSuggestions(value);
    }
  };

  const scrollToBottom = (ref: any, scrollHeight = 0) => {
    if (ref?.current) {
      ref.current.scrollTop = ref?.current?.scrollHeight - scrollHeight;
    }
  };

  const isAllSelected =
    suggestions.filter((suggestion: any) => {
      return queryValues.some((queryValue: any) => {
        return queryValue.value === suggestion.value;
      });
    }).length === suggestions.length;

  useEffect(() => {
    if (searchKeywordDisplay) {
      // @ts-ignore
      setWidth(phantomTextRef.current.offsetWidth + 100);
    } else {
      setWidth('100%');
    }
  }, [searchKeywordDisplay]);

  useEffect(() => {
    scrollToBottom(selectedRef);
  }, [queryValues]);

  useEffect(() => {
    if (report) {
      setBlocks(omit(report, ['suburb', 'propertyType']));
    }
  }, [report]);

  useEffect(() => {
    if (report && blocks) {
      buildCopyText();
    }
  }, [blocks]);

  const renderForm = () => {
    return (
      <div className="l-ai-report__form-wrapper">
        <div className="l-ai-report__form">
          <h2 className="l-ai-report__heading">Suburb Report Builder</h2>
          <p className="l-ai-report__sub-heading">
            Powered by <PoweredLogo /> Data Science Team
          </p>
          <p className="l-ai-report__sub-heading">
            Goldie is rhomeo&apos;s AI magician that can create the most
            advanced in-depth suburb reports that are sure to WOW anyone in the
            realestate industry. <br />
            Simply type in the suburb and start building your report.
          </p>
          <div className="l-ai-report__input">
            <div className="l-ai-report__input-content">
              <div className="l-ai-report__input-field-wrapper">
                <div className="l-ai-report__search-icon">
                  <Search color="#000" />
                </div>
                <div
                  className="l-ai-report__selected"
                  // @ts-ignore
                  ref={selectedRef}
                >
                  {queryValues.map((queryValue: any) => {
                    const details = extractStateAndPostcode(queryValue.value);

                    return (
                      <div
                        className="l-ai-report__overflow-item-suggestion"
                        key={queryValue.value}
                      >
                        <span>{queryValue.key}</span>
                        <small className="l-ai-report__bullet"></small>
                        <span
                          className="l-ai-report__desc"
                          style={{ opacity: 1 }}
                        >
                          {details.postCode} {details.state}
                        </span>
                        <strong
                          onClick={() => {
                            dispatch(
                              setQueryValues(
                                queryValues.filter((queryValueCurrent: any) => {
                                  return (
                                    queryValueCurrent.value !== queryValue.value
                                  );
                                }),
                              ),
                            );
                            // @ts-ignore
                            inputRef.current.focus();
                          }}
                          className="l-ai-report__overflow-item-remove"
                        >
                          <Close />
                        </strong>
                      </div>
                    );
                  })}
                  {!queryValues.length && (
                    <div className="l-ai-report__overflow-item">
                      <span
                        className="l-ai-report__phantom-text"
                        // @ts-ignore
                        ref={phantomTextRef}
                      >
                        <span>{searchKeywordDisplay}</span>
                      </span>
                      <input
                        placeholder={'Suburb name'}
                        onChange={debounce(setSearchKeywordRealValue, 200)}
                        onKeyUp={() => {
                          // @ts-ignore
                          setSearchKeywordDisplay(inputRef.current.value);
                        }}
                        style={{
                          width,
                        }}
                        autoFocus
                        defaultValue={keyword}
                        ref={inputRef}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  buildReport();
                }}
                className="l-ai-report__button"
                disabled={!queryValues.length}
              >
                Build Suburb Report
              </button>
            </div>
          </div>
          <div className="l-ai-report__suggestions-anchor">
            {!queryValues.length &&
              suggestions &&
              !!suggestions.length &&
              !isAllSelected && (
                <div className="l-ai-report__suggestions">
                  <ul className="l-ai-report__suggestions-list">
                    {suggestions.map((suggestion: any) => {
                      if (
                        queryValues.some((queryValue: any) => {
                          return queryValue.value === suggestion.value;
                        })
                      ) {
                        return null;
                      }

                      const details = extractStateAndPostcode(suggestion.value);

                      return (
                        <div
                          className="l-ai-report__suggestions-item"
                          key={suggestion.value}
                          onClick={() => {
                            dispatch(
                              setQueryValues([...queryValues, ...[suggestion]]),
                            );

                            dispatch(resetSuggestions());
                            dispatch(setKeyword(undefined));

                            // @ts-ignore
                            inputRef.current.focus();
                            // @ts-ignore
                            inputRef.current.value = '';
                          }}
                        >
                          <span>
                            {reactStringReplace(
                              suggestion.key,
                              keyword,
                              (match, index) => {
                                return <strong key={match}>{match}</strong>;
                              },
                            )}
                          </span>
                          <small className="l-ai-report__bullet"></small>
                          <span className="l-ai-report__desc">
                            {details.postCode} {details.state}
                          </span>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            {!queryValues.length && !suggestions.length && keyword && (
              <div className="l-ai-report__suggestions">
                <div className="l-ai-report__suggestions-empty">
                  No suggestions found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const copyTextToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText).then(
        () => {
          messageApi.info('Report copied to clipboard!');
        },
        (err) => {
          console.error('Copy to clipboard failed:', err);
          messageApi.error('Unable to copy report to clipboard!');
        },
      );
    } else {
      console.warn('Clipboard API not available in this browser.');
    }
  };

  const buildCopyText = () => {
    const copy = '';

    const title = `${report.suburb} Suburb Report\n`;
    const propertyType = `${report.propertyType} Market`;
    const textBlocks: any = [];

    Object.keys(blocks).map((key) =>
      textBlocks.push(
        `\n\n\n${convertCamelCaseToHeading(key)}\n\n${
          blocks[key]
            ? removeEscapeSequencesAtStart(blocks[key].trim())
            : 'No details.'
        }`,
      ),
    );

    setCopyText(copy.concat(title, propertyType, textBlocks.join('')));
  };

  const renderResult = () => {
    return (
      <div className="l-ai-report__result" id="overflow">
        <div className="l-ai-report__result-content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '60px',
            }}
          >
            <button
              onClick={() => {
                dispatch(resetAiReport());
                setCopyText('');
                setBlocks([]);
              }}
              className="l-ai-report__back-button"
            >
              <ArrowLeftTail />
              Back
            </button>
            {contextHolder}
            <button
              onClick={() => {
                copyTextToClipboard();
              }}
              className="l-ai-report__copy-button"
            >
              <CopyOutlined />
              Copy Report
            </button>
          </div>
          <h3>
            {report.suburb} Suburb Report
            <span className="l-ai-report__property-type">
              {report.propertyType} Market
            </span>
          </h3>
          {Object.keys(blocks).map((key) => (
            <div key={key} className="l-ai-report__block">
              <h4>{convertCamelCaseToHeading(key)}</h4>
              {blocks[key] ? (
                <ParsedText
                  key={key}
                  text={removeEscapeSequencesAtStart(blocks[key].trim())}
                />
              ) : (
                'No details.'
              )}
            </div>
          ))}
        </div>
        {/* @ts-ignore */}
        <BackTop target={() => document.getElementById('overflow')} />
      </div>
    );
  };

  return (
    <div className="l-ai-report">
      {isLoading && <SearchLoader />}
      {report ? <>{renderResult()}</> : <>{renderForm()}</>}
    </div>
  );
};

export default AiReport;
