import React from 'react';

const Car = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 14.5H16M5 14.5H7.5M8.13238 3H15.8676C16.5701 3 17.2212 3.3686 17.5826 3.97101L19.442 7.07003C19.7882 7.64698 20.4117 8 21.0845 8H21.7639C22.4466 8 23 8.55341 23 9.23607V9.3109C23 9.73323 22.7614 10.1193 22.3836 10.3082C21.7029 10.6485 21.5557 11.5557 22.0939 12.0939L22.5402 12.5402C22.8346 12.8346 23 13.2339 23 13.6503V16.2929C23 16.7456 22.8201 17.1799 22.5 17.5C22.1799 17.8201 22 18.2544 22 18.7071V19C22 20.1046 21.1046 21 20 21H18.5C17.6716 21 17 20.3284 17 19.5C17 18.6716 16.3284 18 15.5 18H8.5C7.67157 18 7 18.6716 7 19.5C7 20.3284 6.32843 21 5.5 21H4C2.89543 21 2 20.1046 2 19V18.7071C2 18.2544 1.82014 17.8201 1.5 17.5C1.17986 17.1799 1 16.7456 1 16.2929V13.6503C1 13.2339 1.1654 12.8346 1.45982 12.5402L1.90613 12.0939C2.44427 11.5557 2.29705 10.6485 1.61635 10.3082C1.23861 10.1193 1 9.73323 1 9.3109V9.23607C1 8.55341 1.55341 8 2.23607 8H2.91548C3.58831 8 4.21181 7.64698 4.55798 7.07003L6.41739 3.97101C6.77884 3.3686 7.42985 3 8.13238 3Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Car;
