import { useEffect, useState } from 'react';

import { selectAllAppRoles } from '../../features/account/redux/selectors';
import { selectUser } from '../../features/user/redux/selectors';
import { useAppSelector } from '../../store/hooks';

export const useRole = () => {
  const user = useAppSelector(selectUser);
  const [role, setRole] = useState('Client');

  const appRoles = useAppSelector(selectAllAppRoles);

  useEffect(() => {
    let userRole = 'Client';
    for (const item of appRoles) {
      if (item.value === user.userRole) {
        userRole = `${item.key}`;
      }
    }

    setRole(userRole);
  }, [user, appRoles]);

  return role;
};
