import { UserOutlined } from '@ant-design/icons';
import { CometChat } from '@cometchat-pro/chat';
import { Modal, notification, Spin } from 'antd';
import debounce from 'debounce';
import { forEach, reject } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import MailBird from '../../../../foundation/assets/svgs/MailBird';
import Send from '../../../../foundation/assets/svgs/Send';
import appMessages from '../../../../foundation/messages';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { inviteTeam } from '../../../account/redux/async_thunks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import fetchUsersByName from '../../helpers/fetchUsersByName';

const InviteMembers = ({
  handleCancel,
  selectedConversation,
  members,
  fetchMembers,
  handleChannelModalDisplay,
}: any) => {
  const MAX_CLIENT_TO_INVITE = 5;
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const phantomTextRef = useRef();
  const fetchRef = React.useRef(0);

  const user = useAppSelector(selectUser);
  const clientIp = useAppSelector(selectClientIp);

  const [membersOrClients, setMembersOrClients] = useState<any>([]);
  const [searchKeywordDisplay, setSearchKeywordDisplay] = useState('');
  const [width, setWidth] = useState<any>(0);
  const [keyword, setKeyword] = useState<any>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [memberOptions, setMemberOptions] = useState<any>([]);
  const [isDetailsVisible, setIsDetailsVisible] = useState<any>(false);

  const [isAddMembersLoading, setIsAddMembersLoading] =
    useState<boolean>(false);
  const [isInviteClientsLoading, setIsInviteClientsLoading] =
    useState<boolean>(false);

  const [addMemberStatus, setAddMemberStatus] = useState<any>(undefined);
  const [inviteClientStatus, setInviteClientStatus] = useState<any>(undefined);

  // const [addMemberStatus, setAddMemberStatus] = useState<any>('success');
  // const [inviteClientStatus, setInviteClientStatus] = useState<any>('success');

  const guid: string = selectedConversation?.conversationWith?.guid;
  const channelName: string = selectedConversation?.conversationWith?.name;

  const modalProps = {
    closable: true,
    footer: null as any,
    maskClosable: false,
    open: true,
    title: (
      <>
        Add people to &lsquo;
        {channelName}&rsquo;
      </>
    ),
    width: 500,
    onCancel: handleCancel,
    centered: true,
  };

  const isPublic =
    selectedConversation &&
    selectedConversation.conversationWith &&
    selectedConversation.conversationWith.type === CometChat.GROUP_TYPE.PUBLIC;

  const debounceTimeout = 500;

  const areAllOptionsSelected =
    !!memberOptions.length &&
    memberOptions.length ===
      membersOrClients.filter((mc: any) => {
        return !!mc.value;
      }).length;

  const addMembersToChannel = () => {
    const membersList: any[] = [];

    const membersToAdd = membersOrClients.filter((mc: any) => {
      return !!mc.value;
    });

    forEach(membersToAdd, (memberToAdd: any) => {
      // if a selected member is already part of the member list, don't add
      const indexFound = members.findIndex(
        (member: any) => member.uid === memberToAdd.value,
      );

      if (indexFound === -1) {
        memberToAdd['type'] = 'add';

        const newMember: CometChat.GroupMember = new CometChat.GroupMember(
          memberToAdd.value,
          CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT,
        );

        membersList.push(newMember);
      }
    });

    if (membersList.length) {
      setIsAddMembersLoading(true);
      CometChat.addMembersToGroup(guid, membersList, [])
        .then(async () => {
          await fetchMembers(selectedConversation);
          setIsAddMembersLoading(false);
          setAddMemberStatus('success');
        })
        .catch((error: CometChat.CometChatException) => {
          console.log(error);
          notification.error({
            description: appMessages.channel.ERROR_ADDING_MEMBER,
            message: 'Error!',
          });
          setIsAddMembersLoading(false);
          setAddMemberStatus('failed');
        });
    } else {
      setAddMemberStatus('none');
    }
  };

  const inviteClientsToChannel = async () => {
    const clientsToInvite = membersOrClients.filter((mc: any) => {
      return !!mc.email;
    });

    try {
      if (clientsToInvite && clientsToInvite.length) {
        setIsInviteClientsLoading(true);

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        const data = {
          userId: user.userId,
          invitees: clientsToInvite,
        };

        await dispatch(
          inviteTeam({
            data,
            options,
          }),
        ).unwrap();
        setInviteClientStatus('success');
        setIsInviteClientsLoading(false);
      } else {
        setInviteClientStatus('none');
      }
    } catch (error) {
      setInviteClientStatus('failed');
      setIsInviteClientsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return false;
    }

    return emailRegex.test(email);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setIsFetching(true);

      fetchUsersByName(value)
        .then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            return;
          }

          setMemberOptions(newOptions);
          setIsFetching(false);
        })
        .catch((error) => {
          setMemberOptions([]);
          reject(error);
        });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchUsersByName, debounceTimeout]);

  const setSearchKeywordRealValue = (e: any) => {
    const value = e.target.value;

    if (!value.length) {
      setSearchKeywordDisplay('');
      setKeyword(undefined);
    } else {
      setKeyword(value);
    }

    debounceFetcher(value);
  };

  const renderHideDetails = () => {
    return (
      <strong
        onClick={() => {
          setIsDetailsVisible(false);
          setKeyword(undefined);
          // @ts-ignore
          inputRef.current.value = '';
        }}
        className="l-invite-members__details-toggle"
      >
        Close
      </strong>
    );
  };

  const renderEmailInput = () => {
    if (
      membersOrClients.find((mc: any) => {
        return keyword === mc.email;
      })
    ) {
      return (
        <div className="l-invite-members__suggestions">
          {renderHideDetails()}
          <div className="l-invite-members__suggestions-empty">
            <span className="s-error">Email address already added</span>
          </div>
        </div>
      );
    }
    if (
      membersOrClients.filter((mc: any) => {
        return !!mc.email;
      }).length === MAX_CLIENT_TO_INVITE
    ) {
      return (
        <div className="l-invite-members__suggestions">
          {renderHideDetails()}
          <div className="l-invite-members__suggestions-empty">
            <span className="s-error">
              You can only invite up to {MAX_CLIENT_TO_INVITE} clients at a time
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="l-invite-members__suggestions">
        {renderHideDetails()}
        <div
          className="l-invite-members__suggestions-item"
          onClick={() => {
            setMembersOrClients([
              ...membersOrClients,
              {
                email: keyword,
                roleId: 0,
                groupId: selectedConversation.conversationWith.guid,
              },
            ]);

            setKeyword(undefined);

            // @ts-ignore
            inputRef.current.focus();
            // @ts-ignore
            inputRef.current.value = '';
            debounceFetcher('');
          }}
        >
          <strong style={{ marginRight: '7px' }}>Invite</strong>
          {keyword}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (searchKeywordDisplay && phantomTextRef.current) {
      // @ts-ignore
      setWidth(phantomTextRef.current.offsetWidth + 100);
    } else {
      setWidth('100%');
    }
  }, [searchKeywordDisplay]);

  if (
    !isAddMembersLoading &&
    !isInviteClientsLoading &&
    addMemberStatus &&
    inviteClientStatus
  ) {
    return (
      <Modal
        {...{
          ...modalProps,
          ...{ title: null, closable: false },
        }}
      >
        <div className="l-invite-members l-invite-members--success">
          <MailBird />
          <div className="l-invite-members__finish-up">
            <h5>
              You&apos;ve invited {membersOrClients.length} people to &lsquo;
              {channelName}&rsquo;
            </h5>
            {inviteClientStatus !== 'none' && (
              <ul className="l-invite-members__summary">
                {membersOrClients
                  .filter((mc: any) => {
                    return !!mc.email;
                  })
                  .map((mc: any, index: number) => {
                    return (
                      <li
                        className="l-invite-members__summary-item"
                        key={index}
                      >
                        <span className="l-invite-members__summary-image">
                          <Send
                            style={{ marginTop: '3px', marginLeft: '-2px' }}
                          />
                        </span>
                        <div className="l-invite-members__summary-item-flex">
                          <div className="l-invite-members__summary-item-email">
                            {mc.email}
                          </div>
                          <div className="l-invite-members__summary-item-status">
                            {inviteClientStatus === 'failed' ? (
                              <span className="s-error">
                                Invite Failed - Client
                              </span>
                            ) : (
                              'Invited - Client'
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
            {addMemberStatus !== 'none' && (
              <ul className="l-invite-members__summary">
                {membersOrClients
                  .filter((mc: any) => {
                    return !!mc.value;
                  })
                  .map((mc: any, index: number) => {
                    return (
                      <li
                        className="l-invite-members__summary-item"
                        key={index}
                      >
                        <span className="l-invite-members__summary-image">
                          <UserOutlined />
                        </span>
                        <div className="l-invite-members__summary-item-flex">
                          <div className="l-invite-members__summary-item-email">
                            {mc.label}
                          </div>
                          <div className="l-invite-members__summary-item-status">
                            {addMemberStatus === 'failed' ? (
                              <span className="s-error">
                                Add Failed - Member
                              </span>
                            ) : (
                              'Added - Member'
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
            {(addMemberStatus === 'failed' ||
              inviteClientStatus === 'failed') && (
              <p
                className="s-error"
                style={{ fontSize: '13px', marginBottom: '16px' }}
              >
                Something went wrong with the request. Please re-try to add or
                invite those that were marked as failed or correct any issues.
              </p>
            )}
            <div className="l-invite-members__summary-actions">
              <button
                className="s-manage"
                onClick={() => {
                  handleCancel();
                  handleChannelModalDisplay();
                }}
              >
                Manage Channel Members
              </button>
              <button
                onClick={() => {
                  handleCancel();
                }}
                className="l-invite-members__button"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal {...modalProps}>
      <Spin spinning={isAddMembersLoading || isInviteClientsLoading}>
        {(!addMemberStatus || !inviteClientStatus) && (
          <div className="l-invite-members">
            {isFetching ? (
              <span className="fetch-spinner">
                <Spin size="small" />
              </span>
            ) : (
              <></>
            )}
            <div className="l-invite-members__form">
              {membersOrClients.map((mc: any, index: string) => {
                return (
                  <div
                    className={`l-invite-members__overflow-item-suggestion${
                      mc.email
                        ? ' l-invite-members__overflow-item-suggestion--email'
                        : ''
                    }`}
                    key={index}
                  >
                    {mc.email && (
                      <span style={{ marginRight: '8px', display: 'flex' }}>
                        <Send />
                      </span>
                    )}
                    {mc.label || mc.email}
                    <strong
                      key={index}
                      onClick={() => {
                        setMembersOrClients(
                          membersOrClients.filter((mcCurrent: any) => {
                            return (
                              mcCurrent.value !== mc.value ||
                              mcCurrent.email !== mc.email
                            );
                          }),
                        );
                        // @ts-ignore
                        // inputRef.current.focus();
                      }}
                      className="l-invite-members__overflow-item-remove"
                    >
                      <Close />
                    </strong>
                  </div>
                );
              })}
              <div className="l-invite-members__overflow-item">
                <span
                  className="l-invite-members__phantom-text"
                  // @ts-ignore
                  ref={phantomTextRef}
                >
                  <span>{searchKeywordDisplay}</span>
                </span>
                <input
                  onChange={setSearchKeywordRealValue}
                  onKeyUp={() => {
                    // @ts-ignore
                    setSearchKeywordDisplay(inputRef.current.value);
                  }}
                  style={{
                    width,
                  }}
                  defaultValue={keyword}
                  ref={inputRef}
                  onFocus={() => {
                    debounceFetcher('');
                    setIsDetailsVisible(true);
                  }}
                  placeholder={`${
                    isPublic ? 'Name or email address' : 'Member name'
                  }`}
                />
              </div>
            </div>
            {isDetailsVisible && (
              <div className="l-invite-members__suggestions-anchor">
                {!!memberOptions.length && !areAllOptionsSelected && (
                  <div className="l-invite-members__suggestions">
                    {renderHideDetails()}
                    <div className="l-invite-members__suggestions-list">
                      {memberOptions.map((mo: any, index: number) => {
                        if (
                          membersOrClients.some((queryValue: any) => {
                            return queryValue.value === mo.value;
                          })
                        ) {
                          return null;
                        }

                        return (
                          <div
                            className="l-invite-members__suggestions-item"
                            key={index}
                            onClick={() => {
                              setMembersOrClients([
                                ...membersOrClients,
                                ...[mo],
                              ]);
                            }}
                          >
                            <span>{mo.label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {!!memberOptions.length && areAllOptionsSelected && (
                  <>
                    {isPublic ? (
                      <>
                        {validateEmail(keyword) ? (
                          renderEmailInput()
                        ) : (
                          <div className="l-invite-members__suggestions">
                            {renderHideDetails()}
                            <div className="l-invite-members__suggestions-empty">
                              {!!keyword && keyword.includes('@') ? (
                                <span>Keep typing a full email</span>
                              ) : (
                                'All options selected - Type in an email address'
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="l-invite-members__suggestions">
                        {renderHideDetails()}
                        <div className="l-invite-members__suggestions-empty">
                          All options selected
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!memberOptions.length && !!keyword && (
                  <>
                    {isPublic ? (
                      <>
                        {validateEmail(keyword) ? (
                          renderEmailInput()
                        ) : (
                          <div className="l-invite-members__suggestions">
                            {renderHideDetails()}
                            <div className="l-invite-members__suggestions-empty">
                              {!!keyword && keyword.includes('@') ? (
                                <span>Keep typing a full email</span>
                              ) : (
                                'No matches found - Try using their email'
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="l-invite-members__suggestions">
                        {renderHideDetails()}
                        <div className="l-invite-members__suggestions-empty">
                          No matches found
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {isPublic && (
              <p style={{ margin: '5px 0 0', color: '#6E7191' }}>
                ex. Sam, or james@gmail.com
              </p>
            )}
            <button
              onClick={() => {
                addMembersToChannel();
                inviteClientsToChannel();
              }}
              disabled={!membersOrClients.length}
              className={`l-invite-members__button${
                !membersOrClients.length
                  ? ' l-invite-members__button--subtle'
                  : ''
              }`}
            >
              {isPublic ? 'Add Or Send Invites' : 'Add Member(s)'}
            </button>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default InviteMembers;
