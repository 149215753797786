import React from 'react';

const ErrorField = ({
  error,
  style,
}: {
  error: string | undefined;
  style?: React.CSSProperties;
}) => {
  return (
    <div className="c-error-field" style={style}>
      {error}
    </div>
  );
};

export default ErrorField;
