import React from 'react';

const AddMember = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6337 11.5868C11.5855 11.0446 9.85658 10.3333 8 10.3333C6.14342 10.3333 4.4145 11.0446 3.36628 11.5868C2.68954 11.9368 2.28197 12.6234 2.1922 13.38L2 15H14L13.8078 13.38C13.718 12.6234 13.3105 11.9368 12.6337 11.5868Z"
        stroke="#651FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.33334C9.65685 7.33334 11 5.9902 11 4.33334C11 2.67649 9.65685 1.33334 8 1.33334C6.34315 1.33334 5 2.67649 5 4.33334C5 5.9902 6.34315 7.33334 8 7.33334Z"
        stroke="#651FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6467 8.64706C14.6467 7.73741 13.9093 7 12.9997 7C12.09 7 11.3526 7.73741 11.3526 8.64706V10.0196H9.98007C9.07042 10.0196 8.33301 10.757 8.33301 11.6667C8.33301 12.5763 9.07042 13.3137 9.98007 13.3137H11.3526V14.6863C11.3526 15.5959 12.09 16.3333 12.9997 16.3333C13.9093 16.3333 14.6467 15.5959 14.6467 14.6863V13.3137H16.0193C16.9289 13.3137 17.6663 12.5763 17.6663 11.6667C17.6663 10.757 16.9289 10.0196 16.0193 10.0196H14.6467V8.64706Z"
        fill="#651FFF"
        stroke="#EFF0F7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddMember;
