import React from 'react';

const SuburbMarker = ({ color }: any) => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5355_142419)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8765 24.3046C18.8557 22.6766 23.5 18.5095 23.5 15.25C23.5 11.1079 20.1421 7.75 16 7.75C11.8579 7.75 8.5 11.1079 8.5 15.25C8.5 18.5095 13.1443 22.6766 15.1235 24.3046C15.6371 24.727 16.3629 24.727 16.8765 24.3046ZM16 17.5C17.2426 17.5 18.25 16.4926 18.25 15.25C18.25 14.0074 17.2426 13 16 13C14.7574 13 13.75 14.0074 13.75 15.25C13.75 16.4926 14.7574 17.5 16 17.5Z"
          fill={color ? color : '#651FFF'}
        />
        <path
          d="M17.5117 25.0769C18.5207 24.247 20.2278 22.7553 21.6916 21.0378C22.4236 20.1788 23.1141 19.2409 23.6258 18.2815C24.133 17.3304 24.5 16.292 24.5 15.25C24.5 10.5556 20.6944 6.75 16 6.75C11.3056 6.75 7.5 10.5556 7.5 15.25C7.5 16.292 7.86704 17.3304 8.37421 18.2815C8.88585 19.2409 9.5764 20.1788 10.3084 21.0378C11.7722 22.7553 13.4793 24.247 14.4883 25.0769C15.371 25.8029 16.629 25.8029 17.5117 25.0769ZM17.25 15.25C17.25 15.9404 16.6904 16.5 16 16.5C15.3096 16.5 14.75 15.9404 14.75 15.25C14.75 14.5596 15.3096 14 16 14C16.6904 14 17.25 14.5596 17.25 15.25Z"
          stroke="#FCFCFC"
          strokeWidth="2"
        />
      </g>
      <circle cx="16" cy="15.25" r="3.75" fill={color ? color : '#651FFF'} />
      <defs>
        <filter
          id="filter0_d_5355_142419"
          x="0.5"
          y="0.75"
          width="31"
          height="32.8714"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5355_142419"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5355_142419"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SuburbMarker;
