import React from 'react';

type Props = {
  color?: string;
};

function Earth({ color }: Props) {
  return (
    <svg
      style={{ width: '45%' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12C23 18.0751 18.0751 23 12 23M23 12C23 5.92487 18.0751 1 12 1M23 12H1M12 23C5.92487 23 1 18.0751 1 12M12 23C12 23 8 20.8 8 12C8 3.2 12 1 12 1M12 23C12 23 16 20.8 16 12C16 3.2 12 1 12 1M1 12C1 5.92487 5.92487 1 12 1"
        stroke={color ? color : '#232e40'}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Earth;
