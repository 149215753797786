import React from 'react';

function ShareBubble() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4583 20.1667C15.7741 20.1667 20.0833 15.8575 20.0833 10.5417C20.0833 5.22601 15.7741 0.916748 10.4583 0.916748C5.1426 0.916748 0.833336 5.22601 0.833336 10.5417C0.833336 12.3371 1.32488 14.0176 2.18072 15.4561L0.833336 20.1667L5.66001 18.8873C7.07251 19.7012 8.71105 20.1667 10.4583 20.1667Z"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ShareBubble;
