import { Modal } from 'antd';
import React from 'react';

import InviteTeam from '../../components/invite_team/InviteTeam';

type InviteModalProps = {
  handleCancel: () => void;
  groupId?: string;
  type: 'members' | 'clients';
};
const InviteModal = ({ handleCancel, groupId, type }: InviteModalProps) => {
  const modalProps = {
    wrapClassName: 'l-onboarding',
    closable: false,
    footer: null as any,
    maskClosable: false,
    open: true,
    title: '',
    width: 650,
    onCancel: handleCancel,
  };

  return (
    <Modal {...modalProps}>
      <div className="l-onboarding__section">
        {type === 'clients' ? (
          <>
            <div className="l-onboarding__section-title">Invite clients</div>
            <div className="l-onboarding__section-info">
              Invite your clients to join your community
              {groupId ? ', and add them directly to this channel' : ''}. Rest
              assured, the community is the only feature they will have access
              to.
            </div>
          </>
        ) : (
          <>
            <div className="l-onboarding__section-title">Invite users</div>
            <div className="l-onboarding__section-info">
              Invite users to your company. Make sure to carefully select their
              permissions. <br />
              <br />
              Granting admin rights allows access to critical company settings,
              including payment methods and user management. By choosing the
              appropriate level of access for each user, you can ensure that
              your team operates efficiently and securely.
            </div>
          </>
        )}

        <InviteTeam
          canSkip={false}
          handleCancel={handleCancel}
          type={type}
          groupId={groupId}
        />
      </div>
    </Modal>
  );
};

export default InviteModal;
