export const clearSessionStorage = () => {
  const keys = [
    '_u', // User data
    '_c', // Client IP
  ];

  keys.forEach((key: string) => {
    sessionStorage.removeItem(key);
  });
};
