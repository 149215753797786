import React from 'react';

type Props = {
  color?: string;
};

function Close({ color }: Props) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
        stroke={color ? color : '#14142B'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Close;
