import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';

import CarouselBack from '../../../../../foundation/assets/svgs/CarouselBack';
import CarouselForward from '../../../../../foundation/assets/svgs/CarouselForward';
import Close from '../../../../../foundation/assets/svgs/Close';

const Lightbox = ({
  images,
  currentSlide,
  handleCloseLightbox,
}: {
  images: string[];
  currentSlide: number;
  handleCloseLightbox: any;
}) => {
  const carouselRef = React.useRef<any>(null);
  const [lightboxCurrentSlide, setLightboxCurrentSlide] = useState(0);

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(currentSlide, true);
      setLightboxCurrentSlide(currentSlide);
    }
  }, [carouselRef]);

  return (
    <div className="l-lightbox">
      <button
        className="l-lightbox__close"
        onClick={() => {
          handleCloseLightbox(lightboxCurrentSlide);
        }}
      >
        <Close color="#fff" />
      </button>
      {images && images.length && (
        <div className="l-lightbox__carousel-wrapper">
          {images.length > 1 && (
            <>
              <button
                className="l-lightbox__carousel-prev"
                onClick={() => {
                  carouselRef?.current?.prev();
                }}
              >
                <CarouselBack />
              </button>
              <button
                className="l-lightbox__carousel-next"
                onClick={() => {
                  carouselRef?.current?.next();
                }}
              >
                <CarouselForward />
              </button>
            </>
          )}
          <Carousel
            ref={carouselRef}
            effect={'fade'}
            afterChange={(current: number) => {
              setLightboxCurrentSlide(current);
            }}
          >
            {images.map((i: any, j: number) => {
              return (
                <div className="l-lightbox__carousel-slide" key={j}>
                  <img src={i} alt="Property" />
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default Lightbox;
