import { Alert } from 'antd';
import React from 'react';
import Masonry from 'react-masonry-css';

import useBreakpoint from '../../../../../../foundation/custom_hooks/useBreakpont';
import { useAppSelector } from '../../../../../../store/hooks';
import { selectListContainerSize } from '../../../../redux/selectors';
import { getRelatedArticlesByHashTag } from '../../redux/selectors';

type Props = {
  hashTagValue: string;
  hashTagKey: string;
  isLoading: boolean | undefined;
};

const ArticleCard = ({ hashTagValue, hashTagKey, isLoading }: Props) => {
  const listContainerSize = useAppSelector(selectListContainerSize);
  const LAYOUT_MODE_OPTIONS = ['hidden', 'compact', 'default', 'wide']; // Revisit

  const [isMobileView] = useBreakpoint();

  const relatedArticles = useAppSelector(
    getRelatedArticlesByHashTag(hashTagValue),
  );

  if (!relatedArticles && isLoading) {
    return null;
  }

  if (
    (!relatedArticles ||
      !relatedArticles.articles ||
      !relatedArticles.articles.length) &&
    !isLoading
  ) {
    return (
      <div className="no-articles">
        <Alert
          message="No articles found"
          description={<></>}
          type="warning"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <Masonry
        breakpointCols={
          isMobileView ? 1 : LAYOUT_MODE_OPTIONS.indexOf(listContainerSize) || 1
        }
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {relatedArticles.articles.map((a: any, i: number) => {
          return (
            <div className="l-dashboard-card l-dashboard-card--masonry" key={i}>
              {!!a.descImageUrl && (
                <a
                  href={a.Url}
                  target="_blank"
                  rel="noreferrer"
                  className="l-dashboard-card__image"
                >
                  <img src={a.descImageUrl} alt="" />
                </a>
              )}
              <div className="l-dashboard-card__body">
                {a.publisher && (
                  <div className="l-dashboard-card__publisher">
                    <div className="img">
                      {a.publisherImageUrl ? (
                        <img src={a.publisherImageUrl} alt="" />
                      ) : (
                        a.publisher[0]
                      )}
                    </div>
                    <strong>{a.publisher}</strong>
                  </div>
                )}
                <a
                  href={a.url}
                  target="_blank"
                  rel="noreferrer"
                  className="l-dashboard-card__article-link"
                >
                  {a.headLine}
                </a>
                <p>{a.descText}</p>
                <p className="h-text--muted">{a.published}</p>
              </div>
            </div>
          );
        })}
      </Masonry>
    </>
  );
};

export default ArticleCard;
