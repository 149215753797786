import * as Yup from 'yup';

const DMValidationSchema = () => {
  const requiredMessage = 'Field is required';
  const fields: any = {
    name: Yup.object()
      .shape({
        label: Yup.string().trim().required(requiredMessage),
        value: Yup.string().trim().required(requiredMessage),
      })
      .required(requiredMessage),
    message: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(4, 'Message must be of at least 4 characters'),
  };

  return Yup.object().shape(fields);
};

export default DMValidationSchema;
