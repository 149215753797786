import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Checkbox, Form, Input, Modal, Spin } from 'antd';
import { Options, passwordStrength } from 'check-password-strength';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Facebook from '../../../foundation/assets/images/Facebook';
import Google from '../../../foundation/assets/images/Google';
import IconHide from '../../../foundation/assets/svgs/IconHide';
import IconShow from '../../../foundation/assets/svgs/IconShow';
import Logo from '../../../foundation/assets/svgs/Logo';
import Map from '../../../foundation/assets/svgs/Map';
import FloatLabel from '../../../foundation/components/float_label/FloatLabel';
import Privacy from '../../../foundation/components/static_content/privacy/Privacy';
import Terms from '../../../foundation/components/static_content/terms/Terms';
import type { AppDispatch } from '../../../store';
import { getClientIP, postSignUp } from '../redux/async_thunks';

const SignUp = () => {
  const STATIC_CONTENT = {
    privacy: 'Privacy policy',
    terms: 'Terms of use',
  };

  const dispatch = useDispatch<AppDispatch>();

  const { loginWithRedirect } = useAuth0();
  const loadingIcon = <Loading3QuartersOutlined spin />;
  const loadingTip = 'Loading...';
  const [passwordStrengthState, setPasswordStrengthState] =
    useState<string>('');

  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [details, setDetails] = useState<any>();

  const [contentOnDisplay, setContentOnDisplay] = useState<string>('');

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const [inviteeEmail, setInviteeEmail] = useState<string>('');

  const handleDisplayModal = (content: string) => {
    setContentOnDisplay(content);
  };

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const inviteeEmailEncoded = query.get('e');

  const handleCloseModal = () => {
    setContentOnDisplay('');
  };

  const handleLoginClick = (e: any) => {
    e.preventDefault();
    loginWithRedirect();
  };

  const handleGoogleLoginClick = (e: any) => {
    e.preventDefault();
    loginWithRedirect({ connection: 'google-oauth2', prompt: 'login' });
  };

  const handleFacebookLoginClick = (e: any) => {
    e.preventDefault();
    loginWithRedirect({ connection: 'facebook' });
  };

  const { inviteCode } = useParams<{ inviteCode: string }>();

  const [form] = Form.useForm();

  const firstName = Form.useWatch('firstName', form);
  const lastName = Form.useWatch('lastName', form);
  const email = Form.useWatch('email', form);
  // const passCode = Form.useWatch('passCode', form);
  // const confirmPassCode = Form.useWatch('confirmPassCode', form);

  const errorHandler = (error?: any) => {
    const errorCodes = [
      'InvalidPassword: ',
      'InvalidSignup: ',
      'PasswordDictionaryError: ',
      'PasswordNoUserInfoError: ',
      'PasswordStrengthError: ',
      'Unauthorized: ',
      'UserExists: ',
      'UsernameExists: ',
    ];

    const removeSubstrings = (str: string, substrings: string[]) => {
      const regex = new RegExp(substrings.join('|'), 'gi');
      return str.replace(regex, '');
    };

    if (!error) {
      setFormErrorMessage('Something went wrong with the request');
    }

    setFormErrorMessage(removeSubstrings(error.message, errorCodes));
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (Object.values(allValues).every((value) => Boolean(value))) {
      form
        .validateFields()
        .then(() => {
          // This block is never reached (validateFields() bug)
        })
        .catch((error) => {
          // For some reason, exception is thrown regardless of field error count
          // So as a workaround in this catch block, enable the submit button when error count is zero
          setIsSubmitting(!error.errorFields.length);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  const passwordStrengthOptions: Options<string> = [
    {
      id: 0,
      value: 'Too Weak',
      minDiversity: 0,
      minLength: 0,
    },
    {
      id: 1,
      value: 'Weak',
      minDiversity: 2,
      minLength: 8,
    },
    {
      id: 2,
      value: 'Medium',
      minDiversity: 3,
      minLength: 8,
    },
    {
      id: 3,
      value: 'Strong',
      minDiversity: 4,
      minLength: 10,
    },
  ];

  const getDetails = async () => {
    setIsFormLoading(true);
    const response = await dispatch(getClientIP())
      // @ts-ignore
      .unwrap();

    setDetails({
      timeStamp: response.timeStamp,
      clientIp: response.ip,
    });

    setIsFormLoading(false);
  };

  const proceedSignUp = async (values: any) => {
    setIsSubmitting(true);
    setFormErrorMessage('');

    if (!details) {
      errorHandler();
    }

    setIsFormLoading(true);

    try {
      const response: any = await dispatch(
        postSignUp({
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            passCode: values.passCode,
            policyAccepted: values.policyAccepted,
          },
          options: {
            clientIp: details.clientIp,
            inviteCode: inviteCode || null,
            timeStamp: details.timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      setIsSignUpSuccessful(true);
      setIsFormLoading(false);
      setIsEmailConfirmed(response?.emailConfirmed);
    } catch (error: any) {
      if (error.message && error.message.includes('400')) {
        setFormErrorMessage('Email address is already in use');
      } else {
        errorHandler(error);
      }
    }

    setIsFormLoading(false);
  };

  const renderPasswordStrength = (state: string) => {
    let classNames = `l-common-form__password-state${
      state ? ' l-common-form__password-state--active' : ''
    }`;

    if (state === 'too weak') {
      classNames = `${classNames} l-common-form__password-state--too-weak`;
    } else {
      classNames = `${classNames} l-common-form__password-state--${state}`;
    }

    return <div className={classNames}>Password strength is {state}</div>;
  };

  useEffect(() => {
    getDetails();

    const decodeEmail = () => {
      if (inviteCode && inviteeEmailEncoded) {
        try {
          const decoded = Buffer.from(inviteeEmailEncoded, 'base64').toString(
            'utf-8',
          );

          setInviteeEmail(decoded);

          form.setFieldsValue({
            email: decoded,
          });
        } catch (e) {
          setInviteeEmail('');

          form.setFieldsValue({
            email: '',
          });
        }
      }
    };

    decodeEmail();
  }, []);

  return (
    <>
      <Modal
        title={contentOnDisplay}
        open={!!contentOnDisplay}
        onCancel={handleCloseModal}
        width={700}
        className="u-content-modal"
        footer={null}
      >
        {contentOnDisplay === STATIC_CONTENT.privacy ? (
          <Privacy />
        ) : (
          <Terms
            privacyPolicyCallback={() => {
              setContentOnDisplay(STATIC_CONTENT.privacy);
            }}
          />
        )}
      </Modal>
      <main className="l-common-form">
        <div className="l-common-form__body">
          <div className="l-common-form__fields">
            <div className="l-common-form__heading">
              <Logo />
              <h3>Sign up</h3>
            </div>
            {isSignUpSuccessful && (
              <div>
                <>
                  <p className="l-common-form__alert l-common-form__alert--success">
                    Sign up was successful!
                  </p>
                  {!isEmailConfirmed ? (
                    <p style={{ textAlign: 'center' }}>
                      We&apos;ve sent an email to{' '}
                      <strong>{form.getFieldValue('email')}</strong> to verify
                      your email address & activate your account
                    </p>
                  ) : (
                    <p style={{ textAlign: 'center' }}>
                      To access your account, please{' '}
                      <a href="#" onClick={handleLoginClick}>
                        <strong>log in</strong>
                      </a>
                    </p>
                  )}
                </>
              </div>
            )}
            {!isSignUpSuccessful && (
              <>
                <Spin
                  tip={loadingTip}
                  spinning={isFormLoading}
                  indicator={loadingIcon}
                >
                  {!inviteCode && (
                    <>
                      <button
                        className="l-common-form__google"
                        onClick={handleGoogleLoginClick}
                      >
                        <Google />
                        <span>Sign up with Google</span>
                      </button>
                      <button
                        className="l-common-form__facebook"
                        onClick={handleFacebookLoginClick}
                      >
                        <Facebook />
                        <span>Sign up with Facebook</span>
                      </button>
                      <div className="l-common-form__member">
                        <p>
                          Already a member?{' '}
                          <a href="#" onClick={handleLoginClick}>
                            Login Here
                          </a>
                        </p>
                      </div>
                      <div className="l-common-form__divider">or</div>
                    </>
                  )}
                  {formErrorMessage && (
                    <p className="l-common-form__alert l-common-form__alert--error">
                      {formErrorMessage}
                    </p>
                  )}
                  <Form
                    onFinish={proceedSignUp}
                    form={form}
                    onValuesChange={handleValuesChange}
                  >
                    <FloatLabel label="First Name" value={firstName}>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: 'First Name is required',
                          },
                        ]}
                      >
                        <Input className="l-common-form__input" />
                      </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Last Name" value={lastName}>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: 'Last Name is required',
                          },
                        ]}
                      >
                        <Input className="l-common-form__input" />
                      </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Email" value={email}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Email is required',
                          },
                          {
                            type: 'email',
                            message: 'Email is invalid',
                          },
                        ]}
                      >
                        <Input
                          className="l-common-form__input"
                          disabled={!!inviteeEmail}
                        />
                      </Form.Item>
                    </FloatLabel>
                    {passwordStrengthState &&
                      renderPasswordStrength(passwordStrengthState)}
                    <Form.Item
                      name="passCode"
                      rules={[
                        {
                          required: true,
                          message: 'Password is required',
                        },
                        {
                          min: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const passwordCheck = passwordStrength(
                              value,
                              passwordStrengthOptions,
                            );
                            const characters = passwordCheck.contains;

                            setPasswordStrengthState(
                              passwordCheck.contains.length
                                ? passwordCheck.value.toLowerCase()
                                : '',
                            );

                            if (!value) {
                              return Promise.resolve();
                            }

                            if (value.match(/\s/)) {
                              return Promise.reject(
                                new Error('Spaces are not allowed'),
                              );
                            }

                            if (
                              value &&
                              (!characters.includes('lowercase') ||
                                !characters.includes('uppercase') ||
                                !characters.includes('number'))
                            ) {
                              return Promise.reject(
                                new Error(
                                  'Password must contain a letter in uppercase & lowercase, & a number',
                                ),
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="l-common-form__input"
                        placeholder="Password"
                        iconRender={(visible: boolean) =>
                          visible ? (
                            <span role="img" aria-label="eye">
                              <IconShow />
                            </span>
                          ) : (
                            <span role="img" aria-label="eye">
                              <IconHide />
                            </span>
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassCode"
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('passCode') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error('Passwords do not match'),
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="l-common-form__input"
                        placeholder="Confirm Password"
                        iconRender={(visible: boolean) =>
                          visible ? (
                            <span role="img" aria-label="eye">
                              <IconShow />
                            </span>
                          ) : (
                            <span role="img" aria-label="eye">
                              <IconHide />
                            </span>
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="policyAccepted"
                      valuePropName="checked"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(
                                'Please agree to the Terms of Use & Privacy Policy',
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      <Checkbox>
                        I agree to the{' '}
                        <a
                          className="l-common-form__link"
                          target="_blank"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDisplayModal(STATIC_CONTENT.terms);
                          }}
                        >
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                          className="l-common-form__link"
                          target="_blank"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDisplayModal(STATIC_CONTENT.privacy);
                          }}
                        >
                          Privacy Policy
                        </a>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      <Button
                        className="l-common-form__button"
                        type="primary"
                        htmlType="submit"
                        disabled={!isSubmitting}
                      >
                        Sign up
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </>
            )}
          </div>
        </div>
        <div className="l-common-form__hero">
          <Map />
        </div>
      </main>
    </>
  );
};

export default SignUp;
