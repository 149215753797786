import React from 'react';

import Property from './property/Property';

const Properties = ({ results }: any) => {
  return (
    <>
      {results.properties.map((result: any, index: number) => {
        return <Property key={index} property={result} />;
      })}
    </>
  );
};

export default Properties;
