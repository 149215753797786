import channel from './channel';
import chat_messages from './chat_messages';
import comet_chat from './comet_chat';

const generic = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
};

export default {
  channel,
  comet_chat,
  generic,
  chat_messages,
};
