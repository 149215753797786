import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  authEndpoint,
  endpoint,
} from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getClientID,
  getRandomString,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';
import { setAllAppRoles } from '../../account/redux/slice';

export const getClientIP = createAsyncThunk('user/clientIp', async () => {
  const response = await authEndpoint.get(
    `${env_constants.RHOMEO_API}/Client/ip`,
    {
      headers: {
        clientid: encryptData(`${navigator.userAgent}/${uuidv4()}`),
      },
    },
  );

  const decrypted = decryptData(response.data);

  const ip = decrypted.ip;

  // STORE: Client's IP address
  sessionStorage.setItem('_c', ip);

  return {
    ip: ip,
    timeStamp: decrypted.timeStamp,
    featureFlag: decrypted.featureFlag,
  };
});

export const getAppValues = createAsyncThunk(
  'user/values',
  async (apiParams: { options: any }, thunkAPI) => {
    const clientId = getClientID(apiParams.options.clientIp);

    let result: any;

    await endpoint
      .get(`${env_constants.RHOMEO_API}/Values`, {
        headers: {
          Authorization: `Bearer ${apiParams.options.token}`,
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          const decrypted = decryptData(response.data);

          result = {
            ssd: decrypted.ssd,
            aps: decrypted.aps,
          };

          thunkAPI.dispatch(setAllAppRoles(decrypted.roles));
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const getSubscriptionDetails = createAsyncThunk(
  'user/subscriptionDetails',
  async (apiParams: { options: any }, thunkAPI) => {
    const clientId = getClientID(apiParams.options.clientIp);

    let result: any;

    await endpoint
      .get(`${env_constants.RHOMEO_API}/Values/subscription-details`, {
        headers: {
          Authorization: `Bearer ${apiParams.options.token}`,
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          const decrypted = decryptData(response.data);

          if (decrypted.code === 204) {
            result = null;
          } else {
            result = {
              billingFrequency: decrypted.billingFrequency,
              occupiedSeats: decrypted.occupiedSeats,
              subscriptionPlans: decrypted.subscriptionPlans,
              subscriptionPlanDetails: decrypted.subscriptionPlanDetails,
              customerId: decrypted.customerId,
            };
          }
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postSignUp = createAsyncThunk(
  'user/signup',
  async (apiParams: { data: any; options: any }) => {
    let result;
    const clientId = getClientID(apiParams.options.clientIp);

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const passCodeWrappedInRandomString = `${getRandomString(20)}${
      apiParams.data.passCode
    }${getRandomString(20)}`;

    const data = {
      requestKey: encryptData(requestKey),
      clientIp: apiParams.options.clientIp,
      firstName: apiParams.data.firstName,
      lastName: apiParams.data.lastName,
      email: apiParams.data.email,
      passCode: Buffer.from(passCodeWrappedInRandomString).toString('base64'),
      policyAccepted: apiParams.data.policyAccepted,
      inviteCode: apiParams.options.inviteCode,
    };

    const encrypted = encryptData(data);

    await axios
      .post(`${env_constants.RHOMEO_API}/Auth/sign-up`, encrypted, {
        headers: {
          clientid: clientId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const patchRefresh = createAsyncThunk(
  'user/refresh',
  async (apiParams: {
    data: any;
    options: {
      token: string;
      clientIp: string;
      sessionId: string;
    };
  }) => {
    const clientId = getClientID(apiParams.options.clientIp);
    const encrypted = encryptData(apiParams.data);

    let result: any;

    await authEndpoint
      .patch(`${env_constants.RHOMEO_API}/Auth/refresh`, encrypted, {
        headers: {
          Authorization: `Bearer ${apiParams.options.token}`,
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);
        return Promise.reject(decrypted);
      });

    const data: any = {
      userRole: result.userRole,
      userId: result.userId,
      sessionId: result.sessionId,
      jwtToken: result.jwtToken,
    };

    // STORE: User data
    sessionStorage.setItem('_u', encryptData(data));

    return result;
  },
);

export const postLogin = createAsyncThunk(
  'user/login',
  async (apiParams: { data: any; options: any }) => {
    const clientId = getClientID(apiParams.options.clientIp);

    let result: any;

    const encoded = Buffer.from(
      JSON.stringify(
        pick(
          apiParams.data.auth0user,
          'sub',
          'given_name',
          'family_name',
          'nickname',
          'name',
          'picture',
          'updated_at',
          'email',
        ),
      ),
    ).toString('base64');

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const data = {
      authResponse: encoded,
      clientIp: apiParams.options.clientIp,
      browserToken: apiParams.data.browserToken,
      browser: apiParams.data.browser,
      browserOs: apiParams.data.browserOs,
      requestKey: encryptData(requestKey),
    };

    const encrypted = encryptData(data);

    await authEndpoint
      .post(`${env_constants.RHOMEO_API}/Auth/login`, encrypted, {
        headers: {
          clientid: clientId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);

          const data = {
            jwtToken: result.jwtToken,
            userId: result.userId,
            sessionId: result.sessionId,
          };

          // STORE: User data
          sessionStorage.setItem('_u', encryptData(data));
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);
        return Promise.reject(decrypted);
      });

    return result;
  },
);

export const deleteLogout = createAsyncThunk(
  'user/logout',
  async (apiParams: { data: any; options: any }) => {
    const clientId = getClientID(apiParams.data.clientIp);

    const encrypted = encryptData(apiParams.data);

    const response = await authEndpoint.delete(
      `${env_constants.RHOMEO_API}/Auth/logout`,
      {
        headers: {
          clientid: clientId,
          Authorization: `Bearer ${apiParams.options.token}`,
          Accept: '*/*',
          'Content-Type': 'application/json',
          sessionid: apiParams.options.sessionId,
        },
        data: encrypted,
      },
    );

    return decryptData(response.data);
  },
);

export const postForgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (apiParams: { data: any; options: any }) => {
    const clientId = getClientID(apiParams.options.clientIp);

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const data = {
      requestKey: encryptData(requestKey),
      clientIp: apiParams.options.clientIp,
      email: apiParams.data.email,
    };

    const encrypted = encryptData(data);

    const response = await axios.post(
      `${env_constants.RHOMEO_API}/Auth/forgot-pass`,
      encrypted,
      {
        headers: {
          clientid: clientId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );

    return decryptData(response.data);
  },
);

export const updateProfile = createAsyncThunk(
  'user/update-profile',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      ...apiParams.data,
    };

    const encrypted = encryptData(data);

    const formData = new FormData();
    formData.append('e', encrypted);

    if (apiParams.data.picture) {
      formData.append('f', apiParams.data.picture || null);
    }

    /**
     * We should create this header by using a function because it is shared
     * among so many files.
     */
    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'content-type': 'multipart/form-data',
    };

    await endpoint
      .put(`${env_constants.RHOMEO_API}/Profile`, formData, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data)?.profile;
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);
        return Promise.reject(decrypted);
      });

    return result;
  },
);

export const inviteUser = createAsyncThunk(
  'user/invite-user',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      ...apiParams.data,
    };

    const encrypted = encryptData(data);

    /**
     * We should create this header by using a function because it is shared
     * among so many files.
     */
    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Profile/invite`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        const decrypted = decryptData(error.response.data);
        return Promise.reject(decrypted);
      });

    return result;
  },
);

export const removeSearchItem = createAsyncThunk(
  'favorite/remove-search',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const data = {
      userId: apiParams.data.userId,
      id: apiParams.data.id,
    };

    const encrypted = encryptData(data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .delete(
        `${env_constants.RHOMEO_API}/Favorite/${apiParams.options.searchType}-search`,
        {
          headers,
          data: encrypted,
        },
      )
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });
    return result;
  },
);

export const postChangePassword = createAsyncThunk(
  'user/changePassword',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Auth/change-pass`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const deleteProfileRequest = createAsyncThunk(
  'user/profileDeleteRequest',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .delete(`${env_constants.RHOMEO_API}/Profile`, {
        headers: headers,
        data: encrypted,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const deleteProfile = createAsyncThunk(
  'user/deleteProfile',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const data = {
      requestKey: encryptData(requestKey),
      clientIp: apiParams.options.clientIp,
      deleteCode: apiParams.data.deleteCode,
      otp: apiParams.data.otp,
    };

    const encrypted = encryptData(data);

    await axios
      .delete(`${env_constants.RHOMEO_API}/Auth/delete`, {
        headers: {
          clientid: clientId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        data: encrypted,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
