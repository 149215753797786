import { CometChat } from '@cometchat-pro/chat';
import { Avatar } from 'antd';
import React, { useMemo } from 'react';

import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch } from '../../../store/hooks';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import { setChatWindowType, setSelectedConversation } from '../redux/slice';
import {} from '../redux/slice';

type ListItemProps = {
  conversation: any;
  selectedConversation: any;
};

const ListItem = ({
  conversation,
  selectedConversation,
}: ListItemProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isMobileView, isDesktopView] = useBreakpoint();

  const isGroupConversaton = useMemo(() => {
    return conversation.conversationType === CometChat.RECEIVER_TYPE.GROUP;
  }, [conversation]);

  const membersCountText = useMemo(() => {
    if (isGroupConversaton) {
      const count = conversation?.conversationWith?.membersCount || 0;

      if (count > 1) {
        return `${count} members`;
      } else {
        return `${count} member`;
      }
    }
  }, [conversation, isGroupConversaton]);

  const isSelected = useMemo(() => {
    if (
      conversation &&
      selectedConversation &&
      conversation.conversationId.toString() ===
        selectedConversation.conversationId.toString()
    ) {
      return true;
    }
    return false;
  }, [conversation, selectedConversation]);

  const avatar = useMemo(() => {
    if (!isGroupConversaton) {
      return conversation.conversationWith.avatar;
    } else if (isGroupConversaton) {
      return conversation.conversationWith.icon;
    }
  }, [conversation]);

  const onListItemClick = () => {
    dispatch(setSelectedConversation({ ...conversation }));

    if (isDesktopView) {
      dispatch(setChatWindowType('chat-window'));
    } else if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'chat_window',
        showBackButton: false,
        isClosable: false,
        headerTitle: undefined as any,
        parentConfig: undefined as any,
        isMobileContentHeaderHidden: true,
      };

      dispatch(setSiderMobileContentValues(data));
    }
  };

  return (
    <div
      className={`l-chat-panel__list-item ${
        isSelected ? `l-chat-panel__list-item--selected` : ''
      }`}
      onClick={onListItemClick}
    >
      <div className={`l-chat-panel__list-info-wrapper`}>
        <div className="l-chat-panel__list-avatar">
          <Avatar src={avatar} />
        </div>
        <div className="l-chat-panel__list-info">
          <div className="l-chat-panel__list-label">
            {conversation.conversationWith.name}
          </div>
          {isGroupConversaton && (
            <div className="l-chat-panel__list-sub-label">
              {membersCountText}
            </div>
          )}
        </div>
      </div>

      {conversation.unreadMessageCount > 0 && (
        <div className="l-chat-panel__list-msg-count">
          {conversation.unreadMessageCount <= 10
            ? conversation.unreadMessageCount
            : `${conversation.unreadMessageCount}+`}
        </div>
      )}
    </div>
  );
};

export default ListItem;
