import React from 'react';

const Needle = () => {
  return (
    <svg
      width="12"
      height="38"
      viewBox="0 0 12 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4316 31.9827C11.4316 34.8131 9.01818 37.1075 6.04102 37.1075C3.06386 37.1075 0.650391 34.8131 0.650391 31.9827C0.650391 30.3073 2.53928 14.6574 4.08082 2.16442C4.36876 -0.169147 7.71327 -0.169144 8.00122 2.16442C9.54275 14.6574 11.4316 30.3073 11.4316 31.9827Z"
        fill="#4E4B66"
      />
    </svg>
  );
};

export default Needle;
