import * as Yup from 'yup';

const CreateChannelValidationSchema = (isPasswordRequired: boolean) => {
  const requiredMessage = 'Field is required';
  const fields: any = {
    channelName: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(4, 'Name must be of at least 4 characters'),
    channelPurpose: Yup.string()
      .trim()
      .required(requiredMessage)
      .min(4, 'Description must be of at least 4 characters'),
    restrictionPassword: isPasswordRequired
      ? Yup.string().required(requiredMessage)
      : Yup.string(),
    confirmRestrictionPassword: isPasswordRequired
      ? Yup.string()
          .required(requiredMessage)
          .oneOf([Yup.ref('restrictionPassword'), null], 'Passwords must match')
      : Yup.string(),
  };

  return Yup.object().shape(fields);
};

export default CreateChannelValidationSchema;
