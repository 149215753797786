import { notification } from 'antd';
import React from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import { postFavorite } from '../../redux/async_thunks';
import { getIsFavorite } from '../../redux/selectors';

const Favorite = ({ className, property, style = {}, children }: any) => {
  const searchType = useSearchType();
  const dispatch = useAppDispatch();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const isPropertyInFavoriteList = useAppSelector(
    getIsFavorite(property.propertyId, searchType),
  );

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const favoriteProperty = async () => {
    try {
      await dispatch(
        postFavorite({
          data: {
            userId: USER.userId,
            propertyId: property.propertyId,
            isFavorite: !isPropertyInFavoriteList,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: searchType,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      notification.success({
        message: 'Success',
        description: `The property has been ${
          property.isFavorite ? 'removed from' : 'added to'
        } your favorite list.`,
        placement: 'topRight',
        top: 100,
        closeIcon: (
          <strong className="l-search-main__close">
            <Close />
          </strong>
        ),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <button
      type="button"
      className={className}
      onClick={() => {
        favoriteProperty();
      }}
      style={style}
    >
      {children}
    </button>
  );
};

export default Favorite;
