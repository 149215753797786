import { Divider, Modal } from 'antd';
import React, { useMemo } from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import GreenCheck from '../../../../foundation/assets/svgs/GreenCheck';
import { SubscriptionPlan } from '../../../user/redux/types';
import { SubscriptionFrequency } from '../../helpers/types';

type PlanModalTypes = {
  handleCancel: () => void;
  subscriptionFrequency: SubscriptionFrequency;
  availablePlans: SubscriptionPlan[];
};

const PlanModal = ({
  handleCancel,
  subscriptionFrequency,
  availablePlans,
}: PlanModalTypes) => {
  const modalProps = {
    wrapClassName: 'l-plan-info-modal',
    closable: true,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 1070,
    onCancel: handleCancel,
    closeIcon: <Close />,
  };

  const plans = useMemo(() => {
    const planElements = [];

    const frequencyString =
      subscriptionFrequency === 'Annually' ? 'per year' : 'a month';

    if (availablePlans) {
      for (const item of availablePlans) {
        let planRate = 0;

        for (const rate of item.planRates) {
          if (rate.frequency === subscriptionFrequency) {
            planRate = rate.subscriptionAmount;
          }
        }

        planElements.push(
          <div
            className="l-plan-info-modal__plan-wrapper"
            key={item.subscriptionPlanId}
          >
            <div className="l-plan-info-modal__plan-name">
              {item.subscriptionPlan}
            </div>
            <div className="l-plan-info-modal__plan-rate">
              <span className="l-plan-info-modal__plan-rate--rate">
                ${planRate}
              </span>{' '}
              <span className="l-plan-info-modal__plan-rate--freq">
                {frequencyString}
              </span>
            </div>
            <div className="l-plan-info-modal__plan-inc-users">
              {item.additionalUsersAllowed
                ? `+ $50 per month per additional seat`
                : `One seat per subscription`}
            </div>
            <div className="l-plan-info-modal__plan-description">
              {item.subscriptionSummary}
            </div>
            <Divider />
            {item.subscriptionDetails.map((detail) => (
              <div className="l-plan-info-modal__plan-item" key={detail}>
                <GreenCheck />
                <span className="l-plan-info-modal__plan-desc">{detail}</span>
              </div>
            ))}
          </div>,
        );
      }
    }

    return planElements;
  }, [availablePlans, subscriptionFrequency]);
  return (
    <Modal {...modalProps}>
      <div className="l-plan-info-modal__header">Our plans</div>
      <div className="l-plan-info-modal__plans-container">{plans}</div>
    </Modal>
  );
};

export default PlanModal;
