import { Layout } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIcon from '../../../foundation/assets/svgs/ArrowBackIcon';
import Close from '../../../foundation/assets/svgs/Close';
import Privacy from '../../../foundation/components/static_content/privacy/Privacy';
import Terms from '../../../foundation/components/static_content/terms/Terms';
import ChannelBrowse from '../../chat/channel_browse/ChannelBrowse';
import ChatWindow from '../../chat/chat_window/ChatWindow';
import CreateChannel from '../../chat/components/create_channel/CreateChannel';
import DirectMessage from '../../chat/components/direct_message/DirectMessage';
import Filter from '../../search/form/filter/Filter';
import SaveSearch from '../../search/form/search/save_search/SaveSearch';
import SearchField from '../../search/form/search/search_field/SearchField';
import Sort from '../../search/results/list/sort/Sort';
import Favorite from '../../user/favorite/Favorite';
import Invite from '../../user/invite/Invite';
import Notification from '../../user/notification/Notification';
import Profile from '../../user/profile/Profile';
import ProfileEditForm from '../../user/profile/ProfileEditForm';
import Settings from '../../user/settings/Settings';
import { selectMobileContentSider } from '../redux/selectors';
import {
  resetSiderMobileContentValues,
  setBreakpoint,
  setSiderMobileContentValues,
} from '../redux/slice';

const { Sider } = Layout;

// type Props = {
//   setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
//   isCollapsed: boolean;
// };

const MobileContentSider = () => {
  const content = useSelector(selectMobileContentSider);

  const dispatch = useDispatch();

  //   const handleCollapsedLogic = () => {
  //     setIsCollapsed((v: boolean) => !v);
  //   };

  const handleBreakpointChange = (broken) => {
    dispatch(setBreakpoint(broken ? 'mobile' : 'desktop'));
  };

  const renderContent = () => {
    const view = content.view;

    switch (view) {
      case 'profile': {
        return <Profile />;
      }
      case 'profile_edit': {
        return <ProfileEditForm handleClose={handleBackClick} />;
      }
      case 'invite': {
        return <Invite isVisible={true} />;
      }
      case 'terms': {
        return (
          <div
            style={{
              width: '100%',
              padding: '10px 20px',
            }}
          >
            <Terms privacyPolicyCallback={undefined} />
          </div>
        );
      }
      case 'browse_channels': {
        return <ChannelBrowse />;
      }
      case 'privacy': {
        return (
          <div
            style={{
              width: '100%',
              padding: '10px 20px',
            }}
          >
            <Privacy />
          </div>
        );
      }
      case 'favorites': {
        return <Favorite />;
      }
      case 'create_channel': {
        return <CreateChannel />;
      }
      case 'create_message': {
        return <DirectMessage />;
      }
      case 'search': {
        return (
          <SearchField
            placeholder={'State, LGA, Postcode, or Suburb'}
            isMobileViewVisible
            init
          />
        );
      }
      case 'filter': {
        return <Filter isMobileViewVisible isDisabled init />;
      }
      case 'search_filter': {
        return <Filter isMobileViewVisible />;
      }
      case 'sort': {
        return <Sort />;
      }
      case 'save_search': {
        return <SaveSearch />;
      }
      case 'settings': {
        return <Settings />;
      }
      case 'chat_window': {
        return <ChatWindow />;
      }
      case 'notifications': {
        return <Notification />;
      }
      default:
        return <div></div>;
    }
  };

  const handleClose = () => {
    dispatch(resetSiderMobileContentValues(undefined));
  };

  const handleBackClick = () => {
    dispatch(setSiderMobileContentValues(content.parentConfig));
  };

  return (
    <Sider
      collapsed={false}
      breakpoint="md"
      onBreakpoint={handleBreakpointChange}
      className="c-mobile-content-sider"
      zeroWidthTriggerStyle={{ display: 'none' }}
      collapsible={false}
      width={'100%'}
    >
      {!content.isMobileContentHeaderHidden && (
        <div className="c-mobile-content-sider__header">
          <div className="c-mobile-content-sider__header-left-items">
            {content.showBackButton && (
              <div style={{ marginRight: 15 }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </div>
            )}
            {content.headerTitle && <div>{content.headerTitle}</div>}
          </div>
          <div className="c-mobile-content-sider__header-right-items">
            {content.isClosable && (
              <div onClick={handleClose}>
                <Close />
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={
          content.isMobileContentHeaderHidden
            ? { height: '100%' }
            : { height: 'calc(100% - 60px)' }
        }
      >
        {renderContent()}
      </div>
    </Sider>
  );
};

export default MobileContentSider;
