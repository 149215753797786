import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'antd';
import React from 'react';

import CardForm from './CardForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  'pk_test_51MQkEHHv4F9dyBb7nIZHXAytCMmqOaCTMDd8v1vYe5ycJJi2Hr19zsLqOmUG8V7NvVJxBuqb4EdBVcw69H7as5FH00HPWt2dC5',
);

type UpdateCardModalType = {
  handleCancel: () => void;
};
const UpdateCardModal = ({ handleCancel }: UpdateCardModalType) => {
  const modalProps = {
    wrapClassName: 'l-onboarding l-update-card',
    closable: false,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 650,
    // onCancel: handleCancel,
  };

  return (
    <Modal {...modalProps}>
      <div className="l-onboarding__section">
        <div className="l-onboarding__section-title">Change payment method</div>
        <div className="l-onboarding__section-sub-title">
          Provide new billing and payment details
        </div>
      </div>

      <div className="l-update-card__stripe-elements-wrapper">
        <Elements
          stripe={stripePromise}
          options={{ appearance: { theme: 'flat' } }}
        >
          <CardForm handleModalClose={handleCancel} />
        </Elements>
      </div>
    </Modal>
  );
};

export default UpdateCardModal;
