import React from 'react';

function IconShow() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33497 10.2561C0.888345 9.47815 0.888342 8.52198 1.33497 7.74407C3.68496 3.65097 7.44378 1 11.6798 1C15.9158 1 19.6746 3.65094 22.0246 7.74398C22.4712 8.52189 22.4712 9.47806 22.0246 10.256C19.6746 14.3491 15.9158 17 11.6798 17C7.44377 17 3.68497 14.3491 1.33497 10.2561Z"
        stroke="#6E7191"
        strokeWidth="1.5"
      />
      <path
        d="M14.6797 9C14.6797 10.6569 13.3365 12 11.6797 12C10.0228 12 8.67969 10.6569 8.67969 9C8.67969 7.34315 10.0228 6 11.6797 6C13.3365 6 14.6797 7.34315 14.6797 9Z"
        stroke="#6E7191"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default IconShow;
