import { Button, notification } from 'antd';
import React, { useState } from 'react';

import BinIcon from '../../../foundation/assets/svgs/BinIcon';
import Close from '../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';

type ListItemProps = {
  icon: React.ReactNode;
  mainTitle: string;
  deleteListItem: () => Promise<unknown>;
  viewListItem: () => void;
  propertyType?: string;
  preTitle?: string;
  subTitle?: string;
};
const FavListItem = ({
  icon,
  mainTitle,
  deleteListItem,
  viewListItem,
  propertyType,
  preTitle,
  subTitle,
}: ListItemProps) => {
  const [showDelConfirm, setDelConfirmVisibility] = useState(false);
  const [isListItemLoading, setListItemLoading] = useState(false);

  const handleDelConfirmVisibility = () => {
    setDelConfirmVisibility((v) => !v);
  };

  const handleDeleteConfirm = async () => {
    if (isListItemLoading) {
      return;
    }
    try {
      setListItemLoading(true);
      await deleteListItem();

      notification.success({
        message: 'Success!',
        description: 'Successfully deleted saved item.',
      });

      setListItemLoading(false);
    } catch (error) {
      setListItemLoading(false);
    }
  };

  return (
    <div className="c-favorite__list-item">
      {isListItemLoading && <FullPageLoader />}
      <div className="c-favorite__list-item-lfet-side" onClick={viewListItem}>
        <div className="c-favorite__list-icon">{icon}</div>
        <div>
          {preTitle && (
            <div className="c-favorite__list-pre-title">{preTitle}</div>
          )}
          <div
            className="c-favorite__list-main-title"
            style={{
              color:
                propertyType === 'Houses' || !propertyType
                  ? '#651fff'
                  : '#00ADD2',
            }}
          >
            {mainTitle}
          </div>
          {propertyType && (
            <div className="c-favorite__list-sub-title">{propertyType}</div>
          )}
          {subTitle && (
            <div className="c-favorite__list-sub-title">{subTitle}</div>
          )}
        </div>
      </div>

      <div className="c-favorite__list-item-right-side">
        <div
          className={`c-favorite__list-item-del-btn ${
            showDelConfirm ? 'c-favorite__list-item-del-btn--hidden' : ''
          }`}
          onClick={handleDelConfirmVisibility}
        >
          <BinIcon />
        </div>
        <div
          className={`c-favorite__list-item-del-op-wrapper ${
            showDelConfirm
              ? 'c-favorite__list-item-del-op-wrapper--visible'
              : 'c-favorite__list-item-del-op-wrapper--hidden'
          }`}
        >
          <div
            className="c-favorite__list-item-del-btn"
            style={{
              background: 'white',
              marginRight: 10,
            }}
            onClick={() => setDelConfirmVisibility(false)}
          >
            <Close />
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="c-favorite__list-item-del-btn-confirm"
              onClick={handleDeleteConfirm}
            >
              delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavListItem;
