import { RootState } from '../../../store';

export const selectccUser = (state: RootState) => state.chat.ccUser;

export const selectChannelsList = (state: RootState) => state.chat.channelsList;

export const selectDirectMessagesList = (state: RootState) =>
  state.chat.directMessagesList;

export const selectSelectedConversation = (state: RootState) =>
  state.chat.selectedConversation;

export const selectProfileBeingViewed = (state: RootState) =>
  state.chat.profileBeingViewed;

export const selectChatWindowType = (state: RootState) =>
  state.chat.chatWindowType;

export const selectIsChannelsListFetching = (state: RootState) =>
  state.chat.isChannelsListFetching;

export const selectIsConversationsListFetching = (state: RootState) =>
  state.chat.isConversationsListFetching;

export const selectIsChannelCreationModalVisible = (state: RootState) =>
  state.chat.isChannelCreationModalVisible;
