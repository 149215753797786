import { createSlice } from '@reduxjs/toolkit';

import { getAppValues } from '../../../../user/redux/async_thunks';
import {
  postSuburbDwelling,
  postSuburbForecastGraph,
  postSuburbForecastGraphs,
  postSuburbGraph,
  postSuburbGraphs,
  postSuburbGrowth,
  postSuburbPopulation,
  postSuburbProjects,
  postSuburbRelatedArticles,
} from './async_thunks';
import { DetailsState } from './types';

const initialState: DetailsState = {
  relatedArticlesValues: {
    hashTags: undefined,
    distance: { min: 0, max: 2, interval: 1 },
  },
  graph: {
    SupplyDemandIndicator: false,
    SupplyDemandRatio: false,
    RentalVacancyRatio: false,
    VolumeRentalListings: false,
    MedianSalePrice: false,
    GrossRentalYield: false,
    MedianWeeklyAskingRent: false,
    LgaWeightedInvCapita: false,
    SuburbTotalWeightedInvesmentPerCapita: false,
    MedianMarketTime: false,
    VendorDiscount: false,
    MedianSalesPricePrediction: false,
    MedianWeeklyAskingRentPrediction: false,
  },
  growth: undefined,
  projectsValues: {
    orderTypes: [],
    distance: { min: 0, max: 2, interval: 1 },
  },
  keyIndicatorsValues: undefined,
  timelineType: '10',
  descriptors: {
    dwelling: undefined,
    graph: undefined,
    population: undefined,
    projects: undefined,
  },
  population: undefined,
  dwelling: undefined,
  relatedArticles: {},
  isGraphLoading: {},
  // All enabled by default
  graphDisplaySettings: {
    SupplyDemandIndicator: true,
    SupplyDemandRatio: true,
    RentalVacancyRatio: true,
    VolumeRentalListings: true,
    MedianSalePrice: true,
    GrossRentalYield: true,
    MedianWeeklyAskingRent: true,
    MedianMarketTime: true,
    LgaWeightedInvCapita: true,
    MedianSalesPricePrediction: true,
    MedianWeeklyAskingRentPrediction: true,
  },
  projects: undefined,
  projectSettings: {
    orderType: 'Stage',
    sortKey: 'realValue',
    orderByKey: 'descending',
    kmRadius: 50,
  },
};

const weightedGraph = {
  key: 'Suburb Total Weighted Investment Per Capita',
  value: 'SuburbTotalWeightedInvesmentPerCapita',
};

export const details = createSlice({
  name: 'details',
  initialState,
  reducers: {
    resetRelatedArticles: (state) => {
      return {
        ...state,
        relatedArticles: initialState.relatedArticles,
      };
    },
    resetGraph: (state) => {
      return {
        ...state,
        graph: initialState.graph,
      };
    },
    setIsGraphLoading: (state, action: any) => {
      state.isGraphLoading = {
        ...state.isGraphLoading,
        [action.payload.key]: action.payload.status,
      };
    },
    setGraphDisplaySettings: (state, action: any) => {
      state.graphDisplaySettings = action.payload;
    },
    setTimelineType: (state, action: any) => {
      state.timelineType = action.payload;
    },
    setKeyIndicatorsGraphData: (state, action: any) => {
      state.graph[action.payload.graphType] = action.payload.data;
    },
    setProjectSettings: (state, action: any) => {
      state.projectSettings = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAppValues.fulfilled, (state, action) => {
      state.descriptors = action.payload.ssd.descriptors;
      state.relatedArticlesValues = action.payload.ssd.relatedArticlesValues;
      state.projectsValues = action.payload.ssd.projectsValues;

      state.keyIndicatorsValues = {
        ...action.payload.ssd.keyIndicatorsValues,
        graphTypes: [
          ...action.payload.ssd.keyIndicatorsValues.graphTypes,
          ...[weightedGraph],
        ],
      };
    });

    builder.addCase(postSuburbPopulation.fulfilled, (state, action: any) => {
      state.population = action.payload;
    });

    builder.addCase(postSuburbPopulation.rejected, (state) => {
      state.population = initialState.population;
    });

    builder.addCase(postSuburbDwelling.fulfilled, (state, action: any) => {
      state.dwelling = action.payload;
    });

    builder.addCase(postSuburbDwelling.rejected, (state) => {
      state.dwelling = initialState.dwelling;
    });

    builder.addCase(
      postSuburbRelatedArticles.fulfilled,
      (state, action: any) => {
        state.relatedArticles[action.payload.hashTagValue] =
          action.payload.data;
      },
    );

    builder.addCase(postSuburbRelatedArticles.rejected, (state) => {
      state.relatedArticles = initialState.relatedArticles;
    });

    builder.addCase(postSuburbGraph.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.graph[action.payload.graphType] = action.payload.data;
      }
    });

    builder.addCase(postSuburbGraph.rejected, (state, action) => {
      if (action.payload) {
        state.graph[action.meta.arg.data.graphType] = false;
      }
    });

    builder.addCase(postSuburbGraphs.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.graph = {
          ...state.graph,
          ...action.payload.data,
        };
      }
    });

    builder.addCase(
      postSuburbForecastGraphs.fulfilled,
      (state, action: any) => {
        if (action.payload) {
          state.graph = {
            ...state.graph,
            ...action.payload.data,
          };
        }
      },
    );

    builder.addCase(postSuburbForecastGraph.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.graph[action.payload.graphType] = action.payload.data;
      }
    });

    builder.addCase(postSuburbForecastGraph.rejected, (state, action) => {
      if (action.payload) {
        state.graph[action.meta.arg.data.graphType] = false;
      }
    });

    builder.addCase(postSuburbGrowth.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.growth = action.payload.growth;
      }
    });

    builder.addCase(postSuburbGrowth.rejected, (state) => {
      state.growth = initialState.growth;
    });

    builder.addCase(postSuburbProjects.fulfilled, (state, action: any) => {
      state.projects = {
        ...state.projects,
        ...action.payload,
      };
    });

    builder.addCase(postSuburbProjects.rejected, (state) => {
      state.projects = initialState.projects;
    });
  },
});

export const {
  resetRelatedArticles,
  resetGraph,
  setGraphDisplaySettings,
  setIsGraphLoading,
  setTimelineType,
  setKeyIndicatorsGraphData,
  setProjectSettings,
} = details.actions;

export default details.reducer;
