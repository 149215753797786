import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, notification } from 'antd';
import React, { useState } from 'react';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectClientIp,
  selectStripeCustomerId,
  selectUser,
} from '../../../user/redux/selectors';
import { updatePaymentMethod } from '../../redux/async_thunks';
import { selectAccountSubscriptionId } from '../../redux/selectors';

type CardFormType = {
  handleModalClose: () => void;
};

const CardForm = ({ handleModalClose }: CardFormType) => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isCardFormLoading, setCardFormLoading] = useState(false);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const customerId = useAppSelector(selectStripeCustomerId);

  const subscriptionId = useAppSelector(selectAccountSubscriptionId);

  const CARD_ELEMENT_OPTIONS = {
    showIcon: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#14142b',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
        backgroundColor: 'rgb(241 241 241)',
        lineHeight: '30px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (isCardFormLoading) {
      return;
    }

    setCardFormLoading(true);

    if (stripe && elements) {
      const card = elements.getElement(CardNumberElement);
      const addressElement = elements.getElement('address');

      // @ts-ignore
      const { complete, value } = await addressElement!.getValue();

      if (card && complete) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card,
          billing_details: {
            ...value,
          },
        });

        if (!error) {
          // Update the card on the BE.
          try {
            if (customerId && subscriptionId) {
              const data = {
                paymentMethodId: paymentMethod.id,
                userId: user.userId,
                customerId,
                subscriptionId,
              };

              const options = {
                token: user.jwtToken.token,
                clientIp: clientIp,
                sessionId: user.sessionId,
              };

              await dispatch(updatePaymentMethod({ data, options })).unwrap();

              notification.success({
                message: 'Success',
                description: 'Payment method updated successfully',
              });

              setCardFormLoading(false);

              handleFormCancel();
            }
          } catch (error) {
            console.log(error);
            setCardFormLoading(false);
          }
        } else {
          notification.error({ message: 'Error', description: error.message });

          setCardFormLoading(false);
        }
      } else {
        setCardFormLoading(false);
      }
    }
  };

  const handleFormCancel = () => {
    if (isCardFormLoading) {
      return;
    }
    handleModalClose();
  };

  return (
    <div className="l-update-card__card-form-wrapper">
      {isCardFormLoading && <FullPageLoader />}
      <div className="l-update-card__card-fields-wrapper">
        <div className="l-update-card__card-field-wrapper">
          <div className="l-update-card__card-field-label">Card number</div>
          <div className="l-update-card__card-field">
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        <div className="l-update-card__card-field-wrapper">
          <div className="l-update-card__card-field-label">Expiration</div>
          <div className="l-update-card__card-field">
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        <div className="l-update-card__card-field-wrapper">
          <div className="l-update-card__card-field-label">CVC</div>
          <div className="l-update-card__card-field">
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
      </div>
      <div className="l-update-card__add-fields-wrapper">
        <AddressElement
          options={{
            mode: 'billing',
          }}
        />
      </div>

      <div
        className={`l-onboarding__footer l-team-setup__footer l-update-card__footer`}
      >
        <Button
          className="l-onboarding__save-btn"
          style={{ width: 132, marginRight: 20 }}
          onClick={handleFormCancel}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          className="l-onboarding__save-btn"
          style={{ width: 132 }}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default CardForm;
