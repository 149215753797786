import { map as pluck } from 'lodash';
import React from 'react'; // useState

import ArrowLeftTail from '../../../../foundation/assets/svgs/ArrowLeftTail';
import FilterIcon from '../../../../foundation/assets/svgs/Filter';
import MapIcon from '../../../../foundation/assets/svgs/MapIcon';
import MousePointer from '../../../../foundation/assets/svgs/MousePointer';
import SaveSearch from '../../../../foundation/assets/svgs/SaveSearch';
import Search from '../../../../foundation/assets/svgs/Search';
import Sort from '../../../../foundation/assets/svgs/Sort';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch } from '../../../../store/hooks';
import { useAppSelector } from '../../../../store/hooks';
import { setSiderMobileContentValues } from '../../../layout/redux/slice';
import AccessControl from '../../../user/access_control/AccessControl';
import {
  selectHasActiveResultInView,
  selectIsHeatmapActive,
  selectQueryValues,
  selectResults,
} from '../../redux/selectors';
import { resetActiveSearch, setIsHeatMapActive } from '../../redux/slice';
import {
  setIsFilterModalActive,
  setIsSaveSearchModalActive,
  setIsSortModalActive,
} from '../../redux/slice';

const Bar = ({ isMobileMapActive, setIsMobileMapActive }: any) => {
  const dispatch = useAppDispatch();

  const queryValues = useAppSelector(selectQueryValues);
  const results = useAppSelector(selectResults);

  const hasActiveResultInView = useAppSelector(selectHasActiveResultInView);
  const isHeatmapActive = useAppSelector(selectIsHeatmapActive);

  const [isMobileView, isDesktopView] = useBreakpoint();

  const searchType = useSearchType();
  const searchTypeAttribute =
    searchType === 'suburb' ? 'suburbs' : 'properties';

  const enableFilterHandler = () => {
    if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'search_filter',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Filter',
        parentConfig: undefined as any,
      };

      dispatch(setSiderMobileContentValues(data));
    } else {
      dispatch(setIsFilterModalActive(true));
    }
  };

  return (
    <>
      <div className="l-bar">
        {!isMobileMapActive && hasActiveResultInView && (
          <button
            className="l-bar__back"
            onClick={() => {
              dispatch(resetActiveSearch());
            }}
            type="button"
          >
            <ArrowLeftTail color="#262338" />
          </button>
        )}
        {!isMobileMapActive && !hasActiveResultInView && (
          <div
            className="l-bar__search-icon"
            onClick={(e) => {
              if (hasActiveResultInView) {
                e.stopPropagation();
                return false;
              }

              enableFilterHandler();
            }}
          >
            <Search />
          </div>
        )}
        {isMobileMapActive && (
          <>
            <button
              className="l-bar__back"
              onClick={() => {
                setIsMobileMapActive(false);
              }}
              type="button"
            >
              <ArrowLeftTail color="#262338" />
            </button>
            <div
              className="l-bar__query-values"
              onClick={() => {
                setIsMobileMapActive(false);
              }}
            >
              {`Back${
                results && results[searchTypeAttribute].length
                  ? ' to results'
                  : ''
              }`}
            </div>
          </>
        )}
        {!isMobileMapActive && (
          <div
            className="l-bar__query-values"
            onClick={(e) => {
              if (hasActiveResultInView) {
                dispatch(resetActiveSearch());
                e.stopPropagation();
                return false;
              }

              enableFilterHandler();
            }}
          >
            {queryValues && queryValues.length ? (
              <>{pluck(queryValues, 'key').join('; ')}</>
            ) : (
              'Search'
            )}
          </div>
        )}
        <div className="l-bar__search-bar-actions">
          {!hasActiveResultInView && !isMobileMapActive && (
            <>
              <AccessControl requiredFeature="suburb-save-search">
                <button
                  type="button"
                  className="l-bar__button-common l-bar__button-common--light"
                  onClick={() => {
                    if (isMobileView) {
                      const data = {
                        isVisible: true,
                        view: 'save_search',
                        showBackButton: false,
                        isClosable: true,
                        headerTitle: 'Save Search',
                        parentConfig: undefined as any,
                      };

                      dispatch(setSiderMobileContentValues(data));
                    } else {
                      dispatch(setIsSaveSearchModalActive(true));
                    }
                  }}
                >
                  <SaveSearch />
                  {isDesktopView && <span>Save Search</span>}
                </button>
              </AccessControl>
              <button
                type="button"
                onClick={() => {
                  enableFilterHandler();
                }}
                className="l-bar__button-common l-bar__button-common--light"
              >
                <FilterIcon />
                {isDesktopView && <span>Filter</span>}
              </button>
              {isMobileView && (
                <button
                  type="button"
                  onClick={() => {
                    if (isMobileView) {
                      const data = {
                        isVisible: true,
                        view: 'sort',
                        showBackButton: false,
                        isClosable: true,
                        headerTitle: 'Sort By',
                        parentConfig: undefined as any,
                      };

                      dispatch(setSiderMobileContentValues(data));
                    } else {
                      dispatch(setIsSortModalActive(true));
                    }
                  }}
                  className="l-bar__button-common l-bar__button-common--light"
                >
                  <Sort
                    color="#651FFF"
                    style={{ marginLeft: '2px', width: '17px' }}
                  />
                  {isDesktopView && <span>Sort</span>}
                </button>
              )}
            </>
          )}
          {!isMobileMapActive && (
            <button
              type="button"
              onClick={() => {
                setIsMobileMapActive(!isMobileMapActive);
              }}
              className="l-bar__button-common l-bar__button-common--light"
            >
              <MapIcon />
              {isDesktopView && <span>Map</span>}
            </button>
          )}
          {isMobileMapActive && !isHeatmapActive && (
            <button
              className="l-bar__button-common l-bar__button-common--light l-bar__button-common--padded"
              onClick={() => {
                dispatch(setIsHeatMapActive(true));
              }}
              type="button"
            >
              <MousePointer />
              <span>Heatmap</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Bar;
