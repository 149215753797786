import { CaretDownOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorField from '../../../../foundation/components/error_field/ErrorField';
import Input from '../../../../foundation/components/input/Input';
import TextArea from '../../../../foundation/components/textarea/TextArea';
import messages from '../../../../foundation/messages';
import { useAppDispatch } from '../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import { requestChannelCreation } from '../../redux/async_thunks';
import {
  setIsChannelsListFetching,
  setIsConversationsListFetching,
} from '../../redux/slice';
import CreateChannelValidationSchema from './validation_schema';

type CreateChannelProps = {
  closeModal?: () => any;
};

const CreateChannel = ({ closeModal }: CreateChannelProps) => {
  const dispatch = useAppDispatch();

  const [isCreateChannelFormLoading, setCreateChannelFormLoading] =
    useState(false);

  const [isPasswordRequired, setIsPasswordRequired] = useState(false);

  const user = useSelector(selectUser);
  const clientIp = useSelector(selectClientIp);

  const handleFormSubmit = async (values: any) => {
    if (isCreateChannelFormLoading) {
      return;
    }

    try {
      setCreateChannelFormLoading(true);

      if (user && clientIp) {
        const data: any = {
          channelName: values.channelName,
          channelPurpose: values.channelPurpose,
          restrictionType:
            values.restrictionType === 'none' ? null : values.restrictionType,
          userId: user?.userId,
        };

        if (values.restrictionPassword) {
          data.restrictionPassword = values.restrictionPassword;
        }

        const options = {
          token: user?.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        await dispatch(
          // @ts-ignore
          requestChannelCreation({
            data,
            options,
          }),
        ).unwrap();

        setCreateChannelFormLoading(false);
        dispatch(setIsChannelsListFetching(true));
        dispatch(setIsConversationsListFetching(true));

        if (closeModal) {
          closeModal();
        }

        notification.success({
          message: 'Success!',
          description: messages.channel.SUCCESS_CREATE_CHANNEL,
        });
      }
    } catch (error) {
      setCreateChannelFormLoading(false);

      if (closeModal) {
        closeModal();
      }
    }
  };
  return (
    <div className="l-create-channel-modal">
      <div className="l-create-channel-modal__info">
        Create up to 5 channels to exchange messages on different topics with
        others
      </div>
      <Formik
        initialValues={{
          channelName: undefined,
          channelPurpose: undefined,
          restrictionType: 'none',
          restrictionPassword: undefined,
          confirmRestrictionPassword: undefined,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={CreateChannelValidationSchema(isPasswordRequired)}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
        }) => {
          setTimeout(
            () => setIsPasswordRequired(values.restrictionType === 'password'),
            0,
          );

          return (
            <form onSubmit={handleSubmit}>
              <Input
                label="Name of channel"
                value={values.channelName}
                onChange={handleChange}
                onBlur={handleBlur}
                name="channelName"
                // @ts-ignore
                error={errors && errors.channelName}
                wrapperClassName="l-create-channel-modal__field-wrapper"
              />
              <TextArea
                label="Channel description"
                value={values.channelPurpose}
                onChange={handleChange}
                onBlur={handleBlur}
                name="channelPurpose"
                wrapperClassName="l-create-channel-modal__field-wrapper"
                // @ts-ignore
                error={errors && errors.channelPurpose}
              />
              <div className="c-input-wrapper l-create-channel-modal__field-wrapper">
                <div className="slide-up-label">
                  <select
                    value={values.restrictionType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="restrictionType"
                    id="restrictionType"
                  >
                    <option value="none">No restriction</option>
                    <option value="private">Only invited members</option>
                    <option value="password">Password protected</option>
                  </select>
                  <label htmlFor="restrictionType">Channel access</label>
                  <span
                    style={{
                      position: 'absolute',
                      top: '16px',
                      right: '12px',
                    }}
                  >
                    <CaretDownOutlined />
                  </span>
                </div>
              </div>

              {isPasswordRequired && (
                <>
                  <div className="c-input-wrapper l-create-channel-modal__field-wrapper">
                    <div className="slide-up-label">
                      <input
                        type="password"
                        name="restrictionPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="l-create-channel-modal__field-wrapper"
                        placeholder=" "
                      />
                      <label htmlFor="restrictionPassword">Password</label>
                    </div>
                    {errors.restrictionPassword && (
                      // @ts-ignore
                      <ErrorField error={errors.restrictionPassword} />
                    )}
                  </div>
                  <div className="c-input-wrapper l-create-channel-modal__field-wrapper">
                    <div className="slide-up-label">
                      <input
                        type="password"
                        name="confirmRestrictionPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="l-create-channel-modal__field-wrapper"
                        placeholder=" "
                      />
                      <label htmlFor="confirmRestrictionPassword">
                        Confirm password
                      </label>
                    </div>
                    {errors.confirmRestrictionPassword && (
                      // @ts-ignore
                      <ErrorField error={errors.confirmRestrictionPassword} />
                    )}
                  </div>
                </>
              )}
              <Button
                htmlType="submit"
                type="primary"
                className="l-create-channel-modal__submit-btn"
                disabled={!(isValid && dirty) || isCreateChannelFormLoading}
              >
                Create Channel
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateChannel;
