import { useAuth0 } from '@auth0/auth0-react';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useLogout from '../../../../foundation/custom_hooks/useLogout';

const Error = () => {
  const { isAuthenticated } = useAuth0();
  const [isLogoutButtonVisible, setIsLogoutButtonVisible] =
    useState<boolean>(false);

  const location = useLocation();
  const logoutUser = useLogout();

  const handleLogoutClick = () => {
    logoutUser(true);
  };

  const handleBackClick = () => {
    // Native redirect to reset the store
    window.location.href = window.location.origin;
  };

  const renderLogoutButton = () => {
    return isLogoutButtonVisible ? (
      <Button type="primary" onClick={handleLogoutClick}>
        Logout
      </Button>
    ) : null;
  };

  const state = location.state;

  const errorMessage =
    // @ts-ignore
    state && state.error ? state.error.responseMessage : null;

  const errorResponseCode =
    // @ts-ignore
    state && state.error ? state.error.responseCode : null;

  const isNotAllowed =
    errorResponseCode &&
    (errorResponseCode === 403 || errorResponseCode === 204);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLogoutButtonVisible(true);
    }
  }, [isAuthenticated]);

  return (
    <Result
      status="error"
      title="Error"
      subTitle={
        errorMessage ? errorMessage : 'Something went wrong with the request.'
      }
      extra={
        <>
          {!isNotAllowed && (
            <Button type="primary" onClick={handleBackClick}>
              Back Home
            </Button>
          )}
          {renderLogoutButton()}
        </>
      }
    />
  );
};

export default Error;
