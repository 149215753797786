import { Modal, notification } from 'antd';
import React, { useMemo, useState } from 'react';

import ArrowRightPurple from '../../../../foundation/assets/svgs/ArrowRightPurple';
import ArrowRightTeal from '../../../../foundation/assets/svgs/ArrowRightTeal';
import Building from '../../../../foundation/assets/svgs/Building';
import Close from '../../../../foundation/assets/svgs/Close';
import House from '../../../../foundation/assets/svgs/House';
import OpenModalIcon from '../../../../foundation/assets/svgs/OpenModal';
import ShareBubble from '../../../../foundation/assets/svgs/ShareBubble';
import StarPlain from '../../../../foundation/assets/svgs/StarPlain';
import StarPurple from '../../../../foundation/assets/svgs/StarPurple';
import StarTeal from '../../../../foundation/assets/svgs/StarTeal';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import AccessControl from '../../../user/access_control/AccessControl';
import {
  selectClientIp,
  selectUser,
  selectUserFeatures,
} from '../../../user/redux/selectors';
import ShareSuburb from '../../form/search/share_suburb/ShareSuburb';
import { postFavorite, postSuburbPreview } from '../../redux/async_thunks';
import {
  getIsFavorite,
  selectIsMapSearchEnabled,
  selectMapSearchPropertyType,
  selectResults,
} from '../../redux/selectors';
import { selectOptions } from '../../redux/selectors';
import { setActiveSuburbSscCode } from '../../redux/slice';
import { setIsSearchFeatureLoading } from '../../redux/slice';
import ShareReportModal from '../report/ShareReportModal';

type Props = {
  suburb: any;
  isReadOnly?: boolean;
};

const Heading = ({ suburb, isReadOnly }: Props) => {
  const dispatch = useAppDispatch();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const results = useAppSelector(selectResults);

  const options = useAppSelector(selectOptions);

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const [isShareReportModalVisible, setShareReportModalVisibility] =
    useState(false);

  const [isShareSuburbModalVisible, setIsShareSuburbModalVisible] =
    useState(false);

  const features = useAppSelector(selectUserFeatures);

  const [preview, setPreview] = useState<any>();

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const isSuburbInFavoriteList = useAppSelector(
    getIsFavorite(suburb.sscCode, 'suburb'),
  );

  const isSuburbFavorite = suburb.isFavorite || isSuburbInFavoriteList;

  const favoriteSuburb = async (
    sscCode: number,
    isFavorite: boolean,
    suburbName: string,
  ) => {
    try {
      await dispatch(
        postFavorite({
          data: {
            userId: USER.userId,
            sscCode: sscCode,
            isFavorite: !isFavorite,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: 'suburb',
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      notification.success({
        message: 'Success',
        description: `${suburbName} has been ${
          isFavorite ? 'removed from' : 'added to'
        } your favorite list.`,
        placement: 'topRight',
        top: 100,
        closeIcon: (
          <strong className="l-search-main__close">
            <Close />
          </strong>
        ),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const generatePreviewTemplate = async (sscCode: any) => {
    dispatch(setIsSearchFeatureLoading(true));

    try {
      const response = await dispatch(
        postSuburbPreview({
          data: {
            ...{
              userId: USER.userId,
              sscCode: sscCode,
            },
            ...options,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      setIsShareSuburbModalVisible(true);
      setPreview(response);
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setIsSearchFeatureLoading(false));
  };

  const shareSuburbModalCloseHandler = () => {
    setPreview(null);
    setIsShareSuburbModalVisible(false);
  };

  const openShareReportModal = () => {
    setShareReportModalVisibility(true);
  };

  const shareReportModalCloseHandler = () => {
    setShareReportModalVisibility(false);
  };

  let isPropertyTypeHouses: any =
    (results && results.propertyType === 'Houses') || !results;

  if (isMapSearchEnabled) {
    isPropertyTypeHouses = mapSearchPropertyType;
  }

  const isReportFeatureAvailable = useMemo(() => {
    if (features.indexOf('white-suburb-report') > -1) {
      return true;
    }
    return false;
  }, [features]);

  return (
    <>
      {isShareReportModalVisible && (
        <ShareReportModal
          suburb={suburb}
          handleClose={shareReportModalCloseHandler}
        />
      )}
      {isShareSuburbModalVisible && (
        <Modal
          title={'Share Suburb'}
          open={true}
          footer={false}
          onCancel={shareSuburbModalCloseHandler}
          centered
          maskClosable={false}
          width={455}
          closeIcon={
            <strong className="l-filter-modal__close">
              <Close />
            </strong>
          }
        >
          <ShareSuburb
            previewLink={preview ? preview.linkUrl : ''}
            cancelHandler={shareSuburbModalCloseHandler}
            sscCode={suburb.sscCode}
          />
        </Modal>
      )}
      <div className={`l-heading${isReadOnly ? ' l-heading--read-only' : ''}`}>
        <div className="l-heading__body">
          {isPropertyTypeHouses ? <House /> : <Building />}
          <button
            className="l-heading__title"
            type="button"
            onClick={() => {
              if (!isReadOnly) {
                dispatch(setActiveSuburbSscCode(suburb.sscCode));
              }
            }}
          >
            <span
              className={`l-heading__name${
                isPropertyTypeHouses ? '' : ' l-heading__name--teal'
              }`}
            >
              <strong>{suburb.suburbName},</strong>
              <span style={{ whiteSpace: 'nowrap' }}>
                {' '}
                {suburb.state} {suburb.postCode}
                {!isReadOnly && (
                  <>
                    {isPropertyTypeHouses ? (
                      <ArrowRightPurple />
                    ) : (
                      <ArrowRightTeal />
                    )}
                  </>
                )}
              </span>
            </span>
          </button>
          <div className="l-heading__actions">
            <button
              type="button"
              className="l-heading__action-button"
              onClick={() => {
                favoriteSuburb(
                  suburb.sscCode,
                  isSuburbFavorite,
                  suburb.suburbName,
                );
              }}
            >
              {isSuburbFavorite ? (
                suburb && isPropertyTypeHouses ? (
                  <StarPurple />
                ) : (
                  <StarTeal />
                )
              ) : (
                <StarPlain />
              )}
            </button>
            <AccessControl requiredFeature="messaging">
              <button
                type="button"
                className="l-heading__action-button"
                onClick={() => {
                  generatePreviewTemplate(suburb.sscCode);
                }}
              >
                <ShareBubble />
              </button>
            </AccessControl>

            {isReportFeatureAvailable && (
              <button
                type="button"
                className="l-heading__action-button"
                onClick={openShareReportModal}
              >
                <OpenModalIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
