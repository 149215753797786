import { notification, Upload } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Plus from '../../../foundation/assets/svgs/PlusCircleOutlined';
import messages from '../../../foundation/messages';
import { useAppDispatch } from '../../../store/hooks';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import { scanFile } from '../redux/async_thunks';
import { getMediaMessageType } from '../utils/controllers/messageController';

type Props = {
  filesList: any[];
  unscannedFiles: any[];
  setFilesList: (filesList: any[]) => any;
  setUnScannedFiles: (filesList: any[]) => any;
};

const Attachment = ({
  setFilesList,
  filesList,
  setUnScannedFiles,
  unscannedFiles,
}: Props) => {
  const dispatch = useAppDispatch();

  const USER = useSelector(selectUser);
  const CLIENT_IP = useSelector(selectClientIp);

  function getArrayBuffer(file: any): Promise<string | ArrayBuffer> {
    return new Promise((resolve) => {
      const reader: any = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsArrayBuffer(file);
    });
  }

  function getBase64(file: any): Promise<string | ArrayBuffer> {
    return new Promise((resolve) => {
      const reader: any = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsDataURL(file);
    });
  }

  const handleFileScanning = async (files: any[]) => {
    if (files && files.length > 0) {
      const currentFiles = [...filesList];

      for (const file of files) {
        const scannedFile = await scanMedia(file);

        currentFiles.push(scannedFile);
      }

      setUnScannedFiles([]);

      setFilesList(currentFiles);
    }
  };

  const scanMedia = async (file: any) => {
    try {
      if (USER && CLIENT_IP) {
        await dispatch(
          scanFile({
            file,
            userId: USER.userId,
            sessionId: USER.sessionId,
            clientIp: CLIENT_IP,
            token: USER?.jwtToken.token,
          }),
          // @ts-ignore
        ).unwrap();

        const messageType = getMediaMessageType(file);

        // Get this url from response in real world.

        const [buffer, url] = await Promise.all([
          getArrayBuffer(file),
          getBase64(file),
        ]);

        const newFile = new File([buffer], file.name, file);

        return { file: newFile, messageType, base64URL: url };
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error!',
        description: `${file.name}'s ${messages.chat_messages.ERROR_ATTACHMENT_UPLOAD}`,
      });
      return false;
    }
  };

  useEffect(() => {
    handleFileScanning(unscannedFiles);
  }, [unscannedFiles]);

  const beforeUpload = (file: any, filelist: any) => {
    setUnScannedFiles(filelist);

    return Upload.LIST_IGNORE;
  };

  const fileUploader = (v: any) => {
    return true;
  };

  return (
    <Upload
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={fileUploader}
      multiple
      accept=".png,.jpg,.jpeg,.jfif,.pdf,.xlsx,.xls,.docx,.doc,.pptx,.ppt"
      className="rich-text__bottom-ant-select"
      maxCount={4}
      disabled={unscannedFiles.length > 0}
    >
      <Plus />
    </Upload>
  );
};

export default Attachment;
