import React from 'react';

function Filter({ color = '#651FFF' }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.3334H8.33333M8.33333 10.3334C8.33333 11.438 9.22876 12.3334 10.3333 12.3334C11.4379 12.3334 12.3333 11.438 12.3333 10.3334C12.3333 9.22884 11.4379 8.33341 10.3333 8.33341C9.22876 8.33341 8.33333 9.22884 8.33333 10.3334ZM5 3.66675H12.3333M5 3.66675C5 4.77132 4.10457 5.66675 3 5.66675C1.89543 5.66675 1 4.77132 1 3.66675C1 2.56218 1.89543 1.66675 3 1.66675C4.10457 1.66675 5 2.56218 5 3.66675Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Filter;
