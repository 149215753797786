import { Modal } from 'antd';
import React from 'react';

import OrganizationSettings from '../../components/organization_settings/OrganizationSettings';

type OrganizationModalType = {
  handleCancel: () => void;
};
const OrganizationModal = ({ handleCancel }: OrganizationModalType) => {
  const modalProps = {
    wrapClassName: 'l-onboarding',
    closable: false,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 650,
    // onCancel: handleCancel,
  };

  return (
    <Modal {...modalProps}>
      <div className="l-onboarding__section">
        <div className="l-onboarding__section-title">Update organization</div>
        <div className="l-onboarding__section-sub-title">
          Update your organization details
        </div>
        <OrganizationSettings type="account" handleCancel={handleCancel} />
      </div>
    </Modal>
  );
};

export default OrganizationModal;
