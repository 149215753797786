export const suburbLayerStyle: any = {
  custom: {
    paint: {
      'line-color': 'rgba(113, 107, 190, 1)',
    },
  },
  satellite: {
    paint: {
      'line-color': 'rgba(255, 255, 255, .9)',
    },
  },
  dark: {
    paint: {
      'line-color': 'rgba(255, 255, 255, .5)',
    },
  },
  outdoors: {
    paint: {
      'line-color': 'rgba(125, 125, 125, .9)',
    },
  },
};

export const suburbHighlightedStyle: any = {
  custom: {
    paint: {
      'line-color': 'rgba(127, 17, 224, 1)',
      'line-opacity': 1,
      'line-width': 2,
    },
  },
  satellite: {
    paint: {
      'line-color': '#fff',
      'line-opacity': 1,
      'line-width': 4,
    },
  },
  dark: {
    paint: {
      'line-color': '#fff',
      'line-opacity': 1,
      'line-width': 3,
    },
  },
  outdoors: {
    paint: {
      'line-color': '#FBC351',
      'line-opacity': 1,
      'line-width': 2,
    },
  },
};

export const suburbSelectedStyle: any = {
  custom: {
    paint: {
      'line-color': 'rgba(127, 17, 224, 0.8)',
      'line-opacity': 1,
      'line-width': 4,
    },
  },
  satellite: {
    paint: {
      'line-color': '#1affec',
      'line-opacity': 1,
      'line-width': 4,
    },
  },
  dark: {
    paint: {
      'line-color': '#078D9C',
      'line-opacity': 1,
      'line-width': 3,
    },
  },
  outdoors: {
    paint: {
      'line-color': '#FAAD14',
      'line-opacity': 1,
      'line-width': 4,
    },
  },
};
