import { BackTop } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import PoweredLogo from '../../foundation/assets/svgs/PoweredLogo';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getClientIP } from '../user/redux/async_thunks';
import { postReport } from './redux/async_thunks';
import {
  selectOrgPicture,
  selectPropertyType,
  selectSuburb,
} from './redux/selectors';
import SuburbDetails from './suburb_details/SuburbDetails';
import SuburbMap from './suburb_map/SuburbMap';

const Report = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const suburb: any = useAppSelector(selectSuburb);
  const propertyType = useAppSelector(selectPropertyType);
  const orgPicture = useAppSelector(selectOrgPicture);

  const { reportLinkId } = useParams<{ reportLinkId: string }>();

  const [timeStamp, setTimeStamp] = useState<number>();
  const [clientIp, setClientIp] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const errorHandler = (error: any) => {
    console.log(error);
    navigate('/unauthorized');
  };

  const fetchClientIP = async () => {
    try {
      const response = await dispatch(getClientIP())
        // @ts-ignore
        .unwrap();

      setTimeStamp(response.timeStamp);
      setClientIp(response.ip);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchReport = async () => {
    try {
      await dispatch(
        postReport({
          data: {
            clientIp: clientIp,
            reportLinkId: reportLinkId,
          },
          options: {
            clientIp: clientIp,
            timeStamp: timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchClientIP();
  }, []);

  useEffect(() => {
    if (clientIp && timeStamp) {
      fetchReport();
    }
  }, [clientIp, timeStamp]);

  useEffect(() => {
    if (suburb) {
      setIsLoading(false);
    }
  }, [suburb]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <section className="l-report">
      <div className="l-report-main">
        <div className="l-report-main__content">
          {orgPicture && (
            <div className="l-report__org-picture">
              <img src={orgPicture} alt="" />
            </div>
          )}
          <h3>Suburb Report</h3>
          <p className="l-report__powered-by">
            powered by <PoweredLogo />
          </p>
          <SuburbMap />
          <div className="l-report__heading">
            <h4>
              {suburb.suburbName}, {suburb.postCode} {suburb.state}
            </h4>
            <div className="l-report__tag">{propertyType} Market</div>
          </div>
        </div>
      </div>
      <SuburbDetails />
      <BackTop />
    </section>
  );
};

export default Report;
