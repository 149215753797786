import PropTypes from 'prop-types';
import React, { useState } from 'react';

type Props = {
  label: string;
  value: string;
  children: any;
};

const FloatLabel = (props: Props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0)
      ? 'c-float-label__label c-float-label__label--float'
      : 'c-float-label__label';

  return (
    <div
      className="c-float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

FloatLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
};

export default FloatLabel;
