import React from 'react';

function Italic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="12"
      fill="none"
      viewBox="0 0 9 12"
    >
      <path
        fill="#AFAFAF"
        d="M5.438 11.675L5.342 12H.438l.123-.325c.492-.012.817-.053.975-.123.258-.1.448-.237.571-.413.194-.276.393-.768.598-1.477l2.074-7.19c.176-.597.264-1.048.264-1.353a.643.643 0 00-.114-.387.683.683 0 00-.352-.237C4.425.437 4.123.407 3.672.407l.105-.325h4.606l-.097.325c-.375-.006-.653.035-.835.123a1.357 1.357 0 00-.606.501c-.135.217-.31.698-.528 1.442l-2.065 7.19c-.188.661-.281 1.083-.281 1.265 0 .146.035.272.105.378.076.1.194.178.352.237.164.053.5.097 1.01.132z"
      ></path>
    </svg>
  );
}

export default Italic;
