import { CloseOutlined } from '@ant-design/icons';
import { Avatar, Layout, Popover } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LogoWithLines from '../../../foundation/assets/svgs/LogoWithLines';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import FavHeader from '../../user/favorite/FavHeader';
import Invite from '../../user/invite/Invite';
import NotificationHeader from '../../user/notification/NotificationHeader';
import Profile from '../../user/profile/Profile';
import {
  selectIsSubscribed,
  selectUserProfile,
} from '../../user/redux/selectors';

const { Header } = Layout;

type Props = {
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapsed: boolean;
};

const AppHeader = ({ setIsCollapsed, isCollapsed }: Props) => {
  const [isProfilePopoverVisible, setProfilePopoverVisibility] =
    useState(false);

  const [isInviteViewVisible, setInviteViewVisibility] = useState(false);

  const profile = useSelector(selectUserProfile);

  const isSubscribed = useSelector(selectIsSubscribed);

  const [isMobileView] = useBreakpoint();

  const handleSiderCollapsing = () => {
    setIsCollapsed((v) => !v);
  };

  const closeProfilePopover = () => {
    setProfilePopoverVisibility(false);
  };

  return (
    <Header className="c-layout-header">
      <Invite
        isVisible={isInviteViewVisible}
        setInviteViewVisibility={setInviteViewVisibility}
      />
      {isMobileView && (
        <div
          className="c-layout-header__right-items"
          onClick={handleSiderCollapsing}
          style={{ cursor: 'pointer' }}
        >
          <LogoWithLines />
        </div>
      )}
      <div className="c-layout-header__right-items">
        {isMobileView && !isCollapsed
          ? ''
          : isSubscribed && (
              <>
                <FavHeader />
                <NotificationHeader />
              </>
            )}

        {!isMobileView && (
          <>
            <Popover
              content={
                <>
                  <Profile closeProfilePopover={closeProfilePopover} />
                </>
              }
              // trigger="click"
              className="c-layout-header__user-popover "
              arrowPointAtCenter
              overlayInnerStyle={{
                borderRadius: 10,
                boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
                border: '1px solid #D9DBE9',
              }}
              showArrow={false}
              open={isProfilePopoverVisible}
              onOpenChange={(v) => setProfilePopoverVisibility(v)}
              trigger="click"
            >
              <Avatar
                src={profile?.picture}
                size={'large'}
                style={{ height: 50, width: 50 }}
                // onClick={() => setProfilePopoverVisibility((v) => !v)}
              />
            </Popover>

            {/* {isSubscribed && (
              <div>
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  className="c-layout-header__feedback-btn"
                >
                  <span>Feedback</span>
                  <FeedbackBubble />
                </Button>
              </div>
            )} */}
          </>
        )}
        {/* {isMobileView && isCollapsed && isSubscribed && (
          <Button
            shape="circle"
            icon={<FeedbackBubble />}
            type="primary"
            className="c-layout-header__invite-btn"
          />
        )} */}

        {isMobileView && !isCollapsed && (
          <div
            className="c-layout-header__close-sider-btn"
            onClick={() => setIsCollapsed(true)}
          >
            <CloseOutlined />
          </div>
        )}
      </div>
    </Header>
  );
};

export default AppHeader;
