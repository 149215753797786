import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message, Modal, RadioChangeEvent } from 'antd';
import { Divider, Input, Radio } from 'antd';
import React, { useMemo, useState } from 'react';

import ErrorField from '../../../foundation/components/error_field/ErrorField';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import { SubscriptionPlan } from '../../user/redux/types';
import {
  useAdditionUsersLabel,
  useFrequencyString,
} from '../helpers/custom_hooks/stringHooks';
import { usePlanRate } from '../helpers/custom_hooks/usePlanRate';
import { useSubTotal } from '../helpers/custom_hooks/useSubTotal';
import { getParsedValue } from '../helpers/getParsedValue';
import { PromoCodeDetailsType, SubscriptionFrequency } from '../helpers/types';
import { checkPromoCode } from '../redux/async_thunks';

const { confirm } = Modal;

type SummaryProps = {
  disabled: boolean;
  subscriptionFrequency: SubscriptionFrequency;
  selectedPlan: SubscriptionPlan;
  setSubscriptionFrequency: (v: SubscriptionFrequency) => void;
  buttonTitle: string;
  onButtonClick: () => void;
  setPromoCodeDetails: (v: PromoCodeDetailsType | undefined) => void;
  promoCodeDetails: PromoCodeDetailsType | undefined;
  additionalUsers: number;
};

const Summary = ({
  disabled,
  subscriptionFrequency,
  selectedPlan,
  setSubscriptionFrequency,
  buttonTitle,
  onButtonClick,
  promoCodeDetails,
  setPromoCodeDetails,
  additionalUsers,
}: SummaryProps) => {
  const dispatch = useAppDispatch();
  const [isSummaryLoading, setSummaryLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<undefined | string>(undefined);
  const [promoError, setPromoError] = useState<undefined | string>(undefined);
  const planRate = usePlanRate(selectedPlan, subscriptionFrequency);

  const subTotal = useSubTotal(additionalUsers, planRate);

  const additionalUsersLabel = useAdditionUsersLabel(additionalUsers);

  const frequencyString = useFrequencyString(subscriptionFrequency);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const onChange = (e: RadioChangeEvent) => {
    setSubscriptionFrequency(e.target.value);
  };

  const onPromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoError(undefined);
    setPromoCode(e.target.value);
  };

  const applyPromoCodeHandler = async () => {
    if (isSummaryLoading) {
      return;
    }
    try {
      if (user && promoCode) {
        const data = {
          userId: user?.userId,
          promoCode,
        };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        setSummaryLoading(true);
        const promoCodeDetails = await dispatch(
          checkPromoCode({ data, options }),
        ).unwrap();

        if (!promoCodeDetails?.isValid) {
          setPromoError('Invalid promo code.');
        } else {
          message.success('Promo code applied');
          setPromoCodeDetails(promoCodeDetails);
        }
        setSummaryLoading(false);
      }
    } catch (error: any) {
      setSummaryLoading(false);
      if (error?.message && error?.message.indexOf('Coupon') > -1) {
        setPromoError(error?.message);
      }
    }
  };

  const promoCodeAmount = useMemo(() => {
    if (promoCodeDetails) {
      if (promoCodeDetails.amountOff) {
        return promoCodeDetails.amountOff;
      } else if (promoCodeDetails.percentageOff && subTotal) {
        return getParsedValue(
          parseFloat(subTotal) * promoCodeDetails.percentageOff,
        );
      }
    }
    return 0;
  }, [promoCodeDetails, subTotal]);

  const total = useMemo(() => {
    if (subTotal && promoCodeAmount) {
      return getParsedValue(
        parseFloat(subTotal) - parseFloat(promoCodeAmount.toString()),
      );
    } else {
      return subTotal;
    }
  }, [promoCodeAmount, subTotal]);

  const showRemovePromoConfirm = () => {
    confirm({
      title: 'Do you want to remove the promo code?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        setPromoCodeDetails(undefined);
        setPromoCode(undefined);
        setPromoError(undefined);
      },
    });
  };

  return (
    <div className="l-billing-summary">
      {isSummaryLoading && <FullPageLoader />}
      <div className="l-billing-summary__plan-name">
        {selectedPlan?.subscriptionPlan}
      </div>
      <Divider />
      <div style={{ fontWeight: 600, marginBottom: 15 }}>Billing Cycle</div>
      <Radio.Group
        onChange={onChange}
        value={subscriptionFrequency}
        className="l-billing-summary__interval-wrapper"
        disabled={disabled}
      >
        <Radio value={'Monthly'}>Monthly</Radio>
        <Radio value={'Annually'}>
          <div>
            <div style={{ marginBottom: 8 }}>Annual</div>
            <div className="l-billing-summary__save-tag">Save upto 20%</div>
          </div>
        </Radio>
      </Radio.Group>

      <Divider />

      <div className="l-billing-summary__items-wrapper">
        <div className="l-billing-summary__item-wrapper">
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-name">
              {selectedPlan?.subscriptionPlan}
            </div>
            <div className="l-billing-summary__item-feature">
              1 x seat included
            </div>
          </div>
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-price">
              ${planRate && getParsedValue(planRate?.subscriptionAmount)}
              {`/${frequencyString}`}
            </div>
            {subscriptionFrequency === 'Annually' && (
              <div className="l-billing-summary__item-save-tag">20% off</div>
            )}
          </div>
        </div>
        {additionalUsers > 0 && (
          <div className="l-billing-summary__item-wrapper">
            <div className="l-billing-summary__item-details">
              <div className="l-billing-summary__item-name">
                Additional seats
              </div>
              <div className="l-billing-summary__item-feature">
                {additionalUsersLabel}
              </div>
            </div>
            <div className="l-billing-summary__item-details">
              <div className="l-billing-summary__item-price">
                $
                {planRate &&
                  getParsedValue(
                    additionalUsers * planRate.additionalUserAmount,
                  )}
                {`/${frequencyString}`}
              </div>
            </div>
          </div>
        )}
      </div>
      <Divider />

      {!promoCodeDetails && (
        <>
          {' '}
          <div className="l-billing-summary__promo-section">
            <div style={{ marginBottom: 10 }}>Optional promo code </div>
            <div className="l-billing-summary__promo-field-wrapper">
              <div>
                <Input
                  placeholder="Enter code"
                  className="l-billing-summary__promo-field"
                  value={promoCode}
                  onChange={onPromoCodeChange}
                  status={promoError ? 'error' : undefined}
                />
                <ErrorField error={promoError} />
              </div>
              <div>
                <Button
                  className="l-billing-summary__promo-btn"
                  onClick={applyPromoCodeHandler}
                >
                  Apply code
                </Button>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}

      <div className="l-billing-summary__total-section">
        <div className="l-billing-summary__account">{`Subtotal (incl. tax)`}</div>
        <div className="l-billing-summary__amount">${subTotal}</div>
      </div>
      {promoCodeDetails && (
        <div className="l-billing-summary__item-wrapper">
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-name">
              Promo Code{' '}
              <span>
                <DeleteOutlined
                  className="l-billing-summary__promo-del-icon"
                  onClick={showRemovePromoConfirm}
                />
              </span>
            </div>
            <div className="l-billing-summary__item-feature">
              {promoCodeDetails?.promoCode}
            </div>
          </div>
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-price">
              ${promoCodeAmount}
            </div>
          </div>
        </div>
      )}
      <Divider />

      <div className="l-billing-summary__total-section">
        <div className="l-billing-summary__account">{`Total (incl. tax)`}</div>
        <div className="l-billing-summary__amount">${total}</div>
      </div>

      <Button
        className="l-billing-summary__submit-btn"
        type="primary"
        onClick={isSummaryLoading ? undefined : onButtonClick}
        disabled={isSummaryLoading}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default Summary;
