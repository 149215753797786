import React from 'react';

import { setIsDisclaimerModalActive } from '../../../../features/search/redux/slice';
import { useAppDispatch } from '../../../../store/hooks';

const DisclaimerLink = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <span>Source: </span>
      <a
        href="#"
        onClick={(e: any) => {
          e.preventDefault();
          dispatch(setIsDisclaimerModalActive(true));
        }}
      >
        CoreLogic
      </a>
    </>
  );
};

export default DisclaimerLink;
