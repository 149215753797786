import React from 'react';

function SendMessage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#6E7191"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.5 1.5L8.25 9.75M16.5 1.5l-5.25 15-3-6.75M16.5 1.5l-15 5.25 6.75 3"
      ></path>
    </svg>
  );
}

export default SendMessage;
