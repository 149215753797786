import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

import { endpoint } from '../../../foundation/config/axios_instances';
import messages from '../../../foundation/messages';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const requestChannelApprove = createAsyncThunk(
  'chat/approve-channel',
  async (apiParams: {
    data: {
      isApproved: boolean;
      reason: string | undefined;
    };
    options: {
      clientIp: string;
      linkCode: string | null;
      timeStamp: number;
    };
  }) => {
    const clientId = getClientID(apiParams.options.clientIp);

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const data = {
      isApproved: apiParams.data.isApproved,
      reason: apiParams.data.reason || null,
      clientIp: apiParams.options.clientIp,
      linkCode: apiParams.options.linkCode,
      requestKey: encryptData(requestKey),
    };

    const encrypted = encryptData(data);

    const response = await axios.post(
      `${env_constants.RHOMEO_API}/Chat/approve-channel`,
      encrypted,
      {
        headers: {
          clientid: clientId,
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );

    return decryptData(response.data);
  },
);

export const requestChannelCreation = createAsyncThunk(
  'chat/create-channel',
  async (apiParams: {
    data: {
      userId: string;
      chanelName: string;
      channelPurpose: string | undefined;
      restrictionPassword: string | undefined;
      restrictionType: string | null;
    };
    options: {
      clientIp: string;
      sessionId: any;
      token: any;
    };
  }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Chat/create-channel`, encrypted, {
        headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data);
        }
      })
      .catch((error) => {
        const err = decryptData(error.response.data);
        console.log(err);
        notification.error({
          message: 'Error!',
          description: messages.channel.ERROR_CREATE_CHANNEL,
        });
        return Promise.reject(err);
      });
    return result;
  },
);

export const scanFile = createAsyncThunk(
  'chat/scan-file',
  async (apiParams: {
    file: string;
    userId: string;
    clientIp: string;
    sessionId: string;
    token: string;
  }) => {
    const clientId = getClientID(apiParams.clientIp);

    try {
      const encrypted = encryptData({
        userId: apiParams.userId,
      });

      const formData = new FormData();

      formData.append('f', apiParams.file);

      formData.append('e', encrypted);

      const response = await axios.post(
        `${env_constants.RHOMEO_API}/Chat/file-scan`,
        formData,
        {
          headers: {
            clientid: clientId,
            sessionid: apiParams.sessionId,
            Accept: '*/*',
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${apiParams.token}`,
          },
        },
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (error: any) {
      const err = decryptData(error.response.data);
      console.log(err);
    }
  },
);

export const getProfileForView = createAsyncThunk(
  'chat/get-profile',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    await endpoint
      .get(`${env_constants.RHOMEO_API}/Profile`, {
        headers: {
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiParams.options.token}`,
        },
        params: {
          pid: apiParams.data.pid,
        },
      })
      .then((response) => {
        if (response && response.data) {
          result = decryptData(response.data).profile;
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postProfileFollow = createAsyncThunk(
  'chat/follow',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(`${env_constants.RHOMEO_API}/Profile/follow`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          result = {
            followers: decryptData(response.data).followers,
            following: apiParams.data.follow,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const updateChannel = createAsyncThunk(
  'chat/updateChannel',
  async (apiParams: any, { rejectWithValue }) => {
    const formData = new FormData();

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    formData.append('e', encrypted);

    if (apiParams.file && apiParams.file.picture) {
      formData.append('f', apiParams.file.picture);
    }

    try {
      const response = await endpoint.put(
        `${env_constants.RHOMEO_API}/Chat/edit-channel`,
        formData,
        {
          headers: {
            clientid: clientId,
            sessionid: apiParams.options.sessionId,
            'content-type': 'multipart/form-data',
            Accept: '*/*',
            Authorization: `Bearer ${apiParams.options.token}`,
          },
        },
      );

      const decrypted = decryptData(response.data);

      return {
        pictureUrl: decrypted.pictureUrl,
      };
    } catch (error) {
      return rejectWithValue(
        // @ts-ignore
        error && error.response && error.response.data
          ? // @ts-ignore
            decryptData(error.response.data)
          : '',
      );
    }
  },
);

export const fetchChannelNotificationSettings = createAsyncThunk(
  'chat/fetchNotifyChannel',
  async (apiParams: { data: any; options: any }) => {
    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const response = await endpoint.get(
      `${env_constants.RHOMEO_API}/Chat/notify`,
      {
        headers: {
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiParams.options.token}`,
        },
        params: {
          e: encrypted,
        },
      },
    );

    if (response.status === 204) {
      return {
        appNotify: false,
        emailNotify: false,
      };
    }

    const decrypted = decryptData(response.data);

    return {
      appNotify: decrypted.appNotify,
      emailNotify: decrypted.emailNotify,
    };
  },
);

export const updateChannelNotificationSettings = createAsyncThunk(
  'chat/updateNotifyChannel',
  async (apiParams: { data: any; options: any }) => {
    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const response = await endpoint.post(
      `${env_constants.RHOMEO_API}/Chat/notify`,
      encrypted,
      {
        headers: {
          clientid: clientId,
          sessionid: apiParams.options.sessionId,
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiParams.options.token}`,
        },
      },
    );

    const decrypted = decryptData(response.data);

    return {
      appNotify: decrypted.appNotify,
      emailNotify: decrypted.emailNotify,
    };
  },
);
