import { RootState } from '../../../store';

export const selectReportUser = (state: RootState) => {
  return {
    userId: state.report.userId,
    sessionId: state.report.sessionId,
    jwtToken: state.report.jwtToken,
    clientIp: state.report.clientIp,
  };
};

export const selectOrgPicture = (state: RootState) => state.report.orgPicture;

export const selectSuburb = (state: RootState) => state.report.suburb;
export const selectPropertyType = (state: RootState) =>
  state.report.propertyType;

export const selectPopulation = (state: RootState) => state.report.population;
export const selectDwelling = (state: RootState) => state.report.dwelling;

export const selectDescriptors = (state: RootState) => state.report.descriptors;

export const selectGrowth = (state: RootState) => state.report.growth;

export const selectTimelineType = (state: RootState) =>
  state.report.timelineType;

export const selectKeyIndicatorsValues = (state: RootState) =>
  state.report.keyIndicatorsValues;

export const selectProjectSettings = (state: RootState) =>
  state.report.projectSettings;

export const selectProjects = (state: RootState) => state.report.projects;

export const selectGraphDisplaySettings = (state: RootState) =>
  state.report.graphDisplaySettings;

export const selectProjectsValues = (state: RootState) =>
  state.report.projectsValues;

export const getGraphIsLoadingByKey = (key: any) => (state: RootState) => {
  // @ts-ignore
  return state.report.isGraphLoading[key];
};

export const getGraphByType = (type: any) => (state: RootState) => {
  // @ts-ignore
  return state.report.graph[type];
};
