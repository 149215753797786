import { RootState } from '../../../../../store';

export const selectDescriptors = (state: RootState) =>
  state.details.descriptors;

export const selectPopulation = (state: RootState) => state.details.population;

export const selectDwelling = (state: RootState) => state.details.dwelling;

export const selectGrowth = (state: RootState) => state.details.growth;

export const selectArticlesDistance = (state: RootState) => {
  return state.details.relatedArticlesValues?.distance;
};

export const selectHashTags = (state: RootState) =>
  state.details.relatedArticlesValues?.hashTags;

export const getRelatedArticlesByHashTag =
  (hashTagValue: string) => (state: RootState) => {
    return state.details.relatedArticles[hashTagValue];
  };

export const selectTimelineType = (state: RootState) =>
  state.details.timelineType;

export const selectKeyIndicatorsValues = (state: RootState) =>
  state.details.keyIndicatorsValues;

export const selectGraphDisplaySettings = (state: RootState) =>
  state.details.graphDisplaySettings;

export const selectProjectsValues = (state: RootState) =>
  state.details.projectsValues;

export const getGraphIsLoadingByKey = (key: string) => (state: RootState) => {
  return state.details.isGraphLoading[key];
};

export const getGraphByType = (type: string) => (state: RootState) => {
  return state.details.graph[type];
};

export const selectProjectSettings = (state: RootState) =>
  state.details.projectSettings;

export const selectProjects = (state: RootState) => state.details.projects;
