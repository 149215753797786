import { Modal } from 'antd';
import React, { useEffect } from 'react';

import Close from '../../../../../foundation/assets/svgs/Close';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectBreakpoint } from '../../../../layout/redux/selectors';
import {
  resetSuggestions,
  setIsFilterModalActive,
  setKeyword,
} from '../../../redux/slice';
import Filter from '../Filter';

const FilterModal = ({ searchHandlerOptions, init }: any) => {
  const dispatch = useAppDispatch();
  const breakpoint = useAppSelector(selectBreakpoint);

  const cancelHandler = () => {
    dispatch(setIsFilterModalActive(false));
    dispatch(setKeyword(undefined));
    dispatch(resetSuggestions());
  };

  useEffect(() => {
    if (breakpoint === 'mobile') {
      cancelHandler();
    }
  }, [breakpoint]);

  return (
    <Modal
      title={'Filters'}
      open={true}
      footer={false}
      onCancel={cancelHandler}
      centered
      maskClosable={false}
      width={700}
      closeIcon={
        <strong className="l-filter-modal__close">
          <Close />
        </strong>
      }
    >
      <Filter
        init={init}
        searchHandlerOptions={searchHandlerOptions}
        cancelHandler={cancelHandler}
      />
    </Modal>
  );
};

export default FilterModal;
