import { createSlice } from '@reduxjs/toolkit';

import {
  getAppValues,
  patchRefresh,
  postLogin,
} from '../../user/redux/async_thunks';
import { postSuburbPopulation } from '../view/details/redux/async_thunks';
import {
  postFavorite,
  postMapSearchResults,
  postPropertyDetails,
  postSearchResults,
  postSearchSuggestions,
  postSuburbMetrics,
} from './async_thunks';
import { SearchState } from './types';

const minMaxDefault: any = {
  min: null,
  max: null,
};

export const searchMetricsDefaults: any = {
  suburb: {
    medianSalePrice: minMaxDefault,
    grossRentalYield: minMaxDefault,
    medianPriceGrowth1: minMaxDefault,
    medianPriceGrowth3: minMaxDefault,
    averageDaysMarket: minMaxDefault,
    vacancyRate: minMaxDefault,
    population: minMaxDefault,
    populationForecastRate3: minMaxDefault,
    populationForecastRate5: minMaxDefault,
    lgaTotalEstInvestment: minMaxDefault,
    quarterSalesGrowth: minMaxDefault,
    yearSalesGrowth: minMaxDefault,
    yearRentalGrowth1: minMaxDefault,
    medianSalePriceForecast15: minMaxDefault,
    medianWeeklyAskingRentForecast15: minMaxDefault,
  },
  property: {
    askingPrice: minMaxDefault,
    bedrooms: minMaxDefault,
    baths: null,
    carSpaces: null,
    landSize: minMaxDefault,
    averageDaysMarket: minMaxDefault,
    grossRentalYield: minMaxDefault,
    publicHousing: minMaxDefault,
    umvEstimate: minMaxDefault,
  },
};

const initialState: SearchState = {
  isFirstAttempt: true,
  isSearchFeatureLoading: false,
  isFilterModalActive: false,
  isHeatmapActive: false,
  isSaveSearchModalActive: false,
  isSortModalActive: false,
  isSkippingMapFocus: false,
  results: undefined,
  suggestions: [],
  queryValues: [],
  options: {
    propertyType: 'Houses',
    pageIndex: 1,
    sortType: null,
    orderAsc: true,
    searchMetrics: searchMetricsDefaults.suburb,
  },
  keyword: undefined,
  enabledSearchMetrics: [],
  activeSuburb: undefined,
  activeSuburbSscCode: undefined,
  activeProperty: undefined,
  activePropertyId: undefined,
  searchMetricValues: undefined,
  listContainerSize: 'wide',
  suburbMetricsValues: {
    propertyType: [],
    monthEndDate: [],
    metricType: [],
  },
  suburbMetrics: undefined,
  selectedSuburbMetricsOptions: undefined,
  lastSearchValues: undefined,
  searchType: 'suburb',
  isDisclaimerModalActive: false,
  filterTooltips: undefined,
  isMapSearchEnabled: false,
  gpsPoints: undefined,
  mapSummary: undefined,
  mapSearchPropertyType: 'Houses',
  isMapSearchLoading: false,
  mapSearchSscCodes: undefined,
};

export const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.suggestions = initialState.suggestions;
      state.results = initialState.results;
      state.activeSuburb = initialState.activeSuburb;
      state.activeSuburbSscCode = initialState.activeSuburbSscCode;
      state.activeProperty = initialState.activeProperty;
      state.activePropertyId = initialState.activePropertyId;
      state.enabledSearchMetrics = initialState.enabledSearchMetrics;
      state.keyword = initialState.keyword;
      state.queryValues = initialState.queryValues;
      state.isFirstAttempt = initialState.isFirstAttempt;

      state.options = {
        ...state.options,
        ...initialState.options,
        searchMetrics: initialState.options.searchMetrics,
      };
      state.searchType = initialState.searchType;

      state.gpsPoints = initialState.gpsPoints;
      state.mapSummary = initialState.mapSummary;
      state.mapSearchPropertyType = initialState.mapSearchPropertyType;
      state.isMapSearchEnabled = initialState.isMapSearchEnabled;
      state.isMapSearchLoading = initialState.isMapSearchLoading;
      state.mapSearchSscCodes = initialState.mapSearchSscCodes;
    },
    setIsFirstAttempt: (state, action) => {
      state.isFirstAttempt = action.payload;
    },
    setOptions: (state, action) => {
      state.options = {
        ...action.payload,
      };
    },
    setQueryValues: (state, action) => {
      state.queryValues = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = initialState.suggestions;
    },
    resetResults: (state) => {
      state.results = initialState.results;
    },
    setEnabledSearchMetrics: (state, action) => {
      state.enabledSearchMetrics = action.payload;
    },
    setListContainerSize: (state, action) => {
      state.listContainerSize = action.payload;
    },
    setActiveSuburb: (state, action) => {
      state.activeSuburb = action.payload;
    },
    setActiveSuburbSscCode: (state, action) => {
      state.activeSuburbSscCode = action.payload;
    },
    resetActiveSuburb: (state) => {
      state.activeSuburb = initialState.activeSuburb;
      state.activeSuburbSscCode = initialState.activeSuburbSscCode;
    },
    setActiveProperty: (state, action) => {
      state.activeProperty = action.payload;
    },
    setActivePropertyId: (state, action) => {
      state.activePropertyId = action.payload;
    },
    resetActiveProperty: (state) => {
      state.activeProperty = initialState.activeProperty;
      state.activePropertyId = initialState.activePropertyId;
    },
    resetActiveSearch: (state) => {
      state.activeSuburb = initialState.activeSuburb;
      state.activeSuburbSscCode = initialState.activeSuburbSscCode;
      state.activeProperty = initialState.activeProperty;
      state.activePropertyId = initialState.activePropertyId;
    },
    setIsSearchFeatureLoading: (state, action) => {
      state.isSearchFeatureLoading = action.payload;
    },
    setSelectedSuburbMetricsOptions: (state, action) => {
      state.selectedSuburbMetricsOptions = action.payload;
    },
    resetSuburbMetrics: (state) => {
      state.suburbMetrics = initialState.suburbMetrics;
    },
    setIsFilterModalActive: (state, action) => {
      state.isFilterModalActive = action.payload;
    },
    setIsSaveSearchModalActive: (state, action) => {
      state.isSaveSearchModalActive = action.payload;
    },
    setIsSortModalActive: (state, action) => {
      state.isSortModalActive = action.payload;
    },
    setIsHeatMapActive: (state, action) => {
      state.isHeatmapActive = action.payload;
    },
    setLastSearchValues: (state, action) => {
      state.lastSearchValues = action.payload;
    },
    setSearchTypeWithReset: (state, action) => {
      state.searchType = action.payload;
      state.suggestions = initialState.suggestions;
      state.enabledSearchMetrics = initialState.enabledSearchMetrics;
      state.options = {
        ...state.options,
        ...initialState.options,
        searchMetrics: searchMetricsDefaults[action.payload],
      };
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setIsSkippingMapFocus: (state, action) => {
      state.isSkippingMapFocus = action.payload;
    },
    setIsDisclaimerModalActive: (state, action) => {
      state.isDisclaimerModalActive = action.payload;
    },
    setIsMapSearchEnabled: (state, action) => {
      state.isMapSearchEnabled = action.payload;
    },
    resetGpsPoints: (state) => {
      state.gpsPoints = initialState.gpsPoints;
      state.mapSummary = initialState.mapSummary;
      state.mapSearchPropertyType = initialState.mapSearchPropertyType;
      state.isMapSearchLoading = initialState.isMapSearchLoading;
      state.mapSearchSscCodes = initialState.mapSearchSscCodes;
    },
    setIsMapSearchLoading: (state, action) => {
      state.isMapSearchLoading = action.payload;
    },
    setMapSearchSscCodes: (state, action) => {
      state.mapSearchSscCodes = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAppValues.fulfilled, (state, action) => {
      state.searchMetricValues = {
        suburb: action.payload.ssd.searchMetrics,
        property: action.payload.aps.searchMetrics,
      };
      state.suburbMetricsValues = action.payload.ssd.suburbMetricsValues;

      // Default selected dropdown values in the suburb metric form
      state.selectedSuburbMetricsOptions = {
        metricMonthDate:
          action.payload.ssd.suburbMetricsValues.monthEndDate[0].value,
        metricType: action.payload.ssd.suburbMetricsValues.metricType[0].value,
      };

      state.filterTooltips = {
        ssd: action.payload.ssd.filterTooltips,
        aps: action.payload.aps.filterTooltips,
      };
    });

    builder.addCase(postLogin.fulfilled, (state, action) => {
      state.options = {
        ...state.options,
        propertyType: initialState.options.propertyType,
        pageIndex: initialState.options.pageIndex,
        sortType: initialState.options.sortType,
        orderAsc: initialState.options.orderAsc,
      };
    });

    builder.addCase(patchRefresh.fulfilled, (state, action) => {
      state.options = {
        ...state.options,
        propertyType: initialState.options.propertyType,
        pageIndex: initialState.options.pageIndex,
        sortType: initialState.options.sortType,
        orderAsc: initialState.options.orderAsc,
      };
    });

    builder.addCase(postSearchSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });

    builder.addCase(postSearchSuggestions.rejected, (state) => {
      state.suggestions = initialState.suggestions;
    });

    builder.addCase(postSearchResults.fulfilled, (state, action: any) => {
      const searchType = action.payload.searchType;
      const searchTypeAttribute =
        searchType === 'suburb' ? 'suburbs' : 'properties';

      if (
        !!action.payload &&
        !!action.payload[searchTypeAttribute] &&
        !!action.payload[searchTypeAttribute].length
      ) {
        state.results = {
          [searchTypeAttribute]: action.payload[searchTypeAttribute],
          pageCount: action.payload.pageCount
            ? action.payload.pageCount
            : state.results.pageCount,
          resultCount: action.payload.resultCount
            ? action.payload.resultCount
            : state.results.resultCount,
          propertyType: action.payload.propertyType,
        };

        if (action.payload && action.payload.pageIndex) {
          state.options = {
            ...state.options,
            pageIndex: action.payload.pageIndex,
          };
        }

        if (action.payload) {
          state.lastSearchValues = action.payload.lastSearchValues || null;
        }
      } else {
        state.results = initialState.results;
      }
    });

    builder.addCase(postSearchResults.rejected, (state) => {
      state.results = initialState.results;
    });

    builder.addCase(postMapSearchResults.fulfilled, (state, action: any) => {
      if (
        !!action.payload &&
        !!action.payload.gpsPoints &&
        !!action.payload.gpsPoints.length
      ) {
        state.gpsPoints = action.payload.gpsPoints;
        state.mapSummary = action.payload.mapSummary;
        state.mapSearchPropertyType = action.payload.mapSearchPropertyType;
      } else {
        state.gpsPoints = initialState.gpsPoints;
        state.mapSummary = 'No results found.';
        state.mapSearchPropertyType = initialState.mapSearchPropertyType;
      }
    });

    builder.addCase(postMapSearchResults.rejected, (state) => {
      state.gpsPoints = initialState.gpsPoints;
      state.mapSummary = initialState.mapSummary;
      state.mapSearchPropertyType = initialState.mapSearchPropertyType;
    });

    builder.addCase(postFavorite.fulfilled, (state, action: any) => {
      const { entry, isFavorite, searchDataAttribute, searchTypeAttribute } =
        action.payload.updatedValue;

      if (state.results && state?.results[searchTypeAttribute]) {
        state.results = {
          ...state.results,
          [searchTypeAttribute]: state?.results[searchTypeAttribute].map(
            (result: any) => {
              if (result[searchDataAttribute] === entry) {
                return {
                  ...result,
                  isFavorite: isFavorite,
                };
              }

              return result;
            },
          ),
        };

        state.isSkippingMapFocus = true;
      }
    });

    builder.addCase(postSuburbPopulation.fulfilled, (state, action: any) => {
      state.activeSuburb = action.payload.suburb;
    });

    builder.addCase(postSuburbPopulation.rejected, (state) => {
      state.activeSuburb = initialState.activeSuburb;
    });

    builder.addCase(postPropertyDetails.fulfilled, (state, action: any) => {
      state.activeProperty = action.payload.property;
    });

    builder.addCase(postPropertyDetails.rejected, (state) => {
      state.activeProperty = initialState.activeProperty;
    });

    builder.addCase(postSuburbMetrics.fulfilled, (state, action: any) => {
      state.suburbMetrics = {
        metrics: action.payload.suburbMetric,
        metricsLegend: action.payload.metricsLegend,
      };
    });
  },
});

export const {
  setKeyword,
  setEnabledSearchMetrics,
  setOptions,
  resetSuggestions,
  setQueryValues,
  resetResults,
  setListContainerSize,
  setActiveSuburb,
  resetActiveSuburb,
  setActiveSuburbSscCode,
  setActiveProperty,
  resetActiveProperty,
  setActivePropertyId,
  setIsFirstAttempt,
  resetSearch,
  setIsSearchFeatureLoading,
  setSelectedSuburbMetricsOptions,
  resetSuburbMetrics,
  setIsFilterModalActive,
  setIsSaveSearchModalActive,
  setIsSortModalActive,
  setIsHeatMapActive,
  setLastSearchValues,
  resetActiveSearch,
  setSearchTypeWithReset,
  setSearchType,
  setIsSkippingMapFocus,
  setIsDisclaimerModalActive,
  setIsMapSearchEnabled,
  resetGpsPoints,
  setIsMapSearchLoading,
  setMapSearchSscCodes,
} = search.actions;

export default search.reducer;
