import React, { TextareaHTMLAttributes } from 'react';

import ErrorField from '../error_field/ErrorField';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  error?: string;
  wrapperClassName?: string;
};

const TextArea = (props: Props) => {
  return (
    <div className={`c-input-wrapper ${props.wrapperClassName}`}>
      <div className="slide-up-label">
        <textarea id={props.name} placeholder=" " {...props} />
        <label htmlFor={props.name}>{props.label}</label>
      </div>
      {props.error && <ErrorField error={props.error} />}
    </div>
  );
};

export default TextArea;
