import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Input from '../../../../foundation/components/input/Input';
import Select from '../../../../foundation/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import { updateOrganization } from '../../redux/async_thunks';
import { selectAllAppRoles, selectOrganization } from '../../redux/selectors';
import { MemberEditType } from '../../types';

type EditMemberProps = {
  handleCancel: () => void;
  initialData: MemberEditType | undefined;
};
const EditMember = ({ handleCancel, initialData }: EditMemberProps) => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const [userRole, setUserRole] = useState(0);

  const allAppRoles = useAppSelector(selectAllAppRoles);

  const userAppRoles = allAppRoles
    ? allAppRoles.filter((role) => {
        return role.value !== 0;
      })
    : [];

  const org = useAppSelector(selectOrganization);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  useEffect(() => {
    if (initialData?.roleId) {
      setUserRole(initialData?.roleId);
    }
  }, [initialData]);

  const handleRoleChange = (v: number) => {
    setUserRole(v);
  };

  const handleEditModalCancel = () => {
    if (isEditing) {
      return;
    }
    handleCancel();
  };

  const handleMemberEditSubmitBtn = async () => {
    if (isEditing) {
      return;
    }

    try {
      if (org?.orgId && user.userId) {
        setIsEditing(true);

        const data = {
          orgId: org.orgId,
          userId: user.userId,
          membersRoles: [
            {
              email: initialData?.email,
              newRoleId: userRole,
            },
          ],
        };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        await dispatch(
          updateOrganization({ data, options, picture: undefined }),
        ).unwrap();

        setIsEditing(false);

        notification.success({
          message: 'Success!',
          description: 'Member updated successfully.',
        });

        handleEditModalCancel();
      }
    } catch (error) {
      setIsEditing(false);
    }
  };

  const modalProps = {
    wrapClassName: 'l-onboarding  l-edit-member',
    closable: true,
    footer: null as any,
    maskClosable: false,
    // centered: true,
    open: true,
    title: '',
    width: 650,
    onCancel: handleEditModalCancel,
  };

  return (
    <Modal {...modalProps}>
      {isEditing && <FullPageLoader />}
      <div className="l-onboarding__section">
        <div className="l-onboarding__section-title">Update user</div>
        <div className="l-onboarding__section-info">
          Update user permissions. Granting admin rights will enable access to
          team/company settings such as payment methods etc.
        </div>

        <div className="l-team-setup__field-wrapper">
          <Input
            label="Enter email address"
            disabled
            value={initialData?.email}
          />
        </div>
        <div className="l-team-setup__field-wrapper">
          <div className="l-team-setup__field-label">Team Pemissions</div>
          <Select
            options={userAppRoles}
            className="l-team-setup__select"
            onChange={handleRoleChange}
            value={userRole}
          />
        </div>

        <div className="l-edit-member__btns-wrapper">
          <Button
            className="l-onboarding__save-btn"
            style={{ width: 132, marginRight: 20 }}
            disabled={isEditing}
            onClick={handleEditModalCancel}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            className="l-onboarding__save-btn"
            style={{ width: 132 }}
            disabled={isEditing || userRole === initialData?.roleId}
            onClick={handleMemberEditSubmitBtn}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditMember;
