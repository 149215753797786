import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Logo from '../../../foundation/assets/svgs/Logo';
import Map from '../../../foundation/assets/svgs/Map';
import FloatLabel from '../../../foundation/components/float_label/FloatLabel';
import type { AppDispatch } from '../../../store';
import { getClientIP, postForgotPassword } from '../redux/async_thunks';

const ForgotPassword = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { loginWithRedirect } = useAuth0();
  const loadingIcon = <Loading3QuartersOutlined spin />;
  const loadingTip = 'Loading...';

  const [formErrorMessage, setFormErrorMessage] = useState<string>('');

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const [isRequestSuccessful, setisRequestSuccessful] =
    useState<boolean>(false);

  const handleLoginClick = (e: any) => {
    e.preventDefault();
    loginWithRedirect();
  };

  const [form] = Form.useForm();
  const email = Form.useWatch('email', form);

  const errorHandler = () => {
    setFormErrorMessage('Something went wrong with the request');
  };

  const proceedPasswordReset = async (values: any) => {
    let timeStamp = 0;
    let clientIp = '';
    let isRequestFailed = false;

    setIsFormLoading(true);
    setFormErrorMessage('');

    try {
      const response = await dispatch(getClientIP())
        // @ts-ignore
        .unwrap();

      timeStamp = response.timeStamp;
      clientIp = response.ip;
    } catch (error: any) {
      errorHandler();
      isRequestFailed = true;
    }

    if (isRequestFailed) {
      setIsFormLoading(false);
      return false;
    }

    try {
      await dispatch(
        postForgotPassword({
          data: {
            email: values.email,
          },
          options: {
            clientIp: clientIp,
            timeStamp: timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      setisRequestSuccessful(true);
      setIsFormLoading(false);
    } catch (error: any) {
      if (error.message && error.message.includes('400')) {
        setFormErrorMessage('Email address is already in use');
      } else {
        errorHandler();
      }
    }

    setIsFormLoading(false);
  };

  const renderLoginLink = () => {
    return (
      <div className="l-common-form__member">
        <p>
          Ready to login?{' '}
          <a href="#" onClick={handleLoginClick}>
            Login Here
          </a>
        </p>
      </div>
    );
  };

  return (
    <main className="l-common-form">
      <div className="l-common-form__body">
        <div className="l-common-form__fields">
          <div className="l-common-form__heading">
            <Logo />
            <h3>Forgot your password?</h3>
          </div>
          {isRequestSuccessful && (
            <div>
              <>
                <p className="l-common-form__alert l-common-form__alert--success">
                  Request sent!
                </p>
                <p style={{ textAlign: 'center' }}>
                  We&apos;ve sent an email to{' '}
                  <strong>{form.getFieldValue('email')}</strong> that contains
                  the next steps to reset your password
                </p>
                <br />
                <hr style={{ opacity: '0.3' }} />
                <br />
                {renderLoginLink()}
              </>
            </div>
          )}
          {!isRequestSuccessful && (
            <>
              <Spin
                tip={loadingTip}
                spinning={isFormLoading}
                indicator={loadingIcon}
              >
                {formErrorMessage && (
                  <p className="l-common-form__alert l-common-form__alert--error">
                    {formErrorMessage}
                  </p>
                )}
                <Form onFinish={proceedPasswordReset} form={form}>
                  <FloatLabel label="Email" value={email}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email is required',
                        },
                        {
                          type: 'email',
                          message: 'Email is invalid',
                        },
                      ]}
                    >
                      <Input className="l-common-form__input" />
                    </Form.Item>
                  </FloatLabel>
                  <Form.Item>
                    <Button
                      className="l-common-form__button"
                      type="primary"
                      htmlType="submit"
                    >
                      Request reset link
                    </Button>
                  </Form.Item>
                </Form>
                {renderLoginLink()}
              </Spin>
            </>
          )}
        </div>
      </div>
      <div className="l-common-form__hero">
        <Map />
      </div>
    </main>
  );
};

export default ForgotPassword;
