import type { TabsProps } from 'antd';
import { Divider, Image, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useIsLightPlanId } from '../../foundation/custom_hooks/useIsLightPlanId';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectClientIp, selectUser } from '../user/redux/selectors';
import Members from './members/Members';
import { getOrganizationDetails } from './redux/async_thunks';
import {
  selectAccountClients,
  selectAccountCurrentPlan,
  selectAccountMembers,
  selectOrganization,
} from './redux/selectors';
import Settings from './settings/Settings';

const Account = () => {
  const dispatch = useAppDispatch();

  const [isAccountLoading, setAccountLoading] = useState(true);
  const isLightPlan = useIsLightPlanId();

  const user = useAppSelector(selectUser);
  const clientIp = useAppSelector(selectClientIp);
  const organization = useAppSelector(selectOrganization);
  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  const currentMembers = useAppSelector(selectAccountMembers);
  const currentClients = useAppSelector(selectAccountClients);

  const fetchData = async () => {
    try {
      if (user) {
        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        await dispatch(getOrganizationDetails({ options })).unwrap();
        setAccountLoading(false);
      }
    } catch (error) {
      setAccountLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const items: TabsProps['items'] = [
    {
      key: 'members',
      label: `Members`,
      children: <Members currentMembers={currentMembers} />,
    },
    {
      key: 'clients',
      label: `Clients`,
      children: <Members currentMembers={currentClients} isClients />,
    },
    {
      key: 'settings',
      label: `Settings`,
      children: <Settings />,
    },
  ];
  return (
    <div className="l-account">
      {isAccountLoading && <FullPageLoader />}
      {!isLightPlan && (
        <div className="l-account__header">
          <div className="l-account__header-con">
            <Image
              className="l-account__con-img-wrapper"
              width={100}
              src={organization?.picture}
              preview={false}
            />
          </div>
          <div className="l-account__header-con">
            <div className="l-account__company-name">
              {organization?.orgName}
            </div>
            <div className="l-account__company-label">Company</div>
          </div>
          {user?.userRole === 9 && (
            <div className="l-account__header-con l-account__subs-label">
              {currentPlan?.subscriptionPlan} Subscription
            </div>
          )}
        </div>
      )}
      {isLightPlan && (
        <div className="l-account__header">
          <div className="l-account__header-con">
            <div className="l-account__acc-settings">Account Settings</div>
          </div>
          {user?.userRole === 9 && (
            <div className="l-account__header-con l-account__subs-label">
              Light Plan Subscription
            </div>
          )}
        </div>
      )}
      <Divider />
      {!isLightPlan && (
        <div className="l-account__content-wrapper">
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      )}

      {isLightPlan && <Settings />}
    </div>
  );
};

export default Account;
