import React from 'react';

function Strikethrough() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      fill="none"
      viewBox="0 0 9 14"
    >
      <path
        fill="#AFAFAF"
        d="M7.253.81v4.122h-.325c-.106-.791-.296-1.421-.572-1.89a2.918 2.918 0 00-1.16-1.116 3.221 3.221 0 00-1.564-.413c-.61 0-1.113.187-1.512.562-.398.37-.598.791-.598 1.266 0 .363.126.694.378.993.364.44 1.228 1.025 2.593 1.758 1.113.597 1.872 1.057 2.277 1.38.41.316.723.691.94 1.125.223.433.334.887.334 1.362 0 .902-.352 1.682-1.055 2.338-.697.65-1.596.976-2.698.976-.346 0-.67-.027-.976-.08-.181-.029-.56-.134-1.133-.316-.569-.188-.93-.281-1.081-.281a.475.475 0 00-.352.131c-.082.088-.144.27-.185.546H.24V9.186h.325c.153.855.358 1.497.616 1.924.258.422.65.774 1.177 1.055a3.69 3.69 0 001.75.422c.732 0 1.309-.193 1.73-.58.429-.387.642-.844.642-1.371 0-.293-.082-.59-.246-.888-.158-.299-.407-.577-.747-.835-.228-.176-.852-.548-1.872-1.116-1.02-.574-1.746-1.031-2.18-1.371A3.709 3.709 0 01.46 5.3a2.79 2.79 0 01-.334-1.354c0-.855.328-1.59.984-2.206.657-.62 1.492-.931 2.505-.931.633 0 1.304.155 2.013.465.328.147.56.22.694.22a.518.518 0 00.37-.132c.099-.093.178-.278.237-.553h.325z"
      ></path>
      <path fill="#AFAFAF" d="M-1 8.342H9.01v.896H-1v-.896z"></path>
    </svg>
  );
}

export default Strikethrough;
