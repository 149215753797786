import 'mapbox-gl/dist/mapbox-gl.css';

import { Loading3QuartersOutlined } from '@ant-design/icons';
import bbox from '@turf/bbox';
import { groupBy, map as pluck, maxBy, minBy } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSize } from 'react-hook-size';
import ReactMapGL, {
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from 'react-map-gl';
// @ts-ignore
import linestring from 'turf-linestring';
import WebMercatorViewport from 'viewport-mercator-project';

import Bath from '../../../../foundation/assets/svgs/Bath';
import Bed from '../../../../foundation/assets/svgs/Bed';
import Car from '../../../../foundation/assets/svgs/Car';
import CaretLeft from '../../../../foundation/assets/svgs/CaretLeft';
import CaretRight from '../../../../foundation/assets/svgs/CaretRight';
import Earth from '../../../../foundation/assets/svgs/Earth';
import FavoritePropertyMarker from '../../../../foundation/assets/svgs/FavoritePropertyMarker';
import FavoriteSuburbMarker from '../../../../foundation/assets/svgs/FavoriteSuburbMarker';
import Filter from '../../../../foundation/assets/svgs/Filter';
import FullScreen from '../../../../foundation/assets/svgs/FullScreen';
import MarkerLight from '../../../../foundation/assets/svgs/MarkerLight';
import Minimize from '../../../../foundation/assets/svgs/Minimize';
import PropertyMarker from '../../../../foundation/assets/svgs/PropertyMarker';
import SuburbMarker from '../../../../foundation/assets/svgs/SuburbMarker';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import useMapSearch from '../../../../foundation/custom_hooks/useMapSearch';
import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import envConstants from '../../../../internals/env/env_constants.json';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import AccessControl from '../../../user/access_control/AccessControl';
import {
  selectActivePropertyId,
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectGpsPoints,
  selectIsHeatmapActive,
  selectIsMapSearchEnabled,
  selectIsMapSearchLoading,
  selectIsSkippingMapFocus,
  selectListContainerSize,
  selectMapSearchPropertyType,
  selectMapSummary,
  selectResults,
  selectSuburbMetrics,
} from '../../redux/selectors';
import {
  resetActiveProperty,
  resetActiveSuburb,
  resetGpsPoints,
  resetSuburbMetrics,
  setActivePropertyId,
  setActiveSuburbSscCode,
  setIsFilterModalActive,
  setIsHeatMapActive,
  setIsMapSearchEnabled,
  setIsSkippingMapFocus,
  setListContainerSize,
  setMapSearchSscCodes,
} from '../../redux/slice';
import DrawControl from './controls/DrawControl';
import { mapStyleOptions } from './map_style_picker/map_style_options';
import MapStylePicker from './map_style_picker/MapStylePicker';
import MetricsForm from './metrics_form/MetricsForm';
import {
  suburbHighlightedStyle,
  suburbLayerStyle,
  suburbSelectedStyle,
} from './utils/map_style_layers';

const Map = ({
  isLargeScreen = true,
  setIsMobileMapActive,
}: {
  isLargeScreen?: boolean;
  setIsMobileMapActive?: any;
}) => {
  const MAPBOX_ACCESS_TOKEN = envConstants.MAPBOX_ACCESS_TOKEN;
  const MAPBOX_VECTOR_TILE_URL = envConstants.MAPBOX_VECTOR_TILE_URL;
  const MAPBOX_SOURCE_LAYER = envConstants.MAPBOX_SOURCE_LAYER;
  const GEOJSON_API_BASE = envConstants.SUBURB_GEOJSON_DATA_API_BASE_URL;
  const LAYOUT_MODE_OPTIONS = ['hidden', 'compact', 'default', 'wide'];

  const fetchMapSearchResults = useMapSearch();

  const [isMobileView] = useBreakpoint();

  const dispatch = useAppDispatch();

  const searchType = useSearchType();
  const searchTypeAttribute =
    searchType === 'suburb' ? 'suburbs' : 'properties';

  const SSC_CODE = useAppSelector(selectActiveSuburbSscCode);
  const SUBURB = useAppSelector(selectActiveSuburb);
  const PROPERTY_ID = useAppSelector(selectActivePropertyId);

  const suburbMetrics = useAppSelector(selectSuburbMetrics);
  const isHeatmapActive = useAppSelector(selectIsHeatmapActive);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);
  const isMapSearchLoading = useAppSelector(selectIsMapSearchLoading);

  const mapSummary = useAppSelector(selectMapSummary);
  const gpsPoints = useAppSelector(selectGpsPoints);

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);

  const mapRef = useRef<any>();
  const mapContainerRef = useRef<any>(null);

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [selectedSuburbInfo, setSelectedSuburbInfo] = useState<any>(null); // For mobile
  const [isSuburbFocusSkipped, setIsSuburbFocusSkipped] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const [selectedAreaFeatures, setSelectedAreaFeatures] = useState<any>();
  const [isPolygonToolEnabled, setIsPolygonToolEnabled] = useState(false);
  const [isDeleteAllPolygon, setIsDeleteAllPolygon] = useState(false);

  const [hasPolygonUpdate, setHasPolygonUpdate] = useState(false);

  const { width, height } = useSize(mapContainerRef);

  const [mapStyle, setMapStyle] = useState<any>(mapStyleOptions[0]);
  const [isSuburbBordersDisabled, setIsSuburbBordersDisabled] =
    useState<boolean>(false);

  const DEFAULT_VIEWPORT = {
    initialViewState: {
      latitude: -28.274398,
      longitude: 133.775136,
      zoom: 3.8,
    },
  };

  const DEFAULT_MAP_SETTINGS = {
    attributionControl: false,
    mapStyle: mapStyle.path,
    mapboxAccessToken: MAPBOX_ACCESS_TOKEN,
  };

  const [viewport, setViewport] = useState<any>(DEFAULT_VIEWPORT);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [detailedPopupInfo, setDetailedPopupInfo] = useState<any>(null);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const isSkippingMapFocus = useAppSelector(selectIsSkippingMapFocus);

  const results = useAppSelector(selectResults);

  const listContainerSize = useAppSelector(selectListContainerSize);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const getMinOrMax = (
    markers: any,
    minOrMax: 'max' | 'min',
    latOrLng: 'latitude' | 'longitude',
  ) => {
    if (minOrMax === 'max') {
      return (maxBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
    } else {
      return (minBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
    }
  };

  const getBounds = (markers: any) => {
    const maxLat = getMinOrMax(markers, 'max', 'latitude');
    const minLat = getMinOrMax(markers, 'min', 'latitude');
    const maxLng = getMinOrMax(markers, 'max', 'longitude');
    const minLng = getMinOrMax(markers, 'min', 'longitude');

    const southWest = [minLng, minLat];
    const northEast = [maxLng, maxLat];

    return [southWest, northEast];
  };

  const computeBorderOpacity = () => {
    if (isSuburbBordersDisabled) {
      return 0;
    }

    if (!mapRef || !mapRef.current) {
      return 0.4;
    }

    const zoomLevel = mapRef.current.getZoom();

    // Suburb border outline color darkens as zoom level gets deeper
    switch (true) {
      case zoomLevel < 5:
        return 0.1;
      case zoomLevel < 7:
        return 0.4;
      case zoomLevel < 9:
        return 0.7;
      case zoomLevel < 11:
        return 0.99;
      default:
        return 1;
    }
  };

  const computeViewport = () => {
    if (width && height && results && (results.suburbs || results.properties)) {
      const currentList =
        results.suburbs && results.suburbs.length ? 'suburbs' : 'properties';
      const MARKERS_BOUNDS: any = getBounds(results[currentList]);
      const largerMapPadding = width < 800 ? 15 : 150;
      setViewport((viewport: any) => {
        const NEXT_VIEWPORT = new WebMercatorViewport({
          ...viewport,
          width,
          height,
        }).fitBounds(MARKERS_BOUNDS, {
          padding: isMobileView ? 10 : largerMapPadding,
        });
        return NEXT_VIEWPORT;
      });
    } else if (
      !results ||
      !results[searchTypeAttribute] ||
      !results[searchTypeAttribute].length
    ) {
      setViewport(DEFAULT_VIEWPORT.initialViewState);
    }
  };

  const focusActiveSuburb = async (sscCode?: number) => {
    // Skip if active suburb ssc code change is triggered on map click
    if (isSuburbFocusSkipped) {
      setIsSuburbFocusSkipped(false);
      return false;
    }

    await fetch(`${GEOJSON_API_BASE}${sscCode || SSC_CODE}.json`)
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        const feature = json.features[0];

        const [minLng, minLat, maxLng, maxLat] = bbox(feature);

        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: isMobileView ? 10 : 100,
            duration: isMobileView || sscCode ? 0 : 1000,
          },
        );

        if (!sscCode) {
          setSelectedSuburbInfo({
            sscCode: feature.properties.SAL_CODE21,
          });
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const getUniqueSuburbSSCCodesForSelectedArea = useCallback(() => {
    if (selectedAreaFeatures) {
      const unique: any[] = [];
      const distinct: any[] = [];

      const selectArea: any = Object.values(selectedAreaFeatures).pop();

      const coordinates = selectArea.geometry.coordinates;
      const mapPoints: any[] = [];

      for (const latLng of coordinates[0]) {
        const { x, y } = mapRef.current.getMap().project(latLng);
        mapPoints.push([x, y]);
      }

      const bboxed = bbox(linestring(mapPoints, {}));

      const featureArray = mapRef.current.queryRenderedFeatures(
        [
          [bboxed[0], bboxed[1]],
          [bboxed[2], bboxed[3]],
        ],
        {
          layers: ['suburb-layer'],
        },
      );

      for (const feature of featureArray) {
        if (!unique[feature.properties.SAL_CODE21]) {
          distinct.push(feature.properties.SAL_CODE21);
          unique[feature.properties.SAL_CODE21] = 1;
        }
      }

      return distinct;
    }
  }, [selectedAreaFeatures]);

  // const getBeforeId = () => {
  //   return mapStyle.layerStyle !== 'custom' ? '' : 'aeroway-polygon';
  // };

  const markerHoverHandler = (info: any) => {
    setPopupInfo(info);
  };

  const markerBlurHandler = () => {
    setPopupInfo(null);
  };

  const markerClickHandler = (info: any) => {
    setDetailedPopupInfo(info);
  };

  const selectToolClickHandler = () => {
    setIsPolygonToolEnabled(!isPolygonToolEnabled);
  };

  const resetMapClickHandler = () => {
    setIsPolygonToolEnabled(false);
    dispatch(resetSuburbMetrics());
  };

  const closeHeatMapClickHandler = () => {
    dispatch(setIsHeatMapActive(false));
  };

  const featureHoverHandler = useCallback(
    (event: any) => {
      if (isPolygonToolEnabled) {
        return false;
      }

      const { features } = event;
      const hoveredFeature = features && features[0];

      if (hoveredFeature) {
        const suburbName = hoveredFeature.properties.SUBURB_NAME
          ? hoveredFeature.properties.SUBURB_NAME
          : hoveredFeature.properties.SAL_NAME21;

        const sscCode = hoveredFeature.properties.SAL_CODE21;
        const metrics =
          suburbMetrics && suburbMetrics.length
            ? suburbMetrics.filter((metric) => {
                return metric.sscCode.toString() === sscCode;
              })[0]
            : null;

        setHoverInfo({
          suburbName,
          sscCode: sscCode,
          longitude: event.lngLat.lng,
          latitude: event.lngLat.lat,
          state: metrics ? metrics.state : null,
          postCode: metrics ? metrics.postCode : null,
          metricValue: metrics ? metrics.metricValue : null,
          suburbHexColor: metrics ? metrics.suburbHexColor : null,
        });
      }
    },
    [isPolygonToolEnabled],
  );

  const featureClickHandler = useCallback(
    (event: any) => {
      if (isPolygonToolEnabled || isMapSearchEnabled) {
        return false;
      }

      const { features } = event;
      const clickedFeature = features && features[0];

      setIsSuburbFocusSkipped(true);

      if (clickedFeature) {
        const suburbName = clickedFeature.properties.SUBURB_NAME
          ? clickedFeature.properties.SUBURB_NAME
          : clickedFeature.properties.SAL_NAME21;

        const sscCode = clickedFeature.properties.SAL_CODE21;

        setSelectedSuburbInfo({
          sscCode: sscCode,
          // The properties below are intended for the suburb on hover popup
          suburbName,
          longitude: event.lngLat[0],
          latitude: event.lngLat[1],
        });

        dispatch(setActiveSuburbSscCode(sscCode));
      }
    },
    [isPolygonToolEnabled],
  );

  const hoveredSuburb = (hoverInfo && hoverInfo.sscCode) || '';

  const highlightLayerFilter = useMemo(() => {
    return ['in', 'SAL_CODE21', hoveredSuburb];
  }, [hoveredSuburb]);

  const selectedSuburbFilter = useMemo(() => {
    return [
      'in',
      'SAL_CODE21',
      (selectedSuburbInfo && selectedSuburbInfo.sscCode.toString()) || '',
    ];
  }, [selectedSuburbInfo]);

  const onUpdate = useCallback((e: any) => {
    setHasPolygonUpdate(true);
    setSelectedAreaFeatures((currFeatures: any) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }

      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e: any) => {
    setSelectedAreaFeatures((currFeatures: any) => {
      const newFeatures: any = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }

      return newFeatures;
    });
  }, []);

  const setMapFocus = () => {
    if (
      isMapLoaded &&
      results &&
      results.suburbs &&
      results.suburbs.length === 1
    ) {
      // Use suburb focus logic if result count is 1
      focusActiveSuburb(results.suburbs[0].sscCode);
    } else {
      computeViewport();
    }
  };

  const mapSearch = () => {
    if (isMapSearchEnabled && !isHeatmapActive && !isMapSearchLoading) {
      const unique: any[] = [];
      const distinct: any[] = [];
      let visibleFeatures: any[] = [];

      if (mapRef.current) {
        visibleFeatures = mapRef.current.queryRenderedFeatures(null, {
          layers: ['suburb-layer'],
        });

        for (const feature of visibleFeatures) {
          if (!unique[feature.properties.SAL_CODE21]) {
            distinct.push(parseInt(feature.properties.SAL_CODE21, 10));
            unique[feature.properties.SAL_CODE21] = 1;
          }
        }
      }

      dispatch(setMapSearchSscCodes(distinct));
      fetchMapSearchResults({ sscCodes: distinct });
    }
  };

  const renderSuburbMetrics = () => {
    if (!!suburbMetrics && !!suburbMetrics.length) {
      const jsx: any[] = [];
      const grouped = groupBy(suburbMetrics, 'suburbHexColor');

      Object.keys(grouped).forEach((key: string) => {
        const items = grouped[key];

        jsx.push(
          <Layer
            {...{
              // beforeId: getBeforeId(),
              beforeId: 'suburb-selected-outline',
              id: `suburb-metric-${key}`,
              'source-layer': MAPBOX_SOURCE_LAYER,
              type: 'fill',
              paint: {
                'fill-color': key,
                'fill-opacity': 1,
              },
              filter: [
                'in',
                'SAL_CODE21',
                ...pluck(items, 'sscCode').map((a) => {
                  return a.toString();
                }),
              ],
            }}
          />,
        );
      });

      return jsx;
    } else {
      return <></>;
    }
  };

  const renderSuburbHeading = () => {
    if (isHeatmapActive || !isMobileView || !SUBURB || !selectedSuburbInfo) {
      return <></>;
    }

    const suburb =
      selectedSuburbInfo && selectedSuburbInfo.suburbName
        ? selectedSuburbInfo
        : SUBURB;

    return (
      <>
        {selectedSuburbInfo && SUBURB && (
          <div className="l-map__suburb-name">
            <strong>{suburb.suburbName}</strong>
            {suburb.state && suburb.postCode && (
              <span style={{ whiteSpace: 'nowrap' }}>
                {', '}
                {suburb.state} {suburb.postCode}
              </span>
            )}
            <button
              onClick={() => {
                setIsMobileMapActive(false);
              }}
            >
              View Suburb Data
            </button>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }

    if (isFirstLoad && width && height) {
      // Prevent computing for viewport on container/window resize
      computeViewport();
      // Only on first load
      setIsFirstLoad(false);
    }
  }, [width, height]);

  useEffect(() => {
    if (!isSkippingMapFocus) {
      setPopupInfo(null);
      setSelectedSuburbInfo(null);
      setDetailedPopupInfo(null);
      setMapFocus();
    }

    dispatch(setIsSkippingMapFocus(false));
  }, [results, isMapLoaded]);

  useEffect(() => {
    if (SSC_CODE && !isMapSearchEnabled) {
      if (isMapLoaded && (!results || !results.properties)) {
        focusActiveSuburb();
      }
    } else {
      setPopupInfo(null);
      setDetailedPopupInfo(null);
      setSelectedSuburbInfo(null);

      if (!isMapSearchEnabled) {
        setMapFocus();
      }
    }
  }, [SSC_CODE, isMapLoaded]);

  // useEffect(() => {
  //   setIsPolygonToolEnabled(false);
  // }, [suburbMetrics]);

  useEffect(() => {
    if (isPolygonToolEnabled) {
      setHoverInfo(null);
    }
  }, [isPolygonToolEnabled]);

  useEffect(() => {
    if (isMobileView && isHeatmapActive && !suburbMetrics) {
      setIsPolygonToolEnabled(true);
    }

    if (!isHeatmapActive) {
      setIsPolygonToolEnabled(false);
      setHasPolygonUpdate(false);
    }
  }, [isHeatmapActive]);

  useEffect(() => {
    setDetailedPopupInfo(null);

    const timeoutId = setTimeout(() => {
      // Attempt to let the map element transition finish first
      mapSearch();
    }, 500);

    dispatch(resetActiveProperty());
    dispatch(resetActiveSuburb());

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isMapSearchEnabled]);

  useEffect(() => {
    setDetailedPopupInfo(null);
  }, [gpsPoints]);

  const mapClassNames = !isLargeScreen ? 'l-map l-map--tablet-mobile' : 'l-map';

  return (
    <div
      className={`${mapClassNames}${isMobileView ? ' l-map--mobile' : ''}`}
      ref={mapContainerRef}
    >
      {!isMobileView && !isMapSearchEnabled && (
        <div className="l-map__button-group">
          <button
            className="l-map__button"
            disabled={listContainerSize === 'hidden'}
            onClick={() => {
              dispatch(
                setListContainerSize(
                  LAYOUT_MODE_OPTIONS[
                    LAYOUT_MODE_OPTIONS.indexOf(listContainerSize) - 1
                  ],
                ),
              );
            }}
          >
            <CaretLeft />
          </button>
          <button
            className="l-map__button"
            disabled={listContainerSize === 'wide'}
            onClick={() => {
              dispatch(
                setListContainerSize(
                  LAYOUT_MODE_OPTIONS[
                    LAYOUT_MODE_OPTIONS.indexOf(listContainerSize) + 1
                  ],
                ),
              );
            }}
          >
            <CaretRight />
          </button>
        </div>
      )}
      {isLargeScreen && (
        <div className="l-map__heat-map-button-group">
          {!isHeatmapActive && (
            <>
              <AccessControl requiredFeature="heatmap">
                <button
                  className="l-map__heat-map-button l-map__heat-map-button--large"
                  onClick={() => {
                    dispatch(setIsHeatMapActive(true));
                  }}
                  type="button"
                >
                  <span>Create Heatmap</span>
                </button>
              </AccessControl>
              <button
                className="l-map__search-button"
                type="button"
                onClick={() => {
                  if (isMapSearchEnabled) {
                    dispatch(resetGpsPoints());
                    dispatch(resetActiveProperty());
                    dispatch(resetActiveSuburb());
                    setMapFocus();
                  }

                  dispatch(setIsMapSearchEnabled(!isMapSearchEnabled));
                }}
              >
                {isMapSearchEnabled ? (
                  <span>List Search</span>
                ) : (
                  <span>Map Search</span>
                )}
              </button>
            </>
          )}
          <div>
            {isMapSearchEnabled && (
              <button
                className="l-map__heat-map-button"
                onClick={() => {
                  dispatch(setIsFilterModalActive(true));
                }}
                type="button"
              >
                <Filter color={'#23005C'} />
              </button>
            )}
            {!isMapSearchEnabled && (
              <>
                {listContainerSize === 'hidden' && (
                  <button
                    className="l-map__heat-map-button"
                    onClick={() => {
                      dispatch(setListContainerSize('default'));
                    }}
                    type="button"
                  >
                    <Minimize />
                  </button>
                )}
                {listContainerSize !== 'hidden' && (
                  <button
                    className="l-map__heat-map-button"
                    onClick={() => {
                      dispatch(setListContainerSize('hidden'));
                    }}
                    type="button"
                  >
                    <FullScreen />
                  </button>
                )}
              </>
            )}
            <button
              style={
                isSuburbBordersDisabled
                  ? { marginTop: '5px', backgroundColor: '#651fff' }
                  : { marginTop: '5px' }
              }
              className="l-map__heat-map-button"
              onClick={() => {
                setIsSuburbBordersDisabled(!isSuburbBordersDisabled);
              }}
              type="button"
            >
              <Earth color={!isSuburbBordersDisabled ? '#23005C' : '#FFFFFF'} />
            </button>
            <MapStylePicker
              onMapStyleChange={(style: any) => {
                setMapStyle(style);
              }}
            />
          </div>
        </div>
      )}
      {!isLargeScreen && (
        <div className="l-map__heat-map-button-group">
          <div>
            <MapStylePicker
              onMapStyleChange={(style: any) => {
                setMapStyle(style);
              }}
            />
          </div>
        </div>
      )}
      {isHeatmapActive && (
        <AccessControl requiredFeature="heatmap">
          <MetricsForm
            getUniqueSuburbSSCCodesForSelectedArea={
              getUniqueSuburbSSCCodesForSelectedArea
            }
            setIsPolygonToolEnabled={setIsPolygonToolEnabled}
            isPolygonToolEnabled={isPolygonToolEnabled}
            selectToolClickHandler={selectToolClickHandler}
            resetMapClickHandler={resetMapClickHandler}
            closeHeatMapClickHandler={closeHeatMapClickHandler}
            hasPolygonUpdate={hasPolygonUpdate}
            setHasPolygonUpdate={setHasPolygonUpdate}
            setIsDeleteAllPolygon={setIsDeleteAllPolygon}
          />
        </AccessControl>
      )}
      {isMapSearchEnabled && (isMapSearchLoading || mapSummary) && (
        <div className="l-map__map-search-text">
          {isMapSearchLoading ? (
            <>
              <Loading3QuartersOutlined spin />
              <span>Searching...</span>
            </>
          ) : (
            mapSummary
          )}
        </div>
      )}
      <ReactMapGL
        {...DEFAULT_MAP_SETTINGS}
        {...DEFAULT_VIEWPORT}
        {...viewport}
        ref={mapRef}
        interactiveLayerIds={['suburb-layer']}
        pitchWithRotate={false}
        onMove={(e) => {
          setViewport(e.viewState);
        }}
        onClick={featureClickHandler}
        onTouchEnd={featureClickHandler}
        onMouseMove={featureHoverHandler}
        fitBoundsOptions={{
          animate: true,
          duration: isMobileView ? 0 : 1000,
        }}
        onLoad={() => {
          setIsMapLoaded(true);
        }}
        onDragEnd={() => {
          if (isMapSearchEnabled) {
            mapSearch();
          }
        }}
        onZoomEnd={() => {
          if (isMapSearchEnabled) {
            mapSearch();
          }
        }}
      >
        {!isMobileView && (
          <NavigationControl position="bottom-right" showCompass={false} />
        )}
        <DrawControl
          displayControlsDefault={false}
          defaultMode="draw_polygon"
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          isPolygonToolEnabled={isPolygonToolEnabled}
          isDeleteAllPolygon={isDeleteAllPolygon}
          setIsDeleteAllPolygon={setIsDeleteAllPolygon}
        />
        <Source id="the-source" type="vector" url={MAPBOX_VECTOR_TILE_URL}>
          {!isSuburbBordersDisabled && (
            <Layer
              {...{
                id: 'suburb-selected-outline',
                type: 'line',
                'source-layer': MAPBOX_SOURCE_LAYER,
              }}
              {...suburbSelectedStyle[mapStyle.layerStyle]}
              filter={selectedSuburbFilter}
            />
          )}
          <Layer
            {...{
              id: 'suburb-layer-outline',
              'source-layer': MAPBOX_SOURCE_LAYER,
              type: 'line',
              // beforeId: getBeforeId(),
              paint: {
                ...suburbLayerStyle[mapStyle.layerStyle].paint,
                'line-opacity': computeBorderOpacity(),
                'line-width': computeBorderOpacity() === 1 ? 2 : 1,
              },
            }}
          />
          {renderSuburbMetrics()}
          <Layer
            {...{
              id: 'suburb-layer',
              'source-layer': MAPBOX_SOURCE_LAYER,
              type: 'fill',
              paint: {
                'fill-color': '#fff',
                'fill-opacity': 0,
              },
            }}
          />
          {!isMobileView && !isSuburbBordersDisabled && (
            <Layer
              {...{
                id: 'suburb-highlighted-outline',
                type: 'line',
                'source-layer': MAPBOX_SOURCE_LAYER,
              }}
              {...suburbHighlightedStyle[mapStyle.layerStyle]}
              filter={highlightLayerFilter}
            />
          )}
        </Source>
        {!isHeatmapActive &&
          !isMapSearchEnabled &&
          !!results &&
          !!results.suburbs &&
          !!results.suburbs.length && (
            <>
              {results.suburbs.map((suburb: any, index: number) => {
                return (
                  <Marker
                    key={`suburb-${index}`}
                    longitude={suburb.longitude}
                    latitude={suburb.latitude}
                    anchor="top"
                    onClick={(e: any) => {
                      e.originalEvent.stopPropagation();
                      dispatch(setActiveSuburbSscCode(suburb.sscCode));
                    }}
                  >
                    {/* @ts-ignore */}
                    {suburb.isFavorite ? (
                      <span
                        className="l-map__marker-favorite"
                        onMouseEnter={() => {
                          markerHoverHandler(suburb);
                        }}
                        onMouseLeave={markerBlurHandler}
                      >
                        <FavoriteSuburbMarker
                          color={
                            results && results.propertyType === 'Units'
                              ? '#00ADD2'
                              : ''
                          }
                        />
                      </span>
                    ) : (
                      <span
                        className="l-map__marker"
                        onMouseEnter={() => {
                          markerHoverHandler(suburb);
                        }}
                        onMouseLeave={markerBlurHandler}
                      >
                        <SuburbMarker
                          color={
                            results && results.propertyType === 'Units'
                              ? '#00ADD2'
                              : ''
                          }
                        />
                      </span>
                    )}
                  </Marker>
                );
              })}
            </>
          )}
        {!isHeatmapActive &&
          isMapSearchEnabled &&
          !!gpsPoints &&
          !!gpsPoints.length && (
            <>
              {gpsPoints.map((point: any, index: number) => {
                if (!point.latitude || !point.longitude) {
                  return <></>;
                }

                const color =
                  mapSearchPropertyType === 'Units' ? '#00ADD2' : '';
                const searchType =
                  point.population || point.population === ''
                    ? 'suburb'
                    : 'property';

                const isActive =
                  (SSC_CODE && SSC_CODE === point.sscCode) ||
                  (PROPERTY_ID && PROPERTY_ID === point.propertyId);

                const getActiveMarker = (colorFill: string) => {
                  return (
                    <MarkerLight
                      colorFill={colorFill || '#651fff'}
                      color={'#FFFFFF'}
                    />
                  );
                };

                const settings = {
                  suburb: {
                    onClick: (e: any) => {
                      e.originalEvent.stopPropagation();
                      // dispatch(setActiveSuburbSscCode(point.sscCode));
                      markerClickHandler(point);
                    },
                    onMouseEnter: () => {
                      markerHoverHandler(point);
                    },
                    favoriteMarker: isActive ? (
                      <>{getActiveMarker(color)}</>
                    ) : (
                      <FavoriteSuburbMarker color={color} />
                    ),
                    marker: isActive ? (
                      <>{getActiveMarker(color)}</>
                    ) : (
                      <SuburbMarker color={color} />
                    ),
                    className: 'l-map__marker',
                  },
                  property: {
                    onClick: (e: any) => {
                      e.originalEvent.stopPropagation();
                      // dispatch(setActivePropertyId(point.propertyId));
                      markerClickHandler(point);
                    },
                    // onMouseEnter: () => {
                    //   markerHoverHandler(point);
                    // },
                    favoriteMarker: isActive ? (
                      <>{getActiveMarker('#E61B00')}</>
                    ) : (
                      <FavoritePropertyMarker />
                    ),
                    marker: isActive ? (
                      <>{getActiveMarker('#E61B00')}</>
                    ) : (
                      <PropertyMarker />
                    ),
                    className: 'l-map__point',
                  },
                };

                const setting = settings[searchType];

                return (
                  <Marker
                    key={`gps-point-${index}`}
                    longitude={point.longitude}
                    latitude={point.latitude}
                    anchor="top"
                    onClick={setting.onClick}
                    // Force active marker to be on top most in view
                    style={isActive ? { zIndex: 1000000 } : {}}
                  >
                    {/* @ts-ignore */}
                    {point.isFavorite ? (
                      <span
                        className={`${setting.className}-favorite`}
                        // onMouseEnter={setting.onMouseEnter}
                        onMouseLeave={markerBlurHandler}
                      >
                        {setting.favoriteMarker}
                      </span>
                    ) : (
                      <span
                        className={`${setting.className}`}
                        // onMouseEnter={setting.onMouseEnter}
                        onMouseLeave={markerBlurHandler}
                      >
                        {setting.marker}
                      </span>
                    )}
                  </Marker>
                );
              })}
            </>
          )}
        {!isHeatmapActive &&
          !isMapSearchEnabled &&
          !!results &&
          !!results.properties &&
          !!results.properties.length && (
            <>
              {results.properties.map((property: any, index: number) => {
                return (
                  <Marker
                    key={`property-${index}`}
                    longitude={property.longitude}
                    latitude={property.latitude}
                    anchor="top"
                    onClick={(e: any) => {
                      e.originalEvent.stopPropagation();
                      dispatch(setActivePropertyId(property.propertyId));
                    }}
                  >
                    {property.isFavorite ? (
                      <span
                        className="l-map__point-favorite"
                        onMouseEnter={() => {
                          markerHoverHandler(property);
                        }}
                        onMouseLeave={markerBlurHandler}
                      >
                        <FavoritePropertyMarker />
                      </span>
                    ) : (
                      <span
                        className="l-map__point"
                        onMouseEnter={() => {
                          markerHoverHandler(property);
                        }}
                        onMouseLeave={markerBlurHandler}
                      >
                        <PropertyMarker />
                      </span>
                    )}
                  </Marker>
                );
              })}
            </>
          )}
        {!isMobileView && popupInfo && (
          <Popup
            anchor="bottom"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
            closeButton={false}
            offset={20}
            style={{ zIndex: 10000000 }}
          >
            <span className="l-map__popup">
              {searchType === 'suburb' ? (
                <span>{`${popupInfo.suburbName}, ${popupInfo.state} ${popupInfo.postCode}`}</span>
              ) : (
                <>
                  <span style={{ color: '#651fff' }}>
                    {popupInfo.askingPrice}
                  </span>
                  <span>{popupInfo.address}</span>
                </>
              )}
            </span>
          </Popup>
        )}
        {!isMobileView && hoveredSuburb && !popupInfo && (
          <Popup
            anchor="bottom"
            longitude={Number(hoverInfo.longitude)}
            latitude={Number(hoverInfo.latitude)}
            onClose={() => setHoverInfo(null)}
            closeButton={false}
            offset={20}
            style={{ zIndex: 10000000 }}
          >
            <span className="l-map__popup">
              <span>{`${hoverInfo.suburbName}${
                hoverInfo.metricValue
                  ? ', ' + hoverInfo.state + ' ' + hoverInfo.postCode
                  : ''
              }`}</span>
              {hoverInfo.metricValue && (
                <span>
                  Metric Value:{' '}
                  <span style={{ color: hoverInfo.suburbHexColor }}>
                    {hoverInfo.metricValue}
                  </span>
                </span>
              )}
            </span>
          </Popup>
        )}
        {!isMobileView && detailedPopupInfo && (
          <Popup
            anchor="bottom"
            longitude={Number(detailedPopupInfo.longitude)}
            latitude={Number(detailedPopupInfo.latitude)}
            onClose={() => setDetailedPopupInfo(null)}
            closeButton={false}
            offset={20}
            style={{ zIndex: 10000000 }}
            maxWidth={'500px'}
          >
            <span
              className={`l-map__detailed-popup${
                detailedPopupInfo.images && detailedPopupInfo.images.length
                  ? ' l-map__detailed-popup--has-image'
                  : ''
              }`}
            >
              {detailedPopupInfo.population ||
              detailedPopupInfo.population === '' ? (
                <>
                  <span>{`${detailedPopupInfo.suburbName}, ${detailedPopupInfo.state} ${detailedPopupInfo.postCode}`}</span>
                  {detailedPopupInfo.medianSalePrice && (
                    <span>
                      Median Price:
                      <strong>{detailedPopupInfo.medianSalePrice}</strong>
                    </span>
                  )}
                  {detailedPopupInfo.grossRentalYield && (
                    <span>
                      Median Gross Rental Yield:
                      <strong>{detailedPopupInfo.grossRentalYield}</strong>
                    </span>
                  )}
                  {detailedPopupInfo.population && (
                    <span>
                      Population:
                      <strong>{detailedPopupInfo.population}</strong>
                    </span>
                  )}
                  <a
                    href="#"
                    onClick={(e: any) => {
                      e.preventDefault();
                      dispatch(
                        setActiveSuburbSscCode(detailedPopupInfo.sscCode),
                      );
                      setDetailedPopupInfo(null);
                    }}
                  >
                    View Suburb Details
                  </a>
                </>
              ) : (
                <>
                  {detailedPopupInfo.images && (
                    <>
                      {detailedPopupInfo.images.length && (
                        <i className="img-cont">
                          <img
                            src={detailedPopupInfo.images[0]}
                            alt="Property"
                          />
                        </i>
                      )}
                    </>
                  )}
                  <span>Offers over {detailedPopupInfo.askingPrice}</span>
                  <span>{detailedPopupInfo.address}</span>
                  <ul className="l-property__tag-group">
                    {detailedPopupInfo.beds && (
                      <li className="l-property__tag-details">
                        <Bed />
                        {detailedPopupInfo.beds}
                      </li>
                    )}
                    {detailedPopupInfo.baths && (
                      <li className="l-property__tag-details">
                        <Bath />
                        {detailedPopupInfo.baths}
                      </li>
                    )}
                    {detailedPopupInfo.cars && (
                      <li className="l-property__tag-details">
                        <Car />
                        {detailedPopupInfo.cars}
                      </li>
                    )}
                  </ul>
                  <a
                    href="#"
                    onClick={(e: any) => {
                      e.preventDefault();
                      dispatch(
                        setActivePropertyId(detailedPopupInfo.propertyId),
                      );
                      setDetailedPopupInfo(null);
                    }}
                  >
                    View Property Details
                  </a>
                </>
              )}
            </span>
          </Popup>
        )}
      </ReactMapGL>
      {renderSuburbHeading()}
    </div>
  );
};

export default Map;
