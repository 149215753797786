import { useEffect, useState } from 'react';

import { selectSubscriptionPlanDetails } from '../../features/user/redux/selectors';
import { useAppSelector } from '../../store/hooks';

export const useIsLightPlanId = () => {
  const [isLightPlan, setIsLightPlan] = useState(false);
  const subscriptionPlanDetails = useAppSelector(selectSubscriptionPlanDetails);

  useEffect(() => {
    if (
      subscriptionPlanDetails &&
      subscriptionPlanDetails.subscriptionPlanId === 1
    ) {
      setIsLightPlan(true);
    } else {
      setIsLightPlan(false);
    }
  }, [subscriptionPlanDetails]);

  return isLightPlan;
};
