export const countryMaskedLayer = {
  id: 'country-masked-layer',
  type: 'fill',
  paint: {
    'fill-color': 'rgba(83, 143, 157, .7)',
  },
};

export const highlightLayer = {
  id: 'suburb-highlighted',
  type: 'fill',
  paint: {
    'fill-outline-color': '#484896',
    'line-color': '#fff',
    'line-opacity': 1,
    'line-width': 4,
  },
};

export const selectedLayer = {
  id: 'suburb-selected',
  type: 'fill',
  paint: {
    'line-color': 'rgba(255, 255, 255, 0.3)',
    'line-opacity': 1,
    'line-width': 2,
  },
};
