import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import { reject } from 'lodash';
import debounce from 'lodash/debounce';
import React from 'react';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({ fetchOptions, debounceTimeout = 500, ...props }: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value)
        .then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(newOptions);
          setFetching(false);
        })
        .catch((error) => {
          reject(error);
        });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select<ValueType>
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={() => {
        debounceFetcher('');
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

type Props = {
  fetchOptions: (search: string) => Promise<any[]>;
  onChange?: (value: any) => void;
  value: UserValue[];
  className?: string;
};

const DebounceAjaxSelect = ({
  fetchOptions,
  onChange,
  value,
  className,
}: Props) => {
  return (
    <DebounceSelect
      showSearch
      value={value}
      placeholder="Select users"
      fetchOptions={fetchOptions}
      onChange={onChange}
      style={{ width: '100%' }}
      className={className}
    />
  );
};

export default DebounceAjaxSelect;
