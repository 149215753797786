import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        stroke="#6E7191"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 7h18M1 1h18M1 13h18"
      ></path>
    </svg>
  );
}

export default Icon;
