import React from 'react';

import { useAppSelector } from '../../../store/hooks';
import AccessControl from '../../user/access_control/AccessControl';
import {
  selectActivePropertyId,
  selectActiveSuburbSscCode,
  selectHasActiveResultInView,
} from '../redux/selectors';
import Details from './details/Details';
import PropertyDetails from './property_details/PropertyDetails';

const View = ({
  isLargeScreen = true,
}: {
  isLargeScreen?: boolean | undefined;
}) => {
  const hasActiveResultInView = useAppSelector(selectHasActiveResultInView);
  const hasActiveSuburbSscCode = !!useAppSelector(selectActiveSuburbSscCode);
  const hasActivePropertyId = !!useAppSelector(selectActivePropertyId);

  return (
    <>
      {hasActiveResultInView && !!hasActiveSuburbSscCode && (
        <Details isLargeScreen={isLargeScreen} />
      )}
      {hasActiveResultInView && hasActivePropertyId && (
        <AccessControl requiredFeature="property-search">
          <PropertyDetails isLargeScreen={isLargeScreen} />
        </AccessControl>
      )}
    </>
  );
};

export default View;
