export const mapStyleOptions: any = [
  {
    name: 'Satellite',
    path: 'mapbox://styles/mapbox/satellite-v9',
    layerStyle: 'satellite',
    img: 'satellite_samp',
  },
  {
    name: 'Outdoors',
    path: 'mapbox://styles/mapbox/outdoors-v11',
    layerStyle: 'outdoors',
    img: 'outdoors_samp',
  },
  {
    name: 'Dark',
    path: 'mapbox://styles/mapbox/dark-v10',
    layerStyle: 'dark',
    img: 'dark_samp',
  },
];
