import { Checkbox, Form, Input, notification } from 'antd';
import { map as pluck } from 'lodash';
import React, { useState } from 'react';

import Close from '../../../../../foundation/assets/svgs/Close';
import FloatLabel from '../../../../../foundation/components/float_label/FloatLabel';
import FullPageLoader from '../../../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../../../foundation/custom_hooks/useBreakpont';
import useMetricKeyValues from '../../../../../foundation/custom_hooks/useMetricKeyValues';
import useSearchType from '../../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { resetSiderMobileContentValues } from '../../../../layout/redux/slice';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import { postFavoriteAddSearch } from '../../../redux/async_thunks';
import {
  selectEnabledSearchMetrics,
  selectIsSearchFeatureLoading,
  selectOptions,
  selectQueryValues,
  selectSearchMetricValues,
} from '../../../redux/selectors';
import {
  setIsSaveSearchModalActive,
  setIsSearchFeatureLoading,
} from '../../../redux/slice';
import { prepareSearchMetrics } from '../../../utils/functions';

const SaveSearchModal = () => {
  const searchType = useSearchType();
  const dispatch = useAppDispatch();

  const [isMobileView] = useBreakpoint();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const [form] = Form.useForm();
  const name = Form.useWatch('name', form);

  const metricKeyValues = useMetricKeyValues();

  const searchMetricValues = useAppSelector(selectSearchMetricValues);
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const options = useAppSelector(selectOptions);
  const queryValues = useAppSelector(selectQueryValues);
  const isSearchFeatureLoading = useAppSelector(selectIsSearchFeatureLoading);

  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

  const errorHandler = (error: any) => {
    console.log(error);

    if (error && error.message) {
      notification.error({
        message: 'Request failed',
        // @ts-ignore
        description: error.message,
        placement: 'topRight',
        top: 100,
        closeIcon: (
          <strong className="l-save-search-modal__close">
            <Close />
          </strong>
        ),
      });
    }
  };

  const saveSearch = async (values: any) => {
    dispatch(setIsSaveSearchModalActive(false));
    dispatch(setIsSearchFeatureLoading(true));
    setIsErrorDisplayed(false);

    try {
      await dispatch(
        postFavoriteAddSearch({
          data: {
            userId: USER.userId,
            name: values.name,
            queryValues: queryValues.length
              ? pluck(queryValues, 'value')
              : null,
            propertyType: options.propertyType,
            metrics: prepareSearchMetrics(
              options,
              enabledSearchMetrics,
              searchMetricValues,
            ),
            notifyUser: !!values.notifyUser,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: searchType,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      notification.success({
        message: 'Success',
        description: 'Your search has been saved.',
        placement: 'topRight',
        top: 100,
        closeIcon: (
          <strong className="l-save-search-modal__close">
            <Close />
          </strong>
        ),
      });
    } catch (error) {
      errorHandler(error);
      setIsErrorDisplayed(true);
    }

    dispatch(setIsSearchFeatureLoading(false));
    dispatch(resetSiderMobileContentValues(undefined));
  };

  const renderMetricValues = (metric: any, key: string) => {
    const dummyFormatter = (value: any) => {
      return value;
    };

    const moneyFormatter = (value: any) => {
      return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const percentFormatter = (value: any) => {
      if (!value) {
        return 0;
      }

      let result = value;

      if (Math.round(value) !== Number(value)) {
        result = parseFloat(value).toFixed(2);
      }

      return `${result}`;
    };

    const metricPercents = [
      'grossRentalYield',
      'medianPriceGrowth1',
      'medianPriceGrowth3',
      'vacancyRate',
      'populationForecast3',
      'populationForecast5',
    ];

    const isPercent = metricPercents.includes(key);

    const checkConvertPercent = (value: any) => {
      return isPercent && value !== null ? value * 100 : value;
    };

    const metricUtils: any = {
      suburb: {
        medianSalePrice: {
          formatter: moneyFormatter,
          before: '$',
        },
        grossRentalYield: {
          formatter: percentFormatter,
          after: '%',
        },
        medianPriceGrowth1: {
          formatter: percentFormatter,
          after: '%',
        },
        medianPriceGrowth3: {
          formatter: percentFormatter,
          before: '',
          after: '%',
        },
        averageDaysMarket: {
          formatter: dummyFormatter,
        },
        vacancyRate: {
          formatter: percentFormatter,
          after: '%',
        },
        population: {
          formatter: dummyFormatter,
        },
        populationForecastRate3: {
          formatter: percentFormatter,
          after: '%',
        },
        populationForecastRate5: {
          formatter: percentFormatter,
          after: '%',
        },
        lgaTotalEstInvestment: {
          formatter: moneyFormatter,
          before: '$',
        },
        quarterSalesGrowth: {
          formatter: percentFormatter,
          after: '%',
        },
        yearSalesGrowth: {
          formatter: percentFormatter,
          after: '%',
        },
        yearRentalGrowth1: {
          formatter: percentFormatter,
          after: '%',
        },
        medianSalePriceForecast15: {
          formatter: percentFormatter,
          after: '%',
        },
        medianWeeklyAskingRentForecast15: {
          formatter: percentFormatter,
          after: '%',
        },
      },
      property: {
        askingPrice: {
          formatter: moneyFormatter,
          before: '$',
        },
        bedrooms: {
          formatter: dummyFormatter,
        },
        baths: {
          formatter: dummyFormatter,
        },
        carSpaces: {
          formatter: dummyFormatter,
        },
        landSize: {
          formatter: dummyFormatter,
          after: 'm²',
        },
        averageDaysMarket: {
          formatter: dummyFormatter,
        },
        grossRentalYield: {
          formatter: percentFormatter,
          after: '%',
        },
        publicHousing: {
          formatter: percentFormatter,
          after: '%',
        },
        umvEstimate: {
          formatter: percentFormatter,
          after: '%',
        },
      },
    };

    if (!metric || (metric.min === null && metric.max === null)) {
      return <span className="h-muted">Any</span>;
    }

    if (typeof metric === 'number') {
      return metric;
    }

    return (
      <span>
        {metric.min === null ? (
          <span className="h-muted">Any</span>
        ) : (
          `${metricUtils[searchType][key].before || ''}${metricUtils[
            searchType
          ][key].formatter(checkConvertPercent(metric.min))}${
            metricUtils[searchType][key].after || ''
          }`
        )}
        {' → '}
        {metric.max === null ? (
          <span className="h-muted">Any</span>
        ) : (
          `${metricUtils[searchType][key].before || ''}${metricUtils[
            searchType
          ][key].formatter(checkConvertPercent(metric.max))}${
            metricUtils[searchType][key].after || ''
          }`
        )}
      </span>
    );
  };

  return (
    <>
      {isMobileView && isSearchFeatureLoading && (
        <FullPageLoader style={{ position: 'fixed' }} />
      )}
      <div
        className={`l-save-search-modal${
          isMobileView ? ' l-save-search-modal--mobile' : ''
        }`}
      >
        <Form onFinish={saveSearch} form={form}>
          <div className="l-save-search-modal__content">
            <FloatLabel label="Search Name" value={name}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Search name is required',
                  },
                ]}
              >
                <Input className="l-common-form__input" />
              </Form.Item>
            </FloatLabel>
            <div className="l-metric-summary__box">
              {Object.keys(options.searchMetrics).map((key, index) => {
                return (
                  <dl className="l-metric-summary__details" key={index}>
                    <dt className="l-metric-summary__metric-name">
                      {
                        metricKeyValues.filter((metricKeyValue: any) => {
                          return metricKeyValue.value === key;
                        })[0].key
                      }
                    </dt>
                    <dd className="l-metric-summary__metric-value">
                      {renderMetricValues(options.searchMetrics[key], key)}
                    </dd>
                  </dl>
                );
              })}
            </div>
            <Form.Item name="notifyUser" valuePropName="checked">
              <Checkbox>Notify me when new suburbs match this search</Checkbox>
            </Form.Item>
          </div>
          <div className="l-save-search-modal__footer">
            {isErrorDisplayed && (
              <div className="l-save-search-modal__error">
                Something went wrong with the request
              </div>
            )}
            <div className="l-save-search-modal__button-group">
              <Form.Item>
                <button className="l-save-search-modal__button" type="submit">
                  Save
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SaveSearchModal;
