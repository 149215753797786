import React from 'react';

function Population() {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0827 16.3334V14.5M16.0827 16.3334H6.91602M16.0827 16.3334H21.5827V14.9584C21.5827 13.1864 20.1463 11.75 18.3744 11.75C17.3392 11.75 16.4186 12.2402 15.832 13.0011C15.9944 13.4708 16.0827 13.9751 16.0827 14.5M6.91602 16.3334V14.5M6.91602 16.3334H1.41602V14.9584C1.41602 13.1864 2.85244 11.75 4.62435 11.75C5.65945 11.75 6.58007 12.2402 7.16672 13.0011C7.00426 13.4708 6.91602 13.9751 6.91602 14.5M16.0827 14.5C16.0827 11.9687 14.0307 9.91669 11.4993 9.91669C8.96804 9.91669 6.91602 11.9687 6.91602 14.5M14.2494 4.41669C14.2494 5.93547 13.0181 7.16669 11.4993 7.16669C9.98057 7.16669 8.74935 5.93547 8.74935 4.41669C8.74935 2.8979 9.98057 1.66669 11.4993 1.66669C13.0181 1.66669 14.2494 2.8979 14.2494 4.41669ZM5.99935 7.16669C5.99935 8.17921 5.17854 9.00002 4.16602 9.00002C3.15349 9.00002 2.33268 8.17921 2.33268 7.16669C2.33268 6.15417 3.15349 5.33335 4.16602 5.33335C5.17854 5.33335 5.99935 6.15417 5.99935 7.16669ZM20.666 7.16669C20.666 8.17921 19.8452 9.00002 18.8327 9.00002C17.8202 9.00002 16.9994 8.17921 16.9994 7.16669C16.9994 6.15417 17.8202 5.33335 18.8327 5.33335C19.8452 5.33335 20.666 6.15417 20.666 7.16669Z"
        stroke="#A0A3BD"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Population;
