import React from 'react';

function KeyIndicators() {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8129 1.38037L21.0628 1.38041L21.0628 9.96376M20.8962 1.54727L12.7144 9.79163L8.58937 5.40734L1.45508 12.5416"
        stroke="#A0A3BD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default KeyIndicators;
