import React from 'react';

const AutoLayout = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H21V7H1V1Z" stroke="#A0A3BD" strokeWidth="1.5" />
      <path d="M1 13H21V19H1V13Z" stroke="#A0A3BD" strokeWidth="1.5" />
    </svg>
  );
};

export default AutoLayout;
