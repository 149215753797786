import { Radio } from 'antd';
import React, { useRef } from 'react';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import useMapSearch from '../../../../foundation/custom_hooks/useMapSearch';
import useMetricKeyValues from '../../../../foundation/custom_hooks/useMetricKeyValues';
import useSearch from '../../../../foundation/custom_hooks/useSearch';
import useSearchType from '../../../../foundation/custom_hooks/useSearchType';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectEnabledSearchMetrics,
  selectIsMapSearchEnabled,
  selectIsSearchFeatureLoading,
  selectOptions,
  selectSearchMetricValues,
  selectSuburbMetricsValues,
} from '../../redux/selectors';
import {
  searchMetricsDefaults,
  setEnabledSearchMetrics,
  setOptions,
} from '../../redux/slice';
import SearchField from '../search/search_field/SearchField';
import FilterFieldDropDown from './filter_field/FilterFieldDropDown';

const Filter = ({
  searchHandlerOptions,
  cancelHandler,
  isMobileViewVisible,
  isDisabled,
  init,
}: any) => {
  const dispatch = useAppDispatch();
  const contentRef = useRef<any>();

  const metricKeyValues = useMetricKeyValues();
  const searchType = useSearchType();

  const [isMobileView] = useBreakpoint();
  const searchMetricValues = useAppSelector(selectSearchMetricValues);
  const suburbMetricsValues = useAppSelector(selectSuburbMetricsValues);
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const options = useAppSelector(selectOptions);
  const isSearchFeatureLoading = useAppSelector(selectIsSearchFeatureLoading);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const fetchResults = useSearch();
  const fetchMapResults = useMapSearch();

  let propertyTypeOptions: any[] = [];

  if (searchMetricValues) {
    propertyTypeOptions = suburbMetricsValues.propertyType.map((option) => {
      return {
        label: option.key,
        value: option.value,
      };
    });
  }

  const optionPropertyTypeChangeHandler = (
    e: any, // Save the suburb metrics configuration in redux.
  ) =>
    dispatch(
      // @ts-ignore
      setOptions({
        ...options,
        propertyType: e.target.value,
      }),
    );

  const metricChangeHandler = (value: any, metricKey: string) => {
    const newValue: any = {};
    const searchMetrics = options.searchMetrics;

    if (Array.isArray(value)) {
      newValue[metricKey] = {
        min: value[0],
        max: value[1],
      };
    } else {
      newValue[metricKey] = value;
    }

    dispatch(
      setOptions({
        ...options,
        searchMetrics: {
          ...searchMetrics,
          ...newValue,
        },
      }),
    );

    const checkValueExists = () => {
      if (Array.isArray(value)) {
        return !Object.values(newValue[metricKey]).every(
          (prop) => prop === null,
        );
      }

      return newValue[metricKey] !== null;
    };

    if (checkValueExists()) {
      if (!enabledSearchMetrics.includes(metricKey)) {
        dispatch(setEnabledSearchMetrics([...enabledSearchMetrics, metricKey]));
      }
    } else {
      dispatch(
        setEnabledSearchMetrics(
          enabledSearchMetrics.filter((metric) => {
            return metric !== metricKey;
          }),
        ),
      );
    }
  };

  const clearFiltersHandler = () => {
    contentRef.current.scrollTop = 0;

    dispatch(
      setOptions({
        ...options,
        propertyType: propertyTypeOptions[0].value,
        // @ts-ignore
        searchMetrics: searchMetricsDefaults[searchType],
      }),
    );

    dispatch(setEnabledSearchMetrics([]));
  };

  return (
    <>
      {isMobileView && isSearchFeatureLoading && (
        <FullPageLoader style={{ position: 'fixed' }} />
      )}
      <div
        className={`l-filter-modal${
          isMobileView ? ' l-filter-modal--mobile' : ''
        }`}
      >
        <div ref={contentRef} className="l-filter-modal__content">
          <SearchField
            placeholder={'Search for a State, LGA, Postcode or Suburb...'}
            isCompact
            isMobileViewVisible={isMobileViewVisible}
            isDisabled={isDisabled}
            isParentFilter
            init={init}
          />
          <div className="l-filter-modal__content-padded">
            <div
              className={`l-filter-field-combo${
                isMobileView ? ' l-filter-field-combo--mobile' : ''
              }`}
            >
              <label className="l-filter-field-combo__label">
                Property Type
              </label>
              <div className="l-filter-field-combo__control">
                <Radio.Group
                  options={propertyTypeOptions}
                  optionType="button"
                  buttonStyle="solid"
                  value={options ? options.propertyType : ''}
                  onChange={optionPropertyTypeChangeHandler}
                />
              </div>
            </div>
            {searchMetricValues &&
              metricKeyValues.map((metric: any, index: number) => {
                return (
                  <FilterFieldDropDown
                    metricChangeHandler={metricChangeHandler}
                    metricKey={metric.value}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
        <div className="l-filter-modal__footer">
          <div className="l-filter-modal__button-group">
            <button
              className={`l-filter-modal__button${
                !enabledSearchMetrics.length
                  ? ' l-filter-modal__button--subtle'
                  : ''
              }`}
              type="button"
              onClick={clearFiltersHandler}
              style={{ width: '100%', marginRight: '10px' }}
              disabled={!enabledSearchMetrics.length}
            >
              Reset Filters
            </button>
            <button
              className="l-filter-modal__button"
              type="button"
              style={{ marginLeft: 'auto' }}
              onClick={(e) => {
                e.stopPropagation();

                const beforeCb = () => {
                  if (cancelHandler) {
                    cancelHandler();
                  }
                };

                if (isMapSearchEnabled) {
                  fetchMapResults({ sscCodes: null, beforeCb });
                } else {
                  fetchResults(
                    searchHandlerOptions
                      ? {
                          ...searchHandlerOptions,
                          beforeCb,
                        }
                      : {
                          pageIndexParam: 1,
                          beforeCb,
                        },
                  );
                }
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
