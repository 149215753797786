import React, { useEffect } from 'react';

import useBreakpoint from '../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ChannelBrowse from './channel_browse/ChannelBrowse';
import ChatPanel from './chat_panel/ChatPanel';
import ChatWindow from './chat_window/ChatWindow';
import { selectChatWindowType } from './redux/selectors';
import { setSelectedConversation } from './redux/slice';

const Chat = () => {
  const dispatch = useAppDispatch();
  const chatWindowType = useAppSelector(selectChatWindowType);

  const [isMobileView, isDesktopView] = useBreakpoint();

  useEffect(() => {
    if (isMobileView) {
      dispatch(setSelectedConversation(undefined));
    }
  }, [isMobileView]);

  if (isDesktopView) {
    return (
      <div className="l-chat">
        <ChatPanel />
        {chatWindowType === 'channel-browse' && <ChannelBrowse />}
        {chatWindowType === 'chat-window' && <ChatWindow />}
      </div>
    );
  } else if (isMobileView) {
    return (
      <>
        <ChatPanel />
      </>
    );
  }
};

export default Chat;
