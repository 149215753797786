import { ExclamationCircleFilled } from '@ant-design/icons';
import { Divider, Modal, notification } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import CaretRight from '../../../foundation/assets/svgs/CaretRight';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useIsLightPlanId } from '../../../foundation/custom_hooks/useIsLightPlanId';
import useLogout from '../../../foundation/custom_hooks/useLogout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteProfileRequest } from '../../user/redux/async_thunks';
import {
  selectClientIp,
  selectSubscriptionPlanDetails,
  selectUser,
} from '../../user/redux/selectors';
import { cancelSubscription } from '../redux/async_thunks';
import {
  selectAccountCanEdit,
  selectAccountCurrentPlan,
  selectAccountPaymentMethod,
  selectOrganization,
} from '../redux/selectors';
import OrganizationModal from './organization_modal/OrganizationModal';
import UpdateCardModal from './update_card_modal/UpdateCardModal';

const { confirm } = Modal;

const Settings = () => {
  const isLightPlan = useIsLightPlanId();
  const dispatch = useAppDispatch();

  const logoutUser = useLogout();
  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const navigate = useNavigate();
  const [isOrgModalVisible, setOrgModalVisibility] = useState(false);
  const [isPaymentMethodModalVisible, setPaymentMethodModalVisibility] =
    useState(false);
  const organization = useAppSelector(selectOrganization);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const subscriptionPlanDetails = useAppSelector(selectSubscriptionPlanDetails);

  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  const canEditAccount = useAppSelector(selectAccountCanEdit);

  const accountPaymentMethod = useAppSelector(selectAccountPaymentMethod);

  const options = useMemo(() => {
    return {
      token: user?.jwtToken.token,
      clientIp: clientIp,
      sessionId: user?.sessionId,
    };
  }, [user, clientIp]);

  const handleOrganizationUpdateClick = () => {
    setOrgModalVisibility(true);
  };

  const handleOrganizationModalCancel = () => {
    setOrgModalVisibility(false);
  };

  const handleChangePlan = () => {
    navigate('update-plan');
  };

  const handleChangePaymentMethodClick = () => {
    setPaymentMethodModalVisibility(true);
  };

  const handleChangePaymentMethodModalClose = () => {
    setPaymentMethodModalVisibility(false);
  };

  const handleCancelSubscription = async () => {
    if (isAccountLoading) {
      return;
    }

    try {
      if (subscriptionPlanDetails && subscriptionPlanDetails?.subscriptionId) {
        setIsAccountLoading(true);

        const data = {
          userId: user?.userId,
          subscriptionId: subscriptionPlanDetails?.subscriptionId,
        };

        await dispatch(cancelSubscription({ data, options })).unwrap();

        notification.success({
          message: 'Success',
          description: `Subscription has been canceled successfully.`,
        });

        setIsAccountLoading(false);
      }
    } catch (error) {
      setIsAccountLoading(false);
    }
  };

  const handleCancelPlanClick = () => {
    confirm({
      title: 'Cancel subscription',
      closable: false,
      content: (
        <div>
          {isAccountLoading && <FullPageLoader />}
          <div>{`Are you sure you want to cancel your subscription`}</div>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      onOk: () => {
        handleCancelSubscription();
      },
    });
  };

  const requestDeleteAccount = async () => {
    if (isAccountLoading) {
      return;
    }

    try {
      setIsAccountLoading(true);
      await dispatch(
        deleteProfileRequest({
          data: {
            userId: user?.userId,
            clientIp,
          },
          options,
        }),
      )
        // @ts-ignore
        .unwrap();

      logoutUser('delete');
    } catch (error) {
      setIsAccountLoading(false);
    }
  };

  const handleDeleteAccountClick = () => {
    confirm({
      title: <strong>Confirm Delete Account</strong>,
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          <p>
            You will be logged out of your current session. An email containing
            the next steps will be sent to you.
          </p>
          <br />
          <p>Proceed with this action?</p>
        </>
      ),
      okText: 'Proceed',
      onOk: requestDeleteAccount,
      cancelText: 'Cancel',
      centered: true,
      width: 400,
    });
  };

  const handleAddMoreUserClick = () => {
    navigate('update-seats');
  };

  return (
    <div className="l-acc-settings">
      {isAccountLoading && <FullPageLoader />}
      {isPaymentMethodModalVisible && (
        <UpdateCardModal handleCancel={handleChangePaymentMethodModalClose} />
      )}
      {isOrgModalVisible && (
        <OrganizationModal handleCancel={handleOrganizationModalCancel} />
      )}

      {!isLightPlan && canEditAccount && (
        <>
          <div className="l-acc-settings__section">
            <div className="l-acc-settings__settings-title">
              {organization?.orgName}
            </div>
            <div className="l-acc-settings__settings-options">
              <div
                className="l-acc-settings__settings-option"
                onClick={handleOrganizationUpdateClick}
              >
                <div className="l-acc-settings__settings-option-title">
                  Change company or team name
                </div>
                <div className="l-acc-settings__settings-option-icon">
                  <CaretRight />
                </div>
              </div>
              <div
                className="l-acc-settings__settings-option"
                onClick={handleOrganizationUpdateClick}
              >
                <div className="l-acc-settings__settings-option-title">
                  Change logo
                </div>
                <div className="l-acc-settings__settings-option-icon">
                  <CaretRight />
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}
      {(isLightPlan || (!isLightPlan && canEditAccount)) && (
        <>
          <div className="l-acc-settings__section">
            <div className="l-acc-settings__settings-title">
              {`Plan and billing`}
            </div>
            <div className="l-acc-settings__settings-options">
              <div
                className="l-acc-settings__settings-option"
                onClick={handleChangePlan}
              >
                <div className="l-acc-settings__settings-option-title">
                  Upgrade your plan
                  {currentPlan &&
                    currentPlan.scheduledCancellation &&
                    currentPlan.scheduledCancellation.scheduledToCancel && (
                      <div className="l-acc-settings__settings-option-desc">
                        Your subscription will expire on{' '}
                        {moment(
                          currentPlan.scheduledCancellation.cancellationDate,
                        ).format('DD/MM/YYYY')}
                      </div>
                    )}
                  {currentPlan &&
                    (!currentPlan.scheduledCancellation ||
                      !currentPlan.scheduledCancellation.scheduledToCancel) &&
                    currentPlan.renewalDate && (
                      <div className="l-acc-settings__settings-option-desc">
                        Your subscription renews on{' '}
                        {moment(currentPlan.renewalDate).format('DD/MM/YYYY')}
                      </div>
                    )}
                </div>
                <div className="l-acc-settings__settings-option-icon">
                  <CaretRight />
                </div>
              </div>
              {!isLightPlan && (
                <div
                  className="l-acc-settings__settings-option"
                  onClick={handleAddMoreUserClick}
                >
                  <div className="l-acc-settings__settings-option-title">
                    Add additional seats to your plan
                  </div>
                  <div className="l-acc-settings__settings-option-icon">
                    <CaretRight />
                  </div>
                </div>
              )}
              <div
                className="l-acc-settings__settings-option"
                onClick={handleChangePaymentMethodClick}
              >
                <div className="l-acc-settings__settings-option-title">
                  Change payment method
                  <div className="l-acc-settings__settings-option-desc">
                    {`**** **** **** ${accountPaymentMethod?.last4}`}
                  </div>
                </div>
                <div className="l-acc-settings__settings-option-icon">
                  <CaretRight />
                </div>
              </div>
              <div
                className="l-acc-settings__settings-option"
                onClick={handleCancelPlanClick}
              >
                <div className="l-acc-settings__settings-option-title">
                  Cancel plan
                  <div className="l-acc-settings__settings-option-desc">
                    Cancel your subscription of rhomeo
                  </div>
                </div>
                <div className="l-acc-settings__settings-option-icon">
                  <CaretRight />
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}

      <div className="l-acc-settings__section">
        <div className="l-acc-settings__settings-title">{`Delete`}</div>
        <div className="l-acc-settings__settings-options">
          <div
            className="l-acc-settings__settings-option"
            onClick={handleDeleteAccountClick}
          >
            <div className="l-acc-settings__settings-option-title">
              Delete account
              <div className="l-acc-settings__settings-option-desc">
                {` Permanently delete your account and all resources within`}
              </div>
            </div>
            <div className="l-acc-settings__settings-option-icon">
              <CaretRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
