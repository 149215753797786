import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, notification, UploadProps } from 'antd';
import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Input from '../../../../foundation/components/input/Input';
import generateBase64 from '../../../../foundation/utils/generateBase64';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectAllowedAdditionalUsers,
  selectClientIp,
  selectOrgId,
  selectOrgName,
  selectUser,
} from '../../../user/redux/selectors';
import {
  updateOrganization,
  UpdateOrganizationProps,
} from '../../redux/async_thunks';
import { StepTypes } from '../../types';

const { Dragger } = Upload;

type OrganizationSettingsProps = {
  handleSkip?: () => void;
  setStep?: (v: StepTypes) => void;
  handleCancel?: () => void;
  type: 'account' | 'onboarding';
};
const OrganizationSettings = ({
  handleSkip,
  setStep,
  type,
  handleCancel,
}: OrganizationSettingsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOrgSettingsLoading, setOrgSettingsLoader] = useState(false);
  const [name, setName] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<RcFile>();
  const [base64, setBase64] = useState<string>();
  const orgId = useAppSelector(selectOrgId);
  const orgName = useAppSelector(selectOrgName);
  const additionalNumOfUsers = useAppSelector(selectAllowedAdditionalUsers);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  useEffect(() => {
    setName(orgName);
  }, [orgName]);

  const uploadProps: UploadProps = {
    name: 'picture',
    multiple: false,
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onChange = async ({ file }: { file: any }) => {
    if (file.status === 'uploading') {
      return;
    }

    if (file.status === 'done') {
      const preview: any = await generateBase64(file.originFileObj);

      setFile(file.originFileObj);
      setBase64(preview);
    }
  };

  const handleImageDelete = () => {
    setFile(undefined);
    setBase64(undefined);
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const isNameValid = useMemo(() => {
    if (name && name.length >= 3) {
      return true;
    } else {
      return false;
    }
  }, [name]);

  const handleUpdate = async () => {
    if (isOrgSettingsLoading) {
      return;
    }
    try {
      if (name && orgId) {
        setOrgSettingsLoader(true);

        const data: UpdateOrganizationProps = {
          userId: user?.userId,
          orgId,
          orgName: name,
        };

        const options = {
          token: user?.jwtToken.token,
          clientIp: clientIp,
          sessionId: user?.sessionId,
        };

        await dispatch(
          updateOrganization({
            data,
            options,
            picture: file,
          }),
        ).unwrap();

        if (setStep) {
          if (additionalNumOfUsers) {
            setStep('team');
          } else {
            navigate('/');
          }
        }

        if (type === 'account' && handleCancel) {
          handleCancel();

          notification.success({
            message: 'Success',
            description: 'Organization updated successfully.',
          });
        }

        setOrgSettingsLoader(false);
      }
    } catch (error) {
      setOrgSettingsLoader(false);
    }
  };

  const handleCancelStep = () => {
    if (isOrgSettingsLoading) {
      return;
    }
    if (handleSkip) {
      if (additionalNumOfUsers) {
        handleSkip();
      } else {
        navigate('/');
      }
    }
  };

  const handleModalCancel = () => {
    if (isOrgSettingsLoading) {
      return;
    }

    if (handleCancel) {
      handleCancel();
    }
  };

  return (
    <div className="l-org-setup">
      {isOrgSettingsLoading && <FullPageLoader />}
      <div className="l-org-setup__field-wrapper">
        <Input
          label="Team or Company Name"
          defaultValue={name}
          value={name}
          onChange={handleNameChange}
        />
      </div>
      <div className="l-org-setup__field-wrapper">
        <div className="l-org-setup__field-title">Add your logo</div>

        <Dragger
          {...uploadProps}
          className="l-org-setup__dragger"
          accept=".png,.jpg,.jpeg,.jfif"
          maxCount={1}
          listType="picture"
          customRequest={dummyRequest}
          onChange={onChange}
          showUploadList={false}
          disabled={file ? true : false}
        >
          {base64 ? (
            <div className="l-org-setup__img-item">
              <div className="l-org-setup__img-left-side">
                <div className="l-org-setup__img-wrapper">
                  <img src={base64} />
                </div>
                <div className="l-org-setup__detail-wrapper">
                  <div className="l-org-setup__detail-name"> {file?.name}</div>
                  <div className="l-org-setup__detail-size">
                    {file?.size &&
                      parseFloat((file?.size / 1024 / 1024).toString()).toFixed(
                        2,
                      )}
                    mb
                  </div>
                </div>
              </div>
              <div
                className="l-org-setup__img-right-side"
                onClick={handleImageDelete}
              >
                <Close />
              </div>
            </div>
          ) : (
            <>
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <div className="l-org-setup__dragger-text">
                Drag & Drop your logo
              </div>

              <Button type="primary" className="l-org-setup__dragger-btn">
                Browse to upload
              </Button>
            </>
          )}
        </Dragger>
      </div>
      <div className="l-onboarding__footer">
        {type === 'account' && (
          <Button
            className="l-onboarding__save-btn"
            style={{ width: 132, marginRight: 20 }}
            onClick={handleModalCancel}
            disabled={isOrgSettingsLoading}
          >
            Cancel
          </Button>
        )}

        {type === 'onboarding' && (
          <div className="l-onboarding__skip-btn" onClick={handleCancelStep}>
            Do this later
          </div>
        )}

        <Button
          type="primary"
          className="l-onboarding__save-btn"
          onClick={handleUpdate}
          disabled={!isNameValid}
        >
          {type === 'account' ? 'Save' : `Save & Continue`}
        </Button>
      </div>
    </div>
  );
};

export default OrganizationSettings;
