import { useMemo } from 'react';

import { SubscriptionPlan } from '../../../user/redux/types';
import { SubscriptionFrequency } from '../types';

export const usePlanRate = (
  selectedPlan: SubscriptionPlan | undefined,
  subscriptionFrequency: SubscriptionFrequency,
) => {
  const planRate = useMemo(() => {
    if (selectedPlan) {
      for (const item of selectedPlan.planRates) {
        if (item.frequency === subscriptionFrequency) {
          return item;
        }
      }
    }
  }, [subscriptionFrequency, selectedPlan]);

  return planRate;
};
