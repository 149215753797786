import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Modal, notification, Popover, Table } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useRole } from '../../../foundation/custom_hooks/useRole';
import getInitials from '../../../foundation/utils/getInitials';
import getRandomLightColor from '../../../foundation/utils/getRandomLightColor';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import { updateOrganization } from '../redux/async_thunks';
import {
  selectAccountMembersAvailableSlots,
  selectOrganization,
} from '../redux/selectors';
import { MemberEditType } from '../types';
import EditMember from './edit_member/EditMember';
import InviteModal from './invite_modal/InviteModal';

const { confirm } = Modal;

const Members = ({ currentMembers, isClients }: any) => {
  const dispatch = useAppDispatch();

  const [isMembersLoading, setMembersLoading] = useState(false);
  const [isEditModalVisible, setEditModalVisibility] = useState(false);

  const [editingMember, setEditingMember] = useState<
    MemberEditType | undefined
  >(undefined);

  const user = useAppSelector(selectUser);

  const org = useAppSelector(selectOrganization);

  const clientIp = useAppSelector(selectClientIp);

  const availableSlots = useAppSelector(selectAccountMembersAvailableSlots);

  const userRole = useRole();

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const onMemberEditOptionClick = (row: any) => () => {
    setEditModalVisibility(true);
    const data = {
      email: row.email,
      roleId: row.roleId,
      roleName: row.roleName,
      name: row.name,
    };

    setEditingMember(data);
  };

  const handleMemberRemove = async (email: string) => {
    if (isMembersLoading) {
      return;
    }
    try {
      if (user && org) {
        setMembersLoading(true);

        const data = {
          orgId: org.orgId,
          userId: user.userId,
          membersRemove: [
            {
              email,
            },
          ],
        };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        await dispatch(
          updateOrganization({ data, options, picture: undefined }),
        ).unwrap();

        notification.success({
          message: 'Success',
          description: `${email} is successfully removed from the organization.`,
        });

        setMembersLoading(false);
      }
    } catch (error) {
      console.log(error);
      setMembersLoading(false);
    }
  };

  const onMemberRemoveOptionClick = (row: any) => () => {
    if (isMembersLoading) {
      return;
    }

    confirm({
      title: 'Remove User?',
      closable: false,
      content: (
        <div>
          {isMembersLoading && <FullPageLoader />}
          <div>
            {`Are you sure you want to remove ${row.email} from your
            organization?`}
          </div>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      onOk: () => {
        handleMemberRemove(row.email);
      },
    });
  };

  const renderSettingsView = useCallback(
    (row: any) => {
      if (user?.email === row.email || userRole !== 'Admin') {
        return null;
      }
      return (
        <Popover
          content={
            <div className="l-members__settings-menu">
              {!isClients && (
                <div
                  className="l-members__settings-menu-list-item"
                  onClick={onMemberEditOptionClick(row)}
                >
                  <EditOutlined style={{ marginRight: 10 }} />
                  Edit User
                </div>
              )}
              <div
                className="l-members__settings-menu-list-item"
                onClick={onMemberRemoveOptionClick(row)}
              >
                <DeleteOutlined style={{ marginRight: 10 }} />
                Remove User
              </div>
            </div>
          }
          trigger={'click'}
          style={{ cursor: 'pointer' }}
          overlayInnerStyle={{
            borderRadius: 10,
            boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
            border: '1px solid #D9DBE9',
          }}
        >
          <MoreOutlined style={{ fontSize: 30 }} />
        </Popover>
      );
    },
    [user, userRole, onMemberRemoveOptionClick, isMembersLoading],
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',

      render: (text: string, row: any) => {
        return (
          <div className="l-members__member-info-wrapper">
            <div>
              <Avatar
                className="l-members__member-avatar"
                style={{ backgroundColor: getRandomLightColor() }}
              >
                {getInitials(text)}
              </Avatar>
            </div>
            <div className="l-members__member-info">
              <div className="l-members__member-name">
                {row.email === user?.email ? `${text} (you)` : text}
              </div>
              <div className="l-members__member-email">{row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Permissions',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'settings',
      key: 'settings',
      width: 250,
      render: (text: string, row: any) => renderSettingsView(row),
    },
  ];

  const addUserColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, row: any) => {
        return (
          <div className="l-members__member-info-wrapper">
            <div>
              <Avatar
                className="l-members__member-avatar"
                style={{ backgroundColor: '#C3BEFC', color: '#651fff' }}
                icon={<UserOutlined />}
              />
            </div>
            <div className="l-members__member-info">
              <div className="l-members__member-name">{text}</div>
              <div className="l-members__member-email">{row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Permissions',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'settings',
      key: 'settings',
      width: 250,
      render: () => {
        return (
          <Button
            className="l-members__members-invite-btn"
            onClick={onInviteBtnClick}
          >
            Invite
          </Button>
        );
      },
    },
  ];
  const additionalMemberTableSource = useMemo(() => {
    return [
      {
        name: `${availableSlots} x Additional seat${
          availableSlots && availableSlots > 1 ? 's' : ''
        } available`,
        roleName: ' ',
        settings: ' ',
      },
    ];
  }, [availableSlots]);

  const additionalClientTableSource = [
    {
      name: 'Unlimited seats available',
      roleName: ' ',
      settings: ' ',
    },
  ];

  const onInviteBtnClick = () => {
    setInviteModalVisibility(true);
  };

  const handleInviteModalCancel = () => {
    setInviteModalVisibility(false);
  };

  const handleEditMemberCancel = () => {
    setEditModalVisibility(false);
    setEditingMember(undefined);
  };

  return (
    <div className="l-members">
      {isMembersLoading && <FullPageLoader />}
      {isEditModalVisible && (
        <EditMember
          handleCancel={handleEditMemberCancel}
          initialData={editingMember}
        />
      )}
      {isInviteModalVisible && (
        <InviteModal
          handleCancel={handleInviteModalCancel}
          type={isClients ? 'clients' : 'members'}
        />
      )}
      <Table columns={columns} dataSource={currentMembers} pagination={false} />
      {userRole === 'Admin' && (
        <>
          {!isClients && !!availableSlots && (
            <Table
              columns={addUserColumns}
              showHeader={false}
              pagination={false}
              dataSource={additionalMemberTableSource}
            />
          )}
          {isClients && (
            <Table
              columns={addUserColumns}
              showHeader={false}
              pagination={false}
              dataSource={additionalClientTableSource}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Members;
