import { Skeleton } from 'antd';
import React from 'react';

const ChannelListItemSkeleton = () => {
  const ListItem = () => (
    <div className="l-chat-browse-channels__list-item">
      <div>
        <Skeleton.Input active />
      </div>
      <Skeleton.Button active />
    </div>
  );
  return (
    <>
      <ListItem />
      <ListItem />
      <ListItem />
      <ListItem />
      <ListItem />
    </>
  );
};

export default ChannelListItemSkeleton;
