import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

import CheckOutlined from '../../../../foundation/assets/svgs/CheckOutlined';
import { InviteType } from '../../types';

type OnboardSuccessProps = {
  allInvites: InviteType[];
  handleCancel: () => void;
};

const OnboardSuccess = ({ allInvites, handleCancel }: OnboardSuccessProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    handleCancel();
    navigate('/');
  };

  return (
    <div className="l-onboard-success">
      <div className="l-onboard-success__content-wrapper">
        <div className="l-onboard-success__header-icon">
          <CheckOutlined />
        </div>
        <div className="l-onboard-success__header-title">All done!</div>
        <p className="l-onboard-success__desc">
          Invitations have been sent to the following email addresses:
        </p>
        {allInvites.map((invite) => (
          <p key={invite.email} className="l-onboard-success__invitee">
            <strong>{invite.email}</strong>
          </p>
        ))}
        <p className="l-onboard-success__desc">
          Wrong email? You can manage your team/company account details, in{` `}
          <strong>
            <a>account settings</a>
          </strong>{' '}
          under your profile.
        </p>
      </div>

      <div className="l-onboard-success__btn-wrapper">
        <Button
          type="primary"
          className="l-onboard-success__btn"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default OnboardSuccess;
