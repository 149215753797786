import React from 'react';

function GreenCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      fill="none"
      viewBox="0 0 19 15"
    >
      <path
        stroke="#33B433"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 7.314l5.657 5.657L17.97 1.657"
      ></path>
    </svg>
  );
}

export default React.memo(GreenCheck);
