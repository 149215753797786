import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  classNames?: string;
  message?: string;
  style?: any;
};

function FullPageLoader({
  classNames,
  message,
  style = {},
}: Props): JSX.Element {
  return (
    <div className={`full-page-loader ${classNames || ''}`} style={style}>
      <Spin />
      {message && <p className="full-page-loader__message">{message}</p>}
    </div>
  );
}

// PropTypes
FullPageLoader.propTypes = {
  classNames: PropTypes.string,
};

export default FullPageLoader;
