import React from 'react';

function RelatedArticles() {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.75 18.8332V12.4165C17.75 10.3915 16.1084 8.74984 14.0833 8.74984H12.1776C11.4182 8.74984 10.8026 8.13423 10.8026 7.37484V5.08317C10.8026 3.05813 9.16101 1.4165 7.13596 1.4165H4M17.75 18.8332C17.75 20.352 16.5188 21.5832 15 21.5832H4C2.48122 21.5832 1.25 20.352 1.25 18.8332V4.1665C1.25 2.64772 2.48122 1.4165 4 1.4165M17.75 18.8332V12.6775C17.75 10.7329 17.0631 8.85081 15.8105 7.36337L13.275 4.35238C11.7074 2.49093 9.39799 1.4165 6.96443 1.4165H4"
        stroke="#A0A3BD"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default RelatedArticles;
