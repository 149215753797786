import React from 'react';

function BinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#6E7191"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M12.333 4.333v-.667a2 2 0 00-2-2H7.666a2 2 0 00-2 2v.667m-4 0h14.667m-5.333 4v3.333M7 8.333v3.333M3 4.333h12l-.442 8.833a3.333 3.333 0 01-3.329 3.167H6.771a3.333 3.333 0 01-3.33-3.167L3 4.333z"
      ></path>
    </svg>
  );
}

export default BinIcon;
