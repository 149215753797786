import React from 'react';

function Bold() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#AFAFAF"
        d="M8.007 5.68c1.13.264 1.925.584 2.382.959.656.533.984 1.224.984 2.074 0 .896-.36 1.638-1.081 2.224C9.407 11.646 8.122 12 6.434 12H.378v-.325c.55 0 .923-.05 1.116-.15.2-.105.337-.24.413-.404.082-.164.123-.568.123-1.213V2.174c0-.645-.04-1.049-.123-1.213a.84.84 0 00-.413-.404c-.2-.1-.571-.15-1.116-.15V.082H6.09c1.365 0 2.332.123 2.9.37a2.98 2.98 0 011.345 1.089c.328.48.492.993.492 1.538 0 .574-.208 1.087-.624 1.538-.416.446-1.148.8-2.197 1.064zm-3.2-.237c.833 0 1.445-.093 1.838-.28a2.09 2.09 0 00.914-.792c.21-.34.316-.773.316-1.3 0-.528-.105-.959-.316-1.293a1.946 1.946 0 00-.888-.773C6.284.829 5.663.745 4.808.75v4.693zm0 .686v3.823l-.008.44c0 .316.079.556.237.72.164.159.404.238.72.238.47 0 .9-.103 1.293-.308.398-.21.703-.513.914-.905.21-.399.316-.841.316-1.327 0-.557-.129-1.055-.386-1.495a2.08 2.08 0 00-1.064-.931c-.451-.176-1.125-.261-2.021-.255z"
      ></path>
    </svg>
  );
}

export default Bold;
