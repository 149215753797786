import { FileTextOutlined } from '@ant-design/icons';
import { CometChat } from '@cometchat-pro/chat';
import { Avatar, Button, Image } from 'antd';
import parse, { domToReact } from 'html-react-parser';
import IframeResizer from 'iframe-resizer-react';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ReplyIcon from '../../../../foundation/assets/svgs/ReplyIcon';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch } from '../../../../store/hooks';
import { resetSiderMobileContentValues } from '../../../layout/redux/slice';
import {
  setActiveSuburbSscCode,
  setIsFirstAttempt,
} from '../../../search/redux/slice';
import * as enums from '../../utils/enums';
import ProfileView from './ProfileView';
import ReplyToThread from './ReplyToThread';

type Props = {
  message: any;
  isFirstOfBatch: boolean;
  isDM: boolean;
  isSender: boolean;
  ccUser: any;
  selectedConversation: any;
  members: any[];
  setIsThreadPanelVisible: (v: boolean) => void;
  setParentMessage: (v: any) => void;
  isReply: boolean;
  isHighlighted?: boolean;
};

const ChatMessage = ({
  message,
  isFirstOfBatch,
  isSender,
  isDM,
  ccUser,
  selectedConversation,
  members,
  setIsThreadPanelVisible,
  setParentMessage,
  isReply,
  isHighlighted,
}: Props) => {
  const navigate = useNavigate();
  const mentionRef = useRef(null);
  const [profileId, setProfileId] = useState();
  const [isMobileView] = useBreakpoint();

  const dispatch = useAppDispatch();

  const avatarLink = useMemo(() => {
    if (isSender) {
      return ccUser.avatar;
    } else if (
      !isSender &&
      isDM &&
      selectedConversation &&
      selectedConversation.conversationWith
    ) {
      return selectedConversation.conversationWith.avatar;
    } else {
      let sender;

      for (const member of members) {
        if (member.uid === message.sender.uid) {
          sender = member;
        }
      }
      return sender?.avatar;
    }
  }, [members, isDM, selectedConversation, isSender, message]);

  const messageTime = useMemo(() => {
    if (isFirstOfBatch && message) {
      const messageDate = message?.sentAt * 1000;
      const messageSentDate = moment(messageDate).format('hh:mm A');
      return messageSentDate;
    }
    return null;
  }, [message, isFirstOfBatch]);

  const isCustomMessage = useMemo(
    () => message.type === enums.CUSTOM_TYPE_DRAFTJS,
    [message.type],
  );

  const renderMessageContent = () => {
    if (isCustomMessage) {
      const data = message.data.customData;
      const options = {
        replace: (domNode: any) => {
          if (
            domNode.attribs &&
            domNode.attribs.class === 'chat-mention' &&
            domNode.attribs['data-uid']
          ) {
            let isSelf;

            const profile = members.find((member) => {
              isSelf = ccUser && member.uid === ccUser?.uid;
              return member.uid === domNode.attribs['data-uid'];
            });

            return (
              <ProfileView
                pid={
                  profile &&
                  profile.metadata &&
                  profileId === domNode.attribs['data-uid']
                    ? profile.metadata.pid
                    : null
                }
                trigger={'hover'}
                isFollowEnabled={!isSelf}
              >
                <span
                  className="chat-mention"
                  data-uid={domNode.attribs['data-uid']}
                  // @ts-ignore
                  ref={mentionRef}
                >
                  {/* {domToReact(domNode.children, options)} */}
                  {/* Force display the handle value from metadata instead of the stored message node */}
                  {mentionRef &&
                  mentionRef.current &&
                  profile &&
                  profile.metadata
                    ? profile.metadata.hdl
                    : domToReact(domNode.children, options)}
                </span>
              </ProfileView>
            );
          } else if (
            domNode.attribs &&
            domNode.attribs.class === 'chat-share-suburb' &&
            domNode.attribs['data-purl']
          ) {
            return (
              <>
                <p>{domToReact(domNode.children, options)}</p>
                <div className="h-iframe-wrapper">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        setActiveSuburbSscCode(
                          domNode.attribs['data-ssc-code'],
                        ),
                      );
                      dispatch(setIsFirstAttempt(false));
                      dispatch(resetSiderMobileContentValues(undefined));
                      navigate('/suburb-search');
                    }}
                    className="h-iframe-overlay"
                  ></div>
                  <IframeResizer
                    title="Share Suburb"
                    src={domNode.attribs['data-purl']}
                    checkOrigin={false}
                    className="h-iframe"
                    maxWidth={450}
                    resizeFrom={'parent'}
                    heightCalculationMethod={'max'}
                    inPageLinks={true}
                    autoResize={false}
                  />
                </div>
              </>
            );
          }

          return domNode;
        },
      };

      return parse(data.html, options);
    } else if (message.type === CometChat.MESSAGE_TYPE.IMAGE) {
      const url = message?.data?.attachments[0]?.url;
      return (
        <Image
          width={200}
          height={150}
          src={url}
          fallback={enums.IMAGE_PLACEHOLDER}
          placeholder={enums.IMAGE_PLACEHOLDER}
          style={{ borderRadius: 8 }}
        />
      );
    } else if (
      message.type === CometChat.MESSAGE_TYPE.FILE ||
      message.type === CometChat.MESSAGE_TYPE.MEDIA
    ) {
      const url = message?.data?.attachments[0]?.url;
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="chat-message__msg-file"
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <div className="chat-message__msg-file-icon">
            <FileTextOutlined />
          </div>
          <div>
            <p className="chat-message__msg-file-name">
              {message?.data?.attachments[0]?.name}
            </p>
            <p>{message?.data?.attachments[0]?.size / 1000}KB</p>
          </div>
        </a>
      );
    } else {
      return message?.text;
    }
  };

  const handleReplyIconClick = () => {
    setIsThreadPanelVisible(true);
    setParentMessage(message);
  };

  useEffect(() => {
    if (mentionRef && mentionRef.current) {
      // @ts-ignore
      setProfileId(mentionRef.current.getAttribute('data-uid'));
    }
  }, [mentionRef]);

  if (!isReply) {
    return (
      <div
        className={`chat-message__wrapper ${
          isHighlighted ? 'chat-message__wrapper--highlight' : ''
        } ${
          isSender
            ? 'chat-message__wrapper--sender'
            : 'chat-message__wrapper--receiver'
        }`}
      >
        {!isMobileView && (
          <div
            className={`chat-message__avatar-wrapper ${
              isSender
                ? 'chat-message__avatar-wrapper--sender'
                : 'chat-message__avatar-wrapper--receiver'
            }`}
          >
            {isFirstOfBatch && <Avatar size="large" src={avatarLink} />}
          </div>
        )}

        <div>
          {isFirstOfBatch && (
            <div
              className={`chat-message__sender-details ${
                isSender
                  ? 'chat-message__sender-details--sender'
                  : 'chat-message__sender-details--receiver'
              }`}
            >
              {isMobileView && <Avatar size="small" src={avatarLink} />}
              <div
                className={`chat-message__sender-name ${
                  isSender
                    ? 'chat-message__sender-name--sender'
                    : 'chat-message__sender-name--receiver'
                }`}
              >
                {isSender ? 'You' : message.sender.name}
              </div>
              <div
                className={`chat-message__time  ${
                  isSender
                    ? 'chat-message__time--sender'
                    : 'chat-message__time--receiver'
                }`}
              >
                {messageTime}
              </div>
            </div>
          )}
          <div
            className={`chat-message__message ${
              isSender
                ? 'chat-message__message--sender'
                : 'chat-message__message--receiver'
            }`}
            onClick={() => {
              if (isMobileView) {
                handleReplyIconClick();
              }
            }}
          >
            {renderMessageContent()}
            <div
              className={`chat-message__action-wrapper ${
                isSender
                  ? 'chat-message__action-wrapper--sender'
                  : 'chat-message__action-wrapper--receiver'
              }`}
            >
              <Button
                shape="circle"
                icon={<ReplyIcon />}
                size="middle"
                className="chat-message__reply-btn"
                onClick={handleReplyIconClick}
              />
            </div>
          </div>
          {message.replyCount && (
            <ReplyToThread
              handleReplyInThreadClick={handleReplyIconClick}
              message={message}
              selectedConversation={selectedConversation}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={`chat-thread-message `}>
        {isFirstOfBatch && (
          <div className={`chat-thread-message__info-wrapper`}>
            <div className={`chat-thread-message__avatar-wrapper`}>
              <Avatar size="small" src={avatarLink} />
            </div>
            <div className={`chat-thread-message__sender-name`}>
              {isSender ? 'You' : message.sender.name}
            </div>
            <div className={`chat-thread-message__time`}>{messageTime}</div>
          </div>
        )}
        <div className={`chat-thread-message__message`}>
          {renderMessageContent()}
        </div>
      </div>
    );
  }
};

export default ChatMessage;
