import React from 'react';

type Props = {
  title: string;
  children: JSX.Element;
};

const Template = ({ title, children }: Props) => {
  return (
    <div className="l-template">
      {/* <div className="l-template__header">
        <div className="l-template__content">
          <h1>Rhomeo</h1>
          <p>Dashdot Application</p>
        </div>
      </div> */}
      <div className="l-template__body">
        <div className="l-template__content">{children}</div>
      </div>
    </div>
  );
};

export default Template;
