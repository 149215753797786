import { Row } from 'antd';
import React from 'react';

const View = ({
  setAreDetailsLoading,
  suburb,
  growth,
  isFirstLoad,
  setIsFirstLoad,
  isLoading,
  setIsLoading,
  Graph,
  Growth,
}: any) => {
  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  return (
    <>
      <Row gutter={[0, 50]}>
        {/* The growth component handles the loader for the indicators tab */}
        <Growth
          setIsFirstLoad={setIsFirstLoad}
          toggleLoader={toggleLoader}
          isFirstLoad={isFirstLoad}
          isLoading={isLoading}
        />
        {!!suburb && !!growth && !isLoading && (
          <Graph setAreDetailsLoading={setAreDetailsLoading} />
        )}
      </Row>
    </>
  );
};

export default View;
