import React from 'react';

function House() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_20276)">
        <path
          d="M2 8V7.25C1.58579 7.25 1.25 7.58579 1.25 8H2ZM22 8H22.75C22.75 7.58579 22.4142 7.25 22 7.25V8ZM22 23V23.75C22.4142 23.75 22.75 23.4142 22.75 23H22ZM2 23H1.25C1.25 23.4142 1.58579 23.75 2 23.75V23ZM6 11V10.25C5.58579 10.25 5.25 10.5858 5.25 11H6ZM10 11H10.75C10.75 10.5858 10.4142 10.25 10 10.25V11ZM10 15V15.75C10.4142 15.75 10.75 15.4142 10.75 15H10ZM6 15H5.25C5.25 15.4142 5.58579 15.75 6 15.75V15ZM14 11V10.25C13.5858 10.25 13.25 10.5858 13.25 11H14ZM18 11H18.75C18.75 10.5858 18.4142 10.25 18 10.25V11ZM18 15V15.75C18.4142 15.75 18.75 15.4142 18.75 15H18ZM14 15H13.25C13.25 15.4142 13.5858 15.75 14 15.75V15ZM10 18V17.25H9.25V18H10ZM14 18H14.75V17.25H14V18ZM14 23V23.75H14.75V23H14ZM10 23H9.25V23.75H10V23ZM1 8L0.618453 7.35431C0.327421 7.52628 0.187404 7.87169 0.276546 8.19777C0.365688 8.52385 0.661954 8.75 1 8.75L1 8ZM23 8V8.75C23.338 8.75 23.6343 8.52385 23.7235 8.19777C23.8126 7.87169 23.6726 7.52628 23.3815 7.35431L23 8ZM1 22.25C0.585786 22.25 0.25 22.5858 0.25 23C0.25 23.4142 0.585786 23.75 1 23.75V22.25ZM23 23.75C23.4142 23.75 23.75 23.4142 23.75 23C23.75 22.5858 23.4142 22.25 23 22.25V23.75ZM12 1.5L12.3815 0.854305C12.1462 0.715232 11.8538 0.715232 11.6185 0.854305L12 1.5ZM2 8.75H22V7.25H2V8.75ZM21.25 8V23H22.75V8H21.25ZM22 22.25H2V23.75H22V22.25ZM2.75 23V8H1.25V23H2.75ZM6 11.75H10V10.25H6V11.75ZM9.25 11V15H10.75V11H9.25ZM10 14.25H6V15.75H10V14.25ZM6.75 15V11H5.25V15H6.75ZM14 11.75H18V10.25H14V11.75ZM17.25 11V15H18.75V11H17.25ZM18 14.25H14V15.75H18V14.25ZM14.75 15V11H13.25V15H14.75ZM10 18.75H14V17.25H10V18.75ZM13.25 18V23H14.75V18H13.25ZM14 22.25H10V23.75H14V22.25ZM10.75 23V18H9.25V23H10.75ZM1 8.75H23V7.25H1V8.75ZM1 23.75H23V22.25H1V23.75ZM1.38155 8.64569L12.3815 2.14569L11.6185 0.854305L0.618453 7.35431L1.38155 8.64569ZM11.6185 2.14569L22.6185 8.64569L23.3815 7.35431L12.3815 0.854305L11.6185 2.14569Z"
          fill="#651FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_20276">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default House;
