import { notification, Popover, Select, Tooltip } from 'antd';
import React from 'react';

import Close from '../../../../../foundation/assets/svgs/Close';
import useBreakpoint from '../../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import { postSuburbMetrics } from '../../../redux/async_thunks';
import {
  selectOptions,
  selectSuburbMetricsLegend,
  selectSuburbMetricsValues,
  selectSuburbSelectedMetricsOptions,
} from '../../../redux/selectors';
import {
  resetSuburbMetrics,
  setIsHeatMapActive,
  setIsSearchFeatureLoading,
  setSelectedSuburbMetricsOptions,
} from '../../../redux/slice';

const MetricsForm = ({
  setIsPolygonToolEnabled,
  selectToolClickHandler,
  resetMapClickHandler,
  closeHeatMapClickHandler,
  getUniqueSuburbSSCCodesForSelectedArea,
  isPolygonToolEnabled,
  setIsDeleteAllPolygon,
  hasPolygonUpdate,
  setHasPolygonUpdate,
}: any) => {
  const dispatch = useAppDispatch();

  const [isMobileView] = useBreakpoint();

  const suburbMetricValues = useAppSelector(selectSuburbMetricsValues);
  const selectedMetricOptions: any = useAppSelector(
    selectSuburbSelectedMetricsOptions,
  );
  const suburbMetricsLegend = useAppSelector(selectSuburbMetricsLegend);

  const USER = useAppSelector(selectUser);
  const CLIENT_IP = useAppSelector(selectClientIp);

  const OPTIONS = useAppSelector(selectOptions);

  const getSuburbsMetricsData = async () => {
    try {
      dispatch(setIsSearchFeatureLoading(true));

      const sscCodes = getUniqueSuburbSSCCodesForSelectedArea();

      if (
        USER &&
        sscCodes &&
        selectedMetricOptions &&
        selectedMetricOptions.metricType &&
        selectedMetricOptions.metricMonthDate
      ) {
        dispatch(resetSuburbMetrics());

        await dispatch(
          postSuburbMetrics({
            data: {
              monthEndDate: selectedMetricOptions.metricMonthDate,
              metricType:
                selectedMetricOptions.metricType || metricTypeOptions[0].value,
              propertyType: OPTIONS.propertyType,
              userId: USER.userId,
              sscCodes: sscCodes,
            },
            options: {
              token: USER.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: USER.sessionId,
            },
          }), // @ts-ignore
        ).unwrap();

        setIsPolygonToolEnabled(false);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong with the request.',
        placement: 'topRight',
        top: 100,
        closeIcon: (
          <strong className="l-search-main__close">
            <Close />
          </strong>
        ),
      });
      dispatch(setIsHeatMapActive(false));
    }

    dispatch(setIsSearchFeatureLoading(false));
  };

  const monthEndDateOptions = suburbMetricValues.monthEndDate.map((option) => {
    return {
      label: option.key,
      value: option.value,
    };
  });

  const metricTypeOptions = suburbMetricValues.metricType.map((option) => {
    return {
      label: option.key,
      value: option.value,
    };
  });

  const metricMonthDateChangeHandler = (v: string) =>
    dispatch(
      // @ts-ignore
      setSelectedSuburbMetricsOptions({
        ...selectedMetricOptions,
        metricMonthDate: v,
      }),
    );

  const metricTypeChangeHandler = (v: string) =>
    dispatch(
      // @ts-ignore
      // setSelectedSuburbMetricsOptions({
      //   ...selectedMetricOptions,
      //   metricType: v,
      // }),

      setSelectedSuburbMetricsOptions({
        metricMonthDate: monthEndDateOptions[0].value,
        metricType: v,
      }),
    );

  const undoHandler = () => {
    // Restart the polygon selection
    setIsDeleteAllPolygon(true);
  };

  const renderHint = () => {
    return (
      <>
        <div className="l-metrics-form__hint">
          <p>Tap on the map to add points & create a polygon selection.</p>
          <p>Tap on the first point added to complete.</p>
        </div>
        <button
          className="l-metrics-form__button l-metrics-form__button--full"
          onClick={undoHandler}
        >
          Undo
        </button>
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="l-metrics-form__form-body">
        <label className="l-metrics-form__label">Value At</label>
        <Select
          disabled
          className="l-metrics-form__select"
          options={monthEndDateOptions}
          value={
            selectedMetricOptions ? selectedMetricOptions.metricMonthDate : ''
          }
          onChange={metricMonthDateChangeHandler}
        />
        <label className="l-metrics-form__label">Selected Metric</label>
        <Select
          className="l-metrics-form__select"
          options={metricTypeOptions}
          value={selectedMetricOptions ? selectedMetricOptions.metricType : ''}
          onChange={metricTypeChangeHandler}
        />
        {isMobileView && (
          <div className="l-metrics-form__button-group">
            <button
              className="l-metrics-form__button l-metrics-form__button--full"
              onClick={() => {
                undoHandler();
                setHasPolygonUpdate(false);
              }}
              type="submit"
            >
              Re-select
            </button>
            <button
              className="l-metrics-form__button l-metrics-form__button--submit"
              onClick={getSuburbsMetricsData}
              type="submit"
            >
              Submit
            </button>
          </div>
        )}
        {!isMobileView && (
          <button
            className="l-metrics-form__submit"
            onClick={getSuburbsMetricsData}
            type="submit"
          >
            Submit
          </button>
        )}
      </div>
    );
  };

  const renderMetricsLegend = () => {
    return (
      <>
        {suburbMetricsLegend && (
          <div className="l-metrics-form__legend">
            <p className="l-metrics-form__text" style={{ marginTop: 0 }}>
              Metric Value
            </p>
            <ul className="l-metrics-form__menu-metric-range-colors">
              {suburbMetricsLegend.metricColors.map((c: any, i: number) => {
                const labelStyle = {
                  opacity: 0.6,
                  marginRight: '8px',
                };

                const wrapStyle = {
                  display: 'flex',
                  justifyContent: 'space-between',
                };

                return (
                  <li key={i}>
                    <Tooltip
                      title={
                        <span
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span style={wrapStyle}>
                            <span style={labelStyle}>From</span>
                            {c.lowerValue}
                          </span>
                          <span style={wrapStyle}>
                            <span style={labelStyle}>To</span>
                            {c.upperValue}
                          </span>
                        </span>
                      }
                    >
                      <span
                        style={{ backgroundColor: c.hexColor }}
                        className={`${
                          c.hexColor === '#FFFFFF' ? 'middle' : ''
                        }`}
                      ></span>
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
            <div className="l-metrics-form__menu-metric-range-wrapper">
              <div className="l-metrics-form__menu-metric-range-value">
                <p className="l-metrics-form__text">
                  {suburbMetricsLegend.metricMin}
                </p>
              </div>
              <div className="l-metrics-form__menu-metric-range-value">
                <p className="l-metrics-form__text">
                  {suburbMetricsLegend.metricMax}
                </p>
              </div>
            </div>
            {isMobileView && (
              <button
                className="l-metrics-form__button l-metrics-form__button--full"
                onClick={() => {
                  dispatch(resetSuburbMetrics());
                  setIsDeleteAllPolygon(true);
                  setHasPolygonUpdate(false);
                  setIsPolygonToolEnabled(true);
                }}
              >
                Reset
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  if (isMobileView) {
    return (
      <div className="l-metrics-form l-metrics-form--mobile">
        <div className="l-metrics-form__actions">
          <button
            type="button"
            className="l-metrics-form__action-button"
            onClick={closeHeatMapClickHandler}
          >
            <span>Exit Heatmap Tool</span>
            <Close color="#651fff" />
          </button>
        </div>
        {!suburbMetricsLegend && (
          <>
            {!hasPolygonUpdate && renderHint()}
            {hasPolygonUpdate && renderForm()}
          </>
        )}
        {renderMetricsLegend()}
      </div>
    );
  }

  return (
    <div className="l-metrics-form">
      <div className="l-metrics-form__heading">
        <strong>Heatmap</strong>
        <button type="button" onClick={closeHeatMapClickHandler}>
          <Close />
        </button>
      </div>
      <div className="l-metrics-form__form">
        <div className="l-metrics-form__form-header">
          <Popover
            content={
              <p className="l-descriptor">
                <span>
                  Click anywhere on the map to add points & create a polygon
                  selection.
                </span>
                <span style={{ marginTop: '7px', display: 'block' }}>
                  Click on an existing point or press enter to complete.
                </span>
              </p>
            }
            title={null}
            placement="left"
            trigger="hover"
            overlayClassName="no-override"
          >
            <button
              className={`l-metrics-form__button${
                isPolygonToolEnabled ? ' l-metrics-form__button--cancel' : ''
              }`}
              onClick={selectToolClickHandler}
            >
              {`${isPolygonToolEnabled ? 'Disable' : 'Enable'} Select Tool`}
            </button>
          </Popover>
          <button
            className="l-metrics-form__button"
            onClick={resetMapClickHandler}
          >
            Reset
          </button>
        </div>
        {renderForm()}
      </div>
      {renderMetricsLegend()}
    </div>
  );
};

export default MetricsForm;
