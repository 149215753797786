import React from 'react';

function Email() {
  return (
    <svg
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3C1 1.89543 1.89543 1 3 1H23C24.1046 1 25 1.89543 25 3V16C25 17.1046 24.1046 18 23 18H3C1.89543 18 1 17.1046 1 16V3Z"
        stroke="#651fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.42131 2.30287C1.91709 1.84067 2.24409 1 2.9281 1H23.0719C23.7559 1 24.0829 1.84067 23.5787 2.30287L15.0272 10.1418C13.8802 11.1931 12.1198 11.1931 10.9728 10.1418L2.42131 2.30287Z"
        stroke="#651fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Email;
