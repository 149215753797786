import { createAsyncThunk } from '@reduxjs/toolkit';
import { forEach } from 'lodash';

import {
  authEndpoint,
  endpoint,
} from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

export const postReport = createAsyncThunk(
  'suburb-report/base',
  async (apiParams: { data: any; options: any }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const requestKey = getClientID(
      apiParams.options.clientIp,
      apiParams.options.timeStamp.toString(),
    );

    const data = {
      ...apiParams.data,
      requestKey: encryptData(requestKey),
    };

    const encrypted = encryptData(data);

    const headers = {
      clientid: clientId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await authEndpoint
      .post(`${env_constants.RHOMEO_API}/Report`, encrypted, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data) {
          const decrypted = decryptData(response.data);

          result = {
            ...decrypted,
            clientIp: apiParams.options.clientIp,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const postReportDetail = createAsyncThunk(
  'suburb-report/detail',
  async (apiParams: { data: any; options: any; endpoint: string }) => {
    let result;

    const clientId = getClientID(apiParams.options.clientIp);

    const encrypted = encryptData(apiParams.data);

    const headers = {
      Authorization: `Bearer ${apiParams.options.token}`,
      clientid: clientId,
      sessionid: apiParams.options.sessionId,
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    await endpoint
      .post(
        `${env_constants.RHOMEO_API}/Report/${apiParams.endpoint}`,
        encrypted,
        {
          headers: headers,
        },
      )
      .then((response) => {
        let key: any;

        switch (apiParams.endpoint) {
          case 'graph':
            key = 'graphs';
            break;
          case 'graphs':
          case 'predictions':
            key = 'allGraphs';
            break;
          case 'projects':
            key = 'suburbProjects';
            break;
          default:
            key = apiParams.endpoint;
        }

        result = {
          data: response.data ? decryptData(response.data)[key] : undefined,
          endpoint: apiParams.endpoint,
        };

        if (apiParams.endpoint === 'graph') {
          result = {
            ...result,
            graphType: apiParams.data.graphType,
          };
        }

        if (
          apiParams.endpoint === 'graphs' ||
          apiParams.endpoint === 'predictions'
        ) {
          const graphResults: any = {};

          forEach(result.data, (data) => {
            graphResults[data.graphType] = {
              graphs: data.graphs,
            };
          });

          result = {
            ...result,
            data: graphResults,
          };
        }

        if (
          apiParams.endpoint === 'projects' &&
          apiParams.data.pageIndex !== 1
        ) {
          result.data = {
            projects: result.data.projects,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
