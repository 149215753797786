import React, { useState } from 'react';

import { useAppSelector } from '../../../../../store/hooks';
import { selectActiveSuburb } from '../../../redux/selectors';
import { selectGrowth } from '../redux/selectors';
import Graph from './graph/Graph';
import Growth from './growth/Growth';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const KeyIndicators = ({ setAreDetailsLoading }: Props) => {
  const suburb = useAppSelector(selectActiveSuburb);
  const growth = useAppSelector(selectGrowth);

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <View
      setAreDetailsLoading={setAreDetailsLoading}
      suburb={suburb}
      growth={growth}
      isFirstLoad={isFirstLoad}
      setIsFirstLoad={setIsFirstLoad}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      Graph={Graph}
      Growth={Growth}
    />
  );
};

export default KeyIndicators;
