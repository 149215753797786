import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, notification } from 'antd';
import { Divider } from 'antd';
import React, { useState } from 'react';

import envConstants from '../../../internals/env/env_constants.json';
import { SubscriptionPlan } from '../../user/redux/types';
import {
  useAdditionUsersLabel,
  useFrequencyString,
} from '../helpers/custom_hooks/stringHooks';
import { usePlanRate } from '../helpers/custom_hooks/usePlanRate';
import { getParsedValue } from '../helpers/getParsedValue';
import {
  BillingTabKeys,
  InvoiceType,
  SubscriptionFrequency,
} from '../helpers/types';

type ReviewTypes = {
  subscriptionFrequency: SubscriptionFrequency;
  selectedPlan: SubscriptionPlan | undefined;
  additionalUsers: number;
  isBillingLoading: boolean;
  setIsBillingLoading: (v: boolean) => void;
  setActiveTab: (v: BillingTabKeys) => void;
  invoiceData: InvoiceType | undefined;
};

const Review = ({
  selectedPlan,
  subscriptionFrequency,
  additionalUsers,
  invoiceData,
}: ReviewTypes) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isPaymentInProgress, setPaymentProgress] = useState(false);

  const planRate = usePlanRate(selectedPlan, subscriptionFrequency);

  const frequencyString = useFrequencyString(subscriptionFrequency);

  const additionalUsersLabel = useAdditionUsersLabel(additionalUsers);

  const handleSubmission = async () => {
    if (isPaymentInProgress) {
      return;
    }

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setPaymentProgress(true);
      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${envConstants.APP_URL}/success`,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        notification.error({
          message: 'Error!',
          description: result.error.message,
        });

        setPaymentProgress(false);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.

        setPaymentProgress(false);
      }
    } catch (error) {
      console.log(error);

      setPaymentProgress(false);
    }
  };

  return (
    <div className="l-billing-summary">
      <div className="l-billing-summary__plan-name">
        {selectedPlan?.subscriptionPlan}
      </div>

      <Divider />

      <div className="l-billing-summary__items-wrapper">
        <div className="l-billing-summary__item-wrapper">
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-name">
              {selectedPlan?.subscriptionPlan}
            </div>
            <div className="l-billing-summary__item-feature">
              1 x seat included
            </div>
          </div>
          <div className="l-billing-summary__item-details">
            <div className="l-billing-summary__item-price">
              ${planRate && getParsedValue(planRate?.subscriptionAmount)}
              {`/${frequencyString}`}
            </div>
          </div>
        </div>
        {additionalUsers > 0 && (
          <div className="l-billing-summary__item-wrapper">
            <div className="l-billing-summary__item-details">
              <div className="l-billing-summary__item-name">
                Additional seats
              </div>
              <div className="l-billing-summary__item-feature">
                {additionalUsersLabel}
              </div>
            </div>
            <div className="l-billing-summary__item-details">
              <div className="l-billing-summary__item-price">
                $
                {planRate &&
                  getParsedValue(
                    additionalUsers * planRate.additionalUserAmount,
                  )}
                {`/${frequencyString}`}
              </div>
            </div>
          </div>
        )}
      </div>
      <Divider />

      <div className="l-billing-summary__total-section">
        <div className="l-billing-summary__account">
          {`Subtotal (incl. tax)`}
        </div>
        <div className="l-billing-summary__amount">
          ${getParsedValue(`${invoiceData?.subTotal}`)}
          {`/${frequencyString}`}
        </div>
      </div>

      {invoiceData && invoiceData.discountCode && (
        <div className="l-billing-summary__total-section">
          <div className="l-billing-summary__account">
            {`Discount (${invoiceData?.discountCode})`}
          </div>
          <div className="l-billing-summary__amount">
            ${getParsedValue(`${invoiceData?.discountAmount}`)}
          </div>
        </div>
      )}

      <div className="l-billing-summary__total-section">
        <div className="l-billing-summary__account">Total due today</div>
        <div className="l-billing-summary__amount">
          ${getParsedValue(`${invoiceData?.total}`)}
        </div>
      </div>

      <Button
        className="l-billing-summary__submit-btn"
        type="primary"
        style={{ marginBottom: 40 }}
        onClick={handleSubmission}
        loading={isPaymentInProgress}
        disabled={isPaymentInProgress}
      >
        Complete Purchase
      </Button>

      <div>
        Depending on the selected billing cycle, your subscription will be
        automatically renewed either monthly or annually
      </div>
    </div>
  );
};

export default Review;
