import React from 'react';

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#651FFF"
        strokeWidth="1.5"
        d="M9.511 3.28l3.592 3.592m-1.77-5.413s1.649-.75 3.085.686c1.437 1.437.686 3.086.686 3.086l-9.6 9.6-4.285.513.514-4.285 9.6-9.6z"
      ></path>
    </svg>
  );
}

export default EditIcon;
