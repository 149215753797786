import { Col, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import Info from '../../../../../../../foundation/assets/svgs/Info';
import DisclaimerLink from '../../../../../../../foundation/components/static_content/disclaimer_link/DisclaimerLink';
import { useAppDispatch } from '../../../../../../../store/hooks';
import SearchLoader from '../../../../../form/search/search_loader/SearchLoader';

const View = ({
  user,
  suburb,
  keyIndicators,
  graphDisplaySettings,
  timelineType,
  fetchGraphData,
  fetchGraphsData,
  descriptor,
  setTimelineType,
  isLoading,
  LineChart,
}: any) => {
  const dispatch = useAppDispatch();

  const [supplyDemandRatioTrendType, setSupplyDemandRatioTrendType] =
    useState<string>('3');

  const [rentalVacancyRatioTrendType, setRentalVacancyRatioTrendType] =
    useState<string>('3');

  const [medianWeeklyAskingRentType, setMedianWeeklyAskingRentType] =
    useState<string>('3');

  // Removed the following graphs from the graph type options
  // (StockMarketVolumeSales, LgaWeightedInvCapita, SuburbTotalWeightedInvesmentPerCapita,
  // MedianSalesPricePrediction, MedianWeeklyAskingRentPrediction)

  // Displaying graph types in preferred order
  const graphTypes = [
    {
      key: 'Supply & Demand Indicators',
      value: 'SupplyDemandIndicator',
    },
    {
      key: 'Demand To Supply Ratio',
      value: 'SupplyDemandRatio',
    },
    {
      key: 'Median Sales Price',
      value: 'MedianSalePrice',
    },
    {
      key: 'Rental Vacancy Ratio',
      value: 'RentalVacancyRatio',
    },
    {
      key: 'Volume of Rental Listings',
      value: 'VolumeRentalListings',
    },
    {
      key: 'Gross Rental Yield',
      value: 'GrossRentalYield',
    },
    {
      key: 'Median Weekly Asking Rent',
      value: 'MedianWeeklyAskingRent',
    },
    {
      key: 'Median Time On Market',
      value: 'MedianMarketTime',
    },
    {
      key: 'Vendor Discount',
      value: 'VendorDiscount',
    },
  ];

  const fromCoreLogic = [
    'SupplyDemandIndicator',
    'MedianSalePrice',
    'RentalVacancyRatio',
    'VolumeRentalListings',
    'GrossRentalYield',
    'MedianWeeklyAskingRent',
    'MedianMarketTime',
  ];

  const timelineOptions = keyIndicators
    ? keyIndicators?.timeLineTypes.map((t: any) => {
        return {
          ...t,
          value: t.value.toString(),
        };
      })
    : [];

  const trendlineOptions = keyIndicators
    ? keyIndicators?.trendLineTypes.map((tr: any) => {
        return {
          ...tr,
          value: tr.value.toString(),
        };
      })
    : [];

  const groupFetch = () => {
    if (user && timelineType && suburb && graphDisplaySettings) {
      fetchGraphsData();

      setSupplyDemandRatioTrendType('3');
      setRentalVacancyRatioTrendType('3');
      setMedianWeeklyAskingRentType('3');
    }
  };

  useEffect(() => {
    groupFetch();
  }, [suburb, timelineType]);

  if (!suburb) {
    return null;
  }

  if (!!suburb && isLoading) {
    return (
      <Col span={24}>
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <div
                style={{
                  position: 'relative',
                  minHeight: '100px',
                  width: '100%',
                }}
              >
                <SearchLoader classNames="l-search-loader--report-transparent" />
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                borderRadius: '16px',
                border: '1px solid #d9dbe9',
                padding: '16px',
                maxWidth: '430px',
                margin: '0 auto',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '9px',
                }}
              >
                Graph Timeline
              </p>
              <ul className="l-select-year">
                {timelineOptions.map((option: any, i: any) => {
                  return (
                    <li
                      className={`l-select-year__option${
                        option.value === timelineType
                          ? ' l-select-year__option--active'
                          : ''
                      }`}
                      key={i}
                      data-value={option.value}
                      onClick={(e) => {
                        dispatch(
                          setTimelineType(e.currentTarget.dataset.value),
                        );
                      }}
                    >
                      {option.value}Y
                      {/* <span className="h-text"> </span>Y
                      <span className="h-text">ears</span> */}
                    </li>
                  );
                })}
              </ul>
            </div>

            {graphTypes.map((g: any, i: number) => {
              const graphType: string = g.value;

              if (!graphDisplaySettings || !graphDisplaySettings[graphType]) {
                return null;
              }

              const graphName = g.key;

              let infoPopoverContent: any = null;

              if (descriptor) {
                infoPopoverContent = (
                  <p className="l-descriptor l-descriptor--wide">
                    {
                      descriptor[
                        `${graphType.charAt(0).toLowerCase()}${graphType.slice(
                          1,
                        )}`
                      ]
                    }
                  </p>
                );
              }

              return (
                <Col span={24} key={i}>
                  <div className="l-dashboard-card">
                    <div className="l-dashboard-card__body">
                      <div
                        className="l-dashboard-card__title"
                        style={{ marginBottom: '0', paddingBottom: '0' }}
                      >
                        <span
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {graphName}{' '}
                          {descriptor && (
                            <>
                              <Popover
                                content={infoPopoverContent}
                                title={null}
                                placement="top"
                                trigger="click"
                                arrowPointAtCenter={true}
                              >
                                <span className="l-descriptor-trigger">
                                  <Info />
                                </span>
                              </Popover>
                            </>
                          )}
                        </span>
                      </div>
                      {fromCoreLogic.includes(graphType) && (
                        <p style={{ marginBottom: '18px', fontSize: '13px' }}>
                          <DisclaimerLink />
                        </p>
                      )}
                      {g.value === 'SupplyDemandRatio' && timelineType && (
                        <ul
                          className="l-select-year"
                          style={{ marginTop: '10px' }}
                        >
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any, i: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === supplyDemandRatioTrendType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={i}
                                data-value={option.value}
                                onClick={(e) => {
                                  const val: any =
                                    e.currentTarget.dataset.value;
                                  setSupplyDemandRatioTrendType(val);
                                  fetchGraphData('SupplyDemandRatio', val);
                                }}
                              >
                                {option.value}Y
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {g.value === 'RentalVacancyRatio' && timelineType && (
                        <ul className="l-select-year">
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any, i: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === rentalVacancyRatioTrendType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={i}
                                data-value={option.value}
                                onClick={(e) => {
                                  const val: any =
                                    e.currentTarget.dataset.value;
                                  setRentalVacancyRatioTrendType(val);
                                  fetchGraphData('RentalVacancyRatio', val);
                                }}
                              >
                                {option.value}Y
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {g.value === 'MedianWeeklyAskingRent' && timelineType && (
                        <ul className="l-select-year">
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any, i: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === medianWeeklyAskingRentType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={i}
                                data-value={option.value}
                                onClick={(e) => {
                                  const val: any =
                                    e.currentTarget.dataset.value;
                                  setMedianWeeklyAskingRentType(val);
                                  fetchGraphData('MedianWeeklyAskingRent', val);
                                }}
                              >
                                {option.value}Y
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      <LineChart graphType={g.value} />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default View;
