import React from 'react';

function CarouselBack() {
  return (
    <svg
      width="19"
      height="40"
      viewBox="0 0 19 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3027 2.5L2.6665 19.9079L16.3027 37.3159"
        stroke="#FCFCFC"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CarouselBack;
