import { createSlice } from '@reduxjs/toolkit';

import { getProfileForView, postProfileFollow } from './async_thunks';
import { ChatState } from './types';

const initialState: ChatState = {
  ccUser: undefined,
  channelsList: [],
  directMessagesList: [],
  selectedConversation: undefined,
  profileBeingViewed: undefined,
  chatWindowType: 'chat-window',
  isChannelsListFetching: false,
  isConversationsListFetching: false,
  isChannelCreationModalVisible: false,
};

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCometChatUser: (state, action) => {
      state.ccUser = action.payload;
    },
    setChannelsList: (state, action) => {
      state.channelsList = action.payload;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setDirectMessagesList: (state, action) => {
      state.directMessagesList = action.payload;
    },
    setChatWindowType: (state, action) => {
      state.chatWindowType = action.payload;
    },
    resetProfileBeingViewed: (state) => {
      state.profileBeingViewed = initialState.profileBeingViewed;
    },
    setIsChannelsListFetching: (state, action) => {
      state.isChannelsListFetching = action.payload;
    },
    setIsConversationsListFetching: (state, action) => {
      state.isConversationsListFetching = action.payload;
    },
    setIsChannelCreationModalVisible: (state, action) => {
      state.isChannelCreationModalVisible = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfileForView.fulfilled, (state, action: any) => {
      state.profileBeingViewed = action.payload;
    });

    builder.addCase(getProfileForView.rejected, (state) => {
      state.profileBeingViewed = initialState.profileBeingViewed;
    });

    builder.addCase(postProfileFollow.fulfilled, (state, action: any) => {
      state.profileBeingViewed = {
        ...state.profileBeingViewed,
        ...action.payload,
      };
    });
  },
});

export const {
  setCometChatUser,
  setChannelsList,
  setDirectMessagesList,
  setSelectedConversation,
  setChatWindowType,
  resetProfileBeingViewed,
  setIsChannelsListFetching,
  setIsConversationsListFetching,
  setIsChannelCreationModalVisible,
} = chat.actions;

export default chat.reducer;
