import { Badge, Popover } from 'antd';
import React, { useState } from 'react';

import BellIcon from '../../../foundation/assets/svgs/BellIcon';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import { selectHasNotifications } from '../redux/selectors';
import { setHasNotifications } from '../redux/slice';
import Notification from './Notification';

const NotificationHeader = () => {
  const dispatch = useAppDispatch();
  const hasNotifications = useAppSelector(selectHasNotifications);

  const [isMobileView, isDesktopView] = useBreakpoint();
  const [isNotificationPopoverVisible, setNotificationPopoverVisibility] =
    useState(false);

  const handlePopoverVisibility = (v: any) => {
    const visibility = v ? v : !isNotificationPopoverVisible;

    dispatch(setHasNotifications(false));

    if (isDesktopView) {
      setNotificationPopoverVisibility(visibility);
    } else {
      const data = {
        isVisible: true,
        view: 'notifications',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Notifications',
        parentConfig: undefined as any,
      };
      dispatch(setSiderMobileContentValues(data));
    }
  };

  return (
    <>
      {isDesktopView && (
        <Popover
          content={
            <Notification handlePopoverVisibility={handlePopoverVisibility} />
          }
          arrowPointAtCenter
          overlayInnerStyle={{
            borderRadius: 10,
            boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
            border: '1px solid #D9DBE9',
            width: 370,
          }}
          showArrow={false}
          open={isNotificationPopoverVisible}
          trigger="click"
          onOpenChange={handlePopoverVisibility}
        >
          <div
            className="c-layout-header__notifications-trigger"
            onClick={handlePopoverVisibility}
          >
            <Badge dot={hasNotifications}>
              <BellIcon strokeColor={isMobileView ? 'white' : ''} />
            </Badge>
          </div>
        </Popover>
      )}
      {isMobileView && (
        <div
          className="c-layout-header__notifications-trigger"
          onClick={handlePopoverVisibility}
        >
          <Badge dot={hasNotifications}>
            <BellIcon strokeColor={isMobileView ? 'white' : ''} />
          </Badge>
        </div>
      )}
    </>
  );
};

export default NotificationHeader;
