import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Form, Input, Radio, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Logo from '../../../foundation/assets/svgs/Logo';
import { useAppDispatch } from '../../../store/hooks';
import { requestChannelApprove } from '../../chat/redux/async_thunks';
import { getClientIP } from '../../user/redux/async_thunks';

const ChannelRequest = () => {
  const dispatch = useAppDispatch();

  const loadingTip = 'Loading...';

  const [formErrorMesage, setFormErrorMessage] = useState<string>('');

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const [isChannelRequestSuccessful, setIsChannelRequestSuccessful] =
    useState<boolean>(false);

  const [isReasonRequired, setIsReasonRequired] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { TextArea } = Input;

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const errorHandler = (error?: any) => {
    if (error && error.message && error.message.includes('409')) {
      setFormErrorMessage('Request already treated');
    } else {
      setFormErrorMessage('Something went wrong with the request');
    }
  };

  const proceedChannelRequest = async (values: any) => {
    let timeStamp = 0;
    let clientIp = '';
    let isRequestFailed = false;

    setIsFormLoading(true);
    setFormErrorMessage('');

    try {
      const response = await dispatch(getClientIP())
        // @ts-ignore
        .unwrap();

      timeStamp = response.timeStamp;
      clientIp = response.ip;
    } catch (error: any) {
      errorHandler(error);
      isRequestFailed = true;
    }

    if (!query.get('lc') || isRequestFailed) {
      setIsFormLoading(false);
      errorHandler();
      return false;
    }

    try {
      await dispatch(
        requestChannelApprove({
          data: {
            isApproved: values.isApproved,
            reason: values.reason,
          },
          options: {
            clientIp: clientIp,
            linkCode: query.get('lc'),
            timeStamp: timeStamp,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
      setIsChannelRequestSuccessful(true);
      setIsFormLoading(false);
    } catch (error: any) {
      errorHandler(error);
    }

    setIsFormLoading(false);
  };

  const options = [
    {
      label: (
        <span className="l-common-form__radio-group-item">
          <span className="l-common-form__radio-group-check">
            <CheckOutlined />
          </span>
          <span className="l-common-form__radio-group-text">Approve</span>
        </span>
      ),
      value: true,
    },
    {
      label: (
        <span className="l-common-form__radio-group-item">
          <span className="l-common-form__radio-group-close">
            <CloseOutlined />
          </span>
          <span className="l-common-form__radio-group-text">Reject</span>
        </span>
      ),
      value: false,
    },
  ];

  const handleOnChange = (e: any) => {
    setIsReasonRequired(!e.target.value);
  };

  useEffect(() => {
    form.validateFields(['reason']);
  }, [isReasonRequired]);

  return (
    <>
      <div className="l-common-form-wrapper">
        <main className="l-common-form">
          <div className="l-common-form__fields">
            <div className="l-common-form__heading">
              <Logo />
              <h3>Channel Approval</h3>
            </div>
            <div className="l-common-form__form">
              <ul className="l-common-form__details">
                {query.get('cn') && (
                  <li className="l-common-form__details-item">
                    <span>Channel Name</span>
                    <span>
                      <strong>{query.get('cn')}</strong>
                    </span>
                  </li>
                )}
                {query.get('rq') && (
                  <li className="l-common-form__details-item">
                    <span>Requested By</span>
                    <span>
                      <strong>{query.get('rq')}</strong>
                    </span>
                  </li>
                )}
                {query.get('cp') && (
                  <li className="l-common-form__details-item l-common-form__details-item--column">
                    <span>Purpose</span>
                    <span>{query.get('cp')}</span>
                  </li>
                )}
              </ul>
              {isChannelRequestSuccessful && (
                <div>
                  <>
                    <Alert
                      message={`Success! Channel request is ${
                        form.getFieldValue('isApproved')
                          ? 'approved'
                          : 'rejected'
                      }`}
                      type="success"
                      showIcon
                    />
                  </>
                </div>
              )}
              {!isChannelRequestSuccessful && (
                <Spin tip={loadingTip} spinning={isFormLoading}>
                  {formErrorMesage && (
                    <>
                      <Alert message={formErrorMesage} type="error" showIcon />
                      <br />
                    </>
                  )}
                  <Form
                    className="l-common-form__inputs"
                    onFinish={proceedChannelRequest}
                    form={form}
                  >
                    <Form.Item
                      name="isApproved"
                      rules={[
                        {
                          required: true,
                          message: 'Please decide',
                        },
                      ]}
                    >
                      <Radio.Group
                        options={options}
                        optionType="button"
                        buttonStyle="outline"
                        onChange={handleOnChange}
                        className="l-common-form__radio-group"
                      />
                    </Form.Item>
                    {isReasonRequired && (
                      <Form.Item
                        name="reason"
                        rules={[
                          {
                            required: isReasonRequired,
                            message: 'Reason is required',
                          },
                        ]}
                      >
                        <TextArea
                          style={{ height: '400px' }}
                          className="l-common-form__input l-common-form__textarea"
                          rows={4}
                        />
                      </Form.Item>
                    )}
                    <Form.Item style={{ marginBottom: 0 }}>
                      <button className="l-common-form__button" type="submit">
                        Submit
                      </button>
                    </Form.Item>
                  </Form>
                </Spin>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ChannelRequest;
