import { CometChat } from '@cometchat-pro/chat';

export const fetchNextUsers = (searchKey: string) => {
  let userRequest = null;
  if (searchKey) {
    // By default it returns all users not only frieds.
    // Using .friendsOnly(true) will return friends
    userRequest = new CometChat.UsersRequestBuilder()
      .setLimit(15)
      .setSearchKeyword(searchKey)
      .build();
  } else {
    userRequest = new CometChat.UsersRequestBuilder().setLimit(15).build();
  }
  return userRequest.fetchNext();
};

export const fetchFriendlyUsers = (searchKey: string) => {
  let userRequest = null;

  if (searchKey) {
    userRequest = new CometChat.UsersRequestBuilder()
      .setLimit(15)
      .friendsOnly(true)
      .setSearchKeyword(searchKey)
      .build();
  } else {
    userRequest = new CometChat.UsersRequestBuilder()
      .setLimit(15)
      .friendsOnly(true)
      .build();
  }

  return userRequest.fetchNext();
};
