import React, { useEffect, useRef, useState } from 'react';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
// import appMessages from '../../../../foundation/messages';
import Editor from '../../editor/Editor';
import {
  sendCustomMessage,
  sendMediaMessage,
} from '../../utils/controllers/messageController';
import * as enums from '../../utils/enums';
import ChatMessage from '../chat_message/ChatMessage';
import ChatRenderer from '../chat_renderer/ChatRenderer';

type ChatThreadProps = {
  ccUser: any;
  selectedConversation: any;
  receiverType: string;
  receiverId: any;
  parentMessage: any;
  replies: any[];
  isDMConversation: boolean;
  setParentMessage: (v: any) => void;
  setReplies: (v: any[]) => void;
  updateReplies: (v: any) => void;
  setIsThreadPanelVisible: (v: boolean) => void;
  getConversationMembers: () => any;
  messageHandler: (
    currentList: any,
    parentMessageId?: any,
    isNewChat?: boolean,
  ) => Promise<any>;
  scrollToBottom: (ref?: any, scrollHeight?: number) => void;
};

const ChatThread = ({
  receiverId,
  receiverType,
  parentMessage,
  updateReplies,
  getConversationMembers,
  selectedConversation,
  ccUser,
  setIsThreadPanelVisible,
  messageHandler,
  scrollToBottom,
  replies,
  setParentMessage,
  isDMConversation,
  setReplies,
}: ChatThreadProps) => {
  const repliesEnd = useRef<HTMLDivElement>(null);

  const [isRepliesLoading, setIsRepliesLoading] = useState<boolean>(false);

  const fetchRepliesData = async (parentMessageId: string) => {
    try {
      setIsRepliesLoading(true);
      await Promise.all([messageHandler(replies, parentMessageId, true)]);
      scrollToBottom(repliesEnd);
      setIsRepliesLoading(false);
    } catch (error) {
      console.log(error);
      setIsRepliesLoading(false);
      // showNotification(
      //   'error',
      //   appMessages.chat_messages.ERROR_FETCHING_MESSAGES,
      //   'Error!',
      // );
    }
  };

  useEffect(() => {
    if (selectedConversation && parentMessage) {
      fetchRepliesData(parentMessage.id);
    }
  }, [parentMessage]);

  const sendMediaReplyHandler = (messageInput: any, messageType: string) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const message = await sendMediaMessage(
          messageInput,
          messageType,
          receiverId,
          receiverType,
          ccUser,
          parentMessage.id,
        );
        updateReplies(message);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const editorReplySendHandler = async (raw: any, html: string) => {
    const customData = {
      rawEditorState: raw,
      html,
    };

    const message = await sendCustomMessage(
      customData,
      enums.CUSTOM_TYPE_DRAFTJS,
      receiverId,
      receiverType,
      ccUser,
      parentMessage?.id,
    );

    updateReplies(message);
  };

  const handleThreadWindowClose = () => {
    setIsThreadPanelVisible(false);
    setParentMessage(undefined);
    setReplies([]);
  };

  const renderParentMessage = () => {
    const messageKey = parentMessage._id ? parentMessage._id : parentMessage.id;

    return (
      <ChatMessage
        key={messageKey}
        message={parentMessage}
        isFirstOfBatch={true}
        isDM={true}
        isSender={false}
        ccUser={ccUser}
        selectedConversation={null}
        members={getConversationMembers()}
        setIsThreadPanelVisible={setIsThreadPanelVisible}
        setParentMessage={setParentMessage}
        isReply={true}
        isHighlighted={false}
      />
    );
  };

  return (
    <section className="l-chat-thread">
      {isRepliesLoading && <FullPageLoader />}
      <div className="l-chat-thread__header">
        <div className="l-chat-thread__header-left">
          <div className="l-chat-thread__header-text">Thread</div>
          <div className="l-chat-thread__header-chat-name">
            {selectedConversation?.conversationWith?.name}
          </div>
        </div>
        <div
          onClick={handleThreadWindowClose}
          className="l-chat-thread__header-close-btn"
        >
          <Close />
        </div>
      </div>
      <div className="l-chat-thread__msg-container" ref={repliesEnd}>
        <div className="l-chat-window__thread-parent-message">
          {/* {getComponent(parentMessage, true)} */}
          {renderParentMessage()}
          <p className="l-chat-window__thread-reply-count">
            <span>
              {replies.length} {replies.length === 1 ? 'reply' : 'replies'}
            </span>
          </p>
        </div>
        <ChatRenderer
          messages={replies}
          ccUser={ccUser}
          isDMConversation={isDMConversation}
          selectedConversation={selectedConversation}
          getConversationMembers={getConversationMembers}
          setIsThreadPanelVisible={setIsThreadPanelVisible}
          setParentMessage={setParentMessage}
          isReply={true}
        />
      </div>
      <div className="l-chat-thread__msg-editor-container">
        <Editor
          editorMessageSendHandler={editorReplySendHandler}
          sendMediaMessageHandler={sendMediaReplyHandler}
          conversationMembers={getConversationMembers()}
          isReplying={true}
          selectedConversation={selectedConversation}
        />
      </div>
    </section>
  );
};

export default ChatThread;
