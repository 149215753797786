import { Button, Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import ArrowBackIcon from '../../../foundation/assets/svgs/ArrowBackIcon';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  updateSubscription,
  UpdateSubscriptionBody,
} from '../../billing/redux/async_thunks';
import { selectClientIp, selectUser } from '../../user/redux/selectors';
import { getInvoice } from '../redux/async_thunks';
import { selectAccountCurrentPlan } from '../redux/selectors';
import { UpdateOrderSteps } from '../types';
import OrderSummary from '../update_plan/order_summary/OrderSummary';
import PaymentElements from '../update_plan/payment_elements/PaymentElements';
import SeatElements from './seat_elements/SeatElements';

const UpdateSeats = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isUpdateSeatsLoading, setUpdateSeatsLoading] = useState(false);

  const [updateStep, setUpdateStep] = useState<UpdateOrderSteps>(1);

  const [invoiceDetails, setInvoiceDetails] = useState<any>(undefined);

  const [additionalSeats, setAdditionalSeats] = useState(0);

  const currentPlan = useAppSelector(selectAccountCurrentPlan);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  /**
   * If a user directly tries visiting the update-seats page we will redirect the
   * user back to the Account page.
   */
  useEffect(() => {
    if (!currentPlan) {
      navigate('/account', { replace: true });
    }

    if (currentPlan && currentPlan.additionalUsers) {
      setAdditionalSeats(currentPlan.additionalUsers);
    }
  }, [currentPlan]);

  const subscriptionFrequency = useMemo(
    () => currentPlan?.frequency,
    [currentPlan],
  );

  const onBackBtnClick = () => {
    if (updateStep === 1) {
      navigate('/account');
    } else if (updateStep === 2) {
      setInvoiceDetails(undefined);
      setUpdateStep(1);
    } else {
      setUpdateStep(2);
    }
  };

  const stepHeading = useMemo(() => {
    if (updateStep === 1 || updateStep === 2) {
      return `Add or remove seats on your plan`;
    } else {
      return `Payment information`;
    }
  }, [updateStep]);

  const stepSubHeading = useMemo(() => {
    if (updateStep === 1 || updateStep === 2) {
      return `Add or remove seats`;
    } else {
      return `Payment method`;
    }
  }, [updateStep]);

  const handleInvoice = (plan: any) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (currentPlan) {
          const options = {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          };

          const data: any = {
            userId: user.userId,
            subscriptionId: currentPlan.subscriptionId,
            subscriptionPlanTypeId: plan.subscriptionPlanId,
            subscriptionFrequency: subscriptionFrequency,
            additionalUsers: additionalSeats,
          };

          const invoice = await dispatch(
            getInvoice({ data, options }),
          ).unwrap();

          setInvoiceDetails(invoice);

          resolve(invoice);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSubscription = async () => {
    if (isUpdateSeatsLoading) {
      return;
    }
    try {
      if (currentPlan) {
        setUpdateSeatsLoading(true);
        const data: UpdateSubscriptionBody = {
          userId: user.userId,
          subscriptionId: currentPlan?.subscriptionId,
          subscriptionPlanTypeId: currentPlan?.subscriptionPlanId,
          subscriptionFrequency: currentPlan?.frequency,
          additionalUsers: additionalSeats,
        };

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        const subscription = await dispatch(
          updateSubscription({ data, options }),
        ).unwrap();

        console.log(subscription);
        setUpdateSeatsLoading(false);

        navigate('/success', {
          state: {
            update: true,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setUpdateSeatsLoading(false);
    }
  };

  return (
    <div className="l-update-plan">
      {isUpdateSeatsLoading && <FullPageLoader />}
      <Button
        className="l-update-plan__back-btn"
        icon={<ArrowBackIcon />}
        onClick={onBackBtnClick}
      >
        Back
      </Button>
      <div className="l-update-plan__heading">{stepHeading}</div>

      <Divider />

      <div className="l-update-plan__sub-heading">{stepSubHeading}</div>

      <div className="l-update-plan__content-container">
        {/* Show seat elements only on step 1 and 2 */}
        {updateStep === 1 || updateStep === 2 ? (
          <SeatElements
            subscriptionFrequency={subscriptionFrequency}
            additionalSeats={additionalSeats}
            setAdditionalSeats={setAdditionalSeats}
            minSeatsAllowed={
              currentPlan && currentPlan.additionalUsers
                ? currentPlan.additionalUsers
                : 0
            }
            updateStep={updateStep}
          />
        ) : null}

        {/* Show payment elements only on step 3 */}
        {updateStep === 3 && <PaymentElements />}

        <div className="l-update-plan__content-section">
          <OrderSummary
            selectedPlan={currentPlan}
            invoice={invoiceDetails}
            handleInvoice={handleInvoice}
            setUpdateStep={setUpdateStep}
            updateStep={updateStep}
            handleSubscription={handleSubscription}
            upgradeType="seats-update"
            additionalSeats={additionalSeats}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateSeats;
