import React from 'react';

import Template from '../template/Template';

const Terms = ({ privacyPolicyCallback }: { privacyPolicyCallback: any }) => {
  return (
    <Template title="Terms and Conditions">
      <article>
        <h5 className="h-center">
          Rhomeo transforms the way the world invests
        </h5>
        <h4>
          <span>1</span>Our Disclosures
        </h4>
        <div className="h-box">
          <p>
            Our complete terms and conditions are contained below, but some
            important points for you to know before you become a customer are
            set out below:
          </p>
          <ul>
            <li>
              We may amend these Terms, the features of the Platform at any
              time, by providing written notice to you;
            </li>
            <li>
              Your Account can be suspended or terminated in accordance with
              these Terms;
            </li>
            <li>
              We will handle your personal information in accordance with our
              privacy policy, available{' '}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (privacyPolicyCallback) {
                    privacyPolicyCallback();
                  }
                }}
              >
                here
              </a>
              .
            </li>
            <li>
              To the maximum extent permitted by law, we will not be liable for
              Consequential Loss, any loss that is a result of a Third Party
              Service, or any loss or corruption of data;
            </li>
            <li>
              To the maximum extent permitted by law, our Liability under these
              Terms is limited to us resupplying the Platform to you; and
            </li>
            <li>
              We may terminate your Account at any time by giving 30 days’
              written notice to you.
            </li>
          </ul>
          <p className="h-no-margin">
            Nothing in these terms limit your rights under the Australian
            Consumer Law.
          </p>
        </div>
        <h4>
          <span>2</span>Introduction
        </h4>
        <p className="h-paragraph">
          <span>2.1</span>These terms and conditions (Terms) are entered into
          between Dashdot Pty Ltd ABN 83 631 524 797 (we, us or our) and you,
          together the Parties and each a Party.{' '}
        </p>
        <p className="h-paragraph">
          <span>2.2</span>In these Terms, you means the person or entity
          registered with us as an Account holder.
        </p>
        <p className="h-paragraph">
          <span>2.3</span>We provide a cloud-based, software as a service
          platform (Platform) where:
          <ol>
            <li>
              You can access and view information and data about different
              suburbs in Australia. The information includes or is based on:
              <ol className="roman">
                <li>Census data; and</li>
                <li>
                  Data obtained from third parties, including but not limited to
                  CoreLogic.
                </li>
              </ol>
            </li>
            <li>
              You can communicate with other Account holders in or regarding
              specific suburbs, via a chat function (or similar).
            </li>
          </ol>
        </p>

        <h4>
          <span>3</span>Accounts
        </h4>
        <p className="h-paragraph">
          <span>3.1</span>You must register on the Platform and create an
          account (Account) to access the Platform’s features.
        </p>
        <p className="h-paragraph">
          <span>3.2</span>You must provide basic information when registering
          for an Account including your name and email address and you must
          choose a username and password.
        </p>
        <p className="h-paragraph">
          <span>3.3</span>You may also register for an Account using your
          Facebook, Google or other social media network account (Social Media
          Account). If you sign in to your Account using your Social Media
          Account, you authorise us to access certain information on your Social
          Media Account including but not limited to your current profile photo
          and other basic information.
        </p>
        <p className="h-paragraph">
          <span>3.4</span>All personal information you provide to us will be
          treated in accordance with our Privacy Policy.
        </p>
        <p className="h-paragraph">
          <span>3.5</span>You agree to provide and maintain up to date
          information in your Account and to not share your Account password
          with any other person. Your Account is personal and you must not
          transfer or provide it to others.
        </p>
        <p className="h-paragraph">
          <span>3.6</span>You are responsible for keeping your Account details
          and your username and password confidential and you will be liable for
          all activity on your Account, made using your Account details. You
          agree to immediately notify us of any unauthorised use of your
          Account.
        </p>
        <h4>
          <span>4</span>Acceptance and Platform Licence
        </h4>
        <p className="h-paragraph">
          <span>4.1</span>You accept these Terms by registering on the Platform.
        </p>
        <p className="h-paragraph">
          <span>4.2</span>You must be at least 16 years old to use the Platform.
        </p>
        <p className="h-paragraph">
          <span>4.3</span>We may amend these Terms at any time, by providing
          written notice to you. By clicking “I accept” or continuing to use the
          Platform after the notice or 30 days after notification (whichever
          date is earlier), you agree to the amended Terms. If you do not agree
          to the amendment, you may terminate your Account in accordance with
          the “Cancellation of Accounts” clause 14.1.
        </p>
        <p className="h-paragraph">
          <span>4.4</span>We use CoreLogic services, including CoreLogic API(s).
          Your use of CoreLogic services is subject to CoreLogic&lsquo;s End
          User Terms which can be found at{' '}
          <a
            href="https://www.corelogic.com.au/legals/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            https://www.corelogic.com.au/legals/terms-conditions
          </a>{' '}
          and subject to the Disclaimer provided at clause 7 below. For the
          abundance of clarity, a breach by you of those terms in accessing the
          Platform is a breach of these terms.
        </p>
        <p className="h-paragraph">
          <span>4.5</span>We may use Mapbox. Your use of Mapbox is subject to
          the Mapbox Additional Terms of Service. For the abundance of clarity,
          a breach by you of those terms in accessing the Platform is a breach
          of these terms.
        </p>
        <p className="h-paragraph">
          <span>4.6</span>Subject to your compliance with these Terms, we grant
          you a personal, non-exclusive, royalty-free, revocable, worldwide,
          non-transferable licence to use our Platform in accordance with these
          Terms. All other uses are prohibited without our prior written
          consent.
        </p>
        <p className="h-paragraph">
          <span>4.7</span>When using the Platform, you must not do or attempt to
          do anything that is unlawful or inappropriate, including:
          <ol>
            <li>
              anything that would constitute a breach of an individual&lsquo;s
              privacy (including uploading private or personal information
              without an individual&lsquo;s consent) or any other legal rights;
            </li>
            <li>
              using the Platform to defame, harass, threaten, menace or offend
              any person, including using the Platform to send unsolicited
              electronic messages;
            </li>
            <li>
              publishing, including via the chat function (or similar), any
              images or information that is:
              <ol>
                <li>
                  misleading or deceptive, or is likely to mislead or deceive;
                </li>
                <li>offensive or likely to cause offence;</li>
                <li>
                  any material which is or is likely to promote your business
                  for commercial purposes; or
                </li>
                <li>
                  any material which is or is likely to defame any person; or
                </li>
                <li>
                  any material which is or is likely to be considered illegal;
                  or
                </li>
                <li>
                  any material which breaches or is likely to breach any third
                  party intellectual property rights.
                </li>
              </ol>
            </li>
            <li>
              tampering with or modifying the Platform (including by
              transmitting viruses and using trojan horses);
            </li>
            <li>reverse engineering the Platform or any function thereof;</li>
            <li>
              using data mining, robots, screen scraping or similar data
              gathering and extraction tools on the Platform; or
            </li>
            <li>
              facilitating or assisting a third party to do any of the above
              acts.
            </li>
          </ol>
        </p>

        <h4>
          <span>5</span>Rhomeo Services
        </h4>
        <p className="h-paragraph">
          <span>5.1</span>Once you register an Account, we agree to provide you
          with access to the Platform, the support services as detailed in this
          section, and any other services we agree to provide as set out in your
          Account.
        </p>
        <p className="h-paragraph">
          <span>5.2</span>We agree to use our best endeavours to make the
          Platform available at all times. However, from time to time we may
          perform reasonable scheduled and emergency maintenance, and the
          Platform may be unavailable during the times we are performing such
          maintenance.
        </p>
        <p className="h-paragraph">
          <span>5.3</span>Should you be unable to access the Platform, or should
          you have any other questions or issues impacting on your use and
          enjoyment of the Platform, you must place a request via the
          &lsquo;User Feedback&rsquo; on the Platform, over the phone, via email
          etc. We will endeavour to respond to any support requests in a
          reasonable period, but make no warranties or assurances in that
          regard.
        </p>
        <p className="h-paragraph">
          <span>5.4</span>You acknowledge and agree that data loss is an
          unavoidable risk when using any software. To the extent you input any
          data into the Platform, you agree to maintain a backup copy of any
          data you input into the Platform.
        </p>
        <p className="h-paragraph">
          <span>5.5</span>To the maximum extent permitted by law, we shall have
          no Liability to you for any loss or corruption of data, or any
          scheduled or emergency maintenance that causes the Platform to be
          unavailable.
        </p>
        <h4>
          <span>6</span>Third Party Services
        </h4>
        <p className="h-paragraph">
          <span>6.1</span>You acknowledge and agree that the Platform may be
          reliant on, or interface with third party systems that are not
          provided by us for example, cloud storage providers, CRM systems, data
          providers and internet providers (Third Party Services). To the
          maximum extent permitted by law, we shall have no Liability for any
          Third Party Services, or any unavailability of the Platform due to a
          failure of the Third Party Services.
        </p>
        <p className="h-paragraph">
          <span>6.2</span>You acknowledge when you use our Platform, you are
          responsible for:
          <ol>
            <li>complying with the requirements of; and</li>
            <li>complying with the applicable licensing obligations of;</li>
          </ol>
          any such Third Party Service.
        </p>
        <p className="h-paragraph">
          <span>6.3</span>You agree that the benefit of the Third Party
          Service’s integration, interface, or interoperation with, the
          Services, is subject to your compliance with clause 6.2.
        </p>

        <h4>
          <span>7</span>CoreLogic General Disclaimer
        </h4>
        <p>
          Online Use: all relevant disclaimers may be housed on a separate link
          house on a separate page or footer housed on the same page as which
          the data is being displayed (Disclaimers). The CoreLogic Data provided
          in this Platform is of a general nature and should not be construed as
          specific advice or relied upon in lieu of appropriate professional
          advice. While CoreLogic uses commercially reasonable efforts to ensure
          the CoreLogic Data is current, CoreLogic does not warrant the
          accuracy, currency or completeness of the CoreLogic Data and to the
          full extent permitted by law excludes all loss or damage howsoever
          arising (including through negligence) in connection with the
          CoreLogic Data.
        </p>
        <h4>
          <span>8</span>CoreLogic Copyright Notice
        </h4>
        <p>
          This publication reproduces materials and content owned or licenced by
          RP Data Pty Ltd trading as CoreLogic Asia Pacific (CoreLogic) and may
          include data, statistics, estimates, indices, photographs, maps,
          tools, calculators (including their outputs), commentary, reports and
          other information (CoreLogic Data).
        </p>
        <p>
          © Copyright 2018. CoreLogic and its licensors are the sole and
          exclusive owners of all rights, title and interest (including
          intellectual property rights) the CoreLogic Data contained in this
          publication. All rights reserved.
        </p>
        <h4>
          <span>9</span>Our Intellectual Property
        </h4>
        <p className="h-paragraph">
          <span>9.1</span>You acknowledge and agree that any Intellectual
          Property or content (including copyright and trademarks) available on
          the Platform, the Platform itself, and any algorithms or machine
          learning models used on the Platform (Our Intellectual Property) will
          at all times vest, or remain vested, in us.
        </p>
        <p className="h-paragraph">
          <span>9.2</span>We authorise you to use Our Intellectual Property
          solely for your personal use. You must not exploit Our Intellectual
          Property for any other purpose, nor allow, aid or facilitate such use
          by any third party. You may only access Our Intellectual Property on
          your personal device, and you may not use Our Intellectual Property
          for any commercial purpose.
        </p>
        <p className="h-paragraph">
          <span>9.3</span>You must not, without our prior written consent:
          <ol>
            <li>
              copy, in whole or in part, any of Our Intellectual Property;
            </li>
            <li>
              reproduce, retransmit, distribute, disseminate, sell, publish,
              broadcast or circulate any of Our Intellectual Property to any
              third party; or
            </li>
            <li>
              breach any intellectual property rights connected with the
              Platform, including (without limitation) altering or modifying any
              of Our Intellectual Property, causing any of Our Intellectual
              Property to be framed or embedded in another website, or creating
              derivative works from any of Our Intellectual Property.
            </li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>9.4</span>Nothing in the above clause restricts your ability to
          publish, post or repost Our Intellectual Property on your social media
          page or blog, provided that:
          <ol>
            <li>
              you do not assert that you are the owner of Our Intellectual
              Property;
            </li>
            <li>
              unless explicitly agreed by us in writing, you do not assert that
              you are endorsed or approved by us;
            </li>
            <li>
              you do not damage or take advantage of our reputation, including
              in a manner that is illegal, unfair, misleading or deceptive; and
            </li>
            <li>you comply with all other terms of these Terms.</li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>9.5</span> This clause will survive the termination of your
          Account.
        </p>
        <h4>
          <span>10</span>Your Data
        </h4>
        <p className="h-paragraph">
          <span>10.1</span>You own all data, information or content you upload
          into the Platform (Your Data), as well as any data or information
          output from the Platform using Your Data as input (Output Data). Note
          that Output Data does not include the Analytics (as described below).
          In the event you upload any third-party Data or information, you
          warrant you have all relevant permissions to so upload and provide to
          us the Licence below in relation to such data, such that any such data
          shall be considered Your Data for the purpose of these terms.
        </p>
        <p className="h-paragraph">
          <span>10.2</span>You grant us a limited licence to copy, transmit,
          store, backup and/or otherwise access or use Your Data and the Output
          Data to:
          <ol>
            <li>
              communicate with you (including to send you information we believe
              may be of interest to you);
            </li>
            <li>
              supply the Platform to you and otherwise perform our obligations
              under these Terms;
            </li>
            <li>diagnose problems with the Platform;</li>
            <li>enhance and otherwise modify the Platform;</li>
            <li>perform Analytics;</li>
            <li>
              develop other services, provided we de-identify Your Data; and
            </li>
            <li>
              as reasonably required to perform our obligations under these
              Terms.
            </li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>10.3</span>You agree that you are solely responsible for all of
          Your Data that you make available on or through the Platform. You
          represent and warrant that:
          <ol>
            <li>
              you are either the sole and exclusive owner of Your Data or you
              have all rights, licences, consents and releases that are
              necessary to grant to us the rights in Your Data (as contemplated
              by these Terms); and
            </li>
            <li>
              neither Your Data nor the posting, uploading, publication,
              submission or transmission of Your Data or our use of Your Data
              on, through or by means of our Platform will infringe,
              misappropriate or violate a third party’s intellectual property
              rights, or rights of publicity or privacy, or result in the
              violation of any applicable law or regulation.
            </li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>10.4</span>You acknowledge and agree that we may monitor,
          analyse and compile statistical and performance information based on
          and/or related to your use of the Platform, in an aggregated and
          anonymised format (Analytics). You acknowledge and agree that we own
          all rights in the Analytics, and that we may use the Analytics for our
          own internal business purposes, provided that the Analytics do not
          contain any identifying information.
        </p>
        <p className="h-paragraph">
          <span>10.5</span>We do not endorse or approve, and are not responsible
          for, any of Your Data.
        </p>
        <p className="h-paragraph">
          <span>10.6</span>You acknowledge and agree that the Platform and the
          integrity and accuracy of the Output Data is reliant on the accuracy
          and completeness of Your Data, and the provision by you of Your Data
          that is inaccurate or incomplete may affect the use, output and
          operation of the Platform.
        </p>
        <p className="h-paragraph">
          <span>10.7</span>This clause will survive the termination or expiry of
          your Membership.
        </p>
        <h4>
          <span>11</span>Warranties
        </h4>
        <p className="h-paragraph">
          <span>11.1</span>You represent, warrant and agree that:
          <ol>
            <li>
              you will not use our Platform, including Our Intellectual
              Property, in any way that competes with our business;
            </li>
            <li>
              there are no legal restrictions preventing you from entering into
              these Terms;
            </li>
            <li>
              you will not use the chat function for promotional or commercial
              purposes, and any use in contravention of this provision may
              result in the suspension or termination of the relevant Account;
            </li>
            <li>
              all information and documentation that you provide to us in
              connection with these Terms is true, correct and complete; and
            </li>
            <li>
              you have not relied on any representations or warranties made by
              us in relation to the Platform (including as to whether the
              Platform is or will be fit or suitable for your particular
              purposes), unless expressly stipulated in these Terms.
            </li>
          </ol>
        </p>
        <h4>
          <span>12</span>Australian Consumer Law
        </h4>
        <p className="h-paragraph">
          <span>12.1</span>Certain legislation, including the Australian
          Consumer Law (ACL) in the Competition and Consumer Act 2010 (Cth), and
          similar consumer protection laws and regulations, may confer you with
          rights, warranties, guarantees and remedies relating to the provision
          of the Platform by us to you which cannot be excluded, restricted or
          modified (Consumer Law Rights).
        </p>
        <p className="h-paragraph">
          <span>12.2</span>If the ACL applies to you as a consumer, nothing in
          these Terms excludes your Consumer Law Rights as a consumer under the
          ACL. You agree that our Liability for the Platform provided to an
          entity defined as a consumer under the ACL is governed solely by the
          ACL and these Terms.
        </p>
        <p className="h-paragraph">
          <span>12.3</span>Subject to your Consumer Law Rights, we exclude all
          express and implied warranties, and all material, work and services
          (including the Platform) are provided to you without warranties of any
          kind, either express or implied, whether in statute, at law or on any
          other basis.
        </p>
        <p className="h-paragraph">
          <span>12.4</span>This clause will survive the termination of your
          Account.
        </p>
        <h4>
          <span>13</span>Liability
        </h4>
        <p className="h-paragraph">
          <span>13.1</span>Despite anything to the contrary, to the maximum
          extent permitted by law, we will not be liable for, and you waive and
          release us from and against, any Liability caused or contributed to
          by, arising from or connected with:
          <ol>
            <li>You acts or omissions;</li>
            <li>
              Any use or application of the Platform by a person other than you,
              or other than as reasonably contemplated by these Terms;
            </li>
            <li>
              Any works, services, goods, materials or items which do not form
              part of the Platform, or which have not been provided by us;
            </li>
            <li>
              any defect, error, omission or lack of functionality or
              suitability (or the absence of, or reduction in, any anticipated
              result, outcome or benefit) with respect to the Platform;
            </li>
            <li>
              the Platform being unavailable, or any delay in us providing our
              services to you, for whatever reason; and/or
            </li>
            <li>
              any event outside of our reasonable control (whether known or
              unknown at the time if entering into these Terms).
            </li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>13.2</span>Despite anything to the contrary, to the maximum
          extent permitted by law:
          <ol>
            <li>we will not be liable for Consequential Loss; </li>
            <li>
              our liability for any Liability under these Terms will be reduced
              proportionately to the extent the relevant Liability was caused or
              contributed to by the acts or omissions of you (or any of your
              personnel); and
            </li>
            <li>
              our aggregate liability for any Liability arising from or in
              connection with these Terms will be limited to us resupplying the
              Platform to you.
            </li>
          </ol>
        </p>
        <p className="h-paragraph">
          <span>13.3</span> This clause will survive the termination of your
          Account.
        </p>
        <h4>
          <span>14</span>Termination
        </h4>
        <p className="h-paragraph">
          <span>14.1</span>Cancellation of Accounts: You may request to cancel
          your Account at any time via &lsquo;cancel my account&rsquo; feature
          in your Account. Your cancellation will take effect immediately once
          you have re-confirmed your password.
        </p>
        <p className="h-paragraph">
          <span>14.2</span>We may terminate your Account at any time by giving
          30 days&rsquo; written notice to you (Termination for Convenience).
        </p>
        <p className="h-paragraph">
          <span>14.3</span>An Account will be terminated immediately upon
          written notice by a Party (Non-Defaulting Party) if the other Party
          (Defaulting Party) breaches a material term of these Terms and that
          breach has not been remedied within 10 Business Days of the Defaulting
          Party being notified of the breach by the Non-Defaulting Party.
        </p>
        <p className="h-paragraph">
          <span>14.4</span>Should we suspect that you are in breach of these
          Terms, we may suspend your access to the Platform while we investigate
          the suspected breach.
        </p>
        <p className="h-paragraph">
          <span>14.5</span>Upon expiry or termination of your Account, we will
          remove your access to the Platform and your Account will be deleted;
        </p>
        <p className="h-paragraph">
          <span>14.6</span>Termination of an Account will not affect any rights
          or liabilities that a Party has accrued under these Terms.
        </p>
        <p className="h-paragraph">
          <span>14.7</span>This clause will survive the termination or expiry of
          your Membership.
        </p>
        <h4>
          <span>15</span>General
        </h4>
        <p className="h-paragraph">
          <span>15.1</span>
          <strong>Assignment</strong>: Subject to the below clause, a Party must
          not assign or deal with the whole or any part of its rights or
          obligations under these Terms without the prior written consent of the
          other Party (such consent is not to be unreasonably withheld).
        </p>
        <p className="h-paragraph">
          <span>15.2</span>
          <strong>Assignment of Debt</strong>: You agree that we may assign or
          transfer any debt owed by you to us, arising under or in connection
          with these Terms, to a debt collector, debt collection agency, or
          other third party.
        </p>
        <p className="h-paragraph">
          <span>15.3</span>
          <strong>Disputes</strong>: A Party may not commence court proceedings
          relating to a dispute without first meeting with the other Party to
          seek (in good faith) to resolve the dispute, failing which the Parties
          agree to engage a mediator to attempt to resolve the dispute. The
          costs of the mediation will be shared equally between the Parties.
          Nothing in this clause will operate to prevent a Party from seeking
          urgent injunctive or equitable relief from a court of appropriate
          jurisdiction.
        </p>
        <p className="h-paragraph">
          <span>15.4</span>
          <strong>Entire Terms</strong>: Subject to your Consumer Law Rights,
          these Terms contains the entire understanding between the Parties and
          the Parties agree that no representation or statement has been made
          to, or relied upon by, either of the Parties, except as expressly
          stipulated in these Terms, and these Terms supersedes all previous
          discussions, communications, negotiations, understandings,
          representations, warranties, commitments and agreements, in respect of
          its subject matter.
        </p>
        <p className="h-paragraph">
          <span>15.5</span>
          <strong>Force Majeure</strong>: To the maximum extent permitted by
          law, we shall have no Liability for any event or circumstance outside
          of our reasonable control.
        </p>
        <p className="h-paragraph">
          <span>15.6</span>
          <strong>Governing law</strong>: These Terms are governed by the laws
          of NSW, in the Commonwealth of Australia. Each Party irrevocably and
          unconditionally submits to the exclusive jurisdiction of the courts
          operating in NSW and any courts entitled to hear appeals from those
          courts and waives any right to object to proceedings being brought in
          those courts.
        </p>
        <p className="h-paragraph">
          <span>15.7</span>
          <strong>Notices</strong>: Any notice given under these Terms must be
          in writing addressed to us at the details set out below or to you at
          the details provided in your Account. Any notice may be sent by
          standard post or email, and will be deemed to have been served on the
          expiry of 48 hours in the case of post, or at the time of transmission
          in the case of transmission by email.
        </p>
        <p className="h-paragraph">
          <span>15.8</span>
          <strong>Privacy</strong>: We agree to comply with the legal
          requirements of the Australian Privacy Principles as set out in the
          Privacy Act 1988 (Cth) and any other applicable legislation or privacy
          guideline.
        </p>
        <p className="h-paragraph">
          <span>15.9</span>
          <strong>Severance</strong>: If a provision of these Terms is held to
          be void, invalid, illegal or unenforceable, that provision is to be
          read down as narrowly as necessary to allow it to be valid or
          enforceable, failing which, that provision (or that part of that
          provision) will be severed from these Terms without affecting the
          validity or enforceability of the remainder of that provision or the
          other provisions in these Terms.
        </p>
        <h4>
          <span>16</span>Definitions
        </h4>
        <p>
          In these Terms, unless the context otherwise requires, capitalised
          terms have the meanings given to them in these Terms, and:
        </p>
        <p>
          <strong>Account</strong> means an account accessible to you;
        </p>
        <p>
          <strong>Account</strong> holder means the individual or Entity that
          signed up to our Services;
        </p>
        <p>
          <strong>ACL or Australian Consumer Law</strong> means the Australian
          consumer laws set out in Schedule 2 of the Competition and Consumer
          Act 2010 (Cth), as amended, from time to time;
        </p>
        <p>
          <strong>Business Day</strong> means a day on which banks are open for
          general bank business in New South Wales, excluding Saturdays, Sundays
          and public holidays;
        </p>
        <p>
          <strong>Consequential Loss</strong> includes any consequential loss,
          indirect loss, real or anticipated loss of profit, loss of benefit,
          loss of revenue, loss of business, loss of goodwill, loss of
          opportunity, loss of savings, loss of reputation, loss of use and/or
          loss or corruption of data, whether under statute, contract, equity,
          tort (including negligence), indemnity or otherwise;
        </p>
        <p>
          <strong>Intellectual Property</strong> means any domain names,
          know-how, inventions, processes, trade secrets or confidential
          information; or circuit layouts, software, computer programs,
          databases or source codes, including any application, or right to
          apply, for registration of, and any improvements, enhancements or
          modifications of, the foregoing;
        </p>
        <p>
          <strong>Intellectual Property Rights</strong> means for the duration
          of the rights in any part of the world, any industrial or intellectual
          property rights, whether registrable or not, including in respect of
          Intellectual Property;{' '}
        </p>
        <p>
          <strong>Laws</strong> means all applicable laws, regulations, codes,
          guidelines, policies, protocols, consents, approvals, permits and
          licences, and any requirements or directions given by any person with
          the authority to bind the relevant Party in connection with these
          Terms or the provision of the Services, and includes the Privacy Act
          1988 (Cth) and the Spam Act 2003 (Cth);
        </p>
        <p>
          <strong>Liability</strong> means any expense, cost, liability, loss,
          damage, claim, notice, entitlement, investigation, demand, proceeding
          or judgment (whether under statute, contract, equity, tort (including
          negligence), indemnity or otherwise), howsoever arising, whether
          direct or indirect and/or whether present, unascertained, future or
          contingent and whether involving a third party or a party to these
          Terms or otherwise; and
        </p>
        <p>
          <strong>Third Party Services</strong> means third parties or any
          services provided by third parties, including software providers,
          suppliers or other subcontractors which the provision of the Services
          may be contingent on, or impacted by;
        </p>
        <br />
        <p>
          <strong>For any questions or notices, please contact us at:</strong>
        </p>
        <p>Dashdot Pty Ltd ABN 83 631 524 797</p>
        <p>
          Email:{' '}
          <a href="mailto:feedback@dashdot.com.au">feedback@dashdot.com.au</a>
        </p>
        <p>
          <strong>Last update:</strong> 18 May 2022
        </p>
        <p>
          ©{' '}
          <a
            target="_blank"
            href="https://legalvision.com.au/"
            rel="noreferrer"
          >
            LegalVision ILP Pty Ltd
          </a>
        </p>
      </article>
    </Template>
  );
};

export default Terms;
