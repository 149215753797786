export default () => {
  // Generate three random values between 150 and 255 to get lighter colors
  const r = Math.floor(Math.random() * 106) + 150;
  const g = Math.floor(Math.random() * 106) + 150;
  const b = Math.floor(Math.random() * 106) + 150;

  // Convert the RGB values to a hex code
  const hex = ((r << 16) | (g << 8) | b).toString(16);

  // Pad the hex code with zeros if it's less than 6 digits
  const paddedHex = hex.padStart(6, '0');

  // Prepend a hash sign and return the hex code
  return `#${paddedHex}`;
};
