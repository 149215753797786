import { map as pluck } from 'lodash';
import React, { useRef } from 'react';

import Filter from '../../../../foundation/assets/svgs/Filter';
import SaveSearch from '../../../../foundation/assets/svgs/SaveSearch';
import Search from '../../../../foundation/assets/svgs/Search';
import Sort from '../../../../foundation/assets/svgs/Sort';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import AccessControl from '../../../user/access_control/AccessControl';
import FilterModal from '../../form/filter/filter_modal/FilterModal';
import SaveSearchModal from '../../form/search/save_search/save_search_modal/SaveSearchModal';
import {
  selectActivePropertyId,
  selectIsFilterModalActive,
  selectIsMapSearchEnabled,
  selectIsSaveSearchModalActive,
  selectIsSortModalActive,
  selectListContainerSize,
  selectOptions,
  selectQueryValues,
  selectResults,
} from '../../redux/selectors';
import {
  resetActiveSearch,
  resetResults,
  setIsFilterModalActive,
  setIsSaveSearchModalActive,
  setIsSortModalActive,
} from '../../redux/slice';
import Pagination from '../pagination/Pagination';
import Properties from '../properties/Properties';
import Suburbs from '../suburbs/Suburbs';
import SortModal from './sort/sort_modal/SortModal';

type Props = {
  isLargeScreen?: boolean;
};

const List = ({ isLargeScreen = true }: Props) => {
  const [isMobileView] = useBreakpoint();
  const dispatch = useAppDispatch();

  const listRef = useRef<any>();

  const PROPERTY_ID = useAppSelector(selectActivePropertyId);

  const results = useAppSelector(selectResults);
  const options = useAppSelector(selectOptions);
  const queryValues = useAppSelector(selectQueryValues);
  const listContainerSize = useAppSelector(selectListContainerSize);
  const isFilterModalActive = useAppSelector(selectIsFilterModalActive);
  const isSaveSearchModalActive = useAppSelector(selectIsSaveSearchModalActive);
  const isSortModalActive = useAppSelector(selectIsSortModalActive);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  const hasSuburbs = results && !!results.suburbs && !!results.suburbs.length;
  const hasProperties =
    results && !!results.properties && !!results.properties.length;

  const itemsPerPage = 20;

  const fetchResultsOptions = {
    afterCb: (hasNoResults: boolean) => {
      if (hasNoResults) {
        dispatch(resetResults());
      }

      dispatch(resetActiveSearch());

      if (listRef && listRef.current) {
        listRef.current.scrollTop = 0;
        window.scrollTo(0, 0);
      }
    },
    pageIndexParam: 1,
  };

  if (!results && PROPERTY_ID) {
    return <></>;
  }

  return (
    <>
      {isSaveSearchModalActive && <SaveSearchModal />}
      {isSortModalActive && <SortModal />}
      {isFilterModalActive && (
        <FilterModal searchHandlerOptions={fetchResultsOptions} />
      )}
      {!isMapSearchEnabled && (
        <section
          className={`${
            isLargeScreen
              ? 'l-list l-list--' + listContainerSize
              : 'l-list l-list--tablet-mobile'
          }`}
        >
          <div className="l-list__body">
            {isLargeScreen && (
              <div className="l-list__search-bar">
                <div
                  className="l-list__search-icon"
                  onClick={() => {
                    dispatch(setIsFilterModalActive(true));
                  }}
                >
                  <Search />
                </div>
                <div
                  className="l-list__query-values"
                  onClick={() => {
                    dispatch(setIsFilterModalActive(true));
                  }}
                >
                  {queryValues && queryValues.length ? (
                    <>{pluck(queryValues, 'key').join('; ')}</>
                  ) : (
                    'Search'
                  )}
                </div>
                <div className="l-list__search-bar-actions">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(setIsFilterModalActive(true));
                    }}
                    className="l-list__button-common l-list__button-common--light"
                  >
                    <Filter />
                    {listContainerSize !== 'compact' &&
                      listContainerSize !== 'hidden' && <span>Filter</span>}
                  </button>
                  <AccessControl requiredFeature="suburb-save-search">
                    <button
                      type="button"
                      className="l-list__button-common l-list__button-common--light"
                      onClick={() => {
                        dispatch(setIsSaveSearchModalActive(true));
                      }}
                    >
                      <SaveSearch />
                      {listContainerSize !== 'compact' &&
                        listContainerSize !== 'hidden' && (
                          <span>Save Search</span>
                        )}
                    </button>
                  </AccessControl>
                </div>
              </div>
            )}
            {!!results && !!results.pageCount && (
              <div className="l-pagination">
                <Pagination
                  itemsPerPage={itemsPerPage}
                  searchHandlerOptions={fetchResultsOptions}
                />
              </div>
            )}
            {!results && (
              <div className="l-list__options">No results found</div>
            )}
            {!!results && (hasSuburbs || hasProperties) && (
              <>
                {!isMobileView && (
                  <div className="l-list__options">
                    {results.pageCount > 1 ? (
                      <span>
                        Showing{' '}
                        {((options.pageIndex || 0) - 1) * itemsPerPage + 1} -{' '}
                        {(options.pageIndex || 0) * itemsPerPage} of{' '}
                        {results.resultCount} Results
                      </span>
                    ) : (
                      <span>
                        Showing {results.resultCount} Result
                        {results.resultCount > 1 ? 's' : ''}
                      </span>
                    )}
                    <button
                      type="button"
                      className="l-list__options-sort"
                      onClick={() => {
                        dispatch(setIsSortModalActive(true));
                      }}
                    >
                      <Sort />
                      <span>Sort</span>
                    </button>
                  </div>
                )}
                <ul className="l-list__content" ref={listRef}>
                  {!!results.suburbs && results.suburbs.length && (
                    <Suburbs
                      listContainerSize={listContainerSize}
                      isLargeScreen={isLargeScreen}
                      isMobileView={isMobileView}
                      results={results}
                    />
                  )}
                  {!!results.properties && results.properties.length && (
                    <Properties results={results} />
                  )}
                </ul>
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default List;
