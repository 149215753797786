import { CometChat } from '@cometchat-pro/chat';
import { Button, Input, Modal } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import Search from '../../../foundation/assets/svgs/Search';
import SortChannelIcon from '../../../foundation/assets/svgs/SortChannelIcon';
import ErrorField from '../../../foundation/components/error_field/ErrorField';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import sharedModalProps from '../../../foundation/utils/sharedModalProps';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import { getChannelList } from '../helpers/conversationHelpers';
import { selectIsChannelsListFetching } from '../redux/selectors';
import {
  setChannelsList,
  setIsChannelCreationModalVisible,
  setIsChannelsListFetching,
} from '../redux/slice';
import ChannelListItemSkeleton from '../skeletons/ChannelListItemSkeleton';
import { fetchNextChannels } from '../utils/controllers/channelListController';
import ChannelListItem from './ChannelListItem';

const ChannelBrowse = () => {
  const dispatch = useAppDispatch();
  const isChannelsListFetching = useAppSelector(selectIsChannelsListFetching);

  const [passwordPromptDetails, setPasswordPromptDetails] = useState<any>(null);

  const [allChannels, setAllChannels] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
  const [isChannelsLoading, setIsChannelsLoading] = useState(true);

  const [isMobileView, isDesktopView] = useBreakpoint();

  const debounceTimeout = 500;

  const getChannels = (search: string) => {
    return new Promise((resolve, reject) => {
      fetchNextChannels(search)
        .then((conversationList) => {
          const serialized = conversationList.map((conversation: any) => {
            return JSON.parse(JSON.stringify(conversation));
          });

          setAllChannels(serialized);

          setIsChannelsLoading(false);
          dispatch(setIsChannelsListFetching(false));
          setSortBy('asc');
          resolve(true);
        })
        .catch((error) => {
          setIsChannelsLoading(false);
          dispatch(setIsChannelsListFetching(false));
          reject(error);
        });
    });
  };

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (e: any) => {
      const value = e.target.value;
      setAllChannels([]);
      setIsChannelsLoading(true);

      getChannels(value);
    };

    return debounce(loadOptions, debounceTimeout);
  }, [getChannels, debounceTimeout]);

  const handleSortingClick = () => {
    if (sortBy === 'asc') {
      setSortBy('desc');
    } else {
      setSortBy('asc');
    }
  };

  const updateListWithNewValues = (guid: any, type: 'joined' | 'left') => {
    const updatedChannels = allChannels.map((channel) => {
      if (channel.guid === guid) {
        const hasJoined = type === 'joined';
        return {
          ...channel,
          hasJoined: hasJoined,
          membersCount: hasJoined
            ? channel.membersCount + 1
            : channel.membersCount,
        };
      }
      return channel;
    });

    setAllChannels(updatedChannels);
  };

  const channelJoinHandler = (guid: string, type: string) => {
    if (type === CometChat.GROUP_TYPE.PASSWORD) {
      setPasswordPromptDetails({
        guid: guid,
        type: type,
      });
    } else {
      proceedJoin(guid, type);
    }
  };

  const passwordFormHandler = (values: any) => {
    if (passwordPromptDetails) {
      const { guid, type } = passwordPromptDetails;

      proceedJoin(guid, type, values.password);
    }
  };

  const proceedJoin = async (guid: string, type: string, password?: string) => {
    try {
      let group: any;
      setIsChannelsLoading(true);

      if (type === CometChat.GROUP_TYPE.PASSWORD) {
        group = await CometChat.joinGroup(
          guid,
          // @ts-ignore
          type,
          password,
        );
      } else {
        group = await CometChat.joinGroup(
          guid,
          // @ts-ignore
          type,
        );
      }

      /**
       * Update existing channels list
       */
      updateListWithNewValues(group.guid, 'joined');

      /**
       * Update conversations list.
       */
      const conversationsList = await getChannelList();
      dispatch(setChannelsList(conversationsList));

      setIsChannelsLoading(false);
      setPasswordPromptDetails(null);
    } catch (error: any) {
      console.log(error);
      setIsChannelsLoading(false);
      setPasswordPromptDetails({
        ...passwordPromptDetails,
        error:
          error && error.code === 'ERR_WRONG_GROUP_PASS'
            ? 'Password is incorrect'
            : 'Something went wrong with the request',
      });
    }
  };

  const onCreateChannelClick = () => {
    if (isDesktopView) {
      dispatch(setIsChannelCreationModalVisible(true));
    } else if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'create_channel',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Create Channel',
        parentConfig: undefined as any,
      };

      dispatch(setSiderMobileContentValues(data));
    }
  };

  const handleModalClose = () => {
    setPasswordPromptDetails(null);
  };

  useEffect(() => {
    let sortedItems: any[] = [];
    if (sortBy === 'asc') {
      sortedItems = _.orderBy(allChannels, ['name'], ['asc']);
    } else {
      sortedItems = _.orderBy(allChannels, ['name'], ['desc']);
    }

    setAllChannels(sortedItems);
  }, [sortBy]);

  useEffect(() => {
    getChannels('');
  }, []);

  useEffect(() => {
    if (isChannelsListFetching) {
      // Refresh the list after channel creation
      getChannels('');
    }
  }, [isChannelsListFetching]);

  return (
    <div className="l-chat-browse-channels">
      {passwordPromptDetails && (
        <Modal
          {...{
            ...sharedModalProps,
            title: 'Join Channel',
            onCancel: handleModalClose,
          }}
        >
          <div className="l-create-channel-modal">
            <div className="l-create-channel-modal__info">
              This channel is restricted. To join, please enter the password.
            </div>
            <Formik
              initialValues={{
                password: undefined,
              }}
              onSubmit={passwordFormHandler}
              validationSchema={Yup.object().shape({
                password: Yup.string().required('Field is required'),
              })}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                isValid,
                dirty,
              }: any) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="c-input-wrapper l-create-channel-modal__field-wrapper">
                      <div className="slide-up-label">
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="l-create-channel-modal__field-wrapper"
                          placeholder=" "
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                      {errors.password && (
                        <ErrorField error={errors.password} />
                      )}
                    </div>
                    {passwordPromptDetails.error && !isChannelsLoading && (
                      <ErrorField
                        style={{ fontSize: '14px', textAlign: 'center' }}
                        error={passwordPromptDetails.error}
                      />
                    )}
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="l-create-channel-modal__submit-btn"
                      disabled={!(isValid && dirty) || isChannelsLoading}
                    >
                      Submit
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </Modal>
      )}
      {isDesktopView && (
        <div className="l-chat-browse-channels__header">
          <div className="l-chat-browse-channels__header-title">
            All Channels
          </div>
          <Button
            className="l-chat-browse-channels__header-btn"
            onClick={onCreateChannelClick}
          >
            Create Channel
          </Button>
        </div>
      )}
      <div className="l-chat-browse-channels__search-wrapper">
        <Input
          prefix={<Search color="#6E7191" />}
          className="l-chat-browse-channels__search-field"
          placeholder="Search for a channel"
          onChange={debounceFetcher}
        />
      </div>
      <div className="l-chat-browse-channels__channels-list-wrapper">
        <div className="l-chat-browse-channels__channels-list-config">
          <div>{allChannels.length} channels</div>
          <div
            className="l-chat-browse-channels__channels-list-config-sort-wrapper"
            onClick={handleSortingClick}
          >
            <div>
              <SortChannelIcon />{' '}
            </div>
            <div> Sort {sortBy === 'asc' ? 'Z to A' : 'A to Z'}</div>
          </div>
        </div>
        {isChannelsLoading && <ChannelListItemSkeleton />}
        {!isChannelsLoading && (
          <div className="l-chat-browse-channels__channels-list">
            {allChannels.map((channel) => (
              <ChannelListItem
                key={channel.guid}
                channel={channel}
                channelJoinHandler={channelJoinHandler}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelBrowse;
