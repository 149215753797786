import { Collapse, Divider, Modal, notification, Popover } from 'antd';
import React, { useEffect, useState } from 'react';

import PlusCircleOutlined from '../../../foundation/assets/svgs/PlusCircleOutlined';
import useBreakpoint from '../../../foundation/custom_hooks/useBreakpont';
import sharedModalProps from '../../../foundation/utils/sharedModalProps';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setSiderMobileContentValues } from '../../layout/redux/slice';
import CreateChannel from '../components/create_channel/CreateChannel';
import DirectMessage from '../components/direct_message/DirectMessage';
import {
  getChannelList,
  getDirectMessagesList,
} from '../helpers/conversationHelpers';
import useMessagesUnreadCountListener from '../helpers/useMessagesUnreadCountListener';
import {
  selectChannelsList,
  selectDirectMessagesList,
  selectIsChannelCreationModalVisible,
  // selectDirectMessagesList,
  selectIsConversationsListFetching,
  selectSelectedConversation,
} from '../redux/selectors';
import {
  setChannelsList,
  setDirectMessagesList,
  setIsChannelCreationModalVisible,
  setIsConversationsListFetching,
} from '../redux/slice';
import { setChatWindowType } from '../redux/slice';
import ChannelList from './ChannelList';
import DMList from './DMList';

const { Panel } = Collapse;

const ChatPanel = () => {
  const dispatch = useAppDispatch();

  const [isDirectMessageModalVisible, setDirectMessageModalVisibility] =
    useState(false);

  const [isChannelListLoading, setIsChannelListLoading] = useState(true);
  const [isChannelOptionsPopoverVisible, setChannelOptionsPopoverVisibility] =
    useState(false);
  const [isDirectMessagesListLoading, setIsDirectMessagesListLoading] =
    useState(true);

  const channelsList = useAppSelector(selectChannelsList);

  const isConversationsListFetching = useAppSelector(
    selectIsConversationsListFetching,
  );

  const isChannelCreationModalVisible = useAppSelector(
    selectIsChannelCreationModalVisible,
  );

  const selectedConversation = useAppSelector(selectSelectedConversation);
  const directMessagesList = useAppSelector(selectDirectMessagesList);

  // Custom Hookes
  const [isMobileView, isDesktopView] = useBreakpoint();
  useMessagesUnreadCountListener();

  const getInitialData = async () => {
    try {
      const [channels, dms] = await Promise.all([
        getChannelList(),
        getDirectMessagesList(),
      ]);

      dispatch(setChannelsList(channels));
      dispatch(setDirectMessagesList(dms));

      setIsDirectMessagesListLoading(false);
      setIsChannelListLoading(false);
      dispatch(setIsConversationsListFetching(false));
    } catch (error) {
      dispatch(setIsConversationsListFetching(false));
      notification.error({
        message: 'Something went wrong',
      });
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    // Refresh the list after channel creation
    if (isConversationsListFetching) {
      getInitialData();
    }
  }, [isConversationsListFetching]);

  const onBrowseChannelOptionClick = () => {
    if (isDesktopView) {
      dispatch(setChatWindowType('channel-browse'));
    } else if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'browse_channels',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'All Channels',
        parentConfig: undefined as any,
      };

      dispatch(setSiderMobileContentValues(data));
    }
  };

  const onCreateChannelClick = () => {
    if (isDesktopView) {
      dispatch(setIsChannelCreationModalVisible(true));
    } else if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'create_channel',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Create Channel',
        parentConfig: undefined as any,
      };

      dispatch(setSiderMobileContentValues(data));
    }
  };

  const ChannelOptionsPopoverContent = () => {
    return (
      <div className="l-chat-panel__channel-popover-options-wrapper">
        <div
          className="l-chat-panel__channel-popover-option"
          onClick={onCreateChannelClick}
        >
          Create a channel
        </div>
        <div
          className="l-chat-panel__channel-popover-option"
          onClick={onBrowseChannelOptionClick}
        >
          Browse channels
        </div>
      </div>
    );
  };

  const ChannelPopover = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          setChannelOptionsPopoverVisibility((v) => !v);
        }}
      >
        <Popover
          content={<ChannelOptionsPopoverContent />}
          className="l-chat-panel__channel-options-popover"
          arrowPointAtCenter
          overlayInnerStyle={{
            borderRadius: 10,
            boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
            border: '1px solid #D9DBE9',
          }}
          placement="right"
          showArrow={false}
          open={isChannelOptionsPopoverVisible}
          onOpenChange={(v) => {
            setChannelOptionsPopoverVisibility(v);
          }}
          trigger="click"
        >
          <PlusCircleOutlined />
        </Popover>
      </div>
    );
  };

  const handleModalClose = () => {
    dispatch(setIsChannelCreationModalVisible(false));
    setDirectMessageModalVisibility(false);
  };

  const CreateChannelModal = () => {
    const modalProps = {
      ...sharedModalProps,
      title: 'Request Channel Creation',
      onCancel: handleModalClose,
    };

    return (
      <Modal {...modalProps}>
        <CreateChannel closeModal={handleModalClose} />
      </Modal>
    );
  };

  const DirectMessageModal = () => {
    const modalProps = {
      ...sharedModalProps,
      title: 'Send a Direct Message',
      onCancel: handleModalClose,
    };

    return (
      <Modal {...modalProps}>
        <DirectMessage closeModal={handleModalClose} />
      </Modal>
    );
  };

  const createMessageHandle = (e: any) => {
    e.stopPropagation();

    if (isDesktopView) {
      setDirectMessageModalVisibility(true);
    } else if (isMobileView) {
      const data = {
        isVisible: true,
        view: 'create_message',
        showBackButton: false,
        isClosable: true,
        headerTitle: 'Send a Direct Message',
        parentConfig: undefined as any,
      };

      dispatch(setSiderMobileContentValues(data));
    }
  };

  return (
    <div className="l-chat-panel">
      {isChannelCreationModalVisible && <CreateChannelModal />}
      {isDirectMessageModalVisible && <DirectMessageModal />}
      <div className="l-chat-panel__header">
        <div>Community</div>
      </div>
      <div style={{ padding: '0px 20px' }}>
        <Divider style={{ margin: 0 }} />
      </div>

      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        className="l-chat-panel__collapse"
      >
        <Panel
          header="Channels"
          key="1"
          style={{ border: 'none', padding: 0 }}
          extra={<ChannelPopover />}
        >
          <ChannelList
            conversations={channelsList}
            isChannelListLoading={isChannelListLoading}
            selectedConversation={selectedConversation}
            setIsChannelListLoading={setIsChannelListLoading}
          />
        </Panel>
        <Panel
          header="Direct Messages"
          key="2"
          className="l-chat-panel__colap-panel"
          style={{ border: 'none', padding: 0 }}
          extra={
            <span onClick={createMessageHandle}>
              <PlusCircleOutlined />
            </span>
          }
        >
          <DMList
            conversations={directMessagesList}
            isDMListLoading={isDirectMessagesListLoading}
            selectedConversation={selectedConversation}
            setDMListLoading={setIsDirectMessagesListLoading}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ChatPanel;
