import { CometChat } from '@cometchat-pro/chat';
import { notification } from 'antd';

import messages from '../../../../foundation/messages';
import { CCUser } from '../../redux/types';
import * as enums from '../enums';

const sendMessageErrorHandler = (error: any, reject: any) => {
  notification.error({
    message: 'Error!',
    description: messages.chat_messages.ERROR_SENDING_MESSAGE,
  });
  reject(error);
};

export const sendCustomMessage = (
  customData: any,
  customType: string,
  receiverId: string,
  receiverType: string,
  loggedInUser: any,
  parentMessageId?: number,
) => {
  return new Promise((resolve, reject) => {
    const customMessage = new CometChat.CustomMessage(
      receiverId,
      receiverType,
      customType,
      customData,
    );

    if (parentMessageId) {
      customMessage.setParentMessageId(parentMessageId);
    }

    const blocks = customData.rawEditorState.blocks;
    const pushNotificationMessage = blocks
      .map((block: any) => (!block.text.trim() && '\n') || block.text)
      .join('\n');

    customMessage.setSender(loggedInUser);
    customMessage.setMetadata({
      incrementUnreadCount: true,
      pushNotification: pushNotificationMessage,
    });

    CometChat.sendCustomMessage(customMessage)
      .then((message) => {
        resolve(message);
      })
      .catch((error) => {
        sendMessageErrorHandler(error, reject);
      });
  });
};

export const sendMediaMessage = (
  messageInput: any,
  messageType: string,
  receiverId: string,
  receiverType: string,
  loggedInUser: any,
  parentMessageId?: number,
) => {
  return new Promise((resolve, reject) => {
    const mediaMessage = new CometChat.MediaMessage(
      receiverId,
      messageInput,
      messageType,
      receiverType,
    );
    if (parentMessageId) {
      mediaMessage.setParentMessageId(parentMessageId);
    }

    mediaMessage.setSender(loggedInUser);
    mediaMessage.setType(messageType);
    mediaMessage.setMetadata({
      [enums.CONSTANTS['FILE_METADATA']]: messageInput,
    });

    CometChat.sendMessage(mediaMessage)
      .then((message) => {
        resolve(message);
      })
      .catch((error) => {
        sendMessageErrorHandler(error, reject);
      });
  });
};

export const getMediaMessageType = (file: any) => {
  const type = file && file['type'].split('/')[0];
  if (type === 'image') {
    return CometChat.MESSAGE_TYPE.IMAGE;
  } else if (type === 'video') {
    return CometChat.MESSAGE_TYPE.VIDEO;
  } else if (type === 'application') {
    return CometChat.MESSAGE_TYPE.FILE;
  }
  return CometChat.MESSAGE_TYPE.FILE;
};

export const attachMessageListeners = (listenerId: string, callback: any) => {
  CometChat.addMessageListener(
    listenerId,
    new CometChat.MessageListener({
      onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
        callback(enums.TEXT_MESSAGE_RECEIVED, textMessage);
      },
      onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
        callback(enums.MEDIA_MESSAGE_RECEIVED, mediaMessage);
      },
      onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
        callback(enums.CUSTOM_MESSAGE_RECEIVED, customMessage);
      },
      onMessagesDelivered: (messageReceipt: CometChat.MessageReceipt) => {
        callback(enums.MESSAGE_DELIVERED, messageReceipt);
      },
    }),
  );
};

export const removeMessageListeners = (listenerId: string) => {
  CometChat.removeMessageListener(listenerId);
};

export const sendMessage = (
  messageInput: string,
  receiverId: string,
  receiverType: string,
  loggedInUser: any,
  parentMessageId?: number,
) => {
  return new Promise((resolve, reject) => {
    const textMessage = new CometChat.TextMessage(
      receiverId,
      messageInput,
      receiverType,
    );
    if (parentMessageId) {
      textMessage.setParentMessageId(parentMessageId);
    }
    textMessage.setSender(loggedInUser);
    textMessage.setText(messageInput);

    CometChat.sendMessage(textMessage)
      .then((message) => {
        resolve(message);
      })
      .catch((error) => {
        console.log(error);
        sendMessageErrorHandler(error, reject);
      });
  });
};

export const fetchPreviousMessages = (
  type: string,
  item: any,
  parentMessageId: number | null = null,
  messageId: number | undefined = undefined,
) => {
  let messageRequest = null;
  const limit = 40;
  const categories = Object.keys(enums.messageFilterCategories);
  const types = Object.keys(enums.messageFilterTypes);
  if (type === CometChat.ACTION_TYPE.TYPE_USER) {
    if (parentMessageId) {
      messageRequest = new CometChat.MessagesRequestBuilder()
        .setUID(item.uid)
        .setParentMessageId(parentMessageId)
        .setMessageId(messageId)
        .setCategories(categories)
        .setTypes(types)
        .hideDeletedMessages(true) // We need to hide the deleted messages in all the cases.
        .setLimit(limit)
        .build();
    } else {
      messageRequest = new CometChat.MessagesRequestBuilder()
        .setUID(item.uid)
        .setCategories(categories)
        .setMessageId(messageId)
        .setTypes(types)
        .hideReplies(true)
        .hideDeletedMessages(true)
        .setLimit(limit)
        .build();
    }
  } else {
    if (parentMessageId) {
      messageRequest = new CometChat.MessagesRequestBuilder()
        .setGUID(item.guid)
        .setParentMessageId(parentMessageId)
        .setMessageId(messageId)
        .setCategories(categories)
        .setTypes(types)
        .hideDeletedMessages(true)
        .setLimit(limit)
        .build();
    } else {
      messageRequest = new CometChat.MessagesRequestBuilder()
        .setGUID(item.guid)
        .setCategories(categories)
        .setMessageId(messageId)
        .setTypes(types)
        .hideReplies(true)
        .hideDeletedMessages(true)
        .setLimit(limit)
        .build();
    }
  }
  return messageRequest && messageRequest.fetchPrevious();
};

// mark the message as delivered
export const markMessageAsDelivered = (
  message: any,
  ccUser: CCUser | undefined,
) => {
  if (
    message.sender?.uid !== ccUser?.uid &&
    (!message?.deliveredAt || message?.deliveredAt === false)
  ) {
    CometChat.markAsDelivered(message).catch((error: any) => {
      console.log(error);
    });
  }
};

export const markMessageAsRead = (message: any) => {
  if (!message.readAt || message.readAt === false) {
    CometChat.markAsRead(message).catch((error: any) => {
      console.log(error);
    });
  }
};
