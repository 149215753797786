import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useMemo, useState } from 'react';

import Bin from '../../../foundation/assets/svgs/Bin';
import EditIcon from '../../../foundation/assets/svgs/EditIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAvailablePlans,
  selectClientIp,
  selectIsSubscribed,
  selectOccupiedSeats,
  selectStripeCustomerId,
  selectSubscriptionPlanDetails,
  selectUser,
} from '../../user/redux/selectors';
import { setStripeClientSecretId } from '../../user/redux/slice';
import { SubscriptionPlan } from '../../user/redux/types';
import SectionTitle from '../components/section_components/SectionTitle';
import {
  BillingTabKeys,
  InvoiceType,
  PromoCodeDetailsType,
  SubscriptionFrequency,
} from '../helpers/types';
import {
  createSubscription,
  SubscriptionBody,
  updateSubscription,
} from '../redux/async_thunks';
import Summary from '../summary/Summary';
import AddUserModal from './add_user_modal/AddUserModal';
import PlanModal from './plan_modal/PlanModal';

type PlanInformationTypes = {
  subscriptionFrequency: SubscriptionFrequency;
  setSubscriptionFrequency: (v: SubscriptionFrequency) => void;
  selectedPlan: any;
  setSelectedPlan: (v: any) => void;
  isBillingLoading: boolean;
  setIsBillingLoading: (v: boolean) => void;
  setActiveTab: (v: BillingTabKeys) => void;
  activeTab: BillingTabKeys;
  setPromoCodeDetails: (v: PromoCodeDetailsType | undefined) => void;
  promoCodeDetails: PromoCodeDetailsType | undefined;
  invoiceData: InvoiceType | undefined;
  setInvoiceData: (v: InvoiceType) => void;
  additionalUsers: number;
  setAdditionalUsers: (v: number) => void;
};

const PlanInformation = ({
  subscriptionFrequency,
  selectedPlan,
  setSelectedPlan,
  setSubscriptionFrequency,
  isBillingLoading,
  setIsBillingLoading,
  setActiveTab,
  activeTab,
  setPromoCodeDetails,
  promoCodeDetails,
  setInvoiceData,
  additionalUsers,
  setAdditionalUsers,
}: PlanInformationTypes) => {
  const dispatch = useAppDispatch();

  const [isPlanModalVisible, setPlanModalVisibility] = useState(false);

  const [isAddUserModalVisible, setAddUserModalVisibility] = useState(false);

  const availablePlans = useAppSelector(selectAvailablePlans);

  // Contains the old subscription data
  const subscriptionPlanDetails = useAppSelector(selectSubscriptionPlanDetails);

  // True if a user already has a paid subscription.
  const isSubscribed = useAppSelector(selectIsSubscribed);

  const user = useAppSelector(selectUser);

  const clientIp = useAppSelector(selectClientIp);

  const customerId = useAppSelector(selectStripeCustomerId);

  const occupiedSeats = useAppSelector(selectOccupiedSeats);

  const handleCheckboxChange =
    (item: SubscriptionPlan) => (e: CheckboxChangeEvent) => {
      if (!item.additionalUsersAllowed) {
        setAdditionalUsers(0);
      }
      setSelectedPlan(item);
    };

  const onAddAdditionalUserClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (selectedPlan && selectedPlan.additionalUsersAllowed) {
      setAddUserModalVisibility(true);
    }
  };

  const seatLabel = occupiedSeats ? 'Occupied' : 'Additional';

  const planInfo = useMemo(() => {
    const planElements = [];

    const frequencyString = subscriptionFrequency === 'Annually' ? 'yr' : 'mo';

    if (availablePlans) {
      for (const item of availablePlans) {
        let planRate = 0;

        for (const rate of item.planRates) {
          if (rate.frequency === subscriptionFrequency) {
            planRate = rate.subscriptionAmount;
          }
        }

        planElements.push(
          <th>
            <strong>{item.subscriptionPlan}</strong>
            <br />
            <span>
              ${planRate}/{frequencyString}
            </span>
            <br />
            <span> 1 seat</span>
          </th>,
        );
      }
    }

    return planElements;
  }, [availablePlans, subscriptionFrequency]);

  const planSelectionElement = useMemo(() => {
    const items = [];

    if (availablePlans) {
      for (const item of availablePlans) {
        items.push(
          <td>
            <Checkbox
              value={item.subscriptionPlanId}
              onChange={handleCheckboxChange(item)}
              checked={
                selectedPlan &&
                selectedPlan.subscriptionPlanId === item.subscriptionPlanId
              }
            />
          </td>,
        );
      }
    }

    return items;
  }, [availablePlans, selectedPlan]);

  const handleSubscription = async () => {
    if (isBillingLoading) {
      return;
    }
    try {
      if (customerId) {
        setIsBillingLoading(true);
        const data: SubscriptionBody = {
          userId: user.userId,
          customerId: customerId,
          subscriptionPlanTypeId: selectedPlan.subscriptionPlanId,
          subscriptionFrequency: subscriptionFrequency,
          additionalUsers: additionalUsers,
        };

        if (promoCodeDetails && promoCodeDetails.promoCode) {
          data.promoCode = promoCodeDetails.promoCode;
        }
        if (subscriptionPlanDetails && subscriptionPlanDetails.subscriptionId) {
          data.subscriptionId = subscriptionPlanDetails.subscriptionId;
        }

        const options = {
          token: user.jwtToken.token,
          clientIp: clientIp,
          sessionId: user.sessionId,
        };

        let susbcription: any = null;
        if (isSubscribed) {
          susbcription = await dispatch(
            updateSubscription({ data, options }),
          ).unwrap();
        } else {
          susbcription = await dispatch(
            createSubscription({ data, options }),
          ).unwrap();
        }

        setInvoiceData(susbcription.invoiceDetails);

        setActiveTab('payment');

        setIsBillingLoading(false);
      }
    } catch (error) {
      setIsBillingLoading(false);
      console.log(error);
    }
  };

  const handlePlanModalCancel = () => {
    setPlanModalVisibility(false);
  };

  const handleAdUserModalCancel = () => {
    setAddUserModalVisibility(false);
  };

  const handleAddNewUser = (v: number) => {
    setAdditionalUsers(v);
    handleAdUserModalCancel();
  };

  useEffect(() => {
    if (activeTab === 'info') {
      dispatch(setStripeClientSecretId(undefined));
    }
  }, [activeTab]);

  return (
    <div className="bill-plan-info">
      {isAddUserModalVisible && (
        <AddUserModal
          handleCancel={handleAdUserModalCancel}
          occupiedSeats={occupiedSeats}
          onSubmit={handleAddNewUser}
          value={additionalUsers}
        />
      )}
      {isPlanModalVisible && (
        <PlanModal
          handleCancel={handlePlanModalCancel}
          availablePlans={availablePlans ? availablePlans : []}
          subscriptionFrequency={subscriptionFrequency}
        />
      )}
      <SectionTitle title=" Please confirm your plan and seats" />

      <div className="bill-plan-info__sections">
        <div className="bill-plan-info__info-section">
          <table className="bill-plan-info__plan-table">
            <thead>
              <tr>
                <th className="bill-plan-info__plan-table-col-1">
                  <Button
                    className="bill-plan-info__btn-view-plan"
                    onClick={() => setPlanModalVisibility(true)}
                  >
                    View Plans
                  </Button>
                </th>
                {planInfo}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bill-plan-info__plan-table-col-1">
                  <div className="bill-plan-info__user-details">
                    <Avatar
                      icon={<UserAddOutlined />}
                      size={'large'}
                      style={{
                        backgroundColor: '#651fff',
                        marginRight: 10,
                      }}
                    ></Avatar>
                    <div>
                      <div className="bill-plan-info__user-details-title">
                        Included seats in your <br />
                        Subscription
                      </div>
                      <div className="bill-plan-info__user-details-desc">
                        1 x seat
                      </div>
                    </div>
                  </div>
                </td>
                {planSelectionElement}
              </tr>
              {(occupiedSeats || additionalUsers > 0) && (
                <tr>
                  <td className="bill-plan-info__plan-table-col-1">
                    <div className="bill-plan-info__user-details">
                      <Avatar
                        icon={<UserAddOutlined />}
                        size={'large'}
                        style={{
                          backgroundColor: '#C3BEFC',
                          marginRight: 10,
                          color: '#651FFF',
                        }}
                      ></Avatar>
                      <div>
                        <div className="bill-plan-info__user-details-title">
                          {seatLabel} Seats
                          <br />
                        </div>
                        <div className="bill-plan-info__user-details-desc">
                          {`${additionalUsers} x ${seatLabel} ${
                            additionalUsers > 1 ? 'seats' : 'seat'
                          }`}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td colSpan={3}>
                    <div className="bill-plan-info__additional-user-options">
                      {selectedPlan && selectedPlan.additionalUsersAllowed && (
                        <div
                          className="bill-plan-info__additional-user-options--edit"
                          onClick={onAddAdditionalUserClick}
                        >
                          <EditIcon />
                        </div>
                      )}
                      {!occupiedSeats && (
                        <div
                          className="bill-plan-info__additional-user-options--delete"
                          onClick={() => setAdditionalUsers(0)}
                        >
                          <Bin />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {!occupiedSeats && !additionalUsers && (
                <tr>
                  <td className="bill-plan-info__plan-table-col-1">
                    <div
                      className={`bill-plan-info__plan-add-user-wrapper ${
                        selectedPlan !== 'lite'
                          ? ' bill-plan-info__plan-add-user-wrapper--active'
                          : ''
                      }`}
                      onClick={onAddAdditionalUserClick}
                    >
                      <div className="bill-plan-info__plan-add-icon">
                        <PlusOutlined />
                      </div>
                      <div className="bill-plan-info__plan-add-desc">
                        Add additional seats <br />
                        $50/mo per seat
                      </div>
                    </div>
                  </td>
                  {selectedPlan && !selectedPlan.additionalUsersAllowed && (
                    <td
                      colSpan={3}
                      className="bill-plan-info__plan-add-user-req-statement"
                    >
                      (Available on Pro and Pro+ plans only)
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Summary
          disabled={false}
          subscriptionFrequency={subscriptionFrequency}
          selectedPlan={selectedPlan}
          additionalUsers={additionalUsers}
          setSubscriptionFrequency={setSubscriptionFrequency}
          onButtonClick={handleSubscription}
          buttonTitle={`Next: Payment Information`}
          promoCodeDetails={promoCodeDetails}
          setPromoCodeDetails={setPromoCodeDetails}
        />
      </div>
    </div>
  );
};

export default PlanInformation;
