import React from 'react';

type Props = {
  color?: string;
};

function ArrowLeftTail({ color }: Props) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0192 1.85253C6.30599 1.55366 6.2962 1.07889 5.99733 0.792098C5.69846 0.505307 5.22369 0.5151 4.93689 0.813971L6.0192 1.85253ZM1 5.99992L0.458846 5.48064C0.180385 5.77083 0.180385 6.22901 0.458846 6.5192L1 5.99992ZM4.93689 11.1859C5.22369 11.4847 5.69846 11.4945 5.99733 11.2077C6.2962 10.9209 6.30599 10.4462 6.0192 10.1473L4.93689 11.1859ZM1.84458 5.27108C1.43037 5.27108 1.09458 5.60686 1.09458 6.02108C1.09458 6.43529 1.43037 6.77108 1.84458 6.77108V5.27108ZM12.3333 6.77108C12.7475 6.77108 13.0833 6.43529 13.0833 6.02108C13.0833 5.60686 12.7475 5.27108 12.3333 5.27108V6.77108ZM4.93689 0.813971L0.458846 5.48064L1.54115 6.5192L6.0192 1.85253L4.93689 0.813971ZM0.458846 6.5192L4.93689 11.1859L6.0192 10.1473L1.54115 5.48064L0.458846 6.5192ZM1.84458 6.77108H12.3333V5.27108H1.84458V6.77108Z"
        fill={color ? color : '#651fff'}
      />
    </svg>
  );
}

export default ArrowLeftTail;
