import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectListContainerSize,
} from '../../../redux/selectors';
import LineChart from '../key_indicators/line_chart/LineChart';
import { postSuburbProjects } from '../redux/async_thunks';
import {
  selectDescriptors,
  selectProjects,
  selectProjectSettings,
  selectProjectsValues,
} from '../redux/selectors';
import {
  setIsGraphLoading,
  setKeyIndicatorsGraphData,
  setProjectSettings,
} from '../redux/slice';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const Projects = ({ setAreDetailsLoading }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const user = useAppSelector(selectUser);
  const suburb = useAppSelector(selectActiveSuburb);
  const clientIp = useAppSelector(selectClientIp);
  const sscCode = useAppSelector(selectActiveSuburbSscCode);

  const projectsData = useAppSelector(selectProjects);
  const listContainerSize = useAppSelector(selectListContainerSize);

  const projectValues = useAppSelector(selectProjectsValues);

  const projectSettings = useAppSelector(selectProjectSettings);
  const [prevOrderType, setPrevOrderType] = useState<string>();

  const descriptor = useAppSelector(selectDescriptors).projects;

  const [pageIndex, setPageIndex] = useState(1);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Projects tab',
      description: 'Something went wrong with the request',
    });
  };

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const fetchProjects = async (kmRadiusParam?: any, orderTypeParam?: any) => {
    try {
      toggleLoader(true);

      const param =
        orderTypeParam === null || !!orderTypeParam
          ? orderTypeParam
          : projectSettings.orderType || null;

      await dispatch(
        postSuburbProjects({
          data: {
            kmRadius: kmRadiusParam || projectSettings.kmRadius,
            sscCode: sscCode,
            pageIndex: pageIndex,
            userId: user.userId,
            orderType: param,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      if (param) {
        setPrevOrderType(
          param === 'Industry'
            ? 'category'
            : param[0].toLowerCase() + param.slice(1),
        );
      }
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  };

  return (
    <View
      listContainerSize={listContainerSize}
      isFirstLoad={isFirstLoad}
      fetchProjects={fetchProjects}
      user={user}
      suburb={suburb}
      projectSettings={projectSettings}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      projectsData={projectsData}
      projectValues={projectValues}
      isLoading={isLoading}
      prevOrderType={prevOrderType}
      descriptor={descriptor}
      setIsGraphLoading={setIsGraphLoading}
      setKeyIndicatorsGraphData={setKeyIndicatorsGraphData}
      setProjectSettings={setProjectSettings}
      LineChart={LineChart}
    />
  );
};

export default Projects;
