import { useEffect, useState } from 'react';

import { postMapSearchResults } from '../../features/search/redux/async_thunks';
import {
  selectEnabledSearchMetrics,
  selectMapSearchSscCodes,
  selectOptions,
  selectSearchMetricValues,
} from '../../features/search/redux/selectors';
import {
  resetActiveProperty,
  resetActiveSuburb,
  setIsMapSearchLoading,
} from '../../features/search/redux/slice';
import { prepareSearchMetrics } from '../../features/search/utils/functions';
import {
  selectClientIp,
  selectUser,
} from '../../features/user/redux/selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useSearchType from './useSearchType';

const useMapSearch = () => {
  const dispatch = useAppDispatch();
  const searchType = useSearchType();

  const DEFAULT_SEARCH_OPTIONS = {
    beforeCb: undefined as any,
  };

  const [searchOptions, setSearchOptions] = useState<any>(null);

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const options = useAppSelector(selectOptions);
  const mapSearchSscCodes = useAppSelector(selectMapSearchSscCodes);
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const searchMetricValues = useAppSelector(selectSearchMetricValues);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResults = async () => {
    const { beforeCb } = searchOptions || DEFAULT_SEARCH_OPTIONS;

    dispatch(setIsMapSearchLoading(true));

    if (beforeCb) {
      beforeCb();
    }

    if (searchType === 'suburb') {
      dispatch(resetActiveProperty());
    } else {
      dispatch(resetActiveSuburb());
    }

    try {
      const searchMetricsChecked = prepareSearchMetrics(
        options,
        enabledSearchMetrics,
        searchMetricValues,
      );

      await dispatch(
        postMapSearchResults({
          data: {
            ...{ userId: USER.userId },
            ...{
              sscCodes: searchOptions.sccCodes || mapSearchSscCodes,
              searchValues: null,
            },
            ...options,
            ...{
              pageIndex: 1,
              searchMetrics: searchMetricsChecked,
            },
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: searchType,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setIsMapSearchLoading(false));

    setSearchOptions(null);
  };

  useEffect(() => {
    if (searchOptions) {
      fetchResults();
    }
  }, [searchOptions]);

  return setSearchOptions;
};

export default useMapSearch;
