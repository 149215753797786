import React from 'react';
import { useSelector } from 'react-redux';

import MarkerLight from '../../../foundation/assets/svgs/MarkerLight';
import { useAppDispatch } from '../../../store/hooks';
import { resetSiderMobileContentValues } from '../../layout/redux/slice';
import {
  resetActiveProperty,
  resetGpsPoints,
  resetResults,
  setActiveSuburbSscCode,
  setIsFirstAttempt,
  setIsMapSearchEnabled,
  // setSearchType,
} from '../../search/redux/slice';
import { selectUserSuburbFavorites } from '../redux/selectors';
import { Suburb } from '../redux/types';
import FavListItem from './FavListItem';

const FavSuburbs = ({
  handleFavSuburbDelete,
  handlePopoverVisibility,
}: {
  handleFavSuburbDelete: any;
  handlePopoverVisibility?: (param: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const favoriteSuburbs = useSelector(selectUserSuburbFavorites);

  const handleFavoriteSuburbView = (suburb: Suburb) => {
    dispatch(resetActiveProperty());
    dispatch(setActiveSuburbSscCode(suburb.sscCode));
    dispatch(setIsFirstAttempt(false));
    dispatch(resetResults());
    dispatch(resetGpsPoints());
    dispatch(setIsMapSearchEnabled(false));

    if (handlePopoverVisibility) {
      handlePopoverVisibility(false);
    } else {
      dispatch(resetSiderMobileContentValues(undefined));
    }
  };

  return (
    <div className="c-favorite__content">
      {favoriteSuburbs?.map((suburb) => (
        <FavListItem
          key={suburb.suburbCode}
          mainTitle={`${suburb.suburbName}, ${suburb.state} ${suburb.postCode}`}
          subTitle={`Saved suburb`}
          icon={<MarkerLight />}
          deleteListItem={() =>
            handleFavSuburbDelete({
              property: 'sscCode',
              id: suburb.sscCode,
              searchType: 'suburb',
            })
          }
          viewListItem={() => handleFavoriteSuburbView(suburb)}
        />
      ))}
      {(!favoriteSuburbs || !favoriteSuburbs.length) && (
        <p className="c-favorite__empty">
          <strong>No saved suburbs, yet.</strong>
          <span>Your favourite suburbs will appear here when saved.</span>
        </p>
      )}
    </div>
  );
};

export default FavSuburbs;
