import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteLogout } from '../../features/user/redux/async_thunks';
import {
  selectClientIp,
  selectUser,
} from '../../features/user/redux/selectors';
import { deleteFirebaseToken } from '../../internals/firebase/firebase';
import { decryptData } from '../utils/api';

const useLogout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const [logoutUser, setLogoutStatus] = useState<boolean | string>(false);

  const user = useSelector(selectUser);
  const clientIp = useSelector(selectClientIp);

  const getQueryString = () => {
    if (typeof logoutUser === 'boolean') {
      return '';
    }

    if (typeof logoutUser === 'string') {
      if (logoutUser === 'failed') {
        return '?f=1';
      }

      return logoutUser === 'delete' ? '?d=1' : '?e=' + encodeURI(logoutUser);
    }
  };

  const proceedRedirect = async () => {
    try {
      await deleteFirebaseToken();
    } catch (e) {
      console.log(e);
    }

    const queryString = getQueryString();
    const returnTo = queryString
      ? `${window.location.origin}/logout${queryString}`
      : `${window.location.origin}`;

    logout({
      returnTo: returnTo,
    });

    setLogoutStatus(false);
  };

  const handleLogout = async (options: any, data: any) => {
    try {
      if (options && data) {
        await dispatch(
          // @ts-ignore
          deleteLogout({
            options,
            data,
          }),
        ).unwrap();

        proceedRedirect();
        sessionStorage.clear();
      }
    } catch (error) {
      console.log(error);
      setLogoutStatus(false);
    }
  };

  useEffect(() => {
    if (logoutUser) {
      let params = user;

      if (!params) {
        const storedUser = sessionStorage.getItem('_u');

        if (storedUser) {
          params = decryptData(storedUser);
        }
      }

      const options = {
        token: params?.jwtToken.token,
        clientIp: clientIp,
        sessionId: params?.sessionId,
      };

      const data = {
        userId: params?.userId,
        clientIp: clientIp,
      };

      handleLogout(options, data);
    }
  }, [logoutUser]);

  return setLogoutStatus;
};

export default useLogout;
