import { notification } from 'antd';

import messages from '../../../foundation/messages';
import { fetchNextUsers } from '../utils/controllers/dmListController';

const fetchUsersByName = (name: string) => {
  return fetchNextUsers(name)
    .then((userList) => {
      return userList.map((item: any) => {
        return {
          label: item.name,
          value: item.uid,
        };
      });
    })
    .catch((error) => {
      console.log(error);
      notification.error({
        message: 'Error!',
        description: messages.generic.SOMETHING_WENT_WRONG,
      });
    });
};

export default fetchUsersByName;
