import React from 'react';
import { useSelector } from 'react-redux';

import View from '../../../../search/view/details/key_indicators/line_chart/view/View';
import {
  getGraphByType,
  getGraphIsLoadingByKey,
} from '../../../redux/selectors';

const LineChart = ({
  graphType,
  isXUnitsInMonths,
  yMaxTicksLimit,
  style,
  isProjects,
}: any) => {
  const graphData = useSelector(getGraphByType(graphType));
  const isLoading = useSelector(getGraphIsLoadingByKey(graphType));

  return (
    <View
      isXUnitsInMonths={isXUnitsInMonths}
      yMaxTicksLimit={yMaxTicksLimit}
      style={style}
      isProjects={isProjects}
      graphType={graphType}
      graphData={graphData}
      isLoading={isLoading}
    />
  );
};

export default LineChart;
