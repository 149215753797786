import { useAuth0 } from '@auth0/auth0-react';
import { Button, Result } from 'antd';
import React from 'react';

const SessionExpired = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLoginClick = () => {
    loginWithRedirect();
  };

  return (
    <Result
      status="warning"
      title="Session Expired"
      subTitle="Please log in again."
      extra={
        <Button type="primary" onClick={handleLoginClick}>
          Login
        </Button>
      }
    />
  );
};

export default SessionExpired;
