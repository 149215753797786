import React from 'react';

function LogoWithLines() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="40"
      fill="none"
      viewBox="0 0 58 40"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.31 17.56c0-8.17 6.633-14.782 14.802-14.782 8.168 0 14.802 6.611 14.802 14.782 0 4.19-2.453 8.266-5.598 11.731-3.115 3.433-6.721 6.062-8.627 7.342a1.075 1.075 0 01-1.2.012c-1.898-1.22-5.441-3.713-8.518-7.055l4.066-3.841a9.327 9.327 0 005.191 1.566c5.15 0 9.325-4.145 9.325-9.26 0-5.113-4.175-9.259-9.325-9.259-5.15 0-9.324 4.146-9.324 9.26 0 2.201.773 4.223 2.065 5.812l-4.748 4.485a1.38 1.38 0 00-.432 1.085c-.064.389.038.802.316 1.125 3.483 4.055 7.714 7.032 9.863 8.414a3.89 3.89 0 004.288-.043c1.994-1.339 5.808-4.113 9.138-7.783 3.3-3.637 6.318-8.364 6.318-13.59C37.712 7.868 29.839 0 20.112 0s-17.6 7.869-17.6 17.56c0 2.54.658 4.934 1.688 7.121.327.695 1.16.995 1.86.67a1.385 1.385 0 00.674-1.846c-.893-1.897-1.425-3.888-1.425-5.945zm14.918 6.977c3.605 0 6.527-2.901 6.527-6.481s-2.922-6.482-6.527-6.482-6.527 2.902-6.527 6.482c0 1.85.78 3.52 2.033 4.7a1.404 1.404 0 01.288.256 6.528 6.528 0 004.206 1.525z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#D9DBE9"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M43 15h12M43 25h9"
      ></path>
    </svg>
  );
}

export default LogoWithLines;
