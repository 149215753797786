import { CometChat } from '@cometchat-pro/chat';

import * as enums from '../enums';

export const fetchNextConversation = (chatListMode: string) => {
  let conversationRequest = null;
  const chatListFilterOptions = enums.chatListFilterOptions;

  switch (chatListMode) {
    case chatListFilterOptions['USERS']:
      conversationRequest = new CometChat.ConversationsRequestBuilder()
        .setConversationType(CometChat.ACTION_TYPE.TYPE_USER)
        .setLimit(15)
        .build();
      break;
    case chatListFilterOptions['GROUPS']:
      conversationRequest = new CometChat.ConversationsRequestBuilder()
        .setConversationType(CometChat.ACTION_TYPE.TYPE_GROUP)
        .setLimit(15)
        .build();
      break;
    default:
      conversationRequest = new CometChat.ConversationsRequestBuilder()
        .setLimit(15)
        .build();
      break;
  }

  return conversationRequest.fetchNext();
};

/**
 * Fetches the group members of a particular channel.
 * @param guid Unique id of group
 * @returns Group members array
 */
export const fetchChannelMembers = (guid: string) => {
  const groupMemberRequest = new CometChat.GroupMembersRequestBuilder(guid)
    .setLimit(10)
    .build();

  return groupMemberRequest.fetchNext();
};

// TODO: Add and remove event listeners.
