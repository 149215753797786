import React from 'react';

function OpenModal() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 14L19.5 17M19.5 17L17 20M19.5 17H12M18 10.5V1H1V21H10.5M5.5 6H14M5.5 10.5H11"
        stroke="#4E4B66"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default OpenModal;
