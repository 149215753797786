import { useMemo } from 'react';

import { PlanRate } from '../../../user/redux/types';
import { getParsedValue } from '../getParsedValue';

export const useSubTotal = (
  additionalUsers: number,
  planRate: PlanRate | undefined,
) => {
  const subTotal = useMemo(() => {
    if (planRate) {
      const additionalUserAmout =
        planRate?.additionalUserAmount * additionalUsers;

      return getParsedValue(additionalUserAmout + planRate.subscriptionAmount);
    }
    return 0;
  }, [additionalUsers, planRate]);

  return subTotal;
};
