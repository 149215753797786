import { notification } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import MarkerLight from '../../../foundation/assets/svgs/MarkerLight';
import SuburbHomeIcon from '../../../foundation/assets/svgs/SuburbHomeIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetSiderMobileContentValues } from '../../layout/redux/slice';
import { postSearchResults } from '../../search/redux/async_thunks';
import {
  resetActiveProperty,
  resetActiveSuburb,
  resetSuggestions,
  searchMetricsDefaults,
  setEnabledSearchMetrics,
  setIsFirstAttempt,
  setIsMapSearchEnabled,
  setIsSearchFeatureLoading,
  setKeyword,
  setOptions,
  setQueryValues,
  setSearchType,
} from '../../search/redux/slice';
import {
  selectClientIp,
  selectUser,
  selectUserSearchFavorites,
} from '../../user/redux/selectors';
import { Search as SearchType } from '../redux/types';
import FavListItem from './FavListItem';

const FavSearches = ({
  handleFavSearchDelete,
  handlePopoverVisibility,
}: {
  handleFavSearchDelete: (
    search: SearchType,
    searchType: string,
  ) => Promise<any>;
  handlePopoverVisibility?: (param: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const favoriteSearches = useSelector(selectUserSearchFavorites);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResults = async (search: any, searchType: string) => {
    if (handlePopoverVisibility) {
      handlePopoverVisibility(false);
    } else {
      dispatch(resetSiderMobileContentValues(undefined));
    }

    dispatch(setIsSearchFeatureLoading(true));

    try {
      await dispatch(
        postSearchResults({
          data: {
            userId: USER.userId,
            searchValues: search.searchValues,
            searchMetrics: search.searchMetrics,
            propertyType: search.propertyType,
            pageIndex: 1,
            sortType: null,
            orderAsc: true,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: searchType,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      dispatch(resetActiveSuburb());
      dispatch(resetActiveProperty());
      dispatch(setKeyword(undefined));
      dispatch(resetSuggestions());
      dispatch(setQueryValues([]));

      dispatch(setSearchType(searchType));

      dispatch(
        setOptions({
          pageIndex: 1,
          sortType: null,
          orderAsc: true,
          propertyType: search.propertyType,
          // @ts-ignore
          searchMetrics: {
            ...searchMetricsDefaults[searchType],
            ...search.searchMetrics,
          },
        }),
      );

      if (search && search.searchMetrics) {
        dispatch(
          setEnabledSearchMetrics(
            Object.getOwnPropertyNames(search.searchMetrics),
          ),
        );
      } else {
        dispatch(setEnabledSearchMetrics([]));
      }

      notification.success({
        message: 'Success',
        description: `${search.name} has been loaded.`,
        placement: 'topRight',
        top: 100,
      });

      navigate(`/${searchType}-search`);
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setIsSearchFeatureLoading(false));
    dispatch(setIsFirstAttempt(false));
  };

  return (
    <div className="c-favorite__content">
      {favoriteSearches?.suburbSearches?.map((search) => (
        <FavListItem
          key={search.id}
          mainTitle={`${search.name}`}
          icon={
            <MarkerLight
              // @ts-ignore
              color={search.propertyType === 'Houses' ? '#651fff' : '#00ADD2'}
            />
          }
          // @ts-ignore
          propertyType={search.propertyType}
          deleteListItem={() => handleFavSearchDelete(search, 'suburb')}
          viewListItem={() => {
            dispatch(setIsMapSearchEnabled(false));
            setTimeout(() => {
              fetchResults(search, 'suburb');
            }, 800);
          }}
          preTitle="Suburb Search"
        />
      ))}
      {favoriteSearches?.propertySearches?.map((search) => (
        <FavListItem
          key={search.id}
          mainTitle={`${search.name}`}
          icon={
            <SuburbHomeIcon
              // @ts-ignore
              color={search.propertyType === 'Houses' ? '#651fff' : '#00ADD2'}
            />
          }
          // @ts-ignore
          propertyType={search.propertyType}
          deleteListItem={() => handleFavSearchDelete(search, 'property')}
          viewListItem={() => fetchResults(search, 'property')}
          preTitle="Property Search"
        />
      ))}
      {(!favoriteSearches ||
        (!favoriteSearches.suburbSearches &&
          !favoriteSearches.propertySearches) ||
        (!favoriteSearches.suburbSearches?.length &&
          !favoriteSearches.propertySearches?.length)) && (
        <p className="c-favorite__empty">
          <strong>No saved searches, yet.</strong>
          <span>Your favourite searches will appear here when saved.</span>
        </p>
      )}
    </div>
  );
};

export default FavSearches;
