import React from 'react';

type Props = {
  strokeColor?: string;
};
function BellIcon({ strokeColor }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      fill="none"
      viewBox="0 0 24 23"
    >
      <path
        stroke={strokeColor ? strokeColor : '#4E4B66'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.333 8.014c0-1.86-.772-3.644-2.147-4.96C15.81 1.74 13.945 1 12 1c-1.945 0-3.81.739-5.185 2.054-1.376 1.316-2.148 3.1-2.148 4.96 0 5.934-2.454 8.795-3.803 9.92-.203.168-.07.602.193.602h6.465c.117 0 .219.08.253.191C8 19.453 9 22 12 22c3.001 0 4-2.547 4.225-3.273a.267.267 0 01.253-.19h6.465c.264 0 .395-.435.193-.604-1.349-1.124-3.803-3.985-3.803-9.919z"
      ></path>
    </svg>
  );
}

export default BellIcon;
