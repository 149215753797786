import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Collapse, Row } from 'antd';
import React, { useEffect } from 'react';

import { useAppDispatch } from '../../../../../../store/hooks';
import SearchLoader from '../../../../form/search/search_loader/SearchLoader';

const PriceRentForecast = ({
  user,
  suburb,
  timelineType,
  setTimelineType,
  keyIndicators,
  fetchForecastData,
  isLoading,
  LineChart,
}: any) => {
  const dispatch = useAppDispatch();
  const { Panel } = Collapse;

  const graphTypes = [
    {
      key: 'Median Sales Price Prediction Forecast',
      value: 'MedianSalesPricePrediction',
    },
    {
      key: 'Median Weekly Asking Rent Prediction Forecast',
      value: 'MedianWeeklyAskingRentPrediction',
    },
  ];

  const timelineOptions = keyIndicators
    ? keyIndicators?.timeLineTypes.map((t: any) => {
        return {
          ...t,
          value: t.value.toString(),
        };
      })
    : [];

  const groupFetch = () => {
    if (user && timelineType && suburb) {
      fetchForecastData();
    }
  };

  useEffect(() => {
    groupFetch();
  }, [suburb, timelineType]);

  if (!suburb) {
    return null;
  }

  const renderTimelineInput = () => {
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: '16px',
      border: '1px solid #d9dbe9',
      padding: '16px',
      maxWidth: '430px',
      margin: '0 auto',
    };

    const labelStyle = {
      fontWeight: 'bold',
      fontSize: '16px',
      marginBottom: '9px',
    };

    return (
      <div style={{ ...containerStyle, flexDirection: 'column' }}>
        <p style={labelStyle}>Graph Timeline</p>
        <ul className="l-select-year">
          {timelineOptions.map((option: any, i: any) => {
            return (
              <li
                className={`l-select-year__option${
                  option.value === timelineType
                    ? ' l-select-year__option--active'
                    : ''
                }`}
                key={i}
                data-value={option.value}
                onClick={(e) => {
                  dispatch(setTimelineType(e.currentTarget.dataset.value));
                }}
              >
                {option.value}Y
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  if (isLoading) {
    return (
      <Col span={24}>
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <Row gutter={[0, 15]}>
              <div
                style={{
                  position: 'relative',
                  minHeight: '100px',
                  width: '100%',
                }}
              >
                <SearchLoader />
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col span={24}>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            {renderTimelineInput()}
            {graphTypes.map((g: any, i: number) => {
              const cardStyle = { marginBottom: '0', paddingBottom: '0' };
              const cardNameStyle = {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              };
              const tagStyle = {
                display: 'inline-block',
                padding: '3px 6px',
                borderRadius: '5px',
                background: '#C7F4FF',
                fontWeight: 'bold',
                marginLeft: '10px',
                fontSize: '12px',
              };

              const graphName = g.key;

              return (
                <Col span={24} key={i}>
                  <div className="l-dashboard-card">
                    <div className="l-dashboard-card__body">
                      <div
                        className="l-dashboard-card__title"
                        style={cardStyle}
                      >
                        <span style={cardNameStyle}>
                          {graphName}
                          <span style={tagStyle}>Exclusive to Rhomeo</span>
                        </span>
                      </div>
                      <LineChart graphType={g.value} />
                    </div>
                  </div>
                </Col>
              );
            })}
            <div className="l-more-info" style={{ width: '100%' }}>
              <h4>Important Information</h4>
              <Collapse
                bordered={false}
                accordion={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel header={<h5>How it works</h5>} key="1">
                  <p>
                    Predicting suburb median prices and rents to identify
                    suitable markets to invest in isn&apos;t a simple task.
                  </p>
                  <p>
                    No two markets are the same in time, and individual markets
                    change over time. New developments open, houses deteriorate,
                    children are born, demographics change, populations shift,
                    and the world progressively enters new eras of technology
                    that drive how, when and where people work and live.
                  </p>
                  <p>
                    When this is combined with predicting domestic and
                    international macroeconomic forces, and the complex dynamics
                    that drive every local economy, it seems somewhat unfair to
                    give the task of selecting an investment property to any
                    mere mortal. Predicting all the drivers of property prices
                    is simply too complex.
                  </p>
                  <p>
                    This is why rhomeo exists. Specifically, the rhomeo team
                    sought to develop and test a data-driven and artificial
                    intelligence (AI) model to achieve this goal at least as
                    successfully as the best human practitioners, but:
                    <ul>
                      <li>at greater speed;</li>
                      <li>more consistently; and</li>
                      <li>with enhanced quantitative assessment.</li>
                    </ul>
                  </p>
                  <p>
                    To feed this model over 460 (and keep counting) macro and
                    micro economic indicators were tested to assess their impact
                    on suburbs&apos; prices and rents. Of these, only 60 were
                    deemed significant and selected to trained rhomeo predictive
                    model.
                  </p>
                  <p>
                    Interestingly enough, of these 60 shortlisted indicators, 21
                    are industry standards such as median sales price, volume of
                    auction listings, volume of new rental listings, etc. The
                    remaining 39 are indicators developed in-house, some of
                    which are currently being considered for patenting.
                  </p>
                </Panel>
                <Panel header={<h5>Disclaimer</h5>} key="2">
                  <p>
                    The price and rent forecast model must not be relied upon as
                    an accurate representation of the of the future median price
                    and rent of the subject suburb. Rhomeo does not warrant the
                    accuracy of its price and rent forecasting model and to the
                    full extent permitted by law, rhomeo excludes all liability
                    for any loss or damage howsoever arising in connection with
                    a price and rent forecast.
                  </p>
                </Panel>
              </Collapse>
            </div>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default PriceRentForecast;
