import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectBreakpoint } from '../../features/layout/redux/selectors';

const useBreakpoint = () => {
  const breakpoint = useSelector(selectBreakpoint);

  const isMobileView = useMemo(() => breakpoint === 'mobile', [breakpoint]);

  return [isMobileView, !isMobileView];
};

export default useBreakpoint;
