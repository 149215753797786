import React from 'react';

import Needle from '../../../../../foundation/assets/svgs/Needle';

const EstimateGauge = ({
  estimate,
  keyName,
  unit,
}: {
  estimate: {
    confidenceDesc?: string | null;
    confidenceValue?: number | null;
    value?: string | null;
  };
  keyName: string;
  unit?: string;
}) => {
  const COLORS: any = {
    low: '#ff4d4f',
    medium: '#eaac30',
    high: '#008a00',
  };

  const confidenceValueConverted = (estimate.confidenceValue || 0) * 100;
  const hasEstimateValue =
    estimate.value !== null && estimate.value !== undefined;

  const getConfidenceLevelText = () => {
    if (
      !!estimate.confidenceDesc &&
      estimate.confidenceDesc.includes('Medium')
    ) {
      return 'medium';
    } else if (
      !!estimate.confidenceDesc &&
      estimate.confidenceDesc.includes('High')
    ) {
      return 'high';
    }

    return 'low';
  };

  const computeNeedleAngle = () => {
    if (confidenceValueConverted < 50) {
      return confidenceValueConverted * 1.8 - 90;
    }

    return (confidenceValueConverted - 50) * 1.8;
  };

  const textDisplay = getConfidenceLevelText();

  return (
    <div className="c-estimate-gauge">
      <div className="c-estimate-gauge__values">
        <span>Estimated</span>
        <div className="c-estimate-gauge__key-name">{keyName}</div>
        <div className="c-estimate-gauge__value">
          {hasEstimateValue ? estimate.value : 'n/a'}
          {!!unit && hasEstimateValue ? unit : ''}
        </div>
      </div>
      <div className="c-estimate-gauge__gauge">
        <div className="c-estimate-gauge__progress">
          <div
            className="c-estimate-gauge__needle"
            style={{ transform: `rotate(${computeNeedleAngle()}deg)` }}
          >
            <Needle />
          </div>
          <div className="c-estimate-gauge__overflow">
            <div
              className="c-estimate-gauge__bar-container"
              // 100% = 180° -> 1° = 1% * 1.8
              style={{
                transform: `rotate(${45 + confidenceValueConverted * 1.8}deg)`,
              }}
            >
              <div
                className="c-estimate-gauge__bar"
                style={{
                  borderBottomColor: COLORS[textDisplay],
                  borderRightColor: COLORS[textDisplay],
                }}
              ></div>
            </div>
          </div>
        </div>
        <p
          className="c-estimate-gauge__text"
          style={{
            color: confidenceValueConverted ? COLORS[textDisplay] : '#bbb',
          }}
        >
          {confidenceValueConverted ? <>{estimate.confidenceDesc}</> : 'n/a'}
        </p>
      </div>
    </div>
  );
};

export default EstimateGauge;
