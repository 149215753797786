import { Avatar, Button, Modal, Popover } from 'antd';
import React, { useEffect, useState } from 'react';

import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import useBreakpoint from '../../../../foundation/custom_hooks/useBreakpont';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../user/redux/selectors';
import { getProfileForView, postProfileFollow } from '../../redux/async_thunks';
import { selectProfileBeingViewed } from '../../redux/selectors';
import { resetProfileBeingViewed } from '../../redux/slice';

type Props = {
  pid?: string;
  isFollowEnabled?: boolean;
  children: any;
  trigger: string;
};

const ProfileView = ({ pid, isFollowEnabled, children, trigger }: Props) => {
  const dispatch = useAppDispatch();

  const USER = useAppSelector(selectUser);
  const CLIENT_IP = useAppSelector(selectClientIp);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  const profile = useAppSelector(selectProfileBeingViewed);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);
  const [isMobileView] = useBreakpoint();

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const follow = async () => {
    setIsFollowLoading(true);

    if (profile) {
      try {
        await dispatch(
          postProfileFollow({
            data: {
              userId: USER.userId,
              profileId: pid,
              follow: !profile.following,
            },
            options: {
              token: USER.jwtToken.token,
              clientIp: CLIENT_IP,
              sessionId: USER.sessionId,
            },
          }),
        )
          // @ts-ignore
          .unwrap();
        setIsFollowLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    }

    setIsFollowLoading(false);
  };

  const fetchProfile = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        getProfileForView({
          data: {
            pid: pid,
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
          },
        }),
      )
        // @ts-ignore
        .unwrap();
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (pid && isModalDisplayed) {
      fetchProfile();
    }

    return () => {
      dispatch(resetProfileBeingViewed());
    };
  }, [pid, isModalDisplayed]);

  const modalProps = {
    wrapClassName: '',
    closable: true,
    footer: null as any,
    maskClosable: false,
    centered: true,
    open: true,
    title: '',
    width: 280,
    onCancel: () => {
      setIsModalDisplayed(false);
    },
  };

  const renderContent = () => {
    return (
      <div className="l-profile">
        {isLoading && <FullPageLoader message="Requesting..." />}
        {!isLoading && profile && (
          <>
            <Avatar
              src={profile?.picture}
              size={100}
              className="l-profile__usr-avatar"
            />
            <div className="l-profile__name">
              {profile.firstName} {profile.lastName}
            </div>
            {profile.handle && (
              <div className="l-profile__handle">{profile.handle}</div>
            )}
            {profile.occupation && (
              <div className="l-profile__occ">{profile.occupation}</div>
            )}
            {profile.bio && <p className="l-profile__bio">{profile.bio}</p>}
            <div className="l-profile__followers">
              {`${
                profile.followers === 1
                  ? profile.followers + ' Follower'
                  : profile.followers + ' Followers'
              }`}
            </div>
            {isFollowEnabled && (
              <Button
                className="l-profile__btn"
                onClick={() => {
                  if (!isFollowLoading) {
                    follow();
                  }
                }}
              >
                {profile.following ? 'Unfollow' : 'Follow'}
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isMobileView ? (
        <>
          <span
            onClick={(e) => {
              setIsModalDisplayed(true);
            }}
          >
            {children}
          </span>
          {isModalDisplayed && <Modal {...modalProps}>{renderContent()}</Modal>}
        </>
      ) : (
        <Popover
          placement="top"
          title={null}
          autoAdjustOverflow
          destroyTooltipOnHide
          showArrow={false}
          overlayInnerStyle={{
            borderRadius: 10,
            boxShadow: '4px 8px 14px rgba(0, 0, 0, 0.06)',
            border: '1px solid #D9DBE9',
            padding: '20px',
          }}
          content={renderContent()}
          trigger={trigger}
          onOpenChange={(visible) => {
            if (visible) {
              fetchProfile();
            }
          }}
        >
          {children}
        </Popover>
      )}
    </span>
  );
};

export default ProfileView;
