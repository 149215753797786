/* eslint react/prop-types: 0 */
/* eslint-disable */
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';

import { DeleteFilled, EyeOutlined, FileTextOutlined } from '@ant-design/icons';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin, {
  defaultTheme as defaultEmojiTheme,
} from '@draft-js-plugins/emoji';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  defaultTheme,
  MentionData,
} from '@draft-js-plugins/mention';
import { Card, Spin } from 'antd';
import {
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Strikethrough from '../../../foundation/assets/svgs/Strikethrough';
import BlockQuote from '../../../foundation/assets/svgs/BlockQuote';
import Bold from '../../../foundation/assets/svgs/Bold';
import Code from '../../../foundation/assets/svgs/Code';
import CodeBlock from '../../../foundation/assets/svgs/CodeBlock';
import Italic from '../../../foundation/assets/svgs/Italic';
import OrderedList from '../../../foundation/assets/svgs/OrderedList';
import SendMessageIcon from '../../../foundation/assets/svgs/SendMessage';
import UnorderedList from '../../../foundation/assets/svgs/UnorderedList';
import Attachment from './Attachment';

// import { messageFilterTypes } from '../utils/enums';

type Props = {
  editorMessageSendHandler: (raw: any, html: string) => any;
  sendMediaMessageHandler: (messageInput: any, messageType: string) => any;
  isReplying: boolean;
  conversationMembers: any;
  selectedConversation: any;
};

function MyEditor({
  editorMessageSendHandler,
  sendMediaMessageHandler,
  isReplying,
  conversationMembers,
  selectedConversation,
}: Props) {
  // Editor(draftJS) component ref
  const editorRef = useRef<any>(null);

  // Editor Wrapper component's(div) ref.
  const divRef = useRef<HTMLDivElement>(null);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );

  // Attachment states.
  const [unscannedFiles, setUnScannedFiles] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [mentionOptions, setMentionOptions] = useState<MentionData[]>([]);

  const TOOLBAR_CONTROLS = [
    { label: 'Bold', style: 'BOLD', icon: <Bold />, type: 'inline' },
    { label: 'Italic', style: 'ITALIC', icon: <Italic />, type: 'inline' },
    {
      label: 'Strikethrough',
      style: 'STRIKETHROUGH',
      icon: <Strikethrough />,
      type: 'inline',
    },
    { label: 'Divider', style: 'Divider1', icon: <Code />, type: 'divider' },
    {
      label: 'OL',
      style: 'ordered-list-item',
      icon: <OrderedList />,
      type: 'block',
    },
    {
      label: 'UL',
      style: 'unordered-list-item',
      icon: <UnorderedList />,
      type: 'block',
    },
    { label: 'Divider', style: 'Divider2', icon: <Code />, type: 'divider' },
    {
      label: 'Blockquote',
      style: 'blockquote',
      icon: <BlockQuote />,
      type: 'block',
    },
    { label: 'Divider', style: 'Divider3', icon: <Code />, type: 'divider' },
    { label: 'Monospace', style: 'CODE', icon: <Code />, type: 'inline' },
    {
      label: 'Code Block',
      style: 'code-block',
      icon: <CodeBlock />,
      type: 'block',
    },
  ];

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [selectedConversation]);

  const inlineStylesHandler = (inlineStyle: any) => {
    onEditorStateChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const blockTypesHandler = (blockType: any) => {
    onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const StyleButton = (props: any) => {
    const onToggle = (e: any) => {
      e.preventDefault();
      props.onToggle(props.style);
    };

    let className = 'rich-text__style-btn';
    if (props.active) {
      className += ' rich-text__style-btn--active';
    }

    return (
      <span className={className} onMouseDown={onToggle}>
        {props.icon}
      </span>
    );
  };

  const ToolbarControls = () => {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    const currentStyle = editorState.getCurrentInlineStyle();

    return (
      <>
        {TOOLBAR_CONTROLS.map((type, index) => {
          if (type.type === 'block') {
            return (
              <StyleButton
                key={type.label}
                active={type.style === blockType}
                label={type.label}
                onToggle={blockTypesHandler}
                style={type.style}
                icon={type.icon}
              />
            );
          } else if (type.type === 'inline') {
            return (
              <StyleButton
                key={type.label}
                active={currentStyle.has(type.style)}
                label={type.label}
                onToggle={inlineStylesHandler}
                style={type.style}
                icon={type.icon}
              />
            );
          } else if (type.type === 'divider') {
            return <Divider key={index} />;
          }
          return <></>;
        })}
      </>
    );
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const Divider = () => {
    return <span className="rich-text__control-divider"></span>;
  };

  function myKeyBindingFn(e: any): string | null {
    if (e.keyCode === 13 && !e.shiftKey) {
      return 'save';
    }
    return getDefaultKeyBinding(e);
  }

  const sendMessageHandler = async () => {
    const contentState = editorState.getCurrentContent();
    if (
      contentState.hasText() &&
      contentState.getPlainText().trim().length > 0
    ) {
      // Assign the raw editor content
      const raw = convertToRaw(editorState.getCurrentContent());

      const options = {
        entityStyleFn: (entity: any) => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'mention') {
            const data = entity.getData();

            return {
              class: 'chat-mention',
              attributes: {
                class: 'chat-mention',
                'data-uid': data.mention.uid,
              },
            };
          }
          return {};
        },
      };

      // Generate the html.
      const html = stateToHTML(editorState.getCurrentContent(), options);

      editorMessageSendHandler(raw, html);
      onEditorStateChange(EditorState.createEmpty());
    }
    // Send files.
    if (fileList && fileList.length > 0) {
      const $promises = [];
      for (const item of fileList) {
        // @ts-ignore
        $promises.push(sendMediaMessageHandler(item.file, item.messageType));
      }

      await Promise.all($promises);
      setFileList([]);
    }
  };

  const delay = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 50);
    });

  /**
   * Handles the key command for DraftJS editor.
   * @param {String} command DraftJS commands
   */
  const handleKeyCommand = (command: any) => {
    let newState = null;

    if (command === 'save') {
      sendMessageHandler();
    } else if (command === 'split-block') {
      delay()
        .then(() => {
          if (divRef && divRef.current) {
            divRef.current.scrollTop = divRef?.current?.scrollHeight + 30;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      /**
       * Default handler from DraftJS.
       */
      newState = RichUtils.handleKeyCommand(editorState, command);
    }

    if (newState) {
      onEditorStateChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onScannedMediaDeleteClick = (item: any) => () => {
    const updatedFilesList = [];

    for (const file of fileList) {
      if (file.file.name !== item.file.name) {
        updatedFilesList.push(file);
      }
    }

    setFileList(updatedFilesList);
  };

  const renderMediaPreview = useMemo(() => {
    const previews: any[] = [];
    for (const item of fileList) {
      previews.push(
        <Card
          key={item.name}
          size="small"
          style={{ width: 80, height: 80, borderRadius: 4 }}
          hoverable
          bodyStyle={{ padding: 5 }}
          className="rich-text__file-preview-card"
        >
          {item.messageType === 'image' ? (
            <img
              src={item.base64URL}
              height={'100%'}
              width={'100%'}
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <a>
              <FileTextOutlined />
            </a>
          )}
          <div className="rich-text__file-operations">
            <span className="rich-text__file-delete-option">
              <EyeOutlined />
            </span>
            <span
              className="rich-text__file-delete-option"
              onClick={onScannedMediaDeleteClick(item)}
            >
              <DeleteFilled />
            </span>
          </div>
        </Card>,
      );
    }
    return previews;
  }, [fileList]);

  const renderMediaScanPreview = useMemo(() => {
    const previews: any[] = [];
    for (const item of unscannedFiles) {
      previews.push(
        <Card
          key={item.name}
          size="small"
          style={{ width: 80, height: 80, borderRadius: 4 }}
          hoverable
          bodyStyle={{ padding: 5 }}
          className="rich-text__file-preview-card"
        >
          <Spin size="small" />
        </Card>,
      );
    }
    return previews;
  }, [unscannedFiles]);

  const entry = (props: any): any => {
    const {
      mention,
      theme,
      // searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      // isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;

    return (
      <div {...parentProps}>
        <div className="l-mention">
          <div className="l-mention__avatar">
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>
          <div className="l-mention__details">
            <div className="l-mention__handle">{mention.name}</div>
            <div className="l-mention__full-name">{mention.fullName}</div>
          </div>
        </div>
      </div>
    );
  };

  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } =
    useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        theme: {
          ...defaultTheme,
          ...{ mention: 'chat-mention' },
        },
        mentionComponent: (mentionProps) => {
          return (
            <span className={mentionProps.className}>
              {mentionProps.children}
            </span>
          );
        },
      });

      const emojiPlugin = createEmojiPlugin({
        // useNativeArt: true,
        theme: {
          ...defaultEmojiTheme,
          ...{
            emojiSuggestions: 'chat-emoji',
            emojiSelectPopover: 'chat-emoji-popup',
            emojiSelectButton: 'chat-emoji-button',
            emojiSelectButtonPressed: 'chat-emoji-button',
          },
        },
      });
      const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;

      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin, emojiPlugin];
      return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
    }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      // The mentions plugin dropped support for special characters except for underscore & hyphen
      if (mentionOptions && mentionOptions.length) {
        // @ts-ignore
        setSuggestions(defaultSuggestionsFilter(value, mentionOptions));
      }
    },
    [mentionOptions],
  );

  useEffect(() => {
    if (conversationMembers && conversationMembers.length) {
      setMentionOptions(
        conversationMembers.map((cm: any) => {
          return {
            // Name = handle
            name: cm.metadata.hdl,
            avatar: cm.avatar,
            fullName: cm.name,
            uid: cm.uid,
          };
        }),
      );
    }
  }, [conversationMembers]);

  useEffect(() => {
    if (mentionOptions && mentionOptions.length) {
      setSuggestions(mentionOptions);
    }
  }, [mentionOptions]);

  const handleEditorFocus = () => {
    console.log(divRef?.current?.clientHeight);
  };

  const handleEditorBlur = () => {
    console.log(divRef?.current?.clientHeight);
  };

  /*
   * Handles the onFocus event on Editor Wrapper which actually focuses the Editor.
   * @param {Object(Event)} e
   */
  const onFocus = () => {
    if (editorRef) {
      editorRef?.current?.focus();
    }
  };

  return (
    <div className="rich-text__editor-wrapper">
      <div className="rich-text__top-controls">
        <ToolbarControls />
      </div>

      <div className="rich-text__editor" ref={divRef} onFocus={onFocus}>
        <Editor
          editorState={editorState}
          onChange={onEditorStateChange}
          ref={editorRef}
          placeholder={isReplying ? 'Send reply...' : 'Send a message...'}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          plugins={plugins}
          onFocus={handleEditorFocus}
          onBlur={handleEditorBlur}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // Get the mention object selected
          }}
          entryComponent={entry}
          // mentionRegExp={^[A-Za-z0-9_\.-]*$}
        />
      </div>
      <div className="rich-text__file-preview-container">
        {renderMediaPreview}
        {renderMediaScanPreview}
      </div>

      <div className="rich-text__bottom-controls">
        <div className="rich-text__bottom-left-controls">
          <div className="rich-text__bottom-control">
            <Attachment
              setFilesList={setFileList}
              filesList={fileList}
              setUnScannedFiles={setUnScannedFiles}
              unscannedFiles={unscannedFiles}
            />
          </div>
          <div className="rich-text__bottom-control">
            {' '}
            <EmojiSelect />
          </div>
          <div>
            <EmojiSuggestions />
          </div>
        </div>
        <div className="rich-text__bottom-right-controls">
          <div
            className="rich-text__bottom-control"
            onClick={sendMessageHandler}
          >
            <SendMessageIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyEditor;
