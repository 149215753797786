import { notification } from 'antd';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectClientIp, selectUser } from '../../../../user/redux/selectors';
import {
  selectActiveSuburb,
  selectActiveSuburbSscCode,
  selectIsMapSearchEnabled,
  selectMapSearchPropertyType,
  selectResults,
} from '../../../redux/selectors';
import LineChart from '../key_indicators/line_chart/LineChart';
import { postSuburbForecastGraphs } from '../redux/async_thunks';
import {
  selectKeyIndicatorsValues,
  selectTimelineType,
} from '../redux/selectors';
import { setTimelineType } from '../redux/slice';
import View from './view/View';

type Props = {
  setAreDetailsLoading?: any;
};

const PriceRentForecast = ({ setAreDetailsLoading }: Props) => {
  const sscCode = useAppSelector(selectActiveSuburbSscCode);
  const results = useAppSelector(selectResults);
  let propertyType = results ? results.propertyType : 'Houses';

  const mapSearchPropertyType = useAppSelector(selectMapSearchPropertyType);
  const isMapSearchEnabled = useAppSelector(selectIsMapSearchEnabled);

  if (isMapSearchEnabled) {
    propertyType = mapSearchPropertyType;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const keyIndicators = useAppSelector(selectKeyIndicatorsValues);

  const user = useAppSelector(selectUser);
  const suburb = useAppSelector(selectActiveSuburb);
  const clientIp = useAppSelector(selectClientIp);

  const toggleLoader = (param: boolean) => {
    if (isFirstLoad) {
      setIsLoading(param);
    } else {
      setAreDetailsLoading(param);
    }
  };

  const timelineType = useAppSelector(selectTimelineType);

  const errorHandler = () => {
    notification.error({
      message: 'Error: Graph section',
      description: 'Something went wrong with the request',
    });
  };

  const fetchForecastData = async () => {
    try {
      toggleLoader(true);

      await dispatch(
        postSuburbForecastGraphs({
          data: {
            userId: user.userId,
            sscCode: sscCode,
            propertyType: propertyType,
            timelineYears: timelineType,
          },
          options: {
            token: user.jwtToken.token,
            clientIp: clientIp,
            sessionId: user.sessionId,
          },
        }),
        // @ts-ignore
      ).unwrap();

      toggleLoader(false);

      if (isFirstLoad) {
        setIsFirstLoad(false);
      }
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }
    }

    toggleLoader(false);
  };

  return (
    <View
      user={user}
      suburb={suburb}
      timelineType={timelineType}
      setTimelineType={setTimelineType}
      keyIndicators={keyIndicators}
      fetchForecastData={fetchForecastData}
      isLoading={isLoading}
      LineChart={LineChart}
    />
  );
};

export default PriceRentForecast;
