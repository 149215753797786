import { Modal } from 'antd';
import React, { useEffect } from 'react';

import Close from '../../../../../../foundation/assets/svgs/Close';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectBreakpoint } from '../../../../../layout/redux/selectors';
import { setIsSortModalActive } from '../../../../redux/slice';
import Sort from '../Sort';

const FilterModal = () => {
  const dispatch = useAppDispatch();
  const breakpoint = useAppSelector(selectBreakpoint);

  const cancelHandler = () => {
    dispatch(setIsSortModalActive(false));
  };

  useEffect(() => {
    if (breakpoint === 'mobile') {
      cancelHandler();
    }
  }, [breakpoint]);

  return (
    <Modal
      title={'Sort By'}
      open={true}
      footer={false}
      onCancel={cancelHandler}
      centered
      maskClosable={false}
      width={455}
      closeIcon={
        <strong className="l-filter-modal__close">
          <Close />
        </strong>
      }
    >
      <Sort cancelHandler={cancelHandler} />
    </Modal>
  );
};

export default FilterModal;
