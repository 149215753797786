import { notification } from 'antd';
import { map as pluck } from 'lodash';
import { useEffect, useState } from 'react';

import { postSearchResults } from '../../features/search/redux/async_thunks';
import {
  selectEnabledSearchMetrics,
  selectLastSearchValues,
  selectOptions,
  selectQueryValues,
  selectSearchMetricValues,
} from '../../features/search/redux/selectors';
import {
  resetSuggestions,
  setIsSearchFeatureLoading,
  setKeyword,
} from '../../features/search/redux/slice';
import { prepareSearchMetrics } from '../../features/search/utils/functions';
import {
  selectClientIp,
  selectUser,
} from '../../features/user/redux/selectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useSearchType from './useSearchType';

const useSearch = () => {
  const dispatch = useAppDispatch();
  const searchType = useSearchType();

  const DEFAULT_SEARCH_OPTIONS = {
    beforeCb: undefined as any,
    afterCb: undefined as any,
    isUsingLastSearchValues: false,
    pageIndexParam: undefined as any,
  };

  const [searchOptions, setSearchOptions] = useState<any>(null);

  const CLIENT_IP = useAppSelector(selectClientIp);
  const USER = useAppSelector(selectUser);

  const queryValues = useAppSelector(selectQueryValues);

  const options = useAppSelector(selectOptions);
  const enabledSearchMetrics = useAppSelector(selectEnabledSearchMetrics);
  const searchMetricValues = useAppSelector(selectSearchMetricValues);

  const lastSearchValues = useAppSelector(selectLastSearchValues);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResults = async () => {
    const { isUsingLastSearchValues, pageIndexParam, beforeCb, afterCb } =
      searchOptions || DEFAULT_SEARCH_OPTIONS;

    dispatch(setIsSearchFeatureLoading(true));

    if (beforeCb) {
      beforeCb();
    }

    try {
      const searchMetricsChecked = prepareSearchMetrics(
        options,
        enabledSearchMetrics,
        searchMetricValues,
      );

      const checkQueryValues = queryValues.length
        ? pluck(queryValues, 'value')
        : null;

      // Query values come from the suggestions end point
      // There are some user flows wherein query values are not preconstructed
      // Therefore we have to depend on & use the previous search values in order to do a new search
      const searchValues = isUsingLastSearchValues
        ? lastSearchValues
        : checkQueryValues;

      const response: any = await dispatch(
        postSearchResults({
          data: {
            ...{ userId: USER.userId },
            ...{
              sscCodes: null,
              searchValues: searchValues,
            },
            ...options,
            ...{
              pageIndex: pageIndexParam || options.pageIndex,
              searchMetrics: searchMetricsChecked,
            },
          },
          options: {
            token: USER.jwtToken.token,
            clientIp: CLIENT_IP,
            sessionId: USER.sessionId,
            searchType: searchType,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      const { suburbs, properties, pageCount, pageIndex } = response;
      const hasNoResults =
        response &&
        ((!!suburbs && !suburbs.length) ||
          (!!properties && !properties.length)) &&
        !pageCount &&
        pageIndex === 1;

      if (hasNoResults) {
        notification.warning({
          message: 'No results found',
          description:
            'Sorry, the search and filter combination have no results.',
          placement: 'topRight',
          top: 100,
        });
      }

      if (afterCb) {
        afterCb(hasNoResults);
      }
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setKeyword(undefined));
    dispatch(resetSuggestions());
    dispatch(setIsSearchFeatureLoading(false));

    setSearchOptions(null);
  };

  useEffect(() => {
    if (searchOptions) {
      fetchResults();
    }
  }, [searchOptions]);

  return setSearchOptions;
};

export default useSearch;
